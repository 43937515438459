import axios from "axios";
import { MAIN_URL, REQUEST_URL } from "../../constant/ApplicationVariables";
import { axiosInstance } from "../useAxios/useAxios";


export const getTestCaseList_API = async (body) => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.testCaseList,
            method: "POST",
            data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
} 

export const getTestCaseListAll_API = async (query) => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.testCaseListAll + `?${query}`,
            method: "GET",
            // data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
} 

export const getTestCaseProirityList_API = async () => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.userStoryPriority,
            method: "GET",
            // data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}


export const addTestCase_API = async (body) => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.testCase,
            method: "POST",
            data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}

export const updateTestCase_API = async (body) => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.testCase,
            method: "PUT",
            data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}

export const deleteTestCase_API = async (body) => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.testCase ,
            method: "DELETE",
            data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}


export const getTestCaseItemsForLink_API = async (query) => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.testCaseLink + `?${query}`,
            method: "GET",
            // data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}

export const generateTestCaseCustomId_API = async (body) => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.testCaseCustomId,
            method: "POST",
            data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}


export const getTestCasePropertiesList_API = async () => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.testCasePropertiesList,
            method: "GET",
            // data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}

export const getTestCasePriorityList_API = async () => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.testCasePriority,
            method: "GET",
            // data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}

export const getTestCaseTypeList_API = async () => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.testCaseType,
            method: "GET",
            // data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}

export const getTestCaseStateList_API = async () => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.testCaseState,
            method: "GET",
            // data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}

export const getTestCaseStatusList_API = async () => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.testCaseStatus,
            method: "GET",
            // data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}

export const addCommentTestCase_API = async (body) => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.testCaseComment,
            method: "POST",
            data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}

export const updateCommentTestCase_API = async (body) => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.testCaseComment,
            method: "PUT",
            data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}