import { Avatar, Box, Chip, IconButton, Typography, useTheme } from "@mui/material";
import DeleteConfirmationDialog from "../../dialog/DeleteConfirmationDialog";
import { Attachment, AttachmentOutlined, DeleteOutlined, EditOutlined, ReplyOutlined, Send, SendOutlined } from "@mui/icons-material";
import ReactQuill from "react-quill";
import { convertEditorDataToReact } from "../../../utils/StringOperations";
import { font } from "../../../styles/font";
import { formatDateTime } from "../../../utils/DateTime";
import { useEffect, useState } from "react";
import { ModulePath, Severity } from "../../../constant/ApplicationVariables";
import { HttpStatusCode } from "axios";
import { addCommentUserStory_API, updateCommentUserStory_API } from "../../../api/controller/StoryController";
import { getFileDetail_API } from "../../../api/controller/FileController";
import useErrorHandler from "../../../api/errorHandler/useErrorHandler";
import { useApplicationContext } from "../../../context/ApplicationContext";
import { ValueComponent } from "./CustomTextFormatting";
import { addCommentUseCase_API, updateCommentUseCase_API } from "../../../api/controller/UseCaseController";
import { addCommentTestCase_API, updateCommentTestCase_API } from "../../../api/controller/TestCaseController";
import { useLocation } from "react-router-dom";

export function CommentComponent({ comment }) {
    const { handleSnackbar, activeProject } = useApplicationContext();

    const theme = useTheme();
    const errorHandler = useErrorHandler();
    const pathName = useLocation()?.pathname;


    const [loading, setLoading] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const [commentData, setCommentData] = useState(null);
    const [replyText, setReplyText] = useState("");
    const [replyCommentData, setReplyCommentData] = useState(null);

    const handleToggleEditMode = () => {
        setIsEditMode(!isEditMode);
    }

    const handleOpenDeleteDialog = () => {
        setOpenDeleteDialog(true);
    }

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    }

    const handleToggleReplyEnable = (data) => {
        if (replyCommentData) {
            if (replyCommentData?.customId === data?.customId) {
                setReplyCommentData(null);
            } else {
                setReplyCommentData(data);
            }
        } else {
            setReplyCommentData(data);
        }

    }

    const handleUpdate = (...args) => {
        switch (pathName) {
            case ModulePath.requirements:
                handleUpdateComment_UserStory(...args);
                break;
            case ModulePath.useCase:
                handleUpdateComment_UseCase(...args);
                break;
            case ModulePath.testCase:
                handleUpdateComment_TestCase(...args);
                break;
            default:
                throw new Error('Invalid pathName');
        }
    }

    const handleReply = () => {
        switch (pathName) {
            case ModulePath.requirements:
                handleAddCommentReply_UserStory();
                break;
            case ModulePath.useCase:
                handleAddCommentReply_UseCase();
                break;
            case ModulePath.testCase:
                handleAddCommentReply_TestCase();
                break;
            default:
                throw new Error('Invalid pathName');
        }
    }


    const handleGetFileDetail = async () => {
        try {
            setLoading(true);

            let body = {
                // path: url
            }

            const response = await getFileDetail_API(body);
            console.log(response, "response handleUpdateStory")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response?.status === HttpStatusCode.Ok) {

            }

            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const handleUpdateComment_UserStory = async (isDelete) => {
        try {
            setLoading(true);

            let body = {
                customId: commentData?.customId,
                text: commentData?.text,
                isDelete: isDelete,
                "lastModifiedAt" : commentData?.lastModifiedAt ? commentData?.lastModifiedAt : null,

                // attachments : "",

                projectName: activeProject?.name,

            }


            const bodyFormData = new FormData();

            // Object.keys(body).forEach(key => bodyFormData.append(key, body[key]));
            Object.keys(body).forEach(key => {
                if (Array.isArray(body[key])) {
                    if (key === "attachments") {
                        body[key]?.forEach((file, index) => {
                            bodyFormData.append(`${key}[${index}]`, file, file.name);
                        })
                    } else {
                        bodyFormData.append(key, body[key]);
                    }

                } else {
                    bodyFormData.append(key, body[key]);
                }
            });

            //    formData.append("media", file, file.name);


            const response = await updateCommentUserStory_API(bodyFormData);
            console.log(response, "response handleUpdateStory")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response?.status === HttpStatusCode.Ok) {
                // // update in current user story data
                // let list = userStoryData?.comments ? userStoryData?.comments : [];
                // list.push(response.data);
                // setUserStoryData({ ...userStoryData, comments: list });


                // // update in user story list
                // let completeList = [...userStoryList];
                // const foundIndex = completeList?.findIndex(item => item?.customId === currentUserStoryData?.customId);
                // if (foundIndex !== -1) {
                //     completeList[foundIndex] = { ...currentUserStoryData, comments: list };
                // }
                // setUserStoryList(completeList);


                if (isDelete) {
                    handleCloseDeleteDialog();
                } else {
                    setCommentData(response.data);
                }

                handleSnackbar("Comment updated successfully", Severity.success);
                setIsEditMode(false);
            }


            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const handleUpdateComment_UseCase = async (isDelete) => {
        try {
            setLoading(true);

            let body = {
                customId: commentData?.customId,
                text: commentData?.text,
                delete: isDelete,
                "lastModifiedAt" : commentData?.lastModifiedAt ? commentData?.lastModifiedAt : null,

                // attachments : "",

                projectName: activeProject?.name,

            }


            const bodyFormData = new FormData();

            // Object.keys(body).forEach(key => bodyFormData.append(key, body[key]));
            Object.keys(body).forEach(key => {
                if (Array.isArray(body[key])) {
                    if (key === "attachments") {
                        body[key]?.forEach((file, index) => {
                            bodyFormData.append(`${key}[${index}]`, file, file.name);
                        })
                    } else {
                        bodyFormData.append(key, body[key]);
                    }

                } else {
                    bodyFormData.append(key, body[key]);
                }
            });

            //    formData.append("media", file, file.name);


            const response = await updateCommentUseCase_API(bodyFormData);
            console.log(response, "response handleUpdateStory")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response?.status === HttpStatusCode.Ok) {
                // // update in current user story data
                // let list = userStoryData?.comments ? userStoryData?.comments : [];
                // list.push(response.data);
                // setUserStoryData({ ...userStoryData, comments: list });


                // // update in user story list
                // let completeList = [...userStoryList];
                // const foundIndex = completeList?.findIndex(item => item?.customId === currentUserStoryData?.customId);
                // if (foundIndex !== -1) {
                //     completeList[foundIndex] = { ...currentUserStoryData, comments: list };
                // }
                // setUserStoryList(completeList);

                if (isDelete) {
                    handleCloseDeleteDialog();
                } else {
                    setCommentData(response.data);
                }

                handleSnackbar("Comment updated successfully", Severity.success);
                setIsEditMode(false);
            }


            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const handleUpdateComment_TestCase = async (isDelete) => {
        try {
            setLoading(true);

            let body = {
                customId: commentData?.customId,
                text: commentData?.text,
                delete: isDelete,
                "lastModifiedAt" : commentData?.lastModifiedAt ? commentData?.lastModifiedAt : null,

                // attachments : "",

                projectName: activeProject?.name,

            }


            const bodyFormData = new FormData();

            // Object.keys(body).forEach(key => bodyFormData.append(key, body[key]));
            Object.keys(body).forEach(key => {
                if (Array.isArray(body[key])) {
                    if (key === "attachments") {
                        body[key]?.forEach((file, index) => {
                            bodyFormData.append(`${key}[${index}]`, file, file.name);
                        })
                    } else {
                        bodyFormData.append(key, body[key]);
                    }

                } else {
                    bodyFormData.append(key, body[key]);
                }
            });

            //    formData.append("media", file, file.name);


            const response = await updateCommentTestCase_API(bodyFormData);
            console.log(response, "response handleUpdateStory")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response?.status === HttpStatusCode.Ok) {
                // // update in current user story data
                // let list = userStoryData?.comments ? userStoryData?.comments : [];
                // list.push(response.data);
                // setUserStoryData({ ...userStoryData, comments: list });


                // // update in user story list
                // let completeList = [...userStoryList];
                // const foundIndex = completeList?.findIndex(item => item?.customId === currentUserStoryData?.customId);
                // if (foundIndex !== -1) {
                //     completeList[foundIndex] = { ...currentUserStoryData, comments: list };
                // }
                // setUserStoryList(completeList);

                if (isDelete) {
                    handleCloseDeleteDialog();
                } else {
                    setCommentData(response.data);
                }

                handleSnackbar("Comment updated successfully", Severity.success);
                setIsEditMode(false);
            }


            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }




    const handleAddCommentReply_UserStory = async () => {
        try {
            setLoading(true);

            let body = {
                // "customIds": [currentUserStoryData?.customId],
                // "newCustomId": userStoryData?.customId,
                // "title": userStoryData?.title,
                // "description": userStoryData?.description,
                // "priority": "Medium",
                // "assignedToEmail": userStoryData?.assignedToEmail,
                // "acceptanceCriteria": userStoryData?.acceptanceCriteria,
                // "status": userStoryData?.status,
                // "moduleName": currentFolderData?.type === FolderType.project ? currentUserStoryData?.moduleName : currentFolderData?.name,


                "text": replyText,

                // "userStoryCustomId": userStoryData?.customId,
                // "useCaseCustomId": "",
                // "testCaseCustomId": "",
                "commentCustomId": commentData?.customId, // if user reply for particular comment, then use it.

                // "attachments": "", // MVP2


                // "folderPath": currentFolderData?.path, // if userStoryCustomId / useCaseCustomId / testCaseCustomId is present, then use it.
                "projectName": activeProject?.name,
            }


            // if (selectedFiles?.length > 0) {
            //     body.attachments = selectedFiles
            //     setSelectedFiles([])
            // }



            const bodyFormData = new FormData();

            // Object.keys(body).forEach(key => bodyFormData.append(key, body[key]));
            Object.keys(body).forEach(key => {
                if (Array.isArray(body[key])) {
                    if (key === "attachments") {
                        body[key]?.forEach((file, index) => {
                            bodyFormData.append(`${key}[${index}]`, file, file.name);
                        })
                    } else {
                        bodyFormData.append(key, body[key]);
                    }

                } else {
                    bodyFormData.append(key, body[key]);
                }
            });

            //    formData.append("media", file, file.name);


            const response = await addCommentUserStory_API(bodyFormData);
            console.log(response, "response handleUpdateStory")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response?.status === HttpStatusCode.Ok) {
                // // update in current user story data
                // let list = userStoryData?.comments ? userStoryData?.comments : [];
                // list.unshift(response.data);
                // setUserStoryData({ ...userStoryData, comments: list });


                // // update in user story list
                // let completeList = [...userStoryList];
                // const foundIndex = completeList?.findIndex(item => item?.customId === currentUserStoryData?.customId);
                // if (foundIndex !== -1) {
                //     completeList[foundIndex] = { ...currentUserStoryData, comments: list };
                // }
                // setUserStoryList(completeList);

                setCommentData({ ...commentData, replies: commentData?.replies ? [response.data, ...commentData?.replies] : [response.data] });

                setReplyText("");
                setReplyCommentData(null);
                handleSnackbar(" Comment added successfully", Severity.success);
            }


            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const handleAddCommentReply_UseCase = async () => {
        try {
            setLoading(true);

            let body = {
                // "customIds": [currentUserStoryData?.customId],
                // "newCustomId": userStoryData?.customId,
                // "title": userStoryData?.title,
                // "description": userStoryData?.description,
                // "priority": "Medium",
                // "assignedToEmail": userStoryData?.assignedToEmail,
                // "acceptanceCriteria": userStoryData?.acceptanceCriteria,
                // "status": userStoryData?.status,
                // "moduleName": currentFolderData?.type === FolderType.project ? currentUserStoryData?.moduleName : currentFolderData?.name,


                "text": replyText,

                // "userStoryCustomId": userStoryData?.customId,
                // "useCaseCustomId": "",
                // "testCaseCustomId": "",
                "commentCustomId": commentData?.customId, // if user reply for particular comment, then use it.

                // "attachments": "", // MVP2


                // "folderPath": currentFolderData?.path, // if userStoryCustomId / useCaseCustomId / testCaseCustomId is present, then use it.
                "projectName": activeProject?.name,
            }


            // if (selectedFiles?.length > 0) {
            //     body.attachments = selectedFiles
            //     setSelectedFiles([])
            // }



            const bodyFormData = new FormData();

            // Object.keys(body).forEach(key => bodyFormData.append(key, body[key]));
            Object.keys(body).forEach(key => {
                if (Array.isArray(body[key])) {
                    if (key === "attachments") {
                        body[key]?.forEach((file, index) => {
                            bodyFormData.append(`${key}[${index}]`, file, file.name);
                        })
                    } else {
                        bodyFormData.append(key, body[key]);
                    }

                } else {
                    bodyFormData.append(key, body[key]);
                }
            });

            //    formData.append("media", file, file.name);


            const response = await addCommentUseCase_API(bodyFormData);
            console.log(response, "response handleUpdateStory")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response?.status === HttpStatusCode.Ok) {
                // // update in current user story data
                // let list = userStoryData?.comments ? userStoryData?.comments : [];
                // list.unshift(response.data);
                // setUserStoryData({ ...userStoryData, comments: list });


                // // update in user story list
                // let completeList = [...userStoryList];
                // const foundIndex = completeList?.findIndex(item => item?.customId === currentUserStoryData?.customId);
                // if (foundIndex !== -1) {
                //     completeList[foundIndex] = { ...currentUserStoryData, comments: list };
                // }
                // setUserStoryList(completeList);

                setCommentData({ ...commentData, replies: commentData?.replies ? [response.data, ...commentData?.replies] : [response.data] });

                setReplyText("");
                setReplyCommentData(null);
                handleSnackbar(" Comment added successfully", Severity.success);
            }


            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const handleAddCommentReply_TestCase = async () => {
        try {
            setLoading(true);

            let body = {
                // "customIds": [currentUserStoryData?.customId],
                // "newCustomId": userStoryData?.customId,
                // "title": userStoryData?.title,
                // "description": userStoryData?.description,
                // "priority": "Medium",
                // "assignedToEmail": userStoryData?.assignedToEmail,
                // "acceptanceCriteria": userStoryData?.acceptanceCriteria,
                // "status": userStoryData?.status,
                // "moduleName": currentFolderData?.type === FolderType.project ? currentUserStoryData?.moduleName : currentFolderData?.name,


                "text": replyText,

                // "userStoryCustomId": userStoryData?.customId,
                // "useCaseCustomId": "",
                // "testCaseCustomId": "",
                "commentCustomId": commentData?.customId, // if user reply for particular comment, then use it.

                // "attachments": "", // MVP2


                // "folderPath": currentFolderData?.path, // if userStoryCustomId / useCaseCustomId / testCaseCustomId is present, then use it.
                "projectName": activeProject?.name,
            }


            // if (selectedFiles?.length > 0) {
            //     body.attachments = selectedFiles
            //     setSelectedFiles([])
            // }



            const bodyFormData = new FormData();

            // Object.keys(body).forEach(key => bodyFormData.append(key, body[key]));
            Object.keys(body).forEach(key => {
                if (Array.isArray(body[key])) {
                    if (key === "attachments") {
                        body[key]?.forEach((file, index) => {
                            bodyFormData.append(`${key}[${index}]`, file, file.name);
                        })
                    } else {
                        bodyFormData.append(key, body[key]);
                    }

                } else {
                    bodyFormData.append(key, body[key]);
                }
            });

            //    formData.append("media", file, file.name);


            const response = await addCommentTestCase_API(bodyFormData);
            console.log(response, "response handleUpdateStory")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response?.status === HttpStatusCode.Ok) {
                // // update in current user story data
                // let list = userStoryData?.comments ? userStoryData?.comments : [];
                // list.unshift(response.data);
                // setUserStoryData({ ...userStoryData, comments: list });


                // // update in user story list
                // let completeList = [...userStoryList];
                // const foundIndex = completeList?.findIndex(item => item?.customId === currentUserStoryData?.customId);
                // if (foundIndex !== -1) {
                //     completeList[foundIndex] = { ...currentUserStoryData, comments: list };
                // }
                // setUserStoryList(completeList);

                setCommentData({ ...commentData, replies: commentData?.replies ? [response.data, ...commentData?.replies] : [response.data] });

                setReplyText("");
                setReplyCommentData(null);
                handleSnackbar(" Comment added successfully", Severity.success);
            }


            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }



    useEffect(() => {
        setCommentData(comment);
    }, [comment]);

    console.log(replyCommentData, "replyCommentData");


    return (
        <Box className="flex flex-col h-full w-full ">


            <Box className="flex  gap-2">
                <Box className="flex flex-col items-center gap-2 ">
                    <Avatar sx={{ width: "1.8rem", height: "1.8rem", fontSize: font.size.fs_5 }} />

                    <Box className="w-0.5 h-full flex-1  flex rounded-full transition-all duration-300 ease-in-out" sx={{ background: theme.palette.divider, "&:hover": { background: theme.palette.secondary.main } }} ></Box>
                </Box>


                <Box className="flex-1 flex flex-col gap-2 " >

                    {/* comment header - name and date */}
                    <Box className="flex items-center gap-2">
                        <Chip size="small" label={commentData?.createdBy?.name} sx={{ borderRadius: theme.shape.borderRadius, background: theme.palette.background.custom, backdropFilter: "blur(5px)" }} />
                        <Typography fontSize={font.size.fs_5} className="opacity-80">{formatDateTime(commentData?.lastModifiedAt)}</Typography>
                    </Box>


                    {/* comment message */}
                    <Box className="flex flex-col gap-2 ">
                        {
                            isEditMode ? (
                                <Box className="flex gap-2">

                                    <Box className="w-full flex flex-col gap-0 "
                                        sx={{
                                            height: "131px", // set the height of the Box component to 100%
                                            overflowY: "auto",
                                            "& .ql-container": {
                                                // height: "40px",
                                                maxHeight: "80px",
                                                // overflowY: "auto"
                                            }
                                        }} >

                                        <ReactQuill

                                            theme="snow"
                                            styles={{
                                                // height: '80px', // set the height of the editor
                                                userSelect: loading && 'none'

                                            }}

                                            value={commentData?.text}
                                            onChange={(value) => setCommentData({ ...commentData, text: value })}
                                            placeholder="Your comment"
                                            modules={{
                                                toolbar: [
                                                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                                    ['bold', 'italic', 'underline', 'strike'],
                                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],

                                                ],

                                            }}
                                        />

                                    </Box>

                                    <Box className=" flex flex-col gap-0 ">

                                        <IconButton disabled={loading} onClick={() => handleUpdate(false)}>
                                            <SendOutlined />
                                        </IconButton>
                                        <IconButton disabled={loading}>
                                            <AttachmentOutlined sx={{ transform: "rotate(90deg)" }} />
                                        </IconButton>
                                    </Box>
                                </Box>
                            )
                                :
                                (
                                    <Box className="flex flex-col "
                                    // border={`1px solid ${replyCommentData?.customId === commentData?.customId ? theme.palette.secondary.main : "transparent"}`}
                                    >
                                        <Box className="flex " onClick={handleToggleEditMode}>
                                            <ValueComponent text={convertEditorDataToReact(`${commentData?.text}`)} />

                                        </Box>


                                    </Box>
                                )
                        }
                    </Box>


                    {/* comment actions */}
                    <Box className="flex items-center gap-2">


                        <IconButton disabled={loading} sx={{ color: replyCommentData?.customId === commentData?.customId && theme.palette.secondary.main }} onClick={() => handleToggleReplyEnable(commentData)}>
                            <ReplyOutlined />
                        </IconButton>
                        <IconButton disabled={loading} sx={{ color: isEditMode && theme.palette.secondary.main }} onClick={handleToggleEditMode}>
                            <EditOutlined />
                        </IconButton>
                        <IconButton disabled={loading} sx={{ color: openDeleteDialog && theme.palette.secondary.main }} onClick={handleOpenDeleteDialog}>
                            <DeleteOutlined />
                        </IconButton>
                    </Box>



                    {/* replay */}
                    <Box className="flex flex-col gap-4  ">
                        {/* create replay */}
                        {
                            replyCommentData?.customId === commentData?.customId &&
                            <Box className="flex gap-2">
                                <Box className="w-full flex flex-col gap-0 "
                                    sx={{
                                        height: "131px", // set the height of the Box component to 100%
                                        overflowY: "auto",
                                        "& .ql-container": {
                                            // height: "40px",
                                            maxHeight: "80px",
                                            // overflowY: "auto"
                                        }
                                    }} >

                                    <ReactQuill

                                        theme="snow"
                                        styles={{
                                            // height: '80px', // set the height of the editor
                                            userSelect: loading && 'none'

                                        }}

                                        value={replyText}
                                        onChange={(value) => setReplyText(value)}
                                        placeholder="Your Reply"
                                        modules={{
                                            toolbar: [
                                                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                                ['bold', 'italic', 'underline', 'strike'],
                                                [{ 'list': 'ordered' }, { 'list': 'bullet' }],

                                            ],

                                        }}
                                    />

                                </Box>

                                <Box className=" flex flex-col gap-0 ">
                                    <IconButton disabled={loading} onClick={handleReply}>
                                        <SendOutlined />
                                    </IconButton>
                                    <IconButton disabled={loading}>
                                        <AttachmentOutlined sx={{ transform: "rotate(90deg)" }} />
                                    </IconButton>
                                </Box>
                            </Box>
                        }


                        {/* replay list */}
                        <Box className="flex flex-col gap-2 w-full pl-0 "
                        // borderLeft={commentData?.replies?.length > 0 && `1px solid ${theme.palette.divider}`}
                        >
                            {
                                commentData?.replies?.reverse()?.map((comment, index) => (
                                    <Box key={index} className="flex flex-col gap-2 w-full " >
                                        <CommentComponent comment={comment} />
                                    </Box>
                                ))
                            }
                        </Box>
                    </Box>

                </Box>


            </Box>

            {/* <Box  className="flex flex-col gap-0">
                list of attachment
            </Box> */}


            {openDeleteDialog &&
                <DeleteConfirmationDialog
                    open={openDeleteDialog}
                    handleClose={handleCloseDeleteDialog}
                    handleContinue={() => handleUpdate(true)}
                />
            }

        </Box>
    )
}
