import { Box, Button, Dialog, Typography, useTheme } from "@mui/material";
import { useApplicationContext } from "../../context/ApplicationContext";
import { font } from "../../styles/font";
import { Warning } from "@mui/icons-material";
import { PiWarningCircle } from "react-icons/pi";
import { red } from "@mui/material/colors";
import { extendToken_API } from "../../api/controller/authController";
import { useEffect, useMemo, useRef, useState } from "react";
import useErrorHandler from "../../api/errorHandler/useErrorHandler";
import { HttpStatusCode } from "axios";
import { ModulePath, Severity } from "../../constant/ApplicationVariables";
import dayjs from "dayjs";
import Countdown from "react-countdown";


export default function TokenExpiredDialog() {
    const { showValidTillConfirmation, setShowValidTillConfirmation, handleSnackbar, profileDetails, setProfileDetails, handleLogout } = useApplicationContext();
    const theme = useTheme();
    const errorHandler = useErrorHandler();

    const [minute, setMinute] = useState(5);
    const [seconds, setSeconds] = useState(0);
    // const [time, setTime] = useState(dayjs().add(minute, 'minutes'));
    const [loading, setLoading] = useState(false);

    // useEffect(() => {
    //     let intervalId;
    //     if (showValidTillConfirmation) {
    //         intervalId = setInterval(() => {
    //             setTime((prevTime) => prevTime.subtract(1, 'second'));
    //         }, 1000);
    //     }
    //     return () => clearInterval(intervalId);
    // }, [showValidTillConfirmation]);

    // useEffect(() => {
    //     if (time.diff(dayjs()) <= 0) {
    //         setShowValidTillConfirmation(false);
    //         handleSnackbar("Session timed out", Severity.warning);
    //     }
    // }, [time, setShowValidTillConfirmation, handleSnackbar]);

    const handleLoggedIn = async () => {
        try {
            setLoading(true);
            const response = await extendToken_API();

            if (response) {
                errorHandler(response);
            }

            if (response && response.status === HttpStatusCode.Ok) {

                localStorage.setItem("access-token", response.data.authToken);
                localStorage.setItem("profileDetails", JSON.stringify(response.data));
                setProfileDetails(response.data);

                setShowValidTillConfirmation(false);
                setSeconds(0);
                handleSnackbar("Session extended successfully", Severity.success);
            }

        } catch (error) {
            console.error("Error extending token:", error);
            handleSnackbar(String(error), Severity.error);
        } finally {
            setLoading(false);
        }
    };

    const handleLogOff = () => {
       
        setShowValidTillConfirmation(false);
        handleSnackbar("Logged off successfully", Severity.info);
        handleLogout();

        return;
        localStorage.clear();
        setTimeout(() => {
            // window.location.href = ModulePath.login;
            window.location.replace(ModulePath.login);
        }, 200);
    };

    // Renderer callback with condition
    const renderer = ({ hours, minutes, seconds, completed }) => {
        console.log(hours, minutes, seconds, completed, "Countdown")
        if (completed) {
            console.log("Completed");
            handleLogOff();

            // Render a completed state
            // return <Completionist />;
        } else {
            // Render a countdown
            return <span>{hours}:{minutes}:{seconds}</span>;
        }
    };


    useEffect(() => {
        if (profileDetails?.validTill,showValidTillConfirmation ) {
            let secondsRemaining = dayjs(profileDetails?.validTill).diff(dayjs(), 'milliseconds');
            console.log("secondsRemaining", secondsRemaining, dayjs(profileDetails?.validTill).format("HH:mm:ss"))
            setSeconds(secondsRemaining)
        }
    }, [profileDetails?.validTill, showValidTillConfirmation])

    return (
        <Dialog open={showValidTillConfirmation} onClose={() => setShowValidTillConfirmation(false)}>
            <Box className="flex flex-col gap-0">
                <Box className="flex items-center gap-4 px-4 py-2 border-b">
                    <Typography fontSize={font.size.fs_4} color="textPrimary">Session Timeout</Typography>
                </Box>
                <Box className="flex-1 flex items-center gap-4 px-4 py-2">
                    <Warning color={"warning"} sx={{ fontSize: font.size.fs_1 }} />
                    <Box className="flex-1 flex flex-col gap-0">
                        <Typography fontSize={font.size.fs_4} color="textSecondary">Your session is about to expire.</Typography>
                        <Typography fontSize={font.size.fs_4} color="textSecondary">Please choose to stay logged in or log off.</Typography>
                        <Typography fontSize={font.size.fs_4} color="textSecondary">Otherwise, you will be logged out automatically.</Typography>
                    </Box>
                </Box>
                <Box className="flex items-center justify-end gap-4 px-4 py-2 border-t">
                    <Button variant="outlined" onClick={handleLogOff}>Log Off</Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        sx={{ color: theme.palette.secondary.contrastText, gap: 0.5 }}
                        onClick={handleLoggedIn}
                        disabled={loading} // Disable while loading
                    >
                        {loading ? "Extending..." : `Stay Logged In `}
                        {seconds > 0 && <Countdown
                            date={Date.now() + (seconds)}
                            renderer={renderer}
                            autoStart={seconds > 0}
                        />}
                    </Button>
                    {/* <Countdown date={Date.now() + 10000} /> */}


                </Box>
            </Box>
        </Dialog>
    )
}
