import { AudioFileOutlined, DashboardOutlined, FolderOutlined, FolderSpecialOutlined, Image, ImageOutlined, PagesOutlined, VideocamOutlined } from "@mui/icons-material";
import { font } from "../../styles/font";
import { TiDocumentText } from "react-icons/ti";
import { LuLink, LuUnlink } from "react-icons/lu";
import { blue, green, red } from "@mui/material/colors";
import { FaRegFileExcel, FaRegFilePdf, FaRegFileWord } from "react-icons/fa";

const defaultConfig = {
    fontSize : font.size.fs_4,
    color : "inherit"
}


export const ProjectCustomIcon = (props) => {
    return (
        <FolderSpecialOutlined
            {...props}
            sx={{ ...props.sx, fontSize: props?.sx?.fontSize ? props?.sx?.fontSize : defaultConfig.fontSize, color: props?.sx?.color ? props?.sx?.color : defaultConfig.color }}
        />
    )
}

export const FolderCustomIcon = (props) => {
    return (
        <FolderOutlined
            {...props}
            sx={{ ...props.sx, fontSize: props?.sx?.fontSize ? props?.sx?.fontSize : defaultConfig.fontSize, color: props?.sx?.color ? props?.sx?.color : defaultConfig.color }}
        />
    )
}

export const PageCustomIcon = (props) => {
    return (
        <TiDocumentText
            {...props}
            color={props.color ? props.color : "inherit"}
            fontSize={font.size.fs_4}
            style={{ ...props.sx, fontSize: props?.sx?.fontSize ? props?.sx?.fontSize : defaultConfig.fontSize, color: props?.sx?.color ? props?.sx?.color : defaultConfig.color }}
        />
    )
}

export const UserStoryCustomIcon = (props) => {
    return (
        <PagesOutlined
            {...props}
            sx={{ ...props.sx, fontSize: props?.sx?.fontSize ? props?.sx?.fontSize : defaultConfig.fontSize, color: props?.sx?.color ? props?.sx?.color : defaultConfig.color }}
        />
    )
}

export const UseCaseCustomIcon = (props) => {
    return (
        <PagesOutlined
            {...props}
            sx={{ ...props.sx, fontSize: props?.sx?.fontSize ? props?.sx?.fontSize : defaultConfig.fontSize, color: props?.sx?.color ? props?.sx?.color : defaultConfig.color }}
        />
    )
}

export const TestCaseCustomIcon = (props) => {
    return (
        <PagesOutlined
            {...props}
            sx={{ ...props.sx, fontSize: props?.sx?.fontSize ? props?.sx?.fontSize : defaultConfig.fontSize, color: props?.sx?.color ? props?.sx?.color : defaultConfig.color }}
        />
    )
}

export const LinkCustomIcon = (props) => {
    return (
        <LuLink
            {...props}
            color={props.color ? props.color : green[500]}
            fontSize={font.size.fs_3}
            style={{ ...props.sx, fontSize: props?.sx?.fontSize ? props?.sx?.fontSize : font.size.fs_3, color: props?.sx?.color ? props?.sx?.color : green[500] }}
        />
    )
}

export const UnLinkCustomIcon = (props) => {
    return (
        <LuUnlink
            {...props}
            color={props.color ? props.color : red[500]}
            fontSize={font.size.fs_3}
            style={{ ...props.sx, fontSize: props?.sx?.fontSize ? props?.sx?.fontSize : font.size.fs_3, color: props?.sx?.color ? props?.sx?.color : red[500] }}
        />
    )
}

export const PdfCustomIcon = (props) => {
    return (
        <FaRegFilePdf
            {...props}
            color={props.color ? props.color : red[500]}
            fontSize={font.size.fs_3}
            style={{ ...props.sx, fontSize: props?.sx?.fontSize ? props?.sx?.fontSize : font.size.fs_3, color: props?.sx?.color ? props?.sx?.color : red[500] }}
        />
    )
}

export const ExcelCustomIcon = (props) => {
    return (
        <FaRegFileExcel
            {...props}
            color={props.color ? props.color : green[500]}
            fontSize={font.size.fs_3}
            style={{ ...props.sx, fontSize: props?.sx?.fontSize ? props?.sx?.fontSize : font.size.fs_3, color: props?.sx?.color ? props?.sx?.color : green[500] }}
        />
    )
}

export const WordCustomIcon = (props) => {
    return (
        <FaRegFileWord
            {...props}
            color={props.color ? props.color : blue[500]}
            fontSize={font.size.fs_3}
            style={{ ...props.sx, fontSize: props?.sx?.fontSize ? props?.sx?.fontSize : font.size.fs_3, color: props?.sx?.color ? props?.sx?.color : blue[500] }}
        />
    )
}

export const ImageCustomIcon = (props) => {
    return (
        <ImageOutlined
            {...props}
            sx={{ ...props.sx, fontSize: props?.sx?.fontSize ? props?.sx?.fontSize : defaultConfig.fontSize, color: props?.sx?.color ? props?.sx?.color : defaultConfig.color }}
        />
    )
}

export const VideoCustomIcon = (props) => {
    return (
        <VideocamOutlined
            {...props}
            sx={{ ...props.sx, fontSize: props?.sx?.fontSize ? props?.sx?.fontSize : defaultConfig.fontSize, color: props?.sx?.color ? props?.sx?.color : defaultConfig.color }}
        />
    )
}

export const AudioCustomIcon = (props) => {
    return (
        <AudioFileOutlined
            {...props}
            sx={{ ...props.sx, fontSize: props?.sx?.fontSize ? props?.sx?.fontSize : defaultConfig.fontSize, color: props?.sx?.color ? props?.sx?.color : defaultConfig.color }}
        />
    )
}

export const DashboardCustomIcon = (props) => {
    return (
        <DashboardOutlined
            {...props}
            sx={{ ...props.sx, fontSize: props?.sx?.fontSize ? props?.sx?.fontSize : defaultConfig.fontSize, color: props?.sx?.color ? props?.sx?.color : defaultConfig.color }}
        />
    )
}