export function findFolder(list, keyName, value) {
    for (const item of list) {
        if (item[keyName] === value) {
            return item;
        }

        if (item.children) {
            const foundInChildren = findFolder(item.children, keyName, value);
            if (foundInChildren) {
                return foundInChildren;
            }
        }
    }
    return null;
}