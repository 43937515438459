import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import { HttpStatusCode } from 'axios';
import { login_API, requestOTP_API, resetPassword_API, verifyOTP_API } from '../../../api/controller/authController';
import { useApplicationContext } from '../../../context/ApplicationContext';
import useErrorHandler from '../../../api/errorHandler/useErrorHandler';
import { useLocation, useNavigate, } from 'react-router-dom';
import { ModulePath, Severity, VerificationType } from '../../../constant/ApplicationVariables';
import validator from "validator";
import { useState } from 'react';
import { useEffect } from 'react';
import { CircularProgress, IconButton, InputAdornment, useTheme } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import OtpInput from 'react-otp-input';
import { font } from '../../../styles/font';
import MainContainer, { AuthCardComponent } from '../container';

const Card = styled(MuiCard)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    width: '100%',
    py: theme.spacing(4),
    gap: theme.spacing(4),
    margin: 'auto',
    borderRadius: theme.shape.borderRadius,
    // background: theme.palette.background.paper,
    background: "transparent",
    boxShadow: "none",
    [theme.breakpoints.up('sm')]: {
        // maxWidth: '450px',
    },
    // boxShadow:
    //     'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
    // ...theme.applyStyles('dark', {
    //     boxShadow:
    //         'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
    // }),
}));


export default function ResetPasswordContainer() {
    const { globalSnackbar, setGLobalSnackBar, handleSnackbar, profileDetails, setProfileDetails, accountVerificationType, setAccountVerificationType, handleStoreUserData } = useApplicationContext();
    const errorHandler = useErrorHandler();
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();

    const [loginData, setLoginData] = useState(null);
    const [otp, setOtp] = useState('');
    const [error, setError] = useState(null);
    const [prevPageStateData, setPrevPageStateData] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [loading, setLoading] = useState(false);


    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent default form submission behavior
        validateInputs(); // Call your validation function
    };

    const handleChangeValue = (event) => {
        const { name, value } = event.target;

        setLoginData((prevData) => ({
            ...prevData,
            [name]: value
        }))
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }

    const validateInputs = () => {
        let isValid = true;
        const errorObject = {};

        if (!loginData?.password || loginData?.password?.length < 6) {
            errorObject.password = "Password must be at least 6 characters long.";
            isValid = false;
        }

        if (!loginData?.confirmPassword || loginData?.confirmPassword?.length < 6) {
            errorObject.confirmPassword = "Confirm Password must be at least 6 characters long.";
            isValid = false;
        }

        if (loginData?.password && (!loginData?.confirmPassword || loginData?.confirmPassword?.length < 6)) {
            errorObject.confirmPassword = "Confirm Password must be at least 6 characters long.";
            isValid = false;
        }

        if (loginData?.password && loginData?.confirmPassword && loginData?.password !== loginData?.confirmPassword) {
            errorObject.confirmPassword = "Password and Confirm Password must be same.";
            isValid = false;
        }

        setError(errorObject);

        return isValid && handleContinue();
    };

    const handleContinue = async () => {
        try {
            setLoading(true);

            let body = {
                password: loginData?.password
            }

            const response = await resetPassword_API(body);

            if (response) {
                errorHandler(response);
            }

            if (response.status === HttpStatusCode.Ok) {
                // handleStoreUserData(response.data);
                handleSnackbar("Reset successfully", Severity.success);
                setTimeout(() => {
                    navigate(ModulePath.projects);
                }, 1100);
            }
        } catch (error) {
            console.log(error, error?.message, "error");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }


    console.log(navigate, location, "navigate,location");

    // to prevent this page from being accessed directly, only accessible from verify password page
    useEffect(() => {
        if (location.state) {
            let { email, type, fromPath } = location.state;
            if (fromPath !== ModulePath.verifyAccount) {
                navigate(ModulePath.login);
            } else {
                setPrevPageStateData({ email, type, fromPath });
            }
        } else {
            navigate(ModulePath.login);
        }
    }, [location, navigate]);


    return (
        <Box className=" flex flex-col gap-0 relative h-full w-full box-border overflow-hidden" >
            <MainContainer >
                <AuthCardComponent className="flex-1 gap-10 ">




                    {/* header container */}
                    <Box className="flex flex-col   ">

                        <Typography
                            sx={{ width: '100%', }}
                            color="textPrimary"
                            fontSize={font.size.fs_1_5}
                            fontWeight="bold"
                        >
                            Reset Password
                        </Typography>

                        <Box className="flex items-center gap-2">
                            <Typography color={theme.palette.text.ternary} fontSize={font.size.fs_5} className="flex items-center justify-center gap-1 ">
                                Back to login{' '}

                                <Typography color={theme.palette.text.primary} fontSize={"inherit"} fontWeight="bold" className="underline cursor-pointer " onClick={() => navigate(ModulePath.login)}>
                                    Click here
                                </Typography>

                            </Typography>
                        </Box>
                    </Box>

                    {/* form container */}
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        noValidate
                        className="flex flex-col w-full gap-10     "

                    >

                        {/* input container */}
                        <Box
                            className="flex flex-col w-full gap-6 py-0  "

                        >
                            <FormControl>
                                {/* <FormLabel htmlFor="password">Password</FormLabel> */}
                                <TextField
                                    label="Password"
                                    error={error?.password && error?.password}
                                    helperText={error?.password && error?.password}
                                    name="password"
                                    placeholder="••••••"
                                    type={showPassword ? "text" : "password"}
                                    id="password"
                                    autoComplete="current-password"
                                    autoFocus
                                    required
                                    fullWidth
                                    variant="filled"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    color={error?.password ? 'error' : 'primary'}
                                    value={loginData?.password}
                                    onChange={handleChangeValue}
                                />
                            </FormControl>

                            <FormControl>
                                {/* <FormLabel htmlFor="password">Password</FormLabel> */}
                                <TextField
                                    label="Confirm Password"
                                    error={error?.confirmPassword && error?.confirmPassword}
                                    helperText={error?.confirmPassword && error?.confirmPassword}
                                    name="confirmPassword"
                                    placeholder="••••••"
                                    type={showConfirmPassword ? "text" : "password"}
                                    id="confirmPassword"
                                    autoComplete="current-password"
                                    autoFocus
                                    required
                                    fullWidth
                                    variant="filled"
                                    // slotProps={{
                                    //     input: {
                                    //         inputProps: {
                                    //             endAdornment: (
                                    //                 <InputAdornment position="end">
                                    //                     <IconButton
                                    //                         aria-label="toggle password visibility"
                                    //                         onClick={handleClickShowConfirmPassword}
                                    //                         edge="end"
                                    //                     >
                                    //                         {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                    //                     </IconButton>
                                    //                 </InputAdornment>
                                    //             ),
                                    //         }
                                    //     }
                                    // }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowConfirmPassword}
                                                    edge="end"
                                                >
                                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    color={error?.confirmPassword ? 'error' : 'primary'}
                                    value={loginData?.confirmPassword}
                                    onChange={handleChangeValue}
                                />
                            </FormControl>
                        </Box>

                        <Button
                            size="medium"
                            type="submit"
                            fullWidth
                            color="secondary"
                            variant="contained"
                            disabled={loading}
                            onClick={validateInputs}
                            sx={{ color: '#fff' }}
                        >
                            {loading ? <CircularProgress size={20} /> : "Continue"}
                        </Button>

                    </Box>


                </AuthCardComponent>
            </MainContainer>
        </Box>
    );
}