import axios from "axios";
import { MAIN_URL, REQUEST_URL } from "../../constant/ApplicationVariables";
import { axiosInstance } from "../useAxios/useAxios";


export const getModuleList_API = async () => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.folder,
            method: "GET",
            // data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
} 


export const addModule_API = async (body) => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.folder,
            method: "POST",
            data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}

export const updateModule_API = async (body) => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.folder,
            method: "PUT",
            data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}

export const deleteModule_API = async (body) => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.folder ,
            method: "DELETE",
            data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}


export const getFolderItemsForLink_API = async (query) => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.folderLinkItems + `?${query}`,
            method: "GET",
            // data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}