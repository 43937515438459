import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { Checkbox, CircularProgress, Divider, FormControl, IconButton, InputLabel, Menu, MenuItem, Paper, Popover, Select, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, ToggleButton, Typography, useTheme } from '@mui/material';
import { ArrowRight, Close, Description, DescriptionOutlined, Edit, FolderOutlined, FolderSpecial, KeyboardArrowDown, KeyboardArrowRight, SellOutlined } from '@mui/icons-material';
import { font } from '../../styles/font';
import { forwardRef, useEffect, useState } from 'react';
import { useApplicationContext } from '../../context/ApplicationContext';
import { getProjectCategoryList_API, getProjectList_API, getProjectSubcategoryList_API, updateProject_API } from '../../api/controller/ProjectController';
import { HttpStatusCode } from 'axios';
import { Entity, FolderType, ModulePath, Severity } from '../../constant/ApplicationVariables';
import useErrorHandler from '../../api/errorHandler/useErrorHandler';
import TagIcon from '@mui/icons-material/Tag';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Tree } from 'react-arborist';
import { LightTooltip } from '../styled/CustomComponent';
import { getUseCaseItemsForLink_API, getUseCaseList_API, updateUseCase_API } from '../../api/controller/UseCaseController';
import { getStoryList_API, getUserStoryItemsForLink_API, updateStory_API } from '../../api/controller/StoryController';
import { useLocation } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { TableVirtuoso } from 'react-virtuoso';
import { grey } from '@mui/material/colors';
import { getFolderItemsForLink_API } from '../../api/controller/ModuleController';
import { getTestCaseList_API, updateTestCase_API } from '../../api/controller/TestCaseController';
import { TestCaseCustomIcon, UseCaseCustomIcon, UserStoryCustomIcon } from '../styled/CustomIcons';

class Entity_Label {
    static useCase = "Use Case";
    static userStory = "User Story";
    static testCase = "Test Case";
}


class LocalEntity {
    static userStory = Entity.userStory;
    static useCase = Entity.useCase;
    static testCase = Entity.testCase;
    static folder = Entity.folder;
}

const TableComponents = {
    Scroller: forwardRef((props, ref) => <TableContainer component={Paper} style={{ overflowX: "auto", overflowY: "scroll" }} {...props} ref={ref} />),
    Table: (props) => <Table {...props} style={{ borderCollapse: 'separate' }} />,
    TableHead: TableHead,
    TableRow: (props) => <TableRow {...props} />,
    TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
    TableCell: (props) => <TableCell {...props} sx={{ ...props?.sx, px: "1rem !important" }} />,
}

export default function LinkTagDrawer({ open, handleClose, }) {
    const {
        currentFolderData,
        setProjectList,
        handleSnackbar,
        toggleChatWindow,
        folderStructureList, setFolderStructureList,
        useCaseList, setUseCaseList,
        userStoryList, setUserStoryList,
        currentUseCaseData, setCurrentUseCaseData,
        currentUserStoryData, setCurrentUserStoryData,
    } = useApplicationContext();
    const { activeProject, setActiveProject, } = useApplicationContext();
    const errorHandler = useErrorHandler();
    const theme = useTheme();
    const pathName = useLocation()?.pathname;

    const [activeTab, setActiveTab] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [activeLinkTypeTab, setActiveLinkTypeTab] = useState(Entity_Label.useCase); // To store the active link type
    const [linkTypeTabsList, setLinkTypeTabsList] = useState([]); // To store the link type tabs list
    const [isEditMode, setIsEditMode] = useState(false); // To enable the edit mode

    const [currentProjectItemData, setCurrentProjectItemData] = useState(null); // to story the current userstory/ usecase/ testcase
    const [completeLinkItemsData, setCompleteLinkItemsData] = useState(null); // to store the complete folder structure data of userStories[], useCases[], testCases[]
    const [itemsList, setItemsList] = useState([]); // to show the folder structure
    const [selectedItemsList, setSelectedItemsList] = useState([]); // to make section of checkbox
    const [alreadyLinkedItemsList, setAlreadyLinkedItemsList] = useState([]); // list is to store the already linked items list



    const columns = [
        {
            data: "S.No.",
            padding: "none",
            align: "center",
            background: theme.palette.header.main,
            width: "42px"
        },
        {
            data: "Custom ID",
            padding: "none",
            align: "center",
            background: theme.palette.header.main,
            width: "100px"
        },
        {
            data: "Title",
            padding: "none",
            align: "left",
            background: theme.palette.header.main,
            width: "150px"
        },
        {
            data: "Folder",
            padding: "none",
            align: "left",
            background: theme.palette.header.main,
            width: "100px"
        },

    ];

    const toggleDrawer = () => {
        handleClose();
    };

    const handleUpdateSelectedAndAlreadyLinkedList = (data) => {
        if (data) {

            if (activeLinkTypeTab === Entity_Label.useCase) {
                setSelectedItemsList(data?.useCases);
                setAlreadyLinkedItemsList(data?.useCases);
            }

            if (activeLinkTypeTab === Entity_Label.userStory) {
                setSelectedItemsList(data?.userStories);
                setAlreadyLinkedItemsList(data?.userStories);
            }

            if (activeLinkTypeTab === Entity_Label.testCase) {
                setSelectedItemsList(data?.testCases);
                setAlreadyLinkedItemsList(data?.testCases);
            }
        }
    }


    // handle the checkbox in the tree view
    const handleChangeSelection = (e, itemData) => {
        console.log(itemData, "itemDatatop", e.target.value)

        if (itemData?.type === LocalEntity.folder) {
            console.log(itemData, "itemDatatop folder")
            if (e.target.checked) {

                let selectedList = [...selectedItemsList];
                selectedList = selectedList.concat(addChildren(itemData, selectedList));
                setSelectedItemsList(selectedList);

                console.log("folder result list add", selectedList, selectedItemsList)
            } else {

                let selectedList = [...selectedItemsList];
                selectedList = removeChildren(itemData, selectedList);
                setSelectedItemsList(selectedList);

                console.log("folder result list remove", selectedList, selectedItemsList)
            }
        } else {
            console.log(itemData, "itemDatatop items")
            if (e.target.checked) {
                let selectedList = [...selectedItemsList];

                let foundIndex = selectedList?.findIndex((data) => data?.customId === itemData?.customId && data?.folderPath === itemData?.folderPath);

                if (foundIndex === -1) {
                    selectedList.push(itemData);
                }

                setSelectedItemsList(selectedList);
                console.log("item result list add", selectedList, selectedItemsList)


            } else {
                let list = selectedItemsList;
                list = list?.filter((data) => !(data.customId === itemData.customId && data.folderPath === itemData.folderPath));
                setSelectedItemsList(list);

                console.log("item result list remove", list, selectedItemsList, itemData, e.target.value)
            }
        }
    }

    function addChildren(data, selectedList) {
        let list = [];
        data?.children?.forEach((item) => {
            if (item?.type === LocalEntity.folder) {
                list = list.concat(addChildren(item, selectedList));
            } else {
                let foundIndex = selectedList?.findIndex((data) =>
                    data?.customId === item?.customId &&
                    data?.folderPath === item?.folderPath
                );
                if (foundIndex === -1) {
                    list.push(item);
                }
            }
        });
        return list;
    }

    function removeChildren(data, list) {
        let mainList = [...list];

        data?.children?.forEach((item) => {
            if (item?.type === LocalEntity.folder) {
                mainList = removeChildren(item, mainList);
            } else {
                mainList = mainList.filter((selectedItem) =>
                    !(selectedItem.customId === item.customId && selectedItem.folderPath === item.folderPath)
                );
            }
        });

        return mainList;
    }

    function convertListFormat(selectedItemsList) {
        const result = {};
        selectedItemsList.forEach((item) => {
            if (!result[item.folderPath]) {
                result[item.folderPath] = [];
            }
            result[item.folderPath].push(item.customId);

        });
        return Object.keys(result).map((key) => ({
            folderPath: key,
            customIds: result[key],
        }));
    }

    // handle click on save
    const handleContinueClick = () => {

        let body = {};
        body.customIds = [currentProjectItemData?.customId];
        body.folderPath = currentProjectItemData?.folderPath;
        body.projectName = activeProject?.name;


        // linkUseCases : [  {"folderPath" : "default", "customIds": ["US_01", "US_2"] }  ]
        // linkUserStories {"folderPath" : "default", "customIds": ["US_01", "US_2"] }  ]

        if (pathName === ModulePath.requirements) {

            // body.useCaseIds = [...selectedItemsList?.map((item) => item?.customId)];

            // body.acceptanceCriteria = currentProjectItemData?.acceptanceCriteria;

            console.log(selectedItemsList, "selectedItemsList_before")

            let convertedFormat = convertListFormat(selectedItemsList)?.length > 0 ? convertListFormat(selectedItemsList) : [];
            console.log(selectedItemsList, "selectedItemsList_after")

            if (activeLinkTypeTab === Entity_Label.useCase) {
                body.linkUseCases = convertedFormat;
            }

            if (activeLinkTypeTab === Entity_Label.userStory) {
                body.linkUserStories = convertedFormat;
            }

            if (activeLinkTypeTab === Entity_Label.testCase) {
                body.linkTestCases = convertedFormat;
            }


            console.log(body, "bodyinlink")
            handleUpdateStory(body);
            setIsEditMode(false);
            // handleContinue(body);
        }

        if (pathName === ModulePath.useCase) {

            // body.userStoryIds = [...selectedItemsList?.map((item) => item?.customId)];
            // body.linkUserStories = convertListFormat(selectedItemsList);
            // body.steps = currentProjectItemData?.useCaseSteps;


            if (activeLinkTypeTab === Entity_Label.useCase) {
                body.linkUseCases = convertListFormat(selectedItemsList);
            }

            if (activeLinkTypeTab === Entity_Label.userStory) {
                body.linkUserStories = convertListFormat(selectedItemsList);
            }

            if (activeLinkTypeTab === Entity_Label.testCase) {
                body.linkTestCases = convertListFormat(selectedItemsList);
            }


            handleUpdateUseCase(body);
            setIsEditMode(false);
            // handleContinue(body);
        }


        if (pathName === ModulePath.testCase) {

            // body.userStoryIds = [...selectedItemsList?.map((item) => item?.customId)];
            // body.linkUserStories = convertListFormat(selectedItemsList);
            // body.steps = currentProjectItemData?.useCaseSteps;


            if (activeLinkTypeTab === Entity_Label.useCase) {
                body.linkUseCases = convertListFormat(selectedItemsList);
            }

            if (activeLinkTypeTab === Entity_Label.userStory) {
                body.linkUserStories = convertListFormat(selectedItemsList);
            }

            if (activeLinkTypeTab === Entity_Label.testCase) {
                body.linkTestCases = convertListFormat(selectedItemsList);
            }


            handleUpdateTestCase(body);
            setIsEditMode(false);
            // handleContinue(body);
        }
    }

    // handle get user story items for linking
    const handleGetUserStoryItemsForLink = async () => {
        try {
            setIsLoading(true);

            let query = `name=${activeProject?.name}`

            const response = await getUserStoryItemsForLink_API(query);
            console.log(response, "response handleGetUserStoryList")

            if (response) {
                errorHandler(response, setIsLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                setItemsList(response.data);
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
        }
    }

    // handle get use case items for linking
    const handleGetItemsForLink = async () => {
        try {
            setIsLoading(true);

            let query = `name=${activeProject?.name}`

            const response = await getFolderItemsForLink_API(query);
            console.log(response, "response handleGetUseCaseList")

            if (response) {
                errorHandler(response, setIsLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                setCompleteLinkItemsData(response.data);

                // setItemsList(response.data);
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
        }
    }

    // handle get use case list
    const handleGetUseCaseList = async () => {
        try {
            setIsLoading(true);

            let body = {
                "folderPath": currentFolderData?.path,
                "projectName": activeProject?.name
            }

            const response = await getUseCaseList_API(body);
            console.log(response, "response handleGetUseCaseList")

            if (response) {
                errorHandler(response, setIsLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                setUseCaseList(response.data);

                const foundItem = response.data?.find(item => item?.customId === currentProjectItemData?.customId);

                if (foundItem) {
                    setCurrentProjectItemData(foundItem);
                    setCurrentUseCaseData(foundItem);

                    setSelectedItemsList([...foundItem?.userStories?.map((item) => item?.customId)]);
                    setAlreadyLinkedItemsList(foundItem?.userStories);
                }
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
        }
    }

    const handleGetTestCaseList = async () => {
        try {
            setIsLoading(true);

            let body = {
                "folderPath": currentFolderData?.path,
                "projectName": activeProject?.name
            }

            const response = await getTestCaseList_API(body);
            console.log(response, "response handleGetUseCaseList")

            if (response) {
                errorHandler(response, setIsLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                setUseCaseList(response.data);

                const foundItem = response.data?.find(item => item?.customId === currentProjectItemData?.customId);

                if (foundItem) {
                    setCurrentProjectItemData(foundItem);
                    setCurrentUseCaseData(foundItem);

                    setSelectedItemsList([...foundItem?.userStories?.map((item) => item?.customId)]);
                    setAlreadyLinkedItemsList(foundItem?.userStories);
                }
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
        }
    }

    // handle get user story list
    const handleGetUserStoryList = async () => {
        try {
            setIsLoading(true);

            let body = {
                "folderPath": currentFolderData?.path,
                "projectName": activeProject?.name
            }

            const response = await getStoryList_API(body);
            console.log(response, "response handleGetUserStoryList")

            if (response) {
                errorHandler(response, setIsLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                setUserStoryList(response.data);

                const foundItem = response.data?.find(item => item?.customId === currentProjectItemData?.customId);

                if (foundItem) {
                    setCurrentProjectItemData(foundItem);
                    setCurrentUserStoryData(foundItem);

                    setSelectedItemsList([...foundItem?.useCases?.map((item) => item?.customId)]);
                    setAlreadyLinkedItemsList(foundItem?.useCases);
                }


            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
        }
    }

    // handle update use case
    const handleUpdateUseCase = async (storyData) => {
        try {
            setIsLoading(true);

            let body = {
                ...storyData,
            }

            const bodyFormData = new FormData();

            // Object.keys(body).forEach(key => bodyFormData.append(key, body[key]));
            Object.keys(body).forEach(key => {
                if (Array.isArray(body[key])) {
                    if (key === "attachments") {
                        body[key]?.forEach((file, index) => {
                            bodyFormData.append(`${key}[${index}]`, file, file.name);
                        })
                    } else if (key === "linkUseCases" || key === "linkUserStories") {
                        body[key]?.forEach((data, index) => {
                            bodyFormData.append(`${key}[${index}].${"folderPath"}`, data?.folderPath);
                            data?.customIds?.forEach((cId, cIndex) => {
                                return bodyFormData.append(`${key}[${index}].${"customIds"}[${cIndex}]`, cId);
                            })
                        })
                    } else {
                        bodyFormData.append(key, body[key]);
                    }

                    // bodyFormData.append(key, body[key]);
                } else {
                    bodyFormData.append(key, body[key]);
                }
            });


            const response = await updateUseCase_API(bodyFormData);
            console.log(response, "response handleUpdateStory")

            if (response) {
                errorHandler(response, setIsLoading);
            }

            if (response?.status === HttpStatusCode.Ok) {
                // handleGetUseCaseList();



                let previousData = currentUseCaseData;
                let updatedData = response.data[0];


                setCurrentUseCaseData(updatedData);
                setCurrentProjectItemData(updatedData);

                handleUpdateSelectedAndAlreadyLinkedList(updatedData)
                // setSelectedItemsList(updatedData?.userStories);
                // setAlreadyLinkedItemsList(updatedData?.userStories);


                let list = useCaseList;
                const foundIndex = list?.findIndex(item => item?.customId === previousData?.customId);
                if (foundIndex !== -1) {
                    list[foundIndex] = updatedData;
                }
                setUseCaseList(list);
            }


            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
        }
    }

    const handleUpdateTestCase = async (storyData) => {
        try {
            setIsLoading(true);

            let body = {
                ...storyData,
            }

            const bodyFormData = new FormData();

            // Object.keys(body).forEach(key => bodyFormData.append(key, body[key]));
            Object.keys(body).forEach(key => {
                if (Array.isArray(body[key])) {
                    if (key === "attachments") {
                        body[key]?.forEach((file, index) => {
                            bodyFormData.append(`${key}[${index}]`, file, file.name);
                        })
                    } else if (key === "linkUseCases" || key === "linkUserStories" || key === "linkTestCases") {
                        body[key]?.forEach((data, index) => {
                            bodyFormData.append(`${key}[${index}].${"folderPath"}`, data?.folderPath);
                            data?.customIds?.forEach((cId, cIndex) => {
                                return bodyFormData.append(`${key}[${index}].${"customIds"}[${cIndex}]`, cId);
                            })
                        })
                    } else {
                        bodyFormData.append(key, body[key]);
                    }

                    // bodyFormData.append(key, body[key]);
                } else {
                    bodyFormData.append(key, body[key]);
                }
            });


            const response = await updateTestCase_API(bodyFormData);
            console.log(response, "response handleUpdateStory")

            if (response) {
                errorHandler(response, setIsLoading);
            }

            if (response?.status === HttpStatusCode.Ok) {
                // handleGetTestCaseList();


                let previousData = currentUseCaseData;
                let updatedData = response.data[0];


                setCurrentUseCaseData(updatedData);
                setCurrentProjectItemData(updatedData);

                handleUpdateSelectedAndAlreadyLinkedList(updatedData)
                // setSelectedItemsList(updatedData?.userStories);
                // setAlreadyLinkedItemsList(updatedData?.userStories);


                let list = useCaseList;
                const foundIndex = list?.findIndex(item => item?.customId === previousData?.customId);
                if (foundIndex !== -1) {
                    list[foundIndex] = updatedData;
                }
                setUseCaseList(list);
            }


            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
        }
    }
    // handle update story
    const handleUpdateStory = async (storyData) => {
        try {
            setIsLoading(true);

            let body = {
                ...storyData,
            }

            const bodyFormData = new FormData();

            // linkUserStories : [  {"folderPath" : "default", "customIds": ["US_01", "US_2"] }  ]
            // Object.keys(body).forEach(key => bodyFormData.append(key, body[key]));

            Object.keys(body).forEach(key => {
                if (Array.isArray(body[key])) {
                    if (key === "attachments") {
                        body[key]?.forEach((file, index) => {
                            bodyFormData.append(`${key}[${index}]`, file, file.name);
                        })
                    } else if (key === "linkUseCases" || key === "linkUserStories" || key === "linkTestCases") {
                        body[key]?.forEach((data, index) => {
                            bodyFormData.append(`${key}[${index}].${"folderPath"}`, data?.folderPath);
                            data?.customIds?.forEach((cId, cIndex) => {
                                return bodyFormData.append(`${key}[${index}].${"customIds"}[${cIndex}]`, cId);
                            })
                        })
                    } else {
                        bodyFormData.append(key, body[key]);
                    }

                    // bodyFormData.append(key, body[key]);
                } else {
                    bodyFormData.append(key, body[key]);
                }
            });


            const response = await updateStory_API(bodyFormData);
            console.log(response, "response handleUpdateStory")

            if (response) {
                errorHandler(response, setIsLoading);
            }

            if (response?.status === HttpStatusCode.Ok) {
                // handleGetUserStoryList();


                let previousData = currentUserStoryData;
                let updatedData = response.data[0];


                setCurrentUserStoryData(updatedData);
                setCurrentProjectItemData(updatedData);

                handleUpdateSelectedAndAlreadyLinkedList(updatedData)
                // setSelectedItemsList(updatedData?.useCases);
                // setAlreadyLinkedItemsList(updatedData?.useCases);


                let list = userStoryList;
                const foundIndex = list?.findIndex(item => item?.customId === previousData?.customId);
                if (foundIndex !== -1) {
                    list[foundIndex] = updatedData;
                    // list.splice(foundIndex, 1, updatedData)
                }
                setUserStoryList(list);


            }


            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
        }
    }

    // to get the already selected/linked data, fetch folder structure list - old
    // useEffect(() => {
    //     handleGetItemsForLink();

    //     if (pathName === ModulePath.requirements) {
    //         // handleGetUserStoryItemsForLink();
    //         setSelectedItemsList(currentUserStoryData?.useCases);
    //         setAlreadyLinkedItemsList(currentUserStoryData?.useCases);
    //     }

    //     if (pathName === ModulePath.useCase) {
    //         // handleGetUseCaseItemsForLink();
    //         // setSelectedItemsList(currentUserStoryData?.userStoryIds)
    //         setSelectedItemsList(currentUseCaseData?.userStories);
    //         setAlreadyLinkedItemsList(currentUseCaseData?.userStories);
    //     }

    //     if (pathName === ModulePath.testCase) {
    //         // handleGetUseCaseItemsForLink();
    //         // setSelectedItemsList(currentUserStoryData?.userStoryIds)
    //         setSelectedItemsList(currentUseCaseData?.userStories);
    //         setAlreadyLinkedItemsList(currentUseCaseData?.userStories);
    //     }
    // }, [currentUserStoryData, currentUseCaseData, pathName]);




    // to get the already selected/linked data, fetch folder structure list
    useEffect(() => {
        handleGetItemsForLink();

        if (currentProjectItemData) {
            handleUpdateSelectedAndAlreadyLinkedList(currentProjectItemData)
        }

    }, [currentProjectItemData, pathName, activeLinkTypeTab]);






    // to make the allowed linking types list according to current item
    useEffect(() => {
        let list = []
        if (pathName === ModulePath.requirements) {
            list = [
                Entity_Label.useCase,
                Entity_Label.testCase
            ]
            setActiveLinkTypeTab(Entity_Label.useCase);
        }
        if (pathName === ModulePath.useCase) {
            list = [
                Entity_Label.userStory,
                Entity_Label.testCase
            ]
            setActiveLinkTypeTab(Entity_Label.userStory);
        }
        if (pathName === ModulePath.testCase) {
            list = [
                Entity_Label.userStory,
                Entity_Label.useCase
            ]
            setActiveLinkTypeTab(Entity_Label.userStory);
        }

        setLinkTypeTabsList(list);
    }, [pathName])

    // useEffect(() => {
    //     if (activeLinkTypeTab === Entity_Label.useCase && currentUserStoryData?.useCases?.length > 0) {
    //         handleGetUseCaseItemsForLink();
    //         setSelectedItemsList(currentUserStoryData?.useCases?.map((item) => item?.customId) ?? []);
    //     }

    //     if (activeLinkTypeTab === Entity_Label.userStory && currentUseCaseData?.userStories?.length > 0) {
    //         handleGetUserStoryItemsForLink();
    //         setSelectedItemsList(currentUseCaseData?.userStories?.map((item) => item?.customId) ?? []);
    //     }
    // }, [activeLinkTypeTab, currentUserStoryData, currentUseCaseData]);


    // To store the current item data object having list of userStories, useCases, testCases
    useEffect(() => {
        if (pathName === ModulePath.requirements) {
            setCurrentProjectItemData(currentUserStoryData);
        }
        if (pathName === ModulePath.useCase || pathName === ModulePath.testCase) {
            setCurrentProjectItemData(currentUseCaseData);
        }
    }, [pathName, currentUserStoryData, currentUseCaseData]);


    // to get the folder list for active tab
    useEffect(() => {
        if (completeLinkItemsData) {
            if (activeLinkTypeTab === Entity_Label.useCase) {
                setItemsList(completeLinkItemsData["useCases"]);
            }

            if (activeLinkTypeTab === Entity_Label.userStory) {
                setItemsList(completeLinkItemsData["userStories"]);
            }

            if (activeLinkTypeTab === Entity_Label.testCase) {
                setItemsList(completeLinkItemsData["testCases"]);
            }
        }

    }, [completeLinkItemsData, activeLinkTypeTab]);



    console.log(selectedItemsList, "selectedItemsListist");
    console.log(itemsList, "itemsList");
    console.log(currentProjectItemData, "currentProjectItemData", currentUserStoryData, currentUseCaseData)

    const DrawerList = (
        <Box sx={{ width: "100%", height: "100%" }} role="presentation" className="flex flex-col h-full ">
            {/* header */}
            <Box className="flex items-start px-4 py-2 border-b-2">
                <Box className="flex-1 flex flex-col gap-2">

                    <Box className="flex-1 flex items-center gap-2">
                        <Typography fontSize={font.size.fs_4} className="flex-1 flex items-center gap-1">
                            Link -
                            <Typography fontSize={font.size.fs_4} >
                                {
                                    pathName === ModulePath.requirements
                                        ? Entity_Label.userStory
                                        : pathName === ModulePath.useCase
                                            ? Entity_Label.useCase
                                            : Entity_Label.testCase
                                }
                            </Typography>
                            {/* -
                            <Description color="primary" sx={{ fontSize: font.size.fs_4 }} /> 
                    {currentProjectItemData?.customId} */}

                        </Typography>



                        <ToggleButton
                            size="small"
                            value="check"
                            selected={isEditMode}
                            onClick={() => setIsEditMode(prev => !prev)}
                        >
                            <Edit />
                        </ToggleButton>
                        {/* <IconButton onClick={() => setIsEditMode(prev => !prev)}><Edit /></IconButton> */}
                        <IconButton onClick={() => toggleDrawer()}><Close /></IconButton>
                    </Box>



                    <Typography fontSize={font.size.fs_5} className=" border-l-2 pl-2 py-2 bg-gray-50">
                        <Typography fontSize={font.size.fs_5} className="flex items-center gap-1" sx={{ color: theme.palette.secondary.main }} >
                            {/* <Description sx={{ fontSize: font.size.fs_4, color: "inherit" }} /> */}
                            {
                                pathName === ModulePath.requirements
                                    ? <UserStoryCustomIcon sx={{color: "inherit" }} />
                                    : pathName === ModulePath.useCase
                                        ? <UseCaseCustomIcon sx={{color: "inherit" }} />
                                        : <TestCaseCustomIcon  sx={{color: "inherit" }}  />
                            }
                            {currentProjectItemData?.customId}
                        </Typography>
                        {currentProjectItemData?.title}
                    </Typography>
                </Box>

            </Box>

            {/* main container */}
            <Box className="flex-1 flex flex-col gap-4 px-4 py-4 relative">

                {isLoading && <Box className="absolute left-0 top-0 w-full h-full z-10 flex flex-col items-center justify-center backdrop-blur-sm">
                    <CircularProgress />
                </Box>}

                <Box className=" flex flex-col gap-2">
                    {/* <Box className="flex items-center justify-center gap-1  ">
                        {
                            ["Link", "Tag"].map((item, index) => (
                                <Button
                                    key={index}
                                    onClick={() => setActiveTab(index)}
                                    disabled={index === 1}
                                    sx={{
                                        background: activeTab === index ? theme.palette.primary.main : "transparent",
                                        color: activeTab === index ? theme.palette.primary.contrastText : theme.palette.primary.main
                                    }}
                                >{item}</Button>
                            ))
                        }
                    </Box>

                    <Divider /> */}

                    <Box className="flex flex-col gap-2">


                        <Box className="flex items-center  gap-1">
                            {
                                linkTypeTabsList.map((item, index) => (
                                    <Button
                                        key={index}
                                        onClick={() => setActiveLinkTypeTab(item)}
                                        // disabled={item === Entity_Label.testCase}
                                        sx={{
                                            border: activeLinkTypeTab === item ? `1px solid ${theme.palette.primary.main}` : "transparent",
                                            color: theme.palette.primary.main,
                                            px: 2
                                        }}
                                    >
                                        {item}
                                    </Button>
                                ))
                            }
                        </Box>


                    </Box>
                </Box>



                {/* main container */}
                <Box className="flex-1 flex flex-col gap-4 ">


                    {/* items list container */}
                    <Box className="flex-1 flex flex-col">
                        {isEditMode ?
                            itemsList?.length === 0 ?
                                <Box className="flex-1 flex items-center justify-center">
                                    <Typography fontSize={font.size.fs_4} className="flex items-center gap-1">No {activeLinkTypeTab} linked </Typography>
                                </Box>
                                :
                                (
                                    <Box className="flex-1 flex flex-col">
                                        <AutoSizer>
                                            {({ height, width }) => {
                                                console.log(height, width, "heightwidth")
                                                return (
                                                    <Tree
                                                        initialData={itemsList}
                                                        openByDefault={true}
                                                        width={width}
                                                        height={height}
                                                        indent={24}
                                                        rowHeight={48}
                                                    // overscanCount={1}
                                                    // paddingTop={30}
                                                    // paddingBottom={10}
                                                    // padding={25 /* sets both */}
                                                    >
                                                        {({ node, style, dragHandle }) => (
                                                            <Node node={node} style={style} dragHandle={dragHandle} selectedItemsList={selectedItemsList} handleChangeSelection={handleChangeSelection} />
                                                        )}
                                                    </Tree>

                                                )
                                            }}
                                        </AutoSizer>
                                    </Box>
                                )
                            :
                            (
                                <Box className="flex-1 flex flex-col">
                                    {alreadyLinkedItemsList?.length === 0 ?
                                        <Box className="flex-1 flex items-center justify-center">
                                            <Typography fontSize={font.size.fs_4} className="flex items-center gap-1">No {activeLinkTypeTab} linked  </Typography>
                                        </Box>
                                        :
                                        <Box className="flex-1 flex flex-col">
                                            <Box className="flex-1 flex flex-col">
                                                <TableVirtuoso
                                                    style={{ height: "100%", width: "100%", overflowX: 'auto' }}
                                                    data={alreadyLinkedItemsList}
                                                    components={TableComponents}
                                                    fixedHeaderContent={() => (
                                                        <TableHead>
                                                            {
                                                                columns?.map((item, index) => (
                                                                    <TableCell key={index} align={item?.align} padding={item?.padding} className="whitespace-nowrap" style={{ margin: "0 1rem !important", fontWeight: "bold", minWidth: item?.width, maxWidth: item?.width, background: item?.background, borderRight: index === columns?.length - 1 ? "none" : `1px solid ${grey[300]}` }}>
                                                                        <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap py-1 px-2"> {item?.data}</Typography>
                                                                    </TableCell>
                                                                ))
                                                            }
                                                        </TableHead>
                                                    )}


                                                    itemContent={(index, itemData) => (

                                                        <TableRow sx={{ "&:hover": { background: grey[100], } }}>

                                                            <TableCell padding="none" align="center" sx={{ minWidth: "42px", maxWidth: "42px", borderRight: `1px solid ${grey[300]}` }} >
                                                                <Typography className="py-1">{index + 1}.</Typography>
                                                            </TableCell>

                                                            <TableCell padding="none" align="center" sx={{ minWidth: "100px", maxWidth: "100px", borderRight: `1px solid ${grey[300]}` }} >
                                                                <Typography className="line-clamp-1 px-2 py-1">#{itemData?.customId}</Typography>
                                                            </TableCell>

                                                            <TableCell padding="none" align="left" sx={{ minWidth: "150px", maxWidth: "150px", borderRight: `1px solid ${grey[300]}` }} >
                                                                <LightTooltip title={<Typography className=""> {itemData?.title}</Typography>} placement="left">
                                                                    <Typography noWrap className="px-2 truncate py-1"> {itemData?.title}</Typography>
                                                                </LightTooltip>
                                                            </TableCell>

                                                            <TableCell padding="none" align="left" sx={{ minWidth: "100px", maxWidth: "100px", }} >
                                                                <LightTooltip title={<Typography className=""> {itemData?.folderPath}</Typography>} placement="left">
                                                                    <Typography noWrap className="px-2 truncate py-1"> {itemData?.folderPath}</Typography>
                                                                </LightTooltip>
                                                            </TableCell>

                                                        </TableRow>

                                                    )}
                                                />




                                            </Box>
                                        </Box>
                                    }
                                </Box>
                            )
                        }
                    </Box>

                </Box>
            </Box>

            {/* footer */}
            <Box className="flex flex-col gap-4 px-4 py-4 border-t-2">
                <Button variant="contained" color="secondary" sx={{ color: theme.palette.secondary.contrastText }} disabled={isLoading || !currentProjectItemData || !isEditMode} onClick={() => handleContinueClick()} >Save</Button>
                {/* <Button variant="outlined" color="error" disabled={isLoading } onClick={() => toggleDrawer()}>Discard</Button> */}
            </Box>

        </Box>
    );

    return (
        <Box className="flex flex-col h-full box-border " borderRadius={theme.shape.borderRadius} bgcolor={theme.palette.background.default}>
            <Drawer
                open={open}
                // onClose={handleClose}
                onClose={() => toggleDrawer()}
                // disablePortal
                // hideBackdrop
                anchor="right"
                className="flex flex-col gap-0 h-full"
                PaperProps={{
                    // width: "420px",
                    // maxWidth: "42vw",
                    style: {
                        marginRight: toggleChatWindow ? "30%" : "0%",
                        borderRadius: 0,
                        width: "420px"
                    }
                }}
                sx={{ width: "420px" }}
            >
                {DrawerList}
            </Drawer>
        </Box>
    );
}

function Node({ node, style, dragHandle, selectedItemsList, handleChangeSelection }) {
    const { activeProject, isUpdateFolderStructure, setIsUpdateFolderStructure, currentFolderAnchorEl, setCurrentFolderAnchorEl } = useApplicationContext();
    const theme = useTheme();



    const handleFolderClick = (folder) => {
    }

    const handleIconClick = (folder) => {
        folder.toggle();
    }

    const handleRightClick = (event, folder) => {
        event.preventDefault();
    }

    const checkChecked = () => {
        let isChecked = false;
        let isInderminate = false;

        if (node.data.type === LocalEntity.folder) {
            isChecked = checkFolderItems(node?.data)?.isChecked;
            isInderminate = checkFolderItems(node?.data)?.isIndeterminated;
            // isChecked = node?.data?.children?.every((child) => selectedItemsList?.includes(child?.customId));
            // isInderminate = selectedItemsList?.some((item) => node?.data?.children?.some((child) => child?.customId === item)) && !isChecked

        } else {
            let foundItem = selectedItemsList?.find((data) => data?.customId === node?.data?.customId && data?.folderPath === node?.data?.folderPath);
            isChecked = Boolean(foundItem);
            isInderminate = false
        }

        return { isChecked, isInderminate }
    }


    function checkFolderItems(folder) {
        let totalItemsCount = 0;
        let checkedItemsCount = 0;
        let isIndeterminated = false;

        if (folder?.children?.length === 0) {
            return { isChecked: false, isIndeterminated: false, totalItemsCount: 0, checkedItemsCount: 0 };
        }

        folder?.children?.forEach((item) => {
            if (item?.type === LocalEntity.folder) {
                const {
                    totalItemsCount: childTotalCount,
                    checkedItemsCount: childCheckedCount
                } = checkFolderItems(item);
                totalItemsCount += childTotalCount;
                checkedItemsCount += childCheckedCount;
                if (childCheckedCount > 0 && childCheckedCount < childTotalCount) {
                    isIndeterminated = true;
                }
            } else {
                totalItemsCount++;
                const foundItem = selectedItemsList?.find((data) =>
                    data?.customId === item?.customId &&
                    data?.folderPath === item?.folderPath
                );
                if (foundItem) {
                    checkedItemsCount++;
                }
            }
        });

        const isChecked = totalItemsCount === checkedItemsCount;
        if (checkedItemsCount > 0 && checkedItemsCount < totalItemsCount) {
            isIndeterminated = true;
        }

        return {
            isChecked,
            isIndeterminated,
            totalItemsCount,
            checkedItemsCount
        };
    }

    function checkFolderItems1(folder) {
        let totalItemsCount = 0;
        let checkedItemsCount = 0;
        let isIndeterminated = false;

        folder?.children?.forEach((item) => {
            if (item?.type === LocalEntity.folder) {
                const { totalItemsCount: childTotalCount, checkedItemsCount: childCheckedCount } = checkFolderItems(item);
                totalItemsCount += childTotalCount;
                checkedItemsCount += childCheckedCount;
                if (childCheckedCount > 0 && childCheckedCount < childTotalCount) {
                    isIndeterminated = true;
                }
            } else {
                totalItemsCount++;
                const foundItem = selectedItemsList?.find((data) =>
                    data?.customId === item?.customId &&
                    data?.folderPath === item?.folderPath
                );
                if (foundItem) {
                    checkedItemsCount++;
                }
            }
        });

        const isChecked = totalItemsCount === checkedItemsCount;
        if (checkedItemsCount > 0 && checkedItemsCount < totalItemsCount) {
            isIndeterminated = true;
        }

        return { isChecked, isIndeterminated, totalItemsCount, checkedItemsCount };
    }

    function checkFolderItems2(folder) {
        let totalItemsCount = 0;
        let checkedItemsCount = 0;
        let isChecked = true;
        let isIndeterminated = checkedItemsCount > 0 && checkedItemsCount < totalItemsCount;

        folder?.children?.forEach((item) => {
            if (item?.type === LocalEntity.folder) {
                const { isChecked: childChecked, isIndeterminated: childIndeterminated } = checkFolderItems(item);
                if (!childChecked) {
                    isChecked = false;
                }
                if (childIndeterminated) {
                    isIndeterminated = true;
                }
            } else {
                const foundItem = selectedItemsList?.find((data) => data?.customId === item?.customId && data?.folderPath === item?.folderPath);
                totalItemsCount = totalItemsCount + 1;
                checkedItemsCount = checkedItemsCount + 1;
                if (!foundItem) {
                    isChecked = false;
                    checkedItemsCount = checkedItemsCount - 1;
                    // isIndeterminated = true;
                }
            }
        });

        return { isChecked, isIndeterminated };
    }



    console.log(node, "nodelink");

    console.log(currentFolderAnchorEl, "folderAnchorEl");


    return (
        <Box
            className={`flex items-center gap-1 py-1 px-2 cursor-pointer`}
            borderRadius={theme.shape.borderRadius}
            // style={style}
            // ref={dragHandle}
            // onClick={() => { handleFolderClick(node) }}
            sx={{
                // color: (currentFolderData?.id === node?.id) && theme.palette.secondary.contrastText,
                // background: (currentFolderData?.id === node?.id) && "rgba(0, 0, 0, 0.05)" 
                pl: (node.level * 4)
                // pl : (node.level * 2)
            }}

        >

            <Box className="" onClick={() => handleIconClick(node)}>
                {!node.isLeaf &&
                    (node.isOpen ?
                        <KeyboardArrowDown /> : <KeyboardArrowRight />
                    )
                }
            </Box>


            <Box className="flex-1 flex items-center gap-1 " borderRadius={theme.shape.borderRadius} bgcolor={grey[100]} onClick={() => handleFolderClick(node)} sx={{ pl: 0 }} onContextMenu={(e) => handleRightClick(e, node)}>

                {
                    console.log(
                        (node.data.type === "use_case" || node.data.type === "user_story") && selectedItemsList?.some((item, index) => {
                            console.log(item, index, "item some checkbox")
                            return item === node?.data?.customId
                        }),
                        "checkbox selectedItemsList",
                        node?.data?.customId,
                        selectedItemsList
                    )
                }

                {/* {node.data.type === FolderType.project &&
                    <Checkbox
                        size="small"
                        checked={checkChecked()?.isChecked}
                        indeterminate={checkChecked()?.isInderminate}
                        onChange={(e) => handleChangeSelection(e, node?.data)}
                    />
                }

                {node.data.type === FolderType.module &&
                    <Checkbox
                        size="small"
                        checked={checkChecked()?.isChecked}
                        indeterminate={checkChecked()?.isInderminate}
                        onChange={(e) => handleChangeSelection(e, node?.data)}
                    />
                }

                {(node.data.type === "use_case" || node.data.type === "user_story") &&
                    <Checkbox
                        size="small"
                        checked={checkChecked()?.isChecked}
                        onChange={(e) => handleChangeSelection(e, node?.data)}
                    />
                } */}

                <Checkbox
                    size="small"
                    checked={checkChecked()?.isChecked}
                    indeterminate={checkChecked()?.isInderminate}
                    onChange={(e) => handleChangeSelection(e, node?.data)}
                />


                {node.data.type === LocalEntity.folder &&
                    <FolderOutlined sx={{ fontSize: font.size.fs_3 }} />
                }

                {(node.data.type === LocalEntity.useCase || node.data.type === LocalEntity.userStory) &&
                    <DescriptionOutlined sx={{ fontSize: font.size.fs_3 }} />
                }

                <LightTooltip
                    title={<Box className="flex-1 flex items-center gap-2 ">
                        {(node.data.type === LocalEntity.useCase || node.data.type === LocalEntity.userStory) &&
                            <Typography fontSize={font.size.fs_5} className="" fontWeight={600}>{`#${node?.data?.customId} -`}</Typography>
                        }
                        <Typography fontSize={font.size.fs_5} className="">
                            {`${node.data.type === LocalEntity.folder ? node.data.name : node.data.title} `}
                        </Typography>
                    </Box>}
                    arrow
                    placement="right"
                >
                    <Box className="flex-1 flex flex-col justify-between gap-0 overflow-hidden ml-2">
                        {(node.data.type === LocalEntity.useCase || node.data.type === LocalEntity.userStory) &&
                            <Typography fontSize={font.size.fs_6} className="" lineHeight={font.size.fs_5} fontWeight={600}>{`#${node.data.customId}`}</Typography>
                        }
                        <Typography fontSize={font.size.fs_6} lineHeight={font.size.fs_5} className="line-clamp-1 ">
                            {`${node.data.type === LocalEntity.folder ? node.data.name : node.data.title} `}
                        </Typography>
                    </Box>
                </LightTooltip>
            </Box>


        </Box>
    );
}