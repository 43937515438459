import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { Checkbox, Divider, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Select, Tab, Tabs, TextField, Typography, useTheme } from '@mui/material';
import { BlockOutlined, Check, CheckBox, Close, Description, FilterAltOffOutlined, FilterAltOutlined, Refresh } from '@mui/icons-material';
import { font } from '../../styles/font';
import { useEffect, useState } from 'react';
import { useApplicationContext } from '../../context/ApplicationContext';
import { getProjectCategoryList_API, getProjectList_API, getProjectSubcategoryList_API, updateProject_API } from '../../api/controller/ProjectController';
import { HttpStatusCode } from 'axios';
import { ModulePath, Severity, StoryStatus } from '../../constant/ApplicationVariables';
import useErrorHandler from '../../api/errorHandler/useErrorHandler';
import TagIcon from '@mui/icons-material/Tag';
import StatusBadge from '../common/StatusBadge';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useLocation } from 'react-router-dom';

class Filters {
    static status = "status";
    static createdAt = "createdAt";
    static createdBy = "createdBy";
    static assignedTo = "assignedTo";

    static moduleName = "moduleName";
    static projectName = "projectName";

    static state = "state";
    static type = "type";
    static moscow = "moscow";
    static confidentiality = "confidentiality";
    static priority = "priority";
}

export default function FilterItemDrawer({ open }) {
    const {  setProjectList, handleSnackbar, currentUserStoryData, userStoryList, setUserStoryList, filteredUserStoryList, setFilteredUserStoryList, isUserStoryLoading, setIsUserStoryLoading, currentFilters, setCurrentFilters, openItemFilterDrawer, setOpenItemFilterDrawer, toggleChatWindow,
        userStoryPropertiesData, setUserStoryPropertiesData,
        useCasePropertiesData, setUseCasePropertiesData,
    } = useApplicationContext();
    const { activeProject, setActiveProject, } = useApplicationContext();
    const errorHandler = useErrorHandler();
    const theme = useTheme();
    const pathName = useLocation()?.pathname;

    const [isLoading, setIsLoading] = useState(false);

    const [projectData, setProjectData] = useState(activeProject);
    const [activeTab, setActiveTab] = useState(Filters.priority);
    // const [open, setOpen] = useState(false);

    const [filterData, setFilterData] = useState(null); /* {
        status: [],
        createdAt: { from: null, to: null },
    }*/

    const [activeFilterData, setActiveFilterData] = useState({
        status: "All",
        createdAt: "All",
        createdBy: "All",
        assignedTo: "All",
        priority: "All",
        moduleName: "All",
        projectName: "All",
    });


    let statusFilterList = [
        { label: StoryStatus.pending, value: StoryStatus.pending },
        { label: StoryStatus.inprogress, value: StoryStatus.inprogress },
        { label: StoryStatus.completed, value: StoryStatus.completed },
    ]


    const toggleDrawer = () => {
        handleClose();
    };


    const handleGetCurrentDataState = () => {
        return pathName === ModulePath.requirements ? userStoryPropertiesData : pathName === ModulePath.useCase ? useCasePropertiesData : userStoryPropertiesData
    }


    const handleCheckClearButtonDisabled = () => {
        if (activeTab === Filters.createdAt) {
            return !(filterData && (filterData?.hasOwnProperty(activeTab)) && (filterData[activeTab]?.from || filterData[activeTab]?.to))
        } else {
            return !(filterData && (filterData?.hasOwnProperty(activeTab)) && filterData[activeTab]?.length > 0)
        }

    }

    const handleClose = () => { setOpenItemFilterDrawer(false); };
    const handleContinue = () => { }


    const handleChangeTab = (e, newValue) => {
        setActiveTab(newValue);
    }

    const checkIsFilterApplied = () => {
        return activeFilterData && Object.keys(activeFilterData).some((key) => activeFilterData[key] !== "All");
    }

    const handleGetFilterCount = (key) => {

        if (key === Filters.state || key === Filters.priority || key === Filters.moscow || key === Filters.confidentiality || key === Filters.type || key === Filters.status) {
            return (filterData && (filterData?.hasOwnProperty(key)) ? filterData[key]?.length : 0)
        }

        if (key === Filters.status) {
            return filterData?.status?.length
        }

        if (key === Filters.createdAt) {
            return filterData?.createdAt?.from && filterData?.createdAt?.to ? 2 : filterData?.createdAt?.from ? 1 : filterData?.createdAt?.to ? 1 : 0
        }
    }

    const handleChangeFilters = (name, value) => {

        let data = { ...filterData };
        console.log(name, value, data, "filterChange")


        // select all checkbox - OLD
        if (name === "statusAll") {
            setFilterData({ ...data, status: data?.status?.length === statusFilterList?.length ? [] : statusFilterList?.map((item) => item?.value) })
        }


        // to clear the tab list from filter 
        if (name === `clearAll-${activeTab}`) {
            if (activeTab === Filters.createdAt) {
                delete data[activeTab]
                setFilterData(data)
                // setFilterData({ ...data, createdAt: { from: null, to: null } })
            } else {
                delete data[activeTab]
                setFilterData(data)
                // setFilterData({ ...data, [activeTab]: [] })
            }

        }

        // userstory/ usecase/testcase options change
        if (name === Filters.state || name === Filters.priority || name === Filters.moscow || name === Filters.confidentiality || name === Filters.type || name === Filters.status) {
            let list = (data?.hasOwnProperty(name)) && data[name];

            if (list) {
                if (list?.includes(value)) {
                    list = list.filter((item) => item !== value);
                } else {
                    list.push(value);
                }
            } else {
                list = [value];
            }
            setFilterData({ ...data, [name]: list })
        }

        // createdAt from to change
        if (name === "from") {
            setFilterData({ ...data, createdAt: { ...data?.createdAt, from: value } })
        }

        if (name === "to") {
            setFilterData({ ...data, createdAt: { ...data?.createdAt, to: value } })
        }

        // for today, this week, this month, this year
        if (name === "fromto") {
            setFilterData({ ...data, createdAt: { ...data?.createdAt, from: value, to: dayjs() } })
        }
    }

    const handleChangeColumnFilter = (value, key) => {
        const filterData = currentFilters ? { ...currentFilters } : {};

        console.log(value, key, "value key")

        if (value === '') {
            delete filterData[key];
        } else {
            filterData[key] = value;
        }

        setCurrentFilters(filterData);
    };

    const handleClearFilter = () => {
        setCurrentFilters(null);
        setFilterData(null);
    }


    const handleApplyFilter = () => {
        // let filters = {};

        // Object.keys(filterData).forEach((key) => {
        //     if (handleGetFilterCount(key) > 0) {
        //         filters[key] = filterData[key];
        //     }
        // })
        // setCurrentFilters(filters);

        setCurrentFilters(filterData);
    }


    useEffect(() => {
        if (handleGetCurrentDataState() && Object.keys(handleGetCurrentDataState())?.length) {
            setActiveTab(Object.keys(handleGetCurrentDataState())[0])
        }
    }, []);



    useEffect(() => {
        if (currentFilters) {
            setFilterData(currentFilters)
        }
    }, [currentFilters]);

    // useEffect(() => {
    //     setCurrentFilters(filterData)
    // }, [filterData])


    console.log(projectData, "activeProject");
    console.log(filteredUserStoryList, "filteredUserStoryList")
    console.log(currentFilters, "currentFilters");

    console.log(filterData, "filterData")
    console.log(handleGetCurrentDataState() && Object.keys(handleGetCurrentDataState())[0], "handleGetCurrentDataState")

    const DrawerList = (
        <Box sx={{ width: 460, height: "100%" }} role="presentation" className="flex flex-col h-full ">
            <Box className="flex items-center justify-between px-4 py-2 border-b">
                <FilterAltOutlined sx={{ color: theme.palette.text.primary }} />
                <Typography fontSize={font.size.fs_4} color={theme.palette.text.primary} className="flex-1">Filter</Typography>

                <IconButton onClick={() => toggleDrawer()}><Close /></IconButton>
            </Box>

            <Box className="flex-1 flex gap-0 px-0 pt-0" bgcolor={theme.palette.background.custom}>

                {/* active section tabs */}
                <Box className="w-[34%] h-full  border-r-0"  >
                    <Tabs
                        value={activeTab}
                        orientation="vertical"
                        variant="scrollable"
                        onChange={handleChangeTab}
                        aria-label="Vertical tabs example"
                        sx={{
                            // borderLeft: 1,
                            borderColor: 'divider',

                            '& .MuiTabs-indicator': {
                                backgroundColor: 'secondary.main',
                            },
                            //  '& .Mui-selected': {
                            //   borderColor: 'secondary.main',
                            //   borderWidth: 2,
                            //   borderStyle: 'solid',
                            // },
                        }}
                    >

                        {(pathName === ModulePath.requirements || pathName === ModulePath.useCase || pathName === ModulePath.testCase) &&
                            (handleGetCurrentDataState()) && Object.keys(handleGetCurrentDataState())
                                ?.map((key, index) => {
                                    return (
                                        <Tab
                                            key={index}
                                            label={
                                                <Box className="w-full flex items-center justify-between px-2 gap-1">
                                                    <Typography
                                                        fontSize={font.size.fs_5}
                                                        color={key === activeTab ? theme.palette.text.primary : theme.palette.text.secondary}
                                                    >
                                                        {`${key} `}
                                                    </Typography>
                                                    {
                                                        handleGetFilterCount(key) > 0 &&
                                                        (Array.isArray((pathName === ModulePath.requirements
                                                            ? userStoryPropertiesData : pathName === ModulePath.useCase ? useCasePropertiesData : userStoryPropertiesData)[key]) ? <Typography
                                                                fontSize={font.size.fs_5}
                                                                color={key === activeTab ? theme.palette.text.primary : theme.palette.text.secondary}
                                                            >
                                                            {`${handleGetFilterCount(key) > 0 ? `[${handleGetFilterCount(key)}]` : ""}`}
                                                        </Typography>
                                                            : <Check sx={{ fontSize: font.size.fs_4 }} />)
                                                    }
                                                </Box>
                                            }

                                            value={key}
                                            sx={{
                                                // borderRadius: `${theme.shape.borderRadius} 0 0 ${theme.shape.borderRadius}`,
                                                borderBottom: `1px solid ${theme.palette.divider}`,
                                                width: "100%",
                                                '&.Mui-selected': {
                                                    backgroundColor: theme.palette.background.paper,
                                                },
                                                whiteSpace: "nowrap"
                                            }}

                                        />)
                                })
                        }

                        {/* Creation Date */}
                        <Tab
                            label={
                                <Box className="w-full flex items-center justify-between px-2 gap-1">
                                    <Typography
                                        fontSize={font.size.fs_5}
                                        color={Filters.createdAt === activeTab ? theme.palette.text.primary : theme.palette.text.secondary}
                                    >
                                        Creation Date
                                    </Typography>

                                    {handleGetFilterCount(Filters.createdAt) > 0 &&
                                        <Check />
                                    }
                                </Box>
                            }

                            value={Filters.createdAt}
                            sx={{
                                // borderRadius: `${theme.shape.borderRadius} 0 0 ${theme.shape.borderRadius}`,
                                width: "100%",
                                '&.Mui-selected': {
                                    backgroundColor: theme.palette.background.paper,
                                    // color: "#000"
                                    // color: theme.palette.text.primary,
                                },
                                whiteSpace: "nowrap"
                            }}

                        />


                    </Tabs>
                </Box>

                {/* active section filters */}
                <Box
                    className="flex-1  flex flex-col gap-4 px-4 py-2"
                    borderRadius={`0 ${theme.shape.borderRadius} ${theme.shape.borderRadius} 0`}
                    bgcolor={theme.palette.background.default}
                >

                    <Box className="flex items-center justify-end gap-1 ">
                        <Button
                            // fullWidth
                            variant="outlined"
                            disabled={handleCheckClearButtonDisabled()}
                            name={`clearAll-${activeTab}`}
                            onClick={(e) => handleChangeFilters(e.target.name)}
                        // startIcon={<FilterAltOffOutlined />}
                        >
                            Clear
                        </Button>
                    </Box>



                    {/* userstory /usecase/ testcase filter options list */}
                    {
                        (
                            activeTab === Filters.state || activeTab === Filters.type || activeTab === Filters.priority || activeTab === Filters.moscow || activeTab === Filters.confidentiality || activeTab === Filters.status
                        )
                        &&
                        (
                            <Box className="flex flex-col gap-1 ">

                                {false && <Box className="flex items-center gap-1 ">
                                    <FormControlLabel
                                        name="statusAll"
                                        onChange={(e) => handleChangeFilters(e.target.name)}
                                        control={
                                            <Checkbox
                                                checked={filterData?.status?.length > 0 && filterData?.status?.length === statusFilterList?.length}
                                                indeterminate={filterData?.status?.length > 0 && filterData?.status?.length !== statusFilterList?.length} />
                                        }
                                        label={<Typography fontSize={font.size.fs_5} color={(filterData?.status?.length > 0 && filterData?.status?.length === statusFilterList?.length) ? theme.palette.text.primary : theme.palette.text.secondary}>Select All</Typography>}
                                    />
                                </Box>}

                                {
                                    console.log("tabList2", activeTab, handleGetCurrentDataState())
                                }

                                {
                                    console.log(handleGetCurrentDataState() && activeTab && (handleGetCurrentDataState())[activeTab] && (handleGetCurrentDataState())[activeTab]?.map((item, index) => { console.log(item, "tabList3-in"); return item?.key }), "tabList3")
                                }

                                {true && <Box className="flex  flex-col gap-2 ">
                                    {
                                        handleGetCurrentDataState() && activeTab && (handleGetCurrentDataState())[activeTab] && (handleGetCurrentDataState())[activeTab]?.map((item, index) => {
                                            console.log(item, "tabList4-in")

                                            return <Box className="flex items-center gap-1 " borderBottom={`1px solid ${theme.palette.divider}`} key={item?.key}>
                                                <FormControlLabel
                                                    name={activeTab}
                                                    value={item?.key}
                                                    onChange={(e) => handleChangeFilters(e.target.name, item?.key)}
                                                    control={
                                                        <Checkbox checked={(filterData && (filterData?.hasOwnProperty(activeTab)) && filterData[activeTab]?.includes(item?.key)) || false} />
                                                    }
                                                    label={
                                                        <Typography
                                                            fontSize={font.size.fs_5}
                                                        // color={filterData[activeTab]?.includes(item?.key) ? theme.palette.text.primary : theme.palette.text.secondary}
                                                        >
                                                            {item?.value}
                                                        </Typography>
                                                    }

                                                />
                                            </Box>
                                        })
                                    }
                                </Box>}
                            </Box>
                        )
                    }

                    {/* date filter */}
                    {activeTab === Filters.createdAt && <Box className="flex flex-col gap-4 " >
                        <DemoContainer components={['DatePicker',]}>
                            <DatePicker name="from" label="From" value={filterData?.createdAt?.from} onChange={(value) => handleChangeFilters("from", value)} renderInput={(params) => <TextField {...params} fullWidth className="box-border" />} />
                        </DemoContainer>

                        <DemoContainer components={['DatePicker',]}>
                            <DatePicker name="to" label="To" value={filterData?.createdAt?.to} onChange={(value) => handleChangeFilters("to", value)} />
                        </DemoContainer>


                        <Divider />

                        <Box className="flex items-center gap-2 flex-wrap">
                            {
                                [{ label: "Today", value: dayjs() }, { label: "This Week", value: dayjs().startOf("week") }, { label: "This Month", value: dayjs().startOf("month") }, { label: "This Year", value: dayjs().startOf("year") }].map((item, index) => {
                                    return <Button variant="outlined" onClick={() => handleChangeFilters("fromto", item?.value)} key={index}>
                                        {item?.label}
                                    </Button>
                                }
                                )
                            }
                        </Box>
                    </Box>}




                </Box>
            </Box>

            <Box className="flex  gap-4 px-4 py-4 border-t-0">
                <Button variant="outlined" fullWidth disabled={isLoading} onClick={handleClearFilter}>Reset</Button>
                <Button variant="contained" fullWidth disabled={!filterData} color="secondary" sx={{ color: theme.palette.secondary.contrastText }} onClick={handleApplyFilter}>Apply </Button>
            </Box>

        </Box>
    );

    return (
        <Box className="flex flex-col h-full box-border " sx={{}} borderRadius={theme.shape.borderRadius} bgcolor={theme.palette.background.default}>
            {/* <IconButton  variant={checkIsFilterApplied() ? "contained" : "outlined"} startIcon={<FilterAltOutlined />} onClick={() => setOpen(true)}>
                <FilterAltOutlined />
            </IconButton> */}
            <Drawer
                className="flex flex-col w-full "
                open={open}
                onClose={() => toggleDrawer()}
                anchor="right"
                SlideProps={{ direction: "right" }}
                // hideBackdrop
                PaperProps={{
                    style: {
                        marginRight: toggleChatWindow ? "27.6%" : "0%",
                        // marginTop : "8vh",
                        // height : "calc(100vh - 8vh - 1rem)",

                        // borderRadius: 0,
                        // boxShadow : "none"
                        // height : "80%"
                    }
                }}>
                {DrawerList}
            </Drawer>
        </Box>
    );
}

function KeyValueComponent({ label, children }) {
    return (
        <Box className=" flex flex-col gap-2 border box-border ">
            {label && <Typography fontSize={font.size.fs_4} className="border-b whitespace-nowrap">{label}</Typography>}
            <Box className="flex-1">
                {children}
            </Box>
        </Box>
    )
}