import { Add, ArrowBack, Attachment, AutoAwesome, AutoAwesomeOutlined, AutoAwesomeTwoTone, Checklist, Close, Create, Delete, DeleteOutlined, Description, DescriptionOutlined, Edit, EditOutlined, ExpandMore, Label, Light, Link, MoreHoriz, MoreVert, PersonAddAlt, ReplyOutlined, Save, Send, Settings, Upload } from "@mui/icons-material"
import { AccordionDetails, AccordionSummary, Avatar, Box, Button, Checkbox, Chip, CircularProgress, Divider, FormControl, FormControlLabel, Icon, IconButton, InputAdornment, InputLabel, ListItemIcon, ListItemText, MenuItem, Paper, Popover, Select, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, ToggleButton, Typography, useTheme } from "@mui/material"
import { amber, blue, grey } from "@mui/material/colors";
import { forwardRef, useEffect, useRef, useState } from "react";
import { font } from "../../styles/font";
import { useApplicationContext } from "../../context/ApplicationContext";
import { AI_Entity, Entity_Label, FolderType, Severity } from "../../constant/ApplicationVariables";
import { getStoryStatusList_API } from "../../api/controller/StoryController";
import useErrorHandler from "../../api/errorHandler/useErrorHandler";
import { HttpStatusCode } from "axios";
import { CustomAccordionComponent, CustomAccordionComponentDetails, CustomAccordionComponentSummary, LightTooltip } from "../styled/CustomComponent";
import styled from "@emotion/styled";
import 'react-quill/dist/quill.snow.css';
import { TableVirtuoso } from "react-virtuoso";
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { getUseCaseList_API, updateUseCase_API } from "../../api/controller/UseCaseController";
import ReactQuill from "react-quill";
import { addCommentTestCase_API, getTestCaseList_API, updateCommentTestCase_API, updateTestCase_API } from "../../api/controller/TestCaseController";
import MediaAttachmentComponent from "../media/MediaAttachmentComponent";
import { LinkCustomIcon, TestCaseCustomIcon } from "../styled/CustomIcons";
import DeleteConfirmationDialog from "../dialog/DeleteConfirmationDialog";
import { convertEditorDataToReact } from "../../utils/StringOperations";
import { formatDateTime } from "../../utils/DateTime";
import { CommentComponent } from "../common/previewTemplate/CommentComponent";
import { HeadingComponent, KeyValuePairComponent, ValueComponent } from "../common/previewTemplate/CustomTextFormatting";
import { AccordionComponent, AccordionComponentDetails, AccordionComponentSummary, AccordionDetailsContainer, LinkedItemsTable, SectionContainer } from "../common/previewTemplate/StaticComponents";
import LabelBadge from "../common/LabelBadge";


const TableComponents = {
    Scroller: forwardRef((props, ref) => <TableContainer component={Paper} sx={{ overflowX: "auto", overflowY: "scroll", }} {...props} ref={ref} />),
    Table: (props) => <Table size="small" {...props} style={{ borderCollapse: 'separate' }} />,
    TableHead: TableHead,
    TableRow: (props) => <TableRow {...props} />,
    TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
    TableCell: (props) => <TableCell {...props} sx={{ ...props?.sx, px: "1rem !important", border: `1px solid black`, borderBottom: "1px solid black", borderRight: "1px solid black", }} />,
}

const StyledTextField = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderRadius: 0,
            border: "none"
            // borderColor: theme.palette.common.black
        },
        "&:hover fieldset": {
            borderRadius: 0,
            border: "none"
        },
        "&.Mui-focused fieldset": {
            borderRadius: 0,
            border: "none"
        },
    },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
    // height: '24px ',
    '& .MuiOutlinedInput-root': {
        height: '100%',
        padding: '0 14px',
        border: "none"
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: 0,
        border: "none"
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderRadius: 0,
        border: "none"
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderRadius: 0,
        border: "none"
    },
}));

class Field_Configuration {
    static fieldType = "Field Type";
    static fieldLength = "Field Length";
    static fieldValidation = "Field Validation";
    static elementId = "Element Id";
}

class EditSection {
    static customId = "customId";
    static title = "title";
    static description = "description";
    static steps = "steps";
    static linked = "linked";
    static linkedTestCase = "linkedTestCase";

    static acceptanceCriteria = "acceptanceCriteria";
    static attachments = "attachments";
    static status = "status";
    static assignedTo = "assignedTo";
    static priority = "priority";
    static category = "category";
    static moscow = "moscow";
    static storyType = "storyType";
    static userFields = "userFields";
    static userPoints = "userPoints";
    static plannedInRelease = "plannedInRelease";
    static plannedInSprint = "plannedInSprint";
    static confidenciality = "confidenciality";
    static underSOXCategory = "underSOXCategory";
    static environment = "environment";
    static verificationOwner = "verificationOwner";
    static verificationMethod = "verificationMethod";
    static dependency = "dependency";
    static businessValue = "businessValue";
    static userPersona = "userPersona";
    static testingCriteria = "testingCriteria";
    static businessUnit = "businessUnit";
    static vendor = "vendor";
    static externalPoints = "externalPoints";
    static subProducts = "subProducts";

    static testType = "testType";
    static candidateForRegression = "candidateForRegression";
    static executedBy = "executedBy";
    static executionDate = "executionDate";
    static executionMode = "executionMode";
    static executionEnvironment = "executionEnvironment";
    static executionHistory = "executionHistory";

    static dueDate = "dueDate";
    static members = "members";
    static checklist = "checklist";
    static label = "label";
    static comments = "comments";
    static activity = "activity";

    static expectedResult = "expectedResult";
    static actualResult = "actualResult";
    static preCondition = "preCondition";
    static postCondition = "postCondition";
}

class AccordionName {
    static details = "details";
    static attachments = "attachments";
    static otherFields = "otherFields";
    static estimationStoryPoints = "estimation_StoryPoints";
    static linkTo = "linkTo";
    static tagTo = "tagTo";
    static linkedUseCase = "linkedUseCase";
    static linkedTestCase = "linkedTestCase";
    static linkedUserStory = "linkedUserStory";
    static execution = "execution"
}

export default function EditTestCaseComponent({ open, closeDialog, }) {
    const {
        currentUseCaseData, setCurrentUseCaseData,
        currentFolderData,
        handleSnackbar,
        activeProject,
        setOpenLinkTagDrawer,
        useCaseList, setUseCaseList,
        toggleChatWindow, setToggleChatWindow,
        isItemChatWindow, setIsItemChatWindow,
        currentAIEntityData, setCurrentAIEntityData,
        useCasePropertiesData,
        labelList, setLabelList
    } = useApplicationContext();
    const theme = useTheme();
    const errorHandler = useErrorHandler();

    const [loading, setLoading] = useState(true);
    const [enableEdit, setEnableEdit] = useState(null);

    const [currentStepText, setCurrentStepText] = useState("");
    const [currentStepIndex, setCurrentStepIndex] = useState(null);
    const [stepsAnchorEl, setStepsAnchorEl] = useState(null);

    const [userData, setUserData] = useState(null);
    const [commentText, setCommentText] = useState("");

    const [editorValue, setEditorValue] = useState("");
    const [validationAnchorEl, setValidationAnchorEl] = useState(null);
    const [currentFieldConfiguration, setCurrentFieldConfiguration] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedLabels, setSelectedLabels] = useState([]);

    const [editAnchorEl, setEditAnchorEl] = useState(null);
    const [aiAnchorEl, setAiAnchorEl] = useState(null);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [moreAnchorEl, setMoreAnchorEl] = useState(null);
    const [labelAnchorEl, setLabelAnchorEl] = useState(null);

    const [priorityList, setPriorityList] = useState([]);
    const [typeList, setTypeList] = useState([]);
    const [stateList, setStateList] = useState([]); // X
    const [statusList, setStatusList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [environmentList, setEnvironmentList] = useState([]);
    const [executionModeList, setExecutionModeList] = useState([]);

    const [activityType, setActivityType] = useState("comments");
    const [expandedAccordion, setExpandedAccordion] = useState("details");
    const [sections, setSections] = useState({
        [EditSection.attachments]: false,
        [EditSection.members]: false,
        [EditSection.checklist]: false,
        [EditSection.label]: false,
        [EditSection.dueDate]: false,
        [EditSection.userPersona]: false
    });

    const fileInputRef = useRef(null);

    const columns = [
        {
            data: "S.No.",
            padding: "none",
            px: "0.4rem",
            align: "left",
            background: theme.palette.header.main,
            width: "40px",
            children: []
        },
        {
            data: "Action",
            padding: "none",
            px: "1rem",
            align: "left",
            background: theme.palette.header.main,
            width: "154px",
            children: []
        },
        {
            data: "Test Data",
            padding: "none",
            px: "1rem",
            align: "left",
            background: theme.palette.header.main,
            width: "180px",
            children: []
        },

        {
            data: "Expected Result",
            padding: "none",
            px: "1rem",
            align: "left",
            background: theme.palette.header.main,
            width: "320px",
            children: []
        },
    ];


    const handleChangeFiles = (e) => {
        let files = e.target.files;
        setSelectedFiles([...files]);

        console.log(files, files && files[0] && URL.createObjectURL(files[0]), "files")
    }

    const handleEditClick = () => {

        if (enableEdit) {
            setUserData(currentUseCaseData);
            setEnableEdit(false);
        } else {
            setEnableEdit(true);
        }

    }

    const handleOpenAIPopup = (e) => {
        e && e.stopPropagation();
        setAiAnchorEl(e.currentTarget);
    }

    const handleCloseAIPopup = () => {
        setAiAnchorEl(null);
    }

    const handleChangeAccordion = (panel) => (event, isExpanded) => {
        setExpandedAccordion(panel)
        return;
    };

    const handleToggleAIWindow = (entityLabel, entityType) => {

        if (isItemChatWindow && currentAIEntityData?.label === entityLabel && currentAIEntityData?.entity === entityType) {
            setIsItemChatWindow(false)
            setCurrentAIEntityData(null);

        } else {

            setIsItemChatWindow(true);
            setToggleChatWindow(true);
            setCurrentAIEntityData({ label: entityLabel, entity: entityType })
        }

        handleCloseAIPopup();
    }

    const handleCloseFieldConfiguration = () => {
        setCurrentFieldConfiguration(null);
    }

    const handleChange = (e) => {
        if (e.target.name === "isRegression") {
            setUserData({ ...userData, [e.target.name]: e.target.checked });
        } else {
            setUserData({ ...userData, [e.target.name]: e.target.value });
        }


    }

    const handleAddUpdateListItem = () => {
        if (currentStepIndex !== null) {
            let steps = [...userData?.useCaseSteps];
            steps[currentStepIndex] = currentStepText;
            setUserData({ ...userData, useCaseSteps: steps });
            setCurrentStepIndex(null);
        } else {
            if (userData?.useCaseSteps) {
                setUserData({ ...userData, useCaseSteps: [...userData?.useCaseSteps, currentStepText] });
            } else {
                setUserData({ ...userData, useCaseSteps: [currentStepText] });
            }
        }

        setCurrentStepText("");
    }


    const handleAddRowBelow_ = (index) => {
        let keyName = "steps"
        let list = userData[keyName] ? [...userData[keyName]] : [];
        // if (steps?.length > 0 && steps[index]?.step === "") {
        //     return;
        // }

        if (list?.length > 0) {
            list.splice(index + 1, 0, { step: "", expectedResult: "" });
        } else {
            list.push({ step: "", expectedResult: "" });
        }

        setUserData({ ...userData, [keyName]: list });
    }



    // list drag drop operation start

    const handleCurrentListItem = (key, stepIndex, stepText) => {
        console.log(stepText, stepIndex, "stepdata")
        // setCurrentStepText(stepText)
        // setCurrentStepIndex(stepIndex);

        let list = [];

        if (key === "step" || key === "expectedResult") {
            list = userData?.steps ? userData?.steps : [];
            list[stepIndex][key] = stepText;
            setUserData({ ...userData, ["steps"]: list });
        } else {
            list = userData[key] ? userData[key] : [];
            list[stepIndex] = stepText;
            setUserData({ ...userData, [key]: list });
        }

        // setUserData({ ...userData, [key]: list });
    }

    const handleRemoveListItem = (key, index) => {
        setUserData({ ...userData, [key]: userData[key]?.filter((_, i) => i !== index) });
    }

    const handleAddRowBelow = (key, index) => {
        let list = userData[key] ? [...userData[key]] : [];


        if (key === "steps") {

            if (list?.length > 0) {
                list.splice(index + 1, 0, { step: "", expectedResult: "" });
            } else {
                list.push({ step: "", expectedResult: "" });
            }

        } else {
            if (list?.length > 0 && (list[index] === "" || list[index].length < 3 || list[index].length > 300)) {
                handleSnackbar("Length should be between  3 - 300", Severity.warning)
                return;
            }

            if (list?.length > 0) {
                list.splice(index + 1, 0, "");
            } else {
                list.push("");
            }
        }



        setUserData({ ...userData, [key]: list });
    }

    const handleDragStart = (key, index, e) => {
        e.dataTransfer.setData('text', index.toString());
    };

    const handleDragOver = (key, e) => {
        e.preventDefault();
    };

    const handleDrop = (key, index, e) => {
        const draggedIndex = parseInt(e.dataTransfer.getData('text'));
        const newSteps = userData[key] ? [...userData[key]] : [];
        const [removed] = newSteps.splice(draggedIndex, 1);
        newSteps.splice(index, 0, removed);
        setUserData({ ...userData, [key]: newSteps });
    };


    // list functions end

    const handleChangeFieldConfiguration = (fieldConfiguration) => {
        setCurrentFieldConfiguration(fieldConfiguration);
        setStepsAnchorEl(null);
    }

    const handleEnableSectionEdit = (e, sectionName) => {
        setEnableEdit(sectionName);
        setEditAnchorEl(e.currentTarget);
    }

    const handleCloseSectionEdit = () => {
        setEnableEdit(null);
        setEditAnchorEl(null);
    }

    const handleGetFieldConfigurationComponent = (fieldConfiguration) => {
        switch (fieldConfiguration) {
            case Field_Configuration.fieldType: return <FieldTypeComponent handleClose={handleCloseFieldConfiguration} />;
            case Field_Configuration.fieldLength: return <FieldLengthComponent handleClose={handleCloseFieldConfiguration} />;
            case Field_Configuration.fieldValidation: return <FieldValidationComponent handleClose={handleCloseFieldConfiguration} />;
            case Field_Configuration.elementId: return <TestStepsComponent handleClose={handleCloseFieldConfiguration} />;
            default: return null;
        }
    }

    const handleCheckValues = () => {
        let flag = true;

        if (!userData?.customId) flag = false;
        if (!userData?.title) flag = false;

        return flag;
    }

    const handleOpenLinkTag = (data) => {
        setOpenLinkTagDrawer(true)
    }

    const handleToggleSection = (sectionName) => {
        setSections({ ...sections, [sectionName]: !sections[sectionName] })
    }

    const handleToggleMenuPopup = (e) => {

        e && e.stopPropagation();

        if (!menuAnchorEl) {
            setMenuAnchorEl(e.currentTarget);
        } else {
            setMenuAnchorEl(null);
        }
    }

    const handleToggleMorePopup = (e) => {

        e && e.stopPropagation();

        if (!moreAnchorEl) {
            setMoreAnchorEl(e.currentTarget);
        } else {
            setMoreAnchorEl(null);
        }
    }






    const handleToggleLabelPopup = (e) => {

        e && e.stopPropagation();

        if (!labelAnchorEl) {
            setLabelAnchorEl(e.currentTarget);
        } else {
            setLabelAnchorEl(null);
        }
    }

    const handleChangeLabel = (label) => {
        let list = selectedLabels ? [...selectedLabels] : [];

        const index = list?.findIndex((item) => item?.name === label?.name);

        if (index > -1) {
            list.splice(index, 1);
        } else {
            list.push(label);
        }

        setSelectedLabels(list);
    }

    const handleLabelUpdate = () => {
        // setUserStoryData({ ...userStoryData, labels: selectedLabels });
        handleContinueStory();
    }





    const handleContinueComment = () => {
        let body = {
            // "customIds": [currentUserStoryData?.customId],
            // "newCustomId": userStoryData?.customId,
            // "title": userStoryData?.title,
            // "description": userStoryData?.description,
            // "priority": "Medium",
            // "assignedToEmail": userStoryData?.assignedToEmail,
            // "acceptanceCriteria": userStoryData?.acceptanceCriteria,
            // "status": userStoryData?.status,
            // "moduleName": currentFolderData?.type === FolderType.project ? currentUserStoryData?.moduleName : currentFolderData?.name,


            "text": commentText,

            // "userStoryCustomId": "",
            // "useCaseCustomId":  "",
            "testCaseCustomId": userData?.customId,
            // "commentCustomId": "", // if user reply for particular comment, then use it.

            // "attachments": "", // MVP2


            "folderPath": currentFolderData?.path, // if userStoryCustomId / useCaseCustomId / testCaseCustomId is present, then use it.
            "projectName": activeProject?.name,
        }


        // if (selectedFiles?.length > 0) {
        //     body.attachments = selectedFiles
        //     setSelectedFiles([])
        // }

        console.log(body, "body in handleContinueComment")
        handleAddComment(body);
    }

    const handleContinueStory = () => {
        let body = {
            "customIds": [currentUseCaseData?.customId],
            // "newCustomId": userData?.customId,
            // "title": userData?.title,
            // "description": userData?.description,
            // "priority": "Medium",
            // "assignedToEmail": userData?.assignedToEmail,


            "lastModifiedAt": currentUseCaseData?.lastModifiedAt ? currentUseCaseData?.lastModifiedAt : null,
            "folderPath": currentFolderData?.path,
            "projectName": activeProject?.name,
            // "acceptanceCriteria": userData?.acceptanceCriteria,
            // "status": userData?.status
        }

        if (userData?.customId !== currentUseCaseData?.customId) {
            body.newCustomId = userData?.customId
        }
        if (userData?.title !== currentUseCaseData?.title) {
            body.title = userData?.title
        }
        // if (userData?.description !== currentUseCaseData?.description) {
        //     body.description = userData?.description
        // }
        // if (userData?.priority !== currentUseCaseData?.priority) {
        //     body.priority = userData?.priority
        // }
        // if (userData?.assignedToEmail !== currentUseCaseData?.assignedToEmail) {
        //     body.assignedToEmail = userData?.assignedToEmail
        // }
        if (JSON.stringify(userData?.steps) !== JSON.stringify(currentUseCaseData?.steps)) {
            body.steps = userData?.steps ? userData?.steps : []
        }
        // if (userData?.status !== currentUseCaseData?.status) {
        //     body.status = userData?.status
        // }

        if (userData?.expectedResult !== currentUseCaseData?.expectedResult) {
            body.expectedResult = userData?.expectedResult
        }
        if (userData?.actualResult !== currentUseCaseData?.actualResult) {
            body.actualResult = userData?.actualResult
        }


        if (JSON.stringify(userData?.preCondition) !== JSON.stringify(currentUseCaseData?.preCondition)) {
            body.preCondition = userData?.preCondition ? [...userData?.preCondition?.filter((item) => item && item !== "")] : null
        }
        if (JSON.stringify(userData?.postCondition) !== JSON.stringify(currentUseCaseData?.postCondition)) {
            body.postCondition = userData?.postCondition ? [...userData?.postCondition?.filter((item) => item && item !== "")] : null
        }



        if (userData?.type !== currentUseCaseData?.type) {
            body.type = userData?.type
        }
        if (userData?.priority !== currentUseCaseData?.priority) {
            body.priority = userData?.priority
        }
        if (userData?.status !== currentUseCaseData?.status) {
            body.status = userData?.status
        }
        if (userData?.category !== currentUseCaseData?.category) {
            body.category = userData?.category
        }
        if (userData?.environment !== currentUseCaseData?.environment) {
            body.environment = userData?.environment
        }


        if (userData?.isRegression !== currentUseCaseData?.isRegression) {
            body.isRegression = userData?.isRegression
        }

        // if (userData?.assignedToEmail !== currentUseCaseData?.assignedToEmail) {
        //     body.assignedToEmail = userData?.assignedToEmail
        // }

        if (selectedLabels?.length > 0) {
            body.labels = selectedLabels?.map((item) => item?.name)
        }


        if (selectedFiles?.length > 0) {
            body.attachments = selectedFiles
        }

        setSelectedFiles([]);

        // handleContinue(body);
        // setEnableEdit(false)

        handleUpdateTestCase(body);
    }

    const handleRemoveAttachements = (fileItem) => {
        let body = {
            "customIds": [currentUseCaseData?.customId],
            "folderPath": currentFolderData?.path,
            "projectName": activeProject?.name,
        }

        // if (selectedFiles?.length > 0) {
        body.removeAttachments = [fileItem?.name]
        // }

        // setSelectedFilesForDelete([]);

        handleUpdateTestCase(body);
    }

    const handleGetStatusList = async () => {
        try {


            const response = await getStoryStatusList_API();
            console.log(response, "response handleGetUserStoryList")

            if (response) {
                errorHandler(response);
            }

            if (response.status === HttpStatusCode.Ok) {
                setStatusList(response.data);
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {

            }, 1000);
        }
    }

    const handleUpdateTestCase = async (storyData) => {
        try {
            setLoading(true);

            let body = {
                ...storyData,
            }

            console.log(body, "bodyinupdate")

            const bodyFormData = new FormData();

            Object.keys(body).forEach(key => {
                console.log(key, "keyforEach")
                if (Array.isArray(body[key])) {
                    if (key === "attachments") {
                        body[key]?.forEach((file, index) => {
                            bodyFormData.append(`${key}[${index}]`, file, file.name);
                        })
                    } else if (key === "steps") {
                        body[key]?.forEach((data, index) => {
                            console.log(data, index, "forEach")
                            bodyFormData.append(`${key}[${index}].${"step"}`, data?.step);
                            bodyFormData.append(`${key}[${index}].${"expectedResult"}`, data?.expectedResult);
                        })
                    } else {
                        bodyFormData.append(key, body[key]);
                    }

                } else {
                    bodyFormData.append(key, body[key]);
                }
            });


            const response = await updateTestCase_API(bodyFormData);
            console.log(response, "response handleUpdateStory")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response?.status === HttpStatusCode.Ok) {
                let previousData = currentUseCaseData;
                let updatedData = response.data[0];


                setCurrentUseCaseData(updatedData);
                setUserData(updatedData);


                let list = useCaseList;
                const foundIndex = list?.findIndex(item => item?.customId === previousData?.customId);
                if (foundIndex !== -1) {
                    list[foundIndex] = updatedData;
                    // list.splice(foundIndex, 1, updatedData)
                }
                console.log(list, "updatedlist", previousData, foundIndex, updatedData,)
                setUseCaseList(list);

                // handleGetUseCaseList();
                // setEnableEdit(false);



                handleCloseSectionEdit();
                handleSnackbar("Updated successfully", Severity.success);
            }


            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const handleGetTestCaseList = async () => {
        try {
            setLoading(true);

            let body = {
                "folderPath": currentFolderData?.path,
                "projectName": activeProject?.name
            }

            const response = await getTestCaseList_API(body);
            console.log(response, "response handleGetTestCaseList")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                setUseCaseList(response.data);
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const handleAddComment = async (bodyData) => {
        try {
            setLoading(true);

            let body = {
                ...bodyData,
            }


            const bodyFormData = new FormData();

            // Object.keys(body).forEach(key => bodyFormData.append(key, body[key]));
            Object.keys(body).forEach(key => {
                if (Array.isArray(body[key])) {
                    if (key === "attachments") {
                        body[key]?.forEach((file, index) => {
                            bodyFormData.append(`${key}[${index}]`, file, file.name);
                        })
                    } else {
                        bodyFormData.append(key, body[key]);
                    }

                } else {
                    bodyFormData.append(key, body[key]);
                }
            });

            //    formData.append("media", file, file.name);


            const response = await addCommentTestCase_API(bodyFormData);
            console.log(response, "response handleUpdateStory")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response?.status === HttpStatusCode.Ok) {
                // update in current user story data
                let list = userData?.comments ? userData?.comments : [];
                list.unshift(response.data);
                setUserData({ ...userData, comments: list });


                // update in user story list
                let completeList = [...useCaseList];
                const foundIndex = completeList?.findIndex(item => item?.customId === userData?.customId);
                if (foundIndex !== -1) {
                    completeList[foundIndex] = { ...userData, comments: list };
                }
                setUseCaseList(completeList);

                setCommentText("")
                handleSnackbar("Comment added successfully", Severity.success);
            }


            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    useEffect(() => {
        if (enableEdit) {
            // handleGetStatusList();
        }
    }, [enableEdit]);


    useEffect(() => {
        setLoading(true);
        setUserData(currentUseCaseData);

        if (currentUseCaseData?.labels) {
            setSelectedLabels(currentUseCaseData?.labels);
        }

        setTimeout(() => {
            setLoading(false);
        }, 400)
    }, [currentUseCaseData]);

    useEffect(() => {
        if (useCasePropertiesData) {
            setTypeList(useCasePropertiesData["type"]);
            // setStateList(useCasePropertiesData["state"]);
            setStatusList(useCasePropertiesData["status"]);
            setPriorityList(useCasePropertiesData["priority"]);
            setExecutionModeList(useCasePropertiesData["executionMode"]);
            setEnvironmentList(useCasePropertiesData["environment"]);
            setCategoryList(useCasePropertiesData["category"]);

        }
    }, [useCasePropertiesData]);



    console.log(userData, "userData", userData?.name, currentStepText, currentStepIndex);
    console.log(editorValue, "textvalue");
    console.log(userData, "userData")

    return (
        <Box className="flex flex-col w-full h-full z-20 absolute left-0 top-0 overflow-hidden "
            // bgcolor={theme.palette.primary.layout_col}
            borderRadius={theme.shape.borderRadius}
            bgcolor={theme.palette.common.white}
        >

            {loading && <Box className="flex-1 flex flex-col items-center justify-center backdrop-blur-sm absolute left-0 top-0 right-0 bottom-0 z-10">
                <CircularProgress />
            </Box>}



            <Box className={`flex flex-col px-4 py-4 gap-4 w-full h-full relative rounded-lg overflow-hidden`} >



                {/* top */}
                <Box className="w-full  flex items-center justify-between gap-0 ">
                    {/* left */}
                    <Box className="flex-1 flex items-center gap-2 w-full pr-4 ">

                        <IconButton
                            size="small"
                            value="check"
                            // selected={enableEdit}
                            onChange={() => setEnableEdit((prevSelected) => !prevSelected)}
                            onClick={closeDialog}
                        >
                            <ArrowBack />
                        </IconButton>

                        <Box className="w-full flex flex-col ">

                            {/* custom Id */}
                            <SectionContainer enableEdit={enableEdit} currentSection={EditSection.customId} dataAvailable={userData?.customId} onClick={(e) => handleEnableSectionEdit(e, EditSection.customId)}>
                                {enableEdit && enableEdit === EditSection.customId &&
                                    <HeadingComponent heading="ID" hideColon={enableEdit && enableEdit === EditSection.customId} />}

                                {enableEdit && enableEdit === EditSection.customId ?
                                    <Box className="w-full flex flex-col gap-0">
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            name="customId"
                                            // label="Custom ID"
                                            required
                                            fullWidth
                                            // focused={userData?.customId}
                                            // autoFocus
                                            value={userData?.customId}
                                            onChange={handleChange}
                                        />
                                    </Box>
                                    :
                                    <Box className="flex items-center gap-1">
                                        <TestCaseCustomIcon sx={{ color: theme.palette.secondary.main }} />
                                        <ValueComponent color={theme.palette.secondary.main} text={userData?.customId} />
                                    </Box>
                                }
                            </SectionContainer>


                            {/* Title */}
                            <SectionContainer enableEdit={enableEdit} currentSection={EditSection.title} dataAvailable={userData?.title} onClick={(e) => handleEnableSectionEdit(e, EditSection.title)}>
                                {enableEdit && enableEdit === EditSection.title &&
                                    <HeadingComponent heading="Title" hideColon={enableEdit && enableEdit === EditSection.title ? true : false} />}

                                {enableEdit && enableEdit === EditSection.title ?
                                    <Box className="w-full flex flex-col gap-0">
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            name="title"
                                            required
                                            // label="Title"
                                            // autoFocus
                                            fullWidth
                                            multiline
                                            minRows={1}
                                            maxRows={2}
                                            // focused={userData?.title}
                                            value={userData?.title}
                                            onChange={handleChange}
                                        />
                                    </Box>
                                    :
                                    <Box className="flex flex-col gap-0">
                                        <Typography fontSize={font.size.fs_3_5} fontWeight={600} className="" sx={{ color: theme.palette.text.primary }}>{userData?.title}</Typography>
                                    </Box>
                                }
                            </SectionContainer>
                        </Box>
                    </Box>

                    {/* right */}
                    <Box className="w-[32%] flex items-center justify-end gap-2 ">

                        <IconButton onClick={handleToggleMorePopup}>
                            <MoreHoriz />
                        </IconButton>

                        {false && <Button
                            size="medium"
                            variant={isItemChatWindow ? "contained" : "outlined"}
                            disableElevation
                            onClick={handleOpenAIPopup}
                            startIcon={<AutoAwesome />}
                        // endIcon={<KeyboardArrowDown />}
                        >
                            {isItemChatWindow && <Description sx={{ fontSize: font.size.fs_4 }} />}
                            {!isItemChatWindow ? "Generate" : currentAIEntityData?.label}
                        </Button>}

                        {false && <ToggleButton
                            size="small"
                            value="check"
                            selected={enableEdit}
                            onChange={handleEditClick}
                        >
                            <Edit />
                        </ToggleButton>}

                        {false && <ToggleButton
                            size="small"
                            value="check"
                            selected={false}
                            // disabled={!enableEdit || currentFolderData?.type !== FolderType.module || !handleCheckValues()}
                            disabled={!enableEdit || !handleCheckValues()}
                            onClick={() => handleContinueStory(userData)}
                        >
                            <Save />
                        </ToggleButton>}

                        {/* <IconButton
                                disabled={currentFolderData?.type !== FolderType.module || !handleCheckValues()}
                                onClick={() => handleContinueStory(userData)}
                            >
                                <Save />
                            </IconButton> */}

                        {/* <ToggleButton
                            size="small"
                            value="check"
                            selected={enableEdit}
                            onChange={() => setEnableEdit((prevSelected) => !prevSelected)}
                            onClick={closeDialog}
                        >
                            <Close />
                        </ToggleButton> */}
                    </Box>
                </Box>

                {/* bottom */}
                <Box className="w-full  py-1 flex-1 flex gap-4 overflow-y-auto">
                    {/* left */}
                    <Box className={`flex-1 flex flex-col pr-4   gap-6  overflow-y-auto `}>

                        {/* menu */}
                        <Box className="w-full flex items-center mb-4 gap-4">
                            <Button size="small" variant="outlined" startIcon={<Attachment sx={{ transform: "rotate(90deg)" }} />} disabled={sections[EditSection.attachments]} onClick={() => handleToggleSection(EditSection.attachments)}>Attachment</Button>
                            <Button size="small" variant="outlined" startIcon={<PersonAddAlt />} disabled={sections[EditSection.members]} onClick={() => handleToggleSection(EditSection.members)}>Members</Button>
                            <Button size="small" variant="outlined" startIcon={<Label />} disabled={false} onClick={handleToggleLabelPopup}>Labels</Button>
                            <Button size="small" variant="outlined" startIcon={<Checklist />} disabled={sections[EditSection.checklist]} onClick={() => handleToggleSection(EditSection.checklist)}>Checklist</Button>
                            <IconButton sx={{ borderRadius: theme.shape.borderRadius }} onClick={handleToggleMenuPopup}>
                                <MoreHoriz />
                            </IconButton>
                        </Box>


                        {userData?.labels?.length > 0 && <SectionContainer enableEdit={enableEdit} currentSection={EditSection.label} dataAvailable={true}>
                            <Box className="flex flex-wrap gap-2">
                                {
                                    userData?.labels?.map((label, index) => (
                                        <Box key={label?.name + index} className="flex items-center ">
                                            <LabelBadge label={label} />
                                        </Box>
                                    ))
                                }
                            </Box>

                        </SectionContainer>}

                        {/* steps */}
                        <SectionContainer enableEdit={enableEdit} currentSection={EditSection.steps} dataAvailable={userData?.steps?.length > 0} onClick={(e) => handleEnableSectionEdit(e, EditSection.steps)}>
                            <Box className="flex items-center justify-between gap-2 pointer-events-auto" onClick={e => e.stopPropagation()}>
                                <HeadingComponent heading="Steps" hideColon={enableEdit && enableEdit === EditSection.steps ? true : false} />

                            </Box>

                            <Box className=" h-fit flex flex-col gap-2  overflow-y-auto ">
                                <Box
                                    className={`flex flex-col gap-2  overflow-y-auto ${userData?.steps?.length > 0 ? "h-48" : "h-auto"}
                                  ${userData?.steps?.length > 0 ? " pl-4 pr-4" : "pl-0"}`}
                                >
                                    {userData?.steps?.length > 0 ?
                                        <TableVirtuoso
                                            style={{ height: "100%", width: "100%", overflowX: 'auto', borderRadius: theme.shape.borderRadius }}
                                            data={userData?.steps}
                                            components={TableComponents}
                                            fixedHeaderContent={() => (
                                                <TableHead>
                                                    {
                                                        columns?.map((item, index) => (
                                                            <TableCell key={index} align={item?.align} padding={item?.padding} className="whitespace-nowrap" style={{ margin: "0 1rem !important", fontWeight: "bold", minWidth: item?.width, maxWidth: item?.width, background: item?.background, borderRight: `1px solid ${grey[300]}` }}>
                                                                {item?.children?.length === 0 ? <Box className="py-2 flex items-center gap-2" sx={{ px: item?.px }}>
                                                                    <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap"> {item?.data}</Typography>
                                                                </Box> :
                                                                    <Box className=" flex flex-col h-full items-center  gap-0 ">
                                                                        {/* <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap"> {item?.data}</Typography> */}
                                                                        <Box className="flex-1 w-full h-full flex   gap-0  " >
                                                                            {
                                                                                item?.children?.map((subItem, index) => (
                                                                                    <Box className="flex-1 flex items-center justify-center gap-2" sx={{ borderRight: index !== item?.children?.length - 1 && `1px solid ${grey[300]}` }}>
                                                                                        <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap"> {subItem?.data}</Typography>
                                                                                    </Box>

                                                                                ))
                                                                            }
                                                                        </Box>
                                                                    </Box>}
                                                            </TableCell>
                                                        ))
                                                    }
                                                </TableHead>
                                            )}


                                            itemContent={(index, data) => (
                                                <TableRow sx={{ "&:hover": { background: grey[100], } }}>

                                                    <TableCell padding="none" align="left" sx={{ minWidth: "40px", maxWidth: "40px", cursor: "pointer", borderRight: `1px solid ${grey[300]}` }}>
                                                        <Box className="flex items-center justify-center relative">
                                                            <Typography>{index + 1}</Typography>

                                                            <LightTooltip title="Add Step below" >
                                                                <IconButton
                                                                    onClick={() => handleAddRowBelow("steps", index)}
                                                                    sx={{ position: "absolute", bottom: "-10px", left: "-2px" }}
                                                                >
                                                                    <Add sx={{ fontSize: font.size.fs_5, color: blue[500] }} />
                                                                </IconButton>
                                                            </LightTooltip>
                                                        </Box>
                                                    </TableCell>

                                                    <TableCell padding="none" align="left" sx={{ minWidth: "154px", maxWidth: "154px", cursor: "pointer", borderRight: `1px solid ${grey[300]}` }}>
                                                        <Box className="flex items-center  px-4 ">
                                                            {/* <Typography fontSize={font.size.fs_4}>{data?.step}</Typography> */}
                                                            <TextField fullWidth size="small" name="step" value={data?.step} onChange={(e) => handleCurrentListItem(e.target.name, index, e.target.value)} />
                                                        </Box>
                                                    </TableCell>

                                                    <TableCell padding="none" align="left" sx={{ minWidth: "180px", maxWidth: "180px", cursor: "pointer", borderRight: `1px solid ${grey[300]}` }}>
                                                        <Box className="flex items-center gap-0">
                                                            <StyledTextField
                                                                fullWidth
                                                                // label="xpath"
                                                                name="xpath"
                                                            // defaultValue={data?.elementId?.xpath}
                                                            // onBlur={(e) => handleChange(e, index)}
                                                            />
                                                        </Box>
                                                    </TableCell>

                                                    <TableCell padding="none" align="left" sx={{ minWidth: "320px", maxWidth: "320px", cursor: "pointer", }}>
                                                        <Box className="flex items-center gap-0">
                                                            {/* <Typography fontSize={font.size.fs_4}>{data?.expectedResult}</Typography> */}
                                                            <TextField fullWidth size="small" name="expectedResult" value={data?.expectedResult} onChange={(e) => handleCurrentListItem(e.target.name, index, e.target.value)} />
                                                        </Box>

                                                    </TableCell>


                                                </TableRow>

                                            )}
                                        />
                                        :

                                        <Box
                                            className={`flex  ${enableEdit && enableEdit === EditSection.steps && "bg-gray-100 items-center justify-center    "} `} borderRadius={theme.shape.borderRadius}
                                        >
                                            {/* <Typography>{enableEdit && enableEdit === EditSection.steps ? "Add" : "None"}</Typography> */}
                                            <ValueComponent text={enableEdit && enableEdit === EditSection.steps ? "Add" : "None"} />
                                            {enableEdit && enableEdit === EditSection.steps && <IconButton onClick={() => handleAddRowBelow("steps", 0)}>
                                                <Add />
                                            </IconButton>}
                                        </Box>}
                                </Box>
                            </Box>
                        </SectionContainer>

                        {/* precondition */}
                        <SectionContainer enableEdit={enableEdit} currentSection={EditSection.preCondition} dataAvailable={userData?.preCondition?.length > 0} onClick={(e) => handleEnableSectionEdit(e, EditSection.preCondition)}>
                            <HeadingComponent heading="Precondition" hideColon={enableEdit && enableEdit === EditSection.preCondition ? true : false} />

                            <Box className=" h-fit flex flex-col gap-2  overflow-y-auto ">
                                <Box
                                    className={`flex flex-col gap-2  overflow-y-auto ${enableEdit && enableEdit === EditSection.preCondition ? "h-48" : ""}
                                  ${userData?.preCondition?.length > 0 ? " pl-4 pr-4" : "pl-0"}`}
                                >
                                    {userData?.preCondition?.length > 0 ?
                                        userData?.preCondition?.map((currentStep, index) => (
                                            enableEdit && enableEdit === EditSection.preCondition ? (
                                                <Box
                                                    key={index}
                                                    className="flex items-center gap-1  relative"
                                                >
                                                    <LightTooltip title="Add Step below" >
                                                        <IconButton
                                                            onClick={() => handleAddRowBelow("preCondition", index)}
                                                            sx={{ position: "relative", top: "6px" }}
                                                        >
                                                            <Add sx={{ fontSize: font.size.fs_5, color: blue[500] }} />
                                                        </IconButton>
                                                    </LightTooltip>

                                                    <Typography fontSize={font.size.fs_5} className="">{index + 1}.</Typography>

                                                    <Box
                                                        className="flex items-center gap-2 hover:cursor-grab border rounded-lg w-full"
                                                        draggable
                                                        onDragStart={(e) => handleDragStart("preCondition", index, e)}
                                                        onDragOver={(e) => handleDragOver("preCondition", e)}
                                                        onDrop={(e) => handleDrop("preCondition", index, e)}
                                                    >


                                                        <IconButton>
                                                            <DragHandleIcon />
                                                        </IconButton>

                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            placeholder="Step description"
                                                            value={currentStep}
                                                            onChange={(e) => handleCurrentListItem("preCondition", index, e.target.value)}
                                                            sx={{

                                                                px: 0,
                                                                "& .MuiOutlinedInput-root": {
                                                                    fontSize: font.size.fs_5,
                                                                    "& fieldset": {
                                                                        borderRadius: 0,
                                                                        border: "none"
                                                                        // borderColor: theme.palette.common.black
                                                                    },
                                                                    "&:hover fieldset": {
                                                                        borderRadius: 0,
                                                                        border: "none"
                                                                    },
                                                                    "&.Mui-focused fieldset": {
                                                                        borderRadius: 0,
                                                                        border: "none"
                                                                    },
                                                                },
                                                            }}
                                                        />
                                                        <IconButton color="error" onClick={() => handleRemoveListItem("preCondition", index)}><Delete /></IconButton>
                                                    </Box>
                                                </Box>
                                            ) : (
                                                <Box key={index} className="flex items-center gap-2 " >

                                                    <ValueComponent text={`${index + 1}.`} />
                                                    <ValueComponent text={currentStep} />
                                                </Box>
                                            )
                                        ))
                                        :

                                        <Box className={`flex  ${enableEdit && enableEdit === EditSection.preCondition && "bg-gray-100 items-center justify-center    "} `} borderRadius={theme.shape.borderRadius} >
                                            {/* <Typography>{enableEdit && enableEdit === EditSection.steps ? "Add" : "None"}</Typography> */}
                                            <ValueComponent text={enableEdit && enableEdit === EditSection.preCondition ? "Add" : "None"} />
                                            {enableEdit && enableEdit === EditSection.preCondition && <IconButton onClick={() => handleAddRowBelow("preCondition", 0)}>
                                                <Add />
                                            </IconButton>}
                                        </Box>}
                                </Box>
                            </Box>
                        </SectionContainer>

                        {/* postCondition */}
                        <SectionContainer enableEdit={enableEdit} currentSection={EditSection.postCondition} dataAvailable={userData?.postCondition?.length > 0} onClick={(e) => handleEnableSectionEdit(e, EditSection.postCondition)}>
                            <HeadingComponent heading="PostCondition" hideColon={enableEdit && enableEdit === EditSection.postCondition ? true : false} />

                            <Box className=" h-fit flex flex-col gap-2  overflow-y-auto ">
                                <Box
                                    className={`flex flex-col gap-2  overflow-y-auto ${enableEdit && enableEdit === EditSection.postCondition ? "h-48" : ""}
                                  ${userData?.postCondition?.length > 0 ? " pl-4 pr-4" : "pl-0"}`}
                                >
                                    {userData?.postCondition?.length > 0 ?
                                        userData?.postCondition?.map((currentStep, index) => (
                                            enableEdit && enableEdit === EditSection.postCondition ? (
                                                <Box
                                                    key={index}
                                                    className="flex items-center gap-1  relative"
                                                >
                                                    <LightTooltip title="Add Step below" >
                                                        <IconButton
                                                            onClick={() => handleAddRowBelow("postCondition", index)}
                                                            sx={{ position: "relative", top: "6px" }}
                                                        >
                                                            <Add sx={{ fontSize: font.size.fs_5, color: blue[500] }} />
                                                        </IconButton>
                                                    </LightTooltip>

                                                    <Typography fontSize={font.size.fs_5} className="">{index + 1}.</Typography>

                                                    <Box
                                                        className="flex items-center gap-2 hover:cursor-grab border rounded-lg w-full"
                                                        draggable
                                                        onDragStart={(e) => handleDragStart("postCondition", index, e)}
                                                        onDragOver={(e) => handleDragOver("postCondition", e)}
                                                        onDrop={(e) => handleDrop("postCondition", index, e)}
                                                    >


                                                        <IconButton>
                                                            <DragHandleIcon />
                                                        </IconButton>

                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            placeholder="Step description"
                                                            value={currentStep}
                                                            onChange={(e) => handleCurrentListItem("postCondition", index, e.target.value)}
                                                            sx={{

                                                                px: 0,
                                                                "& .MuiOutlinedInput-root": {
                                                                    fontSize: font.size.fs_5,
                                                                    "& fieldset": {
                                                                        borderRadius: 0,
                                                                        border: "none"
                                                                        // borderColor: theme.palette.common.black
                                                                    },
                                                                    "&:hover fieldset": {
                                                                        borderRadius: 0,
                                                                        border: "none"
                                                                    },
                                                                    "&.Mui-focused fieldset": {
                                                                        borderRadius: 0,
                                                                        border: "none"
                                                                    },
                                                                },
                                                            }}
                                                        />
                                                        <IconButton color="error" onClick={() => handleRemoveListItem("postCondition", index)}><Delete /></IconButton>
                                                    </Box>
                                                </Box>
                                            ) : (
                                                <Box key={index} className="flex items-center gap-2 " >

                                                    <ValueComponent text={`${index + 1}.`} />
                                                    <ValueComponent text={currentStep} />
                                                </Box>
                                            )
                                        ))
                                        :

                                        <Box
                                            className={`flex  ${enableEdit && enableEdit === EditSection.postCondition && "bg-gray-100 items-center justify-center    "} `} borderRadius={theme.shape.borderRadius}
                                        >
                                            {/* <Typography>{enableEdit && enableEdit === EditSection.steps ? "Add" : "None"}</Typography> */}
                                            <ValueComponent text={enableEdit && enableEdit === EditSection.postCondition ? "Add" : "None"} />
                                            {enableEdit && enableEdit === EditSection.postCondition && <IconButton onClick={() => handleAddRowBelow("postCondition", 0)}>
                                                <Add />
                                            </IconButton>}
                                        </Box>}
                                </Box>
                            </Box>
                        </SectionContainer>


                        {/* expected result */}
                        <SectionContainer enableEdit={enableEdit} currentSection={EditSection.expectedResult} dataAvailable={userData?.expectedResult} onClick={(e) => handleEnableSectionEdit(e, EditSection.expectedResult)}>
                            <HeadingComponent heading="Expected Result" hideColon={enableEdit && enableEdit === EditSection.expectedResult ? true : false} />

                            {enableEdit && enableEdit === EditSection.expectedResult ?
                                <Box className="w-full flex flex-col gap-0">
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        name="expectedResult"
                                        required
                                        // label="Title"
                                        // autoFocus
                                        fullWidth
                                        multiline
                                        minRows={1}
                                        maxRows={2}
                                        // focused={userData?.title}
                                        value={userData?.expectedResult}
                                        onChange={handleChange}
                                    />
                                </Box>
                                :
                                <Box className="flex flex-col gap-0">
                                    <ValueComponent text={userData?.expectedResult ? userData?.expectedResult : "None"} />
                                </Box>
                            }
                        </SectionContainer>

                        {/* actual result */}
                        <SectionContainer enableEdit={enableEdit} currentSection={EditSection.actualResult} dataAvailable={userData?.actualResult} onClick={(e) => handleEnableSectionEdit(e, EditSection.actualResult)}>
                            <HeadingComponent heading="Actual Result" hideColon={enableEdit && enableEdit === EditSection.actualResult ? true : false} />

                            {enableEdit && enableEdit === EditSection.actualResult ?
                                <Box className="w-full flex flex-col gap-0">
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        name="actualResult"
                                        required
                                        // label="Title"
                                        // autoFocus
                                        fullWidth
                                        multiline
                                        minRows={1}
                                        maxRows={2}
                                        // focused={userData?.title}
                                        value={userData?.actualResult}
                                        onChange={handleChange}
                                    />
                                </Box>
                                :
                                <Box className="flex flex-col gap-0">
                                    <ValueComponent text={userData?.actualResult ? userData?.actualResult : "None"} />
                                </Box>
                            }
                        </SectionContainer>

                        {/* Comments and activity */}
                        <Box className="flex flex-col gap-2">
                            <Box className="flex gap-2">
                                <Button
                                    size="small"
                                    color="primary"
                                    variant={activityType === "comments" ? "outlined" : "text"}
                                    onClick={() => setActivityType("comments")}
                                    className="px-4"
                                >
                                    Comments
                                </Button>
                                <Button
                                    size="small"
                                    color="primary"
                                    variant={activityType === "history" ? "outlined" : "text"}
                                    onClick={() => setActivityType("history")}
                                    className="px-4"
                                >
                                    Activity
                                </Button>
                            </Box>

                            {/* Comments */}
                            {activityType === "comments" &&
                                <SectionContainer enableEdit={enableEdit} currentSection={EditSection.comments} dataAvailable={true}>
                                    {/* <HeadingComponent heading="Comments" hideColon={enableEdit && enableEdit === EditSection.comments ? true : false} /> */}
                                    <Box className="flex gap-1">


                                        {/* <TextField fullWidth placeholder="Add a comment" multiline maxRows={2} /> */}
                                        <Box className="w-full flex flex-col gap-0 "
                                            sx={{
                                                height: "131px", // set the height of the Box component to 100%
                                                overflowY: "auto",
                                                "& .ql-container": {
                                                    // height: "40px",
                                                    maxHeight: "80px",
                                                    // overflowY: "auto"
                                                }
                                            }} >

                                            <ReactQuill
                                                theme="snow"
                                                // styles={{
                                                //     height: '80px', // set the height of the editor
                                                // }}

                                                value={commentText}
                                                onChange={(value) => setCommentText(value)}
                                                placeholder="Add a comment"
                                                modules={{
                                                    toolbar: [
                                                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                                        ['bold', 'italic', 'underline', 'strike'],
                                                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],

                                                    ],

                                                }}
                                            />

                                        </Box>
                                        <Box className=" flex flex-col gap-0 ">
                                            <IconButton onClick={handleContinueComment}>
                                                <Send />
                                            </IconButton>
                                            <IconButton>
                                                <Attachment sx={{ transform: "rotate(90deg)" }} />
                                            </IconButton>
                                        </Box>


                                    </Box>

                                    {/* comment list */}
                                    {enableEdit && enableEdit === EditSection.comments ?
                                        <Box className="flex flex-col gap-0">
                                            comments edit
                                        </Box>
                                        :
                                        <Box className="flex flex-col gap-2">
                                            {
                                                userData?.comments?.reverse()?.map((comment, index) => (
                                                    <Box key={index} className="flex flex-col gap-2 w-full ">
                                                        <CommentComponent comment={comment} />
                                                    </Box>
                                                ))
                                            }
                                        </Box>
                                    }
                                </SectionContainer>}

                            {/* History */}
                            {activityType === "history" &&
                                <SectionContainer enableEdit={enableEdit} currentSection={EditSection.activity} dataAvailable={true}>
                                    {/* <HeadingComponent heading="Activity" hideColon={enableEdit && enableEdit === EditSection.activity ? true : false} /> */}

                                    {enableEdit && enableEdit === EditSection.activity ?
                                        <Box className="flex flex-col gap-0">
                                            activity edit
                                        </Box>
                                        :
                                        <Box className="flex flex-col gap-2">
                                            {
                                                [{ text: "This is activity 1", attachment: [] }, { text: "This is activity 2", attachment: [] }].map((item, index) => (
                                                    <Box key={index} className="flex flex-col gap-0">
                                                        <Box key={index} className="flex items-center gap-2">
                                                            <Avatar sx={{ width: "2rem", height: "2rem", fontSize: font.size.fs_5 }} />
                                                            <Box className="flex flex-col gap-1">
                                                                <Box className="flex items-center gap-2">
                                                                    <Chip size="small" label="User Name" />
                                                                    <Typography fontSize={font.size.fs_5} className="opacity-80">30 Jan 2025 02:00PM</Typography>
                                                                </Box>
                                                                <ValueComponent text={item?.text} />
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                ))
                                            }
                                        </Box>
                                    }
                                </SectionContainer>}
                        </Box>


                    </Box>


                    {/* right */}
                    <Box className={`w-[32%] h-full flex flex-col     box-border relative  gap-6 overflow-y-auto `}>


                        {/* properties container */}
                        {!currentFieldConfiguration ?
                            <Box className={`h-full flex flex-col gap-6 `} >


                                <Box className={`flex flex-col gap-2 `}>

                                    {/* other fields */}
                                    <AccordionComponent
                                        expanded={expandedAccordion === AccordionName.otherFields}
                                        onChange={handleChangeAccordion(AccordionName.otherFields)}
                                    >
                                        <AccordionComponentSummary expandIcon={<ExpandMore />}    >
                                            <Box className="flex items-center gap-2 w-full">
                                                <HeadingComponent heading="Other Fields" />
                                                {expandedAccordion !== AccordionName.otherFields &&
                                                    <Box className="flex-1 flex items-center gap-0 flex-wrap line-clamp-2  overflow-hidden  ">
                                                        {
                                                            ["Test Type", "Priority", "Category", "Candidate for Regression", "Story Stage"]?.map((item, index, arr) => <Box className="flex items-center mr-2">
                                                                <Typography key={index} fontSize={font.size.fs_5} className="" >{`${item}`} </Typography>
                                                                {index < arr.length - 1 && <Typography key={index} fontSize={font.size.fs_5} className="" >,</Typography>}
                                                            </Box>)
                                                        }
                                                    </Box>}
                                            </Box>
                                        </AccordionComponentSummary>
                                        <AccordionComponentDetails>

                                            <AccordionDetailsContainer>

                                                <SectionContainer enableEdit={enableEdit} currentSection={EditSection.testType} onClick={(e) => handleEnableSectionEdit(e, EditSection.testType)} dataAvailable={userData?.testType}>
                                                    <HeadingComponent heading="Test Type" />

                                                    {enableEdit && enableEdit === EditSection.testType ?
                                                        <KeyValuePairComponent
                                                            // heading={"Test Type"}
                                                            value={<StyledSelect
                                                                size="small"
                                                                variant="outlined"
                                                                name="type"
                                                                fullWidth
                                                                value={userData?.type || ''}
                                                                onChange={handleChange}
                                                            >
                                                                {
                                                                    typeList?.map((item, index) => (
                                                                        <MenuItem key={index} value={item?.key}>{item?.value}</MenuItem>
                                                                    ))
                                                                }
                                                            </StyledSelect>} />
                                                        :
                                                        <Box className="flex flex-col gap-0">
                                                            <Typography fontSize={font.size.fs_4} className="" > {userData?.type || "None"} </Typography>
                                                        </Box>
                                                    }
                                                </SectionContainer>

                                                <SectionContainer enableEdit={enableEdit} currentSection={EditSection.priority} onClick={(e) => handleEnableSectionEdit(e, EditSection.priority)} dataAvailable={userData?.priority}>
                                                    <HeadingComponent heading="Priority" />
                                                    {enableEdit && enableEdit === EditSection.priority ?
                                                        <KeyValuePairComponent
                                                            // heading={"Priority"}
                                                            value={
                                                                <StyledSelect
                                                                    size="small"
                                                                    variant="outlined"
                                                                    name="priority"
                                                                    fullWidth
                                                                    // disabled={!userStoryData?.status}
                                                                    value={userData?.priority || ''}
                                                                    onChange={handleChange}
                                                                >
                                                                    {
                                                                        priorityList?.map((item, index) => (
                                                                            <MenuItem key={index} value={item?.key}>{item?.value}</MenuItem>
                                                                        ))
                                                                    }
                                                                </StyledSelect>
                                                            }
                                                        />
                                                        :
                                                        <Box className="flex flex-col gap-0">

                                                            <Typography fontSize={font.size.fs_4} className="" > {userData?.priority || "None"} </Typography>
                                                        </Box>
                                                    }
                                                </SectionContainer>

                                                <SectionContainer enableEdit={enableEdit} currentSection={EditSection.category} onClick={(e) => handleEnableSectionEdit(e, EditSection.category)} dataAvailable={userData?.category}>
                                                    <HeadingComponent heading="Category" />

                                                    {enableEdit && enableEdit === EditSection.category ?
                                                        <KeyValuePairComponent
                                                            // heading={"Category"}
                                                            value={
                                                                <StyledSelect
                                                                    size="small"
                                                                    variant="outlined"
                                                                    name="category"
                                                                    fullWidth
                                                                    // disabled={!userStoryData?.status}
                                                                    value={userData?.category || ''}
                                                                    onChange={handleChange}
                                                                >
                                                                    {
                                                                        categoryList?.map((item, index) => (
                                                                            <MenuItem key={index} value={item?.key}>{item?.value}</MenuItem>
                                                                        ))
                                                                    }
                                                                </StyledSelect>
                                                            }
                                                        />
                                                        :
                                                        <Box className="flex flex-col gap-0">

                                                            <Typography fontSize={font.size.fs_4} className="" > {userData?.category || "None"} </Typography>
                                                        </Box>
                                                    }
                                                </SectionContainer>

                                                <SectionContainer enableEdit={enableEdit} currentSection={EditSection.candidateForRegression} onClick={(e) => handleEnableSectionEdit(e, EditSection.candidateForRegression)} dataAvailable={userData?.candidateForRegression}>
                                                    <HeadingComponent heading="Candidate for Regression" />
                                                    {enableEdit && enableEdit === EditSection.candidateForRegression ?
                                                        <KeyValuePairComponent
                                                            // heading={"Candidate for Regression"}
                                                            value={
                                                                <Checkbox name="isRegression" checked={userData?.isRegression} onChange={handleChange} />
                                                            }
                                                        />
                                                        :
                                                        <Box className="flex flex-col items-start gap-0">
                                                            {/* <HeadingComponent heading="Candidate for Regression" /> */}
                                                            <Checkbox name="isRegression" checked={userData?.isRegression} onChange={handleChange} />
                                                            {/* <Typography fontSize={font.size.fs_4} className="" > {userData?.candidateForRegression || "None"} </Typography> */}
                                                        </Box>
                                                    }
                                                </SectionContainer>

                                                <SectionContainer enableEdit={enableEdit} currentSection={EditSection.status} onClick={(e) => handleEnableSectionEdit(e, EditSection.status)} dataAvailable={userData?.status}>
                                                    <HeadingComponent heading="Status" />

                                                    {enableEdit && enableEdit === EditSection.status ?
                                                        <KeyValuePairComponent
                                                            // heading={"Status"}
                                                            value={
                                                                <StyledSelect
                                                                    size="small"
                                                                    variant="outlined"
                                                                    name="status"
                                                                    fullWidth
                                                                    // disabled={!userData?.status}
                                                                    value={userData?.status || ''}
                                                                    onChange={handleChange}
                                                                >
                                                                    {
                                                                        statusList?.map((item, index) => (
                                                                            <MenuItem key={index} value={item?.key}>{item?.value}</MenuItem>
                                                                        ))
                                                                    }
                                                                </StyledSelect>
                                                            }
                                                        />
                                                        :
                                                        <Box className="w-full flex flex-col gap-0">

                                                            <Typography fontSize={font.size.fs_4} className="" > {userData?.status || "None"} </Typography>
                                                        </Box>
                                                    }
                                                </SectionContainer>

                                                <SectionContainer enableEdit={enableEdit} currentSection={EditSection.environment} onClick={(e) => handleEnableSectionEdit(e, EditSection.environment)} dataAvailable={userData?.environment}>
                                                    <HeadingComponent heading="Environment" />

                                                    {enableEdit && enableEdit === EditSection.environment ?
                                                        <KeyValuePairComponent
                                                            // heading={"Environment"}
                                                            value={
                                                                <StyledSelect
                                                                    size="small"
                                                                    variant="outlined"
                                                                    name="environment"
                                                                    fullWidth
                                                                    // disabled={!userData?.status}
                                                                    value={userData?.environment || ''}
                                                                    onChange={handleChange}
                                                                >
                                                                    {
                                                                        environmentList?.map((item, index) => (
                                                                            <MenuItem key={index} value={item?.key}>{item?.value}</MenuItem>
                                                                        ))
                                                                    }
                                                                </StyledSelect>
                                                            }
                                                        />
                                                        :
                                                        <Box className="w-full flex flex-col gap-0">

                                                            <Typography fontSize={font.size.fs_4} className="" > {userData?.environment || "None"} </Typography>
                                                        </Box>
                                                    }
                                                </SectionContainer>

                                                {false && <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.moscow)} dataAvailable={userData?.moscow}>
                                                    {enableEdit && enableEdit === EditSection.moscow ?
                                                        <KeyValuePairComponent
                                                            heading={"MOSCOW"}
                                                            value={<StyledSelect
                                                                size="small"
                                                                variant="outlined"
                                                                name="priority"
                                                                fullWidth
                                                            // disabled={!userStoryData?.status}
                                                            // value={userStoryData?.status || ''}
                                                            // onChange={handleChange}
                                                            >
                                                                {
                                                                    ["Must have", "Should have", "Could have", "Won't have"]?.map((status, index) => (
                                                                        <MenuItem key={index} value={status}>{status}</MenuItem>
                                                                    ))
                                                                }
                                                            </StyledSelect>} />
                                                        :
                                                        <Box className="flex flex-col gap-0">
                                                            <HeadingComponent heading="MOSCOW" />
                                                            <Typography fontSize={font.size.fs_4} className="" > {userData?.moscow || "None"} </Typography>
                                                        </Box>
                                                    }
                                                </SectionContainer>}

                                                {false && <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.businessValue)} dataAvailable={userData?.businessValue}>
                                                    {enableEdit && enableEdit === EditSection.businessValue ?
                                                        <KeyValuePairComponent heading={"Business Value"} value={<ValueComponent text={`Value`} />} />
                                                        :
                                                        <Box className="flex flex-col gap-0">
                                                            <HeadingComponent heading="Business Value" />
                                                            <Typography fontSize={font.size.fs_4} className="" > {userData?.businessValue || "None"} </Typography>
                                                        </Box>
                                                    }
                                                </SectionContainer>}

                                                {false && <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.userPoints)}>
                                                    {enableEdit && enableEdit === EditSection.userPoints ?
                                                        <KeyValuePairComponent heading={"User Points"} value={<ValueComponent text={`Value`} />} />
                                                        :
                                                        <Box className="flex flex-col gap-0">
                                                            <HeadingComponent heading="User Points" />
                                                            <Typography fontSize={font.size.fs_4} className="" > {userData?.priority || "None"} </Typography>
                                                        </Box>
                                                    }
                                                </SectionContainer>}

                                                {false && <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.confidenciality)}>
                                                    {enableEdit && enableEdit === EditSection.confidenciality ?
                                                        <KeyValuePairComponent heading={"Confidenciality"} value={<StyledSelect
                                                            size="small"
                                                            variant="outlined"
                                                            name="storyType"
                                                            fullWidth
                                                        // disabled={!userStoryData?.status}
                                                        // value={userStoryData?.status || ''}
                                                        // onChange={handleChange}
                                                        >
                                                            {
                                                                ["Feature", "Epic", "Theme", "Story"]?.map((status, index) => (
                                                                    <MenuItem key={index} value={status}>{status}</MenuItem>
                                                                ))
                                                            }
                                                        </StyledSelect>} />
                                                        :
                                                        <Box className="flex flex-col gap-0">
                                                            <HeadingComponent heading="Confidenciality" />
                                                            <Typography fontSize={font.size.fs_4} className="" > {userData?.priority || "None"} </Typography>
                                                        </Box>
                                                    }
                                                </SectionContainer>}

                                                {false && <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.verificationMethod)}>
                                                    {enableEdit && enableEdit === EditSection.verificationMethod ?
                                                        <KeyValuePairComponent heading={"Verification Methods"} value={<StyledSelect
                                                            size="small"
                                                            variant="outlined"
                                                            name="storyType"
                                                            fullWidth
                                                        // disabled={!userStoryData?.status}
                                                        // value={userStoryData?.status || ''}
                                                        // onChange={handleChange}
                                                        >
                                                            {
                                                                ["Testing", "Inspection", "Demo", "Analysis"]?.map((status, index) => (
                                                                    <MenuItem key={index} value={status}>{status}</MenuItem>
                                                                ))
                                                            }
                                                        </StyledSelect>} />
                                                        :
                                                        <Box className="flex flex-col gap-0">
                                                            <HeadingComponent heading="Verification Methods" />
                                                            <Typography fontSize={font.size.fs_4} className="" > {userData?.priority || "None"} </Typography>
                                                        </Box>
                                                    }
                                                </SectionContainer>}

                                                {false && <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.userPersona)}>
                                                    {enableEdit && enableEdit === EditSection.userPersona ?
                                                        <KeyValuePairComponent heading={"User Persona"} value={<ValueComponent text={`Value`} />} />
                                                        :
                                                        <Box className="flex flex-col gap-0">
                                                            <HeadingComponent heading="User Persona" />
                                                            <Typography fontSize={font.size.fs_4} className="" > {userData?.priority || "None"} </Typography>
                                                        </Box>
                                                    }
                                                </SectionContainer>}



                                            </AccordionDetailsContainer>
                                        </AccordionComponentDetails>
                                    </AccordionComponent>

                                    {/* details */}
                                    <AccordionComponent
                                        expanded={expandedAccordion === AccordionName.details}
                                        onChange={handleChangeAccordion(AccordionName.details)}
                                    >
                                        <AccordionComponentSummary expandIcon={<ExpandMore />}    >
                                            <Box className="flex items-center gap-2 w-full">
                                                <HeadingComponent heading="Details" />
                                                {expandedAccordion !== AccordionName.details &&
                                                    <Box className="flex-1 flex items-center gap-0 flex-wrap line-clamp-2  overflow-hidden  ">
                                                        {
                                                            ["Created By", "Creation Date", "Assigned To",]?.map((item, index, arr) => <Box className="flex items-center mr-2">
                                                                <Typography key={index} fontSize={font.size.fs_5} className="" >{`${item}`} </Typography>
                                                                {index < arr.length - 1 && <Typography key={index} fontSize={font.size.fs_5} className="" >,</Typography>}
                                                            </Box>)
                                                        }
                                                    </Box>}
                                            </Box>
                                        </AccordionComponentSummary>
                                        <AccordionComponentDetails>
                                            <AccordionDetailsContainer>
                                                {false && <SectionContainer enableEdit={enableEdit} currentSection={EditSection.assignedTo} onClick={(e) => handleEnableSectionEdit(e, EditSection.assignedTo)} dataAvailable={userData?.assignedTo}>
                                                    {enableEdit && enableEdit === EditSection.assignedTo ?
                                                        <KeyValuePairComponent
                                                            heading={"Owner"}
                                                            value={
                                                                <StyledSelect
                                                                    size="small"
                                                                    variant="outlined"
                                                                    name="status"
                                                                    fullWidth
                                                                // disabled={!userStoryData?.assignedTo}
                                                                // value={userStoryData?.assignedTo || ''}
                                                                // onChange={handleChange}
                                                                >
                                                                    {
                                                                        ["Arun", "Ritesh", "Vikram", "Rajat"]?.map((status, index) => (
                                                                            <MenuItem key={index} value={status}>{status}</MenuItem>
                                                                        ))
                                                                    }
                                                                </StyledSelect>
                                                            }
                                                        />
                                                        :
                                                        <Box className="flex flex-col gap-0">
                                                            <HeadingComponent heading="Owner" />
                                                            <Typography fontSize={font.size.fs_4} className="" > {userData?.assignedTo || "None"} </Typography>
                                                        </Box>
                                                    }
                                                </SectionContainer>}

                                                <SectionContainer enableEdit={enableEdit} currentSection={EditSection.createdBy} dataAvailable={userData?.createdBy?.name}>
                                                    <HeadingComponent heading="Created By" />
                                                    {enableEdit && enableEdit === EditSection.createdBy ?
                                                        <KeyValuePairComponent
                                                            // heading={"Reporter"}
                                                            value={
                                                                <StyledSelect
                                                                    size="small"
                                                                    variant="outlined"
                                                                    name="createdBy"
                                                                    fullWidth
                                                                    // disabled={!userStoryData?.assignedTo}
                                                                    value={userData?.createdBy?.name || ''}
                                                                    onChange={handleChange}
                                                                >
                                                                    {
                                                                        ["Arun", "Ritesh", "Vikram", "Rajat"]?.map((status, index) => (
                                                                            <MenuItem key={index} value={status}>{status}</MenuItem>
                                                                        ))
                                                                    }
                                                                </StyledSelect>
                                                            }
                                                        />
                                                        :
                                                        <Box className="flex flex-col gap-0">
                                                            <Typography fontSize={font.size.fs_4} className="" > {`${userData?.createdBy?.name} - ${userData?.createdBy?.email}` || "None"} </Typography>
                                                        </Box>
                                                    }
                                                </SectionContainer>

                                                <SectionContainer enableEdit={enableEdit} currentSection={EditSection.assignedTo} dataAvailable={userData?.createdAt}>
                                                    {enableEdit && enableEdit === EditSection.assignedTo ?
                                                        <KeyValuePairComponent
                                                            heading={"Creation Date"}
                                                            value={
                                                                <StyledSelect
                                                                    size="small"
                                                                    variant="outlined"
                                                                    name="status"
                                                                    fullWidth
                                                                // disabled={!userStoryData?.assignedTo}
                                                                // value={userStoryData?.assignedTo || ''}
                                                                // onChange={handleChange}
                                                                >
                                                                    {
                                                                        ["Arun", "Ritesh", "Vikram", "Rajat"]?.map((status, index) => (
                                                                            <MenuItem key={index} value={status}>{status}</MenuItem>
                                                                        ))
                                                                    }
                                                                </StyledSelect>
                                                            }
                                                        />
                                                        :
                                                        <Box className="flex flex-col gap-0">
                                                            <HeadingComponent heading="Creation Date" />
                                                            <Typography fontSize={font.size.fs_4} className="" > {userData?.createdAt || "None"} </Typography>
                                                        </Box>
                                                    }
                                                </SectionContainer>

                                                <SectionContainer enableEdit={enableEdit} currentSection={EditSection.assignedTo} onClick={(e) => handleEnableSectionEdit(e, EditSection.assignedTo)} dataAvailable={userData?.assignedTo}>
                                                    {enableEdit && enableEdit === EditSection.assignedTo ?
                                                        <KeyValuePairComponent
                                                            heading={"Assigned To"}
                                                            value={
                                                                <StyledSelect
                                                                    size="small"
                                                                    variant="outlined"
                                                                    name="status"
                                                                    fullWidth
                                                                // disabled={!userStoryData?.assignedTo}
                                                                // value={userStoryData?.assignedTo || ''}
                                                                // onChange={handleChange}
                                                                >
                                                                    {
                                                                        ["Arun", "Ritesh", "Vikram", "Rajat"]?.map((status, index) => (
                                                                            <MenuItem key={index} value={status}>{status}</MenuItem>
                                                                        ))
                                                                    }
                                                                </StyledSelect>
                                                            }
                                                        />
                                                        :
                                                        <Box className="flex flex-col gap-0">
                                                            <HeadingComponent heading="Assigned To" />
                                                            <Typography fontSize={font.size.fs_4} className="" > {userData?.assignedTo || "None"} </Typography>
                                                        </Box>
                                                    }
                                                </SectionContainer>


                                                {sections[EditSection.label] && <SectionContainer enableEdit={enableEdit} currentSection={EditSection.label} onClick={(e) => handleEnableSectionEdit(e, EditSection.label)} dataAvailable={userData?.label}>
                                                    {enableEdit && enableEdit === EditSection.label ?
                                                        <KeyValuePairComponent
                                                            heading={"Label"}
                                                            value={
                                                                <Box className="flex items-center gap-2">
                                                                    {
                                                                        ["green", "red", "yellow", "blue"]?.map((label, index) => <Label sx={{ color: label, }} />)
                                                                    }
                                                                </Box>
                                                            }
                                                        />
                                                        :
                                                        <Box className="w-full flex flex-col gap-0">
                                                            <HeadingComponent heading="Label" />
                                                            <Typography fontSize={font.size.fs_4} className="" > {userData?.status || "None"} </Typography>
                                                        </Box>
                                                    }
                                                </SectionContainer>}

                                                {false && <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.plannedInRelease)}>
                                                    {enableEdit && enableEdit === EditSection.plannedInRelease ?
                                                        <KeyValuePairComponent
                                                            heading={"Planned in Release"}
                                                            value={<StyledSelect
                                                                size="small"
                                                                variant="outlined"
                                                                name="storyType"
                                                                fullWidth
                                                            // disabled={!userStoryData?.status}
                                                            // value={userStoryData?.status || ''}
                                                            // onChange={handleChange}
                                                            >
                                                                {
                                                                    ["Feature", "Epic", "Theme", "Story"]?.map((status, index) => (
                                                                        <MenuItem key={index} value={status}>{status}</MenuItem>
                                                                    ))
                                                                }
                                                            </StyledSelect>} />
                                                        :
                                                        <Box className="flex flex-col gap-0">
                                                            <HeadingComponent heading="Planned in Release" />
                                                            <Typography fontSize={font.size.fs_4} className="" > {userData?.priority || "None"} </Typography>
                                                        </Box>
                                                    }
                                                </SectionContainer>}

                                                {false && <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.plannedInSprint)}>
                                                    {enableEdit && enableEdit === EditSection.plannedInSprint ?
                                                        <KeyValuePairComponent heading={"Planned in Sprint"} value={<StyledSelect
                                                            size="small"
                                                            variant="outlined"
                                                            name="storyType"
                                                            fullWidth
                                                        // disabled={!userStoryData?.status}
                                                        // value={userStoryData?.status || ''}
                                                        // onChange={handleChange}
                                                        >
                                                            {
                                                                ["Feature", "Epic", "Theme", "Story"]?.map((status, index) => (
                                                                    <MenuItem key={index} value={status}>{status}</MenuItem>
                                                                ))
                                                            }
                                                        </StyledSelect>} />
                                                        :
                                                        <Box className="flex flex-col gap-0">
                                                            <HeadingComponent heading="Planned in Sprint" />
                                                            <Typography fontSize={font.size.fs_4} className="" > {userData?.priority || "None"} </Typography>
                                                        </Box>
                                                    }
                                                </SectionContainer>}


                                            </AccordionDetailsContainer>
                                        </AccordionComponentDetails>
                                    </AccordionComponent>

                                    {/* execution */}
                                    <AccordionComponent
                                        expanded={expandedAccordion === AccordionName.execution}
                                        onChange={handleChangeAccordion(AccordionName.execution)}
                                    >
                                        <AccordionComponentSummary expandIcon={<ExpandMore />}    >
                                            <Box className="flex items-center gap-2 w-full">
                                                <HeadingComponent heading="Execution" />
                                                {expandedAccordion !== AccordionName.execution &&
                                                    <Box className="flex-1 flex items-center gap-0 flex-wrap line-clamp-2  overflow-hidden  ">
                                                        {
                                                            ["Executed By", "Execution Date", "Execution Mode", "Execution Enviroment", "Execution History"]?.map((item, index, arr) => <Box className="flex items-center mr-2">
                                                                <Typography key={index} fontSize={font.size.fs_5} className="" >{`${item}`} </Typography>
                                                                {index < arr.length - 1 && <Typography key={index} fontSize={font.size.fs_5} className="" >,</Typography>}
                                                            </Box>)
                                                        }
                                                    </Box>}
                                            </Box>
                                        </AccordionComponentSummary>
                                        <AccordionComponentDetails>
                                            <AccordionDetailsContainer>

                                                <SectionContainer enableEdit={enableEdit} currentSection={EditSection.executedBy} onClick={(e) => handleEnableSectionEdit(e, EditSection.executedBy)} dataAvailable={userData?.executedBy}>
                                                    <HeadingComponent heading="Executed By" />
                                                    {enableEdit && enableEdit === EditSection.executedBy ?
                                                        <KeyValuePairComponent
                                                            // heading={"Executed By"}
                                                            value={
                                                                <StyledSelect
                                                                    size="small"
                                                                    variant="outlined"
                                                                    name="status"
                                                                    fullWidth
                                                                // disabled={!userStoryData?.assignedTo}
                                                                // value={userStoryData?.assignedTo || ''}
                                                                // onChange={handleChange}
                                                                >
                                                                    {
                                                                        ["Arun", "Ritesh", "Vikram", "Rajat"]?.map((status, index) => (
                                                                            <MenuItem key={index} value={status}>{status}</MenuItem>
                                                                        ))
                                                                    }
                                                                </StyledSelect>
                                                            }
                                                        />
                                                        :
                                                        <Box className="flex flex-col gap-0">

                                                            <Typography fontSize={font.size.fs_4} className="" > {userData?.executedBy || "None"} </Typography>
                                                        </Box>
                                                    }
                                                </SectionContainer>

                                                <SectionContainer enableEdit={enableEdit} currentSection={EditSection.executionDate} onClick={(e) => handleEnableSectionEdit(e, EditSection.assignedTo)} dataAvailable={userData?.executionDate}>
                                                    <HeadingComponent heading="Execution Date" />
                                                    {enableEdit && enableEdit === EditSection.executionDate ?
                                                        <KeyValuePairComponent
                                                            // heading={"Execution Date"}
                                                            value={
                                                                <StyledSelect
                                                                    size="small"
                                                                    variant="outlined"
                                                                    name="status"
                                                                    fullWidth
                                                                // disabled={!userStoryData?.assignedTo}
                                                                // value={userStoryData?.assignedTo || ''}
                                                                // onChange={handleChange}
                                                                >
                                                                    {
                                                                        ["Arun", "Ritesh", "Vikram", "Rajat"]?.map((status, index) => (
                                                                            <MenuItem key={index} value={status}>{status}</MenuItem>
                                                                        ))
                                                                    }
                                                                </StyledSelect>
                                                            }
                                                        />
                                                        :
                                                        <Box className="flex flex-col gap-0">

                                                            <Typography fontSize={font.size.fs_4} className="" > {userData?.executionDate || "None"} </Typography>
                                                        </Box>
                                                    }
                                                </SectionContainer>

                                                <SectionContainer enableEdit={enableEdit} currentSection={EditSection.executionMode} onClick={(e) => handleEnableSectionEdit(e, EditSection.executionMode)} dataAvailable={userData?.executionMode}>
                                                    <HeadingComponent heading="Execution Mode" />
                                                    {enableEdit && enableEdit === EditSection.executionMode ?
                                                        <KeyValuePairComponent
                                                            // heading={"Execution Mode"}
                                                            value={
                                                                <StyledSelect
                                                                    size="small"
                                                                    variant="outlined"
                                                                    name="executionMode"
                                                                    fullWidth
                                                                    // disabled={!userStoryData?.assignedTo}
                                                                    value={userData?.executionMode || ''}
                                                                    onChange={handleChange}
                                                                >
                                                                    {
                                                                        executionModeList?.map((item, index) => (
                                                                            <MenuItem key={index} value={item?.key}>{item?.value}</MenuItem>
                                                                        ))
                                                                    }
                                                                </StyledSelect>
                                                            }
                                                        />
                                                        :
                                                        <Box className="flex flex-col gap-0">

                                                            <Typography fontSize={font.size.fs_4} className="" > {userData?.executionMode || "None"} </Typography>
                                                        </Box>
                                                    }
                                                </SectionContainer>

                                                <SectionContainer enableEdit={enableEdit} currentSection={EditSection.executionEnvironment} onClick={(e) => handleEnableSectionEdit(e, EditSection.executionEnvironment)} dataAvailable={userData?.executionEnvironment}>
                                                    <HeadingComponent heading="Execution Enviroment" />
                                                    {enableEdit && enableEdit === EditSection.executionEnvironment ?
                                                        <KeyValuePairComponent
                                                            // heading={"Execution Enviroment"}
                                                            value={
                                                                <StyledSelect
                                                                    size="small"
                                                                    variant="outlined"
                                                                    name="environment"
                                                                    fullWidth
                                                                    // disabled={!userStoryData?.assignedTo}
                                                                    value={userData?.environment || ''}
                                                                    onChange={handleChange}
                                                                >
                                                                    {
                                                                        environmentList?.map((item, index) => (
                                                                            <MenuItem key={index} value={item?.key}>{item?.value}</MenuItem>
                                                                        ))
                                                                    }
                                                                </StyledSelect>
                                                            }
                                                        />
                                                        :
                                                        <Box className="flex flex-col gap-0">

                                                            <Typography fontSize={font.size.fs_4} className="" > {userData?.executionEnvironment || "None"} </Typography>
                                                        </Box>
                                                    }
                                                </SectionContainer>

                                                <SectionContainer enableEdit={enableEdit} currentSection={EditSection.executionHistory} onClick={(e) => handleEnableSectionEdit(e, EditSection.executionHistory)} dataAvailable={userData?.executionHistory}>
                                                    <HeadingComponent heading="Execution History" />
                                                    {enableEdit && enableEdit === EditSection.executionHistory ?
                                                        <KeyValuePairComponent
                                                            // heading={"Execution History"}
                                                            value={
                                                                <StyledSelect
                                                                    size="small"
                                                                    variant="outlined"
                                                                    name="status"
                                                                    fullWidth
                                                                // disabled={!userStoryData?.assignedTo}
                                                                // value={userStoryData?.assignedTo || ''}
                                                                // onChange={handleChange}
                                                                >
                                                                    {
                                                                        ["Arun", "Ritesh", "Vikram", "Rajat"]?.map((status, index) => (
                                                                            <MenuItem key={index} value={status}>{status}</MenuItem>
                                                                        ))
                                                                    }
                                                                </StyledSelect>
                                                            }
                                                        />
                                                        :
                                                        <Box className="flex flex-col gap-0">

                                                            <Typography fontSize={font.size.fs_4} className="" > {userData?.executionHistory || "None"} </Typography>
                                                        </Box>
                                                    }
                                                </SectionContainer>

                                                {false && <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.plannedInRelease)}>
                                                    {enableEdit && enableEdit === EditSection.plannedInRelease ?
                                                        <KeyValuePairComponent
                                                            heading={"Planned in Release"}
                                                            value={<StyledSelect
                                                                size="small"
                                                                variant="outlined"
                                                                name="storyType"
                                                                fullWidth
                                                            // disabled={!userStoryData?.status}
                                                            // value={userStoryData?.status || ''}
                                                            // onChange={handleChange}
                                                            >
                                                                {
                                                                    ["Feature", "Epic", "Theme", "Story"]?.map((status, index) => (
                                                                        <MenuItem key={index} value={status}>{status}</MenuItem>
                                                                    ))
                                                                }
                                                            </StyledSelect>} />
                                                        :
                                                        <Box className="flex flex-col gap-0">
                                                            <HeadingComponent heading="Planned in Release" />
                                                            <Typography fontSize={font.size.fs_4} className="" > {userData?.priority || "None"} </Typography>
                                                        </Box>
                                                    }
                                                </SectionContainer>}

                                                {false && <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.plannedInSprint)}>
                                                    {enableEdit && enableEdit === EditSection.plannedInSprint ?
                                                        <KeyValuePairComponent heading={"Planned in Sprint"} value={<StyledSelect
                                                            size="small"
                                                            variant="outlined"
                                                            name="storyType"
                                                            fullWidth
                                                        // disabled={!userStoryData?.status}
                                                        // value={userStoryData?.status || ''}
                                                        // onChange={handleChange}
                                                        >
                                                            {
                                                                ["Feature", "Epic", "Theme", "Story"]?.map((status, index) => (
                                                                    <MenuItem key={index} value={status}>{status}</MenuItem>
                                                                ))
                                                            }
                                                        </StyledSelect>} />
                                                        :
                                                        <Box className="flex flex-col gap-0">
                                                            <HeadingComponent heading="Planned in Sprint" />
                                                            <Typography fontSize={font.size.fs_4} className="" > {userData?.priority || "None"} </Typography>
                                                        </Box>
                                                    }
                                                </SectionContainer>}


                                            </AccordionDetailsContainer>
                                        </AccordionComponentDetails>
                                    </AccordionComponent>

                                    {/* estimation/ story points */}
                                    {false && <AccordionComponent
                                        expanded={expandedAccordion === AccordionName.estimationStoryPoints}
                                        onChange={handleChangeAccordion(AccordionName.estimationStoryPoints)}
                                    >
                                        <AccordionComponentSummary expandIcon={<ExpandMore />}    >
                                            <Box className="flex items-center gap-2 w-full">
                                                <HeadingComponent heading="Estimation /Story Points" />
                                                {expandedAccordion !== AccordionName.estimationStoryPoints &&
                                                    <Box className="flex-1 flex items-center gap-0 flex-wrap line-clamp-2  overflow-hidden  ">
                                                        {
                                                            ["Planned Value", "Planned Value",]?.map((item, index, arr) => <Typography key={index} fontSize={font.size.fs_5} className="mr-2" >{`${item}${index !== arr.length - 1 && ","} `} </Typography>)
                                                        }
                                                    </Box>}
                                            </Box>
                                        </AccordionComponentSummary>
                                        <AccordionComponentDetails>

                                            <AccordionDetailsContainer>

                                                <SectionContainer enableEdit={enableEdit} currentSection={EditSection.userPoints} onClick={(e) => handleEnableSectionEdit(e, EditSection.userPoints)}>
                                                    {enableEdit && enableEdit === EditSection.userPoints ?
                                                        <KeyValuePairComponent heading={"Planned Value"} value={<ValueComponent text={`Value`} />} />
                                                        :
                                                        <Box className="flex flex-col gap-0">
                                                            <HeadingComponent heading="Planned Value" />
                                                            <Typography fontSize={font.size.fs_4} className="" > {userData?.priority || "None"} </Typography>
                                                        </Box>
                                                    }
                                                </SectionContainer>

                                                <SectionContainer enableEdit={enableEdit} currentSection={EditSection.confidenciality} onClick={(e) => handleEnableSectionEdit(e, EditSection.confidenciality)}>
                                                    {enableEdit && enableEdit === EditSection.confidenciality ?
                                                        <KeyValuePairComponent heading={"Actual Value"} value={<StyledSelect
                                                            size="small"
                                                            variant="outlined"
                                                            name="storyType"
                                                            fullWidth
                                                        // disabled={!userStoryData?.status}
                                                        // value={userStoryData?.status || ''}
                                                        // onChange={handleChange}
                                                        >
                                                            {
                                                                ["Feature", "Epic", "Theme", "Story"]?.map((status, index) => (
                                                                    <MenuItem key={index} value={status}>{status}</MenuItem>
                                                                ))
                                                            }
                                                        </StyledSelect>} />
                                                        :
                                                        <Box className="flex flex-col gap-0">
                                                            <HeadingComponent heading="Actual Value" />
                                                            <Typography fontSize={font.size.fs_4} className="" > {userData?.priority || "None"} </Typography>
                                                        </Box>
                                                    }
                                                </SectionContainer>
                                            </AccordionDetailsContainer>
                                        </AccordionComponentDetails>
                                    </AccordionComponent>}

                                    {/* Tag to  */}
                                    {false && <AccordionComponent
                                        expanded={expandedAccordion === AccordionName.tagTo}
                                        onChange={handleChangeAccordion(AccordionName.tagTo)}

                                    >
                                        <AccordionSummary expandIcon={<ExpandMore />} disabled  >
                                            <Box className="flex items-center gap-2 w-full">
                                                <HeadingComponent heading="Tag To" />
                                                {expandedAccordion !== AccordionName.tagTo &&
                                                    <Box className="flex-1 flex items-center gap-0 flex-wrap line-clamp-2  overflow-hidden  ">
                                                        {
                                                            ["Business Unit", "Vendor", "External Party",]?.map((item, index, arr) => <Typography key={index} fontSize={font.size.fs_5} className="mr-2" >{`${item}${index !== arr.length - 1 && ","} `} </Typography>)
                                                        }
                                                    </Box>}
                                            </Box>
                                        </AccordionSummary>
                                        <AccordionDetails>

                                            <AccordionDetailsContainer>
                                                <SectionContainer enableEdit={enableEdit} currentSection={EditSection.businessUnit} onClick={(e) => handleEnableSectionEdit(e, EditSection.businessUnit)}>
                                                    {enableEdit && enableEdit === EditSection.businessUnit ?
                                                        <KeyValuePairComponent heading={"Bussiness Unit"} value={<ValueComponent text={`Value`} />} />
                                                        :
                                                        <Box className="flex flex-col gap-0">
                                                            <HeadingComponent heading="Bussiness Unit" />
                                                            <Typography fontSize={font.size.fs_4} className="" > {userData?.priority || "None"} </Typography>
                                                        </Box>
                                                    }
                                                </SectionContainer>

                                                <SectionContainer enableEdit={enableEdit} currentSection={EditSection.vendor} onClick={(e) => handleEnableSectionEdit(e, EditSection.vendor)}>
                                                    {enableEdit && enableEdit === EditSection.vendor ?
                                                        <KeyValuePairComponent heading={"Vendor"} value={<ValueComponent text={`Value`} />} />
                                                        :
                                                        <Box className="flex flex-col gap-0">
                                                            <HeadingComponent heading="Vendor" />
                                                            <Typography fontSize={font.size.fs_4} className="" > {userData?.priority || "None"} </Typography>
                                                        </Box>
                                                    }
                                                </SectionContainer>

                                                <SectionContainer enableEdit={enableEdit} currentSection={EditSection.externalPoints} onClick={(e) => handleEnableSectionEdit(e, EditSection.externalPoints)}>
                                                    {enableEdit && enableEdit === EditSection.externalPoints ?
                                                        <KeyValuePairComponent heading={"External Party"} value={<ValueComponent text={`Value`} />} />
                                                        :
                                                        <Box className="flex flex-col gap-0">
                                                            <HeadingComponent heading="External Party" />
                                                            <Typography fontSize={font.size.fs_4} className="" > {userData?.priority || "None"} </Typography>
                                                        </Box>
                                                    }
                                                </SectionContainer>
                                            </AccordionDetailsContainer>
                                        </AccordionDetails>
                                    </AccordionComponent>}

                                    {/* Linked userstory  */}
                                    <AccordionComponent
                                        expanded={expandedAccordion === AccordionName.linkedUserStory}
                                        onChange={handleChangeAccordion(AccordionName.linkedUserStory)}

                                    >
                                        <AccordionSummary expandIcon={<ExpandMore />}   >
                                            <Box className="flex items-center gap-0 w-full">
                                                <HeadingComponent heading="Linked User Story" className={""} />

                                                {expandedAccordion !== AccordionName.linkedUserStory &&
                                                    <Box className="flex-1 flex items-center gap-1 ml-2  overflow-hidden  ">
                                                        <Typography fontSize={font.size.fs_5} className="" > {`${userData?.userStories?.length === 0 ? `None` : userData?.userStories?.length}`} </Typography>
                                                    </Box>}

                                                <LightTooltip title="Generate using AI" >
                                                    <IconButton onClick={() => handleToggleAIWindow(Entity_Label.userStory, AI_Entity.userStory)} sx={{ ml: "auto" }}>
                                                        {currentAIEntityData?.entity === AI_Entity.userStory ? <AutoAwesome sx={{ color: theme.palette.ai.main }} /> : <AutoAwesomeOutlined sx={{ color: theme.palette.ai.main }} />}
                                                    </IconButton>
                                                </LightTooltip>
                                                <LightTooltip title="Link " >
                                                    <IconButton onClick={() => handleOpenLinkTag(currentUseCaseData)}>
                                                        <LinkCustomIcon sx={{ color: theme.palette.text.ternary }} />
                                                    </IconButton>
                                                </LightTooltip>

                                            </Box>
                                        </AccordionSummary>
                                        <AccordionDetails>

                                            <AccordionDetailsContainer>
                                                <SectionContainer >

                                                    <Box className="">
                                                        <Box className={`w-full  flex flex-col gap-1`}>

                                                            {/* table */}
                                                            {!userData?.userStories || userData?.userStories?.length === 0 ?
                                                                <ValueComponent text={"None"} />
                                                                :
                                                                (
                                                                    <Box className="flex flex-col  w-full h-52">
                                                                        <LinkedItemsTable list={userData?.userStories} />
                                                                    </Box>
                                                                )

                                                            }
                                                        </Box>
                                                    </Box>
                                                </SectionContainer>

                                            </AccordionDetailsContainer>
                                        </AccordionDetails>

                                    </AccordionComponent>

                                    {/* Linked useCase  */}
                                    <AccordionComponent
                                        expanded={expandedAccordion === AccordionName.linkedUseCase}
                                        onChange={handleChangeAccordion(AccordionName.linkedUseCase)}

                                    >
                                        <AccordionSummary expandIcon={<ExpandMore />}   >
                                            <Box className="flex items-center  w-full">
                                                <HeadingComponent heading="Linked Use Case" className={""} />

                                                {expandedAccordion !== AccordionName.linkedUseCase &&
                                                    <Box className="flex-1  flex items-center gap-1 ml-2  overflow-hidden  ">
                                                        <Typography fontSize={font.size.fs_5} className="" > {`${(userData?.testCase || userData?.testCases?.length > 0) ? userData?.testCases?.length : `None`}`} </Typography>
                                                    </Box>}

                                                <LightTooltip title="Generate using AI">
                                                    <IconButton onClick={() => handleToggleAIWindow(Entity_Label.testCase, AI_Entity.testCase)} sx={{ ml: "auto" }}>
                                                        {currentAIEntityData?.entity === AI_Entity.testCase ? <AutoAwesome sx={{ color: theme.palette.ai.main }} /> : <AutoAwesomeOutlined sx={{ color: theme.palette.ai.main }} />}
                                                    </IconButton>
                                                </LightTooltip>
                                                <LightTooltip title="Link ">
                                                    <IconButton onClick={() => handleOpenLinkTag(currentUseCaseData)}>
                                                        <LinkCustomIcon sx={{ color: theme.palette.text.ternary }} />
                                                    </IconButton>
                                                </LightTooltip>
                                            </Box>
                                        </AccordionSummary>
                                        <AccordionDetails>

                                            <AccordionDetailsContainer>
                                                <SectionContainer>

                                                    <Box className="">
                                                        <Box className={`w-full  flex flex-col gap-1 `}>

                                                            {/* table */}
                                                            {!userData?.testCases || userData?.testCases?.length === 0 ?
                                                                <ValueComponent text={"None"} />
                                                                :
                                                                (
                                                                    <Box className="flex flex-col  w-full h-52">
                                                                        <LinkedItemsTable list={userData?.testCases} />
                                                                    </Box>
                                                                )

                                                            }
                                                        </Box>
                                                    </Box>
                                                </SectionContainer>
                                            </AccordionDetailsContainer>
                                        </AccordionDetails>
                                    </AccordionComponent>

                                    {/* Attachments  */}
                                    <AccordionComponent
                                        expanded={expandedAccordion === AccordionName.attachments}
                                        onChange={handleChangeAccordion(AccordionName.attachments)}

                                    >
                                        <AccordionSummary expandIcon={<ExpandMore />}   >
                                            <Box className="flex items-center  w-full">
                                                <HeadingComponent heading="Attachments" className={""} />

                                                {expandedAccordion !== AccordionName.attachments &&
                                                    <Box className="flex-1  flex items-center gap-1 ml-2  overflow-hidden  ">
                                                        <Typography fontSize={font.size.fs_5} className="" > {`${(userData?.attachments?.length > 0) ? userData?.attachments?.length : `None`}`} </Typography>
                                                    </Box>}

                                                <IconButton size="small" onClick={() => fileInputRef.current.click()} sx={{ ml: "auto" }}>
                                                    <Attachment sx={{ transform: "rotate(90deg)" }} />
                                                </IconButton>

                                                <input
                                                    ref={fileInputRef}
                                                    type="file"
                                                    multiple
                                                    accept="image/*"
                                                    onChange={handleChangeFiles}
                                                    style={{ display: "none" }}
                                                />
                                            </Box>
                                        </AccordionSummary>
                                        <AccordionDetails>

                                            <AccordionDetailsContainer>
                                                <SectionContainer >


                                                    <Box>
                                                        {enableEdit && enableEdit === EditSection.attachments
                                                            ?
                                                            <Box className="w-full flex flex-col justify-between gap-1">
                                                                {/* uploaded attachments list */}
                                                                {selectedFiles?.length > 0 &&
                                                                    <Box className="flex-1 flex items-center  gap-4 border overflow-x-auto">
                                                                        {selectedFiles?.map((file, index) => (
                                                                            <Box key={index} className="w-44 h-44 rounded-md shadow relative">
                                                                                <img src={URL.createObjectURL(file)} alt={file.name} className="object-fill w-full h-full overflow-hidden" />

                                                                                <IconButton onClick={() => setSelectedFiles(selectedFiles.filter((_, i) => i !== index))} sx={{ position: "absolute", top: 0, right: 0 }}>
                                                                                    <Close />
                                                                                </IconButton>
                                                                            </Box>
                                                                        ))}
                                                                    </Box>}
                                                            </Box>
                                                            :
                                                            <Box className="w-full flex flex-col justify-between gap-1">

                                                                {/* preview of the selected attachement */}
                                                                {selectedFiles?.length === 0 ?
                                                                    <Box className="flex items-center gap-4">
                                                                        {/* <ValueComponent text={"None"} /> */}
                                                                    </Box>
                                                                    :
                                                                    <Box className="flex items-center gap-4">

                                                                        {selectedFiles?.length > 0 &&
                                                                            <Box className="flex-1 flex items-center flex-wrap gap-4 border-2 border-dashed border-gray-400 p-2 rounded-lg">
                                                                                {selectedFiles?.map((file, index) => (
                                                                                    <Box key={index} className="w-12 h-12 rounded-md shadow relative">
                                                                                        <img src={URL.createObjectURL(file)} alt={file?.name} className="object-fill w-full h-full overflow-hidden" />

                                                                                        <IconButton onClick={() => setSelectedFiles(selectedFiles.filter((_, i) => i !== index))} sx={{ position: "absolute", top: -5, right: -5 }}>
                                                                                            <Close sx={{ fontSize: font.size.fs_6 }} />
                                                                                        </IconButton>
                                                                                    </Box>
                                                                                ))}
                                                                            </Box>}

                                                                        <IconButton onClick={() => handleContinueStory(userData)}>
                                                                            <Upload />
                                                                        </IconButton>

                                                                    </Box>}

                                                                {/* uploaded attachments list */}
                                                                {userData?.attachments?.length === 0 ?
                                                                    <Box className="flex items-center gap-4">
                                                                        <ValueComponent text={"None"} />
                                                                    </Box>
                                                                    :
                                                                    <Box className="flex-1 flex flex-wrap gap-4 px-1 py-1 overflow-y-auto">
                                                                        {userData?.attachments?.map((file, index) => (
                                                                            <Box key={index} className="min-w-20 w-auto max-w-24 h-20  overflow-hidden relative" borderRadius={theme.shape.borderRadius}>
                                                                                <MediaAttachmentComponent data={file} handleRemove={handleRemoveAttachements} showDelete={true} showDownload={true} isOnClick={true} />
                                                                            </Box>
                                                                        ))}
                                                                    </Box>
                                                                }

                                                            </Box>

                                                        }
                                                    </Box>
                                                </SectionContainer>
                                            </AccordionDetailsContainer>
                                        </AccordionDetails>
                                    </AccordionComponent>

                                </Box>

                            </Box>
                            :
                            <Box className="absolute top-0 left-0 w-full h-full  " >
                                {handleGetFieldConfigurationComponent(currentFieldConfiguration)}
                            </Box>}

                    </Box>
                </Box>
            </Box>


            {/*  popup for current edit section */}
            <div style={{ pointerEvents: 'none' }}>
                <Popover
                    id="edit-section"
                    open={Boolean(editAnchorEl)}
                    anchorEl={editAnchorEl}
                    hideBackdrop
                    disablePortal
                    disableEnforceFocus
                    disableAutoFocus
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    transformOrigin={{ vertical: "bottom", horizontal: "right" }}

                    slotProps={{
                        paper: {
                            sx: {
                                backgroundColor: 'transparent',
                                boxShadow: 'none',
                                marginTop: "2rem"
                            }
                        }
                    }}
                >
                    <div style={{ pointerEvents: 'auto' }}>
                        <Box className="flex-1 flex items-center gap-1 py-0">

                            <IconButton
                                size="small"
                                value="check"
                                selected={false}
                                disabled={!enableEdit || !handleCheckValues()}
                                onClick={() => handleContinueStory(userData)}

                            >
                                <Save />
                            </IconButton>


                            <IconButton
                                size="small"
                                value="check"
                                onClick={handleCloseSectionEdit}

                            >
                                <Close />
                            </IconButton>

                        </Box>
                    </div>
                </Popover>
            </div>

            {/* popup for current AI entity */}
            <Popover
                id="AI-Entity"
                open={Boolean(aiAnchorEl)}
                anchorEl={aiAnchorEl}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={handleCloseAIPopup}
            >
                <MenuItem
                    selected={currentAIEntityData?.entity === AI_Entity.userStory}
                    onClick={() => handleToggleAIWindow(Entity_Label.userStory, AI_Entity.userStory)}
                    sx={{
                        color: currentAIEntityData?.entity === AI_Entity.userStory ? theme.palette.primary.main : "",
                        backgroundColor: currentAIEntityData?.entity?.toLowerCase() === AI_Entity.userStory?.toLowerCase() ? theme.palette.secondary.main : ""
                    }}
                >
                    {/* <ListItemIcon>
                        <Description fontSize="small" />
                    </ListItemIcon> */}
                    {/* <ListItemText>{Entity_Label.userStory}</ListItemText> */}
                    {Entity_Label.userStory}
                </MenuItem>

                <MenuItem
                    selected={currentAIEntityData?.entity === AI_Entity.testCase}
                    onClick={() => handleToggleAIWindow(Entity_Label.testCase, AI_Entity.testCase)}
                    sx={{
                        color: currentAIEntityData?.entity === AI_Entity.testCase ? theme.palette.primary.main : "",
                        backgroundColor: currentAIEntityData?.entity?.toLowerCase() === AI_Entity.testCase?.toLowerCase() ? theme.palette.secondary.main : ""
                    }}
                >
                    {/* <ListItemIcon>
                        <Description fontSize="small" />
                    </ListItemIcon> */}
                    {/* <ListItemText>{Entity_Label.testCase}</ListItemText> */}
                    {Entity_Label.testCase}
                </MenuItem>

            </Popover>


            {/* menu items */}
            <Popover
                open={Boolean(menuAnchorEl)}
                anchorEl={menuAnchorEl}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={handleToggleMenuPopup}
            >
                <Box>
                    <MenuItem onClick={() => handleToggleSection(EditSection.dueDate)}>Due Date</MenuItem>
                    {false && <MenuItem onClick={() => handleToggleSection(EditSection.userPersona)}>User Persona</MenuItem>}
                </Box>
            </Popover>

            {/* more actions */}
            <Popover
                open={Boolean(moreAnchorEl)}
                anchorEl={moreAnchorEl}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={handleToggleMorePopup}
            >
                <Box>
                    <MenuItem onClick={() => { }}>Move</MenuItem>
                    <MenuItem onClick={() => { }}>Copy</MenuItem>
                    <MenuItem onClick={() => { }}>Delete</MenuItem>
                </Box>
            </Popover>


            {/* label list */}
            <Popover
                id="label-list"
                open={Boolean(labelAnchorEl)}
                anchorEl={labelAnchorEl}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={handleToggleLabelPopup}
            >
                <Box className="flex flex-col px-0 py-2">
                    <Box className="flex flex-col px-4 py-2 max-h-[40vh] overflow-y-auto">
                        {
                            labelList?.map((label, index) => (
                                <MenuItem key={index} style={{ display: "flex", alignItems: "center", gap: 4 }} >
                                    <Checkbox checked={selectedLabels?.includes(label)} onChange={() => handleChangeLabel(label)} />
                                    <Label sx={{ color: label?.colorCode }} />
                                    {label?.name}
                                </MenuItem>
                            ))
                        }
                    </Box>

                    <Box className="flex  px-4 py-2 border-t">
                        <Button size="small" fullWidth onClick={handleLabelUpdate}>Apply </Button>
                    </Box>


                </Box>

            </Popover>
        </Box>
    )

}





function FieldTypeComponent({ handleClose }) {
    const theme = useTheme();

    const [userFieldsList, setUserFieldsList] = useState([

        {
            step: "Navigate to the login page",
            fieldName: "username",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Click on the login button",
            fieldName: "password",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Enter the username",
            fieldName: "email",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Enter the password",
            fieldName: "phone",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Click on the continue button",
            fieldName: "telephone",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        }

    ]);

    const columns = [
        {
            data: "S.No.",
            padding: "none",
            px: "0.4rem",
            align: "left",
            background: theme.palette.header.main,
            width: "40px",
            children: []
        },
        {
            data: "Field Name",
            padding: "none",
            px: "1rem",
            align: "left",
            background: theme.palette.header.main,
            width: "165px",
            children: []
        },
        {
            data: "Field Type",
            padding: "none",
            px: "1rem",
            align: "left",
            background: theme.palette.header.main,
            width: "120px",
            children: []
        },
    ];


    return (
        <Box className="flex flex-col w-full h-full gap-2 flex-1 p-0.5 overflow-hidden">
            <Box className="flex items-center gap-2">
                <Typography className="flex-1 ">Field Type</Typography>
                <IconButton onClick={handleClose}>
                    <Close />
                </IconButton>
            </Box>
            <TableVirtuoso
                style={{ height: "100%", width: "100%", overflowX: 'auto', borderRadius: theme.shape.borderRadius }}
                data={userFieldsList}
                components={TableComponents}
                fixedHeaderContent={() => (
                    <TableHead>
                        {
                            columns?.map((item, index) => (
                                <TableCell key={index} align={item?.align} padding={item?.padding} className="whitespace-nowrap" style={{ py: "0.4rem", margin: "0 1rem !important", fontWeight: "bold", minWidth: item?.width, maxWidth: item?.width, background: item?.background, borderRight: index !== columns?.length - 1 && `1px solid ${grey[300]}` }}>
                                    {item?.children?.length === 0 ? <Box className="py-2 flex items-center gap-2 px-1" sx={{ px: item?.px }}>
                                        <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap"> {item?.data}</Typography>
                                    </Box> :
                                        <Box className=" flex flex-col items-center  gap-0 ">
                                            <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap"> {item?.data}</Typography>
                                            <Box className="flex-1 w-full flex   gap-0  " sx={{ borderTop: `1px solid ${grey[300]}` }}>
                                                {
                                                    item?.children?.map((subItem, index) => (
                                                        <Box className="flex-1 flex items-center justify-center gap-2" sx={{ borderRight: index !== item?.children?.length - 1 && `1px solid ${grey[300]}` }}>
                                                            <Typography fontSize={font.size.fs_6} fontWeight="bold" className="whitespace-nowrap"> {subItem?.data}</Typography>
                                                        </Box>

                                                    ))
                                                }
                                            </Box>
                                        </Box>}
                                </TableCell>
                            ))
                        }
                    </TableHead>
                )}


                itemContent={(index, data) => (
                    <TableRow sx={{ "&:hover": { background: grey[100], } }}>

                        <TableCell padding="none" align="left" sx={{ minWidth: "40px", maxWidth: "40px", cursor: "pointer", borderRight: `1px solid ${grey[300]}` }}>
                            <Box className="flex items-center justify-center ">
                                <Typography>{index + 1}</Typography>
                            </Box>
                        </TableCell>

                        <TableCell padding="none" align="left" sx={{ minWidth: "165px", maxWidth: "165px", cursor: "pointer", borderRight: `1px solid ${grey[300]}` }}>
                            <Box className="flex items-center  px-4 ">
                                <Typography fontSize={font.size.fs_4}>{data?.fieldName}</Typography>
                            </Box>
                        </TableCell>

                        <TableCell padding="none" align="left" sx={{ minWidth: "120px", maxWidth: "120px", cursor: "pointer", borderRight: `1px solid ${grey[300]}` }}>
                            <StyledSelect
                                fullWidth
                                size="small"
                                variant="outlined"
                                name="fieldType"
                                value={"Textfield"}
                            // value={data?.fieldType}
                            // onChange={(e) => handleChange(e, index)}
                            >
                                {
                                    ["Textfield", "Dropdown", "Checkbox", "Radio", "Textarea", "File", "Link", "Image",]?.map((status, index) => (
                                        <MenuItem key={index} value={status}>{status}</MenuItem>
                                    ))

                                }
                            </StyledSelect>

                        </TableCell>

                    </TableRow>

                )}
            />
        </Box>
    )
}

function FieldLengthComponent({ handleClose }) {
    const theme = useTheme();

    const [userFieldsList, setUserFieldsList] = useState([

        {
            step: "Navigate to the login page",
            fieldName: "username",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Click on the login button",
            fieldName: "password",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Enter the username",
            fieldName: "email",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Enter the password",
            fieldName: "phone",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Click on the continue button",
            fieldName: "telephone",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        }

    ]);

    const columns = [
        {
            data: "S.No.",
            padding: "none",
            px: "0.4rem",
            align: "left",
            background: theme.palette.header.main,
            width: "40px",
            children: []
        },
        {
            data: "Field Name",
            padding: "none",
            px: "1rem",
            align: "left",
            background: theme.palette.header.main,
            width: "154px",
            children: []
        },
        {
            data: "Field Length",
            padding: "none",
            px: "1rem",
            align: "left",
            background: theme.palette.header.main,
            width: "130px",
            children: [
                {
                    data: "Min",
                    padding: "none",
                    align: "left",
                    background: theme.palette.header.main,
                    width: "100px"

                },
                {
                    data: "Max",
                    padding: "none",
                    align: "left",
                    background: theme.palette.header.main,
                    width: "100px",

                }
            ]
        },
    ];


    return (
        <Box className="flex flex-col w-full h-full flex-1 p-0.5 overflow-hidden">
            <Box className="flex items-center gap-2">
                <Typography className="flex-1 ">Field Length</Typography>
                <IconButton onClick={handleClose}>
                    <Close />
                </IconButton>
            </Box>
            <TableVirtuoso
                style={{ height: "100%", width: "100%", overflowX: 'auto', borderRadius: theme.shape.borderRadius }}
                data={userFieldsList}
                components={TableComponents}
                fixedHeaderContent={() => (
                    <TableHead>
                        {
                            columns?.map((item, index) => (
                                <TableCell key={index} align={item?.align} padding={item?.padding} className="whitespace-nowrap" style={{ py: "0.4rem", margin: "0 1rem !important", fontWeight: "bold", minWidth: item?.width, maxWidth: item?.width, background: item?.background, borderRight: index !== columns?.length - 1 && `1px solid ${grey[300]}` }}>
                                    {item?.children?.length === 0 ? <Box className="py-2 flex items-center gap-2 " sx={{ px: item?.px }}>
                                        <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap"> {item?.data}</Typography>
                                    </Box> :
                                        <Box className=" flex flex-col gap-0 ">
                                            {/* <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap"> {item?.data}</Typography> */}
                                            <Box className="flex-1 w-full h-full flex   gap-0  " >
                                                {
                                                    item?.children?.map((subItem, index) => (
                                                        <Box className="flex-1 h-full flex items-center justify-center gap-2" sx={{ borderRight: index !== item?.children?.length - 1 && `1px solid ${grey[300]}` }}>
                                                            <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap"> {subItem?.data}</Typography>
                                                        </Box>

                                                    ))
                                                }
                                            </Box>
                                        </Box>}
                                </TableCell>
                            ))
                        }
                    </TableHead>
                )}


                itemContent={(index, data) => (
                    <TableRow sx={{ "&:hover": { background: grey[100], } }}>

                        <TableCell padding="none" align="left" sx={{ minWidth: "40px", maxWidth: "40px", cursor: "pointer", borderRight: `1px solid ${grey[300]}` }}>
                            <Box className="flex items-center justify-center ">
                                <Typography>{index + 1}</Typography>
                            </Box>
                        </TableCell>

                        <TableCell padding="none" align="left" sx={{ minWidth: "154px", maxWidth: "154px", cursor: "pointer", borderRight: `1px solid ${grey[300]}` }}>
                            <Box className="flex items-center  px-4 ">
                                <Typography fontSize={font.size.fs_4}>{data?.fieldName}</Typography>
                            </Box>
                        </TableCell>

                        <TableCell padding="none" align="left" sx={{ minWidth: "130px", maxWidth: "130px", cursor: "pointer", borderRight: `1px solid ${grey[300]}` }}>
                            <Box className="flex items-center gap-0 px-1 ">
                                <StyledTextField
                                    // label="Min Length"
                                    type="number"
                                    name="minLength"
                                // defaultValue={data?.fieldValidation?.minLength}
                                // onBlur={(e) => handleChange(e, index)}
                                />
                                <Divider orientation="vertical" flexItem />
                                <StyledTextField
                                    // label="Max Length"
                                    type="number"
                                    name="maxLength"
                                // defaultValue={data?.fieldValidation?.maxLength}
                                // onBlur={(e) => handleChange(e, index)}
                                />
                            </Box>
                        </TableCell>

                    </TableRow>

                )}
            />
        </Box>
    )
}

function FieldValidationComponent({ handleClose }) {
    const theme = useTheme();

    const [validationAnchorEl, setValidationAnchorEl] = useState(null);

    const [userFieldsList, setUserFieldsList] = useState([

        {
            step: "Navigate to the login page",
            fieldName: "username",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Click on the login button",
            fieldName: "password",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Enter the username",
            fieldName: "email",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Enter the password",
            fieldName: "phone",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Click on the continue button",
            fieldName: "telephone",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        }

    ]);

    const columns = [
        {
            data: "S.No.",
            padding: "none",
            px: "0.4rem",
            align: "left",
            background: theme.palette.header.main,
            width: "40px",
            children: []
        },
        {
            data: "Field Name",
            padding: "none",
            px: "1rem",
            align: "left",
            background: theme.palette.header.main,
            width: "154px",
            children: []
        },
        {
            data: "Validations",
            padding: "none",
            px: "1rem",
            align: "center",
            background: theme.palette.header.main,
            width: "130px",
            children: []
        },
    ];

    const handleClickValidationPopup = (event,) => {
        if (validationAnchorEl) {
            setValidationAnchorEl(null);
        } else {
            setValidationAnchorEl(event.currentTarget);
        }
    }


    return (
        <Box className="flex flex-col w-full h-full flex-1 p-0.5 overflow-hidden">
            <Box className="flex items-center gap-2">
                <Typography className="flex-1 ">Field Validation</Typography>
                <IconButton onClick={handleClose}>
                    <Close />
                </IconButton>
            </Box>
            <TableVirtuoso
                style={{ height: "100%", width: "100%", overflowX: 'auto', borderRadius: theme.shape.borderRadius }}
                data={userFieldsList}
                components={TableComponents}
                fixedHeaderContent={() => (
                    <TableHead>
                        {
                            columns?.map((item, index) => (
                                <TableCell key={index} align={item?.align} padding={item?.padding} className="whitespace-nowrap" style={{ py: "0.4rem", margin: "0 1rem !important", fontWeight: "bold", minWidth: item?.width, maxWidth: item?.width, background: item?.background, borderRight: `1px solid ${grey[300]}` }}>
                                    {item?.children?.length === 0 ? <Box className="py-2 flex items-center gap-2 " sx={{ px: item?.px }}>
                                        <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap"> {item?.data}</Typography>
                                    </Box> :
                                        <Box className=" flex flex-col items-center  gap-0 ">
                                            <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap"> {item?.data}</Typography>
                                            <Box className="flex-1 w-full flex   gap-0  " sx={{ borderTop: `1px solid ${grey[300]}` }}>
                                                {
                                                    item?.children?.map((subItem, index) => (
                                                        <Box className="flex-1 flex items-center justify-center gap-2" sx={{ borderRight: index !== item?.children?.length - 1 && `1px solid ${grey[300]}` }}>
                                                            <Typography fontSize={font.size.fs_6} fontWeight="bold" className="whitespace-nowrap"> {subItem?.data}</Typography>
                                                        </Box>

                                                    ))
                                                }
                                            </Box>
                                        </Box>}
                                </TableCell>
                            ))
                        }
                    </TableHead>
                )}


                itemContent={(index, data) => (
                    <TableRow sx={{ "&:hover": { background: grey[100], } }}>

                        <TableCell padding="none" align="left" sx={{ minWidth: "40px", maxWidth: "40px", cursor: "pointer", borderRight: `1px solid ${grey[300]}` }}>
                            <Box className="flex items-center justify-center ">
                                <Typography>{index + 1}</Typography>
                            </Box>
                        </TableCell>

                        <TableCell padding="none" align="left" sx={{ minWidth: "154px", maxWidth: "154px", cursor: "pointer", borderRight: `1px solid ${grey[300]}` }}>
                            <Box className="flex items-center  px-4 ">
                                <Typography fontSize={font.size.fs_4}>{data?.fieldName}</Typography>
                            </Box>
                        </TableCell>

                        <TableCell padding="none" align="left" sx={{ minWidth: "130px", maxWidth: "130px", cursor: "pointer", borderRight: `1px solid ${grey[300]}` }}>
                            <Box className="flex items-center gap-2 px-4">


                                <Button fullWidth onClick={handleClickValidationPopup}>
                                    <Add />
                                </Button>

                                <Popover
                                    id="validation-popover"
                                    open={Boolean(validationAnchorEl)}
                                    anchorEl={validationAnchorEl}
                                    onClose={handleClickValidationPopup}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{

                                    }}
                                >
                                    <Box className="flex-1 flex flex-col gap-4 px-4">
                                        <Box className="flex items-center justify-between gap-4 py-2 border-b">
                                            <Typography>Field Validation</Typography>
                                            <IconButton onClick={handleClickValidationPopup}>
                                                <Close />
                                            </IconButton>
                                        </Box>

                                        <Box className="flex-1 flex flex-col gap-4 p-2">
                                            <KeyValuePairComponent
                                                heading={`Uppercase`}
                                                value={
                                                    <FormControlLabel
                                                        // label="Uppercase"
                                                        // labelPlacement="bottom"
                                                        className="w-fit"
                                                        control={
                                                            <Checkbox

                                                            // defaultValue={data?.fieldValidation?.uppercase}
                                                            // onBlur={(e) => handleChange(e, index)}
                                                            />
                                                        }
                                                    />
                                                }
                                            />

                                            <KeyValuePairComponent
                                                heading={`Lowercase`}
                                                value={
                                                    <FormControlLabel
                                                        // label="Uppercase"
                                                        // labelPlacement="bottom"
                                                        className="w-fit"
                                                        control={
                                                            <Checkbox

                                                            // defaultValue={data?.fieldValidation?.uppercase}
                                                            // onBlur={(e) => handleChange(e, index)}
                                                            />
                                                        }
                                                    />
                                                }
                                            />
                                            <KeyValuePairComponent
                                                heading={`Whitespace`}
                                                value={
                                                    <FormControlLabel
                                                        // label="Uppercase"
                                                        // labelPlacement="bottom"
                                                        className="w-fit"
                                                        control={
                                                            <Checkbox

                                                            // defaultValue={data?.fieldValidation?.uppercase}
                                                            // onBlur={(e) => handleChange(e, index)}
                                                            />
                                                        }
                                                    />
                                                }
                                            />
                                            <KeyValuePairComponent
                                                heading={`Special Character`}
                                                value={
                                                    <FormControlLabel
                                                        // label="Uppercase"
                                                        // labelPlacement="bottom"
                                                        className="w-fit"
                                                        control={
                                                            <Checkbox

                                                            // defaultValue={data?.fieldValidation?.uppercase}
                                                            // onBlur={(e) => handleChange(e, index)}
                                                            />
                                                        }
                                                    />
                                                }
                                            />
                                            <KeyValuePairComponent
                                                heading={`Required`}
                                                value={
                                                    <FormControlLabel
                                                        // label="Uppercase"
                                                        // labelPlacement="bottom"
                                                        className="w-fit"
                                                        control={
                                                            <Checkbox

                                                            // defaultValue={data?.fieldValidation?.uppercase}
                                                            // onBlur={(e) => handleChange(e, index)}
                                                            />
                                                        }
                                                    />
                                                }
                                            />

                                            <KeyValuePairComponent
                                                heading={`Numeric`}
                                                value={
                                                    <FormControlLabel
                                                        // label="Uppercase"
                                                        // labelPlacement="bottom"
                                                        className="w-fit"
                                                        control={
                                                            <Checkbox

                                                            // defaultValue={data?.fieldValidation?.uppercase}
                                                            // onBlur={(e) => handleChange(e, index)}
                                                            />
                                                        }
                                                    />
                                                }
                                            />


                                        </Box>
                                    </Box>
                                </Popover>

                            </Box>
                        </TableCell>

                    </TableRow>

                )}
            />
        </Box>
    )
}

function TestStepsComponent({ userData, setUserData }) {
    const theme = useTheme();

    const columns = [
        {
            data: "S.No.",
            padding: "none",
            px: "0.4rem",
            align: "left",
            background: theme.palette.header.main,
            width: "40px",
            children: []
        },
        {
            data: "Action",
            padding: "none",
            px: "1rem",
            align: "left",
            background: theme.palette.header.main,
            width: "154px",
            children: []
        },
        {
            data: "Test Data",
            padding: "none",
            px: "1rem",
            align: "left",
            background: theme.palette.header.main,
            width: "180px",
            children: []
        },

        {
            data: "Expected Result",
            padding: "none",
            px: "1rem",
            align: "left",
            background: theme.palette.header.main,
            width: "320px",
            children: []
        },
    ];

    const handleChange = (e, index) => {
        let name = e.target.name;
        let value = e.target.value;
        let list = userData?.steps;
        list[index][name] = value;
        setUserData({ ...userData, steps: list });
    }

    return (
        <Box className="flex flex-col w-full h-full flex-1 p-0.5 overflow-hidden">
            {/* <Box className="flex items-center gap-2">
                <Typography className="flex-1 ">Element Id</Typography>
                <IconButton onClick={handleClose}>
                    <Close />
                </IconButton>
            </Box> */}
            <TableVirtuoso
                style={{ height: "100%", width: "100%", overflowX: 'auto', borderRadius: theme.shape.borderRadius }}
                data={userData?.steps}
                components={TableComponents}
                fixedHeaderContent={() => (
                    <TableHead>
                        {
                            columns?.map((item, index) => (
                                <TableCell key={index} align={item?.align} padding={item?.padding} className="whitespace-nowrap" style={{ margin: "0 1rem !important", fontWeight: "bold", minWidth: item?.width, maxWidth: item?.width, background: item?.background, borderRight: `1px solid ${grey[300]}` }}>
                                    {item?.children?.length === 0 ? <Box className="py-2 flex items-center gap-2" sx={{ px: item?.px }}>
                                        <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap"> {item?.data}</Typography>
                                    </Box> :
                                        <Box className=" flex flex-col h-full items-center  gap-0 ">
                                            {/* <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap"> {item?.data}</Typography> */}
                                            <Box className="flex-1 w-full h-full flex   gap-0  " >
                                                {
                                                    item?.children?.map((subItem, index) => (
                                                        <Box className="flex-1 flex items-center justify-center gap-2" sx={{ borderRight: index !== item?.children?.length - 1 && `1px solid ${grey[300]}` }}>
                                                            <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap"> {subItem?.data}</Typography>
                                                        </Box>

                                                    ))
                                                }
                                            </Box>
                                        </Box>}
                                </TableCell>
                            ))
                        }
                    </TableHead>
                )}


                itemContent={(index, data) => (
                    <TableRow sx={{ "&:hover": { background: grey[100], } }}>

                        <TableCell padding="none" align="left" sx={{ minWidth: "40px", maxWidth: "40px", cursor: "pointer", borderRight: `1px solid ${grey[300]}` }}>
                            <Box className="flex items-center justify-center ">
                                <Typography>{index + 1}</Typography>
                            </Box>
                        </TableCell>

                        <TableCell padding="none" align="left" sx={{ minWidth: "154px", maxWidth: "154px", cursor: "pointer", borderRight: `1px solid ${grey[300]}` }}>
                            <Box className="flex items-center  px-4 ">
                                {/* <Typography fontSize={font.size.fs_4}>{data?.step}</Typography> */}
                                <TextField size="small" name="step" value={data?.step} onChange={(e) => handleChange(e, index)} />
                            </Box>
                        </TableCell>

                        <TableCell padding="none" align="left" sx={{ minWidth: "180px", maxWidth: "180px", cursor: "pointer", borderRight: `1px solid ${grey[300]}` }}>
                            <Box className="flex items-center gap-0">
                                <StyledTextField
                                    fullWidth
                                    // label="xpath"
                                    name="xpath"
                                // defaultValue={data?.elementId?.xpath}
                                // onBlur={(e) => handleChange(e, index)}
                                />
                            </Box>
                        </TableCell>

                        <TableCell padding="none" align="left" sx={{ minWidth: "320px", maxWidth: "320px", cursor: "pointer", }}>
                            <Box className="flex items-center gap-0">
                                {/* <Typography fontSize={font.size.fs_4}>{data?.expectedResult}</Typography> */}
                                <TextField size="small" name="expectedResult" value={data?.expectedResult} onChange={(e) => handleChange(e, index)} />
                            </Box>

                        </TableCell>


                    </TableRow>

                )}
            />
        </Box>
    )
}





