import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { Checkbox, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Paper, Popover, Select, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography, useTheme } from '@mui/material';
import { Close, Description, Settings } from '@mui/icons-material';
import { font } from '../../styles/font';
import { forwardRef, useEffect, useState } from 'react';
import { useApplicationContext } from '../../context/ApplicationContext';
import { getProjectCategoryList_API, getProjectList_API, getProjectSubcategoryList_API, updateProject_API } from '../../api/controller/ProjectController';
import { HttpStatusCode } from 'axios';
import { Severity } from '../../constant/ApplicationVariables';
import useErrorHandler from '../../api/errorHandler/useErrorHandler';
import TagIcon from '@mui/icons-material/Tag';
import { TableVirtuoso } from 'react-virtuoso';
import { grey } from '@mui/material/colors';

const TableComponents = {
    Scroller: forwardRef((props, ref) => <TableContainer component={Paper} style={{ overflowX: "auto", overflowY: "scroll" }} {...props} ref={ref} />),
    Table: (props) => <Table {...props} style={{ borderCollapse: 'separate' }} />,
    TableHead: TableHead,
    TableRow: (props) => <TableRow {...props} />,
    TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
    TableCell: (props) => <TableCell {...props} sx={{ ...props?.sx, px: "1rem !important" }} />,
}

export default function AddUserFieldsDrawer({ open, handleClose, handleContinue }) {
    const { setProjectList, handleSnackbar, currentUserStoryData, toggleChatWindow } = useApplicationContext();
    const { activeProject, setActiveProject, } = useApplicationContext();
    const errorHandler = useErrorHandler();
    const theme = useTheme();

    const [projectData, setProjectData] = useState(activeProject);
    const [activeProjectCategory, setActiveProjectCategory] = useState(null);
    const [activeProjectSubcategory, setActiveProjectSubcategory] = useState(null);
    const [activeTab, setActiveTab] = useState(0);
    const [userFieldsList, setUserFieldsList] = useState([
        { field: "", value: "" }
    ]);

    const [isLoading, setIsLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const [categoryList, setCategoryList] = useState([]);
    const [subCategoryList, setSubCategoryList] = useState([]);

    const toggleDrawer = () => {
        handleClose();
    };

    const columns = [
        {
            data: "Field",
            padding: "normal",
            align: "left",
            background: theme.palette.secondary.main,
            width: "200px"
        },
        {
            data: "Value",
            padding: "normal",
            align: "left",
            background: theme.palette.secondary.main,
            width: "200px"
        },
        {
            data: "Boundary Conditions",
            padding: "normal",
            align: "left",
            background: theme.palette.secondary.main,
            width: "200px"
        },
    ];

    const handleClickConfigure = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseConfigure = () => {
        setAnchorEl(null);
    };

    const handleChange = (e, index) => {
        let tempList = userFieldsList;
        tempList[index] = { ...tempList[index], [e.target.name]: e.target.value };
        setUserFieldsList(tempList);
    }

    const handleUpdate = () => {
        handleContinue(projectData);
    }

    const handleGetCategoryList = async () => {
        try {


            const response = await getProjectCategoryList_API();

            if (response.status === HttpStatusCode.Ok) {
                setCategoryList(response.data);

                // handleSnackbar(response.message, Severity.success)
            }

            if (response) {
                errorHandler(response);
            }


            console.log(response, "categoryreponse data")
        } catch (error) {
            console.log(error, "categoryreponse errorr")
        }
    }

    const handleGetsubCategoryList = async () => {
        try {


            let query = `parentName=${projectData?.categoryName}`;

            const response = await getProjectSubcategoryList_API(query);

            if (response.status === HttpStatusCode.Ok) {
                setSubCategoryList(response.data);
                setProjectData({ ...projectData, "subCategoryName": response.data[0]?.name });

                // handleSnackbar(response.message, Severity.success)
            }

            if (response) {
                errorHandler(response);
            }


            console.log(response, "subcategoryreponse data")
        } catch (error) {
            console.log(error, "subcategoryreponse errorr")
        }
    }

    useEffect(() => {
        handleGetCategoryList();
    }, []);

    useEffect(() => {
        if (projectData?.categoryName) {
            handleGetsubCategoryList();
        }
    }, [projectData?.categoryName]);

    // useEffect(() => {
    //     setActiveProjectCategory(categoryList[0]);
    // }, []);

    useEffect(() => {
        setProjectData(activeProject);
    }, [activeProject]);

    console.log(projectData, "activeProject");
    console.log(userFieldsList, "userfieldList");

    const DrawerList = (
        <Box sx={{ width: 632 }} role="presentation" className="flex flex-col h-full">
            <Box className="flex items-start px-4 py-2 border-b-2">
                <Box className="flex-1 flex flex-col gap-2">
                    <Typography fontSize={font.size.fs_4} className="flex items-center gap-1"><Description color="primary" sx={{ fontSize: font.size.fs_4 }} /> {currentUserStoryData?.customId}</Typography>
                    <Typography fontSize={font.size.fs_5} className=" border-l-2 pl-2 py-2 bg-gray-50">{currentUserStoryData?.title}</Typography>
                </Box>
                <IconButton onClick={() => toggleDrawer()}><Close /></IconButton>
            </Box>

            <Box className="flex-1 flex flex-col gap-4 px-4 py-4 ">
                {/* <Tabs
                    value={activeTab}
                    onChange={(e, newValue) => setActiveTab(newValue)}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="secondary tabs example"
                >
                    <Tab value={0} label="Link" />
                    <Tab value={1} label="Tag" />
                </Tabs> */}




                <Box className="flex-1 flex flex-col gap-4 ">

                    {/* <Box className="flex items-center gap-2">
                        <Typography fontSize={font.size.fs_3} className="flex-1 px-2">Field</Typography>
                        <Typography fontSize={font.size.fs_3} className="flex-1 px-2">Value</Typography>
                    </Box> */}

                    {/* <Box className="flex-1 flex flex-col gap-2 overflow-y-auto">
                        {
                            userFieldsList?.map((item, index) => {
                                return <Box key={index} className="flex items-center gap-2">
                                    <TextField name="field" defaultValue={item?.field} onBlur={(e) => handleChange(e, index)} />
                                    <TextField name="value" defaultValue={item?.value} onBlur={(e) => handleChange(e, index)} />
                                </Box>
                            })
                        }
                    </Box> */}

                    <TableVirtuoso
                        style={{ height: "100%", width: "100%", overflowX: 'auto' }}
                        data={userFieldsList}
                        components={TableComponents}
                        fixedHeaderContent={() => (
                            <TableHead>
                                {
                                    columns?.map((item, index) => (
                                        <TableCell key={index} align={item?.align} padding={item?.padding} className="whitespace-nowrap" style={{ margin: "0 1rem !important", fontWeight: "bold", minWidth: item?.width, maxWidth: item?.width, background: item?.background }}>
                                            <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap"> {item?.data}</Typography>
                                        </TableCell>
                                    ))
                                }
                            </TableHead>
                        )}


                        itemContent={(index, storyData) => (
                            <TableRow sx={{ "&:hover": { background: grey[100], } }}>

                                <TableCell padding="normal" align="left" sx={{ minWidth: "200px", maxWidth: "200px", cursor: "pointer" }}>
                                    <TextField name="field" defaultValue={storyData?.field} onBlur={(e) => handleChange(e, index)} />
                                </TableCell>


                                <TableCell padding="normal" align="left" sx={{ minWidth: "200px", maxWidth: "200px", cursor: "pointer" }}>
                                    <TextField name="value" defaultValue={storyData?.value} onBlur={(e) => handleChange(e, index)} />
                                </TableCell>

                                <TableCell padding="normal" align="left" sx={{ minWidth: "200px", maxWidth: "200px", cursor: "pointer" }}>
                                    <IconButton onClick={handleClickConfigure}>
                                        <Settings />
                                    </IconButton>
                                </TableCell>

                            </TableRow>

                        )}
                    />
                    <Button variant="contained" onClick={() => setUserFieldsList([...userFieldsList, { field: "", value: "" }])}>Add Row</Button>

                </Box>


            </Box>

            <Box className="flex flex-col gap-4 px-4 py-4 border-t-2">
                <Button variant="contained" disabled={isLoading || !projectData?.name || !projectData?.description} onClick={() => handleUpdate()}>Save</Button>
                {/* <Button variant="outlined" color="error" disabled={isLoading || !projectData?.name || !projectData?.description} onClick={() => toggleDrawer()}>Discard</Button> */}
            </Box>



            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleCloseConfigure}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box className="flex-1 flex flex-col gap-4 px-4">
                    <Box className="flex items-center justify-between gap-4 py-2 border-b">
                        <Typography>Boundary Conditions</Typography>
                        <IconButton onClick={handleCloseConfigure}>
                            <Close />
                        </IconButton>
                    </Box>

                    <Box className="flex-1 flex flex-col gap-4 p-2">
                        <KeyValueComponent
                            heading={`Field Type`}
                            value={
                                <Select
                                    fullWidth
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    defaultValue={""}
                                    displayEmpty
                                // label="Boundary Condition"
                                >
                                    {/* <MenuItem value={""}>None</MenuItem> */}
                                    <MenuItem value={""}>Textfield</MenuItem>
                                    <MenuItem value={1}>Select</MenuItem>
                                    <MenuItem value={2}>Checkbox</MenuItem>
                                    <MenuItem value={3}>Radio</MenuItem>
                                </Select>

                            }
                        />

                        <KeyValueComponent
                            heading={`Max Characters`}
                            value={
                                <TextField type="number" defaultValue={10} />
                            }
                        />
                        <KeyValueComponent
                            heading={`Special Characters `}
                            value={
                                <FormControl component="fieldset">
                                    <FormControlLabel
                                        control={<Checkbox checked={true} />}
                                    // label="Boundary Condition"
                                    />
                                </FormControl>

                            }
                        />
                        <KeyValueComponent
                            heading={`Uppercase `}
                            value={
                                <FormControl component="fieldset">
                                    <FormControlLabel
                                        control={<Checkbox checked={true} />}
                                    // label="Boundary Condition"
                                    />
                                </FormControl>

                            }
                        />


                    </Box>
                </Box>
            </Popover>
        </Box>
    );

    return (
        <Box>
            <Drawer
                open={open}
                disablePortal
                hideBackdrop
                anchor="right"
                PaperProps={{
                    style: {
                        marginRight: toggleChatWindow ? "30%" : "0%",
                        borderRadius: 0
                    }
                }}>
                {DrawerList}
            </Drawer>
        </Box>
    );
}

function KeyValueComponent({ heading, value }) {
    return (
        <Box className="flex items-center justify-between gap-4 ">
            <Typography variant="body2" className="flex-1" component="span">{heading}</Typography>
            <Typography className="flex-1">{value}</Typography>
        </Box>
    );
}
