
import { MAIN_URL, REQUEST_URL } from "../../constant/ApplicationVariables";
import { axiosInstance } from "../useAxios/useAxios";

export const getFileDetail_API = async (body) => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.file,
            method: "POST",
            data: body,
            contentType: "application/json",
            responseType : "blob"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}
