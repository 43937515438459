import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import { HttpStatusCode } from 'axios';
import { login_API, requestOTP_API } from '../../../api/controller/authController';
import { useApplicationContext } from '../../../context/ApplicationContext';
import useErrorHandler from '../../../api/errorHandler/useErrorHandler';
import { useNavigate, Navigate } from 'react-router-dom';
import { ModulePath, Severity, VerificationType } from '../../../constant/ApplicationVariables';
import validator from "validator";
import { useState } from 'react';
import { useEffect } from 'react';
import { CircularProgress, IconButton, InputAdornment, useTheme } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { font } from '../../../styles/font';
import MainContainer, { AuthCardComponent } from '../container';

const Card = styled(MuiCard)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    width: '100%',
    py: theme.spacing(4),
    gap: theme.spacing(4),
    margin: 'auto',
    borderRadius: theme.shape.borderRadius,
    // background: theme.palette.background.paper,
    background: "transparent",
    boxShadow: "none",
    [theme.breakpoints.up('sm')]: {
        // maxWidth: '450px',
    },
    // boxShadow:
    //     'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
    // ...theme.applyStyles('dark', {
    //     boxShadow:
    //         'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
    // }),
}));


export default function ForgotPasswordContainer() {
    const { handleSnackbar, profileDetails, setProfileDetails, accountVerificationType, setAccountVerificationType, handleStoreUserData } = useApplicationContext();
    const errorHandler = useErrorHandler();
    const navigate = useNavigate();
    const theme = useTheme();

    const [loginData, setLoginData] = useState(null);
    const [error, setError] = useState(null);

    const [loading, setLoading] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent default form submission behavior
        validateInputs(); // Call your validation function
    };

    const handleChangeValue = (event) => {
        const { name, value } = event.target;

        setLoginData((prevData) => ({
            ...prevData,
            [name]: value
        }))
    }

    const validateInputs = () => {
        let isValid = true;
        const errorObject = {};

        if (!loginData?.email || !validator.isEmail(loginData?.email)) {
            errorObject.email = "Please enter a valid email address.";
            isValid = false;
        }

        setError(errorObject);

        return isValid && handleContinue();
    };

    const handleContinue = async () => {
        try {
            setLoading(true);

            let body = {
                // otp: "",
                email: loginData.email,
                type: VerificationType.ForgotPassword
            }

            const response = await requestOTP_API(body);

            if (response) {
                errorHandler(response);
            }

            if (response.status === HttpStatusCode.Ok) {
                // setAccountVerificationType(VerificationType.ForgotPassword);
                // localStorage.setItem("email", loginData.email);
                handleSnackbar("OTP sent successfully", Severity.success);

                setTimeout(() => {
                    navigate(ModulePath.verifyAccount, { state: { email: loginData.email, type: VerificationType.ForgotPassword, fromPath: ModulePath.forgotPassword } });
                }, 1100);
            }
        } catch (error) {
            console.log(error, error?.message, "login errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }


    return (
        <Box className=" flex flex-col gap-0 relative h-full w-full box-border overflow-hidden" >
            <MainContainer >
                <AuthCardComponent className="flex-1 gap-10 ">




                    {/* header container */}
                    <Box className="flex flex-col   ">

                        <Typography
                            sx={{ width: '100%', }}
                            color="textPrimary"
                            fontSize={font.size.fs_1_5}
                            fontWeight="bold"
                        >
                            Forgot Password
                        </Typography>

                        <Box className="flex items-center gap-2">
                            <Typography color={theme.palette.text.ternary} fontSize={font.size.fs_5} className="flex items-center justify-center gap-1 ">
                                Back to login{' '}

                                <Typography color={theme.palette.text.primary} fontSize={"inherit"} fontWeight="bold" className="underline cursor-pointer " onClick={() => navigate(ModulePath.login)}>
                                    Click here
                                </Typography>

                            </Typography>
                        </Box>
                    </Box>

                {/* form container */}
                <Box
                        component="form"
                        onSubmit={handleSubmit}
                        noValidate
                        className="flex flex-col w-full gap-10     "

                    >

                        {/* input container */}
                        <Box
                            className="flex flex-col w-full gap-6 py-0  "

                        >
                            <FormControl>
                                {/* <FormLabel htmlFor="email">Email</FormLabel> */}
                                <TextField
                                    label="Email"
                                    error={error?.email && error?.email}
                                    helperText={error?.email && error?.email}
                                    id="email"
                                    type="email"
                                    name="email"
                                    placeholder="your@email.com"
                                    autoComplete="email"
                                    autoFocus
                                    required
                                    fullWidth
                                    variant="filled"
                                    color={error?.email ? 'error' : 'primary'}
                                    value={loginData?.email}
                                    onChange={handleChangeValue}
                                />
                            </FormControl>
                        </Box>

                        <Button
                            size="medium"
                            type="submit"
                            fullWidth
                            color="secondary"
                            variant="contained"
                            disabled={loading}
                            onClick={validateInputs}
                            sx={{ color: '#fff' }}
                        >
                            {loading ? <CircularProgress size={20} /> : "Request OTP"}
                        </Button>

                    </Box>


                </AuthCardComponent>
            </MainContainer>
        </Box>
    );
}