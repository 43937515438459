import { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AI_Entity, Entity_Label, FolderType, ModulePath } from "../constant/ApplicationVariables";
import dayjs from "dayjs";

export const ApplicationContext = createContext();
let EXPIRATION_THRESHOLD = 5; // 5 minutes in milliseconds

export const ApplicationContextProvider = ({ children }) => {

    const pathName = useLocation()?.pathname;
    const navigate = useNavigate();

    const [toggleChatWindow, setToggleChatWindow] = useState(false); // To toggle the AI chat window
    const [toggleFolderSidebar, setToggleFolderSidebar] = useState(true); // To toggle the folder sidebar
    const [isItemChatWindow, setIsItemChatWindow] = useState(false); // To toggle the AI chat window on item click

    const [showValidTillConfirmation, setShowValidTillConfirmation] = useState(false); // To show the token expiration confirmation
    const [intervalId, setIntervalId] = useState(null); // Token expiration interval



    const [globalSnackbar, setGLobalSnackBar] = useState({
        open: false,
        title: "",
        severity: "",
    }); // To show the global snackbar
    const [globalLoader, setGlobalLoader] = useState(false); // To show the global loader

    const [headerSearchText, setHeaderSearchText] = useState(""); // To store the header search text


    const [profileDetails, setProfileDetails] = useState(null); // To store the profile details of the user with token 
    const [userDetail, setUserDetail] = useState(null); // To store the complete profile details of the user without token
    const [accountVerificationType, setAccountVerificationType] = useState(""); // To store the account verification type / otp verification


    const [labelList, setLabelList] = useState([]); // To store the labels list of the user


    const [currentAIEntityData, setCurrentAIEntityData] = useState(null); // To store the current AI entity, label data, data = { label: "", entity: "" }
    const [currentAIResponseSaveData, setCurrentAIResponseSaveData] = useState(null); // To store the current AI response save data
    const [currentAIContextData, setCurrentAIContextData] = useState(null); // To store the current AI context data



    /* toggle states */
    const [openCreateProjectDrawer, setOpenCreateProjectDrawer] = useState(false); // To open the create project drawer
    const [openEditProjectDrawer, setOpenEditProjectDrawer] = useState(false); // To open the edit project drawer
    const [openDeleteProjectDialog, setOpenDeleteProjectDialog] = useState(false); // To open the delete project dialog
    const [openItemFilterDrawer, setOpenItemFilterDrawer] = useState(false); // To open the item filter drawer

    const [openCreateFolderDialog, setOpenCreateFolderDialog] = useState(false); // To open the create folder dialog
    const [openEditFolderDialog, setOpenEditFolderDialog] = useState(false); // To open the edit folder dialog
    const [openDeleteFolderDialog, setOpenDeleteFolderDialog] = useState(false); // To open the delete folder dialog


    const [openCreateStoryDialog, setOpenCreateStoryDialog] = useState(false); // To open the create story dialog
    const [openEditStoryDialog, setOpenEditStoryDialog] = useState(false); // To open the edit story dialog
    const [openDeleteStoryDialog, setOpenDeleteStoryDialog] = useState(false); // To open the delete story dialog
    const [openStoryPreview, setOpenStoryPreview] = useState(false); // To open the story preview - NOT IN USE
    const [openStoryConfirmationPreview, setOpenStoryConfirmationPreview] = useState(false); // To open the story confirmation preview - OLD
    const [openLinkTagDrawer, setOpenLinkTagDrawer] = useState(false); // To open the link tag drawer


    const [openCreateUseCaseDialog, setOpenCreateUseCaseDialog] = useState(false); // To open the create usecase dialog
    const [openEditUseCaseDialog, setOpenEditUseCaseDialog] = useState(false); // To open the edit usecase dialog
    const [openDeleteUseCaseDialog, setOpenDeleteUseCaseDialog] = useState(false); // To open the delete usecase dialog


    /* list and data states*/
    const [activeProject, setActiveProject] = useState(null); // To store the active project data


    const [categoryList, setCategoryList] = useState([]); // To store the category list
    const [subCategoryList, setSubCategoryList] = useState([]); // To store the sub category list  
    const [activeProjectCategory, setActiveProjectCategory] = useState(null); // To store the active project category
    const [activeProjectSubcategory, setActiveProjectSubcategory] = useState(null); // To store the active project subcategory
    const [projectList, setProjectList] = useState([]); // To store the project list
    const [isUpdateProjectList, setIsUpdateProjectList] = useState(false); // Toggle to update the project list


    const [folderStructureList, setFolderStructureList] = useState([]); // To store the folder structure list
    const [currentFolderData, setCurrentFolderData] = useState(null); // To store the current folder data
    const [currentDataSavingFolder, setCurrentDataSavingFolder] = useState(null); // To store the current data saving folder data
    const [currentFolderAnchorEl, setCurrentFolderAnchorEl] = useState(null); // To store the current folder anchor element
    const [isUpdateFolderStructure, setIsUpdateFolderStructure] = useState(false); // Toggle to update the folder structure
    const [isUpdateCurrentFolderData, setIsUpdateCurrentFolderData] = useState(false); // Toggle to update the current folder data
    const [currentEditFolderData, setCurrentEditFolderData] = useState(null); // To store the current folder edit data


    const [userStoryList, setUserStoryList] = useState([]); // To store the user story list
    const [currentUserStoryData, setCurrentUserStoryData] = useState(null); // To store the current user story
    const [currentUserStory, setCurrentUserStory] = useState(null); // To store the current user story - OLD
    const [selectedUserStoryList, setSelectedUserStoryList] = useState([]); // To store the selected user story
    const [isUserStoryLoading, setIsUserStoryLoading] = useState(false); // To show the user story loading
    const [filteredUserStoryList, setFilteredUserStoryList] = useState([]); // To store the filtered user story list
    const [userStoryPropertiesData, setUserStoryPropertiesData] = useState(null); // To store the user story properties  (type, state, moscow, priority, confidentiality)


    const [useCaseList, setUseCaseList] = useState([]); // To store the use case list
    const [currentUseCaseData, setCurrentUseCaseData] = useState(null); // To store the current use case
    const [selectedUseCaseList, setSelectedUseCaseList] = useState([]); // To store the selected use case
    const [filteredUseCaseList, setFilteredUseCaseList] = useState([]); // To store the filtered use case list
    const [useCasePropertiesData, setUseCasePropertiesData] = useState(null); // To store the use case properties data (type, priority, state, status)

    const [testCaseList, setTestCaseList] = useState([]); // To store the test case list
    const [currentTestCaseData, setCurrentTestCaseData] = useState(null); // To store the current test case
    const [selectedTestCaseList, setSelectedTestCaseList] = useState([]); // To store the selected test case
    const [filteredTestCaseList, setFilteredTestCaseList] = useState([]); // To store the filtered test case list

    const [activeDashboard, setActiveDashboard] = useState(null); // To store the active dashboard

    /* loading and filter states*/
    const [isActiveProjectContainerLoading, setIsActiveProjectContainerLoading] = useState(false); // To show the active project container loading
    const [currentFilters, setCurrentFilters] = useState(null); // To store the current filters of active project items list

    const [isContentLoading, setIsContentLoading] = useState(false); // To show the content loading
    const [isGridViewEnabled, setIsGridViewEnabled] = useState(false); // To enable the grid view
    const [isShowStoryFilter, setIsShowStoryFilter] = useState(true); // To show the story filter


    const handleSnackbar = (message, severity, setLoadingState) => {
        setGLobalSnackBar({
            open: true,
            title: message,
            severity: severity,
        });
        setLoadingState && setLoadingState(false);
    };

    const handleLogout = () => {
        localStorage.removeItem("profileDetails");
        localStorage.removeItem("access-token");
        localStorage.removeItem("activeProject");
        localStorage.clear();

        navigate(ModulePath.login);
        setProfileDetails(null);
        setActiveProject(null);
        setFolderStructureList([]);
        setUserStoryList([]);

    }

    const handleStoreUserData = (responseData) => {
        localStorage.setItem("access-token", responseData?.authToken);
        localStorage.setItem("profileDetails", JSON.stringify(responseData));
        setProfileDetails(responseData);
    }

    const getAILabel = () => {
        if (currentAIEntityData) {
            return `AI assistance for: ${currentAIEntityData?.label}`;
            // switch (currentFolderData?.type) {
            //     case FolderType.project: return `AI assistance for: ${currentAIEntityData?.label}`;
            //     case FolderType.module: return `AI assistance for: ${currentAIEntityData?.label}`;
            //     default: return "using AI";
            // }
        }
    }

    console.log(getAILabel())



    useEffect(() => {
        if (window && localStorage.getItem("profileDetails")) {
            setProfileDetails(JSON.parse(localStorage.getItem("profileDetails")));
        }

        if (window && !localStorage.getItem("profileDetails")) {
            setProfileDetails(null)
        }

        if (localStorage.getItem("activeProject") && pathName !== ModulePath.projects && !activeProject) {
            setActiveProject(JSON.parse(localStorage.getItem("activeProject")));
        }



        // when user not authenticated
        // if (window && (!localStorage.getItem("profileDetails") || !localStorage.getItem("access-token"))) {
        //     navigate(ModulePath.login)
        // }
    }, [pathName]);


    useEffect(() => {
        if (activeProject && folderStructureList?.length === 0 && pathName !== ModulePath.projects) {
            // setIsUpdateFolderStructure(true);
        }
    }, [activeProject, folderStructureList?.length]);


    // To set the currentAIEntityData based on the folder / item type
    useEffect(() => {
        if (!isItemChatWindow && currentAIEntityData?.entity !== AI_Entity.folder ) {
            setCurrentAIEntityData({
                label: pathName === ModulePath.requirements ? Entity_Label.userStory :pathName === ModulePath.useCase ? Entity_Label.useCase : Entity_Label.testCase,
                entity: pathName === ModulePath.requirements ? AI_Entity.userStory : pathName === ModulePath.useCase ? AI_Entity.useCase : AI_Entity.testCase
            });
        }
    }, [isItemChatWindow, pathName, currentFolderData?.id, toggleChatWindow]);


    useEffect(() => {
        const checkTokenExpiration = () => {
            const tokenValidTill = profileDetails?.validTill;

            if (tokenValidTill) {
                const expirationThresholdTime = dayjs(tokenValidTill).subtract(EXPIRATION_THRESHOLD, 'minutes');
                const now = dayjs();
                const timeUntilExpiration = expirationThresholdTime.diff(now, 'milliseconds');

                // console.log(timeUntilExpiration, "timeUntilExpiration")

                // if (timeUntilExpiration <= EXPIRATION_THRESHOLD) {
                //     setShowValidTillConfirmation(true);
                // }

                if (timeUntilExpiration <= 0) {
                    setShowValidTillConfirmation(true);
                    clearInterval(intervalId);
                    // handleLogout();
                }
            }
        };

        const startTimer = () => {
            const tokenValidTill = profileDetails?.validTill;

            if (tokenValidTill) {
                const expirationThresholdTime = dayjs(tokenValidTill).subtract(EXPIRATION_THRESHOLD, 'minutes');
                const now = dayjs();
                const timeUntilExpiration = expirationThresholdTime.diff(now, 'seconds');
                console.log(timeUntilExpiration, "timeUntilExpiration")

                if (timeUntilExpiration > 0) {
                    const id = setTimeout(() => {
                        checkTokenExpiration();
                    }, timeUntilExpiration);
                    setIntervalId(id);
                }

                if (timeUntilExpiration <= 0) {
                    setShowValidTillConfirmation(true);
                }
            }
        };

        if (profileDetails && profileDetails?.validTill && !intervalId && !showValidTillConfirmation) {
            startTimer();
        }


        return () => {
            clearInterval(intervalId);
        };
    }, [profileDetails]);


    const values = {
        pathName,
        toggleChatWindow,
        setToggleChatWindow,
        toggleFolderSidebar,
        setToggleFolderSidebar,
        isItemChatWindow,
        setIsItemChatWindow,
        globalSnackbar,
        setGLobalSnackBar,
        globalLoader,
        setGlobalLoader,
        headerSearchText,
        setHeaderSearchText,
        profileDetails,
        setProfileDetails,
        userDetail, 
        setUserDetail,
        accountVerificationType,
        setAccountVerificationType,
        labelList,
         setLabelList,
        currentAIEntityData,
        setCurrentAIEntityData,
        currentAIResponseSaveData, 
        setCurrentAIResponseSaveData,
        currentAIContextData, 
        setCurrentAIContextData,
        showValidTillConfirmation,
        setShowValidTillConfirmation,
        openCreateProjectDrawer,
        setOpenCreateProjectDrawer,
        openEditProjectDrawer,
        setOpenEditProjectDrawer,
        openDeleteProjectDialog,
        setOpenDeleteProjectDialog,
        openItemFilterDrawer,
        setOpenItemFilterDrawer,
        openCreateFolderDialog,
        setOpenCreateFolderDialog,
        openEditFolderDialog,
        setOpenEditFolderDialog,
        openDeleteFolderDialog,
        setOpenDeleteFolderDialog,
        categoryList,
        setCategoryList,
        subCategoryList,
        setSubCategoryList,
        activeProjectCategory,
        setActiveProjectCategory,
        activeProjectSubcategory,
        setActiveProjectSubcategory,
        projectList,
        setProjectList,
        isUpdateProjectList,
        setIsUpdateProjectList,
        currentUserStory,
        setCurrentUserStory,
        selectedUserStoryList,
        setSelectedUserStoryList,
        isUserStoryLoading,
        setIsUserStoryLoading,
        filteredUserStoryList,
        setFilteredUserStoryList,
        userStoryPropertiesData,
        setUserStoryPropertiesData,
        useCaseList,
        setUseCaseList,
        currentUseCaseData,
        setCurrentUseCaseData,
        selectedUseCaseList,
        setSelectedUseCaseList,
        filteredUseCaseList,
        setFilteredUseCaseList,
        useCasePropertiesData,
        setUseCasePropertiesData,
        testCaseList,
        setTestCaseList,
        currentTestCaseData,
        setCurrentTestCaseData,
        selectedTestCaseList,
        setSelectedTestCaseList,
        filteredTestCaseList,
        setFilteredTestCaseList,

        activeDashboard,
        setActiveDashboard,

        isActiveProjectContainerLoading,
        setIsActiveProjectContainerLoading,
        currentFilters,
        setCurrentFilters,

        folderStructureList,
        setFolderStructureList,
        userStoryList,
        setUserStoryList,
        currentFolderData,
        setCurrentFolderData,
        currentDataSavingFolder,
        setCurrentDataSavingFolder,
        currentFolderAnchorEl,
        setCurrentFolderAnchorEl,
        isUpdateFolderStructure,
        setIsUpdateFolderStructure,
        isUpdateCurrentFolderData,
        setIsUpdateCurrentFolderData,
        currentEditFolderData,
        setCurrentEditFolderData,
        currentUserStoryData,
        setCurrentUserStoryData,
        openCreateStoryDialog,
        setOpenCreateStoryDialog,
        openEditStoryDialog,
        setOpenEditStoryDialog,
        openDeleteStoryDialog,
        setOpenDeleteStoryDialog,
        openStoryPreview,
        setOpenStoryPreview,
        openStoryConfirmationPreview,
        setOpenStoryConfirmationPreview,
        openCreateUseCaseDialog,
        setOpenCreateUseCaseDialog,
        openEditUseCaseDialog,
        setOpenEditUseCaseDialog,
        openDeleteUseCaseDialog,
        setOpenDeleteUseCaseDialog,
        openLinkTagDrawer,
        setOpenLinkTagDrawer,
        isContentLoading,
        setIsContentLoading,
        isGridViewEnabled,
        setIsGridViewEnabled,
        isShowStoryFilter,
        setIsShowStoryFilter,
        activeProject,
        setActiveProject,


        // functions
        handleSnackbar,
        handleLogout,
        handleStoreUserData,
        getAILabel
    };

    return (
        <ApplicationContext.Provider value={values}>
            {children}
        </ApplicationContext.Provider>
    )
}

export const useApplicationContext = () => useContext(ApplicationContext);