import { AddTwoTone, Attachment, AutoAwesome, AutoAwesomeOutlined } from "@mui/icons-material"
import { Box, Checkbox, CircularProgress, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material"
import { amber, blue, green, grey, red } from "@mui/material/colors"
import { forwardRef, useMemo, useState } from "react"
import { TableVirtuoso } from "react-virtuoso"
import ViewUserStoryDrawer from "../drawer/ViewUserStory"
import { useApplicationContext } from "../../context/ApplicationContext"
import { font } from "../../styles/font"
import { LightTooltip } from "../styled/CustomComponent"
import { Entity_Label, FolderType } from "../../constant/ApplicationVariables"
import dayjs from "dayjs"
import StatusBadge from "../common/StatusBadge"
import AddUserFieldsDrawer from "../drawer/AddUserFieldsDrawer"
import { LuLink, LuUnlink } from "react-icons/lu";

const TableComponents = {
    Scroller: forwardRef((props, ref) => <TableContainer component={Paper} style={{ overflowX: "auto", overflowY: "scroll" }} {...props} ref={ref} />),
    Table: (props) => <Table {...props} style={{ borderCollapse: 'separate' }} />,
    TableHead: TableHead,
    TableRow: (props) => <TableRow {...props} />,
    TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
    TableCell: (props) => <TableCell {...props} sx={{ ...props?.sx, px: "1rem !important" }} />,
}

export default function TestCaseList({ dataList, }) {
    const {
        currentUserStoryData, setCurrentUserStoryData,
        isUserStoryLoading, setIsUserStoryLoading,
        openLinkTagDrawer, setOpenLinkTagDrawer,
        openEditUseCaseDialog, setOpenEditUseCaseDialog,
        useCaseList, setUseCaseList,
        currentUseCaseData, setCurrentUseCaseData,
        filteredUseCaseList, setFilteredUseCaseList,
        isActiveProjectContainerLoading, setIsActiveProjectContainerLoading,
        selectedUseCaseList, setSelectedUseCaseList,
        toggleChatWindow, setToggleChatWindow,
        isItemChatWindow, setIsItemChatWindow,
    } = useApplicationContext();
    const theme = useTheme();



    const handleOpen = (item) => {
        setCurrentUseCaseData(item);
        setOpenEditUseCaseDialog(true);
        console.log(item, "item handleOpen")
    }

    const handleChangeCheckboxAll = (e) => {
        if (e.target.checked) {
            setSelectedUseCaseList(dataList);
        } else {
            setSelectedUseCaseList([]);
        }
    }

    const handleChangeCheckbox = (e, item) => {
        if (e.target.checked) {
            setSelectedUseCaseList([...selectedUseCaseList, item]);
        } else {
            setSelectedUseCaseList(selectedUseCaseList?.filter((story) => story?.customId !== item?.customId));
        }
    }

    const handleOpenLinkTag = (data) => {
        setCurrentUseCaseData(data)
        setOpenLinkTagDrawer(true)
    }


    const columns = [
        // {
        //     data: "AI",
        //     padding: "checkbox",
        //     align: "center",
        //     background: theme.palette.header.main,
        //     width: "40px"
        // },
        {
            data: <Checkbox size="small" disabled={isActiveProjectContainerLoading} checked={!isActiveProjectContainerLoading && useCaseList?.length > 0 && selectedUseCaseList?.length === useCaseList?.length} indeterminate={selectedUseCaseList?.length > 0 && selectedUseCaseList?.length < useCaseList?.length} onChange={handleChangeCheckboxAll} />,
            padding: "checkbox",
            align: "center",
            background: theme.palette.header.main,
            width: "40px"
        },
        {
            data: "Link",
            padding: "normal",
            align: "center",
            background: theme.palette.header.main,
            width: "70px"
        },
        {
            data: "Define Type",
            padding: "normal",
            align: "center",
            background: theme.palette.header.main,
            width: "100px"
        },
        {
            data: "Custom ID",
            padding: "normal",
            align: "center",
            background: theme.palette.header.main,
            width: "150px"
        },
        {
            data: "Title",
            padding: "normal",
            align: "left",
            background: theme.palette.header.main,
            width: "200px"
        },
        {
            data: "Steps",
            padding: "normal",
            align: "left",
            background: theme.palette.header.main,
            width: "480px"
        },
        {
            data: "Created At",
            padding: "normal",
            align: "left",
            background: theme.palette.header.main,
            width: "170px"
        },

    ];

    return (
        <Box className="grow w-full h-full flex flex-col overflow-hidden" borderRadius={theme.shape.borderRadius + 10}>
            <TableVirtuoso
                style={{ height: "100%", width: "100%", overflowX: 'auto', borderRadius: theme.shape.borderRadius }}
                data={dataList}
                components={TableComponents}
                fixedHeaderContent={() => (
                    <TableHead>
                        {
                            columns?.map((item, index) => (
                                <TableCell key={index} align={item?.align} padding={item?.padding} className="whitespace-nowrap" style={{ margin: "0 1rem !important", fontWeight: "bold", minWidth: item?.width, maxWidth: item?.width, background: item?.background }}>
                                    <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap"> {item?.data}</Typography>
                                </TableCell>
                            ))
                        }
                    </TableHead>
                )}


                itemContent={(index, storyData) => (

                    <TableRow onClick={() => handleOpen(storyData)} sx={{ background: storyData?.customId === currentUseCaseData?.customId && grey[200], "&:hover": { background: grey[100], } }}>

                        {/* <TableCell padding="checkbox" align="center" sx={{ minWidth: "40px", maxWidth: "40px", position: "sticky", left: 0, }} onClick={(e) => handleToggleAIWindow(e, storyData)}>
                                    <IconButton sx={{ background: theme.palette.common.white }}>
                                        {isItemChatWindow && storyData?.customId === currentUseCaseData?.customId ? <AutoAwesome color="primary" /> : <AutoAwesomeOutlined color="primary" />}
                                    </IconButton>
                                </TableCell> */}

                        <TableCell padding="checkbox" align="center" sx={{ minWidth: "40px", maxWidth: "40px", }} onClick={(e) => e && e.stopPropagation()}>
                            <Checkbox
                                size="small"
                                checked={selectedUseCaseList?.includes(storyData)}
                                onChange={(e) => handleChangeCheckbox(e, storyData)}
                            />
                        </TableCell>
                        <TableCell padding="normal" align="center" sx={{ minWidth: "70px", maxWidth: "70px", cursor: "pointer" }} onClick={(e) => e && e.stopPropagation()}>
                            <LightTooltip title={`Link: ${Entity_Label.userStory} / ${Entity_Label.testCase}`} >
                                <IconButton size="small" onClick={() => handleOpenLinkTag(storyData)} sx={{}}>
                                    {storyData?.userStories?.length > 0 ? <LuLink color={green[500]} fontSize={font.size.fs_3} />
                                        : <LuUnlink color={red[500]} fontSize={font.size.fs_3} />}
                                </IconButton>
                            </LightTooltip>
                        </TableCell>
                        <LightTooltip title={`Use Case`}>
                            <TableCell padding="normal" align="center" sx={{ minWidth: "100px", maxWidth: "100px", cursor: "pointer" }}>
                                <TextFieldComponent > Test Case</TextFieldComponent>
                            </TableCell>
                        </LightTooltip>
                        <LightTooltip title={storyData?.customId}>
                            <TableCell padding="normal" align="center" sx={{ minWidth: "150px", maxWidth: "150px", cursor: "pointer" }}>
                                <TextFieldComponent color={theme.palette.text.primary} className=" font-bold underline cursor-pointer"> #{storyData?.customId}</TextFieldComponent>
                            </TableCell>
                        </LightTooltip>
                        <LightTooltip title={storyData?.title}>
                            <TableCell padding="normal" align="left" sx={{ minWidth: "200px", maxWidth: "200px", cursor: "pointer" }}>
                                <TextFieldComponent > {storyData?.title}</TextFieldComponent>
                            </TableCell>
                        </LightTooltip>
                        <LightTooltip title={storyData?.useCaseSteps?.join(", ")} >
                            <TableCell padding="normal" align="left" sx={{ minWidth: "480px", maxWidth: "480px", cursor: "pointer" }}>
                                <TextFieldComponent > {storyData?.useCaseSteps?.join(", ")}</TextFieldComponent>
                            </TableCell>
                        </LightTooltip>

                        <LightTooltip title={dayjs(storyData?.createdAt).format("DD MMM YYYY hh:mm A")}>
                            <TableCell padding="normal" align="left" sx={{ minWidth: "170px", maxWidth: "170px", cursor: "pointer" }}>
                                <TextFieldComponent > {dayjs(storyData?.createdAt).format("DD MMM YYYY hh:mm A")}</TextFieldComponent>
                            </TableCell>
                        </LightTooltip>

                    </TableRow>

                )}
            />

            {/* <AddUserFieldsDrawer
                open={true}
                handleClose={handleCloseUserFieldsDrawer}
                handleContinue={() => { }}
            /> */}


            {/* {openUserStoryDrawer && currentUserStoryData && 
            <ViewUserStoryDrawer open={openUserStoryDrawer} handleClose={handleCloseData} />} */}
        </Box>
    )
}





function TextFieldComponent({ children, ...props }) {
    const theme = useTheme();
    return <Typography fontSize={font.size.fs_5} color={theme.palette.text.secondary} className={`line-clamp-1 ${props.className}`} {...props}> {children}</Typography>
}