import { Avatar, Box, Button, CircularProgress, IconButton, InputAdornment, ListItemIcon, ListItemText, MenuItem, Popover, Tab, Tabs, TextField, ToggleButton, Typography, useTheme } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import ApplicationNavigation from './ApplicationNavigation'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import { useApplicationContext } from '../../context/ApplicationContext';
import { AccountCircleOutlined, ArrowRightAlt, AutoAwesomeOutlined, HelpOutline, HomeOutlined, HomeTwoTone, LogoutOutlined, MenuOpen, Search, SearchTwoTone, Settings, Style } from '@mui/icons-material';
import { grey, purple } from '@mui/material/colors';

import { useLocation, useNavigate } from 'react-router-dom';
import { Entity_Label, FolderType, ModulePath } from '../../constant/ApplicationVariables';
import { font } from '../../styles/font';
import { LightTooltip } from '../styled/CustomComponent';
import AutoAwesomeTwoToneIcon from '@mui/icons-material/AutoAwesomeTwoTone';
import ApplicationLogo from './ApplicationLogo';
import MediaComponent from '../media/MediaComponent';

export default function ApplicationHeader() {
    const {
        activePage, setActivePage,
        headerSearchText, setHeaderSearchText,
        profileDetails,
        toggleFolderSidebar, setToggleFolderSidebar,
        toggleChatWindow, setToggleChatWindow,
        setActiveProject,
        setFolderStructureList,
        setUserStoryList,
        currentFolderData, setCurrentFolderData,
        handleLogout,
        isItemChatWindow, setIsItemChatWindow,
        currentUserStoryData, setCurrentUserStoryData,
        currentUseCaseData, setCurrentUseCaseData,
        openCreateStoryDialog, setOpenCreateStoryDialog,
        openEditStoryDialog, setOpenEditStoryDialog,
        openDeleteStoryDialog, setOpenDeleteStoryDialog,
        openCreateUseCaseDialog, setOpenCreateUseCaseDialog,
        openEditUseCaseDialog, setOpenEditUseCaseDialog,
        openDeleteUseCaseDialog, setOpenDeleteUseCaseDialog,
        getAILabel,
        userDetail, setUserDetail
    } = useApplicationContext();
    const theme = useTheme();
    const pathName = useLocation()?.pathname;
    const navigate = useNavigate();
    let timer;

    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [activePageTab, setActivePageTab] = useState(ModulePath.requirements);
    let [headerPagesList, setHeaderPagesList] = useState([]);

    const [profileAnchorEl, setProfileAnchorEl] = useState(null);

    const handleClickProfilePopup = (event) => {
        if (profileAnchorEl) {
            setProfileAnchorEl(null);
        } else {
            setProfileAnchorEl(event.currentTarget);
        }
    };

    const handleChangeSearch = (e) => {
        clearTimeout(timer);
        setLoading(true);
        setIsLoading(true)

        timer = setTimeout(() => {
            setHeaderSearchText(e.target.value);
            setLoading(false);
            setIsLoading(false)
        }, 1100);
    }


    const getAIButtonText = () => {
        if (toggleChatWindow) {
            return "Close AI Assistant";
        } else {
            return getAILabel()
        }
    }

    const handleHomeClick = () => {
        navigate(ModulePath.projects);
        setActiveProject(null);
        localStorage.removeItem("activeProject");
        setFolderStructureList([]);
        setUserStoryList([]);
        setCurrentFolderData(null);

        // to close the project item dialog
        setOpenEditStoryDialog(false);
        setOpenCreateStoryDialog(false);
        setOpenDeleteStoryDialog(false);
        setOpenEditUseCaseDialog(false);
        setOpenCreateUseCaseDialog(false);
        setOpenDeleteUseCaseDialog(false);
    }

    const handlePageClick = (page) => {
        page.onClick();
        if (currentFolderData) {
            setCurrentFolderData(null);
        }
    }

    // To create the navigation pages
    useEffect(() => {
        let array = [];

        setCurrentUserStoryData(null);
        setCurrentUseCaseData(null);
        setIsItemChatWindow(false);

        setActivePageTab(pathName);

        if (pathName === ModulePath.projects) {
            array = [
                {
                    name: `${ModulePath.projects?.replace("/", "")}`,
                    path: ModulePath.projects,
                    onClick: () => navigate(ModulePath.projects),
                    style: {}
                },
                {
                    name: `${ModulePath.programmes?.replace("/", "")}`,
                    path: ModulePath.programmes,
                    onClick: () => navigate(ModulePath.programmes),
                    style: {}

                },
                // {
                //     name: `${ModulePath.departments?.replace("/", "")}`,
                //     path: ModulePath.departments,
                //     onClick: () => navigate(ModulePath.departments),
                //     style: {}

                // },
                {
                    name: `${ModulePath.organisations?.replace("/", "")}`,
                    path: ModulePath.organisations,
                    onClick: () => navigate(ModulePath.organisations),
                    style: {}

                },
            ]
        }


        else {
            array = [{
                name: "User Story",
                path: ModulePath.requirements,
                onClick: () => navigate(ModulePath.requirements),
                style: {}
            },
            {
                name: "Use Case",
                path: ModulePath.useCase,
                onClick: () => navigate(ModulePath.useCase),
                style: {}

            },
            {
                name: "Test Case",
                path: ModulePath.testCase,
                onClick: () => navigate(ModulePath.testCase),
                style: {}

            },
            {
                name: "Planning",
                path: ModulePath.planning,
                onClick: () => navigate(ModulePath.planning),
                style: {}

            },
            {
                name: "Execution",
                path: ModulePath.execution,
                onClick: () => navigate(ModulePath.execution),
                style: {}

            },
            {
                name: "Dashboard",
                path: ModulePath.dashboard,
                onClick: () => navigate(ModulePath.dashboard),
                style: {}

            },
            ]
        }

        setHeaderPagesList(array);

    }, [pathName])

    useEffect(() => {

    }, [profileDetails])

    console.log(headerSearchText, "headerSearchText");
    console.log(headerPagesList, "headerpageslist", pathName, ModulePath.requirements)

    return (
        <Box className="h-full w-full flex flex-col gap-4" >

            {/* main container */}
            <Box className="h-full w-full flex items-center gap-4 ">

                <Box className="h-full  flex items-center gap-4 px-0 py-2.5">

                    {pathName !== `${(ModulePath.projects)}` &&
                        <LightTooltip title={`${toggleFolderSidebar ? "Hide Sidebar" : "Show Sidebar"}`}>
                            <ToggleButton
                                value="check"
                                size="small"
                                selected={!toggleFolderSidebar}
                                onChange={() => setToggleFolderSidebar((prevSelected) => !prevSelected)}
                            >
                                <MenuOpen sx={{ transform: !toggleFolderSidebar && "rotate(180deg)" }} />
                            </ToggleButton>
                        </LightTooltip>}

                    {true && <Box className="px-0 mr-20">
                        <ApplicationLogo />
                    </Box>}

                    {pathName !== `${(ModulePath.projects)}` &&
                        <LightTooltip title={`Back to Projects`}>
                            <ToggleButton
                                value="check"
                                size="small"
                                color="secondary"
                                selected={true}
                                onChange={handleHomeClick}
                            >
                                <HomeOutlined />
                            </ToggleButton>
                        </LightTooltip>}

                </Box>

                {/* pages */}
                <Box className="flex-1 h-full flex items-center gap-2 overflow-x-auto " sx={{ scrollbarWidth: 'none', msOverflowStyle: 'none', "&::-webkit-scrollbar": { display: 'none' } }}>
                    {pathName !== ModulePath.profile &&
                        headerPagesList?.map((page, index) => {
                            return (
                                <Button
                                    variant={page?.path === activePageTab ? "text" : "text"}
                                    size="medium"

                                    className='min-w-fit h-full'
                                    // color={page?.path === activePageTab ? theme.palette.secondary.contrastText : theme.palette.primary.contrastText}
                                    key={index}
                                    onClick={() => handlePageClick(page)}
                                    sx={{
                                        ...page?.style,
                                        minWidth: "fit-content",
                                        px: page?.path === activePageTab ? 1 : 1,
                                        // color : page?.path === activePageTab && theme.palette.secondary.main,
                                        fontSize: page?.path === activePageTab && font.size.fs_4,
                                        fontWeight: page?.path === activePageTab && "bold",
                                        boxShadow: "none",

                                        color: page?.path === activePageTab && "#B94097",
                                        borderBottom: page?.path === activePageTab && `2px solid #B94097`,
                                        borderRadius: 0,
                                        // background: page?.path === activePageTab ? theme.palette.primary.main : "transparent",
                                        // color: page?.path === activePageTab ? theme.palette.primary.contrastText : theme.palette.primary.main
                                    }}
                                >
                                    {page?.name}
                                </Button>
                            )
                        })
                    }


                </Box>

                {/* search container */}
                <Box className="h-full   w-72 px-0 py-2.5 flex items-center">
                    <TextField
                        fullWidth
                        placeholder="Search"
                        color="white"
                        size="medium"
                        sx={{
                            py: "2px",
                            height: "100%",
                            borderRadius: theme.shape.borderRadius,
                            background: theme.palette.background.custom,
                            // px: "4px",

                            '& .MuiInputBase-input': {

                                // height: "100%",
                                boxSizing: 'border-box',
                                border: "none",
                                // background: theme.palette.secondary.light,
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none'
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                border: 'none'
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                border: 'none'
                            }
                        }}
                        slotProps={{
                            input: {
                                startAdornment: <InputAdornment position="center">
                                    <Search />
                                </InputAdornment>,
                                endAdornment: <InputAdornment position="center">
                                    {loading && <CircularProgress size={22} color="inherit" />}
                                </InputAdornment>,
                            }
                        }}
                        // value={headerSearchText}
                        onChange={handleChangeSearch}
                    />
                </Box>

                {/* AI icon */}
                {pathName !== `${(ModulePath.projects)}` &&
                    <LightTooltip title={`${getAIButtonText()}`}>
                        {/* <ToggleButton
                            value="check"
                            size="small"
                            selected={!toggleChatWindow}
                            onChange={() => setToggleChatWindow((prevSelected) => !prevSelected)}
                            sx={{
                                "&.MuiToggleButton-root": {
                                    background: !toggleChatWindow && purple[500],
                                    color: !toggleChatWindow && theme.palette.secondary.contrastText,
                                    transition: "all 0.3s ease-in-out",
                                    "&:hover": {
                                        background: !toggleChatWindow && purple[700],
                                        // opacity: !toggleChatWindow && 0.8
                                    }
                                }
                            }}
                        >
                            <AutoAwesomeTwoToneIcon />
                        </ToggleButton> */}
                        <Button
                            startIcon={<AutoAwesomeTwoToneIcon />}
                            variant="contained"
                            color="ai"
                            size="large"
                            sx={{
                                background: !toggleChatWindow && theme.palette.ai.main,
                                color: !toggleChatWindow && theme.palette.ai.contrastText,
                                transition: "all 0.3s ease-in-out",
                                "&:hover": {
                                    background: !toggleChatWindow && theme.palette.ai.main,
                                    // opacity: !toggleChatWindow && 0.8
                                }
                            }}
                            onClick={() => setToggleChatWindow((prevSelected) => !prevSelected)}>AI</Button>
                    </LightTooltip>}


                <Box className="flex items-center justify-center gap-2 ">
                    <LightTooltip title="Help">
                        <IconButton size="small" onClick={() => { }}>
                            <HelpOutline sx={{ fontSize: font.size.fs_3 }} />
                        </IconButton>
                    </LightTooltip>

                    {false && <LightTooltip title="Settings">
                        <IconButton size="small" onClick={() => navigate(ModulePath.settings)}>
                            <Settings sx={{ fontSize: font.size.fs_3 }} />
                        </IconButton>
                    </LightTooltip>}
                </Box>


                {/* profile */}
                <Box className="flex items-center justify-center gap-4 " sx={{ color: theme.palette.text.primary }}>

                    <Box className="flex items-center justify-center gap-1 cursor-pointer" onClick={handleClickProfilePopup} sx={{ color: "inherit" }}>
                        <Box className="flex items-center justify-center shadow rounded-full" sx={{ width: 36.16, height: 36.16, }}>
                            {userDetail?.profilePicUrl
                                ? (
                                    <MediaComponent name={`Profile pic: ${userDetail?.firstName}`} type={"image"} variant={"circle"} url={userDetail?.profilePicUrl} sx={{ borderRadius: theme.shape.borderRadius, overFlow: "hidden" }} />
                                )
                                : <Avatar variant="circular" sx={{ width: "100%", height: "100%", background: theme.palette.secondary.light, color: theme.palette.secondary.main, textTransform: "uppercase" }}>
                                    {userDetail?.firstName?.charAt(0) || "-"}
                                </Avatar>}
                        </Box>

                        <Box className="flex-1 w-auto flex flex-col " sx={{ color: "inherit" }}>
                            <Typography fontSize={font.size.fs_5} className="truncate" sx={{ color: "inherit" }}>{userDetail?.firstName}</Typography>
                            <Typography fontSize={font.size.fs_6} className="truncate opacity-60" sx={{ color: theme.palette.text.secondary }}>{userDetail?.role || "Admin"}</Typography>
                        </Box>
                    </Box>

                </Box>
            </Box>


            {/* profie popup */}
            <Popover

                open={Boolean(profileAnchorEl)}
                anchorEl={profileAnchorEl}
                onClose={handleClickProfilePopup}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <MenuItem className="flex items-center gap-0" onClick={() => { navigate(ModulePath.profile); handleClickProfilePopup(); }}>
                    <ListItemIcon>
                        <AccountCircleOutlined fontSize="small" />
                    </ListItemIcon>
                    <ListItemText className='flex flex-1'>Profile</ListItemText>
                </MenuItem>

                <MenuItem className="flex items-center gap-0" onClick={() => { navigate(ModulePath.settings); handleClickProfilePopup(); }}>
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    <ListItemText className='flex flex-1'>Settings</ListItemText>
                </MenuItem>

                <MenuItem className="flex items-center gap-0" onClick={() => handleLogout()}>
                    <ListItemIcon>
                        <LogoutOutlined fontSize="small" />
                    </ListItemIcon>
                    <ListItemText className='flex flex-1'>Logout</ListItemText>
                </MenuItem>
            </Popover>
        </Box>
    )
}

