import { AddTwoTone, Attachment, AutoAwesome, AutoAwesomeOutlined } from "@mui/icons-material"
import { Box, Checkbox, CircularProgress, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material"
import { amber, blue, green, grey, red } from "@mui/material/colors"
import { forwardRef, useMemo, useState } from "react"
import { TableVirtuoso } from "react-virtuoso"
import ViewUserStoryDrawer from "../drawer/ViewUserStory"
import { useApplicationContext } from "../../context/ApplicationContext"
import { font } from "../../styles/font"
import { LightTooltip } from "../styled/CustomComponent"
import { Entity_Label, FolderType, ModulePath } from "../../constant/ApplicationVariables"
import dayjs from "dayjs"
import StatusBadge from "../common/StatusBadge"
import AddUserFieldsDrawer from "../drawer/AddUserFieldsDrawer"
import { LuLink, LuUnlink } from "react-icons/lu";
import { useLocation } from "react-router-dom"
import { LinkCustomIcon, UnLinkCustomIcon } from "../styled/CustomIcons"
import { formatDateTime } from "../../utils/DateTime"

const TableComponents = {
    Scroller: forwardRef((props, ref) => <TableContainer component={Paper} style={{ overflowX: "auto", overflowY: "scroll" }} {...props} ref={ref} />),
    Table: (props) => <Table {...props} style={{ borderCollapse: 'separate' }} />,
    TableHead: TableHead,
    TableRow: (props) => <TableRow {...props} />,
    TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
    TableCell: (props) => <TableCell {...props} sx={{ ...props?.sx, px: "1rem !important" }} />,
}

export default function UseCaseList({ dataList, }) {
    const {
        currentUserStoryData, setCurrentUserStoryData,

        currentFolderData,
        isUserStoryLoading, setIsUserStoryLoading,
        openLinkTagDrawer, setOpenLinkTagDrawer,
        openEditUseCaseDialog, setOpenEditUseCaseDialog,
        useCaseList, setUseCaseList,
        currentUseCaseData, setCurrentUseCaseData,
        filteredUseCaseList, setFilteredUseCaseList,
        isActiveProjectContainerLoading, setIsActiveProjectContainerLoading,
        selectedUseCaseList, setSelectedUseCaseList,
        toggleChatWindow, setToggleChatWindow,
        isItemChatWindow, setIsItemChatWindow,
    } = useApplicationContext();
    const theme = useTheme();
    const pathName = useLocation()?.pathname;


    const handleOpen = (item) => {
        setCurrentUseCaseData(item);
        setOpenEditUseCaseDialog(true);
        console.log(item, "item handleOpen")
    }

    const handleChangeCheckboxAll = (e) => {
        if (e.target.checked) {
            setSelectedUseCaseList(dataList);
        } else {
            setSelectedUseCaseList([]);
        }
    }

    const handleChangeCheckbox = (e, item) => {
        if (e.target.checked) {
            setSelectedUseCaseList([...selectedUseCaseList, item]);
        } else {
            setSelectedUseCaseList(selectedUseCaseList?.filter((story) => story?.customId !== item?.customId));
        }
    }

    const handleOpenLinkTag = (data) => {
        setCurrentUseCaseData(data)
        setOpenLinkTagDrawer(true)
    }

    const handleToggleAIWindow = (e, data) => {
        e && e.stopPropagation()

        if (isItemChatWindow && currentUseCaseData?.customId === data?.customId) {
            setIsItemChatWindow(false)
            setCurrentUseCaseData(null);
        } else {
            setCurrentUseCaseData(data);
            setIsItemChatWindow(true);
            setToggleChatWindow(true);
        }
    }

    const columns = [
        // {
        //     data: "AI",
        //     padding: "checkbox",
        //     align: "center",
        //     background: theme.palette.header.main,
        //     width: "40px"
        // },
        {
            data: <Checkbox size="small" disabled={isActiveProjectContainerLoading} checked={!isActiveProjectContainerLoading && selectedUseCaseList?.length > 0 && selectedUseCaseList?.length === useCaseList?.length} indeterminate={selectedUseCaseList?.length > 0 && selectedUseCaseList?.length < useCaseList?.length} onChange={handleChangeCheckboxAll} />,
            padding: "checkbox",
            align: "center",
            background: theme.palette.header.main,
            width: "40px",
            style: {
                position: "sticky", left: 0, background: theme.palette.background.paper
            }
        },
        {
            data: "Link",
            padding: "normal",
            align: "center",
            background: theme.palette.header.main,
            width: "70px",
            style: {
                position: "sticky", left: "40px", background: theme.palette.background.paper
            }
        },

        {
            data: "ID",
            padding: "normal",
            align: "center",
            background: theme.palette.header.main,
            width: "150px",
            style: {
                position: "sticky", left: "110px", background: theme.palette.background.paper
            }
        },
        {
            data: "Title",
            padding: "normal",
            align: "left",
            background: theme.palette.header.main,
            width: "200px",
            style: {
                position: "sticky", left: "260px", background: theme.palette.background.paper
            }
        },
        {
            data: "Steps",
            padding: "normal",
            align: "left",
            background: theme.palette.header.main,
            width: "480px",
            style: {}
        },
        {
            data: "Define Type",
            padding: "normal",
            align: "center",
            background: theme.palette.header.main,
            width: "100px",
            style: {}
        },

        {
            data: "Created By",
            key: "createdByEmail",
            padding: "normal",
            align: "left",
            background: theme.palette.header.main,
            width: "150px",
            order: "asc",
            isFilterable: true,
            style: {}
        },
        {
            data: "Creation Date",
            padding: "normal",
            align: "left",
            background: theme.palette.header.main,
            width: "170px",
            style: {}
        },


    ];

    const testCaseColumns = [
        // {
        //     data: "AI",
        //     padding: "checkbox",
        //     align: "center",
        //     background: theme.palette.header.main,
        //     width: "40px"
        // },
        {
            data: <Checkbox size="small" disabled={isActiveProjectContainerLoading} checked={!isActiveProjectContainerLoading && useCaseList?.length > 0 && selectedUseCaseList?.length === useCaseList?.length} indeterminate={selectedUseCaseList?.length > 0 && selectedUseCaseList?.length < useCaseList?.length} onChange={handleChangeCheckboxAll} />,
            padding: "checkbox",
            align: "center",
            background: theme.palette.header.main,
            width: "40px",
            style: {
                position: "sticky", left: 0, background: theme.palette.background.paper
            }
        },
        {
            data: "Link",
            padding: "normal",
            align: "center",
            background: theme.palette.header.main,
            width: "70px",
            style: {
                position: "sticky", left: "40px", background: theme.palette.background.paper
            }
        },

        {
            data: "ID",
            padding: "normal",
            align: "center",
            background: theme.palette.header.main,
            width: "150px",
            style: {
                position: "sticky", left: "110px", background: theme.palette.background.paper
            }
        },
        {
            data: "Title",
            padding: "normal",
            align: "left",
            background: theme.palette.header.main,
            width: "200px",
            style: {
                position: "sticky", left: "260px", background: theme.palette.background.paper
            }
        },
        {
            data: "Steps",
            padding: "normal",
            align: "left",
            background: theme.palette.header.main,
            width: "480px",
            style: {}
        },
        {
            data: "Define Type",
            padding: "normal",
            align: "center",
            background: theme.palette.header.main,
            width: "100px",
            style: {}
        },

        {
            data: "Created By",
            key: "createdByEmail",
            padding: "normal",
            align: "left",
            background: theme.palette.header.main,
            width: "150px",
            order: "asc",
            isFilterable: true,
            style: {}
        },

        {
            data: "Creation Date",
            padding: "normal",
            align: "left",
            background: theme.palette.header.main,
            width: "170px",
            style: {}
        },

        {
            data: "Expected Result",
            padding: "normal",
            align: "left",
            background: theme.palette.header.main,
            width: "170px",
            style: {}
        },

        {
            data: "Actual Result",
            padding: "normal",
            align: "left",
            background: theme.palette.header.main,
            width: "170px",
            style: {}
        },

    ];

    return (
        <Box className="grow w-full h-full flex flex-col overflow-hidden" borderRadius={theme.shape.borderRadius + 10}>
            <TableVirtuoso
                style={{ height: "100%", width: "100%", overflowX: 'auto', borderRadius: theme.shape.borderRadius }}
                data={dataList}
                components={TableComponents}
                fixedHeaderContent={() => (
                    <TableHead>
                        {pathName === ModulePath.useCase &&
                            columns?.map((item, index) => (
                                <TableCell key={index} align={item?.align} padding={item?.padding} className="whitespace-nowrap" sx={item?.style} style={{ margin: "0 1rem !important", fontWeight: "bold", minWidth: item?.width, maxWidth: item?.width, background: item?.background }}>
                                    <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap"> {item?.data}</Typography>
                                </TableCell>
                            ))
                        }

                        {pathName === ModulePath.testCase &&
                            testCaseColumns?.map((item, index) => (
                                <TableCell key={index} align={item?.align} padding={item?.padding} className="whitespace-nowrap" sx={item?.style} style={{ margin: "0 1rem !important", fontWeight: "bold", minWidth: item?.width, maxWidth: item?.width, background: item?.background }}>
                                    <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap"> {item?.data}</Typography>
                                </TableCell>
                            ))
                        }
                    </TableHead>
                )}


                itemContent={(index, itemData) => (

                    <TableRow onClick={() => handleOpen(itemData)} sx={{ background: itemData?.customId === currentUseCaseData?.customId && grey[200], "&:hover": { background: grey[100], } }}  >

                        {/* <TableCell padding="checkbox" align="center" sx={{ minWidth: "40px", maxWidth: "40px", position: "sticky", left: 0, }} onClick={(e) => handleToggleAIWindow(e, itemData)}>
                                    <IconButton sx={{ background: theme.palette.common.white }}>
                                        {isItemChatWindow && itemData?.customId === currentUseCaseData?.customId ? <AutoAwesome color="primary" /> : <AutoAwesomeOutlined color="primary" />}
                                    </IconButton>
                                </TableCell> */}
                        {pathName === ModulePath.useCase &&
                            <>
                                <TableCell padding="checkbox" align="center" sx={{ minWidth: "40px", maxWidth: "40px", position: "sticky", left: 0, background: theme.palette.background.paper }} onClick={(e) => e && e.stopPropagation()}>
                                    <Checkbox
                                        size="small"
                                        // disabled={currentFolderData?.type !== FolderType.module}
                                        checked={selectedUseCaseList?.includes(itemData)}
                                        onChange={(e) => handleChangeCheckbox(e, itemData)}
                                    />
                                </TableCell>
                                <TableCell padding="normal" align="center" sx={{ minWidth: "70px", maxWidth: "70px", cursor: "pointer", position: "sticky", left: "40px", background: theme.palette.background.paper }} onClick={(e) => e && e.stopPropagation()}>
                                    <LightTooltip title={`Link: ${Entity_Label.userStory} / ${Entity_Label.testCase}`} >
                                        <IconButton size="small" onClick={() => handleOpenLinkTag(itemData)} sx={{}}>
                                            {itemData?.userStories?.length > 0 ? <LinkCustomIcon />
                                                : <UnLinkCustomIcon />}
                                        </IconButton>
                                    </LightTooltip>
                                </TableCell>

                                <LightTooltip title={itemData?.customId}>
                                    <TableCell padding="normal" align="center" sx={{ minWidth: "150px", maxWidth: "150px", cursor: "pointer", position: "sticky", left: "110px", background: theme.palette.background.paper }}>
                                        <TextFieldComponent color={theme.palette.text.primary} className=" font-bold underline cursor-pointer"> {itemData?.customId}</TextFieldComponent>
                                    </TableCell>
                                </LightTooltip>

                                <LightTooltip title={itemData?.title}>
                                    <TableCell padding="normal" align="left" sx={{ minWidth: "200px", maxWidth: "200px", cursor: "pointer", position: "sticky", left: "260px", background: theme.palette.background.paper }}>
                                        <TextFieldComponent > {itemData?.title}</TextFieldComponent>
                                    </TableCell>
                                </LightTooltip>

                                <LightTooltip title={itemData?.useCaseSteps?.map((item, index) => <span key={index}>- {item}<br /></span>)} >
                                    <TableCell padding="normal" align="left" sx={{ minWidth: "480px", maxWidth: "480px", cursor: "pointer" }}>
                                        <TextFieldComponent className="line-clamp-2 truncate overflow-hidden">{itemData?.useCaseSteps?.map((item, index) => <span className="" key={index}>- {item}<br /></span>)}</TextFieldComponent>
                                    </TableCell>
                                </LightTooltip>

                                <LightTooltip title={`Use Case`}>
                                    <TableCell padding="normal" align="center" sx={{ minWidth: "100px", maxWidth: "100px", cursor: "pointer" }}>
                                        <TextFieldComponent > Use Case</TextFieldComponent>
                                    </TableCell>
                                </LightTooltip>

                                <LightTooltip title={`${itemData?.createdBy?.name} - ${itemData?.createdBy?.email}`}>
                                    <TableCell padding="normal" align="left" sx={{ minWidth: "150px", maxWidth: "150px", cursor: "pointer" }}>
                                        <Typography fontSize={font.size.fs_5} className="truncate "> {itemData?.createdBy?.name}</Typography>
                                    </TableCell>
                                </LightTooltip>


                                <TableCell padding="normal" align="left" sx={{ minWidth: "170px", maxWidth: "170px", cursor: "pointer" }}>
                                    <TextFieldComponent > {formatDateTime(itemData?.createdAt)}</TextFieldComponent>
                                </TableCell>

                            </>
                        }

                        {pathName === ModulePath.testCase &&
                            <>
                                <TableCell padding="checkbox" align="center" sx={{ minWidth: "40px", maxWidth: "40px", position: "sticky", left: 0, background: theme.palette.background.paper }} onClick={(e) => e && e.stopPropagation()}>
                                    <Checkbox
                                        size="small"
                                        // disabled={currentFolderData?.type !== FolderType.module}
                                        checked={selectedUseCaseList?.includes(itemData)}
                                        onChange={(e) => handleChangeCheckbox(e, itemData)}
                                    />
                                </TableCell>
                                <TableCell padding="normal" align="center" sx={{ minWidth: "70px", maxWidth: "70px", cursor: "pointer", position: "sticky", left: "40px", background: theme.palette.background.paper }} onClick={(e) => e && e.stopPropagation()}>
                                    <LightTooltip title={`Link: ${Entity_Label.userStory} / ${Entity_Label.testCase}`} >
                                        <IconButton size="small" onClick={() => handleOpenLinkTag(itemData)} sx={{}}>
                                            {itemData?.userStories?.length > 0 ? <LinkCustomIcon />
                                                : <UnLinkCustomIcon />}
                                        </IconButton>
                                    </LightTooltip>
                                </TableCell>

                                <LightTooltip title={itemData?.customId}>
                                    <TableCell padding="normal" align="center" sx={{ minWidth: "150px", maxWidth: "150px", cursor: "pointer", position: "sticky", left: "110px", background: theme.palette.background.paper }}>
                                        <TextFieldComponent color={theme.palette.text.primary} className=" font-bold underline cursor-pointer"> {itemData?.customId}</TextFieldComponent>
                                    </TableCell>
                                </LightTooltip>

                                <LightTooltip title={itemData?.title}>
                                    <TableCell padding="normal" align="left" sx={{ minWidth: "200px", maxWidth: "200px", cursor: "pointer", position: "sticky", left: "260px", background: theme.palette.background.paper }}>
                                        <TextFieldComponent > {itemData?.title}</TextFieldComponent>
                                    </TableCell>
                                </LightTooltip>


                                <LightTooltip title={itemData?.steps?.map((item, index) => <span className="truncate" key={index}>- {item?.step}<br /></span>)} >
                                    <TableCell padding="normal" align="left" sx={{ minWidth: "480px", maxWidth: "480px", cursor: "pointer" }}>
                                        <TextFieldComponent className="line-clamp-2 truncate overflow-hidden">{itemData?.steps?.map((item, index) => <span className="" key={index}>- {item?.step}<br /></span>)}</TextFieldComponent>
                                    </TableCell>
                                </LightTooltip>

                                <LightTooltip title={`Test Case`}>
                                    <TableCell padding="normal" align="center" sx={{ minWidth: "100px", maxWidth: "100px", cursor: "pointer" }}>
                                        <TextFieldComponent > Test Case</TextFieldComponent>
                                    </TableCell>
                                </LightTooltip>

                                <LightTooltip title={`${itemData?.createdBy?.name} - ${itemData?.createdBy?.email}`}>
                                    <TableCell padding="normal" align="left" sx={{ minWidth: "150px", maxWidth: "150px", cursor: "pointer" }}>
                                        <Typography fontSize={font.size.fs_5} className="truncate "> {itemData?.createdBy?.name}</Typography>
                                    </TableCell>
                                </LightTooltip>


                                <TableCell padding="normal" align="left" sx={{ minWidth: "170px", maxWidth: "170px", cursor: "pointer" }}>
                                    <TextFieldComponent > {formatDateTime(itemData?.createdAt)}</TextFieldComponent>
                                </TableCell>


                                <LightTooltip title={itemData?.expectedResult}>
                                    <TableCell padding="normal" align="left" sx={{ minWidth: "170px", maxWidth: "170px", cursor: "pointer" }}>
                                        <TextFieldComponent > {itemData?.expectedResult}</TextFieldComponent>
                                    </TableCell>
                                </LightTooltip>

                                <LightTooltip title={itemData?.actualResult}>
                                    <TableCell padding="normal" align="left" sx={{ minWidth: "170px", maxWidth: "170px", cursor: "pointer" }}>
                                        <TextFieldComponent > {itemData?.actualResult}</TextFieldComponent>
                                    </TableCell>
                                </LightTooltip>
                            </>
                        }
                    </TableRow>

                )}
            />

            {/* <AddUserFieldsDrawer
                open={true}
                handleClose={handleCloseUserFieldsDrawer}
                handleContinue={() => { }}
            /> */}


            {/* {openUserStoryDrawer && currentUserStoryData && 
            <ViewUserStoryDrawer open={openUserStoryDrawer} handleClose={handleCloseData} />} */}
        </Box>
    )
}


function TextFieldComponent({ children, ...props }) {
    const theme = useTheme();
    return <Typography fontSize={font.size.fs_5} color={theme.palette.text.secondary} className={`line-clamp-1 ${props.className}`} {...props}> {children}</Typography>
}