import { ArrowDownward, ArrowDropDown, ArrowDropDownOutlined, ArrowDropUp, ArrowDropUpOutlined, ArrowUpward, Attachment, AutoAwesome, AutoAwesomeOutlined, FilterAlt, FilterList, Label, LabelOutlined, Link, LinkOff, SortOutlined } from "@mui/icons-material"
import { Box, Checkbox, CircularProgress, FormControl, IconButton, MenuItem, Paper, Popover, Radio, Select, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material"
import { amber, green, grey, red } from "@mui/material/colors"
import { forwardRef, useEffect, useMemo, useState } from "react"
import { TableVirtuoso } from "react-virtuoso"
import ViewUserStoryDrawer from "../drawer/ViewUserStory"
import { useApplicationContext } from "../../context/ApplicationContext"
import { font } from "../../styles/font"
import { LightTooltip } from "../styled/CustomComponent"
import { Entity_Label, FolderType, StoryStatus } from "../../constant/ApplicationVariables"
import dayjs from "dayjs"
import StatusBadge from "../common/StatusBadge"
import { convertEditorDataToReact, parseReactFromHtmlString } from "../../utils/StringOperations";
import { BiLink } from "react-icons/bi";
import { PiLink, PiLinkBreak } from "react-icons/pi";
import { FcBrokenLink } from "react-icons/fc";
import { FcLink } from "react-icons/fc";
import { LuLink, LuUnlink } from "react-icons/lu";
import { LiaSortSolid } from "react-icons/lia";
import { LiaSortDownSolid } from "react-icons/lia";
import { LinkCustomIcon, UnLinkCustomIcon } from "../styled/CustomIcons"
import { formatDateTime } from "../../utils/DateTime"








const TableComponents = {
    Scroller: forwardRef((props, ref) => <TableContainer component={Paper} style={{ overflowX: "auto", overflowY: "scroll" }} {...props} ref={ref} />),
    Table: (props) => <Table {...props} style={{ borderCollapse: 'separate' }} />,
    TableHead: TableHead,
    TableRow: (props) => <TableRow {...props} />,
    TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
    TableCell: (props) => <TableCell {...props} sx={{ ...props?.sx, px: "1rem !important" }} />,
}

export default function UserStoryList({ dataList, }) {
    const {
        currentUserStoryData, setCurrentUserStoryData,
        openEditStoryDialog, setOpenEditStoryDialog,
        userStoryList, setUserStoryList,
        selectedUserStoryList, setSelectedUserStoryList,

        currentFolderData,
        isUserStoryLoading, setIsUserStoryLoading,
        openLinkTagDrawer, setOpenLinkTagDrawer,
        toggleChatWindow, setToggleChatWindow,
        isItemChatWindow, setIsItemChatWindow
    } = useApplicationContext();
    const theme = useTheme();

    const [openUserStoryDrawer, setOpenUserStoryDrawer] = useState(false);
    const [currentColumnAnchorEl, setCurrentColumnAnchorEl] = useState(null);

    const [currentSortingColumn, setCurrentSortingColumn] = useState(null);
    const [currentColumnData, setCurrentColumnData] = useState(null);
    const [sortedList, setSortedList] = useState(dataList);
    // const [filteredList, setFilteredList] = useState(sortedList);
    // const [currentFilters, setCurrentFilters] = useState({});



    // const [current]

    // to open the user story preview
    const handleOpenUserStory = (item) => {
        setCurrentUserStoryData(item);
        setOpenEditStoryDialog(true);
    }

    // to close the user story preview
    const handleCloseUserStory = () => {
        setOpenEditStoryDialog(false);
        setCurrentUserStoryData(null);
    }

    // to handle the table header checkbox
    const handleChangeCheckboxAll = (e) => {
        if (e.target.checked) {
            setSelectedUserStoryList(sortedList);
        } else {
            setSelectedUserStoryList([]);
        }
    }

    // to handle the list checkbox
    const handleChangeCheckbox = (e, item) => {
        if (e.target.checked) {
            setSelectedUserStoryList([...selectedUserStoryList, item]);
        } else {
            setSelectedUserStoryList(selectedUserStoryList?.filter((story) => story?.customId !== item?.customId));
        }
    }

    // to open the link tag drawer
    const handleOpenLinkTag = (data) => {
        setCurrentUserStoryData(data)
        setOpenLinkTagDrawer(true)
    }

    // To toggle the Ai window for particular list item
    const handleToggleAIWindow = (e, data) => {
        e && e.stopPropagation()

        if (isItemChatWindow && currentUserStoryData?.customId === data?.customId) {
            setIsItemChatWindow(false)
            setCurrentUserStoryData(null);
        } else {
            setCurrentUserStoryData(data);
            setIsItemChatWindow(true);
            setToggleChatWindow(true);
        }
    }

    const handleOpenColumnPopover = (e, columnData) => {
        e && e.stopPropagation();

        setCurrentColumnData(columnData)

        setCurrentColumnAnchorEl(e.currentTarget);
    }

    const handleCloseColumnPopover = () => {
        setCurrentColumnAnchorEl(null)
    }

    const handleClickColumn = (columnData) => {

        console.log(currentSortingColumn, columnData, "currentSortingColumn");

        let list = [...sortedList];
        if (columnData?.order) {
            if (currentSortingColumn) {

                if (currentSortingColumn?.key === columnData?.key) {
                    setCurrentSortingColumn({ ...columnData, order: currentSortingColumn?.order === "asc" ? "desc" : "asc" });

                    list.sort((a, b) => {
                        if (currentSortingColumn.order === "desc") {
                            return b[currentSortingColumn.key]?.localeCompare(a[currentSortingColumn.key]);
                        } else {
                            return a[currentSortingColumn.key]?.localeCompare(b[currentSortingColumn.key]);
                        }
                    });
                } else {
                    setCurrentSortingColumn({ ...columnData, order: "asc" });
                    list.sort((a, b) => {
                        return a[currentSortingColumn.key]?.localeCompare(b[currentSortingColumn.key]);
                    });
                }

            } else {
                setCurrentSortingColumn({ ...columnData, order: "asc" });
                list.sort((a, b) => {
                    return a[columnData.key].localeCompare(b[columnData.key]);
                });
            }
        }
        console.log(list, "sorted List")


        setSortedList(list);
    };


    console.log(sortedList, "sortedList")




    // {
    //     "customId": "Pro_Ord_0",
    //     "title": "Order Processing",
    //     "description": "As a customer, I want to be able to view my order history and see the current status of my orders, so that I can stay on top of my purchases.",
    //     "priority": null,
    //     "status": "Pending",
    //     "createdByEmail": "testing@floxpad.com",
    //     "assignedToEmail": null,
    //     "createdAt": "2025-01-09T13:11:19Z",
    //     "lastModifiedAt": "2025-01-09T13:11:19Z",
    //     "moduleName": "Order Processing",
    //     "acceptanceCriteria": [
    //         "User can view order history.",
    //         "System displays updated order status in real-time."
    //     ],
    //     "useCases": []
    // }

    const columns = [
        // {
        //     data: "AI",
        //     padding: "checkbox",
        //     align: "center",
        //     background: theme.palette.header.main,
        //     width: "40px"
        // },
        {
            data: <Checkbox size="small" disabled={isUserStoryLoading || !currentFolderData} checked={userStoryList?.length > 0 && selectedUserStoryList?.length === userStoryList?.length} indeterminate={selectedUserStoryList?.length > 0 && selectedUserStoryList?.length < userStoryList?.length} onChange={handleChangeCheckboxAll} />,
            key: "",
            padding: "checkbox",
            align: "center",
            background: theme.palette.header.main,
            width: "40px",
            order: null,
            style: {
                position: "sticky", left: 0, background: theme.palette.background.paper
            }
        },
        {
            data: "Link",
            key: "",
            padding: "normal",
            align: "center",
            background: theme.palette.header.main,
            width: "70px",
            order: null,
            isFilterable: false,
            style: {
                position: "sticky", left: "40px", background: theme.palette.background.paper
            }
        },
        {
            data: "ID",
            key: "customId",
            padding: "normal",
            align: "center",
            background: theme.palette.header.main,
            width: "150px",
            order: "asc",
            style: {
                position: "sticky", left: "110px", background: theme.palette.background.paper
            }
        },
        {
            data: "Title",
            key: "title",
            padding: "normal",
            align: "left",
            background: theme.palette.header.main,
            width: "200px",
            order: "asc",
            style: {
                position: "sticky", left: "260px", background: theme.palette.background.paper
            }
        },
        {
            data: "Description",
            key: "description",
            padding: "normal",
            align: "left",
            background: theme.palette.header.main,
            width: "260px",
            order: null,
            style: {}
        },
        {
            data: "Acceptance Criteria",
            key: " acceptanceCriteria",
            padding: "normal",
            align: "left",
            background: theme.palette.header.main,
            width: "260px",
            order: null,
            style: {}
        },
        {
            data: "Define Type",
            key: "",
            padding: "normal",
            align: "center",
            background: theme.palette.header.main,
            width: "100px",
            order: null,
            style: {}
        },

        {
            data: "State",
            key: "state",
            padding: "normal",
            align: "center",
            background: theme.palette.header.main,
            width: "120px",
            order: "asc",
            isFilterable: true,
            style: {}
        },
        // {
        //     data: "Category",
        //     padding: "normal",
        //     align: "left",
        //     background: theme.palette.header.main,
        //     width: "100px"
        // },
        {
            data: "Moscow",
            key: "moscow",
            padding: "normal",
            align: "left",
            background: theme.palette.header.main,
            width: "150px",
            order: "asc",
            isFilterable: true,
            style: {}
        },
        {
            data: "Assigend To",
            key: "assignedToEmail",
            padding: "normal",
            align: "left",
            background: theme.palette.header.main,
            width: "150px",
            order: "asc",
            isFilterable: true,
            style: {}
        },
        // {
        //     data: "Sub Product",
        //     padding: "normal",
        //     align: "left",
        //     background: theme.palette.header.main,
        //     width: "100px"
        // },
        // {
        //     data: "ReferenceId",
        //     padding: "normal",
        //     align: "left",
        //     background: theme.palette.header.main,
        //     width: "100px"
        // },
        {
            data: "Type",
            key: "type",
            padding: "normal",
            align: "center",
            background: theme.palette.header.main,
            width: "100px",
            order: "asc",
            isFilterable: true,
            style: {}
        },
        // {
        //     data: "Coverage Status",
        //     padding: "normal",
        //     align: "left",
        //     background: theme.palette.header.main,
        //     width: "120px"
        // },
        // {
        //     data: "Type",
        //     padding: "normal",
        //     align: "center",
        //     background: theme.palette.header.main,
        //     width: "80px"
        // },
        {
            data: "Priority",
            key: "priority",
            padding: "normal",
            align: "center",
            background: theme.palette.header.main,
            width: "100px",
            order: "asc",
            isFilterable: true,
            style: {}
        },
        {
            data: "Confidentility",
            key: "confidentility",
            padding: "normal",
            align: "center",
            background: theme.palette.header.main,
            width: "150px",
            order: "asc",
            isFilterable: true,
            style: {}
        },
        {
            data: "Created By",
            key: "createdByEmail",
            padding: "normal",
            align: "left",
            background: theme.palette.header.main,
            width: "150px",
            order: "asc",
            isFilterable: true,
            style: {}
        },
        {
            data: "Creation Date",
            key: "createdAt",
            padding: "normal",
            align: "left",
            background: theme.palette.header.main,
            width: "180px",
            order: "asc",
            isFilterable: true,
            style: {}
        },
        {
            data: "Label",
            key: "",
            padding: "normal",
            align: "center",
            background: theme.palette.header.main,
            width: "70px",
            order: null,
            isFilterable: true,
            style: {
                position: "sticky", left: "110px", background: theme.palette.background.paper
            }
        },

    ];

    useEffect(() => {
        setSortedList(dataList)
    }, [dataList]);

    return (
        <Box className="grow w-full h-full flex flex-col overflow-hidden" borderRadius={theme.shape.borderRadius + 10}>
            <TableVirtuoso
                style={{ height: "100%", width: "100%", overflowX: 'auto', borderRadius: theme.shape.borderRadius }}
                data={sortedList}
                components={TableComponents}
                fixedHeaderContent={() => (
                    <TableHead>
                        {
                            columns?.map((item, index) => (
                                <TableCell key={index} align={item?.align} padding={item?.padding} className="whitespace-nowrap" style={{ margin: "0 1rem !important", fontWeight: "bold", minWidth: item?.width, maxWidth: item?.width, background: item?.background, }} sx={item?.style}>
                                    <Box className={`h-full flex gap-0  select-none  ${item?.align === "left" ? "justify-start" : item?.align === "right" ? "justify-end" : "justify-center"}  ${item?.order && "cursor-pointer "} `} >


                                        <Box className="flex items-center  w-fit " onClick={() => handleClickColumn(item)}>

                                            {item?.order && (currentSortingColumn?.key === item?.key ?
                                                (currentSortingColumn?.order === "desc" ? <LiaSortDownSolid style={{ fontSize: font.size.fs_4 }} /> : <LiaSortDownSolid style={{ fontSize: font.size.fs_4, transform: "rotate(180deg)" }} />)
                                                : <LiaSortSolid />)
                                            }
                                            <Typography fontSize={font.size.fs_5} color={theme.palette.text.primary} className=" ">{item?.data}</Typography>

                                        </Box>

                                        {false && item?.isFilterable && <Box className="">
                                            {/* {item?.isFilterable && <Box className="flex items-center justify-between ">
                                                <IconButton onClick={(e) => handleOpenColumnPopover(e, item)}>
                                                    <FilterAlt sx={{ fontSize: font.size.fs_5 }} />
                                                </IconButton>
                                            </Box>} */}



                                            <FormControl fullWidth sx={{}} size="small">
                                                <Select
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small"
                                                    // value={(currentFilters && currentFilters[item?.key]) || ""}
                                                    // onChange={(e) => handleChangeColumnFilter(e, item)}
                                                    MenuProps={{
                                                        MenuListProps: {
                                                            style: {
                                                                maxHeight: "200px"
                                                            }
                                                        }
                                                    }}
                                                    sx={{ height: "20px" }}
                                                >
                                                    <MenuItem value="">
                                                        <em>None</em>
                                                    </MenuItem>

                                                    {item?.key === "status" &&
                                                        [StoryStatus.pending, StoryStatus.inprogress, StoryStatus.completed]?.map((item, index) => (
                                                            <MenuItem key={index} value={item}>{item}</MenuItem>
                                                        ))
                                                    }

                                                    {item?.key === "createdAt" &&
                                                        [...new Set(sortedList?.map(item => item?.createdAt))]?.map((item, index) => (
                                                            <MenuItem key={index} value={item}>{dayjs(item).format("DD MMM YYYY HH:mm A")}</MenuItem>
                                                        ))
                                                    }

                                                </Select>
                                            </FormControl>
                                        </Box>}



                                    </Box>
                                </TableCell>
                            ))
                        }
                    </TableHead>
                )}


                itemContent={(index, storyData) => (
                    <TableRow onClick={() => handleOpenUserStory(storyData)} sx={{ background: storyData?.customId === currentUserStoryData?.customId && grey[200], "&:hover": { background: grey[100], } }}>



                        {/* <TableCell padding="checkbox" align="center" sx={{ minWidth: "40px", maxWidth: "40px", position: "sticky", left: 0, }} onClick={(e) => handleToggleAIWindow(e, storyData)}>
                            <IconButton sx={{ background: theme.palette.common.white }}>
                                {isItemChatWindow && storyData?.customId === currentUserStoryData?.customId ? <AutoAwesome color="primary" /> : <AutoAwesomeOutlined color="primary" />}
                            </IconButton>
                        </TableCell> */}

                        <TableCell padding="checkbox" align="center" sx={{ minWidth: "40px", maxWidth: "40px", position: "sticky", left: 0, background: theme.palette.background.paper }} onClick={(e) => e && e.stopPropagation()}>
                            <Checkbox size="small" disabled={!currentFolderData} checked={selectedUserStoryList?.includes(storyData)} onChange={(e) => handleChangeCheckbox(e, storyData)} />
                        </TableCell>

                        <TableCell padding="normal" align="center" sx={{ minWidth: "70px", maxWidth: "70px", cursor: "pointer", position: "sticky", left: "40px", background: theme.palette.background.paper }} onClick={(e) => e && e.stopPropagation()}>
                            <LightTooltip title={`Link: ${Entity_Label.useCase} / ${Entity_Label.testCase}`} >
                                <IconButton size="small" onClick={() => handleOpenLinkTag(storyData)} sx={{}}>
                                    {storyData?.useCases?.length > 0 ? <LinkCustomIcon />
                                        : <UnLinkCustomIcon />}
                                </IconButton>
                            </LightTooltip>
                        </TableCell>


                        <LightTooltip title={storyData?.customId}>
                            <TableCell padding="normal" align="center" sx={{ minWidth: "150px", maxWidth: "150px", cursor: "pointer", position: "sticky", left: "110px", background: theme.palette.background.paper }}>
                                <TextFieldComponent color={theme.palette.text.primary} className=" font-bold underline cursor-pointer ">{storyData?.customId}</TextFieldComponent>
                            </TableCell>
                        </LightTooltip>
                        <LightTooltip title={storyData?.title}>
                            <TableCell padding="normal" align="left" sx={{ minWidth: "200px", maxWidth: "200px", cursor: "pointer" , position: "sticky", left: "260px", background: theme.palette.background.paper}}>
                                <TextFieldComponent className="line-clamp-2"> {storyData?.title}</TextFieldComponent>
                            </TableCell>
                        </LightTooltip>
                        <LightTooltip title={<Typography fontSize={font.size.fs_5} className="w-full max-h-44 overflow-y-auto" > {convertEditorDataToReact(storyData?.description)}</Typography>}>
                            <TableCell padding="normal" align="left" sx={{ minWidth: "260px", maxWidth: "260px", cursor: "pointer" }}>
                                <Typography className="line-clamp-2" fontSize={font.size.fs_5} color={theme.palette.text.secondary} dangerouslySetInnerHTML={{ __html: storyData?.description }} />
                            </TableCell>
                        </LightTooltip>
                        <LightTooltip title={storyData?.acceptanceCriteria?.map((item, index) => <span className="truncate" key={index}>- {item}<br/></span>)} >
                            <TableCell padding="normal" align="left" sx={{ minWidth: "260px", maxWidth: "260px", cursor: "pointer" }}>
                                <TextFieldComponent className="line-clamp-2 truncate overflow-hidden"> {storyData?.acceptanceCriteria?.map((item, index) => <span className="truncate" key={index}>- {item}<br/></span>)}</TextFieldComponent>
                            </TableCell>
                        </LightTooltip>


                        <LightTooltip title={`User Story`}>
                            <TableCell padding="normal" align="center" sx={{ minWidth: "100px", maxWidth: "100px", cursor: "pointer" }}>
                                <TextFieldComponent>User Story</TextFieldComponent>
                            </TableCell>
                        </LightTooltip>



                        <LightTooltip title={storyData?.state}>
                            <TableCell padding="normal" align="center" sx={{ minWidth: "120px", maxWidth: "120px", cursor: "pointer" }}>
                                <Typography className="flex items-center justify-center" >
                                    <StatusBadge status={storyData?.state?.toLowerCase()} />
                                </Typography>
                            </TableCell>
                        </LightTooltip>
                        {/* <LightTooltip title={storyData?.category}>
                            <TableCell padding="normal" align="left" sx={{ minWidth: "100px", maxWidth: "100px", cursor: "pointer" }}>
                                <Typography fontSize={font.size.fs_5} className="line-clamp-1"> {storyData?.category}</Typography>
                            </TableCell>
                        </LightTooltip> */}
                        <LightTooltip title={storyData?.moscow}>
                            <TableCell padding="normal" align="left" sx={{ minWidth: "150px", maxWidth: "150px", cursor: "pointer" }}>
                                <TextFieldComponent> {storyData?.moscow}</TextFieldComponent>
                            </TableCell>
                        </LightTooltip>
                        <LightTooltip title={storyData?.assignedTo}>
                            <TableCell padding="normal" align="left" sx={{ minWidth: "150px", maxWidth: "150px", cursor: "pointer" }}>
                                <TextFieldComponent> {storyData?.assignedTo}</TextFieldComponent>
                            </TableCell>
                        </LightTooltip>
                        {/* <LightTooltip title={storyData?.subProduct}>
                            <TableCell padding="normal" align="left" sx={{ minWidth: "100px", maxWidth: "100px", cursor: "pointer" }} >
                                <Typography fontSize={font.size.fs_5} className="line-clamp-1"> {storyData?.subProduct}</Typography>
                            </TableCell>
                        </LightTooltip> */}
                        {/* <LightTooltip title={storyData?.referenceId}>
                            <TableCell padding="normal" align="left" sx={{ minWidth: "100px", maxWidth: "100px", cursor: "pointer" }}>
                                <Typography fontSize={font.size.fs_5} className="line-clamp-1"> {storyData?.referenceId}</Typography>
                            </TableCell>
                        </LightTooltip> */}
                        {/* <LightTooltip title={storyData?.type}> */}
                        <TableCell padding="normal" align="center" sx={{ minWidth: "100px", maxWidth: "100px", cursor: "pointer" }} >
                            <TextFieldComponent> {storyData?.type}</TextFieldComponent>
                        </TableCell>
                        {/* </LightTooltip> */}

                        {/* <LightTooltip title={storyData?.priority}> */}
                        <TableCell padding="normal" align="center" sx={{ minWidth: "100px", maxWidth: "100px", cursor: "pointer", }}>
                            <TextFieldComponent> {storyData?.priority}</TextFieldComponent>
                        </TableCell>
                        {/* </LightTooltip> */}
                        {/* <LightTooltip title={storyData?.confidentiality}> */}
                        <TableCell padding="normal" align="center" sx={{ minWidth: "150px", maxWidth: "150px", cursor: "pointer" }}>
                            <TextFieldComponent> {storyData?.confidentiality}</TextFieldComponent>
                        </TableCell>
                        {/* </LightTooltip> */}
                        {/* <LightTooltip title={storyData?.coverageStatus}>
                            <TableCell padding="normal" align="left" sx={{ minWidth: "120px", maxWidth: "120px", cursor: "pointer" }}>
                                <Typography fontSize={font.size.fs_5} className="line-clamp-1"> {storyData?.coverageStatus}</Typography>
                            </TableCell>
                        </LightTooltip> */}
                        {/* <LightTooltip title={storyData?.type}>
                            <TableCell padding="normal" align="center" sx={{ minWidth: "80px", maxWidth: "80px", cursor: "pointer" }}>
                                <Typography fontSize={font.size.fs_5} className="line-clamp-1"> {storyData?.type}</Typography>
                            </TableCell>
                        </LightTooltip> */}
                        <LightTooltip title={`${storyData?.createdBy?.name} - ${storyData?.createdBy?.email}`}>
                            <TableCell padding="normal" align="left" sx={{ minWidth: "150px", maxWidth: "150px", cursor: "pointer" }}>
                                <Typography fontSize={font.size.fs_5} className="truncate "> {storyData?.createdBy?.name}</Typography>
                            </TableCell>
                        </LightTooltip>
                        {/* <LightTooltip title={dayjs(storyData?.createdAt).format("DD MMM YYYY HH:mm A")}> */}
                        <TableCell padding="normal" align="left" sx={{ minWidth: "180px", maxWidth: "180px", cursor: "pointer" }}>
                            <TextFieldComponent> { formatDateTime(storyData?.createdAt)}</TextFieldComponent>
                        </TableCell>
                        {/* </LightTooltip> */}

                        <TableCell padding="normal" align="center" sx={{ minWidth: "70px", maxWidth: "70px", cursor: "pointer",  }} onClick={(e) => e && e.stopPropagation()}>
                            <LightTooltip title={`Label`} >
                                <LabelOutlined />
                            </LightTooltip>
                        </TableCell>


                    </TableRow>
                )}
            />




            {openUserStoryDrawer && currentUserStoryData && <ViewUserStoryDrawer open={openUserStoryDrawer} handleClose={handleCloseUserStory} />}


            <Popover
                open={Boolean(currentColumnAnchorEl)}
                anchorEl={currentColumnAnchorEl}
                onClose={handleCloseColumnPopover}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                {currentColumnData?.key === "status" && (
                    <Box>
                        {
                            ["Pending", "In Progress", "Completed"].map((item, index) => {
                                return <MenuItem key={index} onClick={() => { }}> <Radio size="small" value={item} /> {item} </MenuItem>
                            })
                        }
                    </Box>
                )}
            </Popover>
        </Box>
    )
}


function TextFieldComponent({ children, ...props }) {
    const theme = useTheme();
    return <Typography fontSize={font.size.fs_5} color={theme.palette.text.secondary} className={`line-clamp-1 ${props.className}`} {...props}> {children}</Typography>
}