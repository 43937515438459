import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import { HttpStatusCode } from 'axios';
import { login_API } from '../../../api/controller/authController';
import { useApplicationContext } from '../../../context/ApplicationContext';
import useErrorHandler from '../../../api/errorHandler/useErrorHandler';
import { useNavigate } from 'react-router-dom';
import { ModulePath, Severity, VerificationStatus, VerificationType } from '../../../constant/ApplicationVariables';
import validator from "validator";
import { useState } from 'react';
import { useEffect } from 'react';
import { CircularProgress, IconButton, InputAdornment, useTheme } from '@mui/material';
import { AutoAwesome, Visibility, VisibilityOff, WavingHand } from '@mui/icons-material';
import { font } from '../../../styles/font';
import ApplicationLogo from '../../layout/ApplicationLogo';
import { amber } from '@mui/material/colors';
import LeftSection from '../container/leftSection';
import MainContainer, { AuthCardComponent } from '../container';

const Card = styled(MuiCard)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    width: '100%',
    // py: theme.spacing(4),
    // gap: theme.spacing(6),
    margin: 'auto',
    borderRadius: theme.shape.borderRadius,
    // background: theme.palette.background.paper,
    background: "transparent",
    boxShadow: "none",
    [theme.breakpoints.up('sm')]: {
        // maxWidth: '450px',
    },
    // boxShadow:
    //     'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
    // ...theme.applyStyles('dark', {
    //     boxShadow:
    //         'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
    // }),
}));


export default function LoginContainer() {
    const { globalSnackbar, setGLobalSnackBar, handleSnackbar, handleStoreUserData, profileDetails, setProfileDetails, accountVerificationType, setAccountVerificationType } = useApplicationContext();
    const errorHandler = useErrorHandler();
    const navigate = useNavigate();
    const theme = useTheme();

    const [loginData, setLoginData] = useState(null);
    const [error, setError] = useState(null);

    const [open, setOpen] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent default form submission behavior
        validateInputs(); // Call your validation function
    };

    const handleClickOpen = () => {
        setOpen(true);
        navigate(ModulePath.forgotPassword)
    };


    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    }


    const handleChangeValue = (event) => {
        const { name, value } = event.target;

        setLoginData((prevData) => ({
            ...prevData,
            [name]: value
        }))
    }

    const validateInputs = () => {
        let isValid = true;
        const errorObject = {};

        if (!loginData?.email || !validator.isEmail(loginData?.email)) {
            errorObject.email = "Please enter a valid email address.";
            isValid = false;
        }

        if (!loginData?.password || loginData?.password?.length < 6) {
            errorObject.password = "Password must be at least 6 characters long.";
            isValid = false;
        }

        setError(errorObject);

        return isValid && handleContinue();
    };

    const handleContinue = async () => {
        try {
            setLoading(true);
            // let body = {
            //     email: "testing@floxpad.com",
            //     password: "p@ssword"
            // }

            let body = {
                email: loginData.email,
                password: loginData.password
            }

            const response = await login_API(body);

            if (response) {
                errorHandler(response);
            }

            if (response.status === HttpStatusCode.Ok) {
                // localStorage.setItem("access-token", response.data.authToken);
                // localStorage.setItem("profileDetails", JSON.stringify(response.data));
                // setProfileDetails(response.data);

                handleStoreUserData(response.data);

                if (response.data.status === VerificationStatus.Verified) {
                    // handleStoreUserData(response.data);
                    handleSnackbar("User signed in successfully", Severity.success);
                    setTimeout(() => {
                        navigate(ModulePath.projects);
                        // navigate(0);
                    }, 1100);
                }


                if (response.data.status === VerificationStatus.Unverified) {
                    // setAccountVerificationType(VerificationType.Account);
                    // handleStoreUserData(response.data);
                    handleSnackbar("Please verify your account", Severity.info);
                    setTimeout(() => {
                        navigate(ModulePath.verifyAccount, { state: { email: loginData.email, type: VerificationType.Account, fromPath: ModulePath.login }, });
                    }, 1100);
                }

            }
        } catch (error) {
            console.log(error, error?.message, "login errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }


    return (
        <Box className=" flex flex-col gap-0 relative h-full w-full box-border overflow-hidden" >
            <MainContainer >
                <AuthCardComponent className="flex-1 gap-10 ">

                    {/* header container */}
                    <Box className="flex flex-col   ">

                        <Typography
                            sx={{ width: '100%', }}
                            color="textPrimary"
                            fontSize={font.size.fs_1_5}
                            fontWeight="bold"
                        >
                            Welcome Back!
                        </Typography>

                        <Box className="flex items-center gap-2">
                            <Typography color={theme.palette.text.ternary} fontSize={font.size.fs_5} className="flex items-center justify-center gap-1 ">
                                Don&apos;t have an account?{' '}

                                <Typography color={theme.palette.text.primary} fontSize={"inherit"} fontWeight="bold" className="underline cursor-pointer " onClick={() => navigate(ModulePath.register)}>
                                    Create a new account now
                                </Typography>

                            </Typography>
                        </Box>
                    </Box>

                    {/* form container */}
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        noValidate
                        className="flex flex-col w-full gap-10     "

                    >

                        {/* input container */}
                        <Box
                            className="flex flex-col w-full gap-6 py-0  "

                        >
                            <FormControl>
                                {/* <FormLabel htmlFor="email">Email</FormLabel> */}
                                <TextField
                                    // size="medium"
                                    label="Email"
                                    error={error?.email && error?.email}
                                    helperText={error?.email && error?.email}
                                    id="email"
                                    type="email"
                                    name="email"
                                    placeholder="your@email.com"
                                    autoComplete="email"
                                    autoFocus
                                    required
                                    fullWidth
                                    variant="filled"
                                    color={error?.email ? 'error' : 'primary'}
                                    value={loginData?.email}
                                    onChange={handleChangeValue}
                                />
                            </FormControl>
                            <FormControl>

                                {/* <FormLabel htmlFor="password">Password</FormLabel> */}
                                <TextField
                                    // size="medium"
                                    label="Password"
                                    error={error?.password && error?.password}
                                    helperText={error?.password && error?.password}
                                    name="password"
                                    placeholder="••••••"
                                    type={showPassword ? "text" : "password"}
                                    id="password"
                                    autoComplete="current-password"
                                    autoFocus
                                    required
                                    fullWidth
                                    variant="filled"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    color={error?.password ? 'error' : 'primary'}
                                    value={loginData?.password}
                                    onChange={handleChangeValue}
                                />
                            </FormControl>


                            {/* <FormControlLabel
                                    control={<Checkbox value="remember" color="primary" />}
                                    label="Remember me"
                            /> */}

                        </Box>

                        <Box className="flex flex-col gap-10">
                            <Button
                                type="submit"
                                size="large"
                                fullWidth
                                color="secondary"
                                variant="contained"
                                disabled={loading}
                                onClick={validateInputs}
                                sx={{ color: '#fff' }}
                                className=""
                            >
                                {loading ? <CircularProgress size={20} /> : "Login Now"}
                            </Button>

                            {false && <Link
                                component="button"
                                type="button"
                                onClick={handleClickOpen}
                                variant="body2"
                                sx={{ alignSelf: 'end' }}
                                className="-top-2"
                            >
                                Forgot your password?
                            </Link>}

                            <Box className="flex items-center justify-center gap-2 ">
                                <Typography color={theme.palette.text.ternary} fontSize={font.size.fs_5} className="flex items-center justify-center gap-1 ">
                                    Forgot Password?{' '}

                                    <Typography color={theme.palette.text.primary} fontSize={"inherit"} fontWeight="bold" className="underline cursor-pointer " onClick={() => navigate(ModulePath.forgotPassword)}>
                                        Click here
                                    </Typography>
                                </Typography>
                            </Box>

                        </Box>

                    </Box>

                    {/* <Divider>or</Divider> */}


                    {false && <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        {/* <Button
    fullWidth
    variant="outlined"
    onClick={() => alert('Sign in with Google')}
>
    Sign in with Google
</Button>
<Button
    fullWidth
    variant="outlined"
    onClick={() => alert('Sign in with Facebook')}
>
    Sign in with Facebook
</Button> */}
                        <Typography sx={{ textAlign: 'center' }}>
                            Don&apos;t have an account?{' '}
                            <Link
                                href={ModulePath.register}
                                variant="body2"
                                sx={{ alignSelf: 'center' }}
                            >
                                Register
                            </Link>
                        </Typography>
                    </Box>}
                </AuthCardComponent>
            </MainContainer>
        </Box>
    );
}