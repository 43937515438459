import axios from "axios";
import { MAIN_URL, REQUEST_URL } from "../../constant/ApplicationVariables";
import { axiosInstance } from "../useAxios/useAxios";


export const getUseCaseList_API = async (body) => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.useCaseList,
            method: "POST",
            data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}

export const getUseCaseListAll_API = async (query) => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.useCaseListAll + `?${query}`,
            method: "GET",
            // data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}

export const getUseCaseProirityList_API = async () => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.userStoryPriority,
            method: "GET",
            // data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}


export const addUseCase_API = async (body) => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.useCase,
            method: "POST",
            data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}

export const updateUseCase_API = async (body) => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.useCase,
            method: "PUT",
            data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}

export const deleteUseCase_API = async (body) => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.useCase ,
            method: "DELETE",
            data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}


export const getUseCaseItemsForLink_API = async (query) => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.useCaseLink + `?${query}`,
            method: "GET",
            // data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}

export const generateUseCaseCustomId_API = async (body) => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.useCaseCustomId,
            method: "POST",
            data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}


export const getUseCasePropertiesList_API = async () => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.useCasePropertiesList,
            method: "GET",
            // data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}

export const getUseCasePriorityList_API = async () => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.useCasePriority,
            method: "GET",
            // data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}

export const getUseCaseTypeList_API = async () => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.useCaseType,
            method: "GET",
            // data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}

export const getUseCaseStateList_API = async () => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.useCaseState,
            method: "GET",
            // data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}

export const getUseCaseStatusList_API = async () => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.useCaseStatus,
            method: "GET",
            // data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}

export const addCommentUseCase_API = async (body) => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.useCaseComment,
            method: "POST",
            data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}

export const updateCommentUseCase_API = async (body) => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.useCaseComment,
            method: "PUT",
            data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}