import { AccountCircle, AccountCircleOutlined, AddCircleOutline, ArrowRightAlt, AttachFileOutlined, AutoAwesome, Check, Close, CopyAll, DescriptionOutlined, FolderOpenOutlined, FolderOutlined, FolderSpecialTwoTone, FolderTwoTone, Http, KeyboardArrowDown, KeyboardArrowRight, LoopOutlined, Refresh, RefreshOutlined, ReplayOutlined, RestartAltOutlined, Save, SaveAlt, SendOutlined } from "@mui/icons-material"
import { Avatar, Box, Button, Checkbox, Chip, CircularProgress, Dialog, FormControlLabel, Icon, IconButton, InputAdornment, LinearProgress, Menu, Popover, Skeleton, Tab, Tabs, TextField, ToggleButton, ToggleButtonGroup, Typography, useTheme } from "@mui/material"
import { ApplicationContext, useApplicationContext } from "../../context/ApplicationContext";
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import { blue, grey, purple } from "@mui/material/colors";
import { font } from "../../styles/font";
import { CustomLoadingButton, LightTooltip } from "../styled/CustomComponent";
import { useEffect, useRef, useState } from "react";
import { AI_Entity, AI_Response_SentBy, Entity, Entity_Label, FolderType, ModulePath, Severity } from "../../constant/ApplicationVariables";
import { generateResponse_API, getConversationHistoryList_API, saveGeneratedResponse_API } from "../../api/controller/AIChatController";
import useErrorHandler from "../../api/errorHandler/useErrorHandler";
import { HttpStatusCode } from "axios";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { getFolderStructureList_API } from "../../api/controller/RequirementController";
import { getStoryList_API } from "../../api/controller/StoryController";
import dayjs from "dayjs";
import AutoAwesomeTwoToneIcon from '@mui/icons-material/AutoAwesomeTwoTone';
import { getUseCaseList_API } from "../../api/controller/UseCaseController";
import AutoSizer from "react-virtualized-auto-sizer";
import { Tree } from "react-arborist";
import { addModule_API } from "../../api/controller/ModuleController";
import { LuReplace } from "react-icons/lu";



export default function ChatWindow() {
    const {
        toggleChatWindow, setToggleChatWindow,
        activeProject, setActiveProject,
        currentFolderData, setCurrentFolderData,
        handleSnackbar,
        isItemChatWindow, setIsItemChatWindow,
        currentAIEntityData, setCurrentAIEntityData,

        currentUserStoryData, setCurrentUserStoryData,
        currentUseCaseData, setCurrentUseCaseData,
        getAILabel,
        currentDataSavingFolder, setCurrentDataSavingFolder,
        currentAIResponseSaveData, setCurrentAIResponseSaveData,
        setIsUpdateFolderStructure,
        setUserStoryList,
        setUseCaseList,
        currentAIContextData, setCurrentAIContextData,
    } = useApplicationContext();
    const theme = useTheme();
    const errorHandler = useErrorHandler();
    const pathName = useLocation()?.pathname;
    const navigate = useNavigate();

    let string = `As a user, I want to sign up with required information such as name, email, password, and phone number so that the system creates a new user profile.`

    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isDataSaveLoading, setIsDataSaveLoading] = useState(false) // when response is saving
    const [isContentGenerationLoading, setIsContentGenerationLoading] = useState(false); // To show the initial content loading
    const [isDuplicateSaveAllowed, setIsDuplicateSaveAllowed] = useState(false); // to ask user wheather to save duplicate data, by taking confirmation from user.
    const [isDuplicateSaveResponse, setIsDuplicateSaveResponse] = useState(false); // enabled duplicated save checkbox, when duplicate response found.


    const [userInput, setUserInput] = useState(""); // to save the user prompt text
    const [responseList, setResponseList] = useState([]); // to save the list of conversation list
    const [foldersList, setFoldersList] = useState([]);


    const [responseSaveAnchorEl, setResponseSaveAnchorEl] = useState(null);
    const conversationResponseContainerRef = useRef(null);

    console.log(currentAIEntityData, "currentAIEntityDataa")


    const handleGetContext = () => {
        let type = "";
        let name = "";


        if (currentAIEntityData) {
            if (isItemChatWindow) {
                type = pathName === ModulePath.requirements ? Entity_Label.userStory : Entity_Label.useCase;
                name = pathName === ModulePath.requirements ? currentUserStoryData?.title : currentUseCaseData?.title;

            } else {
                type = FolderType.folder;
                name = currentFolderData?.name;
            }
        }
        // `Module: ${currentFolderData?.name}`

        return (
            <Box className="flex items-center gap-1 ">
                <Typography fontSize={font.size.fs_5} className="capitalize" sx={{ color: "inherit" }}>@{type}:</Typography>
                <Typography fontSize={font.size.fs_5} className="capitalize" sx={{ color: theme.palette.text.primary }}>{name}</Typography>
            </Box>
        )
    }

    const handleRefreshConversation = () => {
        handleGetConversationList(setIsLoading);
    }


    const handleClose = (e) => {
        e.stopPropagation();

        setToggleChatWindow(false);
        handleCloseItemChatWindow();

        // if (isItemChatWindow) {
        //     setIsItemChatWindow(false);
        //     setCurrentUserStoryData(null);
        //     setCurrentUseCaseData(null);
        // }
    }

    // To close the file item context from AI window
    const handleCloseItemChatWindow = () => {

        if (isItemChatWindow) {
            setIsItemChatWindow(false);
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleGenerate();
        }
    }

    const handleScrollToBottom = () => {
        console.log(conversationResponseContainerRef, "scroll conversationResponseContainerRef")
        if (conversationResponseContainerRef.current) {
            conversationResponseContainerRef.current.scroll({
                top: conversationResponseContainerRef.current.scrollHeight,
                // top: 0,
                behavior: 'smooth',
            });

            console.log(conversationResponseContainerRef.current.scrollHeight, "scroll")
        }
    }

    const handleOpenSavePopup = (e) => {
        setResponseSaveAnchorEl(e.currentTarget);
    }

    const handleCloseSavePopup = () => {
        setResponseSaveAnchorEl(null);
    }

    const handleContinueSavePopup = (folder) => {
        setCurrentDataSavingFolder(folder?.data);
        handleCloseSavePopup();
        // handleSaveGeneratedResponse(folderPath)
    }

    const handleGenerate = () => {
        setTimeout(() => {
            handleScrollToBottom();
        }, 400);
        handleGenerateResponse();
    }


    const handleGetConversationList = async (setLoader) => {
        try {
            setLoader(true);

            let body;

            // when AI Window is opened on particular item scope
            if (isItemChatWindow && (currentUserStoryData || currentUseCaseData)) {

                body = {

                    "entity": currentAIEntityData?.entity,

                    "userStoryCustomId": pathName === ModulePath.requirements ? currentUserStoryData?.customId : null,
                    "useCaseCustomId": pathName === ModulePath.useCase ? currentUseCaseData?.customId : null,
                    "testCaseCustomId": pathName === ModulePath.testCase ? currentUseCaseData?.customId : null,

                    "folderPath": pathName === ModulePath.requirements ? currentUserStoryData?.folderPath : currentUseCaseData?.folderPath,
                    "projectName": activeProject?.name,
                }


            } else {

                body = {
                    "entity": currentAIEntityData?.entity,

                    "userStoryCustomId": null,
                    "useCaseCustomId": null,
                    "testCaseCustomId": null,

                    // "folderPath": currentFolderData?.path,
                    "projectName": activeProject?.name,
                }

            }

            if (currentAIEntityData?.entity === Entity.folder && body?.folderPath) {
                delete body?.folderPath
            }

            if (!body) return


            const response = await getConversationHistoryList_API(body);
            console.log(response, "response handleGetConversationList")

            if (response) {
                errorHandler(response, setLoader);
            }

            if (response.status === HttpStatusCode.Ok) {
                let list = [...response.data];
                // setResponseList(list?.reverse());
                setResponseList(list);

                setTimeout(() => {
                    conversationResponseContainerRef.current && handleScrollToBottom();
                }, 800);
            }


            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoader(false);
            }, 1000);
        }
    }

    const handleGenerateResponse = async () => {
        try {
            setIsContentGenerationLoading(true);

            let body;

            // when AI Window is opened on particular item scope
            if (isItemChatWindow && (currentUserStoryData || currentUseCaseData)) {

                body = {
                    "prompt": userInput,
                    "entity": currentAIEntityData?.entity,

                    "userStoryCustomId": pathName === ModulePath.requirements ? currentUserStoryData?.customId : null,
                    "useCaseCustomId": pathName === ModulePath.useCase ? currentUseCaseData?.customId : null,
                    "testCaseCustomId": pathName === ModulePath.testCase ? currentUseCaseData?.customId : null,

                    "folderPath": pathName === ModulePath.requirements ? currentUserStoryData?.folderPath : currentUseCaseData?.folderPath,
                    "projectName": activeProject?.name,

                }


            } else {

                body = {
                    "prompt": userInput,
                    "entity": currentAIEntityData?.entity,

                    "userStoryCustomId": null,
                    "useCaseCustomId": null,
                    "testCaseCustomId": null,

                    // "folderPath": currentFolderData?.path,
                    "projectName": activeProject?.name,

                }

            }

            if (currentAIEntityData?.entity === Entity.folder && body?.folderPath) {
                delete body?.folderPath
            }


            const response = await generateResponse_API(body);
            console.log(response, "response handleGenerateResponse")

            if (response) {
                errorHandler(response, setIsContentGenerationLoading);
            }

            if (response?.status === HttpStatusCode.Ok) {
                setUserInput("");
                handleGetConversationList(setIsContentGenerationLoading);
            }

            setTimeout(() => {
                setIsContentGenerationLoading(false)
            }, 1000);


            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setIsContentGenerationLoading(false);
            }, 1000);
        }
    }

    const handleGetFoldersList = async (projectData, entity) => {
        try {
            setLoading(true);
            let projectName = projectData?.name;

            let body = {
                projectName: projectName,
                section: entity === AI_Entity.userStory ? Entity.userStory : entity === AI_Entity.useCase ? Entity.useCase : Entity.testCase,
                // section: pathName === ModulePath.requirements ? Entity.userStory : pathName === ModulePath.useCase ? Entity.useCase : Entity.testCase
            }

            const response = await getFolderStructureList_API(body);

            console.log(response, "reponse handleGetFoldersList")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                setFoldersList(response.data);

                if (isItemChatWindow) {
                    setCurrentDataSavingFolder(response.data[0])
                }
            }

        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 800);
        }
    }


    // save reponse function start
    const handleSaveGeneratedResponse = async (isSaveDuplicate) => {
        try {
            setIsDataSaveLoading(true);

            let body = {
                "responseId": currentAIResponseSaveData?.responseId,
                "conversationId": currentAIResponseSaveData?.conversationId,
                "ids": currentAIResponseSaveData?.ids,
                "folderPath": currentDataSavingFolder?.path,
                "saveDuplicate": isSaveDuplicate,
                // "folderPath": folderPath,
                // "folderPath": currentFolderData?.path,
            }

            if (currentAIEntityData?.entity === AI_Entity.folder) {
                body.saveUserStoryFolder = pathName === ModulePath.requirements;
                body.saveUseCaseFolder = pathName === ModulePath.useCase;
            }

            const response = await saveGeneratedResponse_API(body);
            console.log(response, "response handleSaveGeneratedResponse")

            if (response) {
                errorHandler(response, setIsDataSaveLoading);
            }

            console.log("alreadybeensaved", response?.response?.data?.message)

            if (response.status === HttpStatusCode.BadRequest && response?.response?.data?.message?.includes("already been saved")) {
                setIsDuplicateSaveResponse(true);
                console.log("alreadybeensaved")
            }

            if (response.status === HttpStatusCode.Ok) {

                if (isSaveDuplicate) {
                    setIsDuplicateSaveResponse(false);
                }

                // When AI Window is opened on particular item scope
                if (isItemChatWindow) {

                    if (currentAIEntityData?.entity === AI_Entity.userStory && pathName !== ModulePath.requirements) {
                        navigate(ModulePath.requirements)
                    }

                    if (currentAIEntityData?.entity === AI_Entity.useCase && pathName !== ModulePath.useCase) {
                        navigate(ModulePath.useCase)
                    }

                    if (currentAIEntityData?.entity === AI_Entity.testCase && pathName !== ModulePath.testCase) {
                        navigate(ModulePath.testCase)
                    }


                    // if (pathName === ModulePath.requirements) {
                    //     // no need to fetch usecase list here
                    //     navigate(ModulePath.useCase);
                    // }
                    // if (pathName === ModulePath.useCase) {
                    //     handleGetUseCaseList();
                    // }

                } else {
                    // When AI Window is opened on project/module scope

                    if (pathName === ModulePath.requirements) {
                        handleGetUserStoryList();
                    }

                    if (pathName === ModulePath.useCase) {
                        handleGetUseCaseList();
                    }
                }



                setIsUpdateFolderStructure(true);
                handleCloseSavePopup();
                setIsDuplicateSaveResponse(false);

                setTimeout(() => {
                    setCurrentAIResponseSaveData(null);
                    setCurrentFolderData(currentDataSavingFolder);
                    handleSnackbar(response?.data?.message, Severity.success);
                }, 1000);
            }



        } catch (error) {
            console.log(error, error?.message, "savegeneratedresponse_errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setIsDataSaveLoading(false);
            }, 1000);
        }
    }

    const handleGetUserStoryList = async () => {
        try {


            let body = {
                "folderPath": currentFolderData?.path,
                "projectName": activeProject?.name
            }

            const response = await getStoryList_API(body);
            console.log(response, "response handleGetUserStoryList")

            if (response) {
                errorHandler(response);
            }

            if (response.status === HttpStatusCode.Ok) {
                setUserStoryList(response.data);
            }


            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {

            }, 1000);
        }
    }

    const handleGetUseCaseList = async () => {
        try {


            let body = {
                "folderPath": currentFolderData?.path,
                "projectName": activeProject?.name
            }

            const response = await getUseCaseList_API(body);
            console.log(response, "response handleGetUseCaseList")

            if (response) {
                errorHandler(response);
            }

            if (response.status === HttpStatusCode.Ok) {
                setUseCaseList(response.data);
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {

        }
    }

    // save response fucnctino end

    // useEffect(() => {
    //     if (conversationResponseContainerRef.current && responseList?.length > 0) {
    //         conversationResponseContainerRef.current.scroll({
    //             // top: conversationResponseContainerRef.current.scrollHeight,
    //             top: 0,
    //             behavior: 'smooth',
    //         });
    //     }
    // }, [responseList]);


    // To get the conversation list
    useEffect(() => {
        if (activeProject?.name) {
            handleGetConversationList(setIsLoading);
        }
    }, [currentFolderData?.id, pathName, isItemChatWindow, currentAIEntityData]);


    // Close the item file context from AI chat window
    useEffect(() => {
        if (isItemChatWindow && (pathName === ModulePath.requirements ? currentUserStoryData?.folderPath !== currentFolderData?.path : currentUseCaseData?.folderPath !== currentFolderData?.path)) {
            handleCloseItemChatWindow();
        }
    }, [currentFolderData?.id, isItemChatWindow]);


    // Scroll to bottom
    useEffect(() => {
        if (conversationResponseContainerRef && responseList?.length > 0) {
            setTimeout(() => {
                handleScrollToBottom();
            }, 1100);
        }

    }, [conversationResponseContainerRef, responseList?.length]);


    // to update the current data save folder by default
    useEffect(() => {
        if (currentFolderData) {
            setCurrentDataSavingFolder(currentFolderData);
        }
    }, [currentFolderData, setCurrentDataSavingFolder]);


    // To get the folder list for current entity
    useEffect(() => {
        if (activeProject && currentAIEntityData?.entity) {
            handleGetFoldersList(activeProject, currentAIEntityData?.entity);
        }
    }, [activeProject, currentAIEntityData]);


    useEffect(() => {
        if (isDuplicateSaveResponse) {
            setIsDuplicateSaveResponse(false);
        }
    }, [currentAIResponseSaveData])


    // useEffect(() => {
    //     if (isItemChatWindow && (currentUserStoryData || currentUseCaseData)) {
    //         setIsItemChatWindow(false);
    //     }
    // }, [pathName]);

    console.log(currentDataSavingFolder, "currentDataSavingFolder");
    console.log(currentAIResponseSaveData, "currentAIResponseSaveData_in_chatwindow");

    return (
        <Box className={`w-full h-full flex flex-col pb-0 rounded-lg backdrop-blur-lg`}>

            {/* header */}
            <Box className="flex items-center gap-1 justify-between px-4 py-2 "   >

                <Box className="flex-1 flex flex-col items-start gap-1">
                    <Chip size="small" variant="" label={getAILabel()} sx={{ fontSize: font.size.fs_5, borderRadius: theme.shape.borderRadius, color: theme.palette.ai.main, background: theme.palette.ai.light }} />
                    {/* <Box
                        className={`flex items-center rounded-md px-1 ${isItemChatWindow ? " shadow-sm" : ""}`}
                        sx={{
                            border: `1px solid ${isItemChatWindow ? "transparent" : theme.palette.primary.main}`,
                            background: isItemChatWindow ? theme.palette.primary.main : "",
                            color: isItemChatWindow ? theme.palette.primary.contrastText : ""
                        }}
                    >
                        {handleGetContext()}

                        {isItemChatWindow && <IconButton sx={{ color: theme.palette.primary.contrastText, }} onClick={handleClose}>
                            <Close sx={{ fontSize: font.size.fs_5 }} />
                        </IconButton>}
                    </Box> */}
                </Box>


                <LightTooltip title={`Refresh`}>
                    <IconButton onClick={handleRefreshConversation}>
                        <Refresh />
                    </IconButton>
                </LightTooltip>
                <LightTooltip title={`Close`}>
                    <IconButton onClick={handleClose}>
                        <Close />
                    </IconButton>
                </LightTooltip>
            </Box>

            {/*  response container */}
            <Box className=" flex-1 max-h-[64.6%] border flex flex-col  box-border relative " >

                {
                    responseList?.length === 0 && isContentGenerationLoading && !isLoading ? (
                        <Box className="grow flex flex-col-reverse gap-4 px-4" >
                            <Box className="w-fit flex items-center px-4 py-2 rounded-lg " bgcolor={theme.palette.common.white}>
                                <CircularProgress color="dark" size={16} />
                            </Box>
                        </Box>
                    )
                        :

                        isLoading ? (
                            <Box className="backdrop-blur-sm w-full h-full flex flex-col gap-4 px-4 py-4">
                                {
                                    Array.from({ length: 2 }).map((_, index) => <Skeleton variant="rounded" animation="pulse" width={"100%"} height={"400px"} />)
                                }
                            </Box>
                        )
                            :
                            responseList?.length === 0 ? (
                                <Box className="flex-1 flex flex-col items-center justify-center ">
                                    <Box className=" flex items-center justify-center gap-2 ">
                                        <AutoAwesomeTwoToneIcon sx={{ color: theme.palette.ai.main }} />
                                        <Typography fontSize={font.size.fs_3} color={theme.palette.text.primary}>AI Assistant</Typography>
                                    </Box>
                                    <Typography fontSize={font.size.fs_5} color={theme.palette.text.primary} className="opacity-60">Your {getAILabel()?.replace("AI assistance for:", "").trim()} assistant !</Typography>
                                </Box>
                            ) :
                                (
                                    <Box ref={conversationResponseContainerRef} className="grow flex flex-col gap-4 px-4 py-4 rounded-lg overflow-y-auto ">
                                        {
                                            responseList?.map((item, index, array) => {
                                                console.log(index === responseList?.length - 1, item, "isLastConversation top")
                                                return (
                                                    <MessagesContainer key={index} item={item} isLastConversation={index === responseList?.length - 1} />
                                                )
                                            })
                                        }

                                        {/* when content in generating */}
                                        {isContentGenerationLoading && <Box className="w-fit flex items-center px-4 py-2 rounded-lg" bgcolor={theme.palette.common.white}>
                                            <CircularProgress color="dark" size={16} />
                                        </Box>}
                                    </Box>
                                )
                }

            </Box>

            {/* content generation loader */}
            {/* <Box className="px-0" sx={{ width: '100%' }}>
                <LinearProgress sx={{ display: isContentGenerationLoading ? "block" : "none" }} />
            </Box> */}


            {/* prompt message container */}
            <Box className="px-4 py-2 flex flex-col gap-2 w-full " >

                {/* context */}
                <Box className="w-full flex items-center justify-end flex-wrap gap-2 ">

                    {/* current item chat window box */}
                    {isItemChatWindow &&
                        <LightTooltip title={`File: ${currentAIEntityData?.label}/${pathName === ModulePath.requirements ? currentUserStoryData?.title : currentUseCaseData?.title}`}>
                            <Box
                                className={`flex items-center px-1 py-1 gap-1 w-fit max-w-[39%] overflow-hidden  shadow-sm shadow-[${theme.palette.ai.main}]`}
                                sx={{
                                    // border: `1px solid ${true ? theme.palette.text.secondary : theme.palette.primary.main}`,
                                    background: true ? theme.palette.ai.main : "",
                                    // color: true ? theme.palette.ai.main : "",
                                    fontSize: font.size.fs_5,
                                    // boxShadow: `0px 0px 2px 5px ${theme.palette.ai.light}` 
                                }}
                                borderRadius={theme.shape.borderRadius}
                            // onClick={handleOpenSavePopup}
                            >
                                <DescriptionOutlined sx={{ color: theme.palette.ai.contrastText, fontSize: font.size.fs_5 }} />
                                {/* <Typography fontSize={font.size.fs_5} color={theme.palette.text.secondary}>{currentAIEntityData?.label}:</Typography> */}
                                <Typography fontSize={font.size.fs_5} color={theme.palette.ai.contrastText} className="flex-1 truncate">{pathName === ModulePath.requirements ? currentUserStoryData?.title : currentUseCaseData?.title}</Typography>
                                <IconButton sx={{}} onClick={handleClose}>
                                    <Close sx={{ fontSize: font.size.fs_5, color: theme.palette.ai.contrastText }} />
                                </IconButton>
                            </Box>
                        </LightTooltip>
                    }



                    <FolderLocationComponent />

                    {false && isDuplicateSaveResponse &&
                        <LightTooltip title="Save Duplicate Items">
                            <Checkbox size="small" checked={isDuplicateSaveAllowed} onChange={(e) => setIsDuplicateSaveAllowed(e.target.checked)} />
                        </LightTooltip>
                    }

                    {false && <LightTooltip title="Save">
                        <IconButton
                            disabled={!currentAIResponseSaveData}
                            size="small"
                            variant="outlined"

                            color="secondary"
                            onClick={() => handleSaveGeneratedResponse()}
                            // onClick={handleOpenSavePopup} // save location dialog
                            sx={{}}
                        >
                            {isDataSaveLoading ? <CircularProgress color="secondary" size={16} /> : <Save />}
                        </IconButton>
                    </LightTooltip>}

                    {false && <LightTooltip title={isDuplicateSaveResponse ? "Save Duplicate" : "Save"}>
                        <IconButton
                            disabled={!currentAIResponseSaveData}
                            size="small"
                            variant="outlined"
                            color={isDuplicateSaveResponse ? "warning" : "secondary"}
                            onClick={() => handleSaveGeneratedResponse(isDuplicateSaveResponse ? true : false)}
                            // onClick={handleOpenSavePopup} // save location dialog
                            sx={{}}
                        >
                            {isDataSaveLoading ? <CircularProgress color="secondary" size={16} /> : <Save />}
                        </IconButton>
                    </LightTooltip>}

                    {false &&
                        <Box className="flex-1 flex items-center gap-2 px-1 cursor-pointer" borderRadius={theme.shape.borderRadius} bgcolor={theme.palette.background.custom} onClick={handleOpenSavePopup}>
                            <FolderOpenOutlined sx={{ color: theme.palette.text.secondary, fontSize: font.size.fs_5 }} />
                            <Typography fontSize={font.size.fs_5} color={theme.palette.text.secondary} className="truncate overflow-hidden">/{currentDataSavingFolder?.path}</Typography>
                        </Box>}

                </Box>


                <Box className="flex flex-col gap-2 w-full h-full " >

                    <TextField
                        size="small"
                        placeholder={`Prompt for generating ${getAILabel()?.replace("AI assistance for:", "").trim()}`}
                        disabled={isContentGenerationLoading}
                        autoFocus
                        fullWidth
                        multiline
                        minRows={3}
                        maxRows={3}
                        value={userInput}
                        onChange={(e) => setUserInput(e.target.value)}
                        onKeyDown={(e) => handleKeyDown(e)}
                        // slotProps={{
                        //     input: {
                        //         sx: {
                        //             borderRadius: theme.shape.borderRadius
                        //         }
                        //     }
                        // }}
                        slotProps={{
                            input: {
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Box className="w-full h-fit py-0 px-3.5 flex items-center justify-end box-border">
                                            <ChooseContextComponent />
                                            <IconButton
                                                size="small"
                                                color={theme.palette.ai.main}
                                                disabled={true}
                                                sx={{ ml: "auto" }}
                                            >
                                                <AttachFileOutlined />
                                            </IconButton>
                                            <IconButton
                                                size="small"
                                                disabled={!userInput || isContentGenerationLoading}
                                                onClick={handleGenerate}
                                                sx={{
                                                    background: theme.palette.ai.main,
                                                    color: theme.palette.ai.contrastText,
                                                    transition: "all 0.2s ease-in-out",
                                                    "&:hover": {
                                                        background: theme.palette.ai.main,
                                                        opacity: 0.8,
                                                    },
                                                }}
                                            >
                                                <SendOutlined />
                                            </IconButton>
                                        </Box>
                                    </InputAdornment>
                                ),
                            },
                        }}
                        sx={{
                            bgcolor: theme.palette.card.main,
                            p: 0,
                            borderRadius: theme.shape.borderRadius, // Added borderRadius here
                            // boxShadow: `0px 0px 2px 5px ${theme.palette.ai.light}`,
                            "& .MuiInputBase-input": {
                                // padding: "10px 0px 10px 0px", // Add padding to the input field
                                mb: "2.2rem",
                            },
                            "& .MuiInputAdornment-root": {
                                width: "100%",
                                height: "100%",
                                // border: `2px solid black`,
                                my: "0.6rem",
                                // py : "2px",
                                position: "absolute", // Position the adornment absolutely
                                bottom: "0px", // Position the adornment at the bottom
                                right: "0px", // Position the adornment at the right
                            },
                        }}
                    />

                    {false && <Box className=" w-full flex items-center justify-end px-0">

                        <IconButton size="small" color={theme.palette.ai.main} disabled={true}  >
                            <AttachFileOutlined />
                        </IconButton>
                        <IconButton size="small" disabled={!userInput || isContentGenerationLoading} onClick={handleGenerate} sx={{ background: theme.palette.ai.main, color: theme.palette.ai.contrastText, transition: "all 0.2s ease-in-out", "&:hover": { background: theme.palette.ai.main, opacity: 0.8 } }} >
                            <SendOutlined />
                        </IconButton>
                    </Box>}
                </Box>
            </Box>



            {/* save popup */}
            {responseSaveAnchorEl &&
                <ResponseSaveComponent
                    responseSaveAnchorEl={responseSaveAnchorEl}
                    handleCloseSavePopup={handleCloseSavePopup}
                    handleContinueSavePopup={handleContinueSavePopup}
                    foldersList={foldersList}
                    setFoldersList={setFoldersList}
                // entity={data?.entity}
                />}
        </Box>
    )
}



function MessagesContainer({ item, isLastConversation }) {
    const theme = useTheme();

    return (
        <Box className={`flex flex-col w-full gap-2  `}>

            <Box className={`flex flex-col w-full gap-0 rounded-lg `} >

                <Box className={`flex  w-full gap-2 `}>
                    {item?.sentBy === AI_Response_SentBy.user
                        ? <UserMessageContainer data={item} isLastConversation={isLastConversation} />
                        : <ResponseMessageContainer data={item} isLastConversation={isLastConversation} />
                    }
                </Box>

            </Box>

        </Box>
    )
}

// It is generated response container
function ResponseMessageContainer({ data, isLastConversation }) {
    const {

        setFolderStructureList,
        handleSnackbar,
        currentFolderData, setCurrentFolderData,
        isUpdateFolderStructure, setIsUpdateFolderStructure,
        setUserStoryList,
        activeProject,
        setUseCaseList,
        isItemChatWindow,
        currentDataSavingFolder, setCurrentDataSavingFolder,
        currentAIEntityData,
        currentAIResponseSaveData, setCurrentAIResponseSaveData,
    } = useApplicationContext();
    const theme = useTheme();
    const errorHandler = useErrorHandler();
    const pathName = useLocation()?.pathname;
    const navigate = useNavigate();

    const [selectedResponseList, setSelectedResponseList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isDuplicateSaveAllowed, setIsDuplicateSaveAllowed] = useState(false);

    const [responseSaveAnchorEl, setResponseSaveAnchorEl] = useState(null);
    const [foldersList, setFoldersList] = useState([]);

    const handleSelectAll = (e) => {
        setSelectedResponseList(
            e.target.checked ?
                data?.chatMessage?.some((item) => item?.id === null) ? [] : data?.chatMessage
                : []);
    }

    const handleToggleSelection = (e, data) => {
        if (e.target.checked && data?.id) {
            setSelectedResponseList([...selectedResponseList, data]);
        } else {
            setSelectedResponseList(selectedResponseList?.filter((item) => item?.id !== data?.id));
        }
    }

    const handleOpenSavePopup = (e) => {
        setResponseSaveAnchorEl(e.currentTarget);
    }

    const handleContinueSavePopup = (folder) => {
        console.log(folder, "folderhandleContinueSavePopup");
        setCurrentDataSavingFolder(folder?.data);
        handleCloseSavePopup();
        // handleSaveGeneratedResponse(folderPath)
    }

    const handleCloseSavePopup = () => {
        setResponseSaveAnchorEl(null);
    }

    const handleSaveGeneratedResponse = async (folderPath) => {
        try {
            setIsLoading(true);

            let body = {
                "responseId": data?.responseId,
                "conversationId": data?.conversationId,
                "ids": [...selectedResponseList?.map((item) => item?.id)],
                "folderPath": currentDataSavingFolder?.path,
                "saveDuplicate": isDuplicateSaveAllowed,
                // "folderPath": folderPath,
                // "folderPath": currentFolderData?.path,
            }

            const response = await saveGeneratedResponse_API(body);
            console.log(response, "response handleSaveGeneratedResponse")

            if (response) {
                errorHandler(response, setIsLoading);
            }

            if (response.status === HttpStatusCode.Ok) {

                // When AI Window is opened on particular item scope
                if (isItemChatWindow) {

                    if (currentAIEntityData?.entity === AI_Entity.userStory && pathName !== ModulePath.requirements) {
                        navigate(ModulePath.requirements)
                    }

                    if (currentAIEntityData?.entity === AI_Entity.useCase && pathName !== ModulePath.useCase) {
                        navigate(ModulePath.useCase)
                    }

                    if (currentAIEntityData?.entity === AI_Entity.testCase && pathName !== ModulePath.testCase) {
                        navigate(ModulePath.testCase)
                    }


                    // if (pathName === ModulePath.requirements) {
                    //     // no need to fetch usecase list here
                    //     navigate(ModulePath.useCase);
                    // }
                    // if (pathName === ModulePath.useCase) {
                    //     handleGetUseCaseList();
                    // }

                } else {
                    // When AI Window is opened on project/module scope

                    if (pathName === ModulePath.requirements) {
                        handleGetUserStoryList();
                    }

                    if (pathName === ModulePath.useCase) {
                        handleGetUseCaseList();
                    }
                }



                setIsUpdateFolderStructure(true);
                handleCloseSavePopup();

                setTimeout(() => {
                    setSelectedResponseList([]);
                    setCurrentFolderData(currentDataSavingFolder);
                    handleSnackbar(response?.data?.message, Severity.success);
                }, 1000);
            }

        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
        }
    }

    const handleGetUserStoryList = async () => {
        try {


            let body = {
                "folderPath": currentFolderData?.path,
                "projectName": activeProject?.name
            }

            const response = await getStoryList_API(body);
            console.log(response, "response handleGetUserStoryList")

            if (response) {
                errorHandler(response);
            }

            if (response.status === HttpStatusCode.Ok) {
                setUserStoryList(response.data);
                setSelectedResponseList([]);
            }


            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {

            }, 1000);
        }
    }

    const handleGetUseCaseList = async () => {
        try {


            let body = {
                "folderPath": currentFolderData?.path,
                "projectName": activeProject?.name
            }

            const response = await getUseCaseList_API(body);
            console.log(response, "response handleGetUseCaseList")

            if (response) {
                errorHandler(response);
            }

            if (response.status === HttpStatusCode.Ok) {
                setUseCaseList(response.data);
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {

        }
    }

    const handleGetFoldersList = async (projectData, entity) => {
        try {

            let projectName = projectData?.name;

            let body = {
                projectName: projectName,
                section: entity === AI_Entity.userStory ? Entity.userStory : entity === AI_Entity.useCase ? Entity.useCase : Entity.testCase,
                // section: pathName === ModulePath.requirements ? Entity.userStory : pathName === ModulePath.useCase ? Entity.useCase : Entity.testCase
            }

            const response = await getFolderStructureList_API(body);

            console.log(response, "reponse handleGetFoldersList")

            if (response) {
                errorHandler(response);
            }

            if (response.status === HttpStatusCode.Ok) {
                setFoldersList(response.data);

                if (isItemChatWindow) {
                    setCurrentDataSavingFolder(response.data[0])
                }
            }

        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {

            }, 800);
        }
    }

    useEffect(() => {
        if (data?.chatMessage && isLastConversation) {
            setSelectedResponseList([...data?.chatMessage]);
        }
        if (data?.chatMessage && !isLastConversation) {
            setSelectedResponseList([]);
        }
    }, [data?.chatMessage, isLastConversation]);


    // To get the folder list for current entity
    useEffect(() => {
        if (activeProject && currentAIEntityData?.entity) {
            handleGetFoldersList(activeProject, currentAIEntityData?.entity);
        }
    }, [activeProject, currentAIEntityData]);


    // To save the current selected conversation responses list for save
    useEffect(() => {
        if (selectedResponseList?.length > 0) {
            setCurrentAIResponseSaveData({
                "responseId": data?.responseId,
                "conversationId": data?.conversationId,
                "ids": [...selectedResponseList?.map((item) => item?.id)],
                "folderPath": "",
                "saveDuplicate": false,
            });
        } else {
            setCurrentAIResponseSaveData(null);
        }
    }, [selectedResponseList, setCurrentAIResponseSaveData, data])



    console.log(isLastConversation, "isLastConversation", data?.chatMessage);
    console.log(selectedResponseList, "selectedResponseList");
    // console.log(JSON.parse(data?.chatMessageStr), "data in responsemessagecontainer")
    console.log(data, "data_in_responsemessagecontainer", AI_Entity);

    return (
        <Box className={`flex flex-col w-full `} >

            <Box className={`flex flex-col px-2 pb-2 w-fit max-w-full gap-2 `} borderRadius={theme.shape.borderRadius} bgcolor={theme.palette.card.main}>

                {data?.chatMessage && Array.isArray(data?.chatMessage) ?
                    <Box className={`flex flex-col w-full gap-0 px-0 py-0 rounded-lg relative  `} >

                        {/* header of the response - select all checkbox, save, regenerate button */}
                        <Box className={`flex  items-center flex-wrap w-full gap-2 py-2 overflow-hidden border-b sticky -top-6 z-10 `} bgcolor={theme.palette.background.default}
                            sx={{ borderRadius: `${theme.shape.borderRadius} ${theme.shape.borderRadius} 0 0` }} >
                            <Checkbox
                                size="small"
                                disabled={currentAIResponseSaveData && currentAIResponseSaveData?.responseId !== data?.responseId}
                                checked={(currentAIResponseSaveData && currentAIResponseSaveData?.responseId !== data?.responseId) ? false : data?.chatMessage && selectedResponseList?.length === data?.chatMessage?.length}
                                indeterminate={selectedResponseList?.length > 0 && data?.chatMessage && (selectedResponseList?.length < data?.chatMessage?.length)}
                                onChange={handleSelectAll}
                            />

                            <Avatar sx={{ width: "1.4rem", height: "1.4rem", background: theme.palette.background.custom, }}>
                                <Typography fontSize={font.size.fs_5} color={theme.palette.text.primary}>{selectedResponseList?.length}</Typography>
                            </Avatar>

                            <LightTooltip title="Regenerate">
                                <IconButton disabled color="primary" sx={{ ml: "auto" }}>
                                    <ReplayOutlined />
                                </IconButton>
                            </LightTooltip>

                            {false && <FolderLocationComponent />}

                            {false && <LightTooltip title={`Save Location: /${currentDataSavingFolder?.path}`}>
                                <Box className="min-w-14 flex-1 flex items-center gap-2 px-1 cursor-pointer overflow-hidden" borderRadius={theme.shape.borderRadius} bgcolor={theme.palette.background.custom} onClick={handleOpenSavePopup}>
                                    <FolderOpenOutlined sx={{ color: theme.palette.text.secondary, fontSize: font.size.fs_5 }} />
                                    <Typography fontSize={font.size.fs_5} color={theme.palette.text.secondary} className="truncate overflow-hidden">/{currentDataSavingFolder?.path}</Typography>
                                </Box>
                            </LightTooltip>}


                            {false && <LightTooltip title="Save Duplicate Items">
                                <Checkbox size="small" checked={isDuplicateSaveAllowed} onChange={(e) => setIsDuplicateSaveAllowed(e.target.checked)} />
                            </LightTooltip>}

                            {false && <LightTooltip title="Save">

                                <IconButton
                                    disabled={selectedResponseList?.length === 0}
                                    size="small"
                                    variant="outlined"

                                    color="secondary"
                                    onClick={() => handleSaveGeneratedResponse()}
                                    // onClick={handleOpenSavePopup} // save location dialog
                                    sx={{}}
                                >
                                    {isLoading ? <CircularProgress color="secondary" size={16} /> : <Save />}
                                </IconButton>
                            </LightTooltip>}

                        </Box>


                        {/* list of the responses */}
                        <Box className={`flex flex-col w-full gap-2  pt-2  `}>
                            {
                                data?.chatMessage && data?.chatMessage?.length > 0 && data?.chatMessage?.map((responseItem, index) => (
                                    <Box key={index} className={`flex  w-full gap-0 relative`} >
                                        <Box className="flex h-fit">
                                            <Checkbox size="small" disabled={currentAIResponseSaveData && currentAIResponseSaveData?.responseId !== data?.responseId} checked={(currentAIResponseSaveData && currentAIResponseSaveData?.responseId !== data?.responseId) ? false : selectedResponseList?.includes(responseItem)} onChange={(e) => handleToggleSelection(e, responseItem)} />
                                        </Box>

                                        <Box className="flex-1 flex flex-col  border  gap-1   " borderRadius={theme.shape.borderRadius} sx={{ border: `1px solid ${theme.palette.text.secondary}` }}>
                                            <ResponseComponent response={responseItem} entity={data?.entity} />
                                        </Box>
                                    </Box>
                                ))
                            }
                        </Box>
                    </Box>
                    :
                    <Box className="flex items-center ">
                        <Typography fontSize={font.size.fs_5} color={theme.palette.text.primary} fontStyle="italic" className="opacity-60">Unable to generate response</Typography>
                    </Box>
                }

                <Box className=" flex" >
                    <Typography fontSize={font.size.fs_6} color={theme.palette.text.primary} className="opacity-80">{dayjs(data?.createdAt).format("DD MMM YYYY, hh:mm A")}</Typography>
                </Box>


            </Box>


            {/* save popup */}
            {responseSaveAnchorEl &&
                <ResponseSaveComponent
                    responseSaveAnchorEl={responseSaveAnchorEl}
                    handleCloseSavePopup={handleCloseSavePopup}
                    handleContinueSavePopup={handleContinueSavePopup}
                    foldersList={foldersList}
                    setFoldersList={setFoldersList}
                // entity={data?.entity}
                />}

        </Box>
    )
}


function UserMessageContainer({ data }) {
    const theme = useTheme();

    return (
        <Box className={`flex flex-col items-end w-full gap-2 `} >


            <Box className={`flex flex-col items-end gap-1 px-2 py-1 rounded-lg backdrop-blur-lg `} sx={{ color: theme.palette.text.primary }} bgcolor={theme.palette.card.main} >
                <Box className=" flex" >
                    <Typography fontSize={font.size.fs_5} sx={{ color: "inherit" }}>{data?.chatMessageStr}</Typography>
                </Box>

                <Box className=" flex" >
                    <Typography fontSize={font.size.fs_6} color={theme.palette.text.secondary} className="opacity-80">{dayjs(data?.createdAt).format("DD MMM YYYY, hh:mm A")}</Typography>
                </Box>
            </Box>

            {/* <Avatar className="shadow-md" sx={{ width: "2rem", height: "2rem", background: theme.palette.primary.main }}>
                {data?.sentBy === AI_Response_SentBy.user ?
                    <AccountCircleOutlined sx={{ color: theme.palette.common.white }} />
                    : <AutoAwesomeOutlinedIcon sx={{ color: theme.palette.common.white }} />
                }
            </Avatar> */}
        </Box>
    )
}


// use to show generated module UI
function ResponseComponent({ response, entity }) {
    const {
        currentFolderData,
        isItemChatWindow, setIsItemChatWindow,
        currentUserStoryData, setCurrentUserStoryData,
        currentUseCaseData, setCurrentUseCaseData,
    } = useApplicationContext();

    if (entity === AI_Entity.useCase) {
        return <ResponseUseCaseComponent response={response} />
    }

    if (entity === AI_Entity.testCase) {
        return <ResponseTestCaseComponent response={response} />
    }

    if (entity === AI_Entity.userStory) {
        return <ResponseUserStoryComponent response={response} />
    }

    if (entity === AI_Entity.folder) {
        return <ResponseFolderComponent response={response} />
    }

    return (
        <Box className="w-full flex flex-col gap-0 px-4 py-2">
            <Box className="w-full flex flex-col gap-0 ">
                <Typography fontSize={font.size.fs_5}>Title: {response?.name}</Typography>
                <Typography fontSize={font.size.fs_5} className="">Description: {response?.description}</Typography>
                <Box className="flex flex-col gap-1">
                    <Typography fontSize={font.size.fs_5} className="">Acceptance Criteria:</Typography>
                    {
                        response?.acceptanceCriteria && response?.acceptanceCriteria?.map((critera, index) => (
                            <Box className="flex items-center gap-2">
                                <Box className="w-2 h-2 rounded-full bg-gray-600"></Box>
                                <Typography fontSize={font.size.fs_5} className="flex-1">{critera}</Typography>
                            </Box>
                        ))
                    }
                </Box>

            </Box>
        </Box>
    )
}

function ResponseFolderComponent({ response }) {
    const { currentFolderData } = useApplicationContext();
    const theme = useTheme();

    return (
        <Box className="w-full flex flex-col gap-0 px-4 py-2">
            <Box className="w-full flex flex-col gap-0 " sx={{ color: theme.palette.text.primary }}>
                <Typography fontSize={font.size.fs_5} sx={{ color: "inherit" }}>Title: {response?.name}</Typography>
                <Typography fontSize={font.size.fs_5} className="" sx={{ color: "inherit" }}>Description: {response?.description}</Typography>
            </Box>
        </Box>
    )
}

function ResponseUserStoryComponent({ response }) {
    const { currentFolderData } = useApplicationContext();
    const theme = useTheme();

    return (
        <Box className="w-full flex flex-col gap-0 px-4 py-2">
            <Box className="w-full flex flex-col gap-2 " sx={{ color: theme.palette.text.primary }}>
                <Typography fontSize={font.size.fs_5} sx={{ color: "inherit" }}>Title: {response?.name}</Typography>
                <Typography fontSize={font.size.fs_5} className="" sx={{ color: "inherit" }}>Description: {response?.description}</Typography>
                <Typography fontSize={font.size.fs_5} className="" sx={{ color: "inherit" }}>Acceptance Criteria:</Typography>
                {response?.acceptanceCriteria?.length > 0 && <Box className="flex flex-col gap-1">
                    {
                        response?.acceptanceCriteria?.map((step, index) => (
                            <Box className="flex items-center gap-2">
                                <Box className="w-2 h-2 rounded-full " sx={{ bgcolor: theme.palette.text.primary }}></Box>
                                <Typography fontSize={font.size.fs_5} className="flex-1" sx={{ color: "inherit" }}>{step}</Typography>
                            </Box>
                        ))
                    }
                </Box>}

            </Box>
        </Box>
    )
}

function ResponseUseCaseComponent({ response }) {
    const { currentFolderData } = useApplicationContext();
    const theme = useTheme();

    return (
        <Box className="w-full flex flex-col gap-0 px-4 py-2">
            <Box className="w-full flex flex-col gap-2 " sx={{ color: theme.palette.text.primary }}>
                <Typography fontSize={font.size.fs_5} sx={{ color: "inherit" }}>Title: {response?.name}</Typography>
                <Typography fontSize={font.size.fs_5} sx={{ color: "inherit" }}>Description: {response?.description}</Typography>
                <Typography fontSize={font.size.fs_5} className="" sx={{ color: "inherit" }}>Steps:</Typography>
                {response?.useCaseSteps?.length > 0 && <Box className="flex flex-col gap-1">
                    {
                        response?.useCaseSteps?.map((step, index) => (
                            <Box className="flex items-center gap-2">
                                <Box className="w-2 h-2 rounded-full " sx={{ bgcolor: theme.palette.text.primary }}></Box>
                                <Typography fontSize={font.size.fs_5} className="flex-1" sx={{ color: "inherit" }}>{step}</Typography>
                            </Box>
                        ))
                    }
                </Box>}

            </Box>
        </Box>
    )
}

function ResponseTestCaseComponent({ response }) {
    const { currentFolderData } = useApplicationContext();
    const theme = useTheme();

    return (
        <Box className="w-full flex flex-col gap-0 px-4 py-2">
            <Box className="w-full flex flex-col gap-2 " sx={{ color: theme.palette.text.primary }}>
                <Typography fontSize={font.size.fs_5} sx={{ color: "inherit" }}>Title: {response?.name}</Typography>
                <Typography fontSize={font.size.fs_5} sx={{ color: "inherit" }}>Description: {response?.description}</Typography>
                <Typography fontSize={font.size.fs_5} className="" sx={{ color: "inherit" }}>Steps:</Typography>
                {response?.testCaseSteps?.length > 0 && <Box className="flex flex-col gap-1">
                    {
                        response?.testCaseSteps?.map((step, index) => (
                            <Box className="flex items-center gap-2">
                                <Box className="w-2 h-2 rounded-full " sx={{ bgcolor: theme.palette.text.primary }}></Box>
                                <Box className="flex-1 rounded-full flex flex-col " >
                                    <Typography fontSize={font.size.fs_5} className="flex-1" sx={{ color: "inherit" }}>Step: {step?.step}</Typography>
                                    <Typography fontSize={font.size.fs_5} className="flex-1" sx={{ color: "inherit" }}>Expected: {step?.expectedResult}</Typography>
                                </Box>
                            </Box>
                        ))
                    }
                </Box>}

            </Box>
        </Box>
    )
}

function ResponseSaveComponent({ responseSaveAnchorEl, handleCloseSavePopup, handleContinueSavePopup, entity, foldersList, setFoldersList }) {
    const { folderStructureList, setFolderStructureList, activeProject, handleSnackbar, currentAIEntityData, currentDataSavingFolder, setCurrentDataSavingFolder } = useApplicationContext();
    const theme = useTheme();
    const pathName = useLocation()?.pathname;
    const errorHandler = useErrorHandler();

    const [loading, setLoading] = useState(false);
    const [openCreateFolder, setOpenCreateFolder] = useState(false);

    const [folderText, setFolderText] = useState("");
    const [selectedFolder, setSelectedFolder] = useState(null);
    // const [foldersList, setFoldersList] = useState([]);



    const handleCloseFolderCreate = () => {
        setOpenCreateFolder(false);
        setFolderText("")
    }

    const handleContinue = () => {
        handleContinueSavePopup(selectedFolder)
    }

    const handleCreateFolder = async () => {
        try {
            setLoading(true);

            let body = {
                name: folderText,
                "projectName": activeProject?.name,
                // "section": pathName === ModulePath.requirements ? Entity.userStory : pathName === ModulePath.useCase ? Entity.useCase : Entity.testCase, 
                section: entity === AI_Entity.userStory ? Entity.userStory : entity === AI_Entity.useCase ? Entity.useCase : Entity.testCase,
                path: selectedFolder ? selectedFolder?.data?.path : ""
            }

            const response = await addModule_API(body);
            console.log(response, "response handleCreateModule")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response.status === HttpStatusCode.Ok) {

                // handleGetFoldersList(activeProject, entity);
                handleCloseFolderCreate();
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    // const handleGetFoldersList = async (projectData, entity) => {
    //     try {
    //         setLoading(true);
    //         let projectName = projectData?.name;

    //         let body = {
    //             projectName: projectName,
    //             section: entity === AI_Entity.userStory ? Entity.userStory : entity === AI_Entity.useCase ? Entity.useCase : Entity.testCase,
    //             // section: pathName === ModulePath.requirements ? Entity.userStory : pathName === ModulePath.useCase ? Entity.useCase : Entity.testCase
    //         }

    //         const response = await getFolderStructureList_API(body);

    //         console.log(response, "reponse handleGetFoldersList")

    //         if (response) {
    //             errorHandler(response, setLoading);
    //         }

    //         if (response.status === HttpStatusCode.Ok) {
    //             setFoldersList(response.data);
    //         }

    //     } catch (error) {
    //         console.log(error, error?.message, "projectreponse errorr");
    //         handleSnackbar(String(error), Severity.error);
    //     } finally {
    //         setTimeout(() => {
    //             setLoading(false);
    //         }, 800);
    //     }
    // }

    // useEffect(() => {
    //     if (activeProject && currentAIEntityData?.entity) {
    //         handleGetFoldersList(activeProject, currentAIEntityData?.entity);
    //     }

    // }, [currentAIEntityData?.entity, activeProject]);




    console.log(foldersList, "foldersList")

    return (
        <Dialog
            open={responseSaveAnchorEl}
            onClose={handleCloseSavePopup}
            sx={{
                '& .MuiDialog-paper': {
                    width: '100%',
                    maxWidth: '400px',
                    height: '60vh'
                }
            }}


        >
            <Box className="flex flex-col gap-0  h-full w-full">
                {/* header */}
                <Box className="flex flex-col gap-2 px-4 py-2 border-b">
                    <Typography fontSize={font.size.fs_4}>Folder Location</Typography>
                </Box>


                {/* body */}
                <Box className="flex-1 flex flex-col gap-2  relative " onContextMenu={(e) => e.preventDefault()}>



                    <Box className="  flex h-full w-full ">

                        {/* left folder structure */}
                        <Box className="flex h-full px-4 py-2 w-full">
                            {loading ?
                                <Box className="  flex flex-col gap-2 h-full w-full overflow-y-auto ">
                                    {
                                        Array.from({ length: 9 }, (_, index) => (
                                            <Skeleton variant="rounded" height={30} />
                                        ))
                                    }
                                </Box>
                                :
                                <AutoSizer>
                                    {({ height, width }) => {
                                        console.log(height, width, "heightwidth")
                                        return (

                                            <Tree
                                                initialData={foldersList}
                                                openByDefault={false}
                                                width={width}
                                                height={height}
                                                indent={24}
                                                rowHeight={38}
                                            // overscanCount={1}
                                            // paddingTop={30}
                                            // paddingBottom={10}
                                            // padding={25 /* sets both */}
                                            >
                                                {
                                                    ({ ...props }) => {
                                                        return (
                                                            <Node
                                                                {...props}
                                                                selectedFolder={selectedFolder}
                                                                setSelectedFolder={setSelectedFolder}
                                                            />
                                                        )
                                                    }
                                                }
                                            </Tree>

                                        )
                                    }}
                                </AutoSizer>}
                        </Box>
                        {/* main items list */}
                        {false && <Box className="flex flex-col px-4 py-2 h-full flex-1" bgcolor={theme.palette.background.custom}>
                            list container
                        </Box>}
                    </Box>

                </Box>


                {/* footer */}
                <Box className="flex items-center justify-end gap-2 px-4 py-2 border-t">
                    <Button color="primary" variant="outlined" disabled={loading} onClick={() => setOpenCreateFolder(!openCreateFolder)}>Add Folder</Button>
                    {openCreateFolder &&
                        <TextField
                            placeholder="Folder Name"
                            value={folderText}
                            onChange={(e) => setFolderText(e.target.value)}
                            InputProps={{
                                endAdornment: <Box className="flex items-center gap-0">
                                    <IconButton onClick={() => handleCreateFolder()}>
                                        <Check />
                                    </IconButton>
                                    <IconButton onClick={() => handleCloseFolderCreate()}>
                                        <Close />
                                    </IconButton>
                                </Box>
                            }}
                        />
                    }

                    <Button color="inherit" variant="text" sx={{ ml: "auto" }} onClick={handleCloseSavePopup}>Cancel</Button>
                    <Button color="secondary" sx={{ color: theme.palette.secondary.contrastText }} variant="contained" disabled={loading || !selectedFolder} onClick={handleContinue}>Select</Button>
                </Box>


            </Box>
        </Dialog>
    )
}

function Node({ node, style, dragHandle, selectedFolder, setSelectedFolder }) {
    const {
        currentFolderData, setCurrentFolderData,
        currentDataSavingFolder, setCurrentDataSavingFolder
    } = useApplicationContext();
    const theme = useTheme();
    const pathName = useLocation()?.pathname;


    const handleFolderClick = (e, folder) => {


        console.log(folder, "node1dataa", e);
        // folder.toggle();
        setSelectedFolder(folder);

        if (folder?.id === selectedFolder?.id) {
            setSelectedFolder(null);
        } else {
            setSelectedFolder(folder);
        }
    }

    const handleIconClick = (folder) => {
        folder.toggle();
        setSelectedFolder(folder);
    }

    const handleRightClick = (event, folder) => {
        event.preventDefault();
        console.log(folder, "folder rightclick");
        setSelectedFolder(folder);
    }

    // useEffect(() => {
    //     console.log("rendered")
    //     if (!selectedFolder && node && node.level === 0) {
    //         setSelectedFolder(node)
    //     }
    // }, []);

    useEffect(() => {
        if (currentDataSavingFolder && node?.id === currentDataSavingFolder?.id) {
            // setSelectedFolder(node);
            node?.focus();
        }

    }, [currentDataSavingFolder, setSelectedFolder])

    console.log(selectedFolder, "selectedFolder node1data")



    return (
        <Box
            className={`  flex items-center gap-1 py-1 px-2 cursor-pointer overflow-hidden`}
            borderRadius={theme.shape.borderRadius}
            // style={style}
            // ref={dragHandle}
            sx={{
                // color: (selectedFolder?.id === node?.id) && theme.palette.secondary.contrastText,
                // background: (selectedFolder?.id === node?.id) && "rgba(0, 0, 0, 0.05)" 
                background: (selectedFolder?.id === node?.id) && theme.palette.highlight.main,
                color: (selectedFolder?.id === node?.id) && theme.palette.text.main,
                pl: (node.level * 4)
            }}

        >
            <Box className="" onClick={() => handleIconClick(node)}>
                {!node.isLeaf &&
                    (node.isOpen ?
                        <KeyboardArrowDown sx={{ color: "inherit" }} /> : <KeyboardArrowRight sx={{ color: "inherit" }} />
                    )
                }
            </Box>

            <Box className=" flex-1 flex items-center gap-1 relative  overflow-hidden" onClick={(e) => handleFolderClick(e, node)} sx={{ pl: 0 }} onContextMenu={(e) => handleRightClick(e, node)}>


                <FolderOutlined sx={{ color: "inherit" }} />

                <LightTooltip
                    title={<Box className="flex flex-col gap-1">
                        <Typography fontSize={font.size.fs_5} className="flex-1 ">Title: {node.data.name}</Typography>
                        {pathName === ModulePath.requirements &&
                            <Typography fontSize={font.size.fs_5} className="">User Story: {node?.data?.userStoryCount}</Typography>}
                        {pathName === ModulePath.useCase &&
                            <Typography fontSize={font.size.fs_5} className="">Use Case: {node?.data?.useCaseCount}</Typography>}
                    </Box>}
                    placement="right"
                >
                    <Box className="flex-1 flex items-center  gap-1  box-border  = overflow-hidden">
                        <Typography fontSize={font.size.fs_5} className=" flex-1 overflow-hidden text-ellipsis whitespace-nowrap box-border ">{node.data.name}</Typography>
                        <Typography fontSize={font.size.fs_5} className="w-fit">{`[${pathName === ModulePath.requirements ? node?.data?.userStoryCount : node?.data?.useCaseCount}]`}</Typography>
                    </Box>
                </LightTooltip>
                {/* </Box> */}



            </Box>

        </Box>
    );
}


function FolderLocationComponent() {
    const {
        currentDataSavingFolder, setCurrentDataSavingFolder,
        currentAIEntityData,
        activeProject,
        isItemChatWindow,
        handleSnackbar,
        currentAIResponseSaveData, setCurrentAIResponseSaveData,
        currentFolderData, setCurrentFolderData,
        setUseCaseList, setUserStoryList,
        setIsUpdateFolderStructure,
    } = useApplicationContext();
    const theme = useTheme();
    const pathName = useLocation()?.pathname;
    const navigate = useNavigate();
    const errorHandler = useErrorHandler();

    const [foldersList, setFoldersList] = useState([]);
    const [responseSaveAnchorEl, setResponseSaveAnchorEl] = useState(false);

    const [isDataSaveLoading, setIsDataSaveLoading] = useState(false) // when response is saving
    const [isDuplicateSaveAllowed, setIsDuplicateSaveAllowed] = useState(false); // to ask user wheather to save duplicate data, by taking confirmation from user.
    const [isDuplicateSaveResponse, setIsDuplicateSaveResponse] = useState(false); // enabled duplicated save checkbox, when duplicate response found.


    const [loading, setLoading] = useState(false);
    const [openCreateFolder, setOpenCreateFolder] = useState(false);

    const [folderText, setFolderText] = useState("");
    const [selectedFolder, setSelectedFolder] = useState(null);


    // folder choose functions start
    const handleOpenSavePopup = (e) => {
        console.log(responseSaveAnchorEl, "handle- handleOpenSavePopup")
        setResponseSaveAnchorEl(true);
    }

    const handleCloseSavePopup = () => {
        setResponseSaveAnchorEl(false);
        console.log(responseSaveAnchorEl, "handle- handleCloseSavePopup")
    }

    const handleContinueSavePopup = (folder) => {
        console.log(folder, responseSaveAnchorEl, "handle- folderhandleContinueSavePopup");
        handleCloseSavePopup();
        setCurrentDataSavingFolder(folder?.data);

        // handleSaveGeneratedResponse(folderPath)
    }
    // folder choose functions end



    // choose folder dialog funcitons start
    const handleCloseFolderCreate = () => {
        setOpenCreateFolder(false);
        setFolderText("")
    }

    const handleContinue = () => {
        handleContinueSavePopup(selectedFolder)
    }

    const handleCreateFolder = async () => {
        try {
            setLoading(true);

            let body = {
                name: folderText,
                "projectName": activeProject?.name,
                // "section": pathName === ModulePath.requirements ? Entity.userStory : pathName === ModulePath.useCase ? Entity.useCase : Entity.testCase, 
                section: currentAIEntityData?.entity === AI_Entity.userStory ? Entity.userStory : currentAIEntityData?.entity === AI_Entity.useCase ? Entity.useCase : Entity.testCase,
                path: selectedFolder ? selectedFolder?.data?.path : ""
            }

            const response = await addModule_API(body);
            console.log(response, "response handleCreateModule")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response.status === HttpStatusCode.Ok) {

                handleGetFoldersList(activeProject, currentAIEntityData?.entity);
                handleCloseFolderCreate();
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }
    // chooose folder dialog functions end


    // save reponse function start
    const handleSaveGeneratedResponse = async (isSaveDuplicate) => {
        try {
            setIsDataSaveLoading(true);

            let body = {
                "responseId": currentAIResponseSaveData?.responseId,
                "conversationId": currentAIResponseSaveData?.conversationId,
                "ids": currentAIResponseSaveData?.ids,
                "folderPath": currentDataSavingFolder?.path,
                "saveDuplicate": isSaveDuplicate,
                // "folderPath": folderPath,
                // "folderPath": currentFolderData?.path,
            }

            if (currentAIEntityData?.entity === AI_Entity.folder) {
                body.saveUserStoryFolder = pathName === ModulePath.requirements;
                body.saveUseCaseFolder = pathName === ModulePath.useCase;
            }


            const response = await saveGeneratedResponse_API(body);
            console.log(response, "response handleSaveGeneratedResponse")

            if (response) {
                errorHandler(response, setIsDataSaveLoading);
            }

            console.log("alreadybeensaved", response?.response?.data?.message)

            if (response.status === HttpStatusCode.BadRequest && response?.response?.data?.message?.includes("already been saved")) {
                setIsDuplicateSaveResponse(true);
                console.log("alreadybeensaved")
            }

            if (response.status === HttpStatusCode.Ok) {

                if (isSaveDuplicate) {
                    setIsDuplicateSaveResponse(false);
                }

                // When AI Window is opened on particular item scope
                if (isItemChatWindow) {

                    if (currentAIEntityData?.entity === AI_Entity.userStory && pathName !== ModulePath.requirements) {
                        navigate(ModulePath.requirements)
                    }

                    if (currentAIEntityData?.entity === AI_Entity.useCase && pathName !== ModulePath.useCase) {
                        navigate(ModulePath.useCase)
                    }

                    if (currentAIEntityData?.entity === AI_Entity.testCase && pathName !== ModulePath.testCase) {
                        navigate(ModulePath.testCase)
                    }


                    // if (pathName === ModulePath.requirements) {
                    //     // no need to fetch usecase list here
                    //     navigate(ModulePath.useCase);
                    // }
                    // if (pathName === ModulePath.useCase) {
                    //     handleGetUseCaseList();
                    // }

                } else {
                    // When AI Window is opened on project/module scope

                    if (pathName === ModulePath.requirements) {
                        handleGetUserStoryList();
                    }

                    if (pathName === ModulePath.useCase) {
                        handleGetUseCaseList();
                    }
                }

                setIsUpdateFolderStructure(true);
                handleCloseSavePopup();
                setIsDuplicateSaveResponse(false);

                setTimeout(() => {
                    setCurrentAIResponseSaveData(null);
                    setCurrentFolderData(currentDataSavingFolder);
                    handleSnackbar(response?.data?.message, Severity.success);
                }, 1000);
            }



        } catch (error) {
            console.log(error, error?.message, "savegeneratedresponse_errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setIsDataSaveLoading(false);
            }, 1000);
        }
    }

    const handleGetUserStoryList = async () => {
        try {


            let body = {
                "folderPath": currentFolderData?.path,
                "projectName": activeProject?.name
            }

            const response = await getStoryList_API(body);
            console.log(response, "response handleGetUserStoryList")

            if (response) {
                errorHandler(response);
            }

            if (response.status === HttpStatusCode.Ok) {
                setUserStoryList(response.data);
            }


            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {

            }, 1000);
        }
    }

    const handleGetUseCaseList = async () => {
        try {


            let body = {
                "folderPath": currentFolderData?.path,
                "projectName": activeProject?.name
            }

            const response = await getUseCaseList_API(body);
            console.log(response, "response handleGetUseCaseList")

            if (response) {
                errorHandler(response);
            }

            if (response.status === HttpStatusCode.Ok) {
                setUseCaseList(response.data);
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {

        }
    }

    // save response fucnctino end


    const handleGetFoldersList = async (projectData, entity) => {
        try {

            let projectName = projectData?.name;

            let body = {
                projectName: projectName,
                section: entity === AI_Entity.userStory ? Entity.userStory : entity === AI_Entity.useCase ? Entity.useCase : Entity.testCase,
                // section: pathName === ModulePath.requirements ? Entity.userStory : pathName === ModulePath.useCase ? Entity.useCase : Entity.testCase
            }

            console.log(body, "bodyin handleGetFoldersList")

            const response = await getFolderStructureList_API(body);

            console.log(response, "reponse handleGetFoldersList")

            if (response) {
                errorHandler(response);
            }

            if (response.status === HttpStatusCode.Ok) {
                setFoldersList(response.data);

                if (isItemChatWindow) {
                    setCurrentDataSavingFolder(response.data[0])
                }
            }

        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {

            }, 800);
        }
    }

    // To get the folder list for current entity
    useEffect(() => {
        if (activeProject && currentAIEntityData?.entity) {
            handleGetFoldersList(activeProject, currentAIEntityData?.entity);
        }
    }, [activeProject, currentAIEntityData]);

    return (
        <Box className="flex-1 max-w-fit flex items-center gap-2 px-2 overflow-x-auto" borderRadius={theme.shape.borderRadius} bgcolor={theme.palette.background.default}
        >

            <Box className=" flex items-center gap-2 ">
                {/* <Typography fontSize={font.size.fs_5} color={theme.palette.text.secondary}>Save:</Typography> */}

                <Box className="flex-1 flex items-center gap-2 px-1 cursor-pointer" borderRadius={theme.shape.borderRadius} bgcolor={theme.palette.background.custom}
                    onClick={handleOpenSavePopup}>
                    <FolderOpenOutlined sx={{ color: theme.palette.text.secondary, fontSize: font.size.fs_5 }} />
                    <LightTooltip title={`Save Location: ${currentAIEntityData?.entity?.toLowerCase()}/${currentDataSavingFolder?.path}`}>
                        <Typography fontSize={font.size.fs_5} color={theme.palette.text.secondary} className="truncate overflow-hidden">
                            {currentAIEntityData?.entity?.toLowerCase()}/{currentDataSavingFolder?.path}
                        </Typography>
                    </LightTooltip>
                </Box>

                <LightTooltip title={isDuplicateSaveResponse ? "Save Duplicate" : "Save"}>
                    <IconButton
                        disabled={!currentAIResponseSaveData}
                        size="small"
                        variant="outlined"
                        color={isDuplicateSaveResponse ? "warning" : "secondary"}
                        onClick={() => handleSaveGeneratedResponse(isDuplicateSaveResponse ? true : false)}
                        // onClick={handleOpenSavePopup} // save location dialog
                        sx={{}}
                    >
                        {isDataSaveLoading ? <CircularProgress color="secondary" size={16} /> : <Save />}
                    </IconButton>
                </LightTooltip>
            </Box>
            {console.log(responseSaveAnchorEl, "responseSaveAnchorEl")}

            {responseSaveAnchorEl &&
                <Dialog
                    open={responseSaveAnchorEl}
                    onClose={handleCloseSavePopup}
                    sx={{
                        '& .MuiDialog-paper': {
                            width: '100%',
                            maxWidth: '400px',
                            height: '60vh'
                        }
                    }}
                    onBackdropClick={handleCloseSavePopup}
                    onEscapeKeyDown={handleCloseSavePopup}

                >
                    <Box className="flex flex-col gap-0  h-full w-full">
                        {/* header */}
                        <Box className="flex flex-col gap-2 px-4 py-2 border-b">
                            <Typography fontSize={font.size.fs_4}>Save Location</Typography>
                        </Box>

                        {/* body */}
                        <Box className="flex-1 flex flex-col gap-2  relative " onContextMenu={(e) => e.preventDefault()}>



                            <Box className="  flex h-full w-full ">

                                {/* left folder structure */}
                                <Box className="flex h-full px-4 py-2 w-full">
                                    {loading ?
                                        <Box className="  flex flex-col gap-2 h-full w-full overflow-y-auto ">
                                            {
                                                Array.from({ length: 9 }, (_, index) => (
                                                    <Skeleton variant="rounded" height={30} />
                                                ))
                                            }
                                        </Box>
                                        :
                                        <AutoSizer>
                                            {({ height, width }) => {
                                                console.log(height, width, "heightwidth")
                                                return (

                                                    <Tree
                                                        initialData={foldersList}
                                                        openByDefault={false}
                                                        width={width}
                                                        height={height}
                                                        indent={24}
                                                        rowHeight={38}
                                                    // overscanCount={1}
                                                    // paddingTop={30}
                                                    // paddingBottom={10}
                                                    // padding={25 /* sets both */}
                                                    >
                                                        {
                                                            ({ ...props }) => {
                                                                return (
                                                                    <Node
                                                                        {...props}
                                                                        selectedFolder={selectedFolder}
                                                                        setSelectedFolder={setSelectedFolder}
                                                                    />
                                                                )
                                                            }
                                                        }
                                                    </Tree>

                                                )
                                            }}
                                        </AutoSizer>}
                                </Box>
                                {/* main items list */}
                                {false && <Box className="flex flex-col px-4 py-2 h-full flex-1" bgcolor={theme.palette.background.custom}>
                                    list container
                                </Box>}
                            </Box>

                        </Box>


                        {/* footer */}
                        <Box className="flex items-center justify-end gap-2 px-4 py-2 border-t">
                            <Button color="primary" variant="outlined" disabled={loading} onClick={() => setOpenCreateFolder(!openCreateFolder)}>Add Folder</Button>
                            {openCreateFolder &&
                                <TextField
                                    placeholder="Folder Name"
                                    value={folderText}
                                    onChange={(e) => setFolderText(e.target.value)}
                                    InputProps={{
                                        endAdornment: <Box className="flex items-center gap-0">
                                            <IconButton onClick={() => handleCreateFolder()}>
                                                <Check />
                                            </IconButton>
                                            <IconButton onClick={() => handleCloseFolderCreate()}>
                                                <Close />
                                            </IconButton>
                                        </Box>
                                    }}
                                />
                            }

                            <Button color="inherit" variant="text" sx={{ ml: "auto" }} onClick={handleCloseSavePopup}>Cancel</Button>
                            <Button color="secondary" sx={{ color: theme.palette.secondary.contrastText }} variant="contained" disabled={loading || !selectedFolder} onClick={handleContinue}>Select</Button>
                        </Box>


                    </Box>
                </Dialog>}

            {/* save popup */}
            {false && responseSaveAnchorEl &&
                <ResponseSaveComponent
                    responseSaveAnchorEl={responseSaveAnchorEl}
                    handleCloseSavePopup={handleCloseSavePopup}
                    handleContinueSavePopup={handleContinueSavePopup}
                    foldersList={foldersList}
                    setFoldersList={setFoldersList}
                // entity={data?.entity}
                />}
        </Box>
    )
}

function ChooseContextComponent() {
    const {
        currentDataSavingFolder, setCurrentDataSavingFolder,
        currentAIEntityData,
        activeProject,
        isItemChatWindow,
        handleSnackbar,
        currentAIResponseSaveData, setCurrentAIResponseSaveData,
        currentFolderData, setCurrentFolderData,
        setUseCaseList, setUserStoryList,
        setIsUpdateFolderStructure,
        currentAIContextData, setCurrentAIContextData,
    } = useApplicationContext();
    const theme = useTheme();
    const pathName = useLocation()?.pathname;
    const navigate = useNavigate();
    const errorHandler = useErrorHandler();

    const [foldersList, setFoldersList] = useState([]);
    const [responseSaveAnchorEl, setResponseSaveAnchorEl] = useState(false);

    const [isDataSaveLoading, setIsDataSaveLoading] = useState(false) // when response is saving
    const [isDuplicateSaveAllowed, setIsDuplicateSaveAllowed] = useState(false); // to ask user wheather to save duplicate data, by taking confirmation from user.
    const [isDuplicateSaveResponse, setIsDuplicateSaveResponse] = useState(false); // enabled duplicated save checkbox, when duplicate response found.
    const [currentTabValue, setCurrentTabValue] = useState(AI_Entity.userStory); // To store the current tab value


    const [loading, setLoading] = useState(false);
    const [openCreateFolder, setOpenCreateFolder] = useState(false);

    const [folderText, setFolderText] = useState("");
    const [selectedFolder, setSelectedFolder] = useState(null);

    const handleChangeTab = (event, newValue) => {
        setCurrentTabValue(newValue);
        // handleGetFoldersList(activeProject, newValue?.value);
    }


    // folder choose functions start
    const handleOpenFolderPopup = (e) => {
        console.log(responseSaveAnchorEl, "handle- handleOpenSavePopup")
        setResponseSaveAnchorEl(true);
    }

    const handleCloseFolderPopup = () => {
        setResponseSaveAnchorEl(false);
        console.log(responseSaveAnchorEl, "handle- handleCloseSavePopup")
    }

    const handleContinueSavePopup = (folder) => {
        console.log(folder, responseSaveAnchorEl, "handle- folderhandleContinueSavePopup");

        setCurrentAIContextData(folder?.data);
        handleCloseFolderPopup();
    }
    // folder choose functions end



    // choose folder dialog funcitons start
    const handleCloseFolderCreate = () => {
        setOpenCreateFolder(false);
        setFolderText("")
    }

    const handleContinue = () => {
        handleContinueSavePopup(selectedFolder);
    }

    const handleCreateFolder = async () => {
        try {
            setLoading(true);

            let body = {
                name: folderText,
                "projectName": activeProject?.name,
                // "section": pathName === ModulePath.requirements ? Entity.userStory : pathName === ModulePath.useCase ? Entity.useCase : Entity.testCase, 
                section: currentAIEntityData?.entity === AI_Entity.userStory ? Entity.userStory : currentAIEntityData?.entity === AI_Entity.useCase ? Entity.useCase : Entity.testCase,
                path: selectedFolder ? selectedFolder?.data?.path : ""
            }

            const response = await addModule_API(body);
            console.log(response, "response handleCreateModule")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response.status === HttpStatusCode.Ok) {

                handleGetFoldersList(activeProject, currentAIEntityData?.entity);
                handleCloseFolderCreate();
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }
    // chooose folder dialog functions end


    // save reponse function start
    const handleSaveGeneratedResponse = async (isSaveDuplicate) => {
        try {
            setIsDataSaveLoading(true);

            let body = {
                "responseId": currentAIResponseSaveData?.responseId,
                "conversationId": currentAIResponseSaveData?.conversationId,
                "ids": currentAIResponseSaveData?.ids,
                "folderPath": currentDataSavingFolder?.path,
                "saveDuplicate": isSaveDuplicate,
                // "folderPath": folderPath,
                // "folderPath": currentFolderData?.path,
            }

            if (currentAIEntityData?.entity === AI_Entity.folder) {
                body.saveUserStoryFolder = pathName === ModulePath.requirements;
                body.saveUseCaseFolder = pathName === ModulePath.useCase;
            }


            const response = await saveGeneratedResponse_API(body);
            console.log(response, "response handleSaveGeneratedResponse")

            if (response) {
                errorHandler(response, setIsDataSaveLoading);
            }

            console.log("alreadybeensaved", response?.response?.data?.message)

            if (response.status === HttpStatusCode.BadRequest && response?.response?.data?.message?.includes("already been saved")) {
                setIsDuplicateSaveResponse(true);
                console.log("alreadybeensaved")
            }

            if (response.status === HttpStatusCode.Ok) {

                if (isSaveDuplicate) {
                    setIsDuplicateSaveResponse(false);
                }

                // When AI Window is opened on particular item scope
                if (isItemChatWindow) {

                    if (currentAIEntityData?.entity === AI_Entity.userStory && pathName !== ModulePath.requirements) {
                        navigate(ModulePath.requirements)
                    }

                    if (currentAIEntityData?.entity === AI_Entity.useCase && pathName !== ModulePath.useCase) {
                        navigate(ModulePath.useCase)
                    }

                    if (currentAIEntityData?.entity === AI_Entity.testCase && pathName !== ModulePath.testCase) {
                        navigate(ModulePath.testCase)
                    }


                    // if (pathName === ModulePath.requirements) {
                    //     // no need to fetch usecase list here
                    //     navigate(ModulePath.useCase);
                    // }
                    // if (pathName === ModulePath.useCase) {
                    //     handleGetUseCaseList();
                    // }

                } else {
                    // When AI Window is opened on project/module scope

                    if (pathName === ModulePath.requirements) {
                        handleGetUserStoryList();
                    }

                    if (pathName === ModulePath.useCase) {
                        handleGetUseCaseList();
                    }
                }

                setIsUpdateFolderStructure(true);
                handleCloseFolderPopup();
                setIsDuplicateSaveResponse(false);

                setTimeout(() => {
                    setCurrentAIResponseSaveData(null);
                    setCurrentFolderData(currentDataSavingFolder);
                    handleSnackbar(response?.data?.message, Severity.success);
                }, 1000);
            }



        } catch (error) {
            console.log(error, error?.message, "savegeneratedresponse_errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setIsDataSaveLoading(false);
            }, 1000);
        }
    }

    const handleGetUserStoryList = async () => {
        try {


            let body = {
                "folderPath": currentFolderData?.path,
                "projectName": activeProject?.name
            }

            const response = await getStoryList_API(body);
            console.log(response, "response handleGetUserStoryList")

            if (response) {
                errorHandler(response);
            }

            if (response.status === HttpStatusCode.Ok) {
                setUserStoryList(response.data);
            }


            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {

            }, 1000);
        }
    }

    const handleGetUseCaseList = async () => {
        try {


            let body = {
                "folderPath": currentFolderData?.path,
                "projectName": activeProject?.name
            }

            const response = await getUseCaseList_API(body);
            console.log(response, "response handleGetUseCaseList")

            if (response) {
                errorHandler(response);
            }

            if (response.status === HttpStatusCode.Ok) {
                setUseCaseList(response.data);
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {

        }
    }
    // save response fucnctino end




    const handleGetFoldersList = async (projectData, entity) => {
        try {

            setLoading(true);

            let projectName = projectData?.name;

            let body = {
                projectName: projectName,
                section: entity === AI_Entity.userStory ? AI_Entity.userStory : entity === AI_Entity.useCase ? AI_Entity.useCase : AI_Entity.testCase,
                // section: pathName === ModulePath.requirements ? Entity.userStory : pathName === ModulePath.useCase ? Entity.useCase : Entity.testCase
            }

            console.log(body, "bodyin handleGetFoldersList", entity, currentTabValue)

            const response = await getFolderStructureList_API(body);

            console.log(response, "reponse handleGetFoldersList")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                setFoldersList(response.data);

                if (isItemChatWindow) {
                    // setCurrentDataSavingFolder(response.data[0])
                }
            }

        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 800);
        }
    }

    // To get the folder list for current entity
    useEffect(() => {
        if (activeProject && currentTabValue) {
            handleGetFoldersList(activeProject, currentTabValue);
        }
    }, [activeProject, currentTabValue]);

    console.log(currentAIContextData, "currentAIContextData")

    return (
        <Box className="flex-1 max-w-fit flex items-center gap-2  overflow-x-auto " borderRadius={theme.shape.borderRadius} bgcolor={theme.palette.background.default}
        >

            <Box className=" flex items-center gap-2 ">


                {/* current ai context box */}
                {currentAIContextData ?
                    <LightTooltip title={`Context: ${currentAIContextData?.section}/${currentAIContextData?.path}`}>
                        <Box
                            className={`flex items-center px-1 py-0 gap-0 w-fit  overflow-hidden  shadow-sm shadow-[${theme.palette.ai.main}]`}
                            sx={{
                                border: `1px solid ${true ? theme.palette.ai.main : ""}`,
                                // background: true ? theme.palette.ai.main : "",
                                color: true ? theme.palette.ai.main : "",
                                fontSize: font.size.fs_5,
                                // boxShadow: `0px 0px 2px 5px ${theme.palette.ai.light}` 
                            }}
                            borderRadius={theme.shape.borderRadius}
                            onClick={handleOpenFolderPopup}
                        >
                            <FolderOutlined sx={{ color: "inherit", fontSize: font.size.fs_5 }} />

                            <Typography fontSize={font.size.fs_5} color={"inherit"} className=" flex-1 pl-1 truncate">
                                {currentAIContextData?.section}/{currentAIContextData?.name}
                            </Typography>

                            <LightTooltip title={`Change Context`}>
                                <IconButton onClick={handleOpenFolderPopup}>
                                    <LoopOutlined sx={{ fontSize: font.size.fs_5, color: "inherit", }} />
                                </IconButton>
                            </LightTooltip>
                            <LightTooltip title={`Close Context`}>
                                <IconButton sx={{}} onClick={(e) => { e.stopPropagation(); setCurrentAIContextData(null) }}>
                                    <Close sx={{ fontSize: font.size.fs_5, color: "inherit", }} />
                                </IconButton>
                            </LightTooltip>
                        </Box>
                    </LightTooltip>
                    :
                    <LightTooltip title={`Select Context`}>
                        <IconButton onClick={handleOpenFolderPopup}>
                            <AddCircleOutline />
                        </IconButton>
                    </LightTooltip>
                }


            </Box>


            {responseSaveAnchorEl &&
                <Dialog
                    open={responseSaveAnchorEl}
                    onClose={handleCloseFolderPopup}
                    sx={{
                        '& .MuiDialog-paper': {
                            width: '100%',
                            maxWidth: '400px',
                            height: '60vh'
                        }
                    }}
                    onBackdropClick={handleCloseFolderPopup}
                    onEscapeKeyDown={handleCloseFolderPopup}
                    onSubmit={handleContinue}

                >
                    <Box className="flex flex-col gap-0  h-full w-full">
                        {/* header */}
                        <Box className="flex flex-col gap-2 px-4 py-2 border-b">
                            <Typography fontSize={font.size.fs_4}>Select Context</Typography>
                        </Box>

                        {/* current page */}
                        <Box className="flex  gap-2 px-4 py-0  shadow">
                            <Tabs
                                variant="scrollable"
                                value={currentTabValue}
                                onChange={handleChangeTab}
                            >
                                {
                                    [{ label: Entity_Label.userStory, value: AI_Entity.userStory }, { label: Entity_Label.useCase, value: AI_Entity.useCase }, { label: Entity_Label.testCase, value: AI_Entity.testCase }]
                                        ?.map((item, index) => (
                                            <Tab key={index} label={item.label} value={item?.value} />
                                        ))
                                }
                            </Tabs>

                        </Box>


                        {/* body */}
                        <Box className="flex-1 flex flex-col gap-2  relative " onContextMenu={(e) => e.preventDefault()}>


                            {/* folder container */}
                            <Box className="  flex h-full w-full ">

                                {/* left folder structure */}
                                <Box className="flex-1 flex h-full px-4 py-2 w-full">
                                    {loading ?
                                        <Box className=" flex-1 flex flex-col gap-2  overflow-y-auto ">
                                            {
                                                Array.from({ length: 8 }, (_, index) => (
                                                    <Skeleton variant="rounded" height={30} />
                                                ))
                                            }
                                        </Box>
                                        :
                                        <AutoSizer>
                                            {({ height, width }) => {
                                                console.log(height, width, "heightwidth")
                                                return (

                                                    <Tree
                                                        initialData={foldersList}
                                                        openByDefault={false}
                                                        width={width}
                                                        height={height}
                                                        indent={24}
                                                        rowHeight={38}
                                                    // overscanCount={1}
                                                    // paddingTop={30}
                                                    // paddingBottom={10}
                                                    // padding={25 /* sets both */}
                                                    >
                                                        {
                                                            ({ ...props }) => {
                                                                return (
                                                                    <Node
                                                                        {...props}
                                                                        selectedFolder={selectedFolder}
                                                                        setSelectedFolder={setSelectedFolder}
                                                                    />
                                                                )
                                                            }
                                                        }
                                                    </Tree>

                                                )
                                            }}
                                        </AutoSizer>}
                                </Box>
                                {/* main items list */}
                                {false && <Box className="flex flex-col px-4 py-2 h-full flex-1" bgcolor={theme.palette.background.custom}>
                                    list container
                                </Box>}
                            </Box>

                        </Box>


                        {/* footer */}
                        <Box className="flex items-center justify-end gap-2 px-4 py-2 border-t">
                            {/* <Button color="primary" variant="outlined" disabled={loading} onClick={() => setOpenCreateFolder(!openCreateFolder)}>Add Folder</Button> */}
                            {/* {openCreateFolder &&
                                <TextField
                                    placeholder="Folder Name"
                                    value={folderText}
                                    onChange={(e) => setFolderText(e.target.value)}
                                    InputProps={{
                                        endAdornment: <Box className="flex items-center gap-0">
                                            <IconButton onClick={() => handleCreateFolder()}>
                                                <Check />
                                            </IconButton>
                                            <IconButton onClick={() => handleCloseFolderCreate()}>
                                                <Close />
                                            </IconButton>
                                        </Box>
                                    }}
                                />
                            } */}

                            <Button color="inherit" variant="text" sx={{ ml: "auto" }} onClick={handleCloseFolderPopup}>Cancel</Button>
                            <Button color="secondary" sx={{ color: theme.palette.secondary.contrastText }} variant="contained" disabled={loading || !selectedFolder} onClick={handleContinue}>Select</Button>
                        </Box>


                    </Box>
                </Dialog>}

        </Box>
    )
}