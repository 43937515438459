import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, CircularProgress, styled, Tooltip, tooltipClasses, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { font } from "../../styles/font";

export const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} title={<Typography fontSize={font.size.fs_5} color="textPrimary">{props.title}</Typography>} disableHoverListener={props.disableHoverListener || !props.title} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.text.primary,
        // color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[2],
        fontSize: font.size.fs_6,
        borderRadius: '7px',
    },
}));

const CustomAccordion = styled(Accordion)(({ theme }) => ({
    border: `1px solid ${grey[400]}`,
    borderRadius: theme.shape.borderRadius,
    "&:before": {
        display: "none",
    },
}))
export const CustomAccordionComponent = (props) => <CustomAccordion disableGutters elevation={0} {...props} />

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    // border: `1px solid ${grey[400]}`,
    borderRadius: theme.shape.borderRadius,
    "&:before": {
        display: "none",
    },
    "& .MuiAccordionSummary-content": {
        margin: 0,
    },
    "& .MuiAccordionSummary-expandIconWrapper": {
        color: theme.palette.primary.main,
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
        minHeight: 0,
    },
    "& .MuiAccordionSummary-root.Mui-expanded .MuiAccordionSummary-content": {
        margin: 0,
    },
    "& .MuiAccordionSummary-root.Mui-expanded .MuiAccordionSummary-expandIconWrapper": {
        color: theme.palette.primary.main,
    },
}));
export const CustomAccordionComponentSummary = (props) => <CustomAccordionSummary disableGutters elevation={0} {...props} />

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    // border: `1px solid ${grey[400]}`,
    borderRadius: theme.shape.borderRadius,
    "&:before": {
        display: "none",
    },
}));
export const CustomAccordionComponentDetails = (props) => <CustomAccordionDetails disableGutters elevation={0} {...props} />

const LoadingButton = styled(Button)(({ theme }) => ({
    // color: theme.palette.primary.contrastText,
    // backgroundColor: theme.palette.primary.main,
    // "&:hover": {
    //     backgroundColor: theme.palette.primary.dark,
    // },
}));
export const CustomLoadingButton = (props) => <LoadingButton disableElevation disabled={props?.loading || props.disabled} {...props} startIcon={props?.loading ? <CircularProgress size={16} color="inherit" /> : props.startIcon} />






const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
        ...theme.applyStyles('dark', {
            backgroundColor: '#30404d',
        }),
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
        ...theme.applyStyles('dark', {
            background: 'rgba(57,75,89,.5)',
        }),
    },
    ...theme.applyStyles('dark', {
        boxShadow: '0 0 0 1px rgb(16 22 26 / 40%)',
        backgroundColor: '#394b59',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))',
    }),
}));

const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&::before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.primary.dark,
    },
}));

// Inspired by blueprintjs
export function CustomCheckbox(props) {
    return (
        <Checkbox
            sx={{ '&:hover': { bgcolor: 'transparent' } }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            inputProps={{ 'aria-label': 'Checkbox demo' }}
            {...props}
        />
    );
}