import { Description, Home, PlayArrowRounded, Store } from "@mui/icons-material";
import { Dashboard_Entity, Entity, Entity_Label, ModulePath, StoryStatus } from "./ApplicationVariables";
import { blue, brown, green } from "@mui/material/colors";

export const ApplicationPages = [
    {
        label: "Home",
        path: ModulePath?.home,
        icon: <Home />,
        activeIcon: <Home />,
        onClick: () => { }
    },
    {
        label: "Story Map",
        path: ModulePath?.story,
        icon: <Description />,
        activeIcon: <Description />,
        onClick: () => { }
    },
    {
        label: "Marketplace",
        path: ModulePath?.marketplace,
        icon: <Store />,
        activeIcon: <Store />,
        onClick: () => { }
    },
    {
        label: "Execution",
        path: ModulePath?.execution,
        icon: <PlayArrowRounded />,
        activeIcon: <PlayArrowRounded />,
        onClick: () => { }
    },
];

export const STATUS_COLOR = {
    [StoryStatus.pending?.toLowerCase()]: {
        color: brown[700],
        backgroundColor: brown[50]
    },
    [StoryStatus.inprogress?.toLowerCase()]: {
        color: blue[700],
        backgroundColor: blue[50]
    },
    [StoryStatus.completed?.toLowerCase()]: {
        color: green[700],
        backgroundColor: green[50]
    }
}

export const DashboardPages = [
    {
        label : Dashboard_Entity.project?.label, 
        value : Dashboard_Entity.project?.value
    },
    {
        label : Dashboard_Entity.userStory?.label,
        value : Dashboard_Entity.userStory?.value
    },
    {
        label : Dashboard_Entity.useCase?.label,
        value : Dashboard_Entity.useCase?.value
    },
    {
        label : Dashboard_Entity.testCase?.label,
        value : Dashboard_Entity.testCase?.value
    },
    {
        label : Dashboard_Entity.teams?.label,
        value : Dashboard_Entity.teams?.value
    },
    {
        label : Dashboard_Entity.traceability?.label,
        value : Dashboard_Entity.traceability?.value
    },
    {
        label : Dashboard_Entity.cost?.label,
        value : Dashboard_Entity.cost?.value
    }, 
    {
        label : Dashboard_Entity.userPersona?.label,
        value : Dashboard_Entity.userPersona?.value
    }, 
    {
        label : Dashboard_Entity.qualityAssurance?.label,
        value : Dashboard_Entity.qualityAssurance?.value
    }
 

]