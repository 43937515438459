import { Attachment, AutoAwesome, AutoAwesomeOutlined } from "@mui/icons-material"
import { Box, Checkbox, CircularProgress, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery, useTheme } from "@mui/material"
import { amber, grey } from "@mui/material/colors"
import { forwardRef, useMemo, useState } from "react"
import { TableVirtuoso } from "react-virtuoso"
import ViewUserStoryDrawer from "../drawer/ViewUserStory"
import { useApplicationContext } from "../../context/ApplicationContext"
import { font } from "../../styles/font"
import { LightTooltip } from "../styled/CustomComponent"
import { FolderType } from "../../constant/ApplicationVariables"
import dayjs from "dayjs"
import UseCaseCard from "./TestCaseCard"

const TableComponents = {
    Scroller: forwardRef((props, ref) => <TableContainer component={Paper} style={{ overflowX: "auto", overflowY: "scroll" }} {...props} ref={ref} />),
    Table: (props) => <Table {...props} style={{ borderCollapse: 'separate' }} />,
    TableHead: TableHead,
    TableRow: (props) => <TableRow {...props} />,
    TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
    TableCell: (props) => <TableCell {...props} sx={{ px: "10rem" }} />,
}

export default function TestCaseGrid({ dataList, isLoading }) {
    const {
        openLinkTagDrawer, setOpenLinkTagDrawer,
        currentUseCaseData, setCurrentUseCaseData,
        selectedUseCaseList, setSelectedUseCaseList,
        setOpenEditUseCaseDialog
    } = useApplicationContext();
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.up("sm") && theme.breakpoints.down("md"));
    const isLaptop = useMediaQuery(theme.breakpoints.up("md") && theme.breakpoints.down("lg"));
    const isDesktop = useMediaQuery(theme.breakpoints.up("lg") && theme.breakpoints.down("xl"));
    const isLargeDisplay = useMediaQuery(theme.breakpoints.up("xl"));

    const [openUserStoryDrawer, setOpenUserStoryDrawer] = useState(false);

    const handleOpenUserStory = (item) => {
        setCurrentUseCaseData(item);
        setOpenEditUseCaseDialog(true);
    }

    const handleCloseUserStory = () => {
        setOpenEditUseCaseDialog(false);
        setCurrentUseCaseData(null);
    }

    const handleChangeCheckboxAll = (e) => {
        if (e.target.checked) {
            setSelectedUseCaseList(dataList);
        } else {
            setSelectedUseCaseList([]);
        }
    }

    const handleChangeCheckbox = (e, item) => {
        if (e.target.checked) {
            setSelectedUseCaseList([...selectedUseCaseList, item]);
        } else {
            setSelectedUseCaseList(selectedUseCaseList?.filter((story) => story?.customId !== item?.customId));
        }
    }

    const handleOpenLinkTag = (data) => {
        setCurrentUseCaseData(data)
        setOpenLinkTagDrawer(true)
    }

    return (
        <Box className="  flex flex-col w-full flex-1">
            {
                false ?
                    <Box className="w-full h-full flex justify-center items-center">
                        <CircularProgress />
                    </Box>
                    :
                    <Box className="w-full h-fit flex  flex-wrap gap-6 pl-0  py-0 ">
                        {
                            dataList?.length > 0 && dataList?.map((storyData, index) => {
                                return (
                                    <Box
                                        key={index}
                                        className={` overflow-hidden rounded-lg border hover:shadow-2xl  backdrop-blur-md transition-all ease-in-out duration-300 h-36 
                                        min-w-[220px]  ${isMobile ? "w-full" : isTablet ? "w-[45%]" : isLaptop ? "w-[29.5%]" : isDesktop ? "w-[22%]" : "w-[23%]"} `}
                                        // sx={{ border: `2px solid transparent`, "&:hover": { border: `2px solid ${theme.palette.primary.main}` } }}
                                        // bgcolor={theme.palette.common.white}
                                        bgcolor={theme.palette.card.main}
                                        // onClick={() => handleOpenProject(project)}
                                        sx={{ border: `1px solid transparent`, "&:hover": { border: `1px solid ${theme.palette.primary.main}` } }}
                                    >
                                        <UseCaseCard
                                            item={storyData}
                                            handleClick={() => handleOpenUserStory(storyData)}
                                            handleChangeCheckbox={handleChangeCheckbox}
                                            handleOpenLinkTag={handleOpenLinkTag}
                                        />
                                    </Box>
                                )
                            })
                        }
                    </Box>

            }


            {openUserStoryDrawer && currentUseCaseData && <ViewUserStoryDrawer open={openUserStoryDrawer} handleClose={handleCloseUserStory} />}
        </Box>
    )
}



