import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import { HttpStatusCode } from 'axios';
import { login_API, register_API } from '../../../api/controller/authController';
import { useApplicationContext } from '../../../context/ApplicationContext';
import useErrorHandler from '../../../api/errorHandler/useErrorHandler';
import { useNavigate } from 'react-router-dom';
import { ModulePath, Severity, VerificationStatus, VerificationType } from '../../../constant/ApplicationVariables';
import validator from "validator";
import { useState } from 'react';
import { useEffect } from 'react';
import { CircularProgress, IconButton, InputAdornment, useTheme } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { font } from '../../../styles/font';
import MainContainer, { AuthCardComponent } from '../container';

const Card = styled(MuiCard)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    width: '100%',
    py: theme.spacing(4),
    gap: theme.spacing(4),
    margin: 'auto',
    borderRadius: theme.shape.borderRadius,
    // background: theme.palette.background.paper,
    background: "transparent",
    boxShadow: "none",
    [theme.breakpoints.up('sm')]: {
        // maxWidth: '450px',
    },
    // boxShadow:
    //     'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
    // ...theme.applyStyles('dark', {
    //     boxShadow:
    //         'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
    // }),
}));


export default function RegisterContainer() {
    const { handleSnackbar, profileDetails, setProfileDetails, accountVerificationType, setAccountVerificationType, handleStoreUserData } = useApplicationContext();
    const errorHandler = useErrorHandler();
    const navigate = useNavigate();
    const theme = useTheme();

    const [loginData, setLoginData] = useState(null);
    const [error, setError] = useState(null);


    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [loading, setLoading] = useState(false);


    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent default form submission behavior
        validateInputs(); // Call your validation function
    };


    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }


    const handleChangeValue = (event) => {
        const { name, value } = event.target;

        setLoginData((prevData) => ({
            ...prevData,
            [name]: value
        }))
    }

    const validateInputs = () => {
        let isValid = true;
        const errorObject = {};


        if (!loginData?.firstName || loginData?.firstName?.length < 3) {
            errorObject.firstName = "First name must be at least 3 characters long.";
            isValid = false;
        }

        if (!loginData?.lastName || loginData?.lastName?.length < 3) {
            errorObject.lastName = "Last name must be at least 3 characters long.";
            isValid = false;
        }

        if (loginData?.phone && !validator.isMobilePhone(loginData?.phone)) {
            errorObject.phone = "Please enter a valid phone number.";
            isValid = false;
        }

        if (!loginData?.companyName || loginData?.companyName?.length < 3) {
            errorObject.companyName = "Company name must be at least 3 characters long.";
            isValid = false;
        }

        if (!loginData?.email || !validator.isEmail(loginData?.email)) {
            errorObject.email = "Please enter a valid email address.";
            isValid = false;
        }

        if (!loginData?.password || loginData?.password?.length < 6) {
            errorObject.password = "Password must be at least 6 characters long.";
            isValid = false;
        }

        if (!loginData?.confirmPassword || loginData?.confirmPassword?.length < 6) {
            errorObject.confirmPassword = "Confirm Password must be at least 6 characters long.";
            isValid = false;
        }

        if (loginData?.password && (!loginData?.confirmPassword || loginData?.confirmPassword?.length < 6)) {
            errorObject.confirmPassword = "Confirm Password must be at least 6 characters long.";
            isValid = false;
        }

        if (loginData?.password && loginData?.confirmPassword && loginData?.password !== loginData?.confirmPassword) {
            errorObject.confirmPassword = "Password and Confirm Password must be same.";
            isValid = false;
        }


        setError(errorObject);

        return isValid && handleContinue();
    };

    const handleContinue = async () => {

        try {
            setLoading(true);

            let body = {
                firstName: loginData?.firstName,
                lastName: loginData?.lastName,

                email: loginData?.email,
                password: loginData?.password,
                phone: loginData?.phone, // optional

                companyName: loginData?.companyName
            }
            console.log(body, "body in register")

            const response = await register_API(body);

            console.log(response, " response in register")

            if (response) {
                errorHandler(response);
            }

            if (response.status === HttpStatusCode.Ok) {
                handleStoreUserData(response.data);
                if (response.data.status === VerificationStatus.Verified) {
                    // handleStoreUserData(response.data);
                    handleSnackbar("User signed in successfully", Severity.success);
                    setTimeout(() => {
                        navigate(ModulePath.projects);
                        // navigate(0);
                    }, 1100);
                }


                if (response.data.status === VerificationStatus.Unverified) {
                    // setAccountVerificationType(VerificationType.Account);
                    handleSnackbar("Please verify your account", Severity.info);
                    setTimeout(() => {
                        navigate(ModulePath.verifyAccount, { state: { email: loginData.email, type: VerificationType.Account, fromPath: ModulePath.register }, });
                    }, 1100);
                }
            }

            if (response.status === HttpStatusCode.BadRequest && response?.response.data?.message?.includes("email already exists")) {
                setTimeout(() => {
                    navigate(ModulePath.login);
                }, 1100);
            }



        } catch (error) {
            console.log(error, error?.message, "login errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    // console.log(error, loginData, "error loginData");


    return (
        <Box className=" flex flex-col gap-0 relative h-full w-full box-border overflow-hidden" >
            <MainContainer >
                <AuthCardComponent className="flex-1 gap-10 ">

                    {/* header container */}
                    <Box className="flex flex-col   ">

                        <Typography
                            sx={{ width: '100%', }}
                            color="textPrimary"
                            fontSize={font.size.fs_1_5}
                            fontWeight="bold"
                        >
                            Create an account
                        </Typography>

                        <Box className="flex items-center gap-2">
                            <Typography color={theme.palette.text.ternary} fontSize={font.size.fs_5} className="flex items-center justify-center gap-1 ">
                                Already have an account?{' '}

                                <Typography color={theme.palette.text.primary} fontSize={"inherit"} fontWeight="bold" className="underline cursor-pointer " onClick={() => navigate(ModulePath.login)}>
                                    Login
                                </Typography>

                            </Typography>
                        </Box>
                    </Box>

                    {/* form container */}
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        noValidate
                        className="flex flex-col w-full gap-10     "

                    >

                        {/* input container */}
                        <Box
                            className="flex flex-col w-full gap-6 py-0  "

                        >

                            <Box className="flex items-center gap-6">
                                <FormControl>
                                    {/* <FormLabel htmlFor="email">Email</FormLabel> */}
                                    <TextField
                                        label="First Name"
                                        error={error?.firstName && error?.firstName}
                                        helperText={error?.firstName && error?.firstName}
                                        id="firstName"
                                        type="text"
                                        name="firstName"
                                        placeholder="Jane"
                                        autoFocus
                                        required
                                        fullWidth
                                        variant="filled"
                                        color={error?.firstName ? 'error' : 'primary'}
                                        value={loginData?.firstName}
                                        onChange={handleChangeValue}
                                    />
                                </FormControl>

                                <FormControl>
                                    {/* <FormLabel htmlFor="email">Email</FormLabel> */}
                                    <TextField
                                        label="Last Name"
                                        error={error?.lastName && error?.lastName}
                                        helperText={error?.lastName && error?.lastName}
                                        id="lastName"
                                        type="text"
                                        name="lastName"
                                        placeholder="Jr"
                                        autoFocus
                                        required
                                        fullWidth
                                        variant="filled"
                                        color={error?.lastName ? 'error' : 'primary'}
                                        value={loginData?.lastName}
                                        onChange={handleChangeValue}
                                    />
                                </FormControl>
                            </Box>

                            <FormControl>
                                {/* <FormLabel htmlFor="email">Email</FormLabel> */}
                                <TextField
                                    label="Email"
                                    error={error?.email && error?.email}
                                    helperText={error?.email && error?.email}
                                    id="email"
                                    type="email"
                                    name="email"
                                    placeholder="your@email.com"
                                    autoComplete="email"
                                    autoFocus
                                    required
                                    fullWidth
                                    variant="filled"
                                    color={error?.email ? 'error' : 'primary'}
                                    value={loginData?.email}
                                    onChange={handleChangeValue}
                                />
                            </FormControl>

                            <FormControl>
                                {/* <FormLabel htmlFor="email">Email</FormLabel> */}
                                <TextField
                                    label="Phone Number"
                                    error={error?.phone && error?.phone}
                                    helperText={error?.phone && error?.phone}
                                    id="phone"
                                    type="tel"
                                    name="phone"
                                    placeholder=""
                                    fullWidth
                                    variant="filled"
                                    color={error?.phone ? 'error' : 'primary'}
                                    value={loginData?.phone}
                                    onChange={handleChangeValue}
                                />
                            </FormControl>

                            <FormControl>
                                {/* <FormLabel htmlFor="email">Email</FormLabel> */}
                                <TextField
                                    label="Company Name"
                                    error={error?.companyName && error?.companyName}
                                    helperText={error?.companyName && error?.companyName}
                                    id="companyName"
                                    type="text"
                                    name="companyName"
                                    placeholder="Jr"
                                    autoFocus
                                    required
                                    fullWidth
                                    variant="filled"
                                    color={error?.companyName ? 'error' : 'primary'}
                                    value={loginData?.companyName}
                                    onChange={handleChangeValue}
                                />
                            </FormControl>
                            
                            <Box className="flex items-center gap-6">
                                <FormControl>
                                    {/* <FormLabel htmlFor="password">Password</FormLabel> */}
                                    <TextField
                                        label="Password"
                                        error={error?.password && error?.password}
                                        helperText={error?.password && error?.password}
                                        name="password"
                                        placeholder="••••••"
                                        type={showPassword ? "text" : "password"}
                                        id="password"
                                        autoComplete="current-password"
                                        autoFocus
                                        required
                                        fullWidth
                                        variant="filled"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        color={error?.password ? 'error' : 'primary'}
                                        value={loginData?.password}
                                        onChange={handleChangeValue}
                                    />
                                </FormControl>

                                <FormControl>
                                    {/* <FormLabel htmlFor="password">Password</FormLabel> */}
                                    <TextField
                                        label="Confirm Password"
                                        error={error?.confirmPassword && error?.confirmPassword}
                                        helperText={error?.confirmPassword && error?.confirmPassword}
                                        name="confirmPassword"
                                        placeholder="••••••"
                                        type={showConfirmPassword ? "text" : "password"}
                                        id="confirmPassword"
                                        autoComplete="current-password"
                                        autoFocus
                                        required
                                        fullWidth
                                        variant="filled"
                                        // slotProps={{
                                        //     input: {
                                        //         inputProps: {
                                        //             endAdornment: (
                                        //                 <InputAdornment position="end">
                                        //                     <IconButton
                                        //                         aria-label="toggle password visibility"
                                        //                         onClick={handleClickShowConfirmPassword}
                                        //                         edge="end"
                                        //                     >
                                        //                         {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                        //                     </IconButton>
                                        //                 </InputAdornment>
                                        //             ),
                                        //         }
                                        //     }
                                        // }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowConfirmPassword}
                                                        edge="end"
                                                    >
                                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        color={error?.confirmPassword ? 'error' : 'primary'}
                                        value={loginData?.confirmPassword}
                                        onChange={handleChangeValue}
                                    />
                                </FormControl>
                            </Box>
                        </Box>
                        {/* <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        /> */}
                        {/* <ForgotPassword open={open} handleClose={handleClose} /> */}
                        <Button
                            size="medium"
                            type="submit"
                            fullWidth
                            color="secondary"
                            variant="contained"
                            disabled={loading}
                            onClick={validateInputs}
                            sx={{ color: '#fff' }}
                        >
                            {loading ? <CircularProgress size={20} /> : "Continue"}
                        </Button>

                    </Box>


                </AuthCardComponent>
            </MainContainer>
        </Box>
    );
}