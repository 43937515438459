import { Add, ArrowDropDown, Clear, ClearAll, Close, Delete, DeleteOutline, Description, DescriptionOutlined, DriveFileMove, Edit, FileCopyOutlined, FileDownloadOutlined, FileUploadOutlined, Filter, FilterAlt, FilterAltOffOutlined, FilterAltOutlined, FilterList, FilterListOff, FilterOutlined, Folder, FolderOutlined, FolderSpecialOutlined, GridView, KeyboardArrowDown, Label, LabelOutlined, ListAlt, More, MoreHoriz, MoreVert, NavigateNext, Pages, PagesOutlined, PlayArrow, Search, TrackChanges } from '@mui/icons-material';
import { Avatar, Badge, Box, Breadcrumbs, Button, Chip, CircularProgress, debounce, Divider, Fab, FormControl, Icon, IconButton, InputAdornment, InputLabel, List, MenuItem, Paper, Popover, Select, Skeleton, styled, Tab, Tabs, TextField, ToggleButton, ToggleButtonGroup, toggleButtonGroupClasses, Typography, useMediaQuery, useTheme } from '@mui/material'
import { font } from '../../../styles/font';
import { useEffect, useState } from 'react';
import { blue, purple } from '@mui/material/colors';
import StoryCard from '../../story/StoryCard';
import { useApplicationContext } from '../../../context/ApplicationContext';
import { LightTooltip } from '../../styled/CustomComponent';
import UserStoryList from '../../story/UserStoryList';
import CreateStoryComponent from '../../story/CreateStoryComponent';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import useErrorHandler from '../../../api/errorHandler/useErrorHandler';
import { HttpStatusCode } from 'axios';
import { Entity, Entity_Label, FolderType, ModulePath, Severity, StoryStatus } from '../../../constant/ApplicationVariables';
import { addStory_API, deleteStory_API, getStoryList_API, getStoryListAll_API, getStoryPropertiesList_API, updateStory_API } from '../../../api/controller/StoryController';
import DeleteStoryDialog from '../../dialog/DeleteProjectItemDialog';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecialOutlined';
import EditStoryComponent from '../../story/EditStoryComponent';
import LinkTagDrawer from '../../drawer/LinkTagDrawer';
import UserStoryGrid from '../../story/UserStoryGrid';

import GridViewTwoToneIcon from '@mui/icons-material/GridViewTwoTone';
import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone';
import ListTwoToneIcon from '@mui/icons-material/ListTwoTone';
import { useLocation } from 'react-router-dom';
import UseCaseGrid from '../../useCase/UseCaseGrid';
import UseCaseList from '../../useCase/UseCaseList';
import EditUseCaseComponent from '../../useCase/EditUseCaseComponent';
import { addUseCase_API, deleteUseCase_API, getUseCaseList_API, getUseCaseListAll_API, getUseCasePropertiesList_API, updateUseCase_API } from '../../../api/controller/UseCaseController';
import CreateUseCaseComponent from '../../useCase/CreateUseCaseComponent';
import DeleteProjectItemDialog from '../../dialog/DeleteProjectItemDialog';
import FilterItemDrawer from '../../drawer/FilterItemDrawer';
import dayjs from 'dayjs';
import CreateTestCaseComponent from '../../testCase/CreateTestCaseComponent';
import EditTestCaseComponent from '../../testCase/EditTestCaseComponent';
import TestCaseGrid from '../../testCase/TestCaseGrid';
import TestCaseList from '../../testCase/TestCaseList';
import { getTestCaseList_API, getTestCaseListAll_API, getTestCasePropertiesList_API } from '../../../api/controller/TestCaseController';
import { DashboardCustomIcon, FolderCustomIcon, FolderIcon, PageCustomIcon, ProjectCustomIcon, ProjectIcon, TestCaseCustomIcon, TestCaseIcon, UseCaseCustomIcon, UseCaseIcon, UserStoryCustomIcon, UserStoryIcon } from '../../styled/CustomIcons';
import DashboardList from '../../dashboard/DashboardList';
import DashboardContainerComponent from '../../dashboard';
import { DesktopDatePicker } from '@mui/x-date-pickers';

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))({
    height: "0.6rem",
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',

    },
    '& .MuiTabs-indicatorSpan': {
        // maxWidth: 40,
        width: '100%',
        backgroundColor: "transparent",
    },
});

const StyledTab = styled((props) => <Tab {...props} />)(
    ({ theme }) => ({
        fontSize: font.size.fs_4,
        marginRight: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        transition: "all 0.3s ease-in-out",
        color: theme.palette.primary.main,
        backgroundColor: "transparent",
        '&.Mui-selected': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.secondary.main,
        },
        '&.Mui-focusVisible': {
            backgroundColor: theme.palette.secondary.main,
        },
    }),
);

class ACTIVE_PROJECT_TABS {
    static dashboard = 0;
    static userStory = 1;
    static useCase = 2;
    static testCase = 3;
}


class Filters {
    static status = "status";
    static createdAt = "createdAt";
    static createdBy = "createdBy";
    static assignedTo = "assignedTo";
    static priority = "priority";
    static moduleName = "moduleName";
    static projectName = "projectName";
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .${toggleButtonGroupClasses.grouped}`]: {
        margin: theme.spacing(0.5),
        border: 0,
        borderRadius: theme.shape.borderRadius,
        [`&.${toggleButtonGroupClasses.disabled}`]: {
            border: 0,
        },
    },
    [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
    {
        marginLeft: -1,
        borderLeft: '1px solid transparent',
    },
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
    padding: theme.spacing(0.5),


    // '&:hover': {
    //     backgroundColor: theme.palette.secondary.main,
    // },
    // '&.Mui-selected': {
    //     backgroundColor: theme.palette.secondary.main,
    // },

}));



export default function ActiveProjectContainer() {
    const {
        openCreateStoryDialog, setOpenCreateStoryDialog,
        openEditStoryDialog, setOpenEditStoryDialog,
        openDeleteStoryDialog, setOpenDeleteStoryDialog,
        currentFolderData, setCurrentFolderData,
        currentUserStoryData, setCurrentUserStoryData,
        userStoryList, setUserStoryList,
        userStoryPropertiesData, setUserStoryPropertiesData,
        handleSnackbar,
        selectedUserStoryList, setSelectedUserStoryList,
        activeProject,
        isUserStoryLoading, setIsUserStoryLoading,
        openLinkTagDrawer, setOpenLinkTagDrawer,
        isGridViewEnabled, setIsGridViewEnabled,
        setIsUpdateFolderStructure,
        openCreateUseCaseDialog, setOpenCreateUseCaseDialog,
        openEditUseCaseDialog, setOpenEditUseCaseDialog,
        openDeleteUseCaseDialog, setOpenDeleteUseCaseDialog,
        currentUseCaseData, setCurrentUseCaseData,
        useCaseList, setUseCaseList,
        useCasePropertiesData, setUseCasePropertiesData,
        selectedUseCaseList, setSelectedUseCaseList,
        isActiveProjectContainerLoading, setIsActiveProjectContainerLoading,
        isItemChatWindow, setIsItemChatWindow,
        openItemFilterDrawer, setOpenItemFilterDrawer,
        folderStructureList,
        activeDashboard,
        setActiveDashboard
    } = useApplicationContext();
    const theme = useTheme();
    const errorHandler = useErrorHandler();
    const pathName = useLocation()?.pathname;

    const [breadCrumbsList, setBreadCrumbsList] = useState([]); // to store the location of folder/ item -old
    const [locationPathList, setLocationPathList] = useState([]); // to store the current item / folder location
    const [currentTab, setCurrentTab] = useState(1);  // to change the dashboard, list tab - old
    const [isLoading, setIsLoading] = useState(false); // loading for locationpathlist 
    const [tabsList, setTabsList] = useState([
        { label: "View List", value: ACTIVE_PROJECT_TABS.userStory, disabled: false },
        { label: "View List", value: ACTIVE_PROJECT_TABS.useCase, disabled: false },
        { label: "Dashboard", value: ACTIVE_PROJECT_TABS.dashboard, disabled: true },
    ]); // to change the dashboard, list tab - old


    function handleBreadCrumb() {
        let array = [];

        // when userstory is opened
        if (currentUserStoryData && openEditStoryDialog) {
            array.push(currentUserStoryData)
        }

        // when usecase is opened
        if (currentUseCaseData && openEditUseCaseDialog) {
            array.push(currentUseCaseData)
        }


        let dataObject = currentFolderData;
        array.push(dataObject);

        while (dataObject?.level > 0) {
            dataObject = dataObject?.parent;
            array.push(dataObject);
        }



        // if (dataObject) {
        //     array.push(
        //         <LightTooltip title={<Typography fontSize={font.size.fs_5} className="capitalize">{`${dataObject?.data?.type}: ${dataObject?.data?.name}`}</Typography>}>
        //             <Typography
        //                 fontSize={font.size.fs_4}
        //                 className="cursor-pointer hover:underline"
        //                 // fontWeight={projectActive && "bold"}
        //                 key="1"
        //                 // sx={{ opacity: projectActive ? 1 : 0.8 }}
        //                 onClick={() => { }}>
        //                 {dataObject?.data?.name}
        //             </Typography>
        //         </LightTooltip>
        //     )
        // }

        // while (dataObject?.level > 0) {
        //     dataObject = dataObject?.parent;
        //     array.push(
        //         <LightTooltip title={<Typography fontSize={font.size.fs_5} className="capitalize">{`${dataObject?.data?.type}: ${dataObject?.data?.name}`}</Typography>}>
        //             <Typography
        //                 fontSize={font.size.fs_4}
        //                 className="cursor-pointer hover:underline"
        //                 // fontWeight={moduleActive && "bold"}
        //                 key="2"
        //                 // sx={{ opacity: moduleActive ? 1 : 0.8 }}
        //                 onClick={() => { }}>
        //                 {dataObject?.data?.name}
        //             </Typography>
        //         </LightTooltip>
        //     )

        // }

        setBreadCrumbsList(array?.reverse())
    }

    function handleLocationPath() {
        setIsLoading(true);
        const pathArray = currentFolderData?.path.split("/");

        let locationPathList = [
            // {
            //     name: activeProject?.name,
            //     type: Entity.project
            // },
            {
                name: pathName === ModulePath.requirements ? Entity_Label.userStory : pathName === ModulePath.useCase ? Entity_Label.useCase : pathName === ModulePath.testCase ? Entity_Label.testCase : Entity_Label.dashboard,
                type: "page"
            },
        ];

        console.log(pathArray, "pathArray");
        console.log(locationPathList, "locationPathList")

        if (pathName === ModulePath.requirements || pathName === ModulePath.useCase || pathName === ModulePath.testCase) {
            if (pathArray?.length > 0) {
                locationPathList = locationPathList.concat(pathArray?.map((item) => ({
                    name: item,
                    type: Entity.folder,
                })));
            }
        } else if (pathName === ModulePath.dashboard) {
            // locationPathList?.push({
            //     name: Entity_Label.dashboard,
            //     type: Entity.dashboard
            // })

            // active dashboard
            locationPathList?.push({
                name: activeDashboard?.value,
                type: Entity.dashboard
            })
        }


        if (openEditStoryDialog && currentUserStoryData) {
            locationPathList?.push({
                name: currentUserStoryData?.title,
                type: Entity.userStory
            })
        }

        if (openEditUseCaseDialog && currentUseCaseData) {
            locationPathList?.push({
                name: currentUseCaseData?.title,
                type: Entity.userStory
            })
        }

        console.log(locationPathList, "locationPathList_")

        setLocationPathList(locationPathList);
        // console.log(array, "locationpath")

        setTimeout(() => {
            setIsLoading(false);
        }, 400);
    }

    function handleChangeTab(event, newValue) {
        setCurrentTab(newValue);

        if (newValue === 0 && (openCreateStoryDialog || openEditStoryDialog)) {
            setOpenCreateStoryDialog(false);
            setOpenEditStoryDialog(false);
        }
    }

    const handleClickPathItem2 = (itemData) => {
        if (itemData?.data?.type) {
            setCurrentFolderData(itemData);
        } else {
            return;
        }
    }

    const handleClickPathItem = (itemData, index) => {
        if (itemData?.type === "page" || itemData?.type === Entity.project) setCurrentFolderData(null);
        if (itemData?.type !== Entity.userStory && itemData?.type !== Entity.useCase && itemData?.type !== Entity.testCase) {
            setOpenEditStoryDialog(false);
            setOpenEditUseCaseDialog(false);
        }

        if (itemData?.type !== Entity.folder) return;

        const pathNames = locationPathList?.map((item) => item?.name);
        const urlUpToIndex = pathNames?.slice(0, index + 1).join('/');

        let folder = findFolder(folderStructureList, "path", urlUpToIndex);

        if (folder) {
            console.log(folder, "findFolder folder", urlUpToIndex)
            setCurrentFolderData(folder);
        }
    }


    function findFolder(list, keyName, value) {
        for (const item of list) {
            if (item[keyName] === value) {
                return item;
            }

            if (item.children) {
                const foundInChildren = findFolder(item.children, keyName, value);
                if (foundInChildren) {
                    return foundInChildren;
                }
            }
        }
        return null;
    }


    const handleOpenCreateDialog = () => {
        if (pathName === ModulePath.requirements) {
            setOpenCreateStoryDialog(true);
        }
        if (pathName === ModulePath.useCase || pathName === ModulePath.testCase) {
            setOpenCreateUseCaseDialog(true);
        }
    }

    function handleComponent() {

        // switch (currentTab) {
        //     case ACTIVE_PROJECT_TABS.dashboard:
        //         return <DashboardContainerComponent />;
        //     case ACTIVE_PROJECT_TABS.userStory:
        //         return <UserStoryContainerComponent />;
        //     case ACTIVE_PROJECT_TABS.useCase:
        //         return <UseCaseContainerComponent />;
        //     case ACTIVE_PROJECT_TABS.testCase:
        //         return <TestCaseContainerComponent />;
        //     default:
        //         return <DashboardContainerComponent />;
        // }

        switch (pathName) {
            case ModulePath.requirements:
                return <UserStoryContainerComponent />;
            case ModulePath.useCase:
                return <UseCaseContainerComponent />;
            case ModulePath.testCase:
                return <UseCaseContainerComponent />;
            case ModulePath.dashboard:
                return <DashboardContainerComponent />
            default:
                return <UserStoryContainerComponent />;
        }
    }

    // user story
    const handleGetUserStoryList = async () => {
        try {
            setIsUserStoryLoading(true);

            let body = {
                "folderPath": currentFolderData?.path,
                "projectName": activeProject?.name
            }

            const response = await getStoryList_API(body);
            console.log(response, "response handleGetUserStoryList")

            if (response) {
                errorHandler(response, setIsUserStoryLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                setUserStoryList(response.data);
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setIsUserStoryLoading(false);
            }, 1000);
        }
    }

    const handleGetUserStoryListAll = async () => {
        try {
            setIsUserStoryLoading(true);

            let query = `name=${activeProject?.name}`

            const response = await getStoryListAll_API(query);
            console.log(response, "response handleGetUserStoryList")

            if (response) {
                errorHandler(response, setIsUserStoryLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                setUserStoryList(response.data);
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setIsUserStoryLoading(false);
            }, 1000);
        }
    }

    const handleGetUserStoryPropertiesList = async () => {
        try {
            setIsUserStoryLoading(true);

            const response = await getStoryPropertiesList_API();
            console.log(response, "response handleGetUserStoryList")

            if (response) {
                errorHandler(response, setIsUserStoryLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                setUserStoryPropertiesData(response.data);
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setIsUserStoryLoading(false);
            }, 1000);
        }
    }



    const handleCreateStory = async (storyData) => {
        try {


            let body = {
                ...storyData,
                "priority": "High",
                "moduleName": currentFolderData?.name,
                "projectName": activeProject?.name,
            }


            const response = await addStory_API(body);
            console.log(response, "response handleCreateStory")

            if (response) {
                errorHandler(response);
            }

            if (response.status === HttpStatusCode.Ok) {
                handleGetUserStoryList();
                setOpenCreateStoryDialog(false);
            }

            setIsUpdateFolderStructure(true);


            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {

            }, 1000);
        }
    }

    const handleUpdateStory = async (storyData) => {
        try {


            let body = {
                ...storyData,
            }


            const response = await updateStory_API(body);
            console.log(response, "response handleUpdateStory")

            if (response) {
                errorHandler(response);
            }

            if (response?.status === HttpStatusCode.Ok) {
                handleGetUserStoryList();
                setOpenEditStoryDialog(false);
            }


            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {

            }, 1000);
        }
    }

    const handleDeleteStory = async (storyData) => {
        try {


            let body = {
                "customId": storyData?.customId,
                "moduleName": currentFolderData?.name,
                "projectName": activeProject?.name
            }

            const response = await deleteStory_API(body);

            if (response.status === HttpStatusCode.Ok) {
                // handleSnackbar(response?.message, Severity.success);
                // setOpenDeleteStoryDialog(false);
            }

            if (response) {
                errorHandler(response);
            }

            setTimeout(() => {

            }, 1000);

            console.log(response, "projectreponse data")
        } catch (error) {
            console.error(error, "projectreponse errorr")
        }
    }


    // use case
    const handleGetUseCaseList = async () => {
        try {
            setIsActiveProjectContainerLoading(true);

            let body = {
                "folderPath": currentFolderData?.path,
                "projectName": activeProject?.name
            }

            const response = await getUseCaseList_API(body);
            console.log(response, "response handleGetUseCaseList")

            if (response) {
                errorHandler(response, setIsActiveProjectContainerLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                setUseCaseList(response.data);
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setIsActiveProjectContainerLoading(false);
            }, 1000);
        }
    }

    const handleGetUseCaseListAll = async () => {
        try {
            setIsActiveProjectContainerLoading(true);

            let query = `name=${activeProject?.name}`

            const response = await getUseCaseListAll_API(query);
            console.log(response, "response handleGetUseCaseList")

            if (response) {
                errorHandler(response, setIsActiveProjectContainerLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                setUseCaseList(response.data);
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setIsActiveProjectContainerLoading(false);
            }, 1000);
        }
    }

    const handleGetUseCasePropertiesList = async () => {
        try {
            setIsActiveProjectContainerLoading(true);

            const response = await getUseCasePropertiesList_API();
            console.log(response, "response handleGetUserStoryList")

            if (response) {
                errorHandler(response, setIsActiveProjectContainerLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                setUseCasePropertiesData(response.data)
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setIsActiveProjectContainerLoading(false);
            }, 1000);
        }
    }

    const handleCreateUseCase = async (storyData) => {
        try {


            let body = {
                ...storyData,
            }


            const response = await addUseCase_API(body);
            console.log(response, "response handleCreateStory")

            if (response) {
                errorHandler(response);
            }

            if (response.status === HttpStatusCode.Ok) {
                handleGetUseCaseList();
                setOpenCreateUseCaseDialog(false);
            }

            setIsUpdateFolderStructure(true);


            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {

            }, 1000);
        }
    }

    const handleUpdateUseCase = async (storyData) => {
        try {


            let body = {
                ...storyData,
            }


            const response = await updateUseCase_API(body);
            console.log(response, "response handleUpdateStory")

            if (response) {
                errorHandler(response);
            }

            if (response?.status === HttpStatusCode.Ok) {
                handleGetUseCaseList();
                setOpenEditStoryDialog(false);
            }


            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {

            }, 1000);
        }
    }

    const handleDeleteUseCase = async (storyData) => {
        try {


            let body = {
                "customId": storyData?.customId,
                "moduleName": currentFolderData?.name,
                "projectName": activeProject?.name
            }

            const response = await deleteUseCase_API(body);

            if (response.status === HttpStatusCode.Ok) {
                // handleSnackbar(response?.message, Severity.success);
                // setOpenDeleteStoryDialog(false);
            }

            if (response) {
                errorHandler(response);
            }

            setTimeout(() => {

            }, 1000);

            console.log(response, "projectreponse data")
        } catch (error) {
            console.error(error, "projectreponse errorr")
        }
    }


    // test case
    const handleGetTestCaseList = async () => {
        try {
            setIsActiveProjectContainerLoading(true);

            let body = {
                "folderPath": currentFolderData?.path,
                "projectName": activeProject?.name
            }

            const response = await getTestCaseList_API(body);
            console.log(response, "response handleGetUseCaseList")

            if (response) {
                errorHandler(response, setIsActiveProjectContainerLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                setUseCaseList(response.data);
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setIsActiveProjectContainerLoading(false);
            }, 1000);
        }
    }

    const handleGetTestCaseListAll = async () => {
        try {
            setIsActiveProjectContainerLoading(true);

            let query = `name=${activeProject?.name}`

            const response = await getTestCaseListAll_API(query);
            console.log(response, "response handleGetUseCaseList")

            if (response) {
                errorHandler(response, setIsActiveProjectContainerLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                setUseCaseList(response.data);
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setIsActiveProjectContainerLoading(false);
            }, 1000);
        }
    }

    const handleGetTestCasePropertiesList = async () => {
        try {
            setIsActiveProjectContainerLoading(true);

            const response = await getTestCasePropertiesList_API();
            console.log(response, "response handleGetUserStoryList")

            if (response) {
                errorHandler(response, setIsActiveProjectContainerLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                setUseCasePropertiesData(response.data)
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setIsActiveProjectContainerLoading(false);
            }, 1000);
        }
    }

    // fetch the userstory / usecase list
    useEffect(() => {
        if (pathName === ModulePath.requirements) {
            if (currentFolderData) {
                handleGetUserStoryList();
                handleGetUserStoryPropertiesList();
            } else {
                // setUserStoryList([]);
                if (activeProject) {
                    handleGetUserStoryListAll();
                    handleGetUserStoryPropertiesList();
                }
            }
        }

        if (pathName === ModulePath.useCase) {
            if (currentFolderData) {
                handleGetUseCaseList();
                handleGetUseCasePropertiesList();
            } else {
                // setUseCaseList([]);
                if (activeProject) {
                    handleGetUseCaseListAll();
                    handleGetUseCasePropertiesList();
                }
            }
        }

        if (pathName === ModulePath.testCase) {
            if (currentFolderData) {
                handleGetTestCaseList();
                handleGetTestCasePropertiesList();
            } else {
                // setUseCaseList([]);
                if (activeProject) {
                    handleGetTestCaseListAll();
                    handleGetTestCasePropertiesList();
                }
            }
        }

    }, [currentFolderData?.id, pathName, activeProject]);

    // close the dialog when page changes
    useEffect(() => {
        setOpenEditStoryDialog(false);
        setOpenEditUseCaseDialog(false);
        setOpenCreateUseCaseDialog(false);
        setOpenCreateStoryDialog(false);
    }, [pathName])



    // use to create the tab list and active tab - OLD
    useEffect(() => {

        let foundIndex;

        if (pathName === ModulePath.requirements) {
            foundIndex = tabsList?.findIndex((tab) => tab.value === ACTIVE_PROJECT_TABS.userStory);
            setCurrentTab(ACTIVE_PROJECT_TABS.userStory);
        }

        if (pathName === ModulePath.useCase) {
            foundIndex = tabsList?.findIndex((tab) => tab.value === ACTIVE_PROJECT_TABS.useCase);
            setCurrentTab(ACTIVE_PROJECT_TABS.useCase);
        }

        if (pathName === ModulePath.testCase) {
            foundIndex = tabsList?.findIndex((tab) => tab.value === ACTIVE_PROJECT_TABS.testCase);
            setCurrentTab(ACTIVE_PROJECT_TABS.testCase);
        }

        if (foundIndex > -1) {
            const newTabsList = [...tabsList];
            let dashboardIndex = newTabsList?.findIndex((tab) => tab.value === ACTIVE_PROJECT_TABS.dashboard);

            newTabsList[dashboardIndex].disabled = false;
            newTabsList[foundIndex].disabled = false;

            newTabsList.forEach((tab, index) => {

                if (index !== foundIndex && index !== dashboardIndex) {
                    tab.disabled = true;
                }
            });

            setTabsList(newTabsList);
            // setCurrentTab(ACTIVE_PROJECT_TABS.dashboard);
        }

    }, [pathName]);




    console.log(pathName, "pathNamepathName")
    console.log(breadCrumbsList, "node1breadcrumbs")
    console.log(selectedUserStoryList, "node1selectedUserStoryList");
    console.log(useCaseList, "useCaseList");
    console.log(locationPathList, "locationPathList")

    useEffect(() => {
        if (currentFolderData) {
            handleLocationPath();
        } else {
            handleLocationPath();
        }
        // handleBreadCrumb();
    }, [currentFolderData, pathName, openEditStoryDialog, openEditUseCaseDialog, activeDashboard]);

    return (
        <Box className="flex gap-4 w-full h-full  ">

            <Box className="flex flex-col gap-2 flex-1 h-full   relative">

                {/* header */}
                <Box className="w-full h-fit flex flex-col " >
                    <Box className=" w-full h-fit flex flex-col px-0 py-0  gap-4 " borderRadius={theme.shape.borderRadius} >

                        {/* upper container - path */}
                        <Box className="flex w-full h-fit items-center   justify-between gap-2" >
                            {isLoading ?
                                <Box className="flex-1 flex w-full" >
                                    <Skeleton variant="rounded" width={"100%"} height={28} animation="wave" />
                                </Box>
                                : (
                                    <Box
                                        className="flex "
                                        sx={{
                                            // border: `1px solid ${theme.palette.primary.light}`
                                        }}>
                                        <Breadcrumbs
                                            // separator={<NavigateNext fontSize="small" />}
                                            aria-label="breadcrumb"
                                        >
                                            {
                                                locationPathList?.map((item, index, arr) => {
                                                    return (
                                                        <LightTooltip
                                                            key={index}
                                                            title={
                                                                <Typography fontSize={font.size.fs_5} className="capitalize">
                                                                    {
                                                                        `${item?.type === Entity.project
                                                                            ? `${Entity_Label.project}: ${item?.name}`
                                                                            : item?.type === "page"
                                                                                ? `Page: ${item?.name}`
                                                                                : (item?.type === Entity.folder)
                                                                                    ? `Folder: ${item?.name}`
                                                                                    : `${pathName === ModulePath.requirements ? Entity_Label.userStory : pathName === ModulePath.useCase ? Entity_Label.useCase : Entity_Label.testCase}: ${item?.name}`}`
                                                                    }
                                                                </Typography>
                                                            }
                                                        // placement="bottom"
                                                        >
                                                            <Button key={index} color={(arr?.length - 1 === index) ? "secondary" : "inherit"} disableElevation={item?.type === Entity.project || item?.type === "page"} disableTouchRipple={item?.type === Entity.project || item?.type === "page"} disableRipple={item?.type === Entity.project || item?.type === "page"} className={`flex items-center gap-1  ${(item?.type === Entity.project || item?.type === "page") && "cursor-none"}`} sx={{ textTransform: "inherit" }} onClick={() => { handleClickPathItem(item, index) }}>

                                                                <Box className="flex items-center gap-1" sx={{ color: (arr?.length - 1 === index) ? theme.palette.secondary.main : theme.palette.text.primary }}>
                                                                    {
                                                                        item?.type === Entity.project
                                                                            ? <ProjectCustomIcon />
                                                                            : item?.type === "page"
                                                                                ? (
                                                                                    pathName === ModulePath.requirements
                                                                                        ? <UserStoryCustomIcon />
                                                                                        : pathName === ModulePath.useCase ? <UseCaseCustomIcon />
                                                                                            : <TestCaseCustomIcon />
                                                                                )
                                                                                : item?.type === Entity.folder
                                                                                    ? <FolderCustomIcon />
                                                                                    : item?.type === Entity.dashboard
                                                                                        ? <DashboardCustomIcon />
                                                                                        :
                                                                                        (
                                                                                            pathName === ModulePath.requirements ? <UserStoryCustomIcon />
                                                                                                : pathName === ModulePath.requirements ? <UseCaseCustomIcon />
                                                                                                    : <TestCaseCustomIcon />
                                                                                        )
                                                                    }
                                                                    <Typography
                                                                        fontSize={font.size.fs_5}
                                                                        key={index + 1}
                                                                        sx={{ color: "inherit" }}
                                                                        onClick={() => { }}>
                                                                        {item?.name}
                                                                    </Typography>
                                                                </Box>

                                                            </Button>
                                                        </LightTooltip>
                                                    )
                                                })
                                            }
                                        </Breadcrumbs>
                                    </Box>
                                )
                            }

                            {false && pathName === ModulePath.dashboard && (
                                <Box className="flex items-center gap-2">
                                    <Box className="flex items-center gap-0">
                                        <Tabs
                                            value={0}
                                            // value={selectedTab}
                                            // onChange={(e, value) => setSelectedTab(value)}
                                            aria-label="basic tabs example"
                                            className="!mb-0"
                                        >
                                            <Tab label="Public" value={0} />
                                            <Tab label="Private" value={1} />
                                        </Tabs>
                                    </Box>
                                    <Box className="flex items-center gap-2 w-72">
                                        <DesktopDatePicker defaultValue={dayjs('2022-04-17')} />
                                        <Typography>-</Typography>
                                        <DesktopDatePicker defaultValue={dayjs('2022-04-17')} />
                                    </Box>
                                </Box>
                            )}



                            {false && <Box
                                className="flex "
                                sx={{
                                    // border: `1px solid ${theme.palette.primary.light}`
                                }}>
                                <Breadcrumbs
                                    separator={<NavigateNext fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {
                                        breadCrumbsList?.map((item, index) => {
                                            return (
                                                <LightTooltip
                                                    key={index}
                                                    title={
                                                        <Typography fontSize={font.size.fs_5} className="capitalize">
                                                            {
                                                                `${(item?.data?.type)
                                                                    ? `Folder: ${item?.data?.name}`
                                                                    : `${pathName === ModulePath.requirements ? "User Story" : "Use Case"}: ${item?.customId}`}`
                                                            }
                                                        </Typography>
                                                    }
                                                // placement="bottom"
                                                >
                                                    <Button key={index} className="flex items-center gap-1 border" onClick={() => { handleClickPathItem(item) }}>
                                                        {
                                                            ((currentUserStoryData && currentUserStoryData?.customId === item?.customId) || (currentUseCaseData && currentUseCaseData?.customId === item?.customId)) ?
                                                                (
                                                                    <Box className="flex items-center gap-1" sx={{ color: theme.palette.secondary.main }}>
                                                                        <Description sx={{ fontSize: font.size.fs_4, color: "inherit" }} />
                                                                        <Typography
                                                                            fontSize={font.size.fs_5}
                                                                            // className={`cursor-pointer ${(breadCrumbsList?.length - 1 === index) && "underline"}`}
                                                                            key={index + 1}
                                                                            // fontWeight={(breadCrumbsList?.length - 1 === index) && 600}
                                                                            // color="primary"
                                                                            sx={{ color: "inherit" }}
                                                                            onClick={() => { }}>
                                                                            {item?.customId}
                                                                        </Typography>
                                                                    </Box>
                                                                )
                                                                :
                                                                (
                                                                    <Box className="flex items-center gap-1" sx={{ color: (breadCrumbsList?.length - 1 === index) ? theme.palette.text.primary : theme.palette.text.secondary }}>
                                                                        {item?.data?.type === FolderType.project &&
                                                                            <FolderSpecialIcon
                                                                                sx={{ fontSize: font.size.fs_4, }}
                                                                            />}

                                                                        {item?.data?.type === FolderType.module &&
                                                                            <FolderOutlined
                                                                                sx={{ fontSize: font.size.fs_4 }}
                                                                            />}

                                                                        <Typography
                                                                            fontSize={font.size.fs_5}
                                                                            // className={`cursor-pointer ${(breadCrumbsList?.length - 1 === index) && "underline"}`}
                                                                            key={index + 1}
                                                                            // fontWeight={(breadCrumbsList?.length - 1 === index) && 600}
                                                                            // color="primary"
                                                                            sx={{ color: "inherit" }}
                                                                            // color={(breadCrumbsList?.length - 1 === index) && theme.palette.secondary.main}
                                                                            // sx={{ opacity: breadCrumbsList?.length - 1 === index ? 1 : 0.8 }}
                                                                            onClick={() => { }}>
                                                                            {item?.data?.name}
                                                                        </Typography>
                                                                    </Box>
                                                                )


                                                        }

                                                    </Button>
                                                </LightTooltip>
                                            )
                                        })
                                    }
                                </Breadcrumbs>
                            </Box>}

                        </Box>

                        {/* lower container - viewlist, dashboard tabs and grid, list view / removed from UI */}
                        {false && <Box className="w-full h-fit flex items-center justify-between gap-4 border border-gray-600">
                            {/* <Box
                        className="flex flex-1 items-end h-full"
                        sx={{
                            // border: `1px solid ${theme.palette.primary.light}`
                        }}>
                        <Breadcrumbs
                            separator={<NavigateNext fontSize="small" />}
                            aria-label="breadcrumb"
                        >
                            {
                                breadCrumbsList?.map((item, index) => {
                                    return (
                                        <LightTooltip title={<Typography fontSize={font.size.fs_5} className="capitalize">{`${item?.data?.type}: ${item?.data?.name}`}</Typography>}>
                                            <Box className="flex items-center gap-1" onClick={() => { setCurrentFolderData(item) }}>
                                                {item?.data?.type === FolderType.project &&
                                                    <FolderSpecialIcon
                                                        // color={(breadCrumbsList?.length - 1 === index) ? "secondary" : "dark"}
                                                        sx={{ fontSize: font.size.fs_3, }}
                                                    />}

                                                {item?.data?.type === FolderType.module &&
                                                    <FolderOutlined
                                                        // color={(breadCrumbsList?.length - 1 === index) ? "secondary" : "dark"}
                                                        sx={{ fontSize: font.size.fs_3 }}
                                                    />}
                                                <Typography
                                                    fontSize={font.size.fs_5}
                                                    className={`cursor-pointer ${(breadCrumbsList?.length - 1 === index) && "underline"}`}
                                                    key={index + 1}
                                                    fontWeight={(breadCrumbsList?.length - 1 === index) && 600}
                                                    color="primary"
                                                    // color={(breadCrumbsList?.length - 1 === index) && theme.palette.secondary.main}
                                                    // sx={{ opacity: breadCrumbsList?.length - 1 === index ? 1 : 0.8 }}
                                                    onClick={() => { }}>
                                                    {item?.data?.name}
                                                </Typography>
                                            </Box>
                                        </LightTooltip>
                                    )
                                })
                            }
                        </Breadcrumbs>
                    </Box> */}

                            <Box className=" flex items-center justify-center">
                                {/* <StyledTabs
                        value={currentTab}
                        onChange={(event, newValue) => handleChangeTab(event, newValue)}
                        sx={{ height: '100%' }}
                    >
                        <StyledTab value={0} label="Dashboard" />
                        <StyledTab value={1} label="User Story" />
                    </StyledTabs> */}
                                {
                                    tabsList?.filter((item) => !item?.disabled).map((item, index) => {
                                        return (
                                            <Button
                                                variant={item?.value === currentTab ? "text" : "text"}
                                                size="medium"
                                                key={index}
                                                onClick={(e) => handleChangeTab(e, item?.value)}
                                                sx={{
                                                    px: 1,
                                                    borderRadius: 0,
                                                    borderBottom: item?.value === currentTab ? `2px solid ${theme.palette.primary.main}` : `2px solid transparent`,
                                                    // background: item?.value === currentTab ? theme.palette.primary.main : "transparent",
                                                    // color: item?.value === currentTab ? theme.palette.primary.contrastText : theme.palette.primary.main,

                                                    opacity: item?.value === currentTab ? 1 : 0.6,
                                                    // "&:hover" : { background: theme.palette.secondary.main}
                                                }}
                                            >
                                                {item?.label}
                                            </Button>
                                        )
                                    })
                                }
                            </Box>


                            {!openCreateStoryDialog &&
                                <Box className=" flex items-center  gap-2  rounded-lg overflow-hidden ">
                                    <Paper
                                        elevation={0}
                                        sx={(theme) => ({
                                            display: 'flex',
                                            border: `1px solid ${theme.palette.divider}`,
                                            flexWrap: 'wrap',
                                        })}
                                    >
                                        <StyledToggleButtonGroup
                                            size="small"
                                            value={isGridViewEnabled}
                                            exclusive
                                            onChange={(e, newValue) => {
                                                console.log(newValue, isGridViewEnabled, newValue !== isGridViewEnabled, "value togglebutton")
                                                if (newValue !== null) {
                                                    setIsGridViewEnabled(newValue);
                                                }
                                            }}
                                            aria-label="text alignment"
                                            sx={{ boxSizing: "border-box" }}
                                        >

                                            <StyledToggleButton value={true} aria-label="centered">
                                                <LightTooltip title={`Grid View`}>
                                                    <GridViewTwoToneIcon />
                                                </LightTooltip>
                                            </StyledToggleButton>

                                            <StyledToggleButton value={false} aria-label="centered">
                                                <LightTooltip title={`List View`}>
                                                    <ListTwoToneIcon />
                                                </LightTooltip>
                                            </StyledToggleButton>
                                        </StyledToggleButtonGroup>
                                    </Paper>
                                </Box>}
                        </Box>}
                    </Box>

                </Box>

                {/* body */}
                <Box className="h-[100%]  flex flex-col w-full relative overflow-hidden  ">
                    {handleComponent()}




                    {/* dialog */}
                    {openCreateStoryDialog &&
                        <CreateStoryComponent
                            open={openCreateStoryDialog}
                            closeDialog={() => setOpenCreateStoryDialog(false)}
                        />}

                    {openEditStoryDialog && currentUserStoryData &&
                        <EditStoryComponent
                            open={openEditStoryDialog}
                            closeDialog={() => { setOpenEditStoryDialog(false); setIsItemChatWindow(false); setCurrentUserStoryData(null) }}
                        />}

                    {openDeleteStoryDialog &&
                        <DeleteProjectItemDialog
                            open={openDeleteStoryDialog}
                            handleClose={() => { setOpenDeleteStoryDialog(false); setCurrentUserStoryData(null) }}
                        />}


                    {openCreateUseCaseDialog && pathName === ModulePath.useCase &&
                        <CreateUseCaseComponent
                            open={openCreateUseCaseDialog}
                            closeDialog={() => setOpenCreateUseCaseDialog()}
                        />
                    }

                    {openEditUseCaseDialog && currentUseCaseData && pathName === ModulePath.useCase &&
                        <EditUseCaseComponent
                            open={openEditUseCaseDialog}
                            closeDialog={() => { setOpenEditUseCaseDialog(false); setIsItemChatWindow(false); setCurrentUseCaseData(null) }}
                        />}

                    {openDeleteUseCaseDialog &&
                        <DeleteProjectItemDialog
                            open={openDeleteUseCaseDialog}
                            handleClose={() => { setOpenDeleteUseCaseDialog(false); setCurrentUseCaseData(null) }}
                        />
                    }


                    {openCreateUseCaseDialog && pathName === ModulePath.testCase &&
                        <CreateTestCaseComponent
                            open={openCreateUseCaseDialog}
                            closeDialog={() => setOpenCreateUseCaseDialog()}
                        />
                    }

                    {openEditUseCaseDialog && currentUseCaseData && pathName === ModulePath.testCase &&
                        <EditTestCaseComponent
                            open={openEditUseCaseDialog}
                            closeDialog={() => { setOpenEditUseCaseDialog(false); setIsItemChatWindow(false); setCurrentUseCaseData(null) }}
                        />}

                    {/* {openDeleteUseCaseDialog &&
                        <DeleteProjectItemDialog
                            open={openDeleteUseCaseDialog}
                            handleClose={() => { setOpenDeleteUseCaseDialog(false); setCurrentUseCaseData(null) }}
                        />
                    } */}

                </Box>


                {/* userstory */}
                {pathName === ModulePath.requirements && (!openEditStoryDialog) &&
                    <Box className="absolute bottom-2 right-2 z-10">
                        <LightTooltip title={`Add User Story`}>
                            <Fab
                                color="secondary"
                                disabled={openEditStoryDialog}
                                onClick={handleOpenCreateDialog}
                                sx={{ boxShadow: "none" }}>
                                <Add />
                            </Fab>
                        </LightTooltip>
                    </Box>}

                {(pathName === ModulePath.useCase || pathName === ModulePath.testCase) && (!openEditUseCaseDialog) &&
                    <Box className="absolute bottom-2 right-2 z-10">
                        <LightTooltip title={`Add`}>
                            <Fab
                                color="secondary"
                                onClick={handleOpenCreateDialog}
                                sx={{ boxShadow: "none" }}>
                                <Add />
                            </Fab>
                        </LightTooltip>
                    </Box>
                }

            </Box>

            {/* right container */}
            {/* <Box className="flex flex-col h-full w-fit overflow-hidden" borderRadius={theme.shape.borderRadius} bgcolor={theme.palette.background.default}> */}
            {openLinkTagDrawer && (currentUserStoryData || currentUseCaseData) &&
                <LinkTagDrawer
                    open={openLinkTagDrawer}
                    handleClose={() => setOpenLinkTagDrawer(false)}
                />}

            {openItemFilterDrawer &&
                <FilterItemDrawer
                    open={openItemFilterDrawer}
                />}
            {/* </Box> */}


        </Box>
    )
}




function UserStoryContainerComponent() {
    const {
        isGridViewEnabled, setIsGridViewEnabled,
        handleSnackbar,
        currentFolderData, setCurrentFolderData,
        userStoryList, setUserStoryList,
        currentUserStoryData, setCurrentUserStoryData,
        isShowStoryFilter, setIsShowStoryFilter,
        selectedUserStoryList, setSelectedUserStoryList,
        isUserStoryLoading, setIsUserStoryLoading,
        filteredUserStoryList, setFilteredUserStoryList,
        openDeleteStoryDialog, setOpenDeleteStoryDialog,
        openCreateStoryDialog,
        currentFilters, setCurrentFilters,
        openItemFilterDrawer, setOpenItemFilterDrawer,
        userStoryPropertiesData, setUserStoryPropertiesData,
        activeProject
    } = useApplicationContext();
    const theme = useTheme();
    const errorHandler = useErrorHandler();
    const isTabletScreen = useMediaQuery((theme) => theme.breakpoints.down('xl'));
    let timer;

    const [activeTab, setActiveTab] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isShowFilters, setIsShowFilters] = useState(true);
    const [actionType, setActionType] = useState("label");
    const [actionIconAnchorEl, setActionIconAnchorEl] = useState(null);

    const [searchText, setSearchText] = useState("");
    // const [currentFilters, setCurrentFilters] = useState(null); // To store the table filters

    const [finalList, setFinalList] = useState(filteredUserStoryList); // To store the final filtered -> searched list
    const [selectedPropertiesData, setSelectedPropertiesData] = useState(null); // To store the selected properties values

    const [stateList, setStateList] = useState([]);
    const [moscowList, setMoscowList] = useState([]);
    const [confidentialityList, setConfidentialityList] = useState([]);
    const [typeList, setTypeList] = useState([]);
    const [priorityList, setPriorityList] = useState([]);


    // const [filteredList, setFilteredList] = useState([]);




    const handleChangeSearch = (value) => {
        setSearchText(value);
        clearTimeout(timer);

        setTimeout(() => {
            setIsLoading(true);
        }, 400);

        timer = setTimeout(() => {
            // setSearchText(value);
            setIsLoading(false);
        }, 800);
    };

    const handleDeleteClick = () => {
        setOpenDeleteStoryDialog(true);
        console.log(openDeleteStoryDialog, "true")
    }


    const handleRemoveFilterItem = (key, value) => {
        let filter = currentFilters ? { ...currentFilters } : {};

        if (Array.isArray(filter[key])) {
            filter[key] = currentFilters[key].filter((item) => item !== value);
            if (filter[key].length === 0) {
                delete filter[key]
            }
        } else {
            if (key === "from") {
                delete filter["createdAt"][key]
            }

            if (key === "to") {
                delete filter["createdAt"][key]
            }

            if (filter["createdAt"] && Object.keys(filter["createdAt"])?.length === 0) {
                delete filter["createdAt"]
            }

            else {
                delete filter[key]
            }
        }


        if (filter && Object.keys(filter)?.length === 0) {
            setCurrentFilters(null)
            return;
        }


        // delete filter[value];
        setCurrentFilters(filter)
    }

    const handleGetFilterCount = (value) => {
        if (value === Filters.status) {
            return currentFilters?.status?.length
        }

        if (value === Filters.createdAt) {
            return currentFilters?.createdAt?.from && currentFilters?.createdAt?.to ? 2 : currentFilters?.createdAt?.from ? 1 : currentFilters?.createdAt?.to ? 1 : 0
        }
    }

    const handleChangeColumnFilter = (event, key) => {
        const filterData = currentFilters ? { ...currentFilters } : {};

        if (event.target.value === '') {
            delete filterData[key];
        } else {
            filterData[key] = event.target.value;
        }

        setCurrentFilters(filterData);
    };

    const handleToggleActionMenu = (event, actionType) => {

        if (!actionIconAnchorEl) {
            setActionIconAnchorEl(event.currentTarget)
            setActionType(actionType)
        } else {
            setActionIconAnchorEl(null)
        }
    }

    const handleSelectProperites = (key, value) => {
        setSelectedPropertiesData({ ...selectedPropertiesData, [key]: value });
    }


    const handleCancelPropertiesData = () => {
        setSelectedPropertiesData(null);
        setActionIconAnchorEl(null);
    }


    const handleApplyPropertiesData = () => {
        let body = {
            "customIds": [selectedUserStoryList?.map((item) => item?.customId)],
            "folderPath": currentFolderData?.path,
            "projectName": activeProject?.name,
        }

        body = { ...body, ...selectedPropertiesData };

        handleUpdateStory(body)

    }


    const handleUpdateStory = async (storyData) => {
        try {
            setIsUserStoryLoading(true);

            let body = {
                ...storyData,
            }


            const bodyFormData = new FormData();
            Object.keys(body).forEach(key => {
                if (Array.isArray(body[key])) {
                    bodyFormData.append(key, body[key]);
                } else {
                    bodyFormData.append(key, body[key]);
                }
            });



            const response = await updateStory_API(bodyFormData);
            console.log(response, "response handleUpdateStory")

            if (response) {
                errorHandler(response, setIsUserStoryLoading);
            }

            if (response?.status === HttpStatusCode.Ok) {
                let updatedList = response.data;

                updatedList?.forEach((item) => {
                    let list = userStoryList;
                    let foundIndex = list?.findIndex((listItem) => listItem?.customId === item?.customId);
                    if (foundIndex !== -1) {
                        list[foundIndex] = item;
                    }
                    setUserStoryList(list);
                })

                setSelectedUserStoryList([]);
                setActionIconAnchorEl(null);
                setSelectedPropertiesData(null);

                setTimeout(() => {
                    handleSnackbar("Story updated successfully", Severity.success);
                }, 500)

            }


            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setIsUserStoryLoading(false);
            }, 1000);
        }
    }


    useEffect(() => {
        setIsLoading(isUserStoryLoading);
    }, [isUserStoryLoading, setIsUserStoryLoading]);

    // useEffect(() => {
    //     if (currentFilters) {

    //         const filteredRows = userStoryList?.filter((row) =>
    //             Object.entries(currentFilters).every(([column, value]) => {
    //                 console.log(column, value, "column, value")
    //                 if (column === "createdAt_From") {
    //                     return dayjs(row["createdAt"]).isSame(dayjs(value), 'day') || dayjs(row["createdAt"]).isAfter(dayjs(value));
    //                 }
    //                 if (value === "all") {
    //                     return true
    //                 }
    //                 return !value || row[column]?.toString().toLowerCase().includes(value.toLowerCase())
    //             })
    //         );

    //         setFilteredUserStoryList(filteredRows);
    //         console.log(filteredRows, "filteredRows")
    //     }


    // }, [currentFilters, userStoryList, setFilteredUserStoryList]);

    useEffect(() => {
        setIsLoading(true);
        if (currentFilters) {

            const filteredRows = userStoryList?.filter((row) => Object.entries(currentFilters).every(([column, value]) => {
                console.log(column, value, row, "column, value, row")
                if (!value) return true;
                if (Array.isArray(value)) {
                    return value.includes(row[column]?.toString());
                } else {
                    if (column === "createdAt") {
                        if (value.from && value.to) {
                            return dayjs(row["createdAt"]).isAfter(dayjs(value.from), 'day') && dayjs(row["createdAt"]).isBefore(dayjs(value.to), 'day');
                        } else if (value.from) {
                            return dayjs(row["createdAt"]).isAfter(dayjs(value.from), 'day') || dayjs(row["createdAt"]).isSame(dayjs(value.from), 'day');
                        } else if (value.to) {
                            return dayjs(row["createdAt"]).isBefore(dayjs(value.to), 'day') || dayjs(row["createdAt"]).isSame(dayjs(value.to), 'day');
                        } else {
                            return false;
                        }
                    } else {
                        return row[column]?.toString().toLowerCase().includes(value.toLowerCase());
                    }
                }
            }));
            setFilteredUserStoryList(filteredRows);
            console.log(filteredRows, "filteredRows");
        } else {
            setFilteredUserStoryList(userStoryList);
        }


        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }, [currentFilters, userStoryList, setFilteredUserStoryList]);

    useEffect(() => {
        let list = filteredUserStoryList;

        if (searchText) {
            list = list?.filter(
                (item) => (
                    item?.customId?.toLowerCase()?.includes(searchText.toLowerCase()) ||
                    item?.title?.toLowerCase()?.includes(searchText.toLowerCase()) ||
                    item?.state?.toLowerCase()?.includes(searchText.toLowerCase()) ||
                    item?.priority?.toLowerCase()?.includes(searchText.toLowerCase()) ||
                    item?.type?.toLowerCase()?.includes(searchText.toLowerCase()) ||
                    item?.moscow?.toLowerCase()?.includes(searchText.toLowerCase()) ||
                    item?.confidentiality?.toLowerCase()?.includes(searchText.toLowerCase())
                )
            );
        }

        setFinalList(list);
    }, [searchText, filteredUserStoryList, setFinalList]);


    useEffect(() => {
        setFilteredUserStoryList(userStoryList);
    }, [userStoryList, setFilteredUserStoryList]);

    useEffect(() => {
        if (userStoryPropertiesData) {
            setTypeList(userStoryPropertiesData["type"]);
            setStateList(userStoryPropertiesData["state"]);
            setPriorityList(userStoryPropertiesData["priority"]);
            setMoscowList(userStoryPropertiesData["moscow"]);
            setConfidentialityList(userStoryPropertiesData["confidentiality"]);
        }
    }, [userStoryPropertiesData])



    console.log(isTabletScreen, "isTabletScreen");
    console.log(selectedPropertiesData, "selectedPropertiesData");
    console.log(currentFilters, "currentFiltersss")

    return (
        <Box className="flex flex-col gap-4 w-full h-full overflow-hidden  ">


            {/* header - filter list, grid/list view and search , actions  */}
            <Box className="min-h-fit flex flex-col gap-4 overflow-hidden  ">


                {/* search, actions and grid/list view */}
                <Box className={`flex items-center justify-between gap-2 h-fit w-full overflow-hidden`}>

                    {/* textfield */}
                    <Box className="flex items-center justify-between gap-2 w-72 h-fit">

                        <TextField
                            fullWidth
                            placeholder="Search Keyword"
                            color="white"
                            disabled={userStoryList?.length === 0}
                            // size="medium"
                            sx={{
                                // py: "6px",
                                bgcolor: theme.palette.common.white,
                                borderRadius: theme.shape.borderRadius,
                                // px: "4px",
                                // ":root" : {
                                //     bgcolor : theme.palette.common.white
                                // }
                                // background : theme.palette.common.white,
                                '& .MuiInputBase-input': {
                                    // boxSizing: 'border-box',
                                    border: "none",
                                    // background: theme.palette.common.white,
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none'
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    border: 'none'
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    border: 'none'
                                }
                            }}
                            slotProps={{
                                input: {
                                    startAdornment: <InputAdornment position="center">
                                        <Search />
                                    </InputAdornment>,
                                    endAdornment: <InputAdornment position="center">
                                        {isLoading
                                            ?
                                            <CircularProgress size={22} sx={{ visibility: isLoading ? "visible" : "hidden" }} />
                                            :
                                            (searchText && <IconButton onClick={(e) => handleChangeSearch("")}>
                                                <Close />
                                            </IconButton>)
                                        }
                                    </InputAdornment>,
                                }
                            }}
                            value={searchText}
                            onChange={(e) => handleChangeSearch(e.target.value)}
                        />

                        {/* <FilterItemDrawer /> */}

                    </Box>

                    {/* filters list */}
                    {false && <Box className=" h-full w-[38%] flex  overflow-x-auto ">
                        <Box className={` h-fit  flex items-center gap-2 `}>
                            {
                                currentFilters && Object.keys(currentFilters)?.map((key, index) => {
                                    return (
                                        <Box className="flex flex-col items-center px-1 pb-1" bgcolor={theme.palette.background.paper} borderRadius={theme.shape.borderRadius}>
                                            <Typography fontSize={font.size.fs_6} className="flex capitalize">{key}</Typography>
                                            <Box className="flex items-center gap-1">
                                                {Array.isArray(currentFilters[key]) ?
                                                    currentFilters[key]?.map((itemValue, index) => (
                                                        <Box key={index} className="flex items-center gap-1 px-1 border" bgcolor={theme.palette.background.custom} borderRadius={theme.shape.borderRadius}>
                                                            <Typography fontSize={font.size.fs_6} lineHeight={font.size.fs_6}>{itemValue}</Typography>
                                                            <Close sx={{ fontSize: font.size.fs_5, cursor: "pointer" }} onClick={() => handleRemoveFilterItem(key, itemValue)} />
                                                        </Box>
                                                    ))
                                                    :
                                                    (
                                                        Object.keys(currentFilters[key])?.map((ObjectKey, index) => {
                                                            console.log(dayjs(currentFilters[key][ObjectKey]).format("DD-MM-YYYY"), "currentFilters[key][ObjectKey]");
                                                            return (
                                                                <Box key={index} className="flex items-center gap-1 px-1 border" bgcolor={theme.palette.background.custom} borderRadius={theme.shape.borderRadius}>
                                                                    <Typography fontSize={font.size.fs_6} lineHeight={font.size.fs_6} className="whitespace-nowrap">{dayjs(currentFilters[key][ObjectKey]).format("DD-MM-YYYY")}</Typography>
                                                                    <Close sx={{ fontSize: font.size.fs_5, cursor: "pointer" }} onClick={() => handleRemoveFilterItem(ObjectKey, currentFilters[key][ObjectKey])} />
                                                                </Box>
                                                            )
                                                        })
                                                    )
                                                }
                                            </Box>
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                    </Box>}

                    {/* actions buttons */}
                    <Box className=" w-96 h-fit flex items-center justify-between gap-2  ">

                        <Box className="flex items-center gap-2  ">

                            <LightTooltip title={`${openItemFilterDrawer ? "Hide" : "Show"} Filter`}>
                                <Box className="flex items-center ">
                                    <IconButton onClick={() => setOpenItemFilterDrawer(true)}>
                                        <FilterAltOutlined sx={{ color: currentFilters && Object.keys(currentFilters).length > 0 && theme.palette.secondary.main, fontSize: font.size.fs_3, transition: "all .3s ease" }} />
                                    </IconButton>
                                    {currentFilters && Object.keys(currentFilters).length > 0 && <Typography >{`[${currentFilters && Object.keys(currentFilters).length}]`}</Typography>}
                                </Box>
                            </LightTooltip>

                            {/* <Divider orientation="vertical" flexItem /> */}

                            <LightTooltip title={`Label`}>
                                <IconButton disabled={selectedUserStoryList?.length === 0} onClick={(e) => handleToggleActionMenu(e, "label")}>
                                    <LabelOutlined />
                                </IconButton>
                            </LightTooltip>

                            <LightTooltip title={`Properties`}>
                                <IconButton disabled={selectedUserStoryList?.length === 0} onClick={(e) => handleToggleActionMenu(e, "properties")}>
                                    <TrackChanges />
                                </IconButton>
                            </LightTooltip>

                            <LightTooltip title={`Copy`}>
                                <IconButton disabled={selectedUserStoryList?.length === 0} onClick={handleDeleteClick}>
                                    <FileCopyOutlined />
                                </IconButton>
                            </LightTooltip>

                            <LightTooltip title={`Move`}>
                                <IconButton disabled={selectedUserStoryList?.length === 0} onClick={handleDeleteClick}>
                                    <DriveFileMove />
                                </IconButton>
                            </LightTooltip>

                            <LightTooltip title={`Delete`}>
                                <IconButton disabled={selectedUserStoryList?.length === 0} onClick={handleDeleteClick}>
                                    <DeleteOutline />
                                </IconButton>
                            </LightTooltip>

                            <LightTooltip title={`Upload`}>
                                <IconButton disabled  >
                                    <FileUploadOutlined />
                                </IconButton>
                            </LightTooltip>

                            <LightTooltip title={`Download`}>
                                <IconButton disabled >
                                    <FileDownloadOutlined />
                                </IconButton>
                            </LightTooltip>

                            {/* <LightTooltip title={`${isShowStoryFilter ? "Hide Filter" : "Show Filter"}`}>
        <IconButton onClick={() => setIsShowStoryFilter(!isShowStoryFilter)} >
            {isShowStoryFilter ? <FilterAltOffOutlined /> : <FilterAltOutlined />}
        </IconButton>
    </LightTooltip> */}
                        </Box>


                        <Box className=" h-full flex items-center  gap-2  rounded-lg overflow-hidden ">
                            <Paper
                                elevation={0}
                                sx={(theme) => ({
                                    display: 'flex',
                                    border: `1px solid ${theme.palette.divider}`,
                                    flexWrap: 'wrap',
                                })}
                            >
                                <StyledToggleButtonGroup
                                    size="small"
                                    value={isGridViewEnabled}
                                    exclusive
                                    onChange={(e, newValue) => {
                                        console.log(newValue, isGridViewEnabled, newValue !== isGridViewEnabled, "value togglebutton")
                                        if (newValue !== null) {
                                            setIsGridViewEnabled(newValue);
                                        }
                                    }}
                                    aria-label="text alignment"
                                    sx={{ boxSizing: "border-box" }}
                                >

                                    <StyledToggleButton value={true} aria-label="centered">
                                        <LightTooltip title={`Grid View`}>
                                            <GridViewTwoToneIcon />
                                        </LightTooltip>
                                    </StyledToggleButton>

                                    <StyledToggleButton value={false} aria-label="centered">
                                        <LightTooltip title={`List View`}>
                                            <ListTwoToneIcon />
                                        </LightTooltip>
                                    </StyledToggleButton>
                                </StyledToggleButtonGroup>
                            </Paper>
                        </Box>

                    </Box>

                </Box>

                {/* filters */}
                {currentFilters && Object.keys(currentFilters)?.length > 0 && <Box className=" h-fit w-full flex  overflow-x-auto ">
                    <Box className={` h-fit w-full flex items-center gap-2 overflow-x-auto `}>
                        {
                            currentFilters && Object.keys(currentFilters)?.map((key, index) => {
                                return (
                                    <Box className="flex flex-col items-center px-1 pb-1" bgcolor={theme.palette.background.paper} borderRadius={theme.shape.borderRadius}>
                                        <Typography fontSize={font.size.fs_6} className="flex capitalize">{key}</Typography>
                                        <Box className="flex items-center gap-1">
                                            {Array.isArray(currentFilters[key]) ?
                                                currentFilters[key]?.map((itemValue, index) => (
                                                    <Box key={index} className="flex items-center gap-1 px-1 border" bgcolor={theme.palette.background.custom} borderRadius={theme.shape.borderRadius}>
                                                        <Typography fontSize={font.size.fs_6} lineHeight={font.size.fs_6}>{itemValue}</Typography>
                                                        <Close sx={{ fontSize: font.size.fs_5, cursor: "pointer" }} onClick={() => handleRemoveFilterItem(key, itemValue)} />
                                                    </Box>
                                                ))
                                                :
                                                (
                                                    Object.keys(currentFilters[key])?.map((ObjectKey, index) => {
                                                        console.log(dayjs(currentFilters[key][ObjectKey]).format("DD-MM-YYYY"), "currentFilters[key][ObjectKey]");
                                                        return (
                                                            <Box key={index} className="flex items-center gap-1 px-1 border" bgcolor={theme.palette.background.custom} borderRadius={theme.shape.borderRadius}>
                                                                <Typography fontSize={font.size.fs_6} lineHeight={font.size.fs_6} className="whitespace-nowrap">{dayjs(currentFilters[key][ObjectKey]).format("DD-MM-YYYY")}</Typography>
                                                                <Close sx={{ fontSize: font.size.fs_5, cursor: "pointer" }} onClick={() => handleRemoveFilterItem(ObjectKey, currentFilters[key][ObjectKey])} />
                                                            </Box>
                                                        )
                                                    })
                                                )
                                            }
                                        </Box>
                                    </Box>
                                )
                            })
                        }
                    </Box>
                </Box>}

                {/* filters */}
                {false && isShowFilters && <Box className={`flex items-center gap-4 h-fit `}>
                    <Box className={`flex items-center gap-4 h-[20px] `}>
                        <FormControl size="small" variant="outlined" sx={{ height: "100%", width: "7rem", }}>
                            <InputLabel
                                size="small"
                                shrink
                            >
                                Status
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                displayEmpty
                                name="status"
                                label="Status"
                                value={(currentFilters && currentFilters["status"]) || ""}
                                onChange={(e) => handleChangeColumnFilter(e, "status")}
                                MenuProps={{
                                    MenuListProps: {
                                        style: {
                                            maxHeight: "200px"
                                        }
                                    }
                                }}
                                sx={{
                                    height: "100%",
                                    bgcolor: theme.palette.common.white,
                                    '& .MuiSelect-select': {
                                        // padding: '8px 14px',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }
                                }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>

                                {
                                    [StoryStatus.pending, StoryStatus.inprogress, StoryStatus.completed]?.map((item, index) => {
                                        return <MenuItem key={index} value={item}>{item}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>

                        <FormControl size="small" variant="outlined" sx={{ height: "100%", width: "11rem" }}>
                            <InputLabel
                                size="small"
                                shrink
                            >
                                Created At
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                displayEmpty
                                name="createdAt"
                                label="Created At"
                                value={(currentFilters && currentFilters["createdAt"]) || ""}
                                onChange={(e) => handleChangeColumnFilter(e, "createdAt")}
                                MenuProps={{
                                    MenuListProps: {
                                        style: {
                                            maxHeight: "200px"
                                        }
                                    }
                                }}
                                sx={{
                                    height: "100%",
                                    bgcolor: theme.palette.common.white,
                                    '& .MuiSelect-select': {
                                        // padding: '8px 14px',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }
                                }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>

                                {
                                    [...new Set(userStoryList?.map(item => item?.createdAt))]?.map((item, index) => {
                                        return <MenuItem key={index} value={item}>{dayjs(item).format("DD MMM YYYY HH:mm A")}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Box>


                    <LightTooltip title={`Clear Filters`}>
                        <IconButton disabled={!currentFilters} onClick={() => setCurrentFilters(null)}>
                            <FilterAltOffOutlined />
                        </IconButton>
                    </LightTooltip>


                </Box>}


            </Box>

            {/* user story */}
            <Box className={`flex w-full  ${isGridViewEnabled ? "h-full" : "h-full"} overflow-y-auto `} sx={{}}>

                {isLoading || isUserStoryLoading ? (
                    <Box className={`grow flex flex-col gap-2 items-center justify-center `}>
                        <CircularProgress />
                        <Typography fontSize={font.size.fs_5}>Loading...</Typography>
                    </Box>
                )
                    :
                    finalList?.length === 0 ?
                        <Box className="grow flex flex-col gap-2 justify-center items-center ">
                            <Description sx={{ fontSize: font.size.fs_1 }} />
                            <Typography fontSize={font.size.fs_5}> Add {Entity_Label.userStory}</Typography>
                        </Box>
                        :
                        (
                            <Box className={` flex w-full  ${isGridViewEnabled ? "" : " "}  `}>
                                {isGridViewEnabled ?
                                    <UserStoryGrid dataList={finalList} isLoading={isUserStoryLoading} />
                                    : <UserStoryList dataList={finalList} isLoading={isUserStoryLoading} />
                                }
                            </Box>
                        )
                }

            </Box>


            {/* action menu */}
            <Popover
                open={Boolean(actionIconAnchorEl)}
                anchorEl={actionIconAnchorEl}
                onClose={(e) => handleToggleActionMenu(e, null)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <Box>
                    {actionType === "label" && ["red", "blue", "green", "grey", "brown"].map((item, index) => {
                        return <MenuItem>
                            <LabelOutlined sx={{ color: item }} />
                        </MenuItem>
                    })}


                    <Box className="flex items-center px-4 py-2 border-b">
                        <Typography color={theme.palette.text.primary} fontSize={font.size.fs_4}>Properties</Typography>
                    </Box>

                    {/* (actionType === "state" || actionType === "type" || actionType === "priority" || actionType === "confidentiality" || actionType === "moscow") && */}
                    {actionType === "properties" &&
                        <Box className="flex gap-0 divide-x">

                            {
                                userStoryPropertiesData && Object.keys(userStoryPropertiesData).map((key) => (
                                    <Box key={key} className="flex flex-col">

                                        <Typography fontSize={font.size.fs_5} color={theme.palette.text.primary} className="px-4 py-1 capitalize border-b">{key}</Typography>
                                        <Box className="w-full h-full flex flex-col " >
                                            {
                                                userStoryPropertiesData[key]?.map((item, index) => (
                                                    <MenuItem key={index} selected={selectedPropertiesData && (key in selectedPropertiesData) && selectedPropertiesData[key] && selectedPropertiesData[key] === item?.key} onClick={() => { handleSelectProperites(key, item?.key) }}>

                                                        <Typography fontSize={font.size.fs_5} color={theme.palette.text.secondary} className="">{item?.value}</Typography>
                                                    </MenuItem>
                                                ))
                                            }
                                        </Box>
                                    </Box>
                                ))
                            }
                        </Box>
                    }

                    <Box className="flex items-center justify-end px-4 py-2 border-t">
                        <Button variant="text" onClick={(e) => handleCancelPropertiesData()}>Cancel</Button>
                        <Button variant="contained" color="secondary" disabled={isLoading} sx={{ color: theme.palette.secondary.contrastText }} onClick={handleApplyPropertiesData}>Apply</Button>
                    </Box>


                </Box>
            </Popover>

        </Box>
    )
}

function UseCaseContainerComponent() {
    const {
        isGridViewEnabled, setIsGridViewEnabled,
        handleSnackbar,
        currentFolderData, setCurrentFolderData,
        userStoryList, setUserStoryList,
        currentUserStoryData, setCurrentUserStoryData,
        selectedUserStoryList, setSelectedUserStoryList,
        isUserStoryLoading, setIsUserStoryLoading,
        filteredUserStoryList, setFilteredUserStoryList,
        openDeleteStoryDialog, setOpenDeleteStoryDialog,
        openCreateStoryDialog,
        useCaseList, setUseCaseList,
        currentUseCaseData, setCurrentUseCaseData,
        filteredUseCaseList, setFilteredUseCaseList,
        isActiveProjectContainerLoading, setIsActiveProjectContainerLoading,
        selectedUseCaseList, setSelectedUseCaseList,
        setOpenDeleteUseCaseDialog,
        currentFilters, setCurrentFilters,
        openItemFilterDrawer, setOpenItemFilterDrawer,
        useCasePropertiesData, setUseCasePropertiesData,
        activeProject
    } = useApplicationContext();
    const theme = useTheme();
    const errorHandler = useErrorHandler();
    const pathName = useLocation().pathname;

    const isTabletScreen = useMediaQuery((theme) => theme.breakpoints.down('xl'));
    let timer;

    const [isLoading, setIsLoading] = useState(false);
    const [isShowFilters, setIsShowFilters] = useState(true);
    const [actionType, setActionType] = useState("label");
    const [actionIconAnchorEl, setActionIconAnchorEl] = useState(null);

    const [searchText, setSearchText] = useState("");
    // const [currentFilters, setCurrentFilters] = useState(null); // To store the table filters

    const [finalList, setFinalList] = useState(filteredUseCaseList); // To store the final filtered -> searched list
    const [selectedPropertiesData, setSelectedPropertiesData] = useState(null); // To store the selected properties values

    const [priorityList, setPriorityList] = useState([]);
    const [typeList, setTypeList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [statusList, setStatusList] = useState([]);

    const [activeFilterData, setActiveFilterData] = useState({
        status: "all",
    });

    const handleGetGridComponent = (list,) => {
        switch (pathName) {
            case ModulePath.useCase: return <UseCaseGrid dataList={list} isLoading={false} />;
            case ModulePath.testCase: return <UseCaseGrid dataList={list} isLoading={false} />;
        }
    }

    const handleChangeSearch = (value) => {
        setSearchText(value);
        clearTimeout(timer);

        setTimeout(() => {
            setIsLoading(true);
        }, 400);

        timer = setTimeout(() => {
            // setSearchText(value);
            setIsLoading(false);
        }, 800);
    };

    const handleDeleteClick = () => {
        setOpenDeleteUseCaseDialog(true);
        console.log(openDeleteStoryDialog, "true")
    }

    const handleChangeFilter = (e) => {
        setActiveFilterData({ ...activeFilterData, [e.target.name]: e.target.value });
    }

    const handleToggleActionMenu = (event, actionType) => {

        if (!actionIconAnchorEl) {
            setActionIconAnchorEl(event.currentTarget)
            setActionType(actionType)
        } else {
            setActionIconAnchorEl(null)
        }
    }

    const handleRemoveFilterItem = (key, value) => {
        let filter = currentFilters ? { ...currentFilters } : {};

        if (Array.isArray(filter[key])) {
            filter[key] = currentFilters[key].filter((item) => item !== value);
            if (filter[key].length === 0) {
                delete filter[key]
            }
        } else {
            if (key === "from") {
                delete filter["createdAt"][key]
            }

            if (key === "to") {
                delete filter["createdAt"][key]
            }

            if (filter["createdAt"] && Object.keys(filter["createdAt"])?.length === 0) {
                delete filter["createdAt"]
            }

            else {
                delete filter[key]
            }
        }


        if (filter && Object.keys(filter)?.length === 0) {
            setCurrentFilters(null)
            return;
        }


        // delete filter[value];
        setCurrentFilters(filter)
    }

    const handleGetFilterCount = (value) => {
        if (value === Filters.status) {
            return currentFilters?.status?.length
        }

        if (value === Filters.createdAt) {
            return currentFilters?.createdAt?.from && currentFilters?.createdAt?.to ? 2 : currentFilters?.createdAt?.from ? 1 : currentFilters?.createdAt?.to ? 1 : 0
        }
    }

    const handleChangeColumnFilter = (event, key) => {
        const filterData = currentFilters ? { ...currentFilters } : {};

        if (event.target.value === '') {
            delete filterData[key];
        } else {
            filterData[key] = event.target.value;
        }

        setCurrentFilters(filterData);
    };

    const handleSelectProperites = (key, value) => {
        setSelectedPropertiesData({ ...selectedPropertiesData, [key]: value });
    }

    const handleCancelPropertiesData = () => {
        setSelectedPropertiesData(null);
        setActionIconAnchorEl(null);
    }


    const handleApplyPropertiesData = () => {
        let body = {
            "customIds": [selectedUseCaseList?.map((item) => item?.customId)],
            "folderPath": currentFolderData?.path,
            "projectName": activeProject?.name,
        }

        body = { ...body, ...selectedPropertiesData };

        handleUpdateStory(body)

    }


    const handleUpdateStory = async (storyData) => {
        try {
            setIsActiveProjectContainerLoading(true);

            let body = {
                ...storyData,
            }


            const bodyFormData = new FormData();
            Object.keys(body).forEach(key => {
                if (Array.isArray(body[key])) {
                    bodyFormData.append(key, body[key]);
                } else {
                    bodyFormData.append(key, body[key]);
                }
            });



            const response = await updateUseCase_API(bodyFormData);
            console.log(response, "response handleUpdateStory")

            if (response) {
                errorHandler(response, setIsActiveProjectContainerLoading);
            }

            if (response?.status === HttpStatusCode.Ok) {
                let updatedList = response.data;

                updatedList?.forEach((item) => {
                    let list = useCaseList;
                    let foundIndex = list?.findIndex((listItem) => listItem?.customId === item?.customId);
                    if (foundIndex !== -1) {
                        list[foundIndex] = item;
                    }
                    setUseCaseList(list);
                })

                setSelectedUseCaseList([]);
                setActionIconAnchorEl(null);

                setTimeout(() => {
                    handleSnackbar("UseCase updated successfully", Severity.success);
                }, 500)

            }


            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setIsActiveProjectContainerLoading(false);
            }, 1000);
        }
    }

    useEffect(() => {
        let list = filteredUseCaseList;

        if (searchText) {
            list = list?.filter((item) =>
            (
                item?.customId?.toLowerCase()?.includes(searchText.toLowerCase()) ||
                item?.title?.toLowerCase()?.includes(searchText.toLowerCase()) ||
                item?.state?.toLowerCase()?.includes(searchText.toLowerCase()) ||
                item?.status?.toLowerCase()?.includes(searchText.toLowerCase()) ||
                item?.priority?.toLowerCase()?.includes(searchText.toLowerCase()) ||
                item?.type?.toLowerCase()?.includes(searchText.toLowerCase())
            )
            );
        }

        setFinalList(list);
    }, [searchText, filteredUseCaseList, setFinalList]);

    // useEffect(() => {
    //     let list = useCaseList;

    //     setIsLoading(true);

    //     if (activeFilterData?.status) {
    //         list = list?.filter((item) => activeFilterData?.status?.toLowerCase() === "all" ? true : item?.status?.toLowerCase() === activeFilterData?.status?.toLowerCase());
    //     }

    //     setFilteredUseCaseList(list);

    //     setTimeout(() => {
    //         setIsLoading(false);
    //     }, 800);

    //     console.log(list, "filteredList")

    // }, [activeFilterData?.status,])

    useEffect(() => {
        setIsLoading(isUserStoryLoading);
    }, [isActiveProjectContainerLoading, setIsActiveProjectContainerLoading]);


    useEffect(() => {
        setIsLoading(true);
        if (currentFilters) {

            const filteredRows = useCaseList?.filter((row) => Object.entries(currentFilters).every(([column, value]) => {
                console.log(column, value, row, "column, value, row")
                if (!value) return true;
                if (Array.isArray(value)) {
                    return value.includes(row[column]?.toString());
                } else {
                    if (column === "createdAt") {
                        if (value.from && value.to) {
                            return dayjs(row["createdAt"]).isAfter(dayjs(value.from), 'day') && dayjs(row["createdAt"]).isBefore(dayjs(value.to), 'day');
                        } else if (value.from) {
                            return dayjs(row["createdAt"]).isAfter(dayjs(value.from), 'day') || dayjs(row["createdAt"]).isSame(dayjs(value.from), 'day');
                        } else if (value.to) {
                            return dayjs(row["createdAt"]).isBefore(dayjs(value.to), 'day') || dayjs(row["createdAt"]).isSame(dayjs(value.to), 'day');
                        } else {
                            return false;
                        }
                    } else {
                        return row[column]?.toString().toLowerCase().includes(value.toLowerCase());
                    }
                }
            }));
            setFilteredUseCaseList(filteredRows);
            console.log(filteredRows, "filteredRows");
        } else {
            setFilteredUseCaseList(useCaseList);
        }


        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }, [currentFilters, useCaseList, setFilteredUseCaseList]);

    // useEffect(() => {
    //     let list = filteredUserStoryList;

    //     if (searchText) {
    //         list = list?.filter((item) => (item?.customId?.toLowerCase()?.includes(searchText.toLowerCase()) || item?.title?.toLowerCase()?.includes(searchText.toLowerCase())));
    //     }

    //     setFinalList(list);
    // }, [searchText, filteredUserStoryList, setFinalList]);


    useEffect(() => {
        setFilteredUseCaseList(useCaseList);
    }, [useCaseList, setFilteredUseCaseList]);


    useEffect(() => {
        if (useCasePropertiesData) {
            setTypeList(useCasePropertiesData["type"]);
            setStateList(useCasePropertiesData["state"]);
            setStatusList(useCasePropertiesData["status"]);
            setPriorityList(useCasePropertiesData["priority"]);
        }

    }, [useCasePropertiesData])

    console.log(isTabletScreen, "isTabletScreen");

    return (
        <Box className="flex flex-col gap-4 w-full h-full overflow-hidden  ">


            {/* header - filter list, grid/list view and search , actions  */}
            <Box className="flex flex-col gap-4 ">


                {/* search  */}
                <Box className={`flex items-center justify-between gap-2 min-h-fit `}>

                    <Box className="flex items-center justify-between gap-2 w-72 ">

                        <TextField
                            fullWidth
                            placeholder="Search Keyword"
                            color="white"
                            disabled={userStoryList?.length === 0}
                            // size="medium"
                            sx={{
                                // py: "6px",
                                bgcolor: theme.palette.common.white,
                                borderRadius: theme.shape.borderRadius,
                                // px: "4px",
                                // ":root" : {
                                //     bgcolor : theme.palette.common.white
                                // }
                                // background : theme.palette.common.white,
                                '& .MuiInputBase-input': {
                                    // boxSizing: 'border-box',
                                    border: "none",
                                    // background: theme.palette.common.white,
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none'
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    border: 'none'
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    border: 'none'
                                }
                            }}
                            slotProps={{
                                input: {
                                    startAdornment: <InputAdornment position="center">
                                        <Search />
                                    </InputAdornment>,
                                    endAdornment: <InputAdornment position="center">
                                        {isLoading
                                            ?
                                            <CircularProgress size={22} sx={{ visibility: isLoading ? "visible" : "hidden" }} />
                                            :
                                            (searchText && <IconButton onClick={(e) => handleChangeSearch("")}>
                                                <Close />
                                            </IconButton>)
                                        }
                                    </InputAdornment>,
                                }
                            }}
                            value={searchText}
                            onChange={(e) => handleChangeSearch(e.target.value)}
                        />

                        {/* <FilterItemDrawer /> */}

                    </Box>


                    {/* filters list */}
                    {false && <Box className=" h-full w-[38%] flex  overflow-x-auto ">
                        <Box className={` h-fit  flex items-center gap-2 `}>
                            {
                                currentFilters && Object.keys(currentFilters)?.map((key, index) => {
                                    return (
                                        <Box className="flex flex-col items-center px-1 pb-1" bgcolor={theme.palette.background.paper} borderRadius={theme.shape.borderRadius}>
                                            <Typography fontSize={font.size.fs_6} className="flex capitalize">{key}</Typography>
                                            <Box className="flex items-center gap-1">
                                                {Array.isArray(currentFilters[key]) ?
                                                    currentFilters[key]?.map((itemValue, index) => (
                                                        <Box key={index} className="flex items-center gap-1 px-1 border" bgcolor={theme.palette.background.custom} borderRadius={theme.shape.borderRadius}>
                                                            <Typography fontSize={font.size.fs_6} lineHeight={font.size.fs_6}>{itemValue}</Typography>
                                                            <Close sx={{ fontSize: font.size.fs_5, cursor: "pointer" }} onClick={() => handleRemoveFilterItem(key, itemValue)} />
                                                        </Box>
                                                    ))
                                                    :
                                                    (
                                                        Object.keys(currentFilters[key])?.map((ObjectKey, index) => {
                                                            console.log(dayjs(currentFilters[key][ObjectKey]).format("DD-MM-YYYY"), "currentFilters[key][ObjectKey]");
                                                            return (
                                                                <Box key={index} className="flex items-center gap-1 px-1 border" bgcolor={theme.palette.background.custom} borderRadius={theme.shape.borderRadius}>
                                                                    <Typography fontSize={font.size.fs_6} lineHeight={font.size.fs_6} className="whitespace-nowrap">{dayjs(currentFilters[key][ObjectKey]).format("DD-MM-YYYY")}</Typography>
                                                                    <Close sx={{ fontSize: font.size.fs_5, cursor: "pointer" }} onClick={() => handleRemoveFilterItem(ObjectKey, currentFilters[key][ObjectKey])} />
                                                                </Box>
                                                            )
                                                        })
                                                    )
                                                }
                                            </Box>
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                    </Box>}


                    <Box className="flex items-center justify-between gap-2  ">

                        <Box className="flex items-center gap-2  ">

                            <LightTooltip title={`${openItemFilterDrawer ? "Hide" : "Show"} Filter`}>
                                <Box className="flex items-center ">

                                    <IconButton onClick={() => setOpenItemFilterDrawer(true)}>
                                        {/* <Badge badgeContent={currentFilters && Object.keys(currentFilters).length} color="secondary" anchorOrigin={{ vertical: 'top', horizontal: 'right' }}> */}
                                        <FilterAltOutlined sx={{ color: currentFilters && Object.keys(currentFilters).length > 0 && theme.palette.secondary.main, fontSize: font.size.fs_3, transition: "all .3s ease" }} />
                                        {/* </Badge> */}
                                    </IconButton>

                                    {currentFilters && Object.keys(currentFilters).length > 0 && <Typography >{`[${currentFilters && Object.keys(currentFilters).length}]`}</Typography>}
                                </Box>
                            </LightTooltip>

                            <Divider orientation="vertical" flexItem />

                            <LightTooltip title={`Properties`}>
                                <IconButton disabled={selectedUseCaseList?.length === 0} onClick={(e) => handleToggleActionMenu(e, "properties")}>
                                    <TrackChanges />
                                </IconButton>
                            </LightTooltip>

                            <LightTooltip title={`Copy`}>
                                <IconButton disabled={selectedUseCaseList?.length === 0} onClick={handleDeleteClick}>
                                    <FileCopyOutlined />
                                </IconButton>
                            </LightTooltip>

                            <LightTooltip title={`Move`}>
                                <IconButton disabled={selectedUseCaseList?.length === 0} onClick={handleDeleteClick}>
                                    <DriveFileMove />
                                </IconButton>
                            </LightTooltip>


                            <LightTooltip title={`Delete`}>
                                <IconButton disabled={selectedUseCaseList?.length === 0} onClick={handleDeleteClick}>
                                    <DeleteOutline />
                                </IconButton>
                            </LightTooltip>

                            <LightTooltip title={`Upload`}>
                                <IconButton disabled >
                                    <FileUploadOutlined />
                                </IconButton>
                            </LightTooltip>

                            <LightTooltip title={`Download`}>
                                <IconButton disabled >
                                    <FileDownloadOutlined />
                                </IconButton>
                            </LightTooltip>

                            {/* <LightTooltip title={`${isShowStoryFilter ? "Hide Filter" : "Show Filter"}`}>
        <IconButton onClick={() => setIsShowStoryFilter(!isShowStoryFilter)} >
            {isShowStoryFilter ? <FilterAltOffOutlined /> : <FilterAltOutlined />}
        </IconButton>
    </LightTooltip> */}
                        </Box>


                        <Box className=" h-full flex items-center  gap-2  rounded-lg overflow-hidden ">
                            <Paper
                                elevation={0}
                                sx={(theme) => ({
                                    display: 'flex',
                                    border: `1px solid ${theme.palette.divider}`,
                                    flexWrap: 'wrap',
                                })}
                            >
                                <StyledToggleButtonGroup
                                    size="small"
                                    value={isGridViewEnabled}
                                    exclusive
                                    onChange={(e, newValue) => {
                                        console.log(newValue, isGridViewEnabled, newValue !== isGridViewEnabled, "value togglebutton")
                                        if (newValue !== null) {
                                            setIsGridViewEnabled(newValue);
                                        }
                                    }}
                                    aria-label="text alignment"
                                    sx={{ boxSizing: "border-box" }}
                                >

                                    <StyledToggleButton value={true} aria-label="centered">
                                        <LightTooltip title={`Grid View`}>
                                            <GridViewTwoToneIcon />
                                        </LightTooltip>
                                    </StyledToggleButton>

                                    <StyledToggleButton value={false} aria-label="centered">
                                        <LightTooltip title={`List View`}>
                                            <ListTwoToneIcon />
                                        </LightTooltip>
                                    </StyledToggleButton>
                                </StyledToggleButtonGroup>
                            </Paper>
                        </Box>

                    </Box>

                </Box>

                {/* filters */}
                {currentFilters && Object.keys(currentFilters)?.length > 0 && <Box className=" h-fit w-full flex  overflow-x-auto ">
                    <Box className={` h-fit w-full flex items-center gap-2 overflow-x-auto `}>
                        {
                            currentFilters && Object.keys(currentFilters)?.map((key, index) => {
                                return (
                                    <Box className="flex flex-col items-center px-1 pb-1" bgcolor={theme.palette.background.paper} borderRadius={theme.shape.borderRadius}>
                                        <Typography fontSize={font.size.fs_6} className="flex capitalize">{key}</Typography>
                                        <Box className="flex items-center gap-1">
                                            {Array.isArray(currentFilters[key]) ?
                                                currentFilters[key]?.map((itemValue, index) => (
                                                    <Box key={index} className="flex items-center gap-1 px-1 border" bgcolor={theme.palette.background.custom} borderRadius={theme.shape.borderRadius}>
                                                        <Typography fontSize={font.size.fs_6} lineHeight={font.size.fs_6}>{itemValue}</Typography>
                                                        <Close sx={{ fontSize: font.size.fs_5, cursor: "pointer" }} onClick={() => handleRemoveFilterItem(key, itemValue)} />
                                                    </Box>
                                                ))
                                                :
                                                (
                                                    Object.keys(currentFilters[key])?.map((ObjectKey, index) => {
                                                        console.log(dayjs(currentFilters[key][ObjectKey]).format("DD-MM-YYYY"), "currentFilters[key][ObjectKey]");
                                                        return (
                                                            <Box key={index} className="flex items-center gap-1 px-1 border" bgcolor={theme.palette.background.custom} borderRadius={theme.shape.borderRadius}>
                                                                <Typography fontSize={font.size.fs_6} lineHeight={font.size.fs_6} className="whitespace-nowrap">{dayjs(currentFilters[key][ObjectKey]).format("DD-MM-YYYY")}</Typography>
                                                                <Close sx={{ fontSize: font.size.fs_5, cursor: "pointer" }} onClick={() => handleRemoveFilterItem(ObjectKey, currentFilters[key][ObjectKey])} />
                                                            </Box>
                                                        )
                                                    })
                                                )
                                            }
                                        </Box>
                                    </Box>
                                )
                            })
                        }
                    </Box>
                </Box>}

                {/* filters */}
                {false && isShowFilters && <Box className={`flex items-center gap-4 h-fit `}>
                    <Box className={`flex items-center gap-4 h-[20px] `}>
                        {false && <FormControl size="small" variant="outlined" sx={{ height: "100%", width: "7rem", }}>
                            <InputLabel
                                size="small"
                                shrink
                            >
                                Status
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                displayEmpty
                                name="status"
                                label="Status"
                                value={(currentFilters && currentFilters["status"]) || ""}
                                onChange={(e) => handleChangeColumnFilter(e, "status")}
                                MenuProps={{
                                    MenuListProps: {
                                        style: {
                                            maxHeight: "200px"
                                        }
                                    }
                                }}
                                sx={{
                                    height: "100%",
                                    bgcolor: theme.palette.common.white,
                                    '& .MuiSelect-select': {
                                        // padding: '8px 14px',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }
                                }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>

                                {
                                    [StoryStatus.pending, StoryStatus.inprogress, StoryStatus.completed]?.map((item, index) => {
                                        return <MenuItem key={index} value={item}>{item}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>}


                        <FormControl size="small" variant="outlined" sx={{ height: "100%", width: "11rem" }}>
                            <InputLabel
                                size="small"
                                shrink
                            >
                                Created At
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                displayEmpty
                                name="createdAt"
                                label="Created At"
                                value={(currentFilters && currentFilters["createdAt"]) || ""}
                                onChange={(e) => handleChangeColumnFilter(e, "createdAt")}
                                MenuProps={{
                                    MenuListProps: {
                                        style: {
                                            maxHeight: "200px"
                                        }
                                    }
                                }}
                                sx={{
                                    height: "100%",
                                    bgcolor: theme.palette.common.white,
                                    '& .MuiSelect-select': {
                                        // padding: '8px 14px',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }
                                }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>

                                {
                                    [...new Set(userStoryList?.map(item => item?.createdAt))]?.map((item, index) => {
                                        return <MenuItem key={index} value={item}>{dayjs(item).format("DD MMM YYYY HH:mm A")}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Box>


                    <LightTooltip title={`Clear Filters`}>
                        <IconButton disabled={!currentFilters} onClick={() => setCurrentFilters(null)}>
                            <FilterAltOffOutlined />
                        </IconButton>
                    </LightTooltip>


                </Box>}


                <Popover
                    open={Boolean(actionIconAnchorEl)}
                    anchorEl={actionIconAnchorEl}
                    onClose={(e) => handleToggleActionMenu(e, null)}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    <Box>
                        {actionType === "label" && ["red", "blue", "green", "grey", "brown"].map((item, index) => {
                            return <MenuItem>
                                <LabelOutlined sx={{ color: item }} />
                            </MenuItem>
                        })}


                        <Box className="flex items-center px-4 py-2 border-b">
                            <Typography color={theme.palette.text.primary} fontSize={font.size.fs_4}>Properties</Typography>
                        </Box>

                        {/* (actionType === "state" || actionType === "type" || actionType === "priority" || actionType === "confidentiality" || actionType === "moscow") && */}
                        {actionType === "properties" &&
                            <Box className="flex gap-0 divide-x">

                                {
                                    useCasePropertiesData && Object.keys(useCasePropertiesData).map((key) => (
                                        <Box key={key} className="flex flex-col">

                                            <Typography fontSize={font.size.fs_5} color={theme.palette.text.primary} className="px-4 py-1 capitalize border-b">{key}</Typography>
                                            <Box className="w-full h-full flex flex-col " >
                                                {
                                                    useCasePropertiesData[key]?.map((item, index) => (
                                                        <MenuItem key={index} selected={selectedPropertiesData && (key in selectedPropertiesData) && selectedPropertiesData[key] && selectedPropertiesData[key] === item?.key} onClick={() => { handleSelectProperites(key, item?.key) }}>

                                                            <Typography fontSize={font.size.fs_5} color={theme.palette.text.secondary} className="">{item?.value}</Typography>
                                                        </MenuItem>
                                                    ))
                                                }
                                            </Box>
                                        </Box>
                                    ))
                                }
                            </Box>
                        }

                        <Box className="flex items-center justify-end px-4 py-2 border-t">
                            <Button variant="text" onClick={(e) => handleCancelPropertiesData()}>Cancel</Button>
                            <Button variant="contained" color="secondary" sx={{ color: theme.palette.secondary.contrastText }} onClick={handleApplyPropertiesData}>Apply</Button>
                        </Box>


                    </Box>
                </Popover>

            </Box>

            {/* list container */}
            <Box className={`flex w-full  ${isGridViewEnabled ? "h-full" : "h-full"} overflow-y-auto `}>
                {isLoading || isActiveProjectContainerLoading ? (
                    <Box className={`grow flex flex-col gap-2 items-center justify-center `}>
                        <CircularProgress />
                        <Typography fontSize={font.size.fs_5}>Loading...</Typography>
                    </Box>
                )
                    :
                    finalList?.length === 0 ?
                        <Box className="grow flex flex-col gap-2 justify-center items-center ">
                            <Description sx={{ fontSize: font.size.fs_1 }} />
                            <Typography fontSize={font.size.fs_5}> Add {pathName === ModulePath.useCase ? Entity_Label.useCase : Entity_Label.testCase}</Typography>
                        </Box>
                        :
                        (
                            <Box className={` flex w-full    `}>
                                {isGridViewEnabled ?
                                    <>
                                        <UseCaseGrid dataList={finalList} isLoading={false} />
                                    </>
                                    : <>
                                        <UseCaseList dataList={finalList} isLoading={false} />
                                    </>
                                }
                            </Box>
                        )
                }

            </Box>


        </Box>
    )
}


function TestCaseContainerComponent() {
    const { isGridViewEnabled, setIsGridViewEnabled, handleSnackbar, currentFolderData, setCurrentFolderData, userStoryList, setUserStoryList, currentUserStoryData, setCurrentUserStoryData, isShowStoryFilter, setIsShowStoryFilter, selectedUserStoryList, setSelectedUserStoryList, isUserStoryLoading, setIsUserStoryLoading, filteredUserStoryList, setFilteredUserStoryList, openDeleteStoryDialog, setOpenDeleteStoryDialog, openCreateStoryDialog,
        useCaseList, setUseCaseList,
        currentUseCaseData, setCurrentUseCaseData,
        filteredUseCaseList, setFilteredUseCaseList,
        isActiveProjectContainerLoading, setIsActiveProjectContainerLoading,
        selectedUseCaseList, setSelectedUseCaseList,
        setOpenDeleteUseCaseDialog,
        currentFilters, setCurrentFilters,
        openItemFilterDrawer, setOpenItemFilterDrawer
    } = useApplicationContext();
    const theme = useTheme();
    const isTabletScreen = useMediaQuery((theme) => theme.breakpoints.down('xl'));
    let timer;

    const [isLoading, setIsLoading] = useState(false);
    const [isShowFilters, setIsShowFilters] = useState(true);

    const [searchText, setSearchText] = useState("");
    // const [currentFilters, setCurrentFilters] = useState(null); // To store the table filters

    const [finalList, setFinalList] = useState(filteredUserStoryList); // To store the final filtered -> searched list

    const [activeFilterData, setActiveFilterData] = useState({
        status: "all",
    });

    const handleChangeSearch = (e) => {
        clearTimeout(timer);
        const userInput = e.target.value;

        setTimeout(() => {
            setIsLoading(true);
        }, 400);

        timer = setTimeout(() => {
            setSearchText(userInput);
            setIsLoading(false);
        }, 800);
    };

    const handleDeleteClick = () => {
        setOpenDeleteUseCaseDialog(true);
        console.log(openDeleteStoryDialog, "true")
    }

    const handleChangeFilter = (e) => {
        setActiveFilterData({ ...activeFilterData, [e.target.name]: e.target.value });
    }

    const handleRemoveFilter = (value) => {
        let filter = currentFilters ? { ...currentFilters } : {};
        delete filter[value];
        setCurrentFilters(filter)
    }

    const handleGetFilterCount = (value) => {
        if (value === Filters.status) {
            return currentFilters?.status?.length
        }

        if (value === Filters.createdAt) {
            return currentFilters?.createdAt?.from && currentFilters?.createdAt?.to ? 2 : currentFilters?.createdAt?.from ? 1 : currentFilters?.createdAt?.to ? 1 : 0
        }
    }

    const handleChangeColumnFilter = (event, key) => {
        const filterData = currentFilters ? { ...currentFilters } : {};

        if (event.target.value === '') {
            delete filterData[key];
        } else {
            filterData[key] = event.target.value;
        }

        setCurrentFilters(filterData);
    };


    useEffect(() => {
        let filteredList = useCaseList;

        if (searchText) {
            filteredList = useCaseList?.filter((item) => (item?.customId?.toLowerCase()?.includes(searchText.toLowerCase()) || item?.title?.toLowerCase()?.includes(searchText.toLowerCase())));
        }

        setFilteredUseCaseList(filteredList);
    }, [searchText, useCaseList, setFilteredUseCaseList]);

    useEffect(() => {
        let list = useCaseList;

        setIsLoading(true);

        if (activeFilterData?.status) {
            list = list?.filter((item) => activeFilterData?.status?.toLowerCase() === "all" ? true : item?.status?.toLowerCase() === activeFilterData?.status?.toLowerCase());
        }

        setFilteredUseCaseList(list);

        setTimeout(() => {
            setIsLoading(false);
        }, 800);

        console.log(list, "filteredList")

    }, [activeFilterData?.status,])

    useEffect(() => {
        setIsLoading(isUserStoryLoading);
    }, [isActiveProjectContainerLoading, setIsActiveProjectContainerLoading]);

    useEffect(() => {
        if (currentFilters) {
            const filteredRows = filteredUserStoryList?.filter((row) =>
                Object.entries(currentFilters).every(([column, value]) => {
                    if (!value) return true;
                    if (Array.isArray(value)) {
                        return value.includes(row[column]?.toString());
                    } else {
                        return row[column]?.toString().toLowerCase().includes(value.toLowerCase());
                    }
                })
            );
            setFinalList(filteredRows);
            console.log(filteredRows, "filteredRows");
        }
    }, [currentFilters, filteredUserStoryList]);

    useEffect(() => {
        let list = filteredUserStoryList;

        if (searchText) {
            list = list?.filter((item) => (item?.customId?.toLowerCase()?.includes(searchText.toLowerCase()) || item?.title?.toLowerCase()?.includes(searchText.toLowerCase())));
        }

        setFinalList(list);
    }, [searchText, filteredUserStoryList, setFinalList]);


    useEffect(() => {
        setFilteredUserStoryList(userStoryList);
    }, [userStoryList, setFilteredUserStoryList]);

    console.log(isTabletScreen, "isTabletScreen");

    return (
        <Box className="flex flex-col gap-4 w-full h-full overflow-hidden  ">


            {/* header - filter list, grid/list view and search , actions  */}
            <Box className="flex flex-col gap-2 ">


                {/* search and filter */}
                <Box className={`flex items-center justify-between gap-2 h-fit `}>

                    <Box className="flex items-center justify-between gap-2 w-72 ">

                        <TextField
                            fullWidth
                            placeholder="Search Keyword"
                            color="white"
                            disabled={useCaseList?.length === 0}
                            // size="medium"
                            sx={{
                                // py: "6px",
                                bgcolor: theme.palette.common.white,
                                borderRadius: theme.shape.borderRadius,
                                // px: "4px",
                                // ":root" : {
                                //     bgcolor : theme.palette.common.white
                                // }
                                // background : theme.palette.common.white,
                                '& .MuiInputBase-input': {
                                    // boxSizing: 'border-box',
                                    border: "none",
                                    // background: theme.palette.common.white,
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none'
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    border: 'none'
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    border: 'none'
                                }
                            }}
                            slotProps={{
                                input: {
                                    startAdornment: <InputAdornment position="center">
                                        <Search />
                                    </InputAdornment>,
                                    endAdornment: <InputAdornment position="center">
                                        <CircularProgress size={22} sx={{ visibility: isLoading ? "visible" : "hidden" }} />
                                    </InputAdornment>,
                                }
                            }}
                            defaultValue={searchText}
                            onChange={handleChangeSearch}
                        />

                        {/* <FilterItemDrawer /> */}

                    </Box>


                    {/* filters list */}
                    <Box className={`flex-1 flex items-center gap-2 overflow-x-auto`}>
                        {currentFilters && Object.keys(currentFilters).length > 0 && Object.keys(currentFilters)?.map((key, index) => handleGetFilterCount(key) > 0 && (
                            <Box className={`flex items-center gap-1 capitalize `} borderRadius={theme.shape.borderRadius}>
                                <Chip variant="contained" label={`${key} (${handleGetFilterCount(key)})`} onDelete={() => { handleRemoveFilter(key) }} />
                            </Box>
                        ))}
                    </Box>


                    <Box className="flex items-center justify-between gap-2  ">

                        <Box className="flex items-center gap-2  ">

                            <LightTooltip title={`${openItemFilterDrawer ? "Hide" : "Show"} Filter`}>

                                <IconButton onClick={() => setOpenItemFilterDrawer(true)}>
                                    <Badge badgeContent={currentFilters && Object.keys(currentFilters).length} color="secondary" anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                                        <FilterAlt sx={{ fontSize: (currentFilters && Object.keys(currentFilters).length > 0) ? font.size.fs_4 : font.size.fs_3, transition: "all .3s ease" }} />
                                    </Badge>
                                </IconButton>

                            </LightTooltip>

                            <Divider orientation="vertical" flexItem />

                            <LightTooltip title={`Copy`}>
                                <IconButton disabled={selectedUserStoryList?.length === 0} onClick={handleDeleteClick}>
                                    <FileCopyOutlined />
                                </IconButton>
                            </LightTooltip>

                            <LightTooltip title={`Move`}>
                                <IconButton disabled={selectedUserStoryList?.length === 0} onClick={handleDeleteClick}>
                                    <DriveFileMove />
                                </IconButton>
                            </LightTooltip>


                            <LightTooltip title={`Delete`}>
                                <IconButton disabled={selectedUseCaseList?.length === 0} onClick={handleDeleteClick}>
                                    <DeleteOutline />
                                </IconButton>
                            </LightTooltip>

                            <LightTooltip title={`Upload`}>
                                <IconButton disabled >
                                    <FileUploadOutlined />
                                </IconButton>
                            </LightTooltip>

                            <LightTooltip title={`Download`}>
                                <IconButton disabled >
                                    <FileDownloadOutlined />
                                </IconButton>
                            </LightTooltip>

                            {/* <LightTooltip title={`${isShowStoryFilter ? "Hide Filter" : "Show Filter"}`}>
        <IconButton onClick={() => setIsShowStoryFilter(!isShowStoryFilter)} >
            {isShowStoryFilter ? <FilterAltOffOutlined /> : <FilterAltOutlined />}
        </IconButton>
    </LightTooltip> */}
                        </Box>


                        <Box className=" h-full flex items-center  gap-2  rounded-lg overflow-hidden ">
                            <Paper
                                elevation={0}
                                sx={(theme) => ({
                                    display: 'flex',
                                    border: `1px solid ${theme.palette.divider}`,
                                    flexWrap: 'wrap',
                                })}
                            >
                                <StyledToggleButtonGroup
                                    size="small"
                                    value={isGridViewEnabled}
                                    exclusive
                                    onChange={(e, newValue) => {
                                        console.log(newValue, isGridViewEnabled, newValue !== isGridViewEnabled, "value togglebutton")
                                        if (newValue !== null) {
                                            setIsGridViewEnabled(newValue);
                                        }
                                    }}
                                    aria-label="text alignment"
                                    sx={{ boxSizing: "border-box" }}
                                >

                                    <StyledToggleButton value={true} aria-label="centered">
                                        <LightTooltip title={`Grid View`}>
                                            <GridViewTwoToneIcon />
                                        </LightTooltip>
                                    </StyledToggleButton>

                                    <StyledToggleButton value={false} aria-label="centered">
                                        <LightTooltip title={`List View`}>
                                            <ListTwoToneIcon />
                                        </LightTooltip>
                                    </StyledToggleButton>
                                </StyledToggleButtonGroup>
                            </Paper>
                        </Box>

                    </Box>

                </Box>

                {/* filters */}
                {false && isShowFilters && <Box className={`flex items-center gap-4 h-fit `}>
                    <Box className={`flex items-center gap-4 h-[20px] `}>
                        {false && <FormControl size="small" variant="outlined" sx={{ height: "100%", width: "7rem", }}>
                            <InputLabel
                                size="small"
                                shrink
                            >
                                Status
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                displayEmpty
                                name="status"
                                label="Status"
                                value={(currentFilters && currentFilters["status"]) || ""}
                                onChange={(e) => handleChangeColumnFilter(e, "status")}
                                MenuProps={{
                                    MenuListProps: {
                                        style: {
                                            maxHeight: "200px"
                                        }
                                    }
                                }}
                                sx={{
                                    height: "100%",
                                    bgcolor: theme.palette.common.white,
                                    '& .MuiSelect-select': {
                                        // padding: '8px 14px',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }
                                }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>

                                {
                                    [StoryStatus.pending, StoryStatus.inprogress, StoryStatus.completed]?.map((item, index) => {
                                        return <MenuItem key={index} value={item}>{item}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>}


                        <FormControl size="small" variant="outlined" sx={{ height: "100%", width: "11rem" }}>
                            <InputLabel
                                size="small"
                                shrink
                            >
                                Created At
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                displayEmpty
                                name="createdAt"
                                label="Created At"
                                value={(currentFilters && currentFilters["createdAt"]) || ""}
                                onChange={(e) => handleChangeColumnFilter(e, "createdAt")}
                                MenuProps={{
                                    MenuListProps: {
                                        style: {
                                            maxHeight: "200px"
                                        }
                                    }
                                }}
                                sx={{
                                    height: "100%",
                                    bgcolor: theme.palette.common.white,
                                    '& .MuiSelect-select': {
                                        // padding: '8px 14px',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }
                                }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>

                                {
                                    [...new Set(userStoryList?.map(item => item?.createdAt))]?.map((item, index) => {
                                        return <MenuItem key={index} value={item}>{dayjs(item).format("DD MMM YYYY HH:mm A")}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Box>


                    <LightTooltip title={`Clear Filters`}>
                        <IconButton disabled={!currentFilters} onClick={() => setCurrentFilters(null)}>
                            <FilterAltOffOutlined />
                        </IconButton>
                    </LightTooltip>


                </Box>}

            </Box>

            {/* user story */}
            <Box className={`flex w-full  ${isGridViewEnabled ? "h-full" : "h-full"} overflow-y-auto `}>
                {isLoading || isActiveProjectContainerLoading ? (
                    <Box className={`grow flex flex-col gap-2 items-center justify-center `}>
                        <CircularProgress />
                        <Typography fontSize={font.size.fs_5}>Loading...</Typography>
                    </Box>
                )
                    :
                    filteredUseCaseList?.length === 0 ?
                        <Box className="grow flex flex-col gap-2 justify-center items-center ">
                            <Description sx={{ fontSize: font.size.fs_1 }} />
                            <Typography fontSize={font.size.fs_5}> Add {Entity_Label.useCase}</Typography>
                        </Box>
                        :
                        (
                            <Box className={` flex w-full    `}>
                                {isGridViewEnabled ?
                                    <TestCaseGrid dataList={filteredUseCaseList} isLoading={isActiveProjectContainerLoading} />
                                    : <TestCaseList dataList={filteredUseCaseList} isLoading={isActiveProjectContainerLoading} />
                                }
                            </Box>
                        )
                }

            </Box>


        </Box>
    )
}


function DashboardContainerComponent2() {
    const {
        isGridViewEnabled, setIsGridViewEnabled,
        handleSnackbar,
        currentFolderData, setCurrentFolderData,
        userStoryList, setUserStoryList,
        currentUserStoryData, setCurrentUserStoryData,
        selectedUserStoryList, setSelectedUserStoryList,
        isUserStoryLoading, setIsUserStoryLoading,
        filteredUserStoryList, setFilteredUserStoryList,
        openDeleteStoryDialog, setOpenDeleteStoryDialog,
        openCreateStoryDialog,
        useCaseList, setUseCaseList,
        currentUseCaseData, setCurrentUseCaseData,
        filteredUseCaseList, setFilteredUseCaseList,
        isActiveProjectContainerLoading, setIsActiveProjectContainerLoading,
        selectedUseCaseList, setSelectedUseCaseList,
        setOpenDeleteUseCaseDialog,
        currentFilters, setCurrentFilters,
        openItemFilterDrawer, setOpenItemFilterDrawer,
        useCasePropertiesData, setUseCasePropertiesData,
        activeProject,
        activeDashboard,
        setActiveDashboard
    } = useApplicationContext();
    const theme = useTheme();
    const errorHandler = useErrorHandler();
    const pathName = useLocation().pathname;

    const isTabletScreen = useMediaQuery((theme) => theme.breakpoints.down('xl'));
    let timer;

    const [isLoading, setIsLoading] = useState(false);
    const [isShowFilters, setIsShowFilters] = useState(true);
    const [actionType, setActionType] = useState("label");
    const [actionIconAnchorEl, setActionIconAnchorEl] = useState(null);

    const [searchText, setSearchText] = useState("");
    // const [currentFilters, setCurrentFilters] = useState(null); // To store the table filters

    const [finalList, setFinalList] = useState(filteredUseCaseList); // To store the final filtered -> searched list
    const [selectedPropertiesData, setSelectedPropertiesData] = useState(null); // To store the selected properties values

    const [priorityList, setPriorityList] = useState([]);
    const [typeList, setTypeList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [statusList, setStatusList] = useState([]);

    const [activeFilterData, setActiveFilterData] = useState({
        status: "all",
    });

    const handleGetGridComponent = (list,) => {
        switch (pathName) {
            case ModulePath.useCase: return <UseCaseGrid dataList={list} isLoading={false} />;
            case ModulePath.testCase: return <UseCaseGrid dataList={list} isLoading={false} />;
        }
    }

    const handleChangeSearch = (value) => {
        setSearchText(value);
        clearTimeout(timer);

        setTimeout(() => {
            setIsLoading(true);
        }, 400);

        timer = setTimeout(() => {
            // setSearchText(value);
            setIsLoading(false);
        }, 800);
    };

    const handleDeleteClick = () => {
        setOpenDeleteUseCaseDialog(true);
        console.log(openDeleteStoryDialog, "true")
    }

    const handleChangeFilter = (e) => {
        setActiveFilterData({ ...activeFilterData, [e.target.name]: e.target.value });
    }

    const handleToggleActionMenu = (event, actionType) => {

        if (!actionIconAnchorEl) {
            setActionIconAnchorEl(event.currentTarget)
            setActionType(actionType)
        } else {
            setActionIconAnchorEl(null)
        }
    }

    const handleRemoveFilterItem = (key, value) => {
        let filter = currentFilters ? { ...currentFilters } : {};

        if (Array.isArray(filter[key])) {
            filter[key] = currentFilters[key].filter((item) => item !== value);
            if (filter[key].length === 0) {
                delete filter[key]
            }
        } else {
            if (key === "from") {
                delete filter["createdAt"][key]
            }

            if (key === "to") {
                delete filter["createdAt"][key]
            }

            if (filter["createdAt"] && Object.keys(filter["createdAt"])?.length === 0) {
                delete filter["createdAt"]
            }

            else {
                delete filter[key]
            }
        }


        if (filter && Object.keys(filter)?.length === 0) {
            setCurrentFilters(null)
            return;
        }


        // delete filter[value];
        setCurrentFilters(filter)
    }

    const handleGetFilterCount = (value) => {
        if (value === Filters.status) {
            return currentFilters?.status?.length
        }

        if (value === Filters.createdAt) {
            return currentFilters?.createdAt?.from && currentFilters?.createdAt?.to ? 2 : currentFilters?.createdAt?.from ? 1 : currentFilters?.createdAt?.to ? 1 : 0
        }
    }

    const handleChangeColumnFilter = (event, key) => {
        const filterData = currentFilters ? { ...currentFilters } : {};

        if (event.target.value === '') {
            delete filterData[key];
        } else {
            filterData[key] = event.target.value;
        }

        setCurrentFilters(filterData);
    };

    const handleSelectProperites = (key, value) => {
        setSelectedPropertiesData({ ...selectedPropertiesData, [key]: value });
    }

    const handleCancelPropertiesData = () => {
        setSelectedPropertiesData(null);
        setActionIconAnchorEl(null);
    }


    const handleApplyPropertiesData = () => {
        let body = {
            "customIds": [selectedUseCaseList?.map((item) => item?.customId)],
            "folderPath": currentFolderData?.path,
            "projectName": activeProject?.name,
        }

        body = { ...body, ...selectedPropertiesData };

        handleUpdateStory(body)

    }


    const handleUpdateStory = async (storyData) => {
        try {
            setIsActiveProjectContainerLoading(true);

            let body = {
                ...storyData,
            }


            const bodyFormData = new FormData();
            Object.keys(body).forEach(key => {
                if (Array.isArray(body[key])) {
                    bodyFormData.append(key, body[key]);
                } else {
                    bodyFormData.append(key, body[key]);
                }
            });



            const response = await updateUseCase_API(bodyFormData);
            console.log(response, "response handleUpdateStory")

            if (response) {
                errorHandler(response, setIsActiveProjectContainerLoading);
            }

            if (response?.status === HttpStatusCode.Ok) {
                let updatedList = response.data;

                updatedList?.forEach((item) => {
                    let list = useCaseList;
                    let foundIndex = list?.findIndex((listItem) => listItem?.customId === item?.customId);
                    if (foundIndex !== -1) {
                        list[foundIndex] = item;
                    }
                    setUseCaseList(list);
                })

                setSelectedUseCaseList([]);
                setActionIconAnchorEl(null);

                setTimeout(() => {
                    handleSnackbar("UseCase updated successfully", Severity.success);
                }, 500)

            }


            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setIsActiveProjectContainerLoading(false);
            }, 1000);
        }
    }

    useEffect(() => {
        let list = filteredUseCaseList;

        if (searchText) {
            list = list?.filter((item) =>
            (
                item?.customId?.toLowerCase()?.includes(searchText.toLowerCase()) ||
                item?.title?.toLowerCase()?.includes(searchText.toLowerCase()) ||
                item?.state?.toLowerCase()?.includes(searchText.toLowerCase()) ||
                item?.status?.toLowerCase()?.includes(searchText.toLowerCase()) ||
                item?.priority?.toLowerCase()?.includes(searchText.toLowerCase()) ||
                item?.type?.toLowerCase()?.includes(searchText.toLowerCase())
            )
            );
        }

        setFinalList(list);
    }, [searchText, filteredUseCaseList, setFinalList]);

    // useEffect(() => {
    //     let list = useCaseList;

    //     setIsLoading(true);

    //     if (activeFilterData?.status) {
    //         list = list?.filter((item) => activeFilterData?.status?.toLowerCase() === "all" ? true : item?.status?.toLowerCase() === activeFilterData?.status?.toLowerCase());
    //     }

    //     setFilteredUseCaseList(list);

    //     setTimeout(() => {
    //         setIsLoading(false);
    //     }, 800);

    //     console.log(list, "filteredList")

    // }, [activeFilterData?.status,])

    useEffect(() => {
        setIsLoading(isUserStoryLoading);
    }, [isActiveProjectContainerLoading, setIsActiveProjectContainerLoading]);


    useEffect(() => {
        setIsLoading(true);
        if (currentFilters) {

            const filteredRows = useCaseList?.filter((row) => Object.entries(currentFilters).every(([column, value]) => {
                console.log(column, value, row, "column, value, row")
                if (!value) return true;
                if (Array.isArray(value)) {
                    return value.includes(row[column]?.toString());
                } else {
                    if (column === "createdAt") {
                        if (value.from && value.to) {
                            return dayjs(row["createdAt"]).isAfter(dayjs(value.from), 'day') && dayjs(row["createdAt"]).isBefore(dayjs(value.to), 'day');
                        } else if (value.from) {
                            return dayjs(row["createdAt"]).isAfter(dayjs(value.from), 'day') || dayjs(row["createdAt"]).isSame(dayjs(value.from), 'day');
                        } else if (value.to) {
                            return dayjs(row["createdAt"]).isBefore(dayjs(value.to), 'day') || dayjs(row["createdAt"]).isSame(dayjs(value.to), 'day');
                        } else {
                            return false;
                        }
                    } else {
                        return row[column]?.toString().toLowerCase().includes(value.toLowerCase());
                    }
                }
            }));
            setFilteredUseCaseList(filteredRows);
            console.log(filteredRows, "filteredRows");
        } else {
            setFilteredUseCaseList(useCaseList);
        }


        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }, [currentFilters, useCaseList, setFilteredUseCaseList]);

    // useEffect(() => {
    //     let list = filteredUserStoryList;

    //     if (searchText) {
    //         list = list?.filter((item) => (item?.customId?.toLowerCase()?.includes(searchText.toLowerCase()) || item?.title?.toLowerCase()?.includes(searchText.toLowerCase())));
    //     }

    //     setFinalList(list);
    // }, [searchText, filteredUserStoryList, setFinalList]);


    useEffect(() => {
        setFilteredUseCaseList(useCaseList);
    }, [useCaseList, setFilteredUseCaseList]);


    useEffect(() => {
        if (useCasePropertiesData) {
            setTypeList(useCasePropertiesData["type"]);
            setStateList(useCasePropertiesData["state"]);
            setStatusList(useCasePropertiesData["status"]);
            setPriorityList(useCasePropertiesData["priority"]);
        }

    }, [useCasePropertiesData])

    console.log(isTabletScreen, "isTabletScreen");


    return (
        <Box className="flex flex-col gap-4 w-full h-full overflow-hidden   " bgcolor={theme.palette.background.default}>

            {/* list container */}
            <Box className={`flex relative w-full  ${isGridViewEnabled ? "h-full" : "h-full"} overflow-y-auto `} >
                {
                    (isLoading || isActiveProjectContainerLoading) && (
                        <Box className={`grow flex flex-col gap-2 items-center justify-center absolute left-0 right-0 top-0 bottom-0 `}>
                            <CircularProgress />
                            <Typography fontSize={font.size.fs_5}>Loading...</Typography>
                        </Box>
                    )
                }


                ljsldkjfklsjdf





            </Box>
        </Box>
    )

    return (
        <Box className="flex flex-col gap-4 w-full h-full overflow-hidden  ">


            {/* header - filter list, grid/list view and search , actions  */}
            <Box className="flex flex-col gap-4 ">


                {/* search  */}
                <Box className={`flex items-center justify-between gap-2 min-h-fit `}>

                    <Box className="flex items-center justify-between gap-2 w-72 ">

                        <TextField
                            fullWidth
                            placeholder="Search Keyword"
                            color="white"
                            disabled={userStoryList?.length === 0}
                            // size="medium"
                            sx={{
                                // py: "6px",
                                bgcolor: theme.palette.common.white,
                                borderRadius: theme.shape.borderRadius,
                                // px: "4px",
                                // ":root" : {
                                //     bgcolor : theme.palette.common.white
                                // }
                                // background : theme.palette.common.white,
                                '& .MuiInputBase-input': {
                                    // boxSizing: 'border-box',
                                    border: "none",
                                    // background: theme.palette.common.white,
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none'
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    border: 'none'
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    border: 'none'
                                }
                            }}
                            slotProps={{
                                input: {
                                    startAdornment: <InputAdornment position="center">
                                        <Search />
                                    </InputAdornment>,
                                    endAdornment: <InputAdornment position="center">
                                        {isLoading
                                            ?
                                            <CircularProgress size={22} sx={{ visibility: isLoading ? "visible" : "hidden" }} />
                                            :
                                            (searchText && <IconButton onClick={(e) => handleChangeSearch("")}>
                                                <Close />
                                            </IconButton>)
                                        }
                                    </InputAdornment>,
                                }
                            }}
                            value={searchText}
                            onChange={(e) => handleChangeSearch(e.target.value)}
                        />

                        {/* <FilterItemDrawer /> */}

                    </Box>


                    {/* filters list */}
                    {false && <Box className=" h-full w-[38%] flex  overflow-x-auto ">
                        <Box className={` h-fit  flex items-center gap-2 `}>
                            {
                                currentFilters && Object.keys(currentFilters)?.map((key, index) => {
                                    return (
                                        <Box className="flex flex-col items-center px-1 pb-1" bgcolor={theme.palette.background.paper} borderRadius={theme.shape.borderRadius}>
                                            <Typography fontSize={font.size.fs_6} className="flex capitalize">{key}</Typography>
                                            <Box className="flex items-center gap-1">
                                                {Array.isArray(currentFilters[key]) ?
                                                    currentFilters[key]?.map((itemValue, index) => (
                                                        <Box key={index} className="flex items-center gap-1 px-1 border" bgcolor={theme.palette.background.custom} borderRadius={theme.shape.borderRadius}>
                                                            <Typography fontSize={font.size.fs_6} lineHeight={font.size.fs_6}>{itemValue}</Typography>
                                                            <Close sx={{ fontSize: font.size.fs_5, cursor: "pointer" }} onClick={() => handleRemoveFilterItem(key, itemValue)} />
                                                        </Box>
                                                    ))
                                                    :
                                                    (
                                                        Object.keys(currentFilters[key])?.map((ObjectKey, index) => {
                                                            console.log(dayjs(currentFilters[key][ObjectKey]).format("DD-MM-YYYY"), "currentFilters[key][ObjectKey]");
                                                            return (
                                                                <Box key={index} className="flex items-center gap-1 px-1 border" bgcolor={theme.palette.background.custom} borderRadius={theme.shape.borderRadius}>
                                                                    <Typography fontSize={font.size.fs_6} lineHeight={font.size.fs_6} className="whitespace-nowrap">{dayjs(currentFilters[key][ObjectKey]).format("DD-MM-YYYY")}</Typography>
                                                                    <Close sx={{ fontSize: font.size.fs_5, cursor: "pointer" }} onClick={() => handleRemoveFilterItem(ObjectKey, currentFilters[key][ObjectKey])} />
                                                                </Box>
                                                            )
                                                        })
                                                    )
                                                }
                                            </Box>
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                    </Box>}


                    <Box className="flex items-center justify-between gap-2  ">

                        <Box className="flex items-center gap-2  ">

                            <LightTooltip title={`${openItemFilterDrawer ? "Hide" : "Show"} Filter`}>
                                <Box className="flex items-center ">

                                    <IconButton onClick={() => setOpenItemFilterDrawer(true)}>
                                        {/* <Badge badgeContent={currentFilters && Object.keys(currentFilters).length} color="secondary" anchorOrigin={{ vertical: 'top', horizontal: 'right' }}> */}
                                        <FilterAltOutlined sx={{ color: currentFilters && Object.keys(currentFilters).length > 0 && theme.palette.secondary.main, fontSize: font.size.fs_3, transition: "all .3s ease" }} />
                                        {/* </Badge> */}
                                    </IconButton>

                                    {currentFilters && Object.keys(currentFilters).length > 0 && <Typography >{`[${currentFilters && Object.keys(currentFilters).length}]`}</Typography>}
                                </Box>
                            </LightTooltip>

                            <Divider orientation="vertical" flexItem />

                            {false && <LightTooltip title={`Properties`}>
                                <IconButton disabled={selectedUseCaseList?.length === 0} onClick={(e) => handleToggleActionMenu(e, "properties")}>
                                    <TrackChanges />
                                </IconButton>
                            </LightTooltip>}

                            {false && <LightTooltip title={`Copy`}>
                                <IconButton disabled={selectedUseCaseList?.length === 0} onClick={handleDeleteClick}>
                                    <FileCopyOutlined />
                                </IconButton>
                            </LightTooltip>}

                            {false && <LightTooltip title={`Move`}>
                                <IconButton disabled={selectedUseCaseList?.length === 0} onClick={handleDeleteClick}>
                                    <DriveFileMove />
                                </IconButton>
                            </LightTooltip>}


                            {false && <LightTooltip title={`Delete`}>
                                <IconButton disabled={selectedUseCaseList?.length === 0} onClick={handleDeleteClick}>
                                    <DeleteOutline />
                                </IconButton>
                            </LightTooltip>}

                            {false && <LightTooltip title={`Upload`}>
                                <IconButton disabled >
                                    <FileUploadOutlined />
                                </IconButton>
                            </LightTooltip>}

                            <LightTooltip title={`Download`}>
                                <IconButton disabled >
                                    <FileDownloadOutlined />
                                </IconButton>
                            </LightTooltip>

                            {/* <LightTooltip title={`${isShowStoryFilter ? "Hide Filter" : "Show Filter"}`}>
        <IconButton onClick={() => setIsShowStoryFilter(!isShowStoryFilter)} >
            {isShowStoryFilter ? <FilterAltOffOutlined /> : <FilterAltOutlined />}
        </IconButton>
    </LightTooltip> */}
                        </Box>


                        <Box className=" h-full flex items-center  gap-2  rounded-lg overflow-hidden ">
                            <Paper
                                elevation={0}
                                sx={(theme) => ({
                                    display: 'flex',
                                    border: `1px solid ${theme.palette.divider}`,
                                    flexWrap: 'wrap',
                                })}
                            >
                                <StyledToggleButtonGroup
                                    size="small"
                                    value={isGridViewEnabled}
                                    exclusive
                                    onChange={(e, newValue) => {
                                        console.log(newValue, isGridViewEnabled, newValue !== isGridViewEnabled, "value togglebutton")
                                        if (newValue !== null) {
                                            setIsGridViewEnabled(newValue);
                                        }
                                    }}
                                    aria-label="text alignment"
                                    sx={{ boxSizing: "border-box" }}
                                >

                                    <StyledToggleButton value={true} aria-label="centered">
                                        <LightTooltip title={`Grid View`}>
                                            <GridViewTwoToneIcon />
                                        </LightTooltip>
                                    </StyledToggleButton>

                                    <StyledToggleButton value={false} aria-label="centered">
                                        <LightTooltip title={`List View`}>
                                            <ListTwoToneIcon />
                                        </LightTooltip>
                                    </StyledToggleButton>
                                </StyledToggleButtonGroup>
                            </Paper>
                        </Box>

                    </Box>

                </Box>

                {/* filters list */}
                {currentFilters && Object.keys(currentFilters)?.length > 0 && <Box className=" h-fit w-full flex  overflow-x-auto ">
                    <Box className={` h-fit w-full flex items-center gap-2 overflow-x-auto `}>
                        {
                            currentFilters && Object.keys(currentFilters)?.map((key, index) => {
                                return (
                                    <Box className="flex flex-col items-center px-1 pb-1" bgcolor={theme.palette.background.paper} borderRadius={theme.shape.borderRadius}>
                                        <Typography fontSize={font.size.fs_6} className="flex capitalize">{key}</Typography>
                                        <Box className="flex items-center gap-1">
                                            {Array.isArray(currentFilters[key]) ?
                                                currentFilters[key]?.map((itemValue, index) => (
                                                    <Box key={index} className="flex items-center gap-1 px-1 border" bgcolor={theme.palette.background.custom} borderRadius={theme.shape.borderRadius}>
                                                        <Typography fontSize={font.size.fs_6} lineHeight={font.size.fs_6}>{itemValue}</Typography>
                                                        <Close sx={{ fontSize: font.size.fs_5, cursor: "pointer" }} onClick={() => handleRemoveFilterItem(key, itemValue)} />
                                                    </Box>
                                                ))
                                                :
                                                (
                                                    Object.keys(currentFilters[key])?.map((ObjectKey, index) => {
                                                        console.log(dayjs(currentFilters[key][ObjectKey]).format("DD-MM-YYYY"), "currentFilters[key][ObjectKey]");
                                                        return (
                                                            <Box key={index} className="flex items-center gap-1 px-1 border" bgcolor={theme.palette.background.custom} borderRadius={theme.shape.borderRadius}>
                                                                <Typography fontSize={font.size.fs_6} lineHeight={font.size.fs_6} className="whitespace-nowrap">{dayjs(currentFilters[key][ObjectKey]).format("DD-MM-YYYY")}</Typography>
                                                                <Close sx={{ fontSize: font.size.fs_5, cursor: "pointer" }} onClick={() => handleRemoveFilterItem(ObjectKey, currentFilters[key][ObjectKey])} />
                                                            </Box>
                                                        )
                                                    })
                                                )
                                            }
                                        </Box>
                                    </Box>
                                )
                            })
                        }
                    </Box>
                </Box>}

                {/* filters */}
                {false && isShowFilters && <Box className={`flex items-center gap-4 h-fit `}>
                    <Box className={`flex items-center gap-4 h-[20px] `}>
                        {false && <FormControl size="small" variant="outlined" sx={{ height: "100%", width: "7rem", }}>
                            <InputLabel
                                size="small"
                                shrink
                            >
                                Status
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                displayEmpty
                                name="status"
                                label="Status"
                                value={(currentFilters && currentFilters["status"]) || ""}
                                onChange={(e) => handleChangeColumnFilter(e, "status")}
                                MenuProps={{
                                    MenuListProps: {
                                        style: {
                                            maxHeight: "200px"
                                        }
                                    }
                                }}
                                sx={{
                                    height: "100%",
                                    bgcolor: theme.palette.common.white,
                                    '& .MuiSelect-select': {
                                        // padding: '8px 14px',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }
                                }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>

                                {
                                    [StoryStatus.pending, StoryStatus.inprogress, StoryStatus.completed]?.map((item, index) => {
                                        return <MenuItem key={index} value={item}>{item}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>}


                        <FormControl size="small" variant="outlined" sx={{ height: "100%", width: "11rem" }}>
                            <InputLabel
                                size="small"
                                shrink
                            >
                                Created At
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                displayEmpty
                                name="createdAt"
                                label="Created At"
                                value={(currentFilters && currentFilters["createdAt"]) || ""}
                                onChange={(e) => handleChangeColumnFilter(e, "createdAt")}
                                MenuProps={{
                                    MenuListProps: {
                                        style: {
                                            maxHeight: "200px"
                                        }
                                    }
                                }}
                                sx={{
                                    height: "100%",
                                    bgcolor: theme.palette.common.white,
                                    '& .MuiSelect-select': {
                                        // padding: '8px 14px',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }
                                }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>

                                {
                                    [...new Set(userStoryList?.map(item => item?.createdAt))]?.map((item, index) => {
                                        return <MenuItem key={index} value={item}>{dayjs(item).format("DD MMM YYYY HH:mm A")}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Box>


                    <LightTooltip title={`Clear Filters`}>
                        <IconButton disabled={!currentFilters} onClick={() => setCurrentFilters(null)}>
                            <FilterAltOffOutlined />
                        </IconButton>
                    </LightTooltip>


                </Box>}


                <Popover
                    open={Boolean(actionIconAnchorEl)}
                    anchorEl={actionIconAnchorEl}
                    onClose={(e) => handleToggleActionMenu(e, null)}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    <Box>
                        {actionType === "label" && ["red", "blue", "green", "grey", "brown"].map((item, index) => {
                            return <MenuItem>
                                <LabelOutlined sx={{ color: item }} />
                            </MenuItem>
                        })}


                        <Box className="flex items-center px-4 py-2 border-b">
                            <Typography color={theme.palette.text.primary} fontSize={font.size.fs_4}>Properties</Typography>
                        </Box>

                        {/* (actionType === "state" || actionType === "type" || actionType === "priority" || actionType === "confidentiality" || actionType === "moscow") && */}
                        {actionType === "properties" &&
                            <Box className="flex gap-0 divide-x">

                                {
                                    useCasePropertiesData && Object.keys(useCasePropertiesData).map((key) => (
                                        <Box key={key} className="flex flex-col">

                                            <Typography fontSize={font.size.fs_5} color={theme.palette.text.primary} className="px-4 py-1 capitalize border-b">{key}</Typography>
                                            <Box className="w-full h-full flex flex-col " >
                                                {
                                                    useCasePropertiesData[key]?.map((item, index) => (
                                                        <MenuItem key={index} selected={selectedPropertiesData && (key in selectedPropertiesData) && selectedPropertiesData[key] && selectedPropertiesData[key] === item?.key} onClick={() => { handleSelectProperites(key, item?.key) }}>

                                                            <Typography fontSize={font.size.fs_5} color={theme.palette.text.secondary} className="">{item?.value}</Typography>
                                                        </MenuItem>
                                                    ))
                                                }
                                            </Box>
                                        </Box>
                                    ))
                                }
                            </Box>
                        }

                        <Box className="flex items-center justify-end px-4 py-2 border-t">
                            <Button variant="text" onClick={(e) => handleCancelPropertiesData()}>Cancel</Button>
                            <Button variant="contained" color="secondary" sx={{ color: theme.palette.secondary.contrastText }} onClick={handleApplyPropertiesData}>Apply</Button>
                        </Box>


                    </Box>
                </Popover>

            </Box>


            {/* list container */}
            <Box className={`flex w-full  ${isGridViewEnabled ? "h-full" : "h-full"} overflow-y-auto `}>
                {isLoading || isActiveProjectContainerLoading ? (
                    <Box className={`grow flex flex-col gap-2 items-center justify-center `}>
                        <CircularProgress />
                        <Typography fontSize={font.size.fs_5}>Loading...</Typography>
                    </Box>
                )
                    :
                    finalList?.length === 0 ?
                        <Box className="grow flex flex-col gap-2 justify-center items-center ">
                            <Description sx={{ fontSize: font.size.fs_1 }} />
                            <Typography fontSize={font.size.fs_5}> Add {pathName === ModulePath.useCase ? Entity_Label.useCase : Entity_Label.testCase}</Typography>
                        </Box>
                        :
                        (
                            <Box className={` flex w-full    `}>
                                {isGridViewEnabled ?
                                    <>
                                        <UseCaseGrid dataList={finalList} isLoading={false} />
                                    </>
                                    : <>
                                        <DashboardList dataList={finalList} isLoading={false} />
                                    </>
                                }
                            </Box>
                        )
                }

            </Box>


        </Box>
    )
}

