import { AccountCircleOutlined, ArrowRightAlt, Attachment, AutoAwesome, Delete, Description, Edit, FolderOutlined, MoreHoriz, MoreHorizOutlined } from "@mui/icons-material"
import { Box, Checkbox, Chip, IconButton, Menu, MenuItem, Popover, Radio, Typography, useTheme } from "@mui/material"
import { font } from "../../styles/font"
import { amber, blue, brown, green, grey, red, yellow } from "@mui/material/colors";
import { useState } from "react";
import { useApplicationContext } from "../../context/ApplicationContext";
import { FolderType } from "../../constant/ApplicationVariables";
import { LuLink, LuUnlink } from "react-icons/lu";
import { LinkCustomIcon, UnLinkCustomIcon, UseCaseCustomIcon } from "../styled/CustomIcons";
import { Doughnut } from "react-chartjs-2";
import { LightTooltip } from "../styled/CustomComponent";



const MENU_HEIGHT = 48;

class GRAPH_TYPE {
    static counter = "counter";
    static pie = "pie";
    static bar = "bar";
    static doughnut = "doughnut";
}

const data = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
        {
            label: '# of Votes',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
        },
    ],
};


export default function DashboardCard({ item, isSelectable, handleClick, handleChangeCheckbox, handleOpenLinkTag }) {
    const { selectedUserStoryList, selectedUseCaseList } = useApplicationContext();
    const theme = useTheme();

    const options = [
        {
            label: "Count",
            icon: <FolderOutlined sx={{ color: theme.palette.primary.main }} />,
            onClick: () => console.log("Duplicate Story"),
        },
        {
            label: "Graph",
            icon: <Edit sx={{ color: theme.palette.secondary.main }} />,
            onClick: () => console.log("Edit Story"),
        },
        {
            label: "Pie",
            icon: <Edit sx={{ color: theme.palette.secondary.main }} />,
            onClick: () => console.log("Edit Story"),
        },
        {
            label: "Add to Project Dashboard",
            icon: <Edit sx={{ color: theme.palette.secondary.main }} />,
            onClick: () => console.log("Edit Story"),
        },
        {
            label: "Make as private",
            icon: <Delete color="error" />,
            onClick: () => console.log("Delete Story"),
        },
    ];

    const [moreAnchorEl, setMoreAchorEl] = useState(null);
    const [graphType, setGraphType] = useState(GRAPH_TYPE.counter);


    const handleOpenMore = (event) => {
        setMoreAchorEl(event.currentTarget);
    };
    const handleCloseMore = () => {
        setMoreAchorEl(null);
    };

    const handleGraphComponent = (type) => {
        switch (type) {
            case GRAPH_TYPE.counter: return (
                <Box className="flex-1 flex items-end justify-between ">
                    <Typography fontSize={font.size.fs_2} fontWeight={"bold"} color={"textSecondary"}>$2000</Typography>
                </Box>
            );
            case GRAPH_TYPE.doughnut: return (
                <Box className="flex-1 flex items-end justify-between ">
                    <Doughnut data={item?.data} />
                </Box>
            );
            default: return (
                <Box className="flex-1 flex items-end justify-between ">
                    <Typography fontSize={font.size.fs_2} fontWeight={"bold"} color={"textSecondary"}>$2000</Typography>
                </Box>
            )
        }
    }

    console.log(item , "item in DashboardCard")

    return (
        <Box
            className=" flex flex-col py-0 w-full h-full min-w-72 min-h-64"
            sx={{
                // borderBottom: `4px solid ${GetStoryStatusColor(item?.status?.toLowerCase())}`,
                // borderStyle: "inset",
                // "@media (max-width: 600px)": {
                //     width: "100%",
                // },
            }}
        // onClick={handleClick}
        >

            {/* upper container */}
            <Box className="flex items-center justify-between px-4 py-2 ">


                <Typography fontSize={font.size.fs_4} color={"textSecondary"}>{item?.title}</Typography>
                {false
                    ? <LightTooltip title="Add to Project Dashboard">
                        <Radio size="small" />
                    </LightTooltip>
                    : <IconButton onClick={handleOpenMore}>
                        <MoreHorizOutlined />
                    </IconButton>
                }
            </Box>


            {/* bottom container */}
            <Box className="flex-1 flex items-end justify-between px-4 py-2 ">
                {handleGraphComponent(item?.type)}


            </Box>

            <Popover
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={moreAnchorEl}
                open={Boolean(moreAnchorEl)}
                onClose={handleCloseMore}
                slotProps={{
                    paper: {
                        style: {
                            maxHeight: MENU_HEIGHT * 4.5,
                            // width: '20ch',
                        },
                    },
                }}
            >
                {options.map((option, index) => (
                    <MenuItem key={index} onClick={handleCloseMore} className="flex items-center gap-2">
                        {/* {option?.icon} */}
                        {option?.label}
                    </MenuItem>
                ))}
            </Popover>
        </Box>
    )
}





