import React, { useEffect, useState } from 'react'
import { getFileDetail_API } from '../../api/controller/FileController';
import { useApplicationContext } from '../../context/ApplicationContext';
import { Box, CircularProgress, IconButton, useTheme } from '@mui/material';
import useErrorHandler from '../../api/errorHandler/useErrorHandler';
import { HttpStatusCode } from 'axios';
import { FileType, ModulePath, Severity } from '../../constant/ApplicationVariables';
import { DeleteOutlined, DownloadOutlined } from '@mui/icons-material';
import { FaRegFileWord } from "react-icons/fa";
import { FaRegFileExcel } from "react-icons/fa";
import { font } from '../../styles/font';
import { blue, green, red } from '@mui/material/colors';
import { FaRegFilePdf } from "react-icons/fa";
import MediaPreview from './MediaPreview';
import { useLocation } from 'react-router-dom';
import { ExcelCustomIcon, PdfCustomIcon, WordCustomIcon } from '../styled/CustomIcons';


class ICON_CONFIG {
    static document = { color: blue[500], background: blue[50], icon: <WordCustomIcon sx={{ fontSize: font.size.fs_1, }} /> };
    static excel = { color: green[500], background: green[50], icon: <ExcelCustomIcon sx={{ fontSize: font.size.fs_1, }} /> };
    static pdf = { color: red[500], background: red[50], icon: <PdfCustomIcon sx={{ fontSize: font.size.fs_1, }} /> };
}

export default function MediaAttachmentComponent({ data, handleRemove, showDownload, showDelete, isOnClick }) {
    const { handleSnackbar, currentUserStoryData, setCurrentUserStoryData, currentUseCaseData, setCurrentUseCaseData, } = useApplicationContext();
    let { name, contentType, size, uploadedAt, url } = data;

    const theme = useTheme();
    const pathName = useLocation()?.pathname;
    const errorHandler = useErrorHandler();


    const [loading, setLoading] = useState(false);
    const [openAttachementPreview, setOpenAttachementPreview] = useState(false);

    const [extractedUrl, setExtractedUrl] = useState("");
    const [currentAttachmentData, setCurrentAttachmentData] = useState(null);
    const [mediaList, setMediaList] = useState([]);

    const handleOpenAttachmentPreview = (mediaData) => {
        setCurrentAttachmentData(mediaData);
        setOpenAttachementPreview(true);
    }

    const handleCloseAttachmentPreview = () => {
        setOpenAttachementPreview(false);
        setCurrentAttachmentData(null);
    }


    function handleMedia() {
        if (FileType.imageList.includes(contentType)) return (
            <img src={extractedUrl} alt={name} className="object-cover w-full h-full overflow-hidden" />

        );
        if (FileType.videoList.includes(contentType)) return (
            <video src={extractedUrl} alt={name} className="object-fill w-full h-full overflow-hidden" />

        );
        // if (FileType.audioList.includes(contentType)) return <audio src={url} alt={name} className="object-fill w-full h-full overflow-hidden" />

        if (FileType.documentList.includes(contentType)) return (
            <Box className="flex items-center justify-center w-full h-full overflow-hidden border " bgcolor={ICON_CONFIG.document.background} >
                {ICON_CONFIG.document.icon}
            </Box>
        );
        if (FileType.excelList.includes(contentType)) return (
            <Box className="flex items-center justify-center w-full h-full overflow-hidden border " bgcolor={ICON_CONFIG.excel.background}>
                {ICON_CONFIG.excel.icon}
            </Box>
        );
        if (FileType.pdfList.includes(contentType)) return (
            <Box className="flex items-center justify-center w-full h-full overflow-hidden border " bgcolor={ICON_CONFIG.pdf.background} >
                {ICON_CONFIG.pdf.icon}
            </Box>
        );
    }

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = extractedUrl;
        link.download = name;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }



    const handleGetFileDetail = async () => {
        try {
            setLoading(true);

            let body = {
                path: url
            }

            const response = await getFileDetail_API(body);
            console.log(response, "response handleUpdateStory")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response?.status === HttpStatusCode.Ok) {

                const url = URL.createObjectURL(response.data);

                console.log(url, "mediaattachement url")

                setExtractedUrl(url)

                // setExtractedUrl(`data:image/png;base64,${btoa(response.data)}`)
            }

            console.log(response, "mediaattachement data")
        } catch (error) {
            console.log(error, error?.message, "mediaattachement errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    useEffect(() => {
        handleGetFileDetail();

        if (pathName === ModulePath.requirements) {
            setMediaList(currentUserStoryData?.attachments)
        }

        if (pathName === ModulePath.useCase || pathName === ModulePath.testCase) {
            setMediaList(currentUseCaseData?.attachments)
        }
    }, [data]);

    console.log(extractedUrl, "extractedUrl")




    return (
        <Box className="flex flex-col h-full w-full  " borderRadius={theme.shape.borderRadius} bgcolor={theme.palette.background.default} >
            <Box className="flex flex-col h-full w-full relative overflow-hidden" onClick={() => isOnClick && handleOpenAttachmentPreview(data)}>


                {loading &&
                    <Box className="flex flex-col items-center justify-center h-full w-full absolute left-0 top-0 backdrop-blur-sm">
                        <CircularProgress color="secondary" size={16} />
                    </Box>}

                {/* actions  */}
                <Box className="absolute top-0 left-0 flex items-center justify-end w-full z-10" >
                    {showDownload &&
                        <IconButton color="info" onClick={() => handleDownload(data)} sx={{ backdropFilter: "blur(5px)" }}>
                            <DownloadOutlined />
                        </IconButton>}
                    {showDelete &&
                        <IconButton color="error" onClick={() => handleRemove(data)} sx={{ backdropFilter: "blur(5px)" }}>
                            <DeleteOutlined />
                        </IconButton>}
                </Box>

                <Box className="flex flex-col h-full w-full transition-all ease-in-out duration-300  hover:scale-110">
                    {handleMedia()}
                </Box>
            </Box>


            {
                openAttachementPreview && currentAttachmentData &&
                <MediaPreview
                    open={openAttachementPreview}
                    handleClose={handleCloseAttachmentPreview}
                    mediaItem={currentAttachmentData}
                    mediaList={mediaList}
                    isOnClick={true}
                />
            }

        </Box >
    )
}