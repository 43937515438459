import { Box, Typography, useTheme } from '@mui/material'
import React from 'react'
import { font } from '../../styles/font'
import { useNavigate } from 'react-router-dom'
import { ModulePath } from '../../constant/ApplicationVariables';

export default function ApplicationLogo({ className, sx, imageHeight, imageWidth }) {
    const navigate = useNavigate();
    const theme = useTheme();

    return (
        <Box className={`flex items-center  gap-2 w-full h-10 py-2 px-0    box-border ${className} `} sx={{height : imageHeight, width : imageWidth, ...sx}} borderRadius={theme.shape.borderRadius} onClick={() => navigate(ModulePath.projects)}>
            <img src="/images/latestLogo.png" alt="FIcon" className="object-contain  rounded-lg  " style={{ height: "100%", width: "auto" }} />
            {/* <Box className="flex items-center justify-center   rounded-lg w-8 h-8 " bgcolor={theme.palette.primary.layout_col}>
        <Typography fontWeight={500}>FP</Typography>
    </Box> */}

            {false && <Typography fontSize={font.size.fs_3} className="flex items-center h-fit " sx={{ color: theme.palette.text.primary }}>
                FLO
                <Typography fontSize={font.size.fs_2} lineHeight={font.size.fs_4} color="secondary" >X</Typography>
                PAD
            </Typography>}
        </Box>
    )
}


