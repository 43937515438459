import { Box, Button, Dialog, TextField, Typography, useTheme } from "@mui/material"
import { font } from "../../styles/font"
import { useState } from "react";
import { useApplicationContext } from "../../context/ApplicationContext";
import { Entity_Label } from "../../constant/ApplicationVariables";


export default function CreateModuleDialog({ open, handleClose, handleConfirm, loading, setLoading }) {
  const { currentFolderData } = useApplicationContext();
  const theme = useTheme();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState(null);

  const handleChange = (e) => {

    if (e.target.name === "name") {
      setName(e.target.value);
    }

    else if (e.target.name === "description") {
      setDescription(e.target.value);
    }
  };

  const handleContinue = () => {
    if (name.trim() === "" || name.length < 3 || name.length > 20) {
      return setError({ ...error, name: "Name must be between 3 and 20 characters" });
    }

    // if (description === "") {
    //   return setError({ ...error, description: "Description is required" });
    // }

    let body = {
      name,
      description
    }

    if (!description) {
      delete body.description
    }

    handleConfirm(body);
  }

  return (
    <Dialog open={open} onClose={handleClose}
      sx={{ "& .MuiDialog-paper": { width: "100%", maxWidth: "400px" } }}
    >
      <Box className="flex flex-col gap-4 px-4 py-2">
        <Box className="flex items-center justify-between gap-4">
          <Typography fontSize={font.size.fs_4} color={theme.palette.text.primary}>{`Add ${Entity_Label.folder}`}</Typography>
          <Typography fontSize={font.size.fs_4} color={theme.palette.text.secondary}>/{currentFolderData ? currentFolderData?.path : ""}</Typography>
        </Box>

        <Box className="flex flex-col gap-4">
          <TextField name="name" autoFocus={!name} required={true} label="Name" fullWidth value={name} onChange={handleChange} error={error?.name && error?.name} helperText={error?.name && error?.name} />
          <TextField name="description" required={false} label="Description" fullWidth multiline minRows={4} maxRows={4} value={description} onChange={handleChange} error={error?.description && error?.description} helperText={error?.description && error?.description} />
        </Box>

        <Box className="flex items-center justify-end gap-4">
          <Button variant="outlined" disabled={loading} onClick={handleClose}>Cancel</Button>
          <Button variant="contained" color="secondary" sx={{ color: theme.palette.text.white }} disabled={!name || loading} onClick={handleContinue}>Confirm</Button>
        </Box>

      </Box>
    </Dialog>
  )
}


