import { AutoAwesome, AutoAwesomeOutlined, WavingHand } from '@mui/icons-material'
import { Box, Typography, useTheme } from '@mui/material'
import React from 'react'
import { font } from '../../../../styles/font'
import { amber } from '@mui/material/colors';

export default function LeftSection() {
    const theme = useTheme();

    return (
        <Box className="flex-1 flex flex-col px-14 py-12 " bgcolor={theme.palette.background.custom}>

            {/* application intro */}
            <Box className="flex-1  flex flex-col  justify-center  gap-6 px-44 py-0 pt-16">
                <Box className="flex items-center px-0 py-0 rounded-full w-fit" >
                    {/* <ApplicationLogo /> */}
                    <AutoAwesomeOutlined sx={{ fontSize: font.size.fs_1, color: theme.palette.ai.main }} />
                </Box>

                <Typography color="textPrimary" fontSize={"3.4rem"} lineHeight={"3.4rem"} fontWeight="bolder">
                    Hello <br /> Floxpad! <WavingHand sx={{ fontSize: font.size.fs_1, color: amber[500] }} />
                </Typography>

                <Typography color="textSecondary" fontSize={font.size.fs_4}>
                    Skip repeating and manual sales-marketing tasks. Get highlighly productive through automation and save tons of time!
                </Typography>

            </Box>

            {/* copy right */}
            <Box className=" flex flex-col  justify-center gap-6 px-44 py-6">
                <Typography fontSize={font.size.fs_4} color={theme.palette.text.ternary}>@2025 Floxpad. All rights are reserved.</Typography>
            </Box>
        </Box>
    )
}
