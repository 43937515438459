import { Alert, Avatar, Box, Button, CircularProgress, Dialog, Drawer, Fab, IconButton, ListItemIcon, ListItemText, MenuItem, Popover, Snackbar, Typography, useTheme } from '@mui/material'
import ApplicationHeader from './ApplicationHeader'
import RoutesComponent from '../../routes';
import React, { useEffect, useState } from 'react';
import { login_API } from '../../api/controller/authController';
import { useApplicationContext } from '../../context/ApplicationContext';
import useErrorHandler from '../../api/errorHandler/useErrorHandler';
import { HttpStatusCode } from 'axios';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate, redirect } from 'react-router-dom';
import ProtectedRoute from '../../routes/ProtectedRoute';
import ProjectsContainer from '../projects/ProjectsContainer';
import ExecutionContainer from '../execution';
import MarketplaceContainer from '../marketplace';
import StoryContainer from '../story';
import RequirementsContainer from '../projects/ActiveProject';
import LoginContainer from '../auth/login';
import { getFolderStructureList_API } from '../../api/controller/RequirementController';
import { APP_CONFIG, FolderType, ModulePath, Severity } from '../../constant/ApplicationVariables';
import ActiveProject from '../projects/ActiveProject';
import { font } from '../../styles/font';
import { AddTwoTone, DeleteTwoTone, EditTwoTone, HelpTwoTone, LogoutOutlined } from '@mui/icons-material';
import ApplicationSidebar from './ApplicationSidebar';
import ChatWindow from '../projects/ChatWindow';
import { addModule_API, deleteModule_API, updateModule_API } from '../../api/controller/ModuleController';
import CreateModuleDialog from '../dialog/CreateModuleDialog';
import EditModuleDialog from '../dialog/EditModuleDialog';
import DeleteModuleDialog from '../dialog/DeleteModuleDialog';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TokenExpiredDialog from '../dialog/TokenExpiredDialog';
import RegisterContainer from '../auth/register';
import ForgotPasswordContainer from '../auth/forgotPassword';
import VerifyAccountContainer from "../auth/verifyAccount";
import ResetPasswordContainer from '../auth/resetPassword';
import { getUserDetail_API } from '../../api/controller/userController';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { FaGripLinesVertical } from 'react-icons/fa';
import SettingsContainer from '../settings';
import ProfileContainer from '../profile';
import { getLabelList_API } from '../../api/controller/SettingsController';



export default function ApplicationLayout() {
    const {
        globalSnackbar, setGLobalSnackBar,
        globalLoader, setGlobalLoader,
        handleSnackbar,
        profileDetails, setProfileDetails,
        showValidTillConfirmation, setShowValidTillConfirmation
    } = useApplicationContext();
    const theme = useTheme();
    const errorHandler = useErrorHandler();
    const pathName = useLocation()?.pathname;
    const navigate = useNavigate();

    let UnderDevelopmentText = "Under Development"



    console.log(profileDetails, "profileDetails");

    return (

        <Box className="w-full h-screen flex overflow-hidden relative" >

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box className="w-full h-[100dvh] flex flex-col">
                    <Routes>
                        <Route element={<ProtectedRoute />}>
                            <Route element={<ApplicationLayoutMainContainer />}>
                                {/* <Route path={ModulePath.home} element={<div>Home</div>} key='home' /> */}
                                <Route path={ModulePath.profile} element={<ProfileContainer />} key='profile' />
                                {/* <Route path={ModulePath.settings} element={<div>Settings - @{APP_CONFIG.appName} v{APP_CONFIG.appVersion} <br /> {UnderDevelopmentText}</div>} key='settings' /> */}
                                <Route path={ModulePath.settings} element={<SettingsContainer />} key='settings' />
                                <Route path={ModulePath.projects} element={<ProjectsContainer />} key='project' />
                                <Route path={ModulePath.requirements} element={<ActiveProject />} key='requirements' />
                                <Route path={ModulePath.useCase} element={<ActiveProject />} key='usecase' />
                                <Route path={ModulePath.testCase} element={<ActiveProject />} key='testcase' />
                                {/* <Route path={ModulePath.testCase} element={<div>{UnderDevelopmentText}</div>} key='testcase' /> */}
                                <Route path={ModulePath.dashboard} element={<ActiveProject />} key='dashboard' />
                                <Route path={ModulePath.execution} element={<div>{UnderDevelopmentText}</div>} key='execution' />
                                <Route path={ModulePath.planning} element={<div>{UnderDevelopmentText}</div>} key='planning' />

                                {/* <Route path={ModulePath.story} element={<StoryContainer />} key='story' />
                            <Route path={ModulePath.marketplace} element={<MarketplaceContainer />} key='marketplace' />
                            <Route path={ModulePath.execution} element={<ExecutionContainer />} key='execution' /> */}
                            </Route>
                        </Route>

                        {localStorage.getItem("access-token") && <Route path="*" element={<Navigate to={ModulePath.projects} />} key="notfound" />}

                        <Route exact path="/" element={<Navigate to={localStorage.getItem("access-token") ? ModulePath.projects : ModulePath.login} />} key="home" />

                        {/* <Route exact path="*" element={<Box className="w-full h-full flex flex-col items-center justify-center gap-2">
                        <Typography>No page found</Typography>
                        <Button variant="contained" onClick={() => navigate(ModulePath.login)}>Go to Login</Button>
                    </Box>} key="notfound" /> */}

                        <Route path={ModulePath.login} element={<LoginContainer />} key={ModulePath.login} />
                        <Route path={ModulePath.register} element={<RegisterContainer />} key={ModulePath.register} />
                        <Route path={ModulePath.forgotPassword} element={<ForgotPasswordContainer />} key={ModulePath.forgotPassword} />
                        <Route path={ModulePath.verifyAccount} element={<VerifyAccountContainer />} key={ModulePath.verifyAccount} />
                        <Route path={ModulePath.resetPassword} element={<ResetPasswordContainer />} key={ModulePath.resetPassword} />

                    </Routes>
                </Box>
            </LocalizationProvider>

            {false && <Box className="w-full h-[100dvh] flex flex-col">

                {/* header */}
                <Box className="h-[11dvh] ">
                    <ApplicationHeader />
                </Box>

                {/* main container */}
                <Box className="h-[89dvh] flex grow px-0 pb-0 overflow-hidden " >
                    <ApplicationLayoutMainContainer />
                </Box>
            </Box>}







            <SnackbarT2T
                globalSnackbar={globalSnackbar}
                setGLobalSnackBar={setGLobalSnackBar}
            />

            {globalLoader && <GlobalLoader />}


            {showValidTillConfirmation && <TokenExpiredDialog />}

        </Box>
    )
}

function ApplicationLayoutMainContainer() {
    const {
        toggleChatWindow, setToggleChatWindow,
        toggleFolderSidebar, setToggleFolderSidebar,
        profileDetails,
        globalLoader, setGlobalLoader,
        handleSnackbar,
        userDetail, setUserDetail,
        activeProject,
        labelList, setLabelList
    } = useApplicationContext();
    const theme = useTheme();
    const pathName = useLocation()?.pathname;
    const errorHandler = useErrorHandler();

    const authPagesList = [ModulePath.login, ModulePath.register, ModulePath.forgotPassword, ModulePath.verifyAccount, ModulePath.resetPassword]


    const handleGetUserDetail = async () => {
        try {
            setGlobalLoader(true);

            const response = await getUserDetail_API();
            console.log(response, "response handleCreateModule")

            if (response) {
                errorHandler(response, setGlobalLoader);
            }

            if (response.status === HttpStatusCode.Ok) {
                setUserDetail(response.data);
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setGlobalLoader(false);
            }, 1000);
        }
    }

    const handleGetLabelsList = async () => {

        try {

            setGlobalLoader(true)


            let body = {
                name: activeProject?.name,
            }

            let query = new URLSearchParams(body).toString();

            const response = await getLabelList_API(query);

            console.log(response, " response in updateprofile")

            if (response) {
                errorHandler(response, setGlobalLoader);
            }

            if (response.status === HttpStatusCode.Ok) {
                setLabelList(response.data);

            }

        } catch (error) {
            console.log(error, error?.message, "login errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setGlobalLoader(false);

            }, 1000);
        }
    }


    useEffect(() => {
        if (profileDetails && !authPagesList.includes(pathName)) {
            handleGetUserDetail();
        }
    }, [profileDetails]);

       // get user labels list
       useEffect(() => {
        if (activeProject?.name) {
            handleGetLabelsList();
        }
    }, [activeProject?.name]);

    console.log(userDetail, "userDetail");


    return (
        <Box className="h-screen w-screen flex flex-col" bgcolor={theme.palette.background.custom}>

            <Box className={`h-[8vh] w-full flex px-4 py-0`} bgcolor={theme.palette.background.default}>
                <ApplicationHeader />
            </Box>

            <Box className=" h-[92vh] w-full flex gap-4  px-4 py-4 "   >

                <PanelGroup autoSaveId="example" direction="horizontal" className="flex gap-0">
                    {((pathName !== ModulePath.projects && pathName !== ModulePath.profile) && (toggleFolderSidebar)) &&
                        <Panel defaultSize={20} minSize={16} maxSize={24} className="">
                            {true &&
                                <Box
                                    sx={{
                                        height: "100%",
                                        width: (pathName !== `${(ModulePath.projects)}` && (toggleFolderSidebar)) ? "100%" : "0%",
                                        display: (pathName !== `${(ModulePath.projects)}` && (toggleFolderSidebar)) ? "flex" : "none",
                                        flexShrink: 0,
                                        borderRadius: theme.shape.borderRadius,
                                        overflow: "hidden",
                                        transition: "all 0.5s ease-in-out",
                                        '& .MuiDrawer-paper': {
                                            width: "20%",
                                            borderRadius: 0,
                                            boxSizing: 'border-box',
                                            bgcolor: theme.palette.background.default,
                                            transition: "all 0.5s ease-in-out",
                                        },
                                    }}
                                    variant="persistent"
                                    anchor="left"
                                    open={true}
                                    // className="h-full w-[20%]  flex flex-col" bgcolor={theme.palette.common.white}
                                    bgcolor={theme.palette.common.white}
                                >
                                    <ApplicationSidebar />
                                </Box>
                            }
                        </Panel>
                    }

                    {((pathName !== ModulePath.projects && pathName !== ModulePath.profile) && (toggleFolderSidebar)) &&
                        <CustomPanelResizeHandle />
                    }

                    <Panel>
                        <Box className="h-full  flex-1 min-w-[36%] flex flex-col gap-0  " >

                            {/* <Box className={`h-[8vh] w-full flex px-4 py-0 border-b`} bgcolor={theme.palette.background.default}>
                        <ApplicationHeader />
                    </Box> */}

                            {/* main container */}
                            <Box className={`h-full w-full flex px-0 py-0  `}>
                                <Outlet />
                            </Box>
                        </Box>
                    </Panel>

                    {(pathName !== `${(ModulePath.projects)}` && (toggleChatWindow)) &&
                        <CustomPanelResizeHandle />
                    }

                    {(pathName !== `${(ModulePath.projects)}` && (toggleChatWindow)) &&
                        <Panel defaultSize={26} minSize={22} maxSize={42} onResize={(e) => { console.log(e, "resize") }} >
                            {true &&
                                <Box
                                    sx={{
                                        // width: "30%",
                                        width: "100%",
                                        height: "100%",
                                        // width: (pathName !== `${(ModulePath.projects)}` && (toggleChatWindow)) ? "100%" : "0%",
                                        // display: (pathName !== `${(ModulePath.projects)}` && (toggleChatWindow)) ? "flex" : "none",
                                        flexShrink: 0,
                                        borderRadius: theme.shape.borderRadius,
                                        overflow: "hidden",
                                        transition: "all 0.5s ease-in-out",
                                        background: theme.palette.ai.light,
                                        '& .MuiDrawer-paper': {
                                            width: "26%",
                                            borderRadius: 0,
                                            boxSizing: 'border-box',
                                            bgcolor: theme.palette.background.default,
                                            transition: "all 0.5s ease-in-out",
                                        },
                                    }}
                                    variant="persistent"
                                    anchor="right"
                                    open={true}
                                // className="h-full w-[20%]  flex flex-col" bgcolor={theme.palette.common.white}
                                >
                                    <ChatWindow />
                                </Box>
                            }
                        </Panel>
                    }
                </PanelGroup>
            </Box>

        </Box>
    )
}


const SnackbarT2T = ({ globalSnackbar, setGLobalSnackBar }) => (
    <Snackbar
        autoHideDuration={3000}
        sx={{ zIndex: '99999' }}
        onClose={() =>
            setGLobalSnackBar({
                open: false,
                title: "",
                severity: "",
            })
        }
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        open={globalSnackbar?.open}
    >
        <Alert
            severity={globalSnackbar?.severity}
            variant="filled"
            sx={{ width: "100%" }}
        >
            {globalSnackbar?.title}
        </Alert>
    </Snackbar>
);

const GlobalLoader = () => {
    return (
        <Box className="absolute top-0 left-0 right-0 bottom-0 flex flex-col items-center justify-center w-full h-full z-20 backdrop-blur-sm">
            <CircularProgress />
        </Box>
    )
}

function CustomPanelResizeHandle() {
    const theme = useTheme();
    return (
        <PanelResizeHandle className={` w-4 flex  justify-center  hover:bg-gray-200 transition-all ease-in-out duration-300`} style={{ borderRadius: theme.shape.borderRadius }}>
            <Box className="w-0 h-full flex flex-col items-center justify-center ">
                <FaGripLinesVertical />
            </Box>
        </PanelResizeHandle>
    )
}