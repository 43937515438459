// import { PUBLIC_URL } from "../../../next-env";

import { alpha } from "@mui/material";
import { COLORS } from "../styles/theme";


let PUBLIC_URL = {
  url: "http://localhost:5000"
}

const isProductionMode = false;

export class APP_CONFIG {
  static appName = "Floxpad";
  static appVersion = "0.1.2";
}

export class API_CONFIG {
  static minute = 4;

  static timeout = this.minute * 60 * 1000; // 5 minutes
}

export class HOST_URL {
  static base_url = isProductionMode ? PUBLIC_URL.url : "https://floxpad.com/api";

  static host_url = isProductionMode
    ? `${this.base_url}/new/api`
    : `${this.base_url}`;
}

export class BASE_URL {
  static base_url = "";
}

export class MAIN_URL {
  static url = HOST_URL.host_url + BASE_URL.base_url;
}

export class REQUEST_URL {
  static login = "/authenticate";
  static register = "/register";
  static verify = "/verify";
  static requestOTP = "/requestOTP";
  static extendSession = "/extendSession";


  static profile = "/profile";
  static updatePassword = `${this.profile}/updatePassword`;

  static labels = "/labels";


  static category = "/categories";


  static projects = "/projects";
  static projectStatus = `${this.projects}/status`;
  static projectDetail = `${this.projects}/details`;


  static folder = "/folder";
  static folderStructure = `${this.folder}/explorer`;
  static folderLinkItems = `${this.folder}/link`;


  static userStory = "/userstory";

  static userStoryPropertiesList = `${this.userStory}/properties`;
  static userStoryPriority = `${this.userStory}/priority`;
  static userStoryType = `${this.userStory}/type`;
  static userStoryMOSCOW = `${this.userStory}/moscow`;
  static userStoryConfidenciality = `${this.userStory}/confidentiality`;
  static userStoryStatus = `${this.userStory}/state`;

  static userStoryList = `${this.userStory}/list`;
  static userStoryListAll = `${this.userStory}/list/all`;

  static userStoryLink = `${this.userStory}/link`;
  static userStoryCustomId = `${this.userStory}/fetch_customId`;
  static userStoryComment = `/comment`;


  static useCase = "/usecase";

  static useCasePropertiesList = `${this.useCase}/properties`;
  static useCasePriority = `${this.useCase}/priority`;
  static useCaseType = `${this.useCase}/type`;
  static useCaseStatus = `${this.useCase}/status`;
  static useCaseState = `${this.useCase}/state`;

  static useCaseList = `${this.useCase}/list`;
  static useCaseListAll = `${this.useCase}/list/all`;

  static useCaseLink = `${this.useCase}/link`;
  static useCaseCustomId = `${this.useCase}/fetch_customId`;
  static useCaseComment = `/comment`;

  static testCase = "/testcase";

  static testCasePropertiesList = `${this.testCase}/properties`;
  static testCasePriority = `${this.testCase}/priority`;
  static testCaseType = `${this.testCase}/type`;
  static testCaseStatus = `${this.testCase}/status`;
  static testCaseState = `${this.testCase}/state`;

  static testCaseList = `${this.testCase}/list`;
  static testCaseListAll = `${this.testCase}/list/all`;

  static testCaseLink = `${this.testCase}/link`;
  static testCaseCustomId = `${this.testCase}/fetch_customId`;
  static testCaseComment = `/comment`;


  static aiChat = "/ai";
  static aiChatConversation = `${this.aiChat}/conversation`;
  static aiChatSave = `${this.aiChat}/save`;
  static aiChatGenerate = `${this.aiChat}/generate`;
  static aiChatEntity = `${this.aiChat}/entity`;

  static file = "/files";
}

export class ModulePath {
  static login = "/login";
  static register = "/register";
  static forgotPassword = "/forgot-password";
  static verifyAccount = "/verify-account";
  static resetPassword = "/reset-password";
  static profile = "/profile";

  static home = "/home";
  static story = "/story";
  static marketplace = "/marketplace";
  static execution = "/execution";
  static settings = "/settings";

  static projects = "/project";
  static programmes = "/programme";
  static departments = "/department";
  static organisations = "/organisation";
  static requirements = "/userstory";
  static useCase = "/usecase";
  static testCase = "/testcase";
  static planning = "/planning";
  static dashboard = "/dashboard";
}

export class Severity {
  static success = "success";
  static info = "info";
  static warning = "warning";
  static error = "error";
}

export class AI_Entity {
  static module = "Modules";
  static userStory = "UserStory";
  static useCase = "UseCase";
  static testCase = "TestCase";
  static folder = "Folders";
}

export class AI_Response_SentBy {
  static user = "USER";
  static model = "FLOXPAD";
}

export class FolderType {
  static module = "module";
  static project = "project";
  static folder = "folder";
}

export class StoryStatus {
  static pending = "Pending";
  static inprogress = "Inprogress";
  static completed = "Completed";
}

export class Entity_Label {
  static project = "Project";
  static module = "Module";
  static userStory = "User Story";
  static useCase = "Use Case";
  static testCase = "Test Case";
  static folder = "Module";

  static dashboard = "Dashboard";
}

export class Entity {
  static project = "Project";
  static userStory = "UserStory";
  static useCase = "UseCase";
  static testCase = "TestCase";
  static folder = "Folders";
  static dashboard = "Dashboard";
}

export class FileType {
  static imageList = ["image/jpeg", "image/png", "image/jpg"];
  static pdfList = ["application/pdf"];
  static documentList = ["application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/doc", "application/docx"];
  static excelList = ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/xls", "application/xlsx"];
  static videoList = ["video/mp4"];
}

export class VerificationType {
  static Account = "Account";
  static ForgotPassword = "ForgotPassword";
}

export class VerificationStatus {
  static Verified = "Verified";
  static Unverified = "Unverified";
}


export class Dashboard_Entity {
  static project = {
    label: "Project",
    value: "Project"
  };
  static userStory = {
    label: "User Story",
    value: "UserStory"
  };
  static useCase = {
    label: "Use Case",
    value: "UseCase"
  };
  static testCase = {
    label: "Test Case",
    value: "TestCase"
  };
  static teams = {
    label: "Teams",
    value: "Teams"
  };
  static traceability = {
    label: "Traceability",
    value: "Traceability"
  };
  static cost = {
    label: "Cost",
    value: "Cost"
  };
  static userPersona = {
    label: "User Persona",
    value: "UserPersona"
  };
  static qualityAssurance = {
    label: "Quality Assurance",
    value: "QualityAssurance"
  };
}


export const CHART_OPTIONS = {
  title: {
    display: true,
    text: ' Chart Title',
    fontColor: COLORS.text,
  },
  legend: {
    display: true,
    position: 'top',
    labels: {
      fontColor: alpha(COLORS.text, 0.8),
    },
  },
  scales: {
    yAxes: [{
      ticks: {
        beginAtZero: true,
        fontColor: alpha(COLORS.text, 0.8),
      },
    }],
  },
};

export const CHART_COLORS = {
  primaryColor: COLORS.primary,
  secondaryColor: COLORS.secondary,
  backgroundColor: COLORS.background,
  borderColor: COLORS.primary,
};
