
import { ArrowLeftOutlined, ArrowRightOutlined, DeleteOutlined, DownloadOutlined } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { AppBar, Box, Button, CircularProgress, Dialog, Divider, IconButton, List, ListItemButton, ListItemText, Slide, Toolbar, Typography, useTheme } from '@mui/material';
import { forwardRef, useState, Fragment } from 'react';
import { font } from '../../styles/font';
import { useApplicationContext } from '../../context/ApplicationContext';
import useErrorHandler from '../../api/errorHandler/useErrorHandler';
import { useEffect } from 'react';
import { FileType, Severity } from '../../constant/ApplicationVariables';
import { HttpStatusCode } from 'axios';
import { getFileDetail_API } from '../../api/controller/FileController';
import { FaRegFileExcel, FaRegFilePdf, FaRegFileWord } from 'react-icons/fa';
import { blue, green, red } from '@mui/material/colors';
import MediaAttachmentComponent from './MediaAttachmentComponent';
import { LightTooltip } from '../styled/CustomComponent';
import { ExcelCustomIcon, ImageCustomIcon, PdfCustomIcon, VideoCustomIcon, WordCustomIcon } from '../styled/CustomIcons';
import { formatDateTime } from '../../utils/DateTime';


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ICON_CONFIG {
    static document = { color: blue[500], background: blue[50], icon: <WordCustomIcon sx={{ fontSize: font.size.fs_1, }} /> };
    static excel = { color: green[500], background: green[50], icon: <ExcelCustomIcon sx={{ fontSize: font.size.fs_1, }} /> };
    static pdf = { color: red[500], background: red[50], icon: <PdfCustomIcon sx={{ fontSize: font.size.fs_1, }} /> };
}

export default function MediaPreview({ open, handleClose, mediaItem, mediaList }) {
    const { handleSnackbar } = useApplicationContext();
    const theme = useTheme();
    const errorHandler = useErrorHandler();


    const [loading, setLoading] = useState(false);
    const [extractedUrl, setExtractedUrl] = useState("");
    const [currentMediaData, setCurrentMediaData] = useState(null);
    const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
    const [mediaUrlData, setMediaUrlData] = useState(null);


    // if (currentMediaData) {
    //     let { name, contentType, size, uploadedAt, url } = currentMediaData;
    // }

    function handleMedia(data) {
        if (FileType.imageList.includes(data?.contentType)) {
            return (
                <img src={mediaUrlData[data?.name?.toLowerCase()]} alt={data?.name} className="object-contain h-full w-full   overflow-hidden" style={{ borderRadius: theme.shape.borderRadius }} />
            );
        }
        if (FileType.videoList.includes(data?.contentType)) {
            return (
                <video src={mediaUrlData[data?.name?.toLowerCase()]} alt={data?.name} className="object-fill w-full h-full overflow-hidden" style={{ borderRadius: theme.shape.borderRadius }} />
            );
        }
        // if (FileType.audioList.includes(contentType)) return <audio src={url} alt={name} className="object-fill w-full h-full overflow-hidden" />

        if (FileType.documentList.includes(data?.contentType)) {
            return (
                <Box className="flex items-center justify-center w-44 h-44 overflow-hidden border " borderRadius={theme.shape.borderRadius} bgcolor={ICON_CONFIG.document.background} >
                    {ICON_CONFIG.document.icon}
                </Box>
            );
        }
        if (FileType.excelList.includes(data?.contentType)) {
            return (
                <Box className="flex items-center justify-center w-44 h-44 overflow-hidden border " borderRadius={theme.shape.borderRadius} bgcolor={ICON_CONFIG.excel.background}>
                    {ICON_CONFIG.excel.icon}
                </Box>
            );
        }
        if (FileType.pdfList.includes(data?.contentType)) {
            return (
                <Box className="flex items-center justify-center  w-44 h-44 overflow-hidden border " borderRadius={theme.shape.borderRadius} bgcolor={ICON_CONFIG.pdf.background} >
                    {ICON_CONFIG.pdf.icon}
                </Box>
            );
        }
    }


    const handleShowMediaPreview = (e, data, index) => {
        e && e.stopPropagation();
        setCurrentMediaIndex(index);
        setCurrentMediaData(data);
    }

    const handleNextPrevious = (type) => {
        if (type === "next") {
            if (currentMediaIndex < mediaList.length - 1) {
                setCurrentMediaIndex((prevIndex) => prevIndex + 1);
                setCurrentMediaData(mediaList[currentMediaIndex + 1]);
            }

        }

        if (type === "previous") {
            if (currentMediaIndex > 0) {
                setCurrentMediaIndex((prevIndex) => prevIndex - 1);
                setCurrentMediaData(mediaList[currentMediaIndex - 1]);
            }
        }
    }

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = extractedUrl;
        link.download = currentMediaData?.name;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleGetFileDetail = async (media) => {
        try {
            setLoading(true);

            let body = {
                path: media?.url
            }

            const response = await getFileDetail_API(body);
            console.log(response, "response handleUpdateStory")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response?.status === HttpStatusCode.Ok) {

                const url = URL.createObjectURL(response.data);

                console.log(url, "mediaattachement url2");

                setMediaUrlData(prevState => ({ ...prevState, [media?.name?.toLowerCase()]: url }));

                setExtractedUrl(url);

                // setExtractedUrl(`data:image/png;base64,${btoa(response.data)}`)
            }

            console.log(response, "mediaattachement data");
        } catch (error) {
            console.log(error, error?.message, "mediaattachement errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    // to get file detail , media list not provided
    useEffect(() => {
        if (currentMediaData && !mediaUrlData?.[currentMediaData?.name?.toLowerCase()]) {
            handleGetFileDetail(currentMediaData);
        }
    }, [currentMediaData]);



    useEffect(() => {
        if (mediaItem) {
            setCurrentMediaData(mediaItem);
            if (mediaList?.length) {
                const currentIndex = mediaList?.findIndex((data) => data?.name?.toLowerCase() === mediaItem?.name?.toLowerCase()) ?? -1;
                if (currentIndex !== -1) {
                    setCurrentMediaIndex(currentIndex);
                }
            } else {
                setCurrentMediaIndex(0);
            }
        }
    }, [mediaItem]);


    useEffect(() => {
        if (mediaList?.length > 0) {
            mediaList?.map((media, index) => {
                return handleGetFileDetail(media);
            })
        }
    }, [mediaList]);


    const handleOnClose = () => {
        handleClose();
    };

    console.log(currentMediaData, "currentMediaData", mediaList, extractedUrl, mediaList?.name === currentMediaData?.name, mediaList, currentMediaData);
    console.log(mediaUrlData, "mediaUrlData")


    // if (loading) {
    //     return (
    //         <Box className="flex flex-col items-center justify-center h-full w-full absolute left-0 top-0 backdrop-blur-sm">
    //             <CircularProgress color="secondary" size={16} />
    //         </Box>
    //     )
    // }

    return (
        <Box>
            <Dialog
                fullScreen
                open={open}
                onClose={handleOnClose}
                TransitionComponent={Transition}
                PaperProps={{
                    sx: {
                        background: "transparent"
                    }
                }}
                sx={{
                    backdropFilter: "blur(5px)",
                    backgroundColor: "transparent"
                }}
            >
                <Box className="flex flex-col w-full h-full relative" >

                    {/* header */}
                    <Box className="flex items-center justify-end gap-2 px-4 py-2  absolute left-0 top-0 w-full z-10 ">

                        {false && <Box className="flex-1 flex items-center gap-2">
                            <Typography fontSize={font.size.fs_4} className="flex-1 flex">
                                {currentMediaData?.name}
                            </Typography>

                            <Typography fontSize={font.size.fs_4} className="flex">
                                {FileType.imageList.includes(currentMediaData?.contentType) && <ImageCustomIcon />}
                                {FileType.videoList.includes(currentMediaData?.contentType) && <VideoCustomIcon />}
                                {FileType.pdfList.includes(currentMediaData?.contentType) && <PdfCustomIcon />}
                                {FileType.excelList.includes(currentMediaData?.contentType) && <ExcelCustomIcon />}
                                {FileType.documentList.includes(currentMediaData?.contentType) && <WordCustomIcon />}
                            </Typography>

                            <Typography fontSize={font.size.fs_4} className="flex">
                                {currentMediaData?.size}
                            </Typography>

                            <Typography fontSize={font.size.fs_4} className="flex">
                                ({formatDateTime(currentMediaData?.uploadedAt)})
                            </Typography>
                        </Box>}

                        <LightTooltip title="Download">
                            <IconButton onClick={() => handleDownload(currentMediaData)} sx={{ backdropFilter: "blur(5px)" }}>
                                <DownloadOutlined sx={{color : theme.palette.text.white}}  />
                            </IconButton>
                        </LightTooltip>

                        {/* <IconButton color="error" onClick={() => handleRemove(data)} sx={{ backdropFilter: "blur(5px)" }}>
                                <DeleteOutlined />
                            </IconButton> */}

                        <IconButton onClick={handleClose} sx={{ backdropFilter: "blur(5px)" }}>
                            <CloseIcon sx={{color : theme.palette.text.white}} />
                        </IconButton>
                    </Box>


                    <Box className="flex-1 flex flex-col items-center justify-between gap-4 pt-4 relative overflow-auto">

                        {loading &&
                            <Box className="flex flex-col items-center justify-center h-full w-full absolute left-0 top-0 ">
                                <CircularProgress color="secondary" size={28} />
                            </Box>
                        }

                        {/* button and media container */}
                        <Box className="  flex-1 flex  justify-between px-4 gap-4 h-[60%] w-full overflow-hidden">

                            <Box className=" w-fit flex flex-col items-center justify-center ">
                                <IconButton sx={{ color: theme.palette.text.white, backdropFilter: "blur(5px)" }} disabled={loading || !mediaList?.length || currentMediaIndex === 0} onClick={() => handleNextPrevious("previous")}>
                                    <ArrowLeftOutlined />
                                </IconButton>
                            </Box>
                            <Box className=" flex  flex-col overflow-hidden select-none">
                                <Box className="flex-1  flex  flex-col items-center justify-center  overflow-hidden " borderRadius={theme.shape.borderRadius}>
                                    {currentMediaData && !loading && handleMedia(currentMediaData)}
                                </Box>
                            </Box>

                            <Box className="w-fit  flex flex-col items-center justify-center ">
                                <IconButton sx={{ color: theme.palette.text.white , backdropFilter: "blur(5px)" }} disabled={loading || !mediaList?.length || currentMediaIndex === mediaList?.length - 1} onClick={() => handleNextPrevious("next")}>
                                    <ArrowRightOutlined />
                                </IconButton>
                            </Box>
                        </Box>

                        {/*  attachments list */}
                        {mediaList?.length > 0 && (
                            <Box className="w-full min-h-fit flex items-center justify-center gap-4 px-4 py-2 overflow-x-auto  backdrop-blur select-none " >
                                <Box className="flex-1 flex gap-4   ">
                                    {mediaList?.map((file, index) => (
                                        <Box key={index} className={`w-32 h-32 flex flex-col  overflow-hidden cursor-pointer relative px-2 py-2 border-2   ${file?.name === currentMediaData?.name ? "border-white " : "border-transparent"}`} borderRadius={theme.shape.borderRadius} onClick={(e) => handleShowMediaPreview(e, file, index)}>
                                            <Box key={index} className={` h-[80%] flex items-center justify-center relative overflow-hidden `} borderRadius={theme.shape.borderRadius}>
                                                <MediaAttachmentComponent data={file} showDownload={false} showDelete={false} isOnClick={false} />
                                            </Box>

                                            <LightTooltip title={
                                                <Box className="flex flex-col gap-2">
                                                    <Typography fontSize={font.size.fs_4} className="flex-1 flex">
                                                        {file?.name}
                                                    </Typography>

                                                    

                                                    

                                                    <Typography fontSize={font.size.fs_4} className="flex">
                                                        {FileType.imageList.includes(file?.contentType) && <ImageCustomIcon />}
                                                        {FileType.videoList.includes(file?.contentType) && <VideoCustomIcon />}
                                                        {FileType.pdfList.includes(file?.contentType) && <PdfCustomIcon />}
                                                        {FileType.excelList.includes(file?.contentType) && <ExcelCustomIcon />}
                                                        {FileType.documentList.includes(file?.contentType) && <WordCustomIcon />}
                                                    </Typography>

                                                    <Typography fontSize={font.size.fs_4} className="flex">
                                                        {file?.size}
                                                    </Typography>

                                                    <Typography fontSize={font.size.fs_4} className="flex">
                                                        ({formatDateTime(file?.uploadedAt)})
                                                    </Typography>
                                                </Box>}>
                                                <Typography fontSize={font.size.fs_4} className="truncate z-10" sx={{ color: theme.palette.text.white }}>{file?.name}</Typography>
                                            </LightTooltip>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        )
                        }

                    </Box>
                </Box>
            </Dialog>
        </Box>
    );
}