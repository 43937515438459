import * as React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import { HttpStatusCode } from 'axios';
import { login_API } from '../../../api/controller/authController';
import { useApplicationContext } from '../../../context/ApplicationContext';
import useErrorHandler from '../../../api/errorHandler/useErrorHandler';
import { useLocation, useNavigate } from 'react-router-dom';
import { ModulePath, Severity, VerificationStatus, VerificationType } from '../../../constant/ApplicationVariables';
import validator from "validator";
import { useState } from 'react';
import { useEffect } from 'react';
import { CircularProgress, IconButton, InputAdornment, useTheme } from '@mui/material';
import { AutoAwesome, Visibility, VisibilityOff, WavingHand } from '@mui/icons-material';
import { font } from '../../../styles/font';
import ApplicationLogo from '../../layout/ApplicationLogo';
import { amber } from '@mui/material/colors';
import LeftSection from './leftSection';


const Container = styled(Stack)(({ theme }) => ({
    height: 'calc((1 - var(--template-frame-height, 0)) * 100dvh)',
    // background: theme.palette.background.custom,
    minHeight: '100%',
    // padding: theme.spacing(2),
    zIndex: 10,

    // background: `linear-gradient(to bottom, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,

    // [theme.breakpoints.up('sm')]: {
    //     padding: theme.spacing(4),
    // },

    // '&::before': {
    //     content: '""',
    //     display: 'block',
    //     position: 'absolute',
    //     zIndex: -1,
    //     inset: 0,
    //     backgroundImage:
    //         'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
    //     backgroundRepeat: 'no-repeat',
    //     ...theme.applyStyles('dark', {
    //         backgroundImage:
    //             'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))',
    //     }),
    // },



    // width: "100%",
    // height: "100%",
    // backgroundSize: "100px 100px",
    // backgroundImage: "radial-gradient(#444cf7 10%, transparent 10%), radial-gradient(#444cf7 10%, transparent 10%)",
    // backgroundPosition: "0 0, 50px 50px"
}));

export default function MainContainer({ children }) {
    const navigate = useNavigate();
    const pathName = useLocation()?.pathname;
    const theme = useTheme();


    return (
        <Box className=" flex flex-col gap-0 relative h-full w-full box-border overflow-hidden" bgcolor={theme.palette.background.custom}>


            {/* <img src='/images/blobImages/image.png' alt='blob1' className="w-64 h-72 absolute -top-20 -right-6 opacity-60" />
            <img src='/images/blobImages/image2.png' alt='blob2' className="w-80 h-96 absolute -bottom-20 -right-12 opacity-60" />
            <img src='/images/blobImages/image4.png' alt='blob4' className="w-80 h-96 absolute -bottom-16 -left-10 opacity-60" />
            <img src='/images/blobImages/image3.png' alt='blob3' className="w-80 h-96 absolute -top-36 -left-10 opacity-60" /> */}

            {/* <img src='/images/blobImages/image5.png' alt='blob5' className="w-[50%] h-[80%] absolute top-24 right-80 opacity-60" /> */}


            {/* <div className="absolute inset-0">
                <div className="absolute inset-0 -z-10 h-full w-full bg-white bg-[linear-gradient(to_right,#8080800a_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:14px_24px]"></div>
            </div> */}

            <Container direction="row" justifyContent="space-between" className="backdrop-blur-sm">

                {false && <div className="absolute inset-0">
                    <div className="absolute inset-0 -z-10 h-full w-full bg-white bg-[linear-gradient(to_right,#8080800a_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[length:14px_24px]"></div>
                </div>}

                {false && <div className="absolute inset-0">
                    <div className="relative h-full w-full bg-red [&>div]:absolute [&>div]:h-full [&>div]:w-full [&>div]:bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [&>div]:[background-size:16px_16px] [&>div]:[mask-image:radial-gradient(ellipse_50%_50%_at_50%_50%,#000_70%,transparent_100%)]">
                        <div></div>
                    </div>
                </div>}


                {/* left part */}
                <LeftSection />

                {/* right part */}
                <Box className={`w-[38vw] flex flex-col  ${pathName === ModulePath.register ? "gap-8" : "gap-16"} px-20 pt-20 `} bgcolor={theme.palette.background.default}>

                    <Box className="flex items-center px-0 py-0 ">
                        <ApplicationLogo  imageHeight={"50px"} imageWidth={"auto"} />
                    </Box>

                    {children}
                </Box>
            </Container>
        </Box>
    );
}


export const AuthCardComponent = styled(MuiCard)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    width: '100%',
    // py: theme.spacing(4),
    // gap: theme.spacing(6),
    margin: 'auto',
    borderRadius: theme.shape.borderRadius,
    // background: theme.palette.background.paper,
    background: "transparent",
    boxShadow: "none",
    [theme.breakpoints.up('sm')]: {
        // maxWidth: '450px',
    },
    // boxShadow:
    //     'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
    // ...theme.applyStyles('dark', {
    //     boxShadow:
    //         'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
    // }),
}));