import { Box, getContrastRatio, Typography, useTheme } from '@mui/material'
import React from 'react'
import { font } from '../../styles/font'

export default function LabelBadge({label}) {
    const theme = useTheme();
    
    return (
        <Box className="flex items-center px-2 py-1" sx={{ bgcolor: label?.colorCode, borderRadius: theme.shape.borderRadius }}>
            <Typography fontSize={font.size.fs_6} color={getContrastRatio(label?.colorCode, '#FFFFFF') < 4.5 ? '#000000' : '#FFFFFF'}>{label?.name}</Typography>
        </Box>
    )
}
