import { AccountCircleOutlined, Add, AddOutlined, ArrowBack, ArrowBackOutlined, ArrowLeft, ArrowLeftOutlined, ArrowRightAlt, AutoAwesome, AutoAwesomeOutlined, AutoModeOutlined, CancelOutlined, CloseOutlined, Delete, DeleteOutline, Description, DownloadOutlined, Edit, EditOutlined, FilterAltOffOutlined, FilterAltOutlined, FolderOutlined, FolderSpecialOutlined, Home, HomeOutlined, KeyboardDoubleArrowLeft, MenuOpen, MoreHoriz, UploadOutlined, Visibility, VisibilityOff } from '@mui/icons-material';
import { Avatar, Box, Breadcrumbs, Button, Chip, CircularProgress, Divider, Fab, FormControl, FormHelperText, FormLabel, Icon, IconButton, InputAdornment, Skeleton, Tab, Tabs, TextField, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material'
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import GridViewIcon from '@mui/icons-material/GridView';
import ListIcon from '@mui/icons-material/List';
import { LightTooltip } from "../styled/CustomComponent";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { HttpStatusCode } from 'axios';
import { FolderType, ModulePath, Severity, VerificationStatus, VerificationType } from "../../constant/ApplicationVariables";
import useErrorHandler from '../../api/errorHandler/useErrorHandler';
import { addModule_API, deleteModule_API, updateModule_API } from '../../api/controller/ModuleController';
import { getFolderStructureList_API } from '../../api/controller/RequirementController';
import { useApplicationContext } from '../../context/ApplicationContext';
import { font } from '../../styles/font';
import validator from "validator"
import { register_API, resetPassword_API } from '../../api/controller/authController';
import { updateUserDetail_API } from '../../api/controller/userController';
import { MuiTelInput } from 'mui-tel-input';
import MediaComponent from '../media/MediaComponent';



export default function ProfileContainer() {
    const theme = useTheme();


    return (
        <Box className=" flex flex-col gap-0   h-full w-full box-border  overflow-hidden  " >

            {/* main container */}
            <Box className="flex-1 flex flex-col gap-4  px-4 py-2 h-full relative overflow-hidden  "    >
                <ProfileComponent />
            </Box>

        </Box>
    )
}

function ProfileComponent({ }) {
    const {
        handleSnackbar,
        handleStoreUserData,
        userDetail, setUserDetail,
        handleLogout
    } = useApplicationContext();
    const theme = useTheme();
    const navigate = useNavigate();
    const errorHandler = useErrorHandler();


    const [profileData, setProfileData] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [error, setError] = useState(null);
    const [phone, setPhone] = useState('')


    const [passwordData, setPasswordData] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [loading, setLoading] = useState(false);
    const [updatePasswordLoading, setUpdatePasswordLoading] = useState(false);

    const fileInputRef = useRef(null);


    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent default form submission behavior
        validateInputs(); // Call your validation function
    };


    const handleSubmitUpdatePassword = (event) => {
        event.preventDefault(); // Prevent default form submission behavior
        validatePasswordInputs(); // Call your validation function
    };


    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }

    const handleClearSelectedFiles = (e) => {
        e.stopPropagation();
        setSelectedFiles([]);
    }

    const handleUploadSelectedFiles = (e) => {
        e.stopPropagation();

        let body = {};

        if (selectedFiles?.length > 0) {
            body.profilePic = selectedFiles
            // setSelectedFiles([]);
        }

        handleContinue(body);
    }


    const handleChangeValue = (event) => {
        const { name, value } = event.target;

        if (name === "newPassword" || name === "currentPassword") {
            setPasswordData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        }

        setProfileData((prevData) => ({
            ...prevData,
            [name]: value
        }));

    }


    const handleChangeFiles = (e) => {
        let files = e.target.files;
        setSelectedFiles([...files]);

        console.log(files, files && files[0] && URL.createObjectURL(files[0]), "files")
    }

    const validateInputs = () => {
        let isValid = true;
        const errorObject = {};


        if (!profileData?.firstName || profileData?.firstName?.length < 3) {
            errorObject.firstName = "First name must be at least 3 characters long.";
            isValid = false;
        }

        if (!profileData?.lastName || profileData?.lastName?.length < 3) {
            errorObject.lastName = "Last name must be at least 3 characters long.";
            isValid = false;
        }

        if (profileData?.phone && (profileData?.phone?.trim()?.length < 8 || profileData?.phone?.trim()?.length > 20)) {
            errorObject.phone = "Phone number must be between 8 - 12 digit long..";
            isValid = false;
        }

        if (!profileData?.companyName || profileData?.companyName?.length < 3) {
            errorObject.companyName = "Company name must be at least 3 characters long.";
            isValid = false;
        }

        if (!profileData?.email || !validator.isEmail(profileData?.email)) {
            errorObject.email = "Please enter a valid email address.";
            isValid = false;
        }



        setError(errorObject);

        console.log(isValid, errorObject, "isValid");


        let body = {
            firstName: profileData?.firstName,
            lastName: profileData?.lastName,

            // email: profileData?.email,

            phone: profileData?.phone?.replace(/\s/g, ""), // optional
            // role: profileData?.role, // optional
            companyName: profileData?.companyName, // optional
        }

        // if (selectedFiles?.length > 0) {
        //     body.profilePic = selectedFiles
        //     // setSelectedFiles([]);
        // }


        if (profileData?.firstName === userDetail?.firstName) {
            delete body.firstName;
        }

        if (profileData?.lastName === userDetail?.lastName) {
            delete body.lastName;
        }

        if (profileData?.companyName === userDetail?.companyName) {
            delete body.companyName;
        }




        return isValid && handleContinue(body);
    };

    const validatePasswordInputs = async () => {

        let isValid = true;
        let errorObject = {};

        if (!passwordData?.currentPassword || passwordData?.currentPassword?.length < 6) {
            errorObject.currentPassword = "Current Password must be at least 6 characters long.";
            isValid = false;
        }

        if (!passwordData?.newPassword || passwordData?.newPassword?.length < 6) {
            errorObject.newPassword = "New Password must be at least 6 characters long.";
            isValid = false;
        }

        if (passwordData?.currentPassword && (!passwordData?.newPassword || passwordData?.newPassword?.length < 6)) {
            errorObject.newPassword = "New Password must be at least 6 characters long.";
            isValid = false;
        }

        if (passwordData?.currentPassword && passwordData?.newPassword && passwordData?.currentPassword === passwordData?.newPassword) {
            errorObject.newPassword = "Current Password and New Password cannot be same.";
            isValid = false;
        }

        console.log(isValid, errorObject, "isValid passwordData", passwordData?.currentPassword, passwordData?.newPassword)


        setError(errorObject);


        let body = {
            currentPassword: passwordData?.currentPassword,
            newPassword: passwordData?.newPassword,
        }


        return isValid && handleContinue(body);
    }


    const handleContinue = async (bodyData) => {

        try {

            if (bodyData?.currentPassword) {
                setUpdatePasswordLoading(true)
            } else {
                setLoading(true)
            }


            let body = {
                ...bodyData
            }

            if (Object.keys(body)?.length === 0) {
                return;
            }
            console.log(body, "body in register")


            const bodyFormData = new FormData();

            Object.keys(body).forEach(key => {
                if (Array.isArray(body[key])) {

                    if (key === "profilePic") {

                        // body[key]?.forEach((file, index) => {
                        //     bodyFormData.append(`${key}[${index}]`, file, file.name);
                        // })

                        bodyFormData.append(key, body[key][0], body[key][0]?.name);
                    } else {
                        bodyFormData.append(key, body[key]);
                    }

                } else {
                    bodyFormData.append(key, body[key]);
                }
            });




            const response = await updateUserDetail_API(bodyFormData);

            console.log(response, " response in updateprofile")

            if (response) {
                errorHandler(response, bodyData?.currentPassword ? setUpdatePasswordLoading : setLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                // handleStoreUserData(response.data);
                setProfileData(response.data)
                setUserDetail(response.data);
                setSelectedFiles([]);
                setPasswordData(null);



                handleSnackbar(bodyData?.currentPassword ? `Password updated successfully! Please sign in again` : `User profile updated successfully`, Severity.success);

                if (bodyData?.currentPassword) {
                    setTimeout(() => {
                        handleLogout();
                    }, 2000);
                }


            }

        } catch (error) {
            console.log(error, error?.message, "login errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
                setUpdatePasswordLoading(false);
            }, 1000);
        }
    }

    useEffect(() => {
        setProfileData(userDetail);
    }, [userDetail]);

    // {
    //     "firstName": "sdfds",
    //     "lastName": "lkjf",
    //     "email": "naxasod691@codverts.com",
    //     "role": "Admin",
    //     "phone": null,
    //     "profilePicUrl": null,
    //     "companyName": "dfgdh"
    // }

    console.log(userDetail, "userDetail in settings", profileData);

    console.log(profileData, "profileData");
    console.log(passwordData, "passwordData");
    console.log(phone, "phone")

    return (
        <Box className="w-full h-full flex flex-col gap-1  " >






            <Box
                className="flex gap-16 px-8 py-8 w-full h-full"
            >



                {/* image and button */}
                <Box
                    className="flex flex-col justify-between gap-8 px-0 py-4 w-[28%]"
                >
                    {/* image  */}
                    <Box className="flex flex-col items-center justify-center gap-4 w-full h-72  relative shadow" borderRadius={theme.shape.borderRadius} >

                        {
                            selectedFiles?.length > 0
                                ? (
                                    <Avatar variant="rounded" src={URL.createObjectURL(selectedFiles[0])} sx={{ height: "100%", width: "100%", }} />
                                )
                                : profileData?.profilePicUrl
                                    ? (
                                        <MediaComponent name={"Profile pic"} type={"image"} variant={"rounded"} url={profileData?.profilePicUrl} sx={{ borderRadius: theme.shape.borderRadius, overFlow: "hidden" }} />
                                    )
                                    : (
                                        <Avatar variant="rounded" sx={{ fontSize: font.size.fs_1, background: theme.palette.secondary.light, color: theme.palette.secondary.main, height: "100%", width: "100%" }}> {profileData?.firstName?.charAt(0)} </Avatar>
                                    )
                        }


                        <input
                            ref={fileInputRef}
                            type="file"
                            multiple
                            accept="*"
                            onChange={handleChangeFiles}
                            style={{ display: "none" }}
                        />

                        {/* selected file : upload, cancel button */}
                        {selectedFiles?.length > 0 && <Box className="flex items-center justify-between px-4 py-2 w-full absolute left-0 bottom-0 ">
                            <IconButton variant="outlined" className="backdrop-blur-2xl shadow" disabled={loading} onClick={handleClearSelectedFiles}>
                                <CancelOutlined />
                            </IconButton>

                            <IconButton variant="outlined" className="backdrop-blur-2xl shadow" disabled={loading} color="secondary" onClick={handleUploadSelectedFiles}>
                                <UploadOutlined />
                            </IconButton>
                        </Box>}

                        {/* no selected file, select image button */}
                        {selectedFiles?.length === 0 && <Box className="flex items-center justify-between px-4 py-2 w-full absolute left-0 bottom-0 ">
                            <IconButton variant="outlined" className="backdrop-blur-2xl shadow" disabled={loading} color="secondary" onClick={() => fileInputRef.current.click()}>
                                <EditOutlined />
                            </IconButton>
                        </Box>}

                    </Box>


                    {/* password, newpassword */}
                    <Box

                        component="form"
                        onSubmit={handleSubmitUpdatePassword}
                        noValidate

                        className="flex flex-col gap-4 w-full">

                        <FormControl>
                            <FormLabel htmlFor=" currentPassword">Current Password</FormLabel>
                            <TextField
                                // label="Password"
                                error={error?.currentPassword && error?.currentPassword}
                                helperText={error?.currentPassword && error?.currentPassword}
                                name="currentPassword"
                                placeholder="••••••"
                                type={showPassword ? "text" : "password"}
                                id="currentPassword"
                                autoComplete="current-password"
                                autoFocus
                                required
                                fullWidth
                                variant="outlined"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                color={error?.currentPassword ? 'error' : 'primary'}
                                value={passwordData?.currentPassword}
                                onChange={handleChangeValue}
                            />
                        </FormControl>

                        <FormControl>
                            <FormLabel htmlFor="newPassword">New Password</FormLabel>
                            <TextField
                                // label="Confirm Password"
                                error={error?.newPassword && error?.newPassword}
                                helperText={error?.newPassword && error?.newPassword}
                                name="newPassword"
                                placeholder="••••••"
                                type={showConfirmPassword ? "text" : "password"}
                                id="newPassword"
                                autoComplete="current-password"
                                autoFocus
                                required
                                fullWidth
                                variant="outlined"
                                // slotProps={{
                                //     input: {
                                //         inputProps: {
                                //             endAdornment: (
                                //                 <InputAdornment position="end">
                                //                     <IconButton
                                //                         aria-label="toggle password visibility"
                                //                         onClick={handleClickShowConfirmPassword}
                                //                         edge="end"
                                //                     >
                                //                         {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                //                     </IconButton>
                                //                 </InputAdornment>
                                //             ),
                                //         }
                                //     }
                                // }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowConfirmPassword}
                                                edge="end"
                                            >
                                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                color={error?.newPassword ? 'error' : 'primary'}
                                value={passwordData?.newPassword}
                                onChange={handleChangeValue}
                            />
                        </FormControl>


                        <Button
                            size="medium"
                            type="submit"
                            // fullWidth
                            color="secondary"
                            variant="contained"
                            // disabled={updatePasswordLoading || error?.currentPassword || error?.newPassword || !profileData?.currentPassword || !profileData?.newPassword}
                            // onClick={() => validatePasswordInputs()}
                            sx={{ color: '#fff', width: "fit-content", marginTop: 0 }}
                        >
                            {updatePasswordLoading ? <CircularProgress size={20} /> : "Update Password"}
                        </Button>
                    </Box>


                </Box>


                <Divider orientation="vertical" flexItem />


                {/* form input */}
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    noValidate
                    className="flex-1 flex flex-col gap-4  px-0 py-4"
                >

                    {/* firstname, lastname */}
                    <Box className="w-full flex gap-4">

                        <FormControl fullWidth>
                            <FormLabel htmlFor="firstName">First Name</FormLabel>
                            <TextField
                                // label="First Name"
                                error={error?.firstName && error?.firstName}
                                helperText={error?.firstName && error?.firstName}
                                id="firstName"
                                type="text"
                                name="firstName"
                                placeholder="First Name"
                                autoComplete="firstName"
                                autoFocus
                                required
                                fullWidth
                                variant="outlined"
                                color={error?.firstName ? 'error' : 'primary'}
                                value={profileData?.firstName}
                                onChange={handleChangeValue}
                            />
                        </FormControl>

                        <FormControl fullWidth>
                            <FormLabel htmlFor="lastName">Last Name</FormLabel>
                            <TextField
                                // label="Last Name"
                                error={error?.lastName && error?.lastName}
                                helperText={error?.lastName && error?.lastName}
                                id="lastName"
                                type="text"
                                name="lastName"
                                placeholder="Last Name"
                                autoComplete="lastName"
                                autoFocus
                                required
                                fullWidth
                                variant="outlined"
                                color={error?.lastName ? 'error' : 'primary'}
                                value={profileData?.lastName}
                                onChange={handleChangeValue}
                            />
                        </FormControl>

                    </Box>

                    {/* email, phone */}
                    <Box className="w-full flex  gap-4">

                        <FormControl fullWidth>
                            <FormLabel htmlFor="email">Email</FormLabel>
                            <TextField
                                disabled
                                // label="Email"
                                error={error?.email && error?.email}
                                helperText={error?.email && error?.email}
                                id="email"
                                type="email"
                                name="email"
                                placeholder="your@email.com"
                                autoComplete="email"
                                autoFocus
                                required
                                fullWidth
                                variant="outlined"
                                color={error?.email ? 'error' : 'primary'}
                                value={profileData?.email}
                                onChange={handleChangeValue}
                            />
                        </FormControl>


                        <FormControl fullWidth>
                            <FormLabel htmlFor="phone">Phone</FormLabel>
                            {/* <TextField
                                // label="Phone"
                                error={error?.phone && error?.phone}
                                helperText={error?.phone && error?.phone}
                                id="phone"
                                type="number"
                                name="phone"
                                placeholder=" +91 1234567890"
                                autoComplete="phone"
                                autoFocus
                                required
                                fullWidth
                                variant="outlined"
                                color={error?.phone ? 'error' : 'primary'}
                                value={profileData?.phone}
                                onChange={handleChangeValue}
                            /> */}
                            <MuiTelInput
                                value={profileData?.phone}
                                onChange={(newPhone) => setProfileData({ ...profileData, phone: newPhone })}
                                defaultCountry="IN"


                                // label="Phone"
                                name="phone"
                                id="phone"
                                placeholder="+91 1234567890"
                                variant="outlined"
                                color={error?.phone ? 'error' : 'primary'}

                            />

                            <FormHelperText error={error?.phone}>{error?.phone && error?.phone}</FormHelperText>
                        </FormControl>

                    </Box>

                    {/* companyname, role */}
                    <Box className="flex gap-4">

                        <FormControl fullWidth>
                            <FormLabel htmlFor="companyName">Company Name</FormLabel>
                            <TextField
                                // label="Company Name"
                                error={error?.companyName && error?.companyName}
                                helperText={error?.companyName && error?.companyName}
                                id="companyName"
                                type="text"
                                name="companyName"
                                placeholder="Company Name"
                                autoComplete="companyName"
                                autoFocus
                                required
                                fullWidth
                                variant="outlined"
                                color={error?.companyName ? 'error' : 'primary'}
                                value={profileData?.companyName}
                                onChange={handleChangeValue}
                            />
                        </FormControl>


                        <FormControl fullWidth>
                            <FormLabel htmlFor="role">Role</FormLabel>
                            <TextField
                                disabled
                                // label="Role"
                                error={error?.role && error?.role}
                                helperText={error?.role && error?.role}
                                id="role"
                                type="text"
                                name="role"
                                placeholder="Role"
                                autoComplete="role"
                                autoFocus
                                required
                                fullWidth
                                variant="outlined"
                                color={error?.role ? 'error' : 'primary'}
                                value={profileData?.role}
                                onChange={handleChangeValue}
                            />
                        </FormControl>

                    </Box>

                    {/* <Box className="flex flex-col gap-4"> password , confirm password update</Box> */}

                    <Button
                        size="medium"
                        type="submit"
                        // fullWidth
                        color="secondary"
                        variant="contained"
                        // disabled={loading || error}
                        // onClick={validateInputs}
                        sx={{ color: '#fff', width: "fit-content", marginTop: 0 }}
                    >
                        {loading ? <CircularProgress size={20} /> : "Update Profile"}
                    </Button>

                </Box>

            </Box>

        </Box>
    )
}

