import axios from "axios";
import { MAIN_URL, REQUEST_URL, VerificationType } from "../../constant/ApplicationVariables";
import { axiosInstance } from "../useAxios/useAxios";

export const login_API = async (body) => {
    try {
        const response = await axios({
            url: MAIN_URL.url + REQUEST_URL.login,
            method: "POST",
            data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}

export const register_API = async (body) => {
    try {
        const response = await axios({
            url: MAIN_URL.url + REQUEST_URL.register,
            method: "POST",
            data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}

export const verifyOTP_API = async (body, headers) => {
    let options = {
        url: MAIN_URL.url + REQUEST_URL.verify,
        method: "POST",
        data: body,
        contentType: "application/json",

        // headers : {
        //     Authorization : `Bearer ${body?.type === VerificationType.Account && localStorage.getItem("access-token")}`
        // }
    }


    if (headers) {
        options.headers = headers
    }

    try {
        const response = await axios(options);
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error..");
        return error;
    }
}

export const requestOTP_API = async (body, headers) => {
    let options = {
        url: MAIN_URL.url + REQUEST_URL.requestOTP,
        method: "POST",
        data: body,
        contentType: "application/json"
    }

    if (headers) {
        options.headers = headers
    }


    try {
        const response = await axios(options);
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}


export const resetPassword_API = async (body) => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.updatePassword,
            method: "POST",
            data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}


export const extendToken_API = async () => {
    try {
        const response = await axiosInstance({
            url: MAIN_URL.url + REQUEST_URL.extendSession,
            method: "GET",
            // data: body,
            contentType: "application/json"
        });
        console.log(response, "loginresponsne response");
        return response
    } catch (error) {
        console.log(error, "loginresponsne error");
        return error;
    }
}

