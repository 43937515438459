import { Avatar, Box, Button, FormControl, IconButton, InputLabel, List, ListItemIcon, ListItemText, Menu, MenuItem, Popover, Select, Skeleton, TextField, ToggleButton, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { AI_Entity, Dashboard_Entity, Entity, Entity_Label, FolderType, ModulePath, Severity } from "../../constant/ApplicationVariables";
import { HttpStatusCode } from "axios";
import { addModule_API, deleteModule_API, updateModule_API } from "../../api/controller/ModuleController";
import { AddOutlined, AddTwoTone, AutoAwesome, AutoAwesomeOutlined, AutorenewOutlined, Close, CreateNewFolderOutlined, DeleteOutline, DeleteTwoTone, DriveFileMoveOutlined, EditOutlined, EditTwoTone, FolderDelete, FolderDeleteOutlined, FolderOffOutlined, FolderSpecialOutlined, FolderSpecialTwoTone, HelpOutline, HelpTwoTone, ListOutlined, LogoutOutlined, MoreHoriz, MoreVertOutlined, Settings } from "@mui/icons-material";
import { LightTooltip } from "../styled/CustomComponent";
import { getFolderStructureList_API } from "../../api/controller/RequirementController";
import useErrorHandler from "../../api/errorHandler/useErrorHandler";
import { useApplicationContext } from "../../context/ApplicationContext";
import Treeview from "../common/Treeview";
import DeleteModuleDialog from "../dialog/DeleteModuleDialog";
import EditModuleDialog from "../dialog/EditModuleDialog";
import CreateModuleDialog from "../dialog/CreateModuleDialog";
import { font } from "../../styles/font";
import { useLocation, useNavigate } from "react-router-dom";
import ApplicationLogo from "./ApplicationLogo";
import { findFolder } from "../../utils/Folder";
import { getProjectCategoryList_API, getProjectDetail_API, getProjectSubcategoryList_API, updateProject_API } from "../../api/controller/ProjectController";
import { ProjectCustomIcon } from "../styled/CustomIcons";
import { DashboardPages } from "../../constant/ApplicationJson";


export default function ApplicationSidebar() {
    const {
        toggleChatWindow, setToggleChatWindow,
        toggleFolderSidebar, setToggleFolderSidebar,
        folderStructureList, setFolderStructureList,
        activeProject, setActiveProject,
        handleSnackbar,
        currentFolderData, setCurrentFolderData,
        isUpdateCurrentFolderData, setIsUpdateCurrentFolderData,
        isUpdateFolderStructure, setIsUpdateFolderStructure,
        handleLogout,
        profileDetails, setProfileDetails,
        currentFolderAnchorEl, setCurrentFolderAnchorEl,
        isUpdateProjectList, setIsUpdateProjectList,

        openCreateFolderDialog,
        setOpenCreateFolderDialog,

        openEditFolderDialog,
        setOpenEditFolderDialog,

        openDeleteFolderDialog,
        setOpenDeleteFolderDialog,

        currentEditFolderData,
        setCurrentEditFolderData,

        currentAIEntityData,
        setCurrentAIEntityData,

        isItemChatWindow,
        setIsItemChatWindow,

        currentDataSavingFolder,
        setCurrentDataSavingFolder,

        projectList, setProjectList,
    } = useApplicationContext();
    const theme = useTheme();
    const errorHandler = useErrorHandler();
    const navigate = useNavigate();
    const pathName = useLocation()?.pathname;

    const [loading, setLoading] = useState(false);
    const [moreAnchorEl, setMoreAnchorEl] = useState(null);

    const [newFolderLocation, setNewFolderLocation] = useState("");



    const [projectData, setProjectData] = useState(activeProject);


    const [isLoading, setIsLoading] = useState(false);

    const [categoryList, setCategoryList] = useState([]);
    const [subCategoryList, setSubCategoryList] = useState([]);


    // const [openCreateModuleDialog, setOpenCreateModuleDialog] = useState(false);
    // const [openEditModuleDialog, setOpenEditModuleDialog] = useState(false);
    // const [openDeleteModuleDialog, setOpenDeleteModuleDialog] = useState(false);


    const handleToggleSidebar = () => {
        setToggleFolderSidebar(!toggleFolderSidebar);
    }

    const handleOpenMorePopup = (e) => {
        e && e.stopPropagation();

        if (!moreAnchorEl) {
            setMoreAnchorEl(e.currentTarget);
        }
    }

    const handleCloseMorePopup = () => {
        setMoreAnchorEl(null);
    }


    // button / icon click start
    const handleToggleFolderAIWindow = () => {
        if (currentAIEntityData?.entity === AI_Entity.folder) {
            setToggleChatWindow(false);
            setCurrentAIEntityData(null);
        } else {
            setToggleChatWindow(true);
            setCurrentAIEntityData({ label: Entity_Label.folder, entity: AI_Entity.folder });

            if (isItemChatWindow) setIsItemChatWindow(false);
        }
    }

    const handleViewAllItems = () => {
        handleClearSelectedFolder();
    }

    const handleClearSelectedFolder = () => {
        setCurrentFolderData(null);
    }

    const handleOpenAddModule = (e, value) => {
        e && e.stopPropagation();
        if (value && value === "root") {
            setCurrentFolderData(null);
        }
        setOpenCreateFolderDialog(true);
        setCurrentFolderAnchorEl(null);
        // setCurrentEditFolderData(currentFolderData);
    }

    const handleOpenEditModule = () => {
        setOpenEditFolderDialog(true);
        setCurrentFolderAnchorEl(null);
        // setCurrentEditFolderData(currentFolderData);
    }

    const handleOpenDeleteModule = () => {
        setOpenDeleteFolderDialog(true);
        setCurrentFolderAnchorEl(null);
        // setCurrentEditFolderData(currentFolderData);
    }

    // button / icon click end

    // project funciton start
    const handleChange = (e) => {
        if (e.target.name === "categoryName" || e.target.name === "subCategoryName") {
            setProjectData({ ...projectData, [e.target.name]: e.target.value?.name });
        } else {
            setProjectData({ ...projectData, [e.target.name]: e.target.value });
        }

    }

    const handleUpdate = () => {
        handleUpdateProject(projectData);
    }

    // project funciton end


    function findFolderDataByPathName(array, path) {
        for (const obj of array) {
            if (obj.path === path) {
                return obj;
            }
            if (obj.children) {
                const found = findFolderDataByPathName(obj.children, path);
                if (found) {
                    return found;
                }
            }
        }
        return null;
    }


    const handleGetFolderStructureList = async (projectData) => {
        try {
            setLoading(true);
            let projectName = projectData?.name;
            let query = `name=${projectName}`

            let body = {
                projectName: projectName,
                section: pathName === ModulePath.requirements ? Entity.userStory : pathName === ModulePath.useCase ? Entity.useCase : Entity.testCase
            }

            console.log(pathName, "pathName handleGetFolderStructureList layout")

            const response = await getFolderStructureList_API(body);
            // setIsUpdateFolderStructure(false);
            console.log(response, "projectreponse handleGetFolderStructureList layout")

            if (response) {
                errorHandler(response);
            }

            if (response.status === HttpStatusCode.Ok) {
                setFolderStructureList(response.data);

                let folderData = findFolder(response.data, "path", newFolderLocation);
                console.log(folderData, "newFolderLocationdata ", newFolderLocation);

                if (newFolderLocation && folderData) {
                    setCurrentFolderData(prev => folderData);
                } else {


                    if (!currentFolderData) {
                        setCurrentFolderData(prev => response.data[0]);
                    }

                    if (currentFolderData) {
                        let folderData = findFolder(response.data, "path", currentFolderData?.path);
                        console.log(folderData, "newFolderLocationdata ", newFolderLocation);

                        setCurrentFolderData(prev => folderData ? folderData : currentFolderData);
                    }


                }

                // handleSnackbar(response.message, Severity.success);
            }

        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setIsUpdateFolderStructure(false);
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }


    const handleCreateModule = async (moduleData) => {
        try {
            setLoading(true);

            let body = {
                ...moduleData,
                "projectName": activeProject?.name,
                "section": pathName === ModulePath.requirements ? Entity.userStory : pathName === ModulePath.useCase ? Entity.useCase : Entity.testCase,
                "path": currentFolderData ? currentFolderData?.path : ""
            }

            const response = await addModule_API(body);
            console.log(response, "response handleCreateModule")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                setTimeout(() => {
                    setIsUpdateFolderStructure(true);
                    setIsUpdateProjectList(true);
                    setOpenCreateFolderDialog(false);
                }, 1000);

                setNewFolderLocation(body.path ? `${body?.path}/${body?.name}` : `${body?.name}`); // To store the new folder location , make this active after creating 

                console.log(body, "body newFolderLocation")


                handleSnackbar(response?.message ? response?.message : `Folder created successfully`, Severity.success);
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const handleUpdateModule = async (moduleData) => {
        try {
            setLoading(true);

            let body = {
                "projectName": activeProject?.name,
                "currentName": currentFolderData?.name,
                "newName": moduleData?.name,
                "description": moduleData?.description,
                "section": pathName === ModulePath.requirements ? Entity.userStory : pathName === ModulePath.useCase ? Entity.useCase : Entity.testCase,
                "path": currentFolderData?.path,
                "delete": false,

                "lastModifiedAt" : currentFolderData?.lastModifiedAt ? currentFolderData?.lastModifiedAt : null,
            }

            if (currentFolderData?.name === moduleData?.name) {
                delete body.newName
            }

            // if(currentFolderData?.path === moduleData?.path) {
            //     delete body.path
            // }

            // if(currentFolderData?.section === moduleData?.section) {
            //     delete body.section
            // }




            const response = await updateModule_API(body);
            console.log(response, "response handleUpdateModule")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response.status === HttpStatusCode.Ok) {

                setIsUpdateFolderStructure(true);
                setOpenEditFolderDialog(false);
                setIsUpdateCurrentFolderData(true);

                setNewFolderLocation(body.newName ? `${body?.newName}` : `${body?.path}`);
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const handleDeleteModule = async () => {
        try {
            setLoading(true);

            let body = {

                "projectName": activeProject?.name,
                "currentName": currentFolderData?.name,
                // "newName": moduleData?.name,
                "section": pathName === ModulePath.requirements ? Entity.userStory : pathName === ModulePath.useCase ? Entity.useCase : Entity.testCase,
                "path": currentFolderData?.path,
                "delete": true
            }

            const response = await updateModule_API(body);
            console.log(response, "response handleDeleteModule")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                setIsUpdateFolderStructure(true);
                setIsUpdateProjectList(true);
                setOpenDeleteFolderDialog(false);
                setCurrentFolderData(folderStructureList[0]);
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }




    // project funciton start
    const handleGetCategoryList = async () => {
        try {


            const response = await getProjectCategoryList_API();

            if (response.status === HttpStatusCode.Ok) {
                setCategoryList(response.data);

                // handleSnackbar(response.message, Severity.success)
            }

            if (response) {
                errorHandler(response);
            }


            console.log(response, "categoryreponse data")
        } catch (error) {
            console.log(error, "categoryreponse errorr")
        }
    }

    const handleGetsubCategoryList = async () => {
        try {


            let query = `parentName=${projectData?.categoryName}`;

            const response = await getProjectSubcategoryList_API(query);

            if (response.status === HttpStatusCode.Ok) {
                setSubCategoryList(response.data);
                setProjectData({ ...projectData, "subCategoryName": response.data[0]?.name });

                // handleSnackbar(response.message, Severity.success)
            }

            if (response) {
                errorHandler(response);
            }


            console.log(response, "subcategoryreponse data")
        } catch (error) {
            console.log(error, "subcategoryreponse errorr")
        }
    }

    const handleGetProjectDetail = async () => {
        try {
            setIsLoading(true);

            let query = `name=${activeProject?.name}`

            const response = await getProjectDetail_API(query);

            if (response.status === HttpStatusCode.Ok) {
                setProjectData(response.data)
                // handleSnackbar(response.message, Severity.success)
            }

            if (response) {
                errorHandler(response, setIsLoading);
            }

            console.log(response, "handleGetProjectDetail data")
        } catch (error) {
            console.log(error, "handleGetProjectDetail errorr")
        } finally {
            setTimeout(() => {
                setIsLoading(false);
            }, 1000)
        }
    }

    const handleUpdateProject = async (projectData) => {

        if (!projectData?.name || !projectData?.description || !projectData?.categoryName || !projectData?.subCategoryName) {
            return handleSnackbar("Please fill all the fields", Severity.error)
        }

        setIsLoading(true)

        try {
            // setIsLoading(true);

            // let body = { ...projectData };

            let body = {
                // "newName": projectData?.name,
                "currentName": activeProject?.name,
                "lastModifiedAt" : activeProject?.lastModifiedAt ? activeProject?.lastModifiedAt : null,
                // "categoryName": projectData?.categoryName,
                // "subCategoryName": projectData?.subCategoryName,
                // "description": projectData?.description,
                // "status": "Archive"
                // "status" : projectData?.status
            }

            if (projectData?.name !== activeProject?.name) {
                body.newName = projectData?.name
            }
            if (projectData?.description !== activeProject?.description) {
                body.description = projectData?.description
            }

            if (projectData?.categoryName !== activeProject?.categoryName) {
                body.categoryName = projectData?.categoryName
            }
            if (projectData?.subCategoryName !== activeProject?.subCategoryName) {
                body.subCategoryName = projectData?.subCategoryName
            }

            if (projectData?.status !== activeProject?.status) {
                body.status = projectData?.status
            }

            if (projectData?.useCasePrefix !== activeProject?.useCasePrefix) {
                body.useCasePrefix = projectData?.useCasePrefix
            }

            if (projectData?.userStoryPrefix !== activeProject?.userStoryPrefix) {
                body.userStoryPrefix = projectData?.userStoryPrefix
            }

            if (projectData?.testCasePrefix !== activeProject?.testCasePrefix) {
                body.testCasePrefix = projectData?.testCasePrefix
            }

            const response = await updateProject_API(body);

            if (response.status === HttpStatusCode.Ok) {
                localStorage.setItem("activeProject", JSON.stringify(response.data));
                setActiveProject(response.data);


                let list = [...projectList];
                let foundIndex = list?.findIndex((item) => item?.name === body.currentName);
                if (foundIndex !== -1) {
                    list[foundIndex] = response.data;
                }
                setProjectList(list);


                // handleGetProjectList();
                handleCloseMorePopup();
            }

            if (response) {
                errorHandler(response, setIsLoading);
            }

            setTimeout(() => {
                setIsLoading(false);
            }, 1000);

            console.log(response, "projectreponse data");
        } catch (error) {
            console.log(error, "projectreponse errorr");
        }
    }

    // project function end

    useEffect(() => {
        handleGetCategoryList();
    }, []);

    useEffect(() => {
        if (projectData?.categoryName) {
            handleGetsubCategoryList();
        }
    }, [projectData?.categoryName]);

    // useEffect(() => {
    //     setActiveProjectCategory(categoryList[0]);
    // }, []);

    useEffect(() => {
        // setProjectData(activeProject);
        handleGetProjectDetail();
    }, [activeProject]);

    // useEffect(() => {
    //     if (activeProject && folderStructureList?.length === 0) {
    //         handleGetFolderStructureList(activeProject);
    //     }
    // }, [activeProject, folderStructureList?.length]);


    useEffect(() => {
        if (activeProject && folderStructureList?.length === 0) {
            handleGetFolderStructureList(activeProject);
        }
    }, [activeProject]);


    useEffect(() => {
        if (isUpdateFolderStructure && activeProject) {
            handleGetFolderStructureList(activeProject);
        }
    }, [isUpdateFolderStructure, activeProject]);


    useEffect(() => {
        if (activeProject) {
            handleGetFolderStructureList(activeProject);
        }

    }, [pathName])

    console.log(pathName === ModulePath.requirements ? Entity.userStory : pathName === ModulePath.useCase ? Entity.useCase : Entity.testCase, "section")

    console.log(isUpdateProjectList, " isUpdateProjectList")
    console.log(newFolderLocation, "newFolderLocatione")
    console.log(currentDataSavingFolder, "currentDataSavingFolder")


    return (
        <Box className="flex flex-col gap-4 w-full h-full overflow-hidden " bgcolor={theme.palette.background.custom} borderRadius={theme.shape.borderRadius}>

            {/* <Box className="w-full h-[8vh] flex items-center justify-center border-b ">
                <ApplicationLogo />
            </Box> */}

            {/* project card */}
            <Box className="flex items-center  gap-0 " sx={{}} bgcolor={theme.palette.background.default} borderRadius={theme.shape.borderRadius}>

                <Box className="flex-1 h-full flex items-center px-4 py-4  gap-2  overflow-hidden max-w-[88.5%]">
                    <Avatar variant="rounded" sx={{ background: theme.palette.secondary.main, color: theme.palette.secondary.contrastText }}>
                        <ProjectCustomIcon sx={{ fontSize: font.size.fs_2, color: "inherit" }} />
                    </Avatar>


                    <Box className=" flex flex-col justify-between flex-1 w-auto gap-1 overflow-hidden" >
                        <LightTooltip
                            title={activeProject?.name}
                            placement="right"
                        >
                            <Typography fontSize={font.size.fs_5} fontWeight={500} sx={{ color: theme.palette.text.primary }}>{activeProject?.name}</Typography>
                        </LightTooltip>
                        {/* <Typography fontSize={font.size.fs_5} lineHeight={font.size.fs_5} sx={{ color: theme.palette.text.ternary }}>{"Project"}</Typography> */}
                        <LightTooltip
                            title={activeProject?.description}
                            placement="right"
                        >
                            <Typography fontSize={font.size.fs_5} lineHeight={font.size.fs_5} className="flex-1 line-clamp-1" sx={{ color: theme.palette.text.ternary }}>{activeProject?.description}</Typography>
                        </LightTooltip>
                    </Box>

                </Box>

                <Box className=" min-w-10 w-fit max-w-10  h-full flex flex-col  items-center justify-center  px-0 py-0 gap-0 ">
                    <IconButton onClick={handleOpenMorePopup}>
                        <MoreVertOutlined sx={{ fontSize: font.size.fs_3 }} />
                    </IconButton>

                    {/* <IconButton color={!currentFolderData ? "secondary" : "default"} onClick={handleViewAllItems}>
                        <ListOutlined sx={{ fontSize: font.size.fs_3 }} />
                    </IconButton> */}
                </Box>
            </Box>




            {/* folder structure */}
            {(pathName === ModulePath.requirements || pathName === ModulePath.useCase || pathName === ModulePath.testCase) ?
                <Box className="flex-1 flex flex-col py-0 gap-0 " sx={{}} bgcolor={theme.palette.background.default} borderRadius={theme.shape.borderRadius}>

                    {/* folder actions  header */}
                    <Box className=" flex flex-col gap-4 py-2  shadow"
                    // bgcolor={!currentFolderData ? theme.palette.highlight.main : theme.palette.background.paper}
                    >

                        {/* generate module, view all */}
                        {false && <Box className="flex items-center flex-wrap  justify-between gap-2 px-4">
                            <Button color="ai" sx={{ color: currentAIEntityData?.entity === AI_Entity.folder ? theme.palette.ai.contrastText : theme.palette.ai.main }} variant={currentAIEntityData?.entity === AI_Entity.folder ? "contained" : "outlined"} startIcon={<AutoAwesomeOutlined />} onClick={handleToggleFolderAIWindow}>Generate {Entity_Label.folder}</Button>
                            <Button color={!currentFolderData ? "secondary" : "default"} variant={!currentFolderData ? "contained" : "contained"} sx={{ color: !currentFolderData && theme.palette.secondary.contrastText }} startIcon={<ListOutlined />} onClick={handleViewAllItems}>View All</Button>
                        </Box>}

                        {/* explorer */}
                        <Box className="flex items-center px-4 py-0 gap-4 cursor-pointer "  >
                            {/* <Typography fontSize={font.size.fs_5} fontWeight={600} sx={{ color: theme.palette.text.primary }} className="flex-1" onClick={handleClearSelectedFolder}> Explorer </Typography> */}

                            <Box className="flex-1 flex items-center justify-between flex-wrap gap-2 px-0 ">
                                {/* <Typography fontSize={font.size.fs_4} fontWeight={500} color={theme.palette.text.primary} className="flex-1">Directory</Typography> */}

                                <LightTooltip title={`Add Root-${Entity_Label.folder}`}>
                                    <IconButton color="ai" onClick={(e) => handleOpenAddModule(e, "root")}>
                                        <CreateNewFolderOutlined />
                                    </IconButton>
                                </LightTooltip>

                                <LightTooltip title={`Add Sub-${Entity_Label.folder}`}>
                                    <IconButton disabled={!currentFolderData} color="secondary" onClick={(e) => handleOpenAddModule(e)}>
                                        <CreateNewFolderOutlined />
                                    </IconButton>
                                </LightTooltip>

                                <LightTooltip title={`Edit ${Entity_Label.folder}`}>
                                    <IconButton disabled={!currentFolderData} onClick={handleOpenEditModule}>
                                        <EditOutlined />
                                    </IconButton>
                                </LightTooltip>

                                <LightTooltip title={`Move ${Entity_Label.folder}`}>
                                    <IconButton disabled={!currentFolderData}  >
                                        <DriveFileMoveOutlined />
                                    </IconButton>
                                </LightTooltip>

                                <LightTooltip title={`Delete ${Entity_Label.folder}`}>
                                    <IconButton disabled={!currentFolderData} onClick={handleOpenDeleteModule}>
                                        <FolderDeleteOutlined />
                                    </IconButton>
                                </LightTooltip>

                                <LightTooltip title={`Generate ${Entity_Label.folder}`}>
                                    <IconButton color="ai"
                                        sx={{
                                            background: currentAIEntityData?.entity === AI_Entity.folder && theme.palette.ai.main, color: currentAIEntityData?.entity === AI_Entity.folder && theme.palette.ai.contrastText,
                                            "&:hover": { background: currentAIEntityData?.entity === AI_Entity.folder && theme.palette.ai.dark, color: currentAIEntityData?.entity === AI_Entity.folder && theme.palette.ai.contrastText }
                                        }}
                                        onClick={handleToggleFolderAIWindow}>
                                        <AutoAwesomeOutlined />
                                    </IconButton>
                                </LightTooltip>

                                {false && <LightTooltip title={`Deselect ${Entity_Label.folder}`}>
                                    <IconButton
                                        sx={{
                                            background: currentFolderData && theme.palette.highlight.main, color: currentFolderData && theme.palette.highlight.contrastText,
                                            "&:hover": { background: currentFolderData && theme.palette.highlight.main, color: currentFolderData && theme.palette.highlight.contrastText, }
                                        }}
                                        onClick={handleClearSelectedFolder}>
                                        <FolderOffOutlined />
                                    </IconButton>
                                </LightTooltip>}

                            </Box>
                        </Box>

                        {false && <Box className="flex items-center justify-between gap-2 px-4">
                            {/* <Typography fontSize={font.size.fs_4} fontWeight={500} color={theme.palette.text.primary} className="flex-1">Directory</Typography> */}

                            <LightTooltip title={`Add ${Entity_Label.folder}`}>
                                <IconButton onClick={(e) => handleOpenAddModule(e, "root")}>
                                    <CreateNewFolderOutlined />
                                </IconButton>
                            </LightTooltip>

                            <LightTooltip title={`Edit ${Entity_Label.folder}`}>
                                <IconButton onClick={handleOpenEditModule}>
                                    <EditOutlined />
                                </IconButton>
                            </LightTooltip>

                            <LightTooltip title={`Move ${Entity_Label.folder}`}>
                                <IconButton >
                                    <DriveFileMoveOutlined />
                                </IconButton>
                            </LightTooltip>

                            <LightTooltip title={`Delete ${Entity_Label.folder}`}>
                                <IconButton onClick={handleOpenDeleteModule}>
                                    <FolderDeleteOutlined />
                                </IconButton>
                            </LightTooltip>

                            <LightTooltip title={`Deselect ${Entity_Label.folder}`}>
                                <IconButton sx={{ background: currentFolderData && theme.palette.highlight.main, color: currentFolderData && theme.palette.highlight.contrastText }} onClick={handleClearSelectedFolder}>
                                    <FolderOffOutlined />
                                </IconButton>
                            </LightTooltip>

                        </Box>}
                    </Box>

                    {/* folder list */}
                    <Box className=" flex flex-col py-4 w-full h-full ">

                        {
                            (loading) ?
                                <Box className=" flex flex-col items-center  gap-2 w-full h-full px-4 ">
                                    {/* <CircularProgress />
                                <Typography>Loading...</Typography> */}
                                    {
                                        Array.from({ length: 15 }).map((item, index) => <Skeleton key={index} variant="rounded" animation="pulse" width={"100%"} height={30} />)
                                    }
                                </Box>
                                :
                                (
                                    <Box className=" flex w-full h-full ">
                                        <Treeview dataList={folderStructureList} />
                                    </Box>
                                )
                        }

                    </Box>

                </Box>
                :
                <Box className="flex-1 flex flex-col px-4 py-4 gap-0 " sx={{}} bgcolor={theme.palette.background.default} borderRadius={theme.shape.borderRadius}>

                    <DashboardSidebar />

                </Box>}


            {/* user profile */}
            {false && <Box className="flex items-center justify-center gap-2 w-full py-3 px-4 border-t">
                <Avatar variant="rounded" sx={{ background: theme.palette.secondary.main, color: theme.palette.secondary.contrastText }}>
                    {profileDetails?.name?.charAt(0) || "-"}
                </Avatar>
                <Box className="flex-1 w-auto flex flex-col overflow-hidden">
                    <Typography fontSize={font.size.fs_4} className=" overflow-hidden text-ellipsis whitespace-nowrap box-border">{profileDetails?.name}</Typography>
                    <Typography fontSize={font.size.fs_5} className="overflow-hidden text-ellipsis whitespace-nowrap box-border opacity-60">{profileDetails?.role || "Admin"}</Typography>
                </Box>
                <IconButton onClick={() => navigate(ModulePath.settings)}>
                    <Settings />
                </IconButton>
                <IconButton onClick={handleLogout}>
                    <LogoutOutlined />
                </IconButton>
            </Box>}



            {openCreateFolderDialog &&
                <CreateModuleDialog
                    open={openCreateFolderDialog}
                    handleClose={() => setOpenCreateFolderDialog(false)}
                    handleConfirm={handleCreateModule}
                    loading={loading}
                    setLoading={setLoading}
                />}

            {openEditFolderDialog &&
                <EditModuleDialog
                    open={openEditFolderDialog}
                    handleClose={() => setOpenEditFolderDialog(false)}
                    handleConfirm={handleUpdateModule}
                    loading={loading}
                    setLoading={setLoading}
                />}

            {openDeleteFolderDialog &&
                <DeleteModuleDialog
                    open={openDeleteFolderDialog}
                    handleClose={() => setOpenDeleteFolderDialog(false)}
                    handleConfirm={handleDeleteModule}
                    loading={loading}
                    setLoading={setLoading}

                />}




            {/* current folder menu */}
            <Popover
                id="folder-menu"
                open={Boolean(currentFolderAnchorEl)}
                anchorEl={currentFolderAnchorEl}
                onClose={() => setCurrentFolderAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Box className="flex flex-col ">
                    {
                        <MenuItem
                            className="flex items-center gap-2"
                            onClick={handleOpenAddModule}>
                            <ListItemIcon>
                                <AddOutlined fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Add Folder</ListItemText>
                        </MenuItem>}

                    {
                        <MenuItem
                            className="flex items-center gap-2"
                            onClick={handleOpenEditModule}>
                            <ListItemIcon>
                                <EditOutlined fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Edit Folder</ListItemText>
                        </MenuItem>}

                    {
                        <MenuItem
                            className="flex items-center gap-2"
                            onClick={handleOpenDeleteModule}>
                            <ListItemIcon>
                                <DeleteOutline fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Delete Folder</ListItemText>
                        </MenuItem>}
                </Box>
            </Popover>

            {/* project menu */}
            <Menu
                anchorEl={moreAnchorEl}
                open={Boolean(moreAnchorEl)}
                // onClose={() => handleCloseMorePopup()}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}

            >
                <Box className="flex flex-col min-w-96 max-w-fit min-h-72 max-h-fit  ">
                    <Box className="flex items-center justify-between px-4 py-0 border-b">
                        <Typography fontSize={font.size.fs_4} color="textPrimary">Edit</Typography>
                        <IconButton onClick={handleCloseMorePopup}>
                            <Close />
                        </IconButton>
                    </Box>

                    <Box className="flex-1 flex flex-col px-4 py-4 gap-4 " >
                        <TextField required name="name" label="Project Name" sx={{ fontSize: font.size.fs_4 }} value={projectData?.name} onChange={handleChange} />
                        <TextField required name="description" label="Project Description" multiline minRows={4} sx={{ fontSize: font.size.fs_4 }} value={projectData?.description} onChange={handleChange} />

                        <Box className="flex flex-col gap-4">
                            <Box className="flex items-center justify-between gap-2 ">
                                {/* <Typography>Category</Typography> */}

                                <FormControl required fullWidth>
                                    <InputLabel id="demo-simple-select-label-category">Category</InputLabel>
                                    <Select
                                        // className="w-44 h-8"
                                        variant="outlined"
                                        name="categoryName"
                                        labelId='demo-simple-select-label-category'
                                        label="Category"
                                        value={projectData?.categoryName}
                                        displayEmpty
                                        renderValue={() => projectData?.categoryName}
                                        onChange={handleChange}
                                    >
                                        {
                                            categoryList?.map((item, index) => {
                                                return (
                                                    <MenuItem value={item} key={index}>
                                                        {item?.name}
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Box>

                            <Box className="flex items-center justify-between gap-2 ">
                                {/* <Typography>SubCategory</Typography> */}
                                <FormControl required fullWidth>
                                    <InputLabel id="demo-simple-select-label-subcategory">Subcategory</InputLabel>
                                    <Select
                                        // className="w-44 h-8"
                                        variant="outlined"
                                        name="subCategoryName"
                                        labelId='demo-simple-select-label-subcategory'
                                        label="Subcategory"
                                        value={projectData?.subCategoryName}
                                        displayEmpty
                                        renderValue={() => projectData?.subCategoryName}
                                        onChange={handleChange}
                                    >
                                        {
                                            subCategoryList?.map((item, index) => {
                                                return (
                                                    <MenuItem value={item} key={index}>
                                                        {item?.name}
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Box>

                            <TextField required name="useCasePrefix" label="Use Case Prefix" sx={{ fontSize: font.size.fs_4 }} value={projectData?.useCasePrefix} onChange={handleChange} />
                            <TextField required name="userStoryPrefix" label="Use Story Prefix" sx={{ fontSize: font.size.fs_4 }} value={projectData?.userStoryPrefix} onChange={handleChange} />
                            <TextField required name="testCasePrefix" label="Test Case Prefix" sx={{ fontSize: font.size.fs_4 }} value={projectData?.testCasePrefix} onChange={handleChange} />
                        </Box>
                    </Box>




                    <Box className="flex flex-col gap-4 px-4 py-4 border-t">
                        <Button variant="contained" color="secondary" sx={{ color: theme.palette.secondary.contrastText }} disabled={isLoading} onClick={() => handleUpdate()}>Update</Button>
                        <Button variant="outlined" disabled={isLoading || !projectData?.name || !projectData?.description} onClick={() => handleCloseMorePopup()}>Cancel</Button>
                    </Box>
                </Box>
            </Menu>
        </Box>
    )
}



function DashboardSidebar() {
    const theme = useTheme();
    const {
        activeDashboard,
        setActiveDashboard,
    } = useApplicationContext();

    // const [activeItem, setActiveItem] = useState(DashboardPages[0]);

    const handleClick = (item) => {
        setActiveDashboard(item)
    }

    useEffect(() => {
        setActiveDashboard(DashboardPages[0]);
    }, []);


    return (
        <Box className=" flex flex-col gap-2 w-full h-full ">
            {
                DashboardPages?.map((item, index) => {
                    return (
                        <Box
                            className="flex items-center gap-2 px-2 py-1 cursor-pointer"
                            borderRadius={theme.shape.borderRadius}
                            sx={{ background: activeDashboard?.value === item?.value ? theme.palette.highlight.main : "", "&:hover": { background: theme.palette.highlight.main } }}
                            onClick={() => handleClick(item)}
                        >
                            <Typography fontSize={font.size.fs_5} color={theme.palette.text.primary} > {item?.label} </Typography>
                        </Box>
                    )
                })
            }
        </Box>
    )
}