import { Add, ArrowBack, Attachment, AutoAwesomeTwoTone, Close, Create, Delete, Description, DragHandle, Edit, ExpandMore, Light, Save } from "@mui/icons-material"
import { Box, Button, Checkbox, CircularProgress, Dialog, Divider, FormControl, FormControlLabel, Icon, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Popover, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, ToggleButton, Typography, useTheme } from "@mui/material"
import { amber, blue, grey } from "@mui/material/colors";
import { forwardRef, useEffect, useState } from "react";
import { font } from "../../styles/font";
import { useApplicationContext } from "../../context/ApplicationContext";
import { FolderType, Severity } from "../../constant/ApplicationVariables";
import { getStoryStatusList_API } from "../../api/controller/StoryController";
import useErrorHandler from "../../api/errorHandler/useErrorHandler";
import { HttpStatusCode } from "axios";
import { CustomAccordionComponent, CustomAccordionComponentDetails, CustomAccordionComponentSummary, LightTooltip } from "../styled/CustomComponent";
import styled from "@emotion/styled";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { TableVirtuoso } from "react-virtuoso";
import { addUseCase_API, generateUseCaseCustomId_API, getUseCaseList_API } from "../../api/controller/UseCaseController";
import { addTestCase_API, generateTestCaseCustomId_API, getTestCaseList_API, getTestCaseStatusList_API } from "../../api/controller/TestCaseController";

// const StyledSelect = styled(Select)(({ theme }) => ({
//     height: '24px ',
//     '& .MuiOutlinedInput-root': {
//         height: '100%',
//         padding: '0 14px',
//     },
// }));

const QuillContainer = styled("div")(({ theme }) => ({
    ".ql-container": {
        borderRadius: " 0 0 0.6rem 0.6rem",
    },
    ".ql-toolbar": {
        borderRadius: "  0.6rem 0.6rem 0 0 "
    },
    ".ql-editor": {
        height: "200px"
    },
}));

const TableComponents = {
    Scroller: forwardRef((props, ref) => <TableContainer component={Paper} sx={{ overflowX: "auto", overflowY: "scroll", }} {...props} ref={ref} />),
    Table: (props) => <Table size="small" {...props} style={{ borderCollapse: 'separate' }} />,
    TableHead: TableHead,
    TableRow: (props) => <TableRow {...props} />,
    TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
    TableCell: (props) => <TableCell {...props} sx={{ ...props?.sx, px: "1rem !important", border: `1px solid black`, borderBottom: "1px solid black", borderRight: "1px solid black", }} />,
}

const StyledTextField = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderRadius: 0,
            border: "none"
            // borderColor: theme.palette.common.black
        },
        "&:hover fieldset": {
            borderRadius: 0,
            border: "none"
        },
        "&.Mui-focused fieldset": {
            borderRadius: 0,
            border: "none"
        },
    },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
    // height: '24px ',
    '& .MuiOutlinedInput-root': {
        height: '100%',
        padding: '0 14px',
        border: "none"
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: 0,
        border: "none"
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderRadius: 0,
        border: "none"
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderRadius: 0,
        border: "none"
    },
}));

class Field_Configuration {
    static fieldType = "Field Type";
    static fieldLength = "Field Length";
    static fieldValidation = "Field Validation";
    static elementId = "Element Id";
}

export default function CreateTestCaseComponent({ open, closeDialog, }) {
    const {
        currentUseCaseData, setCurrentUseCaseData,
        currentFolderData,
        handleSnackbar,
        activeProject,
        setUseCaseList,
        setIsUpdateFolderStructure,
        isUpdateProjectList, setIsUpdateProjectList
    } = useApplicationContext();
    const theme = useTheme();
    const errorHandler = useErrorHandler();

    const [loading, setLoading] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useState(false);

    const [currentStepText, setCurrentStepText] = useState("");
    const [currentStepIndex, setCurrentStepIndex] = useState(null);
    const [userData, setUserData] = useState(null);

    const [statusList, setStatusList] = useState([]);

    const [enableEdit, setEnableEdit] = useState(true);
    const [validationAnchorEl, setValidationAnchorEl] = useState(null);
    const [currentFieldConfiguration, setCurrentFieldConfiguration] = useState(null);



    const handleCloseFieldConfiguration = () => {
        setCurrentFieldConfiguration(null);
    }

    const handleDiscardClick = () => {
        if (userData?.title) {
            setOpenConfirmation(true)
        } else {
            closeDialog();
        }
    }

    const handleClickValidationPopup = (event,) => {
        if (validationAnchorEl) {
            setValidationAnchorEl(null);
        } else {
            setValidationAnchorEl(event.currentTarget);
        }
    }

    const handleChange = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    }

    const handleChangeFieldConfiguration = (fieldConfiguration) => {
        setCurrentFieldConfiguration(fieldConfiguration);
    }

    const handleAddAcceptanceCriteria = () => {
        if (currentStepIndex !== null) {
            let steps = [...userData?.steps];
            steps[currentStepIndex] = currentStepText;
            setUserData({ ...userData, steps: steps });
            setCurrentStepIndex(null);
        } else {
            if (userData?.steps) {
                setUserData({ ...userData, steps: [...userData?.steps, currentStepText] });
            } else {
                setUserData({ ...userData, steps: [currentStepText] });
            }
        }
        setCurrentStepText("");
    }

    const handleCurrentListItem = (stepIndex, stepText) => {
        console.log(stepText, stepIndex, "stepdata")
        // setCurrentStepText(stepText)
        // setCurrentStepIndex(stepIndex);

        let list = userData?.steps
        list[stepIndex] = stepText;
        setUserData({ ...userData, steps: list });
    }

    const handleRemoveListItem = (index) => {
        setUserData({ ...userData, steps: userData?.steps.filter((_, i) => i !== index) });
    }

    const handleDragStart = (index, e) => {
        e.dataTransfer.setData('text', index.toString());
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (index, e) => {
        const draggedIndex = parseInt(e.dataTransfer.getData('text'));
        const newSteps = [...userData.steps];
        const [removed] = newSteps.splice(draggedIndex, 1);
        newSteps.splice(index, 0, removed);
        setUserData({ ...userData, steps: newSteps });
    };

    const handleAddRowBelow = (index) => {
        let steps = userData?.steps ? [...userData?.steps] : [];

        if (steps?.length > 0 && steps[index] === "") {
            return;
        }
        if (steps?.length > 0) {
            steps.splice(index + 1, 0, "");
        } else {
            steps.push("");
        }

        setUserData({ ...userData, steps: steps });
    }

    const handleGetFieldConfigurationComponent = (fieldConfiguration) => {
        switch (fieldConfiguration) {
            case Field_Configuration.fieldType: return <FieldTypeComponent handleClose={handleCloseFieldConfiguration} />;
            case Field_Configuration.fieldLength: return <FieldLengthComponent handleClose={handleCloseFieldConfiguration} />;
            case Field_Configuration.fieldValidation: return <FieldValidationComponent handleClose={handleCloseFieldConfiguration} />;
            case Field_Configuration.elementId: return <FieldElementIdComponent handleClose={handleCloseFieldConfiguration} />;
            default: return null;
        }
    }

    const handleCheckValues = () => {
        let flag = true;

        if (!userData?.customId) flag = false;
        if (!userData?.title) flag = false;

        return flag;
    }

    const handleContinueStory = () => {
        let body = {
            "customId": userData?.customId,
            // "newCustomId": userData?.customId,
            "title": userData?.title,
            // "description": userData?.description,
            // "priority": "Medium",
            // "assignedToEmail": userData?.assignedToEmail,
            "folderPath": currentFolderData?.path,
            "projectName": activeProject?.name,
            "steps": userData?.steps ? userData?.steps?.map((step) => ({
                step: step,
                expectedResult: ""
            })) : [],
            // "status": userData?.status,


            // "expectedResult" : userData?.expectedResult,
            // "actualResult": userData?.actualResult,
            // "type" : userData?.type,
            // "priority" : userData?.priority,
            // "category" : userData?.category,
            // "status" : userData?.status,
            // "environment" : userData?.environment,
            // "isRegression" : userData?.isRegression,
            // "preCondition" : userData?.preCondition,
            // "postCondition" : userData?.postCondition,
        }

        console.log(body, "body in create")


        // handleContinue(body);
        handleCreateTestCase(body);
    }

    const handleGetStatusList = async () => {
        try {


            const response = await getTestCaseStatusList_API();
            console.log(response, "response handleGetUserStoryList")

            if (response) {
                errorHandler(response);
            }

            if (response.status === HttpStatusCode.Ok) {
                setStatusList(response.data);
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {

            }, 1000);
        }
    }

    const handleGenerateCustomId = async () => {
        try {


            let body = {
                "folderPath": currentFolderData?.path,
                "projectName": activeProject?.name,
            }

            const response = await generateTestCaseCustomId_API(body);
            console.log(response, "response handleGetUserStoryList")

            if (response) {
                errorHandler(response);
            }

            if (response.status === HttpStatusCode.Ok) {

                setUserData({ ...userData, customId: response.data });
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {

            }, 1000);
        }
    }

    const handleGetTestCaseList = async () => {
        try {
            setLoading(true);

            let body = {
                "folderPath": currentFolderData?.path,
                "projectName": activeProject?.name,
            }

            const response = await getTestCaseList_API(body);
            console.log(response, "response handleGetTestCaseList")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                setUseCaseList(response.data);
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const handleCreateTestCase = async (storyData) => {
        try {
            setLoading(true);

            let body = {
                ...storyData,
                // "priority": "High",
                // "moduleName": currentFolderData?.name,
                // "projectName": activeProject?.name,
            }

            const bodyFormData = new FormData();

            // Object.keys(body).forEach(key => bodyFormData.append(key, body[key]));
            Object.keys(body).forEach(key => {
                if (Array.isArray(body[key])) {
                    if (key === "attachments") {
                        body[key]?.forEach((file, index) => {
                            bodyFormData.append(`${key}[${index}]`, file, file.name);
                        })
                    } else if (key === "linkUseCases" || key === "linkUserStories") {
                        body[key]?.forEach((data, index) => {
                            bodyFormData.append(`${key}[${index}].${"folderPath"}`, data?.folderPath);
                            data?.customIds?.forEach((cId, cIndex) => {
                                return bodyFormData.append(`${key}[${index}].${"customIds"}[${cIndex}]`, cId);
                            })
                        })
                    } else if (key === "steps") {
                        body[key]?.forEach((data, index) => {
                            bodyFormData.append(`${key}[${index}].${"step"}`, data?.step);
                            bodyFormData.append(`${key}[${index}].${"expectedResult"}`, data?.expectedResult);
                        })
                    } else {
                        bodyFormData.append(key, body[key]);
                    }

                    // bodyFormData.append(key, body[key]);
                } else {
                    bodyFormData.append(key, body[key]);
                }
            });


            const response = await addTestCase_API(bodyFormData);
            console.log(response, "response handleCreateStory")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                handleGetTestCaseList();
                setIsUpdateFolderStructure(true);
                setIsUpdateProjectList(true);
                closeDialog();
            }


            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }



    useEffect(() => {
        // handleGetStatusList();
        setTimeout(() => {
            handleGenerateCustomId();
        }, 1000)
    }, []);


    // useEffect(() => {
    //     setUserData(currentUseCaseData);
    //     setEditorValue(currentUseCaseData?.description)
    // }, [currentUseCaseData]);



    console.log(userData, "userData", userData?.name, userData?.status);



    return (
        <Box className="flex flex-col w-full h-full z-20 absolute left-0 top-0 overflow-hidden "
            // bgcolor={theme.palette.primary.layout_col}
            borderRadius={theme.shape.borderRadius}
            bgcolor={theme.palette.common.white}
        >

            {loading && <Box className="flex-1 flex flex-col items-center justify-center backdrop-blur-sm absolute left-0 top-0 right-0 bottom-0 z-10">
                <CircularProgress />
            </Box>}

            <Box className={`flex flex-col px-4 py-4 ${enableEdit ? "gap-8" : "gap-2"} w-full h-full relative rounded-lg overflow-hidden`} >



                {/* top */}
                <Box className="w-full h-[7%] flex items-center justify-between gap-4 ">
                    {/* left */}
                    <Box className="flex-1 flex items-center gap-2 w-full ">
                        {/* <ToggleButton
                            size="small"
                            value="check"
                            // selected={enableEdit}
                            onChange={() => setEnableEdit((prevSelected) => !prevSelected)}
                            onClick={closeDialog}
                        >
                            <ArrowBack />
                        </ToggleButton> */}

                        {/* custom Id */}
                        <SectionContainer >
                            <HeadingComponent heading="Custom ID" hideColon />
                            <TextField
                                size="small"
                                variant="outlined"
                                name="customId"
                                // label="Custom ID"
                                required
                                fullWidth

                                // focused={userData?.customId}
                                // autoFocus
                                value={userData?.customId}
                                onChange={handleChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <LightTooltip title="Generate Custom ID">
                                                <IconButton disabled={userData?.customId} onClick={handleGenerateCustomId}>
                                                    <AutoAwesomeTwoTone color={userData?.customId ? theme.palette.primary.light : theme.palette.primary.main} />
                                                </IconButton>
                                            </LightTooltip>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </SectionContainer>

                    </Box>

                    {/* right */}
                    <Box className="w-[32%] flex items-center justify-end gap-2">

                        <Button variant="outlined" onClick={handleDiscardClick}>Discard</Button>
                        <Button variant="contained" color="secondary" disabled={!handleCheckValues()} onClick={() => handleContinueStory(userData)}>Save</Button>


                        {/* <ToggleButton
                            size="small"
                            value="check"
                            selected={false}
                            // disabled={!enableEdit || currentFolderData?.type !== FolderType.module || !handleCheckValues()}
                            disabled={!handleCheckValues()}
                            onClick={() => handleContinueStory(userData)}
                        >
                            <Save />
                        </ToggleButton> */}

                        {/* <IconButton
                                disabled={currentFolderData?.type !== FolderType.module || !handleCheckValues()}
                                onClick={() => handleContinueStory(userData)}
                            >
                                <Save />
                            </IconButton> */}

                        {/* <ToggleButton
                            size="small"
                            value="check"
                            selected={enableEdit}
                            onChange={() => setEnableEdit((prevSelected) => !prevSelected)}
                            onClick={closeDialog}
                        >
                            <Close />
                        </ToggleButton> */}
                    </Box>
                </Box>

                {/* bottom */}
                <Box className="w-full h-[93%] py-1 flex-1 flex gap-0 overflow-y-auto">
                    {/* left */}
                    <Box className={`flex-1 flex flex-col pr-4 py-1  ${enableEdit ? "gap-8" : "gap-6"} sticky top-10 overflow-y-auto`}>
                        <SectionContainer >
                            <HeadingComponent heading="Title" hideColon />
                            <TextField
                                size="small"
                                variant="outlined"
                                name="title"
                                required
                                placeholder="Title"
                                // autoFocus
                                fullWidth
                                multiline
                                minRows={1}
                                maxRows={2}
                                // focused={userData?.title}
                                value={userData?.title}
                                onChange={handleChange}
                            />
                        </SectionContainer>


                        <SectionContainer >
                            <HeadingComponent heading="Steps" hideColon />

                            <Box className={`flex flex-col gap-2 overflow-y-auto ${userData?.steps?.length > 0 ? "h-48" : ""}`}>
                                {userData?.steps?.length > 0 ?
                                    userData?.steps?.map((currentStep, index) => (
                                        enableEdit ? (
                                            <Box
                                                key={index}
                                                className="flex items-center gap-1 relative"
                                            >
                                                <LightTooltip title="Add Step below" >
                                                    <IconButton
                                                        onClick={() => handleAddRowBelow(index)}
                                                        sx={{ position: "relative", top: "6px" }}
                                                    >
                                                        <Add sx={{ fontSize: font.size.fs_5, color: blue[500] }} />
                                                    </IconButton>
                                                </LightTooltip>

                                                <Typography fontSize={font.size.fs_5} className="">{index + 1}.</Typography>

                                                <Box
                                                    className="flex items-center gap-2 hover:cursor-grab border rounded-lg w-full"
                                                    draggable
                                                    onDragStart={(e) => handleDragStart(index, e)}
                                                    onDragOver={(e) => handleDragOver(e)}
                                                    onDrop={(e) => handleDrop(index, e)}
                                                >
                                                    <IconButton>
                                                        <DragHandle />
                                                    </IconButton>

                                                    <TextField
                                                        fullWidth
                                                        size="small"
                                                        placeholder="Step description"
                                                        value={currentStep}
                                                        onChange={(e) => handleCurrentListItem(index, e.target.value)}
                                                        sx={{
                                                            px: 0,
                                                            "& .MuiOutlinedInput-root": {
                                                                fontSize: font.size.fs_4,
                                                                "& fieldset": {
                                                                    borderRadius: 0,
                                                                    border: "none"
                                                                    // borderColor: theme.palette.common.black
                                                                },
                                                                "&:hover fieldset": {
                                                                    borderRadius: 0,
                                                                    border: "none"
                                                                },
                                                                "&.Mui-focused fieldset": {
                                                                    borderRadius: 0,
                                                                    border: "none"
                                                                },
                                                            },
                                                        }}
                                                    />
                                                    <IconButton color="error" onClick={() => handleRemoveListItem(index)}><Delete /></IconButton>
                                                </Box>
                                            </Box>
                                        ) : (
                                            <Box key={index} className="flex items-center gap-2 " >
                                                <Typography fontSize={font.size.fs_5} className="">{index + 1}.</Typography>
                                                <Typography fontSize={font.size.fs_5} className="flex-1">{currentStep}</Typography>
                                            </Box>
                                        )
                                    ))
                                    :
                                    <Box className="flex  items-center justify-center   bg-gray-100 " borderRadius={theme.shape.borderRadius} >
                                        <Typography>Add</Typography>
                                        <IconButton onClick={() => handleAddRowBelow(0)}>
                                            <Add />
                                        </IconButton>
                                    </Box>}
                            </Box>
                        </SectionContainer>
                    </Box>

                    {/* {!enableEdit && <Divider orientation="vertical" flexItem />} */}

                    {/* right */}
                    {true && <Box className={`w-[32%] h-full flex flex-col  px-4 py-1  box-border relative  ${enableEdit ? "gap-8 " : "gap-6 "} overflow-y-auto `}>


                        {/* properties container */}
                        {!currentFieldConfiguration ? <Box className={`h-full flex flex-col border-l-2 ${enableEdit ? "gap-6" : "gap-6"} `} sx={{ pl: 2 }}>

                            {false && (!enableEdit ?
                                <Box className="flex flex-col gap-0">
                                    <HeadingComponent heading="Linked UserStory" />
                                    <Typography fontSize={font.size.fs_4} className="" > {"US_001 - Login functionality" || "None"} </Typography>
                                </Box>
                                : <KeyValuePairComponent
                                    heading={"Status"}
                                    value={
                                        <StyledSelect
                                            size="small"
                                            variant="outlined"
                                            name="status"
                                            fullWidth
                                            disabled={!userData?.status}
                                            value={userData?.status || ''}
                                            onChange={handleChange}
                                        >
                                            {
                                                statusList?.map((status, index) => (
                                                    <MenuItem key={index} value={status}>{status}</MenuItem>
                                                ))
                                            }
                                        </StyledSelect>
                                    }
                                />)}


                        </Box>
                            :
                            <Box className="absolute top-0 left-0 w-full h-full  border-l-2 " sx={{ pl: 2 }}>
                                {handleGetFieldConfigurationComponent(currentFieldConfiguration)}
                            </Box>}




                    </Box>}
                </Box>
            </Box>


            {/* confirmation dialog */}
            <Dialog open={openConfirmation} onClose={() => setOpenConfirmation(false)}>
                <Box className="flex flex-col gap-4 px-4 py-4">
                    <Box className="flex flex-col">
                        <Typography fontSize={font.size.fs_3}>
                            Are you sure
                        </Typography>
                        <Typography fontSize={font.size.fs_4}>
                            You want to discard changes?
                        </Typography>
                    </Box>
                    <Box className="flex items-center gap-4">
                        <Button fullWidth onClick={() => setOpenConfirmation(false)}>
                            Cancel
                        </Button>
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            onClick={closeDialog}
                        >
                            Continue
                        </Button>
                    </Box>
                </Box>
            </Dialog>
        </Box>
    )

}

function KeyValuePairComponent({ heading, value }) {
    return (
        <Box className="flex items-center ">
            <Box className="flex w-[40%]">
                <HeadingComponent heading={heading} />
            </Box>
            <Box className="flex items-center flex-1 ">
                {value}
            </Box>
        </Box>
    )
}


function KeyValueHeadingComponent({ heading }) {
    return (
        <Typography fontSize={font.size.fs_5} fontWeight={600} className="opacity-60">{heading}</Typography>
    )
}

function KeyValueValueComponent({ value }) {
    return (
        <Typography fontSize={font.size.fs_4}>{value}</Typography>
    )
}

function HeadingComponent(props) {
    return (
        <Typography fontSize={font.size.fs_5} fontWeight={600} className={`${props.className} opacity-100`} {...props}>{props.heading} {props.hideColon ? "" : ":"}</Typography>
    )
}

function ValueComponent(props) {
    return (
        <Typography fontSize={font.size.fs_4} className={`${props.className} opacity-100`} {...props}> {props.value ? props.value : "None"} </Typography>
    )
}

function CheckBoxContainerComponent({ children }) {
    return (
        <Box className="flex justify-center gap-2 ">
            {children}
        </Box>
    )
}

function KeyValueComponent({ heading, value }) {
    return (
        <Box className="flex items-center justify-between gap-4 ">
            <Typography variant="body2" className="flex-1 ">{heading}</Typography>
            <Typography className=" flex  ">{value}</Typography>
        </Box>
    );
}

function FieldTypeComponent({ handleClose }) {
    const theme = useTheme();

    const [userFieldsList, setUserFieldsList] = useState([

        {
            step: "Navigate to the login page",
            fieldName: "username",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Click on the login button",
            fieldName: "password",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Enter the username",
            fieldName: "email",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Enter the password",
            fieldName: "phone",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Click on the continue button",
            fieldName: "telephone",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        }

    ]);

    const columns = [
        {
            data: "S.No.",
            padding: "none",
            px: "0.4rem",
            align: "left",
            background: theme.palette.secondary.main,
            width: "40px",
            children: []
        },
        {
            data: "Field Name",
            padding: "none",
            px: "1rem",
            align: "left",
            background: theme.palette.secondary.main,
            width: "165px",
            children: []
        },
        {
            data: "Field Type",
            padding: "none",
            px: "1rem",
            align: "left",
            background: theme.palette.secondary.main,
            width: "120px",
            children: []
        },
    ];


    return (
        <Box className="flex flex-col w-full h-full gap-2 flex-1 p-0.5 overflow-hidden">
            <Box className="flex items-center gap-2">
                <Typography className="flex-1 ">Field Type</Typography>
                <IconButton onClick={handleClose}>
                    <Close />
                </IconButton>
            </Box>
            <TableVirtuoso
                style={{ height: "100%", width: "100%", overflowX: 'auto', borderRadius: theme.shape.borderRadius }}
                data={userFieldsList}
                components={TableComponents}
                fixedHeaderContent={() => (
                    <TableHead>
                        {
                            columns?.map((item, index) => (
                                <TableCell key={index} align={item?.align} padding={item?.padding} className="whitespace-nowrap" style={{ py: "0.4rem", margin: "0 1rem !important", fontWeight: "bold", minWidth: item?.width, maxWidth: item?.width, background: item?.background, borderRight: index !== columns?.length - 1 && `1px solid ${grey[300]}` }}>
                                    {item?.children?.length === 0 ? <Box className="py-2 flex items-center gap-2 px-1" sx={{ px: item?.px }}>
                                        <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap"> {item?.data}</Typography>
                                    </Box> :
                                        <Box className=" flex flex-col items-center  gap-0 ">
                                            <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap"> {item?.data}</Typography>
                                            <Box className="flex-1 w-full flex   gap-0  " sx={{ borderTop: `1px solid ${grey[300]}` }}>
                                                {
                                                    item?.children?.map((subItem, index) => (
                                                        <Box className="flex-1 flex items-center justify-center gap-2" sx={{ borderRight: index !== item?.children?.length - 1 && `1px solid ${grey[300]}` }}>
                                                            <Typography fontSize={font.size.fs_6} fontWeight="bold" className="whitespace-nowrap"> {subItem?.data}</Typography>
                                                        </Box>

                                                    ))
                                                }
                                            </Box>
                                        </Box>}
                                </TableCell>
                            ))
                        }
                    </TableHead>
                )}


                itemContent={(index, data) => (
                    <TableRow sx={{ "&:hover": { background: grey[100], } }}>

                        <TableCell padding="none" align="left" sx={{ minWidth: "40px", maxWidth: "40px", cursor: "pointer", borderRight: `1px solid ${grey[300]}` }}>
                            <Box className="flex items-center justify-center ">
                                <Typography>{index + 1}</Typography>
                            </Box>
                        </TableCell>

                        <TableCell padding="none" align="left" sx={{ minWidth: "165px", maxWidth: "165px", cursor: "pointer", borderRight: `1px solid ${grey[300]}` }}>
                            <Box className="flex items-center  px-4 ">
                                <Typography fontSize={font.size.fs_4}>{data?.fieldName}</Typography>
                            </Box>
                        </TableCell>

                        <TableCell padding="none" align="left" sx={{ minWidth: "120px", maxWidth: "120px", cursor: "pointer", borderRight: `1px solid ${grey[300]}` }}>
                            <StyledSelect
                                fullWidth
                                size="small"
                                variant="outlined"
                                name="fieldType"
                                value={"Textfield"}
                            // value={data?.fieldType}
                            // onChange={(e) => handleChange(e, index)}
                            >
                                {
                                    ["Textfield", "Dropdown", "Checkbox", "Radio", "Textarea", "File", "Link", "Image",]?.map((status, index) => (
                                        <MenuItem key={index} value={status}>{status}</MenuItem>
                                    ))

                                }
                            </StyledSelect>

                        </TableCell>

                    </TableRow>

                )}
            />
        </Box>
    )
}

function FieldLengthComponent({ handleClose }) {
    const theme = useTheme();

    const [userFieldsList, setUserFieldsList] = useState([

        {
            step: "Navigate to the login page",
            fieldName: "username",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Click on the login button",
            fieldName: "password",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Enter the username",
            fieldName: "email",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Enter the password",
            fieldName: "phone",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Click on the continue button",
            fieldName: "telephone",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        }

    ]);

    const columns = [
        {
            data: "S.No.",
            padding: "none",
            px: "0.4rem",
            align: "left",
            background: theme.palette.secondary.main,
            width: "40px",
            children: []
        },
        {
            data: "Field Name",
            padding: "none",
            px: "1rem",
            align: "left",
            background: theme.palette.secondary.main,
            width: "154px",
            children: []
        },
        {
            data: "Field Length",
            padding: "none",
            px: "1rem",
            align: "left",
            background: theme.palette.secondary.main,
            width: "130px",
            children: [
                {
                    data: "Min",
                    padding: "none",
                    align: "left",
                    background: theme.palette.secondary.main,
                    width: "100px"

                },
                {
                    data: "Max",
                    padding: "none",
                    align: "left",
                    background: theme.palette.secondary.main,
                    width: "100px",

                }
            ]
        },
    ];


    return (
        <Box className="flex flex-col w-full h-full flex-1 p-0.5 overflow-hidden">
            <Box className="flex items-center gap-2">
                <Typography className="flex-1 ">Field Length</Typography>
                <IconButton onClick={handleClose}>
                    <Close />
                </IconButton>
            </Box>
            <TableVirtuoso
                style={{ height: "100%", width: "100%", overflowX: 'auto', borderRadius: theme.shape.borderRadius }}
                data={userFieldsList}
                components={TableComponents}
                fixedHeaderContent={() => (
                    <TableHead>
                        {
                            columns?.map((item, index) => (
                                <TableCell key={index} align={item?.align} padding={item?.padding} className="whitespace-nowrap" style={{ py: "0.4rem", margin: "0 1rem !important", fontWeight: "bold", minWidth: item?.width, maxWidth: item?.width, background: item?.background, borderRight: index !== columns?.length - 1 && `1px solid ${grey[300]}` }}>
                                    {item?.children?.length === 0 ? <Box className="py-2 flex items-center gap-2 " sx={{ px: item?.px }}>
                                        <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap"> {item?.data}</Typography>
                                    </Box> :
                                        <Box className=" flex flex-col gap-0 ">
                                            {/* <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap"> {item?.data}</Typography> */}
                                            <Box className="flex-1 w-full h-full flex   gap-0  " >
                                                {
                                                    item?.children?.map((subItem, index) => (
                                                        <Box className="flex-1 h-full flex items-center justify-center gap-2" sx={{ borderRight: index !== item?.children?.length - 1 && `1px solid ${grey[300]}` }}>
                                                            <Typography fontSize={font.size.fs_6} fontWeight="bold" className="whitespace-nowrap"> {subItem?.data}</Typography>
                                                        </Box>

                                                    ))
                                                }
                                            </Box>
                                        </Box>}
                                </TableCell>
                            ))
                        }
                    </TableHead>
                )}


                itemContent={(index, data) => (
                    <TableRow sx={{ "&:hover": { background: grey[100], } }}>

                        <TableCell padding="none" align="left" sx={{ minWidth: "40px", maxWidth: "40px", cursor: "pointer", borderRight: `1px solid ${grey[300]}` }}>
                            <Box className="flex items-center justify-center ">
                                <Typography>{index + 1}</Typography>
                            </Box>
                        </TableCell>

                        <TableCell padding="none" align="left" sx={{ minWidth: "154px", maxWidth: "154px", cursor: "pointer", borderRight: `1px solid ${grey[300]}` }}>
                            <Box className="flex items-center  px-4 ">
                                <Typography fontSize={font.size.fs_4}>{data?.fieldName}</Typography>
                            </Box>
                        </TableCell>

                        <TableCell padding="none" align="left" sx={{ minWidth: "130px", maxWidth: "130px", cursor: "pointer", borderRight: `1px solid ${grey[300]}` }}>
                            <Box className="flex items-center gap-0 px-1 ">
                                <StyledTextField
                                    // label="Min Length"
                                    type="number"
                                    name="minLength"
                                // defaultValue={data?.fieldValidation?.minLength}
                                // onBlur={(e) => handleChange(e, index)}
                                />
                                <Divider orientation="vertical" flexItem />
                                <StyledTextField
                                    // label="Max Length"
                                    type="number"
                                    name="maxLength"
                                // defaultValue={data?.fieldValidation?.maxLength}
                                // onBlur={(e) => handleChange(e, index)}
                                />
                            </Box>
                        </TableCell>

                    </TableRow>

                )}
            />
        </Box>
    )
}

function FieldValidationComponent({ handleClose }) {
    const theme = useTheme();

    const [validationAnchorEl, setValidationAnchorEl] = useState(null);

    const [userFieldsList, setUserFieldsList] = useState([

        {
            step: "Navigate to the login page",
            fieldName: "username",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Click on the login button",
            fieldName: "password",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Enter the username",
            fieldName: "email",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Enter the password",
            fieldName: "phone",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Click on the continue button",
            fieldName: "telephone",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        }

    ]);

    const columns = [
        {
            data: "S.No.",
            padding: "none",
            px: "0.4rem",
            align: "left",
            background: theme.palette.secondary.main,
            width: "40px",
            children: []
        },
        {
            data: "Field Name",
            padding: "none",
            px: "1rem",
            align: "left",
            background: theme.palette.secondary.main,
            width: "154px",
            children: []
        },
        {
            data: "Validations",
            padding: "none",
            px: "1rem",
            align: "center",
            background: theme.palette.secondary.main,
            width: "130px",
            children: []
        },
    ];

    const handleClickValidationPopup = (event,) => {
        if (validationAnchorEl) {
            setValidationAnchorEl(null);
        } else {
            setValidationAnchorEl(event.currentTarget);
        }
    }


    return (
        <Box className="flex flex-col w-full h-full flex-1 p-0.5 overflow-hidden">
            <Box className="flex items-center gap-2">
                <Typography className="flex-1 ">Field Validation</Typography>
                <IconButton onClick={handleClose}>
                    <Close />
                </IconButton>
            </Box>
            <TableVirtuoso
                style={{ height: "100%", width: "100%", overflowX: 'auto', borderRadius: theme.shape.borderRadius }}
                data={userFieldsList}
                components={TableComponents}
                fixedHeaderContent={() => (
                    <TableHead>
                        {
                            columns?.map((item, index) => (
                                <TableCell key={index} align={item?.align} padding={item?.padding} className="whitespace-nowrap" style={{ py: "0.4rem", margin: "0 1rem !important", fontWeight: "bold", minWidth: item?.width, maxWidth: item?.width, background: item?.background, borderRight: `1px solid ${grey[300]}` }}>
                                    {item?.children?.length === 0 ? <Box className="py-2 flex items-center gap-2 " sx={{ px: item?.px }}>
                                        <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap"> {item?.data}</Typography>
                                    </Box> :
                                        <Box className=" flex flex-col items-center  gap-0 ">
                                            <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap"> {item?.data}</Typography>
                                            <Box className="flex-1 w-full flex   gap-0  " sx={{ borderTop: `1px solid ${grey[300]}` }}>
                                                {
                                                    item?.children?.map((subItem, index) => (
                                                        <Box className="flex-1 flex items-center justify-center gap-2" sx={{ borderRight: index !== item?.children?.length - 1 && `1px solid ${grey[300]}` }}>
                                                            <Typography fontSize={font.size.fs_6} fontWeight="bold" className="whitespace-nowrap"> {subItem?.data}</Typography>
                                                        </Box>

                                                    ))
                                                }
                                            </Box>
                                        </Box>}
                                </TableCell>
                            ))
                        }
                    </TableHead>
                )}


                itemContent={(index, data) => (
                    <TableRow sx={{ "&:hover": { background: grey[100], } }}>

                        <TableCell padding="none" align="left" sx={{ minWidth: "40px", maxWidth: "40px", cursor: "pointer", borderRight: `1px solid ${grey[300]}` }}>
                            <Box className="flex items-center justify-center ">
                                <Typography>{index + 1}</Typography>
                            </Box>
                        </TableCell>

                        <TableCell padding="none" align="left" sx={{ minWidth: "154px", maxWidth: "154px", cursor: "pointer", borderRight: `1px solid ${grey[300]}` }}>
                            <Box className="flex items-center  px-4 ">
                                <Typography fontSize={font.size.fs_4}>{data?.fieldName}</Typography>
                            </Box>
                        </TableCell>

                        <TableCell padding="none" align="left" sx={{ minWidth: "130px", maxWidth: "130px", cursor: "pointer", borderRight: `1px solid ${grey[300]}` }}>
                            <Box className="flex items-center gap-2 px-4">


                                <Button fullWidth onClick={handleClickValidationPopup}>
                                    <Add />
                                </Button>

                                <Popover
                                    id="validation-popover"
                                    open={Boolean(validationAnchorEl)}
                                    anchorEl={validationAnchorEl}
                                    onClose={handleClickValidationPopup}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{

                                    }}
                                >
                                    <Box className="flex-1 flex flex-col gap-4 px-4">
                                        <Box className="flex items-center justify-between gap-4 py-2 border-b">
                                            <Typography>Field Validation</Typography>
                                            <IconButton onClick={handleClickValidationPopup}>
                                                <Close />
                                            </IconButton>
                                        </Box>

                                        <Box className="flex-1 flex flex-col gap-4 p-2">
                                            <KeyValueComponent
                                                heading={`Uppercase`}
                                                value={
                                                    <FormControlLabel
                                                        // label="Uppercase"
                                                        // labelPlacement="bottom"
                                                        className="w-fit"
                                                        control={
                                                            <Checkbox

                                                            // defaultValue={data?.fieldValidation?.uppercase}
                                                            // onBlur={(e) => handleChange(e, index)}
                                                            />
                                                        }
                                                    />
                                                }
                                            />

                                            <KeyValueComponent
                                                heading={`Lowercase`}
                                                value={
                                                    <FormControlLabel
                                                        // label="Uppercase"
                                                        // labelPlacement="bottom"
                                                        className="w-fit"
                                                        control={
                                                            <Checkbox

                                                            // defaultValue={data?.fieldValidation?.uppercase}
                                                            // onBlur={(e) => handleChange(e, index)}
                                                            />
                                                        }
                                                    />
                                                }
                                            />
                                            <KeyValueComponent
                                                heading={`Whitespace`}
                                                value={
                                                    <FormControlLabel
                                                        // label="Uppercase"
                                                        // labelPlacement="bottom"
                                                        className="w-fit"
                                                        control={
                                                            <Checkbox

                                                            // defaultValue={data?.fieldValidation?.uppercase}
                                                            // onBlur={(e) => handleChange(e, index)}
                                                            />
                                                        }
                                                    />
                                                }
                                            />
                                            <KeyValueComponent
                                                heading={`Special Character`}
                                                value={
                                                    <FormControlLabel
                                                        // label="Uppercase"
                                                        // labelPlacement="bottom"
                                                        className="w-fit"
                                                        control={
                                                            <Checkbox

                                                            // defaultValue={data?.fieldValidation?.uppercase}
                                                            // onBlur={(e) => handleChange(e, index)}
                                                            />
                                                        }
                                                    />
                                                }
                                            />
                                            <KeyValueComponent
                                                heading={`Required`}
                                                value={
                                                    <FormControlLabel
                                                        // label="Uppercase"
                                                        // labelPlacement="bottom"
                                                        className="w-fit"
                                                        control={
                                                            <Checkbox

                                                            // defaultValue={data?.fieldValidation?.uppercase}
                                                            // onBlur={(e) => handleChange(e, index)}
                                                            />
                                                        }
                                                    />
                                                }
                                            />

                                            <KeyValueComponent
                                                heading={`Numeric`}
                                                value={
                                                    <FormControlLabel
                                                        // label="Uppercase"
                                                        // labelPlacement="bottom"
                                                        className="w-fit"
                                                        control={
                                                            <Checkbox

                                                            // defaultValue={data?.fieldValidation?.uppercase}
                                                            // onBlur={(e) => handleChange(e, index)}
                                                            />
                                                        }
                                                    />
                                                }
                                            />


                                        </Box>
                                    </Box>
                                </Popover>

                            </Box>
                        </TableCell>

                    </TableRow>

                )}
            />
        </Box>
    )
}

function FieldElementIdComponent({ handleClose }) {
    const theme = useTheme();

    const [userFieldsList, setUserFieldsList] = useState([

        {
            step: "Navigate to the login page",
            fieldName: "username",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Click on the login button",
            fieldName: "password",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Enter the username",
            fieldName: "email",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Enter the password",
            fieldName: "phone",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        },
        {
            step: "Click on the continue button",
            fieldName: "telephone",
            fieldType: "",
            fieldValidation: {
                required: true,
                minLength: 6,
                maxLength: 20,
                pattern: "",
                uppercase: true,
                lowercase: true,
                numeric: true,
                specialChar: true,
                whitespace: true,
                email: true,
                phone: true,
                date: true,
                time: true,
                datetime: true,
                url: true,
                password: true,
            },
            elementId: {
                xpath: "",
                cssSelector: "",
                id: ""
            }
        }

    ]);

    const columns = [
        {
            data: "S.No.",
            padding: "none",
            px: "0.4rem",
            align: "left",
            background: theme.palette.secondary.main,
            width: "40px",
            children: []
        },
        {
            data: "Field Name",
            padding: "none",
            px: "1rem",
            align: "left",
            background: theme.palette.secondary.main,
            width: "154px",
            children: []
        },
        {
            data: "Element Id",
            padding: "none",
            px: "1rem",
            align: "left",
            background: theme.palette.secondary.main,
            width: "130px",
            children: [
                {
                    data: "Xpath",
                    padding: "none",
                    align: "left",
                    background: theme.palette.secondary.main,
                    width: "200px"
                },
                {
                    data: "CSS",
                    padding: "none",
                    align: "left",
                    background: theme.palette.secondary.main,
                    width: "200px"
                },
                {
                    data: "ID",
                    padding: "none",
                    align: "left",
                    background: theme.palette.secondary.main,
                    width: "200px"
                }
            ]
        },
    ];


    return (
        <Box className="flex flex-col w-full h-full flex-1 p-0.5 overflow-hidden">
            <Box className="flex items-center gap-2">
                <Typography className="flex-1 ">Element Id</Typography>
                <IconButton onClick={handleClose}>
                    <Close />
                </IconButton>
            </Box>
            <TableVirtuoso
                style={{ height: "100%", width: "100%", overflowX: 'auto', borderRadius: theme.shape.borderRadius }}
                data={userFieldsList}
                components={TableComponents}
                fixedHeaderContent={() => (
                    <TableHead>
                        {
                            columns?.map((item, index) => (
                                <TableCell key={index} align={item?.align} padding={item?.padding} className="whitespace-nowrap" style={{ py: "0.4rem", margin: "0 1rem !important", fontWeight: "bold", minWidth: item?.width, maxWidth: item?.width, background: item?.background, borderRight: `1px solid ${grey[300]}` }}>
                                    {item?.children?.length === 0 ? <Box className="py-2 flex items-center gap-2" sx={{ px: item?.px }}>
                                        <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap"> {item?.data}</Typography>
                                    </Box> :
                                        <Box className=" flex flex-col items-center  gap-0 ">
                                            <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap"> {item?.data}</Typography>
                                            <Box className="flex-1 w-full flex   gap-0  " sx={{ borderTop: `1px solid ${grey[300]}` }}>
                                                {
                                                    item?.children?.map((subItem, index) => (
                                                        <Box className="flex-1 flex items-center justify-center gap-2" sx={{ borderRight: index !== item?.children?.length - 1 && `1px solid ${grey[300]}` }}>
                                                            <Typography fontSize={font.size.fs_6} fontWeight="bold" className="whitespace-nowrap"> {subItem?.data}</Typography>
                                                        </Box>

                                                    ))
                                                }
                                            </Box>
                                        </Box>}
                                </TableCell>
                            ))
                        }
                    </TableHead>
                )}


                itemContent={(index, data) => (
                    <TableRow sx={{ "&:hover": { background: grey[100], } }}>

                        <TableCell padding="none" align="left" sx={{ minWidth: "40px", maxWidth: "40px", cursor: "pointer", borderRight: `1px solid ${grey[300]}` }}>
                            <Box className="flex items-center justify-center ">
                                <Typography>{index + 1}</Typography>
                            </Box>
                        </TableCell>

                        <TableCell padding="none" align="left" sx={{ minWidth: "154px", maxWidth: "154px", cursor: "pointer", borderRight: `1px solid ${grey[300]}` }}>
                            <Box className="flex items-center  px-4 ">
                                <Typography fontSize={font.size.fs_4}>{data?.fieldName}</Typography>
                            </Box>
                        </TableCell>

                        <TableCell padding="none" align="left" sx={{ minWidth: "130px", maxWidth: "130px", cursor: "pointer", }}>
                            <Box className="flex items-center gap-0">
                                <StyledTextField
                                    fullWidth
                                    // label="xpath"
                                    name="xpath"
                                // defaultValue={data?.elementId?.xpath}
                                // onBlur={(e) => handleChange(e, index)}
                                />
                                <Divider orientation="vertical" flexItem />
                                <StyledTextField
                                    fullWidth
                                    // label="cssSelector"
                                    name="cssSelector"
                                // defaultValue={data?.elementId?.cssSelector}
                                // onBlur={(e) => handleChange(e, index)}
                                />
                                <Divider orientation="vertical" flexItem />
                                <StyledTextField
                                    fullWidth
                                    // label="id"
                                    name="id"
                                // defaultValue={data?.elementId?.id}
                                // onBlur={(e) => handleChange(e, index)}
                                />
                            </Box>
                        </TableCell>

                    </TableRow>

                )}
            />
        </Box>
    )
}

function SectionContainer({ children, ...props }) {
    return (
        <Box className={`w-full flex flex-col gap-1 px-1 border border-transparent cursor-pointer ${props.onClick && "hover:border hover:border-gray-200"}  rounded-lg ${props.className} `} {...props}>
            {children}
        </Box>
    );
}