import React, { useEffect, useState } from 'react'
import { getFileDetail_API } from '../../api/controller/FileController';
import { useApplicationContext } from '../../context/ApplicationContext';
import { Box, CircularProgress, IconButton, useTheme } from '@mui/material';
import useErrorHandler from '../../api/errorHandler/useErrorHandler';
import { HttpStatusCode } from 'axios';
import { FileType, ModulePath, Severity } from '../../constant/ApplicationVariables';
import { DeleteOutlined, DownloadOutlined, PersonOutline } from '@mui/icons-material';
import { FaRegFileWord } from "react-icons/fa";
import { FaRegFileExcel } from "react-icons/fa";
import { font } from '../../styles/font';
import { blue, green, red } from '@mui/material/colors';
import { FaRegFilePdf } from "react-icons/fa";
import MediaPreview from './MediaPreview';
import { useLocation } from 'react-router-dom';
import { ExcelCustomIcon, PdfCustomIcon, WordCustomIcon } from '../styled/CustomIcons';


class ICON_CONFIG {
    static document = { color: blue[500], background: blue[50], icon: <WordCustomIcon sx={{ fontSize: font.size.fs_1, }} /> };
    static excel = { color: green[500], background: green[50], icon: <ExcelCustomIcon sx={{ fontSize: font.size.fs_1, }} /> };
    static pdf = { color: red[500], background: red[50], icon: <PdfCustomIcon sx={{ fontSize: font.size.fs_1, }} /> };
}

export default function MediaComponent({ url, name, type, variant, ...props }) {

    const { handleSnackbar, currentUserStoryData, setCurrentUserStoryData, currentUseCaseData, setCurrentUseCaseData, } = useApplicationContext();

    const theme = useTheme();
    const pathName = useLocation()?.pathname;
    const errorHandler = useErrorHandler();


    const [loading, setLoading] = useState(false);
    const [extractedUrl, setExtractedUrl] = useState("");



    function handleMedia() {
        if (type === "image") return (
            <img src={extractedUrl} alt={name} className={`object-cover w-full h-full overflow-hidden ${props.className}`} style={props.sx} />
        );


        if (type === "video") return (
            <video src={extractedUrl} alt={name} className={`object-fill w-full h-full overflow-hidden ${props.className}`} style={props.sx} />

        );
        // if (FileType.audioList.includes(contentType)) return <audio src={url} alt={name} className="object-fill w-full h-full overflow-hidden" />

        if (type === "document") return (
            <Box className={`flex items-center justify-center w-full h-full overflow-hidden border ${props.className} `} sx={props.sx} bgcolor={ICON_CONFIG.document.background} >
                {ICON_CONFIG.document.icon}
            </Box>
        );
        if (type === "excel") return (
            <Box className={`flex items-center justify-center w-full h-full overflow-hidden border ${props.className} `} sx={props.sx} bgcolor={ICON_CONFIG.excel.background}>
                {ICON_CONFIG.excel.icon}
            </Box>
        );
        if (type === "pdf") return (
            <Box className={`flex items-center justify-center w-full h-full overflow-hidden border ${props.className} `} sx={props.sx} bgcolor={ICON_CONFIG.pdf.background} >
                {ICON_CONFIG.pdf.icon}
            </Box>
        );


        return (
            <Box className={`flex items-center justify-center w-full h-full overflow-hidden border ${props.className} `} sx={props.sx} bgcolor={theme.palette.background.custom} >
                <PersonOutline />
            </Box>
        )
    }

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = extractedUrl;
        link.download = name;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }



    const handleGetFileDetail = async (mediaPath) => {
        try {
            setLoading(true);

            let body = {
                path: mediaPath
            }

            const response = await getFileDetail_API(body);
            console.log(response, "response handleUpdateStory")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response?.status === HttpStatusCode.Ok) {

                const url = URL.createObjectURL(response.data);
                setExtractedUrl(url);


                console.log(url, "mediaattachement url")
                // setExtractedUrl(`data:image/png;base64,${btoa(response.data)}`)
            }

            console.log(response, "media data")
        } catch (error) {

            console.log(error, error?.message, "media error");
            handleSnackbar(String(error), Severity.error);

        } finally {

            setTimeout(() => {
                setLoading(false);
            }, 1000);

        }
    }

    useEffect(() => {
        if (url) {
            handleGetFileDetail(url);
        }
    }, [url])

    console.log(extractedUrl, "extractedUrl")




    return (
        <Box className="flex flex-col h-full w-full  overflow-hidden" borderRadius={variant === "rounded" ? theme.shape.borderRadius : variant === "circle" ? "50%" : "0"} bgcolor={theme.palette.background.default} >
            <Box className="flex flex-col h-full w-full relative overflow-hidden" >

                {loading ?
                    <Box className="flex flex-col items-center justify-center h-full w-full absolute left-0 top-0 backdrop-blur-sm">
                        <CircularProgress color="secondary" size={16} />
                    </Box>

                    :
                    <Box className="flex flex-col h-full w-full transition-all ease-in-out duration-300  hover:scale-110">
                        {handleMedia()}
                    </Box>
                }


            </Box>

        </Box>
    )
}