import { Box, Typography, useTheme } from "@mui/material";
import { font } from "../../../styles/font";


export function KeyValuePairComponent({ heading, value }) {
    return (
        <Box className="w-full flex flex-col gap-1">
            {heading && <Box className="flex ">
                <HeadingComponent heading={heading} />
            </Box>}
            <Box className="flex items-center flex-1 ">
                {value}
            </Box>
        </Box>
    )
}

export function HeadingComponent({ heading, hideColon, ...props }) {
    const theme = useTheme();
    return (
        <Typography fontSize={font.size.fs_5} color={theme.palette.text.primary} fontWeight={600} className={`${props.className} `} {...props}>{heading} :
            {/* {hideColon ? "" : ":"} */}
        </Typography>
    )
}


export function ValueComponent({ text, ...props }) {
    const theme = useTheme();

    return (
        <Typography fontSize={font.size.fs_5} color={theme.palette.text.secondary} className={`${props.className} `} {...props}> {text ? text : "None"} </Typography>
    )
}