import { Box, Button, Dialog, Typography } from '@mui/material'
import { useEffect, useState } from 'react';
import { font } from '../../styles/font';

export default function DeleteConfirmationDialog({ open, handleClose, handleContinue, data }) {
    const [loading, setLoading] = useState(false);

    let [deleteData, setDeleteData] = useState(null);

    const handleCloseDialog = () => {
        handleClose();
    }

    useEffect(() => {
        if (data) {
            setDeleteData(data);
        }
    }, [data])

    return (
        <Dialog
            open={Boolean(open)}
            onClose={handleCloseDialog}
            sx={{
                '& .MuiDialog-paper': {
                    width: '100%',
                    maxWidth: '400px',
                    // height: '60vh'
                }
            }}
        >
            <Box className="flex flex-col gap-0  h-full w-full">
                <Box className="flex flex-col gap-4 px-4 py-2  w-full">
                    <Typography fontSize={font.size.fs_4} color='textPrimary'>Delete</Typography>
                </Box>

                <Box className="flex flex-col gap-4 px-4 py-2   flex-1 w-full">
                    <Typography fontSize={font.size.fs_4} color='textSecondary'>Are you sure you want to delete this item?</Typography>
                    {deleteData && <Typography fontSize={font.size.fs_4} color='textSecondary'>{JSON.stringify(deleteData)}</Typography>}
                </Box>

                <Box className="flex justify-end gap-4 px-4 py-2  w-full">
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                    <Button variant="contained" color="error" onClick={handleContinue}>Continue</Button>
                </Box>
            </Box>

        </Dialog>
    )
}

