import { AccountCircleOutlined, ArrowRightAlt, Delete, DeleteOutline, DeleteTwoTone, Edit, EditOutlined, FolderOutlined, FolderSpecial, FolderSpecialOutlined, FolderSpecialTwoTone, MoreHoriz, MoreVert, PlayArrow } from "@mui/icons-material"
import { Box, Breadcrumbs, Chip, IconButton, Menu, MenuItem, Popover, Typography, useTheme } from "@mui/material"
import { font } from "../../styles/font"
import { blue, brown, green, grey, red } from "@mui/material/colors";
import { useState } from "react";
import { LightTooltip } from "../styled/CustomComponent";

class STORY_STATUS {
    static DRAFT = "Draft";
    static APPROVED = "Approved";
    static UNDER_DEVELOPMENT = "Under development";
    static CLOSED = "Closed";
}

const ITEM_HEIGHT = 48;


function ProjectCard({ item, handleClick, handleEditClick, handleClickDelete }) {
    const theme = useTheme();

    const options = [
        {
            label: "Edit Story",
            icon: <Edit sx={{ color: theme.palette.secondary.main }} />,
            onClick: () => console.log("Edit Story"),
        },
        {
            label: "Delete Story",
            icon: <Delete color="error" />,
            onClick: () => console.log("Delete Story"),
        },
    ];

    const [moreAnchorEl, setMoreAchorEl] = useState(null);

    const handleOpenMore = (event) => {
        setMoreAchorEl(event.currentTarget);
    };
    const handleCloseMore = () => {
        setMoreAchorEl(null);
    };

    return (
        <Box className=" flex flex-col justify-between gap-0 w-full h-full py-0"  >

            {/* upper container */}
            <Box className="flex items-center gap-2 px-4 py-2 border-b ">
                <FolderSpecialOutlined />

                <Box className="flex-1 flex items-center justify-end gap-2  " >
                    <LightTooltip title="Edit">
                        <EditOutlined className="cursor-pointer" sx={{ color: blue[500] }} onClick={handleEditClick} />
                    </LightTooltip>

                    <LightTooltip title="Delete">
                        <DeleteOutline className="cursor-pointer" sx={{ color: red[500] }} onClick={handleClickDelete} />
                    </LightTooltip>

                </Box>
            </Box>

            <Box className=" flex flex-col justify-between gap-2 w-full h-full pt-2 pb-4" onClick={() => { handleClick(item) }}>
                <Box className="flex-1 flex flex-col gap-0 px-4 cursor-pointer " >
                    <Typography fontSize={font.size.fs_4} color={theme.palette.text.primary} className=" line-clamp-1 ">{item?.name}</Typography>
                    <Typography fontSize={font.size.fs_5} color={theme.palette.text.secondary} className=" line-clamp-2">{item?.description}</Typography>
                </Box>

                {/* bottom container */}
                <Box className="flex items-center justify-between px-4  " >
                    <ItemCount label={"User Stories"} value={item?.userStoryCount} />
                    <ItemCount label={"Use Cases"} value={item?.useCaseCount} />
                </Box>

                <Box className="w-full flex items-center gap-2 px-4 overflow-y-scroll overflow-x-auto " sx={{ "&::-webkit-scrollbar": { width: 0, height: 0 } }} >

                    {/* <LightTooltip title={<Typography fontSize={font.size.fs_6} className=" ">{item?.categoryName} / {item?.subCategoryName}</Typography>}>
                    <Box className="line-clamp-1 w-full flex items-center ">
                        <Typography fontSize={font.size.fs_5} className=" ">{item?.categoryName} / {item?.subCategoryName}</Typography>
                    </Box>
                </LightTooltip> */}
                    {/* <Breadcrumbs aria-label="breadcrumb" sx={{width : "100%", display : "flex", alignItems : "center"}}> */}
                    <Chip
                        size="small"
                        variant="outlined"
                        label={item?.categoryName}
                        fontSize={font.size.fs_5}
                        sx={{ fontSize: font.size.fs_6 }}
                    />
                    <Typography>/</Typography>
                    <Chip
                        size="small"
                        variant="outlined"
                        label={item?.subCategoryName}
                        fontSize={font.size.fs_5}
                        sx={{ fontSize: font.size.fs_6 }}
                    />
                    {/* </Breadcrumbs> */}

                </Box>
            </Box>


            <Popover
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={moreAnchorEl}
                open={Boolean(moreAnchorEl)}
                onClose={handleCloseMore}
                slotProps={{
                    paper: {
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: '20ch',
                        },
                    },
                }}
            >
                {options.map((option, index) => (
                    <MenuItem key={index} onClick={handleCloseMore} className="flex items-center gap-2">
                        {option?.icon}
                        {option?.label}
                    </MenuItem>
                ))}
            </Popover>
        </Box>
    )
}

export default ProjectCard


function ItemCount({ label, value }) {
    const theme = useTheme();

    return (
        <Box className="flex items-center gap-1">
            <Typography fontSize={font.size.fs_5} color={theme.palette.text.secondary} className="">{label}:</Typography>
            <Typography fontSize={font.size.fs_5} color={theme.palette.text.primary} className="  ">{value}</Typography>
        </Box>
    )
}