import { Box, CircularProgress, Fab, IconButton, Paper, Radio, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { font } from "../../styles/font";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useErrorHandler from "../../api/errorHandler/useErrorHandler";
import { useApplicationContext } from "../../context/ApplicationContext";
import { CHART_OPTIONS, Dashboard_Entity, Entity } from "../../constant/ApplicationVariables";
import { Add, ArrowBackOutlined, MoreHorizOutlined, MoreVert, MoreVertOutlined, SaveOutlined } from "@mui/icons-material";
import DashboardCard from "./DashboardCard";
import { forwardRef } from "react";
import { TableVirtuoso } from "react-virtuoso";
import { ValueComponent } from "../common/previewTemplate/CustomTextFormatting";
import { LightTooltip } from "../styled/CustomComponent";
import { amber, blue, brown, cyan, deepOrange, deepPurple, green, grey, indigo, lightBlue, lightGreen, lime, orange, pink, purple, red, teal, yellow } from "@mui/material/colors";
import { Bar } from "react-chartjs-2";
import { FaBoxTissue } from "react-icons/fa";
import { DatePicker, DesktopDatePicker } from "@mui/x-date-pickers";

export default function DashboardContainerComponent() {
    const {
        isGridViewEnabled, setIsGridViewEnabled,
        handleSnackbar,
        isActiveProjectContainerLoading, setIsActiveProjectContainerLoading,
        activeProject,
        activeDashboard,
        setActiveDashboard
    } = useApplicationContext();
    const theme = useTheme();
    const errorHandler = useErrorHandler();
    const pathName = useLocation().pathname;

    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.up("sm") && theme.breakpoints.down("md"));
    const isLaptop = useMediaQuery(theme.breakpoints.up("md") && theme.breakpoints.down("lg"));
    const isDesktop = useMediaQuery(theme.breakpoints.up("lg") && theme.breakpoints.down("xl"));
    const isLargeDisplay = useMediaQuery(theme.breakpoints.up("xl"));

    const [isLoading, setIsLoading] = useState(false);

    const [dashboardType, setDashboardType] = useState("public");



    const handleComponent = () => {
        switch (activeDashboard?.value) {
            case Dashboard_Entity.project.value: return <ProjectDashboardContainer />;
            case Dashboard_Entity.userStory.value: return <RequirementToTestingContainer />;
            default: return <ProjectDashboardContainer />
        }
    }



    return (
        <Box className="flex flex-col gap-4 w-full h-full overflow-hidden   " >

            {/* list container */}
            <Box className={`flex relative w-full  h-full overflow-hidden `} >
                {
                    (isLoading || isActiveProjectContainerLoading) && (
                        <Box className={`grow flex flex-col gap-2 items-center justify-center absolute left-0 right-0 top-0 bottom-0 `}>
                            <CircularProgress />
                            <Typography fontSize={font.size.fs_5}>Loading...</Typography>
                        </Box>
                    )
                }



                {/* main container */}
                <Box className="flex-1 flex flex-col gap-4">

                    {/* private/public , date filter */}
                    <Box className="w-full h-fit flex items-center justify-between gap-4 ">
                        {activeDashboard?.value === Dashboard_Entity.project.value && (
                            <Tabs
                                value={dashboardType}
                                className="border-b"
                                onChange={(event, newValue) => {
                                    setDashboardType(newValue);
                                }}
                            >
                                <Tab label="Public" value={"public"} />
                                <Tab label="Private" value={"private"} />
                            </Tabs>
                        )}


                        <Box className="flex items-center gap-2 w-72 ml-auto">

                            <DesktopDatePicker
                                label="Start Date"
                                onChange={(newValue) => {
                                    // handle start date change
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            <Typography>-</Typography>
                            <DesktopDatePicker
                                label="End Date"
                                onChange={(newValue) => {
                                    // handle end date change
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </Box>

                    </Box>

                    {/* main container */}
                    <Box className="flex-1 flex gap-4 overflow-hidden">
                        {handleComponent()}
                    </Box>
                </Box>


            </Box>
        </Box>
    )
}


function DashboardCard2() {
    const theme = useTheme();


    return (
        <Box className=" flex flex-col justify-between px-4 py-4 w-full h-full" bgcolor={theme.palette.background.default}>
            <Box className="flex items-center justify-between">
                <Typography fontSize={font.size.fs_4} color={"textSecondary"}>Revenue</Typography>
                <IconButton>
                    <MoreHorizOutlined />
                </IconButton>
                {/* <Radio size="small" /> */}
            </Box>

            <Box className="flex items-center justify-between">
                <Typography fontSize={font.size.fs_2} fontWeight={"bold"} color={"textSecondary"}>$2000</Typography>

            </Box>
        </Box>
    )
}

function ProjectDashboardContainer() {
    const theme = useTheme();
    const errorHandler = useErrorHandler();
    const pathName = useLocation().pathname;

    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.up("sm") && theme.breakpoints.down("md"));
    const isLaptop = useMediaQuery(theme.breakpoints.up("md") && theme.breakpoints.down("lg"));
    const isDesktop = useMediaQuery(theme.breakpoints.up("lg") && theme.breakpoints.down("xl"));
    const isLargeDisplay = useMediaQuery(theme.breakpoints.up("xl"));

    const [dataList, setDataList] = useState([
        {
            title: "User Story",
            type: "counter",
            isSelectable: false,
            data: "2597"
        },
        {
            title: "Use Case",
            type: "counter",
            isSelectable: false,
            data: "7464"
        },
        {
            title: "Test Case",
            type: "counter",
            isSelectable: false,
            data: "8765"
        },

    ]);

    const [dataListOther, setDataListOther] = useState([

        {
            title: "Priority",
            type: "doughnut",
            isSelectable: true,
            data: {
                labels: ['HIGH', 'MEDIUM', 'LOW',],
                datasets: [
                    {
                        // label: '# of Votes',
                        label: '',
                        data: [32, 40, 28],
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)',
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)',
                        ],
                        borderWidth: 1,
                    },
                ],
            }
        }
    ]);

    return (
        <Box className="flex-1 flex flex-wrap gap-4 overflow-y-auto">


            {/* project card */}
            <Box className="w-full h-fit flex  flex-wrap gap-4 pl-0  py-0 ">
                {
                    dataList?.map((item, index) => {
                        return (
                            <Box
                                key={index}
                                className={` overflow-hidden rounded-lg border hover:shadow-2xl  backdrop-blur-md transition-all ease-in-out duration-300 

                                     `}
                                bgcolor={theme.palette.card.main}
                                // onClick={() => handleOpenProject(project)}
                                sx={{ border: `1px solid transparent`, "&:hover": { border: `1px solid ${theme.palette.primary.main}` } }}
                            >
                                <DashboardCard
                                    item={item}
                                    isSelectable={item.isSelectable}
                                // handleClick={() => handleOpenUserStory(item)}
                                // handleChangeCheckbox={handleChangeCheckbox}
                                // handleOpenLinkTag={handleOpenLinkTag}
                                />

                            </Box>
                        )
                    })
                }
            </Box>

            {/* other card */}
            <Box className="w-full h-fit flex  flex-wrap gap-4 pl-0  py-0 ">
                {
                    dataListOther?.map((item, index) => {
                        return (
                            <Box
                                key={index}
                                className={` overflow-hidden rounded-lg border hover:shadow-2xl  backdrop-blur-md transition-all ease-in-out duration-300 

                                     `}
                                bgcolor={theme.palette.card.main}
                                // onClick={() => handleOpenProject(project)}
                                sx={{ border: `1px solid transparent`, "&:hover": { border: `1px solid ${theme.palette.primary.main}` } }}
                            >
                                <DashboardCard
                                    item={item}
                                    isSelectable={item.isSelectable}
                                // handleClick={() => handleOpenUserStory(item)}
                                // handleChangeCheckbox={handleChangeCheckbox}
                                // handleOpenLinkTag={handleOpenLinkTag}
                                />

                            </Box>
                        )
                    })
                }
            </Box>
        </Box>
    )
}

function RequirementToTestingContainer() {
    const { activeDashboard, setActiveDashboard } = useApplicationContext();
    const theme = useTheme();
    const errorHandler = useErrorHandler();
    const pathName = useLocation().pathname;

    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.up("sm") && theme.breakpoints.down("md"));
    const isLaptop = useMediaQuery(theme.breakpoints.up("md") && theme.breakpoints.down("lg"));
    const isDesktop = useMediaQuery(theme.breakpoints.up("lg") && theme.breakpoints.down("xl"));
    const isLargeDisplay = useMediaQuery(theme.breakpoints.up("xl"));

    const [activeProperty, setActiveProperty] = useState({
        label: "All",
        value: ""
    });
    const [currentGraphData, setCurrentGraphData] = useState(null);

    const [openCreateGraphDialog, setOpenCreateGraphDialog] = useState(false);

    // left list of properties
    let propertiesList = [
        {
            label: "Dashboard",
            value: ""
        },
        {
            label: "Priority",
            value: "Priority"
        },
        {
            label: "State",
            value: "State"
        },
        {
            label: "Type",
            value: "Type"
        },
        {
            label: "MOSCOW",
            value: "MOSCOW"
        },
        {
            label: "Confidentiality",
            value: "Confidentiality"
        },
        {
            label: "Owner",
            value: "Owner"
        },
        {
            label: "Business Value",
            value: "Business Value"
        },
        {
            label: "User Value",
            value: "User Value"
        },
        {
            label: "Assigned",
            value: "Assigned"
        },
        {
            label: "Planned Value",
            value: "Planned Value"
        },
        {
            label: "Actual Value",
            value: "Actual Value"
        },
        {
            label: "Linked Usecase",
            value: "Linked Usecase"
        },
        {
            label: "Linked TestCase",
            value: "Linked TestCase"
        },
        {
            label: "Attachements",
            value: "Attachements"
        },
        {
            label: "Acceptance Criteria",
            value: "Acceptance Criteria"
        },
        {
            label: "Dependencies",
            value: "Dependencies"
        },
        {
            label: "Comments",
            value: "Comments"
        },
        {
            label: "Business Unit",
            value: "Business Unit"
        },
        {
            label: "Vendor",
            value: "Vendor"
        },
        {
            label: "External Party",
            value: "External Party"
        }
    ];

    // card 
    // const [dataList, setDataList] = useState([
    //     {
    //         title: activeProperty,
    //         type: "doughnut",
    //         data: {
    //             labels: ['HIGH', 'MEDIUM', 'LOW'],
    //             datasets: [
    //                 {
    //                     // label: '# of Votes',
    //                     label: '',
    //                     data: [32, 40, 28],
    //                     backgroundColor: [
    //                         'rgba(255, 99, 132, 0.2)',
    //                         'rgba(54, 162, 235, 0.2)',
    //                         'rgba(255, 206, 86, 0.2)',
    //                         'rgba(75, 192, 192, 0.2)',
    //                         'rgba(153, 102, 255, 0.2)',
    //                         'rgba(255, 159, 64, 0.2)',
    //                     ],
    //                     borderColor: [
    //                         'rgba(255, 99, 132, 1)',
    //                         'rgba(54, 162, 235, 1)',
    //                         'rgba(255, 206, 86, 1)',
    //                         'rgba(75, 192, 192, 1)',
    //                         'rgba(153, 102, 255, 1)',
    //                         'rgba(255, 159, 64, 1)',
    //                     ],
    //                     borderWidth: 1,
    //                 },
    //             ],
    //         }
    //     }
    // ]);

    const [dataList, setDataList] = useState([
        {
            title: "User Story",
            type: "counter",
            isSelectable: false,
            data: "2597"
        },
        {
            title: "Priority",
            type: "doughnut",
            isSelectable: true,
            data: {
                labels: ['HIGH', 'MEDIUM', 'LOW',],
                datasets: [
                    {
                        // label: '# of Votes',
                        label: '',
                        data: [32, 40, 28],
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)',
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)',
                        ],
                        borderWidth: 1,
                    },
                ],
            }
        }
    ]);


    // table, graph data - priority
    const list2 = [
        {
            name: "Folder1",
            value: 110,
            properties: [
                {
                    name: "HIGH",
                    value: 40
                },
                {
                    name: "MEDIUM",
                    value: 30
                },
                {
                    name: "LOW",
                    value: 60
                },
            ]

        },
        {
            name: "Folder2",
            value: 110,
            properties: [
                {
                    name: "HIGH",
                    value: 20
                },
                {
                    name: "MEDIUM",
                    value: 30
                },
                {
                    name: "LOW",
                    value: 60
                }
            ]
        },
        {
            name: "Folder3",
            value: 110,
            properties: [
                {
                    name: "HIGH",
                    value: 20
                },
                {
                    name: "MEDIUM",
                    value: 30
                },
                {
                    name: "LOW",
                    value: 60
                }
            ]
        },
        {
            name: "Folder4",
            value: 110,
            properties: [
                {
                    name: "HIGH",
                    value: 20
                },
                {
                    name: "MEDIUM",
                    value: 30
                },
                {
                    name: "LOW",
                    value: 60
                }
            ]
        },
        {
            name: "Folder5",
            value: 110,
            properties: [
                {
                    name: "HIGH",
                    value: 20
                },
                {
                    name: "MEDIUM",
                    value: 30
                },
                {
                    name: "LOW",
                    value: 60
                }
            ]
        }
    ]

    // table, graph data - state
    const list = [
        {
            name: "Folder1",
            value: 110,
            properties: [
                {
                    name: "DRAFT",
                    value: 40
                },
                {
                    name: "APPROVED",
                    value: 30
                },
                {
                    name: "UNDER DEVELOPMENT",
                    value: 60
                },
                {
                    name: "COMPLETED",
                    value: 60
                },
                {
                    name: "CLOSED",
                    value: 50
                },
            ]

        },
        {
            name: "Folder2",
            value: 110,
            properties: [
                {
                    name: "DRAFT",
                    value: 40
                },
                {
                    name: "APPROVED",
                    value: 30
                },
                {
                    name: "UNDER DEVELOPMENT",
                    value: 60
                },
                {
                    name: "COMPLETED",
                    value: 60
                },
                {
                    name: "CLOSED",
                    value: 50
                },
            ]
        },
        {
            name: "Folder3",
            value: 110,
            properties: [
                {
                    name: "DRAFT",
                    value: 40
                },
                {
                    name: "APPROVED",
                    value: 30
                },
                {
                    name: "UNDER DEVELOPMENT",
                    value: 60
                },
                {
                    name: "COMPLETED",
                    value: 60
                },
                {
                    name: "CLOSED",
                    value: 50
                },
            ]
        },
        {
            name: "Folder4",
            value: 110,
            properties: [
                {
                    name: "DRAFT",
                    value: 40
                },
                {
                    name: "APPROVED",
                    value: 30
                },
                {
                    name: "UNDER DEVELOPMENT",
                    value: 60
                },
                {
                    name: "COMPLETED",
                    value: 60
                },
                {
                    name: "CLOSED",
                    value: 50
                },
            ]
        },
        {
            name: "Folder5",
            value: 110,
            properties: [
                {
                    name: "DRAFT",
                    value: 40
                },
                {
                    name: "APPROVED",
                    value: 30
                },
                {
                    name: "UNDER DEVELOPMENT",
                    value: 60
                },
                {
                    name: "COMPLETED",
                    value: 60
                },
                {
                    name: "CLOSED",
                    value: 50
                },
            ]
        }
    ];


    const handleOpenGraphPreview = (item) => {
        setCurrentGraphData(item);
    }

    const handleCloseGraphPreview = () => {
        setCurrentGraphData(null);
    }

    const handleOpenCreateGraph = () => {
        setOpenCreateGraphDialog(true)
    }

    const handleCloseCreateGraph = () => {
        setOpenCreateGraphDialog(false)
    }



    useEffect(() => {
        setDataList([
            {
                title: "User Story",
                type: "counter",
                isSelectable: false,
                data: "2597"
            },
            {
                // title: "activeProperty",
                title: "Priority",
                type: "doughnut",
                data: {
                    labels: ['HIGH', 'MEDIUM', 'LOW',],
                    datasets: [
                        {
                            // label: '# of Votes',
                            label: '',
                            data: [32, 40, 28],
                            backgroundColor: [
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)',
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)',
                            ],
                            borderWidth: 1,
                        },
                    ],
                }
            },
            {
                title: "State",
                type: "doughnut",
                data: {
                    labels: ['DRAFT', 'INPROGRESS', 'APPROVED', "COMPLETED", "CLOSED"],
                    datasets: [
                        {
                            // label: '# of Votes',
                            label: '',
                            data: [32, 40, 28, 30, 50],
                            backgroundColor: [
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)',
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)',
                            ],
                            borderWidth: 1,
                        },
                    ],
                }
            },
            {
                title: "Type",
                type: "doughnut",
                data: {
                    labels: ['PROCESS', 'FUNCTIONAL', 'NON-FUNCTIONAL',],
                    datasets: [
                        {
                            // label: '# of Votes',
                            label: '',
                            data: [32, 40, 28],
                            backgroundColor: [
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)',
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)',
                            ],
                            borderWidth: 1,
                        },
                    ],
                }
            },
            {
                title: "MOSCOW",
                type: "doughnut",
                data: {
                    labels: ["MUST HAVE", "SHOULD HAVE", "COULD HAVE", "WONT HAVE"],
                    datasets: [
                        {
                            // label: '# of Votes',
                            label: '',
                            data: [32, 40, 28, 30],
                            backgroundColor: [
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)',
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)',
                            ],
                            borderWidth: 1,
                        },
                    ],
                }
            },
            {
                title: "Confidentiality",
                type: "doughnut",
                data: {
                    labels: ['CONFIDENTIAL', 'SENSITIVE', 'UNCLASSIFIED',],
                    datasets: [
                        {
                            // label: '# of Votes',
                            label: '',
                            data: [32, 40, 28],
                            backgroundColor: [
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)',
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)',
                            ],
                            borderWidth: 1,
                        },
                    ],
                }
            },
            {
                title: "Business Value",
                type: "doughnut",
                data: {
                    labels: ['Business Value',],
                    datasets: [
                        {
                            // label: '# of Votes',
                            label: '',
                            data: [32,],
                            backgroundColor: [
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)',
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)',
                            ],
                            borderWidth: 1,
                        },
                    ],
                }
            },
            {
                title: "User Value",
                type: "doughnut",
                data: {
                    labels: ["'PROCESS', 'FUNCTIONAL', 'NON-FUNCTIONAL',"],
                    datasets: [
                        {
                            // label: '# of Votes',
                            label: '',
                            data: [32, 40, 28],
                            backgroundColor: [
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)',
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)',
                            ],
                            borderWidth: 1,
                        },
                    ],
                }
            }
        ])
    }, [activeProperty]);

    console.log(dataList, "dataList", activeProperty);
    console.log(currentGraphData, "currentGraphData");

    return (
        <Box className="flex-1 flex gap-4 overflow-hidden box-border">



            {/* left list of properties */}
            {
                (activeDashboard?.value === Entity.userStory || activeDashboard?.value === Entity.useCase || activeDashboard?.value === Entity.testCase) && (
                    <Box className="flex flex-col gap-4 px-4 py-4 w-[18%] h-full" borderRadius={theme.shape.borderRadius} bgcolor={theme.palette.background.default} >
                        <TextField placeholder="Search" />

                        <Box className="flex-1 flex flex-col gap-2 overflow-y-auto">
                            {
                                propertiesList?.map((item, index) => {
                                    return (
                                        <Box className="flex items-center gap-2 px-2 py-1" sx={{ cursor: "pointer", background: activeProperty?.value === item?.value ? theme.palette.highlight.main : "none", "&:hover": { background: theme.palette.highlight.main } }} borderRadius={theme.shape.borderRadius} onClick={() => setActiveProperty(item)}>
                                            <Typography fontSize={font.size.fs_5} color="textSecondary">{item?.label}</Typography>
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                    </Box>
                )}

            {/* dashboard container */}
            <Box className="flex-1 flex gap-4 relative overflow-hidden">

                {activeProperty?.value && (<Box className="absolute bottom-2 right-2 z-10">
                    <LightTooltip title={`Create Graph`}>

                    
                        <Fab
                            color="secondary"
                            // disabled={openEditStoryDialog}
                            onClick={handleOpenCreateGraph}
                            sx={{ boxShadow: "none" }}>
                            <Add />
                        </Fab>
                    </LightTooltip>
                </Box>)}

                {openCreateGraphDialog && (

                    <Box className={`absolute top-0 right-0 bottom-0 left-0  w-full h-full backdrop-blur-md bg-white z-50 flex items-center justify-center overflow-hidden`} borderRadius={theme.shape.borderRadius} >
                        <CreateGraphComponent onClose={handleCloseCreateGraph} />
                    </Box>
                )}




                {/* other properites container */}
                {activeProperty?.value &&
                    <Box className="flex-1 flex flex-col gap-0 relative overflow-y-auto ">


                        {/* card list container */}
                        {!currentGraphData ? (
                            <Box className="flex items-center flex-wrap gap-4 w-full h-fit  ">
                                {
                                    [{ title: "All Folder", list: list }, { title: "5folders", list: list }, { title: "3folders", list: list }, { title: "2folders", list: list }, { title: "7folders", list: list }]
                                        ?.map((item, index) => {
                                            return (
                                                <Box className=" flex flex-col gap-0 px-0 py-0 overflow-auto  w-full max-w-[440px] h-72  " bgcolor={theme.palette.background.paper} borderRadius={theme.shape.borderRadius} onClick={() => handleOpenGraphPreview(item)}>
                                                    <Box className="flex items-center justify-between gap-4 px-4 py-2 border-b">
                                                        <Typography fontSize={font.size.fs_5} color="textSecondary">{item?.title}</Typography>

                                                        <IconButton>
                                                            <MoreHorizOutlined />
                                                        </IconButton>
                                                    </Box>

                                                    <Box className="flex-1 flex items-center  gap-4 px-0 py-0 overflow-auto">
                                                        <GraphComponent title={item?.title} list={list} style={{ width: "100%", height: "100%" }} />
                                                    </Box>
                                                </Box>
                                            )
                                        })
                                }
                            </Box>) : (
                            <Box className={`absolute top-0 right-0 bottom-0 left-0  w-full h-full backdrop-blur-md bg-white z-50 flex items-center justify-center overflow-hidden`} borderRadius={theme.shape.borderRadius} >
                                <GraphPreview data={currentGraphData} onClose={handleCloseGraphPreview} />
                            </Box>
                        )}

                    </Box>
                }

                {/* Dashboard container  */}
                {!activeProperty?.value && <Box className="flex-1 flex flex-col gap-0 overflow-y-auto">
                    <Box className="w-full h-fit flex  flex-wrap gap-4 pl-0  py-0 ">
                        {
                            dataList?.map((item, index) => {
                                return (
                                    <Box
                                        key={index}
                                        className={` overflow-hidden rounded-lg border hover:shadow-2xl  backdrop-blur-md transition-all ease-in-out duration-300 

                                     `}
                                        bgcolor={theme.palette.card.main}
                                        // onClick={() => handleOpenProject(project)}
                                        sx={{ border: `1px solid transparent`, "&:hover": { border: `1px solid ${theme.palette.primary.main}` } }}
                                    >
                                        <DashboardCard
                                            item={item}
                                            isSelectable={item.isSelectable}
                                        // handleClick={() => handleOpenUserStory(item)}
                                        // handleChangeCheckbox={handleChangeCheckbox}
                                        // handleOpenLinkTag={handleOpenLinkTag}
                                        />

                                    </Box>
                                )
                            })
                        }
                    </Box>
                </Box>}

            </Box>
        </Box>
    )
}


function CreateGraphComponent({ onClose }) {

    const handleClose = () => {
        onClose();
    }

    return (
        <Box className=" flex flex-col gap-0 w-full h-full">
            <Box className="flex items-center justify-between gap-2 px-4 py-2 border-b">
                <IconButton onClick={handleClose}>
                    <ArrowBackOutlined />

                </IconButton>
                <Typography fontSize={font.size.fs_5} color="textSecondary" className="flex-1  ">Create Graph</Typography>

                <IconButton>
                    <SaveOutlined />
                </IconButton>
            </Box>

            <Box className="flex-1 flex  gap-0 overflow-auto divide-x">
                <Box className="flex flex-col gap-4 h-full w-[40%]">
                    folder list
                </Box>
                <Box className="flex-1 flex flex-col gap-4 ">
                    graph preview before save
                </Box>

            </Box>
        </Box>
    )
}


function GraphPreview({ data, onClose }) {

    const handleClose = () => {
        onClose();
    }


    return (
        <Box className=" flex flex-col gap-0 w-full h-full">
            <Box className="flex items-center justify-between gap-2 px-4 py-2 border-b">
                <IconButton onClick={handleClose}>
                    <ArrowBackOutlined />
                </IconButton>
                <Typography fontSize={font.size.fs_5} color="textSecondary" className="flex-1  ">{data?.title}</Typography>

                <IconButton>
                    <MoreHorizOutlined />
                </IconButton>
            </Box>

            <Box className="flex-1 flex flex-col  gap-0 overflow-auto">
                <Box className="flex-1 flex flex-col gap-2 px-0 py-0 overflow-auto   ">
                    <GraphComponent title={data?.title} list={data?.list} style={{ width: "auto", height: "100%" }} />
                </Box>

                <Box className="flex-1 flex-col gap-2 px-4 py-2  h-full w-full ">
                    <CounterTableComponent data={data} />
                </Box>
            </Box>

        </Box>
    )
}


export function CounterTableComponent({ data, }) {
    const theme = useTheme();

    const TableComponents = {
        Scroller: forwardRef((props, ref) => <TableContainer component={Paper} style={{ overflowX: "auto", overflowY: "scroll" }} {...props} ref={ref} />),
        Table: (props) => <Table {...props} style={{ borderCollapse: 'separate' }} />,
        TableHead: TableHead,
        TableRow: (props) => <TableRow {...props} />,
        TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
        TableCell: (props) => <TableCell {...props} sx={{ ...props?.sx, px: "1rem !important" }} />,
    }

    let columns = [
        {
            label: "Name",
            key: "name",
            sx: {
                width: "100%", maxWidth: "100%", background: theme.palette.header.main,
            }
        },
        {
            label: "Count",
            key: "count",
            sx: {
                minWidth: "120px", maxWidth: "100%", background: theme.palette.header.main,
            }
        }
    ]

    const dataList = [
        ...data?.list
    ];

    console.log(dataList, "dataList");

    return (
        <Box className="flex flex-col gap-0 w-full h-full">
            <TableVirtuoso
                style={{ width: "100%", height: "100%", overflowX: 'auto', }}
                data={dataList}
                components={TableComponents}
                fixedHeaderContent={() => (
                    <TableHead>
                        {false &&
                            columns?.map((item, index, arr) => {
                                return (
                                    <TableCell key={index} align="left" padding={"none"} className="whitespace-nowrap" style={{ margin: "0 1rem !important", fontWeight: "bold", ...item?.sx, borderRight: arr?.length - 1 === index ? "none" : `1px solid ${grey[300]}` }}>
                                        <ValueComponent text={item?.label} className="whitespace-nowrap py-1 px-2" fontWeight="bold" />
                                    </TableCell>
                                )
                            })
                        }

                        <TableCell align="left" padding={"none"} className="" style={{ margin: "0 1rem !important", fontWeight: "bold", minWidth: "150px", width: "100%", maxWidth: "100%", background: theme.palette.header.main, borderRight: `1px solid ${grey[300]}` }}>
                            <LightTooltip title={`Location`}>
                                <Box className="flex  py-1 px-2 overflow-hidden ">
                                    <ValueComponent text={"Location"} className=" w-fit truncate " fontWeight="bold" />
                                </Box>
                            </LightTooltip>
                        </TableCell>

                        {
                            dataList[0]?.properties?.map((item, index, arr) => {
                                return (
                                    <TableCell align="left" padding={"none"} className="overflow-hidden" style={{ overflow: "hidden", margin: "0 1rem !important", fontWeight: "bold", minWidth: "100px", maxWidth: "100px", background: theme.palette.header.main, borderRight: arr?.length - 1 === index ? "none" : `1px solid ${grey[300]}` }}>
                                        <LightTooltip title={`${item?.name}`}>
                                            <Box className="flex py-1 px-2 overflow-hidden ">
                                                <ValueComponent text={item?.name} className=" truncate text-wrap " fontWeight="bold" />
                                            </Box>
                                        </LightTooltip>
                                    </TableCell>
                                )
                            })
                        }

                        {/* <TableCell align="left" padding={"none"} className="whitespace-nowrap" style={{ margin: "0 1rem !important", fontWeight: "bold", width: "100%", maxWidth: "100%", background: theme.palette.header.main, borderRight: `1px solid ${grey[300]}` }}>

                        <ValueComponent text={"Module Name"} className="whitespace-nowrap py-1 px-2" fontWeight="bold" />
                    </TableCell>

                    <TableCell align="left" padding={"none"} className="whitespace-nowrap" style={{ margin: "0 1rem !important", fontWeight: "bold", minWidth: "120px", maxWidth: "100%", background: theme.palette.header.main, }}>

                        <ValueComponent text={"Count"} className="whitespace-nowrap py-1 px-2" fontWeight="bold" />
                    </TableCell> */}
                    </TableHead>
                )}


                itemContent={(index, itemData) => (

                    <TableRow sx={{ "&:hover": { background: grey[100], }, display: "table-row" }}>

                        <TableCell padding="none" align="left" sx={{ minWidth: "100px", width: "100%", maxWidth: "100%", borderRight: `1px solid ${grey[300]}` }} >
                            <ValueComponent text={`${itemData?.["name"]}`} className=" px-2 py-1" />
                        </TableCell>

                        {
                            itemData?.properties?.map((item, index, arr) => {
                                return (
                                    <TableCell padding="none" align="left" sx={{ minWidth: "100px", maxWidth: "100px", borderRight: arr?.length - 1 === index ? "none" : `1px solid ${grey[300]}` }} >
                                        <LightTooltip title={item?.value} >
                                            <ValueComponent text={`${item?.value}`} className=" px-2 py-1" />
                                        </LightTooltip>
                                    </TableCell>
                                )
                            })
                        }

                        {/* <TableCell padding="none" align="left" sx={{ width: "100%", maxWidth: "100%", borderRight: `1px solid ${grey[300]}` }} >

                        <ValueComponent text={`${itemData?.name}`} className="line-clamp-1 px-2 py-1" />
                    </TableCell>

                    <TableCell padding="none" align="left" sx={{ minWidth: "120px", maxWidth: "100%", }} >
                        <LightTooltip title={<ValueComponent text={`${itemData?.count}`} />} placement="left">

                            <ValueComponent text={`${itemData?.count}`} className="line-clamp-1 px-2 py-1" />
                        </LightTooltip>
                    </TableCell> */}

                    </TableRow>

                )}
            />
        </Box>
    )
}

function GraphComponent({ title, list, props }) {
    const theme = useTheme();

    const [chartDataList, setChartDataList] = useState(null);

    const options = {
        responsive: true,
        maintainAspectRatio: false,

        plugins: {
            ...CHART_OPTIONS,
            title: {
                ...CHART_OPTIONS.title,
                text: title,
                // display: property?.label,
                display: false,

            }
            // legend: {
            //     position: 'top',
            // },
            // title: {
            //     display: false,
            //     text: "User Story",
            // },

        },
    };


    const outputData = {
        labels: ["Folder1", "Folder2", "Folder3", "Folder4", "Folder5"],
        datasets: [
            {
                label: "HIGH",
                data: [20, 20, 20, 20, 20],
                backgroundColor: theme.palette.secondary.main,
            },
            {
                label: "MEDIUM",
                data: [30, 30, 30, 30, 30],
                backgroundColor: theme.palette.secondary.main,
            },
            {
                label: "LOW",
                data: [60, 60, 60, 60, 60],
                backgroundColor: theme.palette.secondary.main,
            },
        ],
    };


    function transformData1(inputList) {
        const labels = inputList?.map(item => item.name);
        const datasets = inputList[0].properties?.map(prop => ({
            label: prop.name,
            data: inputList?.map(item => item.properties?.find(p => p.name === prop.name).value),
            backgroundColor: theme.palette.secondary.main,
        }));

        return { labels, datasets };
    }

    let COLORS = [
        red[400],
        pink[400],
        purple[400],
        deepPurple[400],
        indigo[400],
        blue[400],
        lightBlue[400],
        cyan[400],
        teal[400],
        green[400],
        lightGreen[400],
        lime[400],
        yellow[400],
        amber[400],
        orange[400],
        deepOrange[400],
    ];

    function transformData(inputList) {
        const labels = inputList?.map(item => item.name);
        const datasets = Array.from(new Set(inputList?.flatMap(item => item.properties?.map(prop => prop.name))))?.map((name, index) => ({
            label: name,
            data: inputList?.map(item => item.properties.find(prop => prop.name === name).value),
            backgroundColor: COLORS[index % COLORS.length]
        }));

        return { labels, datasets };
    }

    console.log("datasets", transformData(list))



    useEffect(() => {
        if (list?.length > 0) {
            setChartDataList(transformData(list));
        }
    }, [list]);


    /*
    {
    "labels": [
        "Folder1",
        "Folder2",
        "Folder3",
        "Folder4",
        "Folder5"
    ],
    "datasets": [
        {
            "label": "HIGH",
            "data": [
                20,
                20,
                20,
                20,
                20
            ],
            "backgroundColor": "#1A73E8"
        },
        {
            "label": "MEDIUM",
            "data": [
                30,
                30,
                30,
                30,
                30
            ],
            "backgroundColor": "#1A73E8"
        },
        {
            "label": "LOW",
            "data": [
                60,
                60,
                60,
                60,
                60
            ],
            "backgroundColor": "#1A73E8"
        }
    ]
}
    */

    return (
        <Box className="flex items-center justify-center px-4 py-4 w-full h-full ">
            {chartDataList && <Bar options={options} data={chartDataList} redraw width={props?.width ? props?.width : "quto"} height={props?.height ? props?.height : "100%"} />}
        </Box>
    )
}