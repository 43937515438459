import { AccountCircle, Add, ArrowBack, Attachment, AutoAwesome, AutoAwesomeMosaic, AutoAwesomeOutlined, CheckBox, Close, ContentCut, CopyAllOutlined, Create, Delete, DeleteOutlined, Description, DescriptionOutlined, DocumentScanner, DragHandle, Edit, EditOutlined, ExpandMore, KeyboardArrowDown, Label, MoreHoriz, Person, ReplayOutlined, ReplyOutlined, Save, Send, Settings, Upload } from "@mui/icons-material"
import { Accordion, AccordionDetails, AccordionSummary, alpha, Avatar, Box, Button, Checkbox, Chip, CircularProgress, ClickAwayListener, Divider, FormControl, FormControlLabel, getContrastRatio, IconButton, ImageList, ImageListItem, InputAdornment, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Popover, Select, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, ToggleButton, Typography, useTheme } from "@mui/material"
import { blue, grey, red } from "@mui/material/colors";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { font } from "../../styles/font";
import { useApplicationContext } from "../../context/ApplicationContext";
import { AI_Entity, Entity_Label, FileType, FolderType, Severity } from "../../constant/ApplicationVariables";
import { addCommentUserStory_API, getStoryConfidencialityList_API, getStoryList_API, getStoryMOSCOWList_API, getStoryProirityList_API, getStoryPropertiesList_API, getStoryStatusList_API, getStoryTypeList_API, updateCommentUserStory_API, updateStory_API } from "../../api/controller/StoryController";
import useErrorHandler from "../../api/errorHandler/useErrorHandler";
import { HttpStatusCode } from "axios";
import { CustomAccordionComponent, CustomAccordionComponentDetails, CustomAccordionComponentSummary, LightTooltip } from "../styled/CustomComponent";
import styled from "@emotion/styled";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { convertEditorDataToReact } from "../../utils/StringOperations";
import { TableVirtuoso } from "react-virtuoso";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import LabelIcon from '@mui/icons-material/Label';
import ChecklistIcon from '@mui/icons-material/Checklist';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import LinkIcon from '@mui/icons-material/Link';
import dayjs from "dayjs";
import UserStoryList from "./UserStoryList";
import { getFileDetail_API } from "../../api/controller/FileController";
import MediaAttachmentComponent from "../media/MediaAttachmentComponent";
import MediaPreview from "../media/MediaPreview";
import { LinkCustomIcon, UserStoryCustomIcon } from "../styled/CustomIcons";
import { formatDateTime } from "../../utils/DateTime";
import DeleteConfirmationDialog from "../dialog/DeleteConfirmationDialog";
import { CommentComponent } from "../common/previewTemplate/CommentComponent";
import { HeadingComponent, KeyValuePairComponent, ValueComponent } from "../common/previewTemplate/CustomTextFormatting";
import { AccordionComponent, AccordionComponentDetails, AccordionComponentSummary, AccordionDetailsContainer, LinkedItemsTable, SectionContainer } from "../common/previewTemplate/StaticComponents";
import LabelBadge from "../common/LabelBadge";

const StyledSelect = styled(Select)(({ theme }) => ({
    height: '24px ',
    '& .MuiOutlinedInput-root': {
        height: '100%',
        padding: '0 14px',
    },
}));

const QuillContainer = styled("div")(({ theme }) => ({
    ".ql-container": {
        borderRadius: " 0 0 0.6rem 0.6rem",
    },
    ".ql-toolbar": {
        borderRadius: "  0.6rem 0.6rem 0 0 "
    },
    ".ql-editor": {
        height: "200px"
    },
}));



class EditSection {
    static customId = "customId";
    static title = "title";
    static description = "description";

    static acceptanceCriteria = "acceptanceCriteria";
    static dependencies = "dependencies";
    static checklist = "checklist";
    static attachments = "attachments";
    static linked = "linked";
    static linkedTestCase = "linkedTestCase";

    static status = "status";
    static assignedTo = "assignedTo";
    static priority = "priority";
    static category = "category";
    static moscow = "moscow";
    static createdBy = "createdBy";
    static createdAt = "createdAt";
    static owner = "owner";
    static storyPointPlanned = "storyPointPlanned";
    static storyPointActual = "storyPointActual";

    static storyType = "storyType";
    static userFields = "userFields";
    static userPoints = "userPoints";
    static plannedInRelease = "plannedInRelease";
    static plannedInSprint = "plannedInSprint";
    static confidenciality = "confidenciality";
    static underSOXCategory = "underSOXCategory";
    static environment = "environment";
    static verificationOwner = "verificationOwner";
    static verificationMethod = "verificationMethod";
    static dependency = "dependency";
    static businessValue = "businessValue";
    static userValue = "userValue";
    static userPersona = "userPersona";
    static testingCriteria = "testingCriteria";
    static businessUnit = "businessUnit";
    static vendor = "vendor";
    static externalPoints = "externalPoints";
    static subProducts = "subProducts";
    static dueDate = "dueDate";
    static members = "members";
    static checklist = "checklist";
    static label = "label";
    static comments = "comments";
    static activity = "activity";


}

class AccordionName {
    static details = "details";
    static attachments = "attachments";
    static otherFields = "otherFields";
    static estimationStoryPoints = "estimation_StoryPoints";
    static linkTo = "linkTo";
    static tagTo = "tagTo";
    static linkedUseCase = "linkedUseCase";
    static linkedTestCase = "linkedTestCase";
}


export default function EditStoryComponent({ open, closeDialog, }) {
    const {
        currentUserStoryData, setCurrentUserStoryData,
        currentFolderData,
        activeProject,
        handleSnackbar,
        setOpenLinkTagDrawer,
        userStoryList, setUserStoryList,
        toggleChatWindow, setToggleChatWindow,
        isItemChatWindow, setIsItemChatWindow,
        currentAIEntityData, setCurrentAIEntityData,
        userStoryPropertiesData, setUserStoryPropertiesData,
        labelList, setLabelList
    } = useApplicationContext();
    const theme = useTheme();
    const errorHandler = useErrorHandler();


    const [loading, setLoading] = useState(false);
    const [enableEdit, setEnableEdit] = useState(null);


    const [userStoryData, setUserStoryData] = useState(null);
    const [accetanceCriteriaText, setAccetanceCriteriaText] = useState("");
    const [commentText, setCommentText] = useState("");


    const [editorValue, setEditorValue] = useState("");
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedFilesForDelete, setSelectedFilesForDelete] = useState([]);
    const [deleteWarning, setDeleteWarning] = useState({
        open: false,
        data: null,
        type: ""
    });
    const [selectedLabels, setSelectedLabels] = useState([]);

    const [sections, setSections] = useState({
        [EditSection.attachments]: false,
        [EditSection.members]: false,
        [EditSection.checklist]: false,
        [EditSection.label]: false,
        [EditSection.dueDate]: false,
        [EditSection.userPersona]: false
    });

    const [stateList, setStateList] = useState([]);
    const [moscowList, setMoscowList] = useState([]);
    const [confidentialityList, setConfidentialityList] = useState([]);
    const [typeList, setTypeList] = useState([]);
    const [priorityList, setPriorityList] = useState([]);

    const [expandedAccordion, setExpandedAccordion] = useState(AccordionName.details);
    const [activityType, setActivityType] = useState("comments");

    const [editAnchorEl, setEditAnchorEl] = useState(null);
    const [aiAnchorEl, setAiAnchorEl] = useState(null);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [moreAnchorEl, setMoreAnchorEl] = useState(null);
    const [labelAnchorEl, setLabelAnchorEl] = useState(null);


    const fileInputRef = useRef(null);


    const handleChangeAccordion = (panel) => (event, isExpanded) => {

        setExpandedAccordion(`${panel}`)
        return;

        let list = [...expandedAccordion];

        if (list.includes(panel)) {
            const index = list.indexOf(panel);
            list.splice(index, 1);
        } else {
            list = [...list, panel]
        }

        setExpandedAccordion(list);
    };


    const handleAddAcceptanceCriteria = (e) => {
        let text = e.target.value ? e.target.value : accetanceCriteriaText;
        if (!text) {
            return
        }

        if (userStoryData?.acceptanceCriteria) {
            setUserStoryData({ ...userStoryData, acceptanceCriteria: [...userStoryData?.acceptanceCriteria, text] });
        } else {
            setUserStoryData({ ...userStoryData, acceptanceCriteria: [text] });
        }

        setAccetanceCriteriaText("");
    }

    const handleRemoveAcceptanceCriteria = (index) => {
        setUserStoryData({ ...userStoryData, acceptanceCriteria: userStoryData?.acceptanceCriteria.filter((_, i) => i !== index) });
    }


    const handleChange = (e) => {
        setUserStoryData({ ...userStoryData, [e.target.name]: e.target.value });
    }

    const handleChangeFiles = (e) => {
        let files = e.target.files;
        setSelectedFiles([...files]);

        console.log(files, files && files[0] && URL.createObjectURL(files[0]), "files")
    }

    const handleCheckValues = () => {
        let flag = true;

        if (!userStoryData?.customId) flag = false;
        if (!userStoryData?.title) flag = false;
        // if (!userStoryData?.description) flag = false;

        return flag;
    }


    const handleOpenLinkTag = (data) => {
        setOpenLinkTagDrawer(true)
    }


    const handleOpenAIPopup = (e) => {
        e && e.stopPropagation();
        setAiAnchorEl(e.currentTarget);
    }

    const handleCloseAIPopup = () => {
        setAiAnchorEl(null);
    }

    const handleToggleAIWindow = (entityLabel, entityType) => {

        if (isItemChatWindow && currentAIEntityData?.label === entityLabel && currentAIEntityData?.entity === entityType) {
            setIsItemChatWindow(false)
            setCurrentAIEntityData(null);
            // setCurrentUserStoryData(null);
        } else {
            // setCurrentUserStoryData(data);
            setIsItemChatWindow(true);
            setToggleChatWindow(true);
            setCurrentAIEntityData({ label: entityLabel, entity: entityType })
        }

        handleCloseAIPopup();
    }



    // steps list items operation
    const handleCurrentListItem = (key, stepIndex, stepText) => {
        console.log(stepText, stepIndex, "stepdata")
        // setCurrentStepText(stepText)
        // setCurrentStepIndex(stepIndex);

        let list = userStoryData[key] ? userStoryData[key] : [];
        list[stepIndex] = stepText;
        setUserStoryData({ ...userStoryData, [key]: list });
    }

    const handleRemoveListItem = (key, index) => {
        setUserStoryData({ ...userStoryData, [key]: userStoryData[key]?.filter((_, i) => i !== index) });
    }

    const handleDragStart = (key, index, e) => {
        e.dataTransfer.setData('text', index.toString());
    };

    const handleDragOver = (key, e) => {
        e.preventDefault();
    };

    const handleDrop = (key, index, e) => {
        const draggedIndex = parseInt(e.dataTransfer.getData('text'));
        const newSteps = userStoryData[key] ? [...userStoryData[key]] : [];
        const [removed] = newSteps.splice(draggedIndex, 1);
        newSteps.splice(index, 0, removed);
        setUserStoryData({ ...userStoryData, [key]: newSteps });
    };

    const handleAddRowBelow = (key, index) => {
        let steps = userStoryData[key] ? [...userStoryData[key]] : [];

        if (steps?.length > 0 && (steps[index] === "" || steps[index].length < 3 || steps[index].length > 300)) {
            handleSnackbar("Length should be between  3 - 300", Severity.warning)
            return;
        }
        if (steps?.length > 0) {
            steps.splice(index + 1, 0, "");
        } else {
            steps.push("");
        }

        setUserStoryData({ ...userStoryData, [key]: steps });
    }


    const handleDelete = (data, type) => {
        if (type === "file") {
            setDeleteWarning({
                open: true,
                data: data,
                type: type
            })
        }


        if (type === "item") {
            setDeleteWarning({
                open: true,
                data: data,
                type: type
            })
        }
    }


    const handleEnableSectionEdit = (e, sectionName) => {
        setEnableEdit(sectionName);
        setEditAnchorEl(e.currentTarget);
    }

    const handleCloseSectionEdit = () => {
        setEnableEdit(null);
        setEditAnchorEl(null);
        setUserStoryData(currentUserStoryData);
    }

    const handleToggleMenuPopup = (e) => {

        e && e.stopPropagation();

        if (!menuAnchorEl) {
            setMenuAnchorEl(e.currentTarget);
        } else {
            setMenuAnchorEl(null);
        }
    }


    const handleToggleMorePopup = (e) => {

        e && e.stopPropagation();

        if (!moreAnchorEl) {
            setMoreAnchorEl(e.currentTarget);
        } else {
            setMoreAnchorEl(null);
        }
    }

    const handleToggleSection = (sectionName) => {
        setSections({ ...sections, [sectionName]: !sections[sectionName] })
    }



    const handleToggleLabelPopup = (e) => {

        e && e.stopPropagation();

        if (!labelAnchorEl) {
            setLabelAnchorEl(e.currentTarget);
        } else {
            setLabelAnchorEl(null);
        }
    }

    const handleChangeLabel = (label) => {
        let list = selectedLabels ? [...selectedLabels] : [];

        const index = list?.findIndex((item) => item?.name === label?.name);

        if (index > -1) {
            list.splice(index, 1);
        } else {
            list.push(label);
        }

        setSelectedLabels(list);
    }

    const handleLabelUpdate = () => {
        // setUserStoryData({ ...userStoryData, labels: selectedLabels });
        handleContinueStory();
    }




    const handleContinueComment = () => {
        let body = {
            // "customIds": [currentUserStoryData?.customId],
            // "newCustomId": userStoryData?.customId,
            // "title": userStoryData?.title,
            // "description": userStoryData?.description,
            // "priority": "Medium",
            // "assignedToEmail": userStoryData?.assignedToEmail,
            // "acceptanceCriteria": userStoryData?.acceptanceCriteria,
            // "status": userStoryData?.status,
            // "moduleName": currentFolderData?.type === FolderType.project ? currentUserStoryData?.moduleName : currentFolderData?.name,


            "text": commentText,

            "userStoryCustomId": userStoryData?.customId,
            // "useCaseCustomId": "",
            // "testCaseCustomId": "",
            // "commentCustomId": "", // if user reply for particular comment, then use it.

            // "attachments": "", // MVP2


            "folderPath": currentFolderData?.path, // if userStoryCustomId / useCaseCustomId / testCaseCustomId is present, then use it.
            "projectName": activeProject?.name,
        }


        // if (selectedFiles?.length > 0) {
        //     body.attachments = selectedFiles
        //     setSelectedFiles([])
        // }

        console.log(body, "body in handleContinueComment")
        handleAddComment(body);
    }

    const handleContinueStory = () => {
        let body = {
            "customIds": [currentUserStoryData?.customId],
            // "newCustomId": userStoryData?.customId,
            // "title": userStoryData?.title,
            // "description": userStoryData?.description,
            // "priority": "Medium",
            // "assignedToEmail": userStoryData?.assignedToEmail,
            // "acceptanceCriteria": userStoryData?.acceptanceCriteria,
            // "status": userStoryData?.status,
            // "moduleName": currentFolderData?.type === FolderType.project ? currentUserStoryData?.moduleName : currentFolderData?.name,
            "lastModifiedAt": currentUserStoryData?.lastModifiedAt ? currentUserStoryData?.lastModifiedAt : null,
            "folderPath": currentFolderData?.path,
            "projectName": activeProject?.name,
        }

        if (userStoryData?.customId !== currentUserStoryData?.customId) {
            body.newCustomId = userStoryData?.customId
        }
        if (userStoryData?.title !== currentUserStoryData?.title) {
            body.title = userStoryData?.title
        }
        if (JSON.stringify(userStoryData?.description) !== JSON.stringify(currentUserStoryData?.description)) {
            body.description = userStoryData?.description
        }


        if (JSON.stringify(userStoryData?.acceptanceCriteria) !== JSON.stringify(currentUserStoryData?.acceptanceCriteria)) {
            body.acceptanceCriteria = userStoryData?.acceptanceCriteria ? [...userStoryData?.acceptanceCriteria?.filter((item) => item && item !== "")] : null
        }

        if (JSON.stringify(userStoryData?.dependencies) !== JSON.stringify(currentUserStoryData?.dependencies)) {
            body.dependencies = userStoryData?.dependencies ? [...userStoryData?.dependencies?.filter((item) => item && item !== "")] : null
        }


        if (userStoryData?.storyPointPlanned !== currentUserStoryData?.storyPointPlanned) {
            body.storyPointPlanned = userStoryData?.storyPointPlanned
        }
        if (userStoryData?.storyPointActual !== currentUserStoryData?.storyPointActual) {
            body.storyPointActual = userStoryData?.storyPointActual
        }


        if (userStoryData?.businessValue !== currentUserStoryData?.businessValue) {
            body.businessValue = userStoryData?.businessValue
        }
        if (userStoryData?.userValue !== currentUserStoryData?.userValue) {
            body.userValue = userStoryData?.userValue
        }


        if (userStoryData?.state !== currentUserStoryData?.state) {
            body.state = userStoryData?.state
        }
        if (userStoryData?.type !== currentUserStoryData?.type) {
            body.type = userStoryData?.type
        }
        if (userStoryData?.moscow !== currentUserStoryData?.moscow) {
            body.moscow = userStoryData?.moscow
        }
        if (userStoryData?.priority !== currentUserStoryData?.priority) {
            body.priority = userStoryData?.priority
        }
        if (userStoryData?.confidentiality && userStoryData?.confidentiality !== currentUserStoryData?.confidentiality) {
            body.confidentiality = userStoryData?.confidentiality
        }

        // if (userStoryData?.assignedToEmail !== currentUserStoryData?.assignedToEmail) {
        //     body.assignedToEmail = userStoryData?.assignedToEmail
        // }



        if (selectedLabels?.length > 0) {
            body.labels = selectedLabels?.map((item) => item?.name)
        }


        if (selectedFiles?.length > 0) {
            body.attachments = selectedFiles
        }

        setSelectedFiles([])

        // handleContinue(body);

        handleUpdateStory(body);
    }

    const handleRemoveAttachements = (fileItem) => {
        let body = {
            "customIds": [currentUserStoryData?.customId],
            "folderPath": currentFolderData?.path,
            "projectName": activeProject?.name,
        }

        // if (selectedFiles?.length > 0) {
        body.removeAttachments = [fileItem?.name]
        // }

        // setSelectedFilesForDelete([]);

        handleUpdateStory(body);
    }

    const handleUpdateStory = async (storyData) => {
        try {
            setLoading(true);

            let body = {
                ...storyData,
            }


            const bodyFormData = new FormData();

            // Object.keys(body).forEach(key => bodyFormData.append(key, body[key]));
            Object.keys(body).forEach(key => {
                if (Array.isArray(body[key])) {
                    if (key === "attachments") {
                        body[key]?.forEach((file, index) => {
                            bodyFormData.append(`${key}[${index}]`, file, file.name);
                        })
                    } else {
                        bodyFormData.append(key, body[key]);
                    }

                    // bodyFormData.append(key, body[key]);
                } else {
                    bodyFormData.append(key, body[key]);
                }
            });

            //    formData.append("media", file, file.name);


            const response = await updateStory_API(bodyFormData);
            console.log(response, "response handleUpdateStory")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response?.status === HttpStatusCode.Ok) {
                let previousData = currentUserStoryData;
                let updatedData = response.data[0];


                setCurrentUserStoryData(updatedData);
                setUserStoryData(updatedData);


                let list = userStoryList;
                const foundIndex = list?.findIndex(item => item?.customId === previousData?.customId);
                if (foundIndex !== -1) {
                    list[foundIndex] = updatedData;
                    // list.splice(foundIndex, 1, updatedData)
                }
                console.log(list, "updatedlist", previousData, foundIndex, updatedData,)
                setUserStoryList(list);



                // handleGetUserStoryList();
                // setEnableEdit(false);
                handleCloseSectionEdit();

                handleSnackbar("Updated successfully", Severity.success);
            }


            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const handleGetUserStoryList = async () => {
        try {
            setLoading(true);

            let body = {
                "folderPath": currentFolderData?.path,
                "projectName": activeProject?.name
            }

            const response = await getStoryList_API(body);
            console.log(response, "response handleGetUserStoryList")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                const foundItem = response.data.find(x => x.customId === currentUserStoryData?.customId);

                if (foundItem) {
                    setCurrentUserStoryData(foundItem);
                    setUserStoryData(foundItem);
                }

                setUserStoryList(response.data);
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const handleAddComment = async (bodyData) => {
        try {
            setLoading(true);

            let body = {
                ...bodyData,
            }


            const bodyFormData = new FormData();

            // Object.keys(body).forEach(key => bodyFormData.append(key, body[key]));
            Object.keys(body).forEach(key => {
                if (Array.isArray(body[key])) {
                    if (key === "attachments") {
                        body[key]?.forEach((file, index) => {
                            bodyFormData.append(`${key}[${index}]`, file, file.name);
                        })
                    } else {
                        bodyFormData.append(key, body[key]);
                    }

                } else {
                    bodyFormData.append(key, body[key]);
                }
            });

            //    formData.append("media", file, file.name);


            const response = await addCommentUserStory_API(bodyFormData);
            console.log(response, "response handleUpdateStory")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response?.status === HttpStatusCode.Ok) {
                // update in current user story data
                let list = userStoryData?.comments ? userStoryData?.comments : [];
                list.unshift(response.data);
                setUserStoryData({ ...userStoryData, comments: list });


                // update in user story list
                let completeList = [...userStoryList];
                const foundIndex = completeList?.findIndex(item => item?.customId === currentUserStoryData?.customId);
                if (foundIndex !== -1) {
                    completeList[foundIndex] = { ...currentUserStoryData, comments: list };
                }
                setUserStoryList(completeList);

                setCommentText("")
                handleSnackbar("Comment added successfully", Severity.success);
            }


            console.log(response, "projectreponse data")
        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    useEffect(() => {
        setUserStoryData(currentUserStoryData);
        setEditorValue(currentUserStoryData?.description);

        if (currentUserStoryData?.labels) {
            setSelectedLabels(currentUserStoryData?.labels);
        }
    }, [currentUserStoryData]);


    useEffect(() => {
        if (userStoryPropertiesData) {
            setTypeList(userStoryPropertiesData["type"]);
            setStateList(userStoryPropertiesData["state"]);
            setPriorityList(userStoryPropertiesData["priority"]);
            setMoscowList(userStoryPropertiesData["moscow"]);
            setConfidentialityList(userStoryPropertiesData["confidentiality"]);
        }

    }, [userStoryPropertiesData]);


    // useEffect(() => {
    //     if (enableEdit) {
    //         setLoading(true)

    //         handleGetStateList();
    //         handleGetTypeList();
    //         handleGetMOSCOWList();
    //         handleGetConfidentialityList();
    //         handleGetPriorityList();


    //         setTimeout(() => {
    //             setLoading(false)
    //         }, 400)
    //     }
    // }, [enableEdit]);



    console.log(userStoryData, "userStoryData", userStoryData?.name, userStoryData?.status);
    console.log(editorValue, "textvalue")
    console.log(selectedFiles, "selectedFiles")
    console.log(sections, "sections")
    console.log(selectedLabels, "selectedLabels")


    // if (loading) {
    //     return (
    //         <Box className="flex flex-col items-center justify-center gap-2 w-full h-full z-20 absolute left-0 top-0 overflow-hidden "
    //             // bgcolor={theme.palette.primary.layout_col}
    //             borderRadius={theme.shape.borderRadius}
    //             bgcolor={theme.palette.common.white}
    //         >
    //             <CircularProgress />
    //             <Typography fontSize={font.size.fs_4}>Loading...</Typography>
    //         </Box>
    //     )
    // }

    if (true) {
        return (
            <Box className="flex flex-col w-full h-full z-20 absolute left-0 top-0 overflow-hidden shadow-lg"
                // bgcolor={theme.palette.primary.layout_col}
                borderRadius={theme.shape.borderRadius}
                bgcolor={theme.palette.common.white}
            >

                {loading && <Box className="flex-1 flex flex-col items-center justify-center backdrop-blur-sm absolute left-0 top-0 right-0 bottom-0 z-10">
                    <CircularProgress />
                </Box>}

                <Box className={`flex flex-col  px-4 py-4 gap-2 w-full h-full relative rounded-lg overflow-hidden`} >

                    {/* top */}
                    <Box className="w-full flex items-center justify-between gap-4 ">
                        {/* left */}
                        <Box className="flex-1 flex items-center gap-2 w-full  pr-4 ">

                            <IconButton
                                size="small"
                                value="check"
                                // selected={enableEdit}
                                onChange={() => setEnableEdit((prevSelected) => !prevSelected)}
                                onClick={closeDialog}
                            >
                                <ArrowBack />
                            </IconButton>


                            <Box className="w-full flex flex-col ">
                                {/* custom Id */}
                                <SectionContainer enableEdit={enableEdit} currentSection={EditSection.customId} onClick={(e) => handleEnableSectionEdit(e, EditSection.customId)} dataAvailable={userStoryData?.customId}>
                                    {enableEdit && enableEdit === EditSection.customId &&
                                        <HeadingComponent heading="Custom ID" hideColon={enableEdit && enableEdit === EditSection.customId} />}

                                    {enableEdit && enableEdit === EditSection.customId ?
                                        <Box className="w-full flex flex-col gap-0">
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                name="customId"
                                                // label="Custom ID"
                                                required
                                                fullWidth
                                                // focused={userStoryData?.customId}
                                                // autoFocus
                                                value={userStoryData?.customId}
                                                onChange={handleChange}
                                            />
                                        </Box>
                                        :
                                        <Box className="flex items-center gap-1">
                                            <UserStoryCustomIcon sx={{ color: theme.palette.secondary.main }} />
                                            <ValueComponent color={theme.palette.secondary.main} text={currentUserStoryData?.customId} />
                                        </Box>
                                    }
                                </SectionContainer>


                                {/* Title */}
                                <SectionContainer enableEdit={enableEdit} currentSection={EditSection.title} onClick={(e) => handleEnableSectionEdit(e, EditSection.title)} dataAvailable={userStoryData?.title}>
                                    {enableEdit && enableEdit === EditSection.title &&
                                        <HeadingComponent heading="Title" hideColon={enableEdit && enableEdit === EditSection.title ? true : false} />}

                                    {enableEdit && enableEdit === EditSection.title ?
                                        <Box className="w-full flex flex-col gap-0">
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                name="title"
                                                required
                                                // label="Title"
                                                // autoFocus
                                                fullWidth
                                                multiline
                                                minRows={1}
                                                maxRows={2}
                                                // focused={userStoryData?.title}
                                                value={userStoryData?.title}
                                                onChange={handleChange}
                                            />
                                        </Box>
                                        :
                                        <Box className="flex flex-col gap-0">
                                            <Typography fontSize={font.size.fs_3_5} fontWeight={600} className="" sx={{ color: theme.palette.text.primary }}>{currentUserStoryData?.title}</Typography>
                                        </Box>
                                    }
                                </SectionContainer>
                            </Box>
                        </Box>

                        {/* right */}
                        <Box className=" w-[32%] flex items-center justify-end gap-2">

                            {/* <ToggleButton
                                value="check"
                                selected={false}
                                sx={{ color: isItemChatWindow && theme.palette.secondary.dark, background: isItemChatWindow && theme.palette.secondary.light }}
                                // onClick={handleToggleAIWindow}
                                onClick={handleOpenAIPopup}
                            >
                                <AutoAwesome />
                            </ToggleButton> */}

                            {false && <LightTooltip title="Generate Use Case" >
                                <IconButton color="secondary" onClick={() => handleToggleAIWindow(Entity_Label.useCase, AI_Entity.useCase)}>
                                    {currentAIEntityData?.entity === AI_Entity.useCase ? <Description /> : <DescriptionOutlined />}
                                </IconButton>
                            </LightTooltip>}

                            {false && <LightTooltip color="secondary" title="Generate Test Case">
                                <IconButton onClick={() => handleToggleAIWindow(Entity_Label.testCase, AI_Entity.testCase)}>
                                    {currentAIEntityData?.entity === AI_Entity.testCase ? <Description /> : <DescriptionOutlined />}
                                </IconButton>
                            </LightTooltip>}

                            <LightTooltip title={userStoryData?.generatedByAI ? "AI Generated" : "User Generated"}>
                                {userStoryData?.generatedByAI ? <AutoAwesome sx={{ color: theme.palette.ai.main }} /> : <Person sx={{ color: theme.palette.secondary.main }} />}
                            </LightTooltip>

                            <IconButton onClick={handleToggleMorePopup}>
                                <MoreHoriz />
                            </IconButton>

                            {false && <Button
                                size="medium"
                                variant={isItemChatWindow ? "contained" : "outlined"}
                                disableElevation
                                onClick={handleOpenAIPopup}
                                startIcon={<AutoAwesome />}
                            // endIcon={<KeyboardArrowDown />}
                            >
                                {isItemChatWindow && <Description sx={{ fontSize: font.size.fs_4 }} />}
                                {!isItemChatWindow ? "Generate" : currentAIEntityData?.label}
                            </Button>}


                            {false && <ToggleButton
                                size="small"
                                value="check"
                                // disabled={currentFolderData?.type !== FolderType.module}
                                selected={enableEdit}
                                onChange={() => setEnableEdit((prevSelected) => !prevSelected)}
                            >
                                <Edit />
                            </ToggleButton>}

                            {false && <ToggleButton
                                size="small"
                                value="check"
                                selected={false}
                                disabled={!enableEdit || !handleCheckValues()}
                                onClick={() => handleContinueStory(userStoryData)}
                            >
                                <Save />
                            </ToggleButton>}

                            {/* <IconButton
                                disabled={currentFolderData?.type !== FolderType.module || !handleCheckValues()}
                                onClick={() => handleContinueStory(userStoryData)}
                            >
                                <Save />
                            </IconButton> */}

                            {/* <ToggleButton
                                size="small"
                                value="check"
                                selected={enableEdit}
                                onChange={() => setEnableEdit((prevSelected) => !prevSelected)}
                                onClick={closeDialog}
                            >
                                <Close />
                            </ToggleButton> */}
                        </Box>
                    </Box>


                    {/* bottom */}
                    <Box className="w-full  flex gap-4 overflow-hidden ">
                        {/* left */}
                        <Box className={`flex-1 flex flex-col   gap-2  overflow-y-auto `}>


                            {/* menu */}
                            <Box className="w-full flex items-center mb-4 gap-4">
                                <Button size="small" variant="outlined" startIcon={<Attachment sx={{ transform: "rotate(90deg)" }} />} disabled={sections[EditSection.attachments]} onClick={() => handleToggleSection(EditSection.attachments)}>Attachment</Button>
                                <Button size="small" variant="outlined" startIcon={<PersonAddAltIcon />} disabled={sections[EditSection.members]} onClick={() => handleToggleSection(EditSection.members)}>Members</Button>
                                <Button size="small" variant="outlined" startIcon={<LabelIcon />} disabled={false} onClick={handleToggleLabelPopup}>Labels</Button>
                                <Button size="small" variant="outlined" startIcon={<ChecklistIcon />} disabled={sections[EditSection.checklist]} onClick={() => handleToggleSection(EditSection.checklist)}>Checklist</Button>
                                <IconButton sx={{ borderRadius: theme.shape.borderRadius }} onClick={handleToggleMenuPopup}>
                                    <MoreHoriz />
                                </IconButton>
                            </Box>


                            {/* main container */}
                            <Box className="flex flex-col gap-6">
                                {/* <TextField
                                size="small"
                                variant="outlined"
                                name="description"
                                // autoFocus
                                required
                                label="Description"
                                fullWidth
                                multiline
                                minRows={6}
                                maxRows={6}
                                // focused={userStoryData?.description}
                                value={userStoryData?.description}
                                onChange={handleChange}
                            /> */}


                                {userStoryData?.labels?.length > 0 && <SectionContainer enableEdit={enableEdit} currentSection={EditSection.label} dataAvailable={true}>
                                    <Box className="flex flex-wrap gap-2">
                                        {
                                            userStoryData?.labels?.map((label, index) => (
                                                <Box key={label?.name + index} className="flex items-center ">
                                                    <LabelBadge label={label} />
                                                </Box>
                                            ))
                                        }
                                    </Box>

                                </SectionContainer>}


                                {/* Description */}
                                <SectionContainer enableEdit={enableEdit} currentSection={EditSection.description} onClick={(e) => handleEnableSectionEdit(e, EditSection.description)} dataAvailable={userStoryData?.description}>
                                    <HeadingComponent heading="Description" hideColon={enableEdit && enableEdit === EditSection.description ? true : false} />
                                    {enableEdit && enableEdit === EditSection.description ?
                                        <Box className="flex flex-col gap-0">
                                            <QuillContainer>
                                                <ReactQuill
                                                    theme="snow"
                                                    value={userStoryData?.description}
                                                    onChange={(value) => setUserStoryData({ ...userStoryData, description: value })}
                                                    placeholder="Description"
                                                    modules={{
                                                        toolbar: [
                                                            [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                                            ['bold', 'italic', 'underline', 'strike'],
                                                            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                            // [{ 'indent': '-1' }, { 'indent': '+1' }],
                                                            // ['link', 'image', 'video'],
                                                            // ['blockquote', 'code-block'],
                                                            // [{ 'align': [] }],
                                                            // [{ 'color': [] }, { 'background': [] }],
                                                            // ['clean'],
                                                            // [CustomButton]
                                                            // [{ 'label': 'Add', 'icon': Add, 'onClick': handleAddAcceptanceCriteria }],

                                                        ],
                                                    }}
                                                />
                                            </QuillContainer>
                                        </Box>
                                        :
                                        <Box className="flex flex-col gap-0">
                                            {/* <Box>{convertEditorDataToReact(currentUserStoryData?.description)}</Box> */}
                                            {currentUserStoryData?.description ?
                                                <Box>
                                                    <ValueComponent text={convertEditorDataToReact(currentUserStoryData?.description)} />
                                                </Box>
                                                :
                                                <ValueComponent text={"None"} />
                                            }
                                        </Box>
                                    }
                                </SectionContainer>

                                {/* Acceptance Criteria */}
                                <SectionContainer enableEdit={enableEdit} currentSection={EditSection.acceptanceCriteria} onClick={(e) => handleEnableSectionEdit(e, EditSection.acceptanceCriteria)} dataAvailable={userStoryData?.acceptanceCriteria?.length > 0} >
                                    <HeadingComponent heading="Acceptance Criteria" hideColon={enableEdit && enableEdit === EditSection.acceptanceCriteria ? true : false} />
                                    {enableEdit && enableEdit === EditSection.acceptanceCriteria ?
                                        <Box className={`w-full flex flex-col gap-1 ${userStoryData?.acceptanceCriteria?.length > 0 ? "h-48" : ""}`}>
                                            {userStoryData?.acceptanceCriteria?.length > 0 ?
                                                userStoryData?.acceptanceCriteria?.map((currentStep, index) => (
                                                    <Box
                                                        key={index}
                                                        className="flex items-center gap-1  relative"
                                                    >
                                                        <LightTooltip title="Add Step below" >
                                                            <IconButton
                                                                onClick={() => handleAddRowBelow("acceptanceCriteria", index)}
                                                                sx={{ position: "relative", top: "6px" }}
                                                            >
                                                                <Add sx={{ fontSize: font.size.fs_5, color: blue[500] }} />
                                                            </IconButton>
                                                        </LightTooltip>

                                                        <Typography fontSize={font.size.fs_4} className="">{index + 1}.</Typography>

                                                        <Box
                                                            className="flex items-center gap-2 hover:cursor-grab border rounded-lg w-full"
                                                            draggable
                                                            onDragStart={(e) => handleDragStart("acceptanceCriteria", index, e)}
                                                            onDragOver={(e) => handleDragOver("acceptanceCriteria", e)}
                                                            onDrop={(e) => handleDrop("acceptanceCriteria", index, e)}
                                                        >


                                                            <IconButton>
                                                                <DragHandle />
                                                            </IconButton>

                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                placeholder="Criteria"
                                                                value={currentStep}
                                                                onChange={(e) => handleCurrentListItem("acceptanceCriteria", index, e.target.value)}
                                                                onKeyDown={e => e.key === "Enter" && handleAddRowBelow("acceptanceCriteria", index)}
                                                                sx={{
                                                                    px: 0,
                                                                    "& .MuiOutlinedInput-root": {
                                                                        fontSize: font.size.fs_5,
                                                                        "& fieldset": {
                                                                            borderRadius: 0,
                                                                            border: "none"
                                                                            // borderColor: theme.palette.common.black
                                                                        },
                                                                        "&:hover fieldset": {
                                                                            borderRadius: 0,
                                                                            border: "none"
                                                                        },
                                                                        "&.Mui-focused fieldset": {
                                                                            borderRadius: 0,
                                                                            border: "none"
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                            <IconButton color="error" onClick={() => handleRemoveListItem("acceptanceCriteria", index)}><Delete /></IconButton>
                                                        </Box>
                                                    </Box>
                                                ))
                                                :
                                                <Box className="flex items-center justify-center    bg-gray-100 " borderRadius={theme.shape.borderRadius} >
                                                    <Typography>Add</Typography>
                                                    <IconButton onClick={() => handleAddRowBelow("acceptanceCriteria", 0)}>
                                                        <Add />
                                                    </IconButton>
                                                </Box>

                                            }

                                        </Box>
                                        :
                                        <Box className={`w-full flex flex-col gap-1 `} >

                                            {userStoryData?.acceptanceCriteria?.length > 0 ? userStoryData?.acceptanceCriteria?.map((criteria, index) => (
                                                <Box key={index} className="flex items-center gap-2 rounded-full px-1 pl-4 py-0" >
                                                    <ValueComponent text={`${index + 1}.`} />
                                                    <ValueComponent text={criteria} />
                                                </Box>
                                            ))
                                                :
                                                <ValueComponent text={"None"} />
                                            }

                                        </Box>
                                    }
                                </SectionContainer>

                                {/* Dependencies */}
                                <SectionContainer enableEdit={enableEdit} currentSection={EditSection.dependencies} onClick={(e) => handleEnableSectionEdit(e, EditSection.dependencies)} dataAvailable={userStoryData?.dependencies?.length > 0} >
                                    <HeadingComponent heading="Dependencies" hideColon={enableEdit && enableEdit === EditSection.dependencies ? true : false} />
                                    {enableEdit && enableEdit === EditSection.dependencies ?
                                        <Box className={`w-full flex flex-col gap-1 ${userStoryData?.dependencies?.length > 0 ? "h-48" : ""}`}>
                                            {userStoryData?.dependencies?.length > 0 ?
                                                userStoryData?.dependencies?.map((currentStep, index) => (
                                                    <Box
                                                        key={index}
                                                        className="flex items-center gap-1  relative"
                                                    >
                                                        <LightTooltip title="Add Step below" >
                                                            <IconButton
                                                                onClick={() => handleAddRowBelow("dependencies", index)}
                                                                sx={{ position: "relative", top: "6px" }}
                                                            >
                                                                <Add sx={{ fontSize: font.size.fs_5, color: blue[500] }} />
                                                            </IconButton>
                                                        </LightTooltip>

                                                        <Typography fontSize={font.size.fs_4} className="">{index + 1}.</Typography>

                                                        <Box
                                                            className="flex items-center gap-2 hover:cursor-grab border rounded-lg w-full"
                                                            draggable
                                                            onDragStart={(e) => handleDragStart("dependencies", index, e)}
                                                            onDragOver={(e) => handleDragOver("dependencies", e)}
                                                            onDrop={(e) => handleDrop("dependencies", index, e)}
                                                        >


                                                            <IconButton>
                                                                <DragHandle />
                                                            </IconButton>

                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                placeholder="Description"
                                                                value={currentStep}
                                                                onChange={(e) => handleCurrentListItem("dependencies", index, e.target.value)}
                                                                onKeyDown={e => e.key === "Enter" && handleAddRowBelow("dependencies", index)}
                                                                sx={{
                                                                    px: 0,
                                                                    "& .MuiOutlinedInput-root": {
                                                                        fontSize: font.size.fs_5,
                                                                        "& fieldset": {
                                                                            borderRadius: 0,
                                                                            border: "none"
                                                                            // borderColor: theme.palette.common.black
                                                                        },
                                                                        "&:hover fieldset": {
                                                                            borderRadius: 0,
                                                                            border: "none"
                                                                        },
                                                                        "&.Mui-focused fieldset": {
                                                                            borderRadius: 0,
                                                                            border: "none"
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                            <IconButton color="error" onClick={() => handleRemoveListItem("dependencies", index)}><Delete /></IconButton>
                                                        </Box>
                                                    </Box>
                                                ))
                                                :
                                                <Box className="flex items-center justify-center    bg-gray-100 " borderRadius={theme.shape.borderRadius} >
                                                    <Typography>Add</Typography>
                                                    <IconButton onClick={() => handleAddRowBelow("dependencies", 0)}>
                                                        <Add />
                                                    </IconButton>
                                                </Box>

                                            }

                                        </Box>
                                        :
                                        <Box className={`w-full flex flex-col gap-1 `} >

                                            {userStoryData?.dependencies?.length > 0 ? userStoryData?.dependencies?.map((text, index) => (
                                                <Box key={index} className="flex items-center gap-2 rounded-full px-1 pl-4 py-0" >
                                                    <ValueComponent text={`${index + 1}.`} />
                                                    <ValueComponent text={text} />
                                                </Box>
                                            ))
                                                :
                                                <ValueComponent text={"None"} />
                                            }

                                        </Box>
                                    }
                                </SectionContainer>

                                {/* Checklist */}
                                <SectionContainer enableEdit={enableEdit} currentSection={EditSection.checklist} onClick={(e) => handleEnableSectionEdit(e, EditSection.checklist)} dataAvailable={userStoryData?.checklist?.length > 0} >
                                    <HeadingComponent heading="Checklist" hideColon={enableEdit && enableEdit === EditSection.checklist ? true : false} />
                                    {enableEdit && enableEdit === EditSection.checklist ?
                                        <Box className={`w-full flex flex-col gap-1 ${userStoryData?.checklist?.length > 0 ? "h-48" : ""}`}>
                                            {userStoryData?.checklist?.length > 0 ?
                                                userStoryData?.checklist?.map((currentStep, index) => (
                                                    <Box
                                                        key={index}
                                                        className="flex items-center gap-1  relative"
                                                    >
                                                        <LightTooltip title="Add Step below" >
                                                            <IconButton
                                                                onClick={() => handleAddRowBelow("checklist", index)}
                                                                sx={{ position: "relative", top: "6px" }}
                                                            >
                                                                <Add sx={{ fontSize: font.size.fs_5, color: blue[500] }} />
                                                            </IconButton>
                                                        </LightTooltip>

                                                        <Typography fontSize={font.size.fs_4} className="">{index + 1}.</Typography>

                                                        <Box
                                                            className="flex items-center gap-2 hover:cursor-grab border rounded-lg w-full"
                                                            draggable
                                                            onDragStart={(e) => handleDragStart("checklist", index, e)}
                                                            onDragOver={(e) => handleDragOver("checklist", e)}
                                                            onDrop={(e) => handleDrop("checklist", index, e)}
                                                        >


                                                            <IconButton>
                                                                <DragHandle />
                                                            </IconButton>

                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                placeholder="Description"
                                                                value={currentStep}
                                                                onChange={(e) => handleCurrentListItem("checklist", index, e.target.value)}
                                                                onKeyDown={e => e.key === "Enter" && handleAddRowBelow("checklist", index)}
                                                                sx={{
                                                                    px: 0,
                                                                    "& .MuiOutlinedInput-root": {
                                                                        fontSize: font.size.fs_5,
                                                                        "& fieldset": {
                                                                            borderRadius: 0,
                                                                            border: "none"
                                                                            // borderColor: theme.palette.common.black
                                                                        },
                                                                        "&:hover fieldset": {
                                                                            borderRadius: 0,
                                                                            border: "none"
                                                                        },
                                                                        "&.Mui-focused fieldset": {
                                                                            borderRadius: 0,
                                                                            border: "none"
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                            <IconButton color="error" onClick={() => handleRemoveListItem("checklist", index)}><Delete /></IconButton>
                                                        </Box>
                                                    </Box>
                                                ))
                                                :
                                                <Box className="flex items-center justify-center    bg-gray-100 " borderRadius={theme.shape.borderRadius} >
                                                    <Typography>Add</Typography>
                                                    <IconButton onClick={() => handleAddRowBelow("checklist", 0)}>
                                                        <Add />
                                                    </IconButton>
                                                </Box>

                                            }

                                        </Box>
                                        :
                                        <Box className={`w-full flex flex-col gap-1 `} >

                                            {userStoryData?.checklist?.length > 0 ? userStoryData?.checklist?.map((text, index) => (
                                                <Box key={index} className="flex items-center gap-2 rounded-full px-1 pl-4 py-0" >
                                                    {/* <ValueComponent text={`${index + 1}.`} /> */}
                                                    <Checkbox size="small" />
                                                    <ValueComponent text={text} />
                                                </Box>
                                            ))
                                                :
                                                <ValueComponent text={"None"} />
                                            }

                                        </Box>
                                    }
                                </SectionContainer>

                                {/* members */}
                                <SectionContainer enableEdit={enableEdit} currentSection={EditSection.members} dataAvailable={userStoryData?.members?.length > 0} >
                                    <Box className="flex items-center justify-between">
                                        <HeadingComponent heading="Members" hideColon={enableEdit && enableEdit === EditSection.activity ? true : false} />
                                        <IconButton>
                                            <Add />
                                        </IconButton>
                                    </Box>
                                    <Box>
                                        {enableEdit && enableEdit === EditSection.activity ?
                                            <Box className="flex flex-col gap-0">
                                                activity edit
                                            </Box>
                                            :
                                            <Box className="flex flex-col gap-2">
                                                {false ?
                                                    [{ name: "User Name", role: "Developer" }, { name: "User Name", role: "Developer" }]?.map((item, index) => (
                                                        <Box key={index} className="flex flex-col gap-0">
                                                            <Box key={index} className="flex items-center gap-2  px-1 py-1" sx={{ "&:hover": { background: theme.palette.highlight.main } }} borderRadius={theme.shape.borderRadius}>
                                                                <Avatar sx={{ width: "2rem", height: "2rem", fontSize: font.size.fs_5 }} >{item?.name[0].toUpperCase() + (item?.name?.split(" ")[1] ? item?.name?.split(" ")[1][0].toUpperCase() : "")}</Avatar>
                                                                <Box className="flex-1 flex flex-col gap-0">
                                                                    {/* <Chip size="small" label="User Name" /> */}
                                                                    <ValueComponent text={item?.name} />
                                                                    <ValueComponent text={item?.role} />
                                                                </Box>
                                                                <IconButton>
                                                                    <Delete sx={{ color: red[500] }} />
                                                                </IconButton>

                                                            </Box>
                                                        </Box>
                                                    ))
                                                    :
                                                    <ValueComponent text={"None"} />
                                                }
                                            </Box>
                                        }
                                    </Box>
                                </SectionContainer>

                                {/* comments and activity */}
                                <Box className="flex flex-col gap-2">
                                    <Box className="flex gap-2">
                                        <Button
                                            size="small"
                                            color="primary"
                                            variant={activityType === "comments" ? "outlined" : "text"}
                                            onClick={() => setActivityType("comments")}
                                            className="px-4"
                                        >
                                            Comments
                                        </Button>
                                        <Button
                                            size="small"
                                            color="primary"
                                            variant={activityType === "history" ? "outlined" : "text"}
                                            onClick={() => setActivityType("history")}
                                            className="px-4"
                                        >
                                            Activity
                                        </Button>
                                    </Box>

                                    {/* Comments */}
                                    {activityType === "comments" &&
                                        <SectionContainer enableEdit={enableEdit} currentSection={EditSection.comments} dataAvailable={true}>
                                            {/* <HeadingComponent heading="Comments" hideColon={enableEdit && enableEdit === EditSection.comments ? true : false} /> */}

                                            {/* comment box */}
                                            <Box className="flex gap-1">
                                                {/* <TextField fullWidth placeholder="Add a comment" multiline maxRows={2} /> */}
                                                <Box className="w-full flex flex-col gap-0 "
                                                    sx={{
                                                        height: "131px", // set the height of the Box component to 100%
                                                        overflowY: "auto",
                                                        "& .ql-container": {
                                                            // height: "40px",
                                                            maxHeight: "80px",
                                                            // overflowY: "auto"
                                                        }
                                                    }} >

                                                    <ReactQuill
                                                        theme="snow"
                                                        // styles={{
                                                        //     height: '80px', // set the height of the editor
                                                        // }}

                                                        value={commentText}
                                                        onChange={(value) => setCommentText(value)}
                                                        placeholder="Add a comment"
                                                        modules={{
                                                            toolbar: [
                                                                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                                                ['bold', 'italic', 'underline', 'strike'],
                                                                [{ 'list': 'ordered' }, { 'list': 'bullet' }],

                                                            ],

                                                        }}
                                                    />

                                                </Box>
                                                <Box className=" flex flex-col gap-0 ">
                                                    <IconButton onClick={handleContinueComment}>
                                                        <Send />
                                                    </IconButton>
                                                    <IconButton>
                                                        <Attachment sx={{ transform: "rotate(90deg)" }} />
                                                    </IconButton>
                                                </Box>


                                            </Box>

                                            {/* list of comment */}
                                            {enableEdit && enableEdit === EditSection.comments ?
                                                <Box className="flex flex-col gap-0">
                                                    comments edit
                                                </Box>
                                                :
                                                <Box className="flex flex-col gap-2 w-full ">
                                                    {
                                                        userStoryData?.comments?.reverse()?.map((comment, index) => (
                                                            <Box key={index} className="flex flex-col gap-2 w-full ">
                                                                <CommentComponent comment={comment} />
                                                            </Box>
                                                        ))
                                                    }
                                                </Box>
                                            }
                                        </SectionContainer>}

                                    {/* History */}
                                    {activityType === "history" && <SectionContainer enableEdit={enableEdit} currentSection={EditSection.activity} dataAvailable={true}>
                                        {/* <HeadingComponent heading="Activity" hideColon={enableEdit && enableEdit === EditSection.activity ? true : false} /> */}

                                        {enableEdit && enableEdit === EditSection.activity ?
                                            <Box className="flex flex-col gap-0">
                                                activity edit
                                            </Box>
                                            :
                                            <Box className="flex flex-col gap-2">
                                                {
                                                    [{ text: "This is activity 1", attachment: [] }, { text: "This is activity 2", attachment: [] }].map((item, index) => (
                                                        <Box key={index} className="flex flex-col gap-0">
                                                            <Box key={index} className="flex items-center gap-2">
                                                                <Avatar sx={{ width: "2rem", height: "2rem", fontSize: font.size.fs_5 }} />
                                                                <Box className="flex flex-col gap-1">
                                                                    <Box className="flex items-center gap-2">
                                                                        <Chip size="small" label="User Name" />
                                                                        <Typography fontSize={font.size.fs_5} className="opacity-80">30 Jan 2025 02:00PM</Typography>
                                                                    </Box>
                                                                    <ValueComponent text={item?.text} />
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    ))
                                                }
                                            </Box>
                                        }
                                    </SectionContainer>}
                                </Box>

                                {/* Attachment */}
                                {false && <SectionContainer  >
                                    <Box className="flex  items-center justify-between gap-0">
                                        <HeadingComponent heading="Attachment" hideColon={enableEdit && enableEdit === EditSection.attachments ? true : false} />

                                        <IconButton size="small" onClick={() => fileInputRef.current.click()} >
                                            <Attachment sx={{ transform: "rotate(90deg)" }} />
                                        </IconButton>

                                        <input
                                            ref={fileInputRef}
                                            type="file"
                                            multiple
                                            accept="image/*"
                                            onChange={handleChangeFiles}
                                            style={{ display: "none" }}
                                        />

                                    </Box>


                                    {enableEdit && enableEdit === EditSection.attachments
                                        ?
                                        <Box className="w-full flex flex-col justify-between gap-1">
                                            {/* uploaded attachments list */}
                                            {selectedFiles?.length > 0 &&
                                                <Box className="flex-1 flex items-center  gap-4 border overflow-x-auto">
                                                    {selectedFiles?.map((file, index) => (
                                                        <Box key={index} className="w-44 h-44 rounded-md shadow relative">
                                                            <img src={URL.createObjectURL(file)} alt={file.name} className="object-fill w-full h-full overflow-hidden" />

                                                            <IconButton onClick={() => setSelectedFiles(selectedFiles.filter((_, i) => i !== index))} sx={{ position: "absolute", top: 0, right: 0 }}>
                                                                <Close />
                                                            </IconButton>
                                                        </Box>
                                                    ))}
                                                </Box>}
                                        </Box>
                                        :
                                        <Box className="w-full flex flex-col justify-between gap-1">

                                            {/* preview of the selected attachement */}
                                            {selectedFiles?.length === 0 ?
                                                <Box className="flex items-center gap-4">
                                                    <ValueComponent text={"None"} />
                                                </Box>
                                                :
                                                <Box className="flex items-center gap-4">

                                                    {selectedFiles?.length > 0 &&
                                                        <Box className="flex-1 flex items-center flex-wrap gap-4 border-2 border-dashed border-gray-400 p-2 rounded-lg">
                                                            {selectedFiles?.map((file, index) => (
                                                                <Box key={index} className="w-12 h-12 rounded-md shadow relative">
                                                                    <img src={URL.createObjectURL(file)} alt={file?.name} className="object-fill w-full h-full overflow-hidden" />

                                                                    <IconButton onClick={() => setSelectedFiles(selectedFiles.filter((_, i) => i !== index))} sx={{ position: "absolute", top: -5, right: -5 }}>
                                                                        <Close sx={{ fontSize: font.size.fs_6 }} />
                                                                    </IconButton>
                                                                </Box>
                                                            ))}
                                                        </Box>}

                                                    <IconButton >
                                                        <Upload />
                                                    </IconButton>

                                                </Box>}

                                            {/* uploaded attachments list */}
                                            {selectedFiles?.length > 0 &&
                                                <Box className="flex-1 flex items-center  gap-4 border overflow-x-auto">
                                                    {selectedFiles?.map((file, index) => (
                                                        <Box key={index} className="w-44 h-44 rounded-md shadow relative">
                                                            <img src={URL.createObjectURL(file)} alt={file?.name} className="object-fill w-full h-full overflow-hidden" />

                                                            <IconButton onClick={() => setSelectedFiles(selectedFiles.filter((_, i) => i !== index))} sx={{ position: "absolute", top: 0, right: 0 }}>
                                                                <Close />
                                                            </IconButton>
                                                        </Box>
                                                    ))}
                                                </Box>}

                                        </Box>

                                    }
                                </SectionContainer>}


                            </Box>
                        </Box>



                        {/* right */}
                        <Box className={`w-[32%] h-full flex flex-col box-border gap-4 overflow-y-auto sm:absolute sm:right-0 sm:top-0  lg:relative `}>

                            {/* main container */}
                            <Box className={`flex flex-col gap-2 `}>

                                {/* other fields */}
                                <AccordionComponent
                                    expanded={expandedAccordion === AccordionName.otherFields}
                                    onChange={handleChangeAccordion(AccordionName.otherFields)}
                                >
                                    <AccordionComponentSummary expandIcon={<ExpandMore />}    >
                                        <Box className="flex items-center gap-2 w-full">
                                            <HeadingComponent heading="Other Fields" />
                                            {expandedAccordion !== AccordionName.otherFields &&
                                                <Box className="flex-1 flex items-center gap-0 flex-wrap line-clamp-2  overflow-hidden  ">
                                                    {
                                                        ["Type", "MOSCOW", "Business Value", "User Value", "Priority", "State", "Confidentiality"]?.map((item, index, arr) => <Box className="flex items-center mr-2">
                                                            <Typography key={index} fontSize={font.size.fs_5} className="" >{`${item}`} </Typography>
                                                            {index < arr.length - 1 && <Typography key={index} fontSize={font.size.fs_5} className="" >,</Typography>}
                                                        </Box>)
                                                    }
                                                </Box>}
                                        </Box>
                                    </AccordionComponentSummary>

                                    <AccordionComponentDetails>

                                        <AccordionDetailsContainer>

                                            <SectionContainer enableEdit={enableEdit} currentSection={EditSection.priority} onClick={(e) => handleEnableSectionEdit(e, EditSection.priority)} dataAvailable={userStoryData?.priority}>
                                                <HeadingComponent heading="Priority" />
                                                {enableEdit && enableEdit === EditSection.priority ?
                                                    <KeyValuePairComponent
                                                        // heading={"Priority"}
                                                        value={
                                                            <StyledSelect
                                                                size="small"
                                                                variant="outlined"
                                                                name="priority"
                                                                fullWidth
                                                                // disabled={!userStoryData?.status}
                                                                value={userStoryData?.priority || ''}
                                                                onChange={handleChange}
                                                            >
                                                                {
                                                                    priorityList?.map((item, index) => (
                                                                        <MenuItem key={index} value={item?.key}>{item?.value}</MenuItem>
                                                                    ))
                                                                }
                                                            </StyledSelect>
                                                        }
                                                    />
                                                    :
                                                    <Box className="flex flex-col gap-0">

                                                        <Typography fontSize={font.size.fs_4} className="" > {userStoryData?.priority || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>

                                            <SectionContainer enableEdit={enableEdit} currentSection={EditSection.status} onClick={(e) => handleEnableSectionEdit(e, EditSection.status)} dataAvailable={userStoryData?.state}>
                                                <HeadingComponent heading=" State" />
                                                {enableEdit && enableEdit === EditSection.status ?
                                                    <KeyValuePairComponent
                                                        // heading={"Story State"}
                                                        value={
                                                            <StyledSelect
                                                                size="small"
                                                                variant="outlined"
                                                                name="state"
                                                                fullWidth
                                                                // disabled={!userStoryData?.state}
                                                                value={userStoryData?.state || ''}
                                                                onChange={handleChange}
                                                            >
                                                                {
                                                                    stateList?.map((item, index) => (
                                                                        <MenuItem key={index} value={item?.key}>{item?.value}</MenuItem>
                                                                    ))
                                                                }
                                                            </StyledSelect>
                                                        }
                                                    />
                                                    :
                                                    <Box className="w-full flex flex-col gap-0">

                                                        <Typography fontSize={font.size.fs_4} className="" > {userStoryData?.state || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>

                                            <SectionContainer enableEdit={enableEdit} currentSection={EditSection.storyType} onClick={(e) => handleEnableSectionEdit(e, EditSection.storyType)} dataAvailable={userStoryData?.type}>
                                                <HeadingComponent heading="Type" />

                                                {enableEdit && enableEdit === EditSection.storyType ?
                                                    <KeyValuePairComponent
                                                        // heading={"Story Type"}
                                                        value={<StyledSelect
                                                            size="small"
                                                            variant="outlined"
                                                            name="type"
                                                            fullWidth
                                                            // disabled={!userStoryData?.status}
                                                            displayEmpty
                                                            value={userStoryData?.type || ''}
                                                            onChange={handleChange}
                                                        >
                                                            {
                                                                typeList?.map((item, index) => (
                                                                    <MenuItem key={index} value={item?.key}>{item?.value}</MenuItem>
                                                                ))
                                                            }
                                                        </StyledSelect>} />
                                                    :
                                                    <Box className="flex flex-col gap-0">
                                                        {/* <HeadingComponent heading="Story Type" /> */}
                                                        <Typography fontSize={font.size.fs_4} className="" > {userStoryData?.type || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>

                                            <SectionContainer enableEdit={enableEdit} currentSection={EditSection.moscow} onClick={(e) => handleEnableSectionEdit(e, EditSection.moscow)} dataAvailable={userStoryData?.moscow}>
                                                <HeadingComponent heading="MOSCOW" />
                                                {enableEdit && enableEdit === EditSection.moscow ?
                                                    <KeyValuePairComponent
                                                        // heading={"MOSCOW"}
                                                        value={<StyledSelect
                                                            size="small"
                                                            variant="outlined"
                                                            name="moscow"
                                                            fullWidth
                                                            // disabled={!userStoryData?.status}
                                                            value={userStoryData?.moscow || ''}
                                                            onChange={handleChange}
                                                        // open={true}
                                                        >
                                                            {
                                                                moscowList?.map((item, index) => (
                                                                    <MenuItem selected={userStoryData?.moscow ? userStoryData?.moscow === item?.key : index === 0} key={index} value={item?.key}>{item?.value}</MenuItem>
                                                                ))
                                                            }
                                                        </StyledSelect>} />
                                                    :
                                                    <Box className="flex flex-col gap-0">
                                                        <Typography fontSize={font.size.fs_4} className="" > {userStoryData?.moscow || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>

                                            <SectionContainer enableEdit={enableEdit} currentSection={EditSection.confidenciality} onClick={(e) => handleEnableSectionEdit(e, EditSection.confidenciality)} dataAvailable={userStoryData?.confidenciality}>
                                                <HeadingComponent heading="Confidentiality" />
                                                {enableEdit && enableEdit === EditSection.confidenciality ?
                                                    <KeyValuePairComponent
                                                        // heading={"Story State"}
                                                        value={
                                                            <StyledSelect
                                                                size="small"
                                                                variant="outlined"
                                                                name="confidenciality"
                                                                fullWidth
                                                                // disabled={!userStoryData?.state}
                                                                value={userStoryData?.confidenciality || ''}
                                                                onChange={handleChange}
                                                            >
                                                                {
                                                                    confidentialityList?.map((item, index) => (
                                                                        <MenuItem key={index} value={item?.key}>{item?.value}</MenuItem>
                                                                    ))
                                                                }
                                                            </StyledSelect>
                                                        }
                                                    />
                                                    :
                                                    <Box className="w-full flex flex-col gap-0">

                                                        <Typography fontSize={font.size.fs_4} className="" > {userStoryData?.confidenciality || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>

                                            <SectionContainer enableEdit={enableEdit} currentSection={EditSection.businessValue} onClick={(e) => handleEnableSectionEdit(e, EditSection.businessValue)} dataAvailable={userStoryData?.businessValue}>
                                                <HeadingComponent heading="Business Value" />

                                                {enableEdit && enableEdit === EditSection.businessValue ?
                                                    <KeyValuePairComponent
                                                        // heading={"Business Value"}
                                                        value={<TextField fullWidth value={userStoryData?.businessValue} name="businessValue" onChange={handleChange} />}
                                                    />
                                                    :
                                                    <Box className="flex flex-col gap-0">
                                                        <Typography fontSize={font.size.fs_4} className="" > {userStoryData?.businessValue || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>

                                            <SectionContainer enableEdit={enableEdit} currentSection={EditSection.userValue} onClick={(e) => handleEnableSectionEdit(e, EditSection.userValue)} dataAvailable={userStoryData?.userValue}>
                                                <HeadingComponent heading="User Value" />

                                                {enableEdit && enableEdit === EditSection.userValue ?
                                                    <KeyValuePairComponent
                                                        // heading={"Business Value"}
                                                        value={<TextField fullWidth value={userStoryData?.userValue} name="userValue" onChange={handleChange} />}
                                                    />
                                                    :
                                                    <Box className="flex flex-col gap-0">
                                                        <Typography fontSize={font.size.fs_4} className="" > {userStoryData?.userValue || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>

                                            {false && <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.userPoints)}>
                                                {enableEdit && enableEdit === EditSection.userPoints ?
                                                    <KeyValuePairComponent heading={"User Points"} value={<KeyValuePairComponent value={"Value"} />} />
                                                    :
                                                    <Box className="flex flex-col gap-0">
                                                        <HeadingComponent heading="User Points" />
                                                        <Typography fontSize={font.size.fs_4} className="" > {userStoryData?.priority || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>}

                                            {false && <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.confidenciality)}>
                                                {enableEdit && enableEdit === EditSection.confidenciality ?
                                                    <KeyValuePairComponent heading={"Confidenciality"} value={<StyledSelect
                                                        size="small"
                                                        variant="outlined"
                                                        name="storyType"
                                                        fullWidth
                                                    // disabled={!userStoryData?.status}
                                                    // value={userStoryData?.status || ''}
                                                    // onChange={handleChange}
                                                    >
                                                        {
                                                            ["Feature", "Epic", "Theme", "Story"]?.map((status, index) => (
                                                                <MenuItem key={index} value={status}>{status}</MenuItem>
                                                            ))
                                                        }
                                                    </StyledSelect>} />
                                                    :
                                                    <Box className="flex flex-col gap-0">
                                                        <HeadingComponent heading="Confidenciality" />
                                                        <Typography fontSize={font.size.fs_4} className="" > {userStoryData?.priority || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>}

                                            {false && <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.verificationMethod)}>
                                                {enableEdit && enableEdit === EditSection.verificationMethod ?
                                                    <KeyValuePairComponent heading={"Verification Methods"} value={<StyledSelect
                                                        size="small"
                                                        variant="outlined"
                                                        name="storyType"
                                                        fullWidth
                                                    // disabled={!userStoryData?.status}
                                                    // value={userStoryData?.status || ''}
                                                    // onChange={handleChange}
                                                    >
                                                        {
                                                            ["Testing", "Inspection", "Demo", "Analysis"]?.map((status, index) => (
                                                                <MenuItem key={index} value={status}>{status}</MenuItem>
                                                            ))
                                                        }
                                                    </StyledSelect>} />
                                                    :
                                                    <Box className="flex flex-col gap-0">
                                                        <HeadingComponent heading="Verification Methods" />
                                                        <Typography fontSize={font.size.fs_4} className="" > {userStoryData?.priority || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>}

                                            {false && <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.userPersona)}>
                                                {enableEdit && enableEdit === EditSection.userPersona ?
                                                    <KeyValuePairComponent heading={"User Persona"} value={<KeyValuePairComponent value={"Value"} />} />
                                                    :
                                                    <Box className="flex flex-col gap-0">
                                                        <HeadingComponent heading="User Persona" />
                                                        <Typography fontSize={font.size.fs_4} className="" > {userStoryData?.priority || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>}

                                        </AccordionDetailsContainer>
                                    </AccordionComponentDetails>

                                </AccordionComponent>

                                {/* details */}
                                <AccordionComponent
                                    expanded={expandedAccordion === AccordionName.details}
                                    onChange={handleChangeAccordion(AccordionName.details)}
                                >
                                    <AccordionComponentSummary expandIcon={<ExpandMore />}    >
                                        <Box className="flex items-center gap-2 w-full">
                                            <HeadingComponent heading="Details" />
                                            {expandedAccordion !== AccordionName.details &&
                                                <Box className="flex-1 flex items-center gap-0 flex-wrap line-clamp-2  overflow-hidden  ">
                                                    {
                                                        ["Creation Date", "Created By", "Assigned To",]?.map((item, index, arr) => <Box className="flex items-center mr-2">
                                                            <Typography key={index} fontSize={font.size.fs_5} className="" >{`${item}`} </Typography>
                                                            {index < arr.length - 1 && <Typography key={index} fontSize={font.size.fs_5} className="" >,</Typography>}
                                                        </Box>)
                                                    }
                                                </Box>}
                                        </Box>
                                    </AccordionComponentSummary>

                                    <AccordionComponentDetails>
                                        <AccordionDetailsContainer>

                                            <SectionContainer enableEdit={enableEdit} currentSection={EditSection.createdBy} dataAvailable={userStoryData?.createdBy?.name}>
                                                <HeadingComponent heading="Created By" />
                                                {enableEdit && enableEdit === EditSection.createdBy ?
                                                    <KeyValuePairComponent
                                                        // heading={"Reporter"}
                                                        value={
                                                            <StyledSelect
                                                                size="small"
                                                                variant="outlined"
                                                                name="status"
                                                                fullWidth
                                                                // disabled={!userStoryData?.assignedTo}
                                                                value={userStoryData?.createdBy?.name || ''}
                                                                onChange={handleChange}
                                                            >
                                                                {
                                                                    ["Arun", "Ritesh", "Vikram", "Rajat"]?.map((status, index) => (
                                                                        <MenuItem key={index} value={status}>{status}</MenuItem>
                                                                    ))
                                                                }
                                                            </StyledSelect>
                                                        }
                                                    />
                                                    :
                                                    <Box className="flex flex-col gap-0">
                                                        <Typography fontSize={font.size.fs_4} className="" > {`${userStoryData?.createdBy?.name} - ${userStoryData?.createdBy?.email}` || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>

                                            <SectionContainer enableEdit={enableEdit} currentSection={EditSection.createdAt} dataAvailable={userStoryData?.createdAt}>
                                                <HeadingComponent heading="Creation Date" />
                                                {enableEdit && enableEdit === EditSection.createdAt ?
                                                    <KeyValuePairComponent
                                                        // heading={"Creation Date"}
                                                        value={
                                                            <StyledSelect
                                                                size="small"
                                                                variant="outlined"
                                                                name="status"
                                                                fullWidth
                                                            // disabled={!userStoryData?.assignedTo}
                                                            // value={userStoryData?.assignedTo || ''}
                                                            // onChange={handleChange}
                                                            >
                                                                {
                                                                    ["Arun", "Ritesh", "Vikram", "Rajat"]?.map((status, index) => (
                                                                        <MenuItem key={index} value={status}>{status}</MenuItem>
                                                                    ))
                                                                }
                                                            </StyledSelect>
                                                        }
                                                    />
                                                    :
                                                    <Box className="flex flex-col gap-0">
                                                        <Typography fontSize={font.size.fs_4} className="" > {formatDateTime(userStoryData?.createdAt) || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>

                                            <SectionContainer enableEdit={enableEdit} currentSection={EditSection.assignedTo} onClick={(e) => handleEnableSectionEdit(e, EditSection.assignedTo)} dataAvailable={userStoryData?.assignedTo}>
                                                <HeadingComponent heading="Assigned To" />
                                                {enableEdit && enableEdit === EditSection.assignedTo ?
                                                    <KeyValuePairComponent
                                                        // heading={"Reporter"}
                                                        value={
                                                            <StyledSelect
                                                                size="small"
                                                                variant="outlined"
                                                                name="status"
                                                                fullWidth
                                                                // disabled={!userStoryData?.assignedTo}
                                                                value={userStoryData?.assignedTo || ''}
                                                                onChange={handleChange}
                                                            >
                                                                {
                                                                    ["Arun", "Ritesh", "Vikram", "Rajat"]?.map((status, index) => (
                                                                        <MenuItem key={index} value={status}>{status}</MenuItem>
                                                                    ))
                                                                }
                                                            </StyledSelect>
                                                        }
                                                    />
                                                    :
                                                    <Box className="flex flex-col gap-0">

                                                        <Typography fontSize={font.size.fs_4} className="" > {userStoryData?.assignedTo || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>

                                            {sections[EditSection.label] && <SectionContainer enableEdit={enableEdit} currentSection={EditSection.label} onClick={(e) => handleEnableSectionEdit(e, EditSection.label)} dataAvailable={userStoryData?.label}>
                                                {enableEdit && enableEdit === EditSection.label ?
                                                    <KeyValuePairComponent
                                                        heading={"Label"}
                                                        value={
                                                            <Box className="flex items-center gap-2">
                                                                {
                                                                    ["green", "red", "yellow", "blue"]?.map((label, index) => <LabelIcon sx={{ color: label, }} />)
                                                                }
                                                            </Box>
                                                        }
                                                    />
                                                    :
                                                    <Box className="w-full flex flex-col gap-0">
                                                        <HeadingComponent heading="Label" />
                                                        <Typography fontSize={font.size.fs_4} className="" > {userStoryData?.status || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>}

                                            {false && <SectionContainer enableEdit={enableEdit} currentSection={EditSection.owner} onClick={(e) => handleEnableSectionEdit(e, EditSection.owner)} dataAvailable={userStoryData?.owner}>
                                                <HeadingComponent heading="Owner" />
                                                {enableEdit && enableEdit === EditSection.owner ?
                                                    <KeyValuePairComponent
                                                        // heading={"Owner"}
                                                        value={
                                                            <StyledSelect
                                                                size="small"
                                                                variant="outlined"
                                                                name="status"
                                                                fullWidth
                                                            // disabled={!userStoryData?.assignedTo}
                                                            // value={userStoryData?.assignedTo || ''}
                                                            // onChange={handleChange}
                                                            >
                                                                {
                                                                    ["Arun", "Ritesh", "Vikram", "Rajat"]?.map((status, index) => (
                                                                        <MenuItem key={index} value={status}>{status}</MenuItem>
                                                                    ))
                                                                }
                                                            </StyledSelect>
                                                        }
                                                    />
                                                    :
                                                    <Box className="flex flex-col gap-0">
                                                        <Typography fontSize={font.size.fs_4} className="" > {userStoryData?.owner || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>}

                                            {false && <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.plannedInRelease)}>
                                                {enableEdit && enableEdit === EditSection.plannedInRelease ?
                                                    <KeyValuePairComponent
                                                        heading={"Planned in Release"}
                                                        value={<StyledSelect
                                                            size="small"
                                                            variant="outlined"
                                                            name="storyType"
                                                            fullWidth
                                                        // disabled={!userStoryData?.status}
                                                        // value={userStoryData?.status || ''}
                                                        // onChange={handleChange}
                                                        >
                                                            {
                                                                ["Feature", "Epic", "Theme", "Story"]?.map((status, index) => (
                                                                    <MenuItem key={index} value={status}>{status}</MenuItem>
                                                                ))
                                                            }
                                                        </StyledSelect>} />
                                                    :
                                                    <Box className="flex flex-col gap-0">
                                                        <HeadingComponent heading="Planned in Release" />
                                                        <Typography fontSize={font.size.fs_4} className="" > {userStoryData?.priority || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>}

                                            {false && <SectionContainer onClick={(e) => handleEnableSectionEdit(e, EditSection.plannedInSprint)}>
                                                {enableEdit && enableEdit === EditSection.plannedInSprint ?
                                                    <KeyValuePairComponent heading={"Planned in Sprint"} value={<StyledSelect
                                                        size="small"
                                                        variant="outlined"
                                                        name="storyType"
                                                        fullWidth
                                                    // disabled={!userStoryData?.status}
                                                    // value={userStoryData?.status || ''}
                                                    // onChange={handleChange}
                                                    >
                                                        {
                                                            ["Feature", "Epic", "Theme", "Story"]?.map((status, index) => (
                                                                <MenuItem key={index} value={status}>{status}</MenuItem>
                                                            ))
                                                        }
                                                    </StyledSelect>} />
                                                    :
                                                    <Box className="flex flex-col gap-0">
                                                        <HeadingComponent heading="Planned in Sprint" />
                                                        <Typography fontSize={font.size.fs_4} className="" > {userStoryData?.priority || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>}


                                        </AccordionDetailsContainer>
                                    </AccordionComponentDetails>
                                </AccordionComponent>

                                {/* estimation/ story points */}
                                <AccordionComponent
                                    expanded={expandedAccordion === AccordionName.estimationStoryPoints}
                                    onChange={handleChangeAccordion(AccordionName.estimationStoryPoints)}
                                >
                                    <AccordionComponentSummary expandIcon={<ExpandMore />}    >
                                        <Box className="flex items-center gap-2 w-full">
                                            <HeadingComponent heading="Estimation /Story Points" />
                                            {expandedAccordion !== AccordionName.estimationStoryPoints &&
                                                <Box className="flex-1 flex items-center gap-0 flex-wrap line-clamp-2  overflow-hidden  ">
                                                    {
                                                        ["Planned Value", "Actual Value",]?.map((item, index, arr) => <Box className="flex items-center mr-2">
                                                            <Typography key={index} fontSize={font.size.fs_5} className="" >{`${item}`} </Typography>
                                                            {index < arr.length - 1 && <Typography key={index} fontSize={font.size.fs_5} className="" >,</Typography>}
                                                        </Box>)
                                                    }
                                                </Box>}
                                        </Box>
                                    </AccordionComponentSummary>
                                    <AccordionComponentDetails>

                                        <AccordionDetailsContainer>

                                            <SectionContainer enableEdit={enableEdit} currentSection={EditSection.storyPointPlanned} onClick={(e) => handleEnableSectionEdit(e, EditSection.storyPointPlanned)}>
                                                <HeadingComponent heading="Planned Value" />
                                                {enableEdit && enableEdit === EditSection.storyPointPlanned ?
                                                    <KeyValuePairComponent
                                                        //  heading={"Planned Value"} 
                                                        value={<TextField fullWidth value={userStoryData?.storyPointPlanned} name="storyPointPlanned" onChange={handleChange} />}
                                                    />
                                                    :
                                                    <Box className="flex flex-col gap-0">
                                                        <Typography fontSize={font.size.fs_4} className="" > {userStoryData?.storyPointPlanned || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>

                                            <SectionContainer enableEdit={enableEdit} currentSection={EditSection.storyPointActual} onClick={(e) => handleEnableSectionEdit(e, EditSection.storyPointActual)}>
                                                <HeadingComponent heading="Actual Value" />
                                                {enableEdit && enableEdit === EditSection.storyPointActual ?
                                                    <KeyValuePairComponent
                                                        //  heading={"Planned Value"} 
                                                        value={<TextField fullWidth value={userStoryData?.storyPointActual} name="storyPointActual" onChange={handleChange} />}
                                                    />
                                                    :
                                                    <Box className="flex flex-col gap-0">
                                                        <Typography fontSize={font.size.fs_4} className="" > {userStoryData?.storyPointActual || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>



                                        </AccordionDetailsContainer>
                                    </AccordionComponentDetails>
                                </AccordionComponent>


                                {/* Tag to  */}
                                <AccordionComponent
                                    expanded={expandedAccordion === AccordionName.tagTo}
                                    onChange={handleChangeAccordion(AccordionName.tagTo)}

                                >
                                    <AccordionSummary expandIcon={<ExpandMore />} disabled  >
                                        <Box className="flex items-center gap-2 w-full">
                                            <HeadingComponent heading="Tag To" />
                                            {expandedAccordion !== AccordionName.tagTo &&
                                                <Box className="flex-1 flex items-center gap-0 flex-wrap line-clamp-2  overflow-hidden  ">
                                                    {
                                                        ["Business Unit", "Vendor", "External Party",]?.map((item, index, arr) => <Box className="flex items-center mr-2">
                                                            <Typography key={index} fontSize={font.size.fs_5} className="" >{`${item}`} </Typography>
                                                            {index < arr.length - 1 && <Typography key={index} fontSize={font.size.fs_5} className="" >,</Typography>}
                                                        </Box>)
                                                    }
                                                </Box>}
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails>

                                        <AccordionDetailsContainer>
                                            <SectionContainer enableEdit={enableEdit} currentSection={EditSection.businessUnit} onClick={(e) => handleEnableSectionEdit(e, EditSection.businessUnit)}>
                                                {enableEdit && enableEdit === EditSection.businessUnit ?
                                                    <KeyValuePairComponent heading={"Bussiness Unit"} value={<KeyValuePairComponent value={"Value"} />} />
                                                    :
                                                    <Box className="flex flex-col gap-0">
                                                        <HeadingComponent heading="Bussiness Unit" />
                                                        <Typography fontSize={font.size.fs_4} className="" > {userStoryData?.priority || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>

                                            <SectionContainer enableEdit={enableEdit} currentSection={EditSection.vendor} onClick={(e) => handleEnableSectionEdit(e, EditSection.vendor)}>
                                                {enableEdit && enableEdit === EditSection.vendor ?
                                                    <KeyValuePairComponent heading={"Vendor"} value={<KeyValuePairComponent value={"Value"} />} />
                                                    :
                                                    <Box className="flex flex-col gap-0">
                                                        <HeadingComponent heading="Vendor" />
                                                        <Typography fontSize={font.size.fs_4} className="" > {userStoryData?.priority || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>

                                            <SectionContainer enableEdit={enableEdit} currentSection={EditSection.externalPoints} onClick={(e) => handleEnableSectionEdit(e, EditSection.externalPoints)}>
                                                {enableEdit && enableEdit === EditSection.externalPoints ?
                                                    <KeyValuePairComponent heading={"External Party"} value={<KeyValuePairComponent value={"Value"} />} />
                                                    :
                                                    <Box className="flex flex-col gap-0">
                                                        <HeadingComponent heading="External Party" />
                                                        <Typography fontSize={font.size.fs_4} className="" > {userStoryData?.priority || "None"} </Typography>
                                                    </Box>
                                                }
                                            </SectionContainer>
                                        </AccordionDetailsContainer>
                                    </AccordionDetails>
                                </AccordionComponent>

                                {/* Linked usecase  */}
                                <AccordionComponent
                                    expanded={expandedAccordion === AccordionName.linkedUseCase}
                                    onChange={handleChangeAccordion(AccordionName.linkedUseCase)}

                                >
                                    <AccordionSummary expandIcon={<ExpandMore />}   >
                                        <Box className="flex items-center gap-0 w-full">
                                            <HeadingComponent heading="Linked UseCase" className={""} />


                                            {expandedAccordion !== AccordionName.linkedUseCase &&
                                                <Box className="flex-1 flex items-center gap-1 ml-2  overflow-hidden  ">
                                                    <Typography fontSize={font.size.fs_5} className="" > {`${userStoryData?.useCases?.length === 0 ? `None` : userStoryData?.useCases?.length}`} </Typography>
                                                </Box>}


                                            <LightTooltip title="Generate using AI" >
                                                <IconButton onClick={() => handleToggleAIWindow(Entity_Label.useCase, AI_Entity.useCase)} sx={{ ml: "auto" }}>
                                                    {currentAIEntityData?.entity === AI_Entity.useCase ? <AutoAwesome sx={{ color: theme.palette.ai.main }} /> : <AutoAwesomeOutlined sx={{ color: theme.palette.ai.main }} />}
                                                </IconButton>
                                            </LightTooltip>
                                            <LightTooltip title="Link " >
                                                <IconButton onClick={() => handleOpenLinkTag(currentUserStoryData)}>
                                                    <LinkCustomIcon sx={{ color: theme.palette.text.ternary }} />
                                                </IconButton>
                                            </LightTooltip>
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails>

                                        <AccordionDetailsContainer>
                                            <SectionContainer enableEdit={enableEdit} currentSection={EditSection.linked}>

                                                <Box className="">

                                                    <Box className={`w-full  flex flex-col gap-1 `}>

                                                        {/* table */}
                                                        {!userStoryData?.useCases || userStoryData?.useCases?.length === 0 ?
                                                            <ValueComponent text={"None"} />
                                                            :
                                                            (
                                                                <Box className="flex flex-col  w-full h-52">
                                                                    <LinkedItemsTable list={userStoryData?.useCases} />
                                                                </Box>
                                                            )

                                                        }
                                                    </Box>


                                                </Box>
                                            </SectionContainer>

                                        </AccordionDetailsContainer>
                                    </AccordionDetails>

                                </AccordionComponent>





                                {/* Linked testcase  */}
                                <AccordionComponent
                                    expanded={expandedAccordion === AccordionName.linkedTestCase}
                                    onChange={handleChangeAccordion(AccordionName.linkedTestCase)}

                                >
                                    <AccordionSummary expandIcon={<ExpandMore />}   >
                                        <Box className="flex items-center  w-full">
                                            <HeadingComponent heading="Linked TestCase" className={""} />

                                            {expandedAccordion !== AccordionName.linkedTestCase &&
                                                <Box className="flex-1  flex items-center gap-1 ml-2  overflow-hidden  ">
                                                    <Typography fontSize={font.size.fs_5} className="" > {`${(userStoryData?.testCase || userStoryData?.testCases?.length > 0) ? userStoryData?.testCases?.length : `None`}`} </Typography>
                                                </Box>}

                                            <LightTooltip title="Generate using AI">
                                                <IconButton onClick={() => handleToggleAIWindow(Entity_Label.testCase, AI_Entity.testCase)} sx={{ ml: "auto" }}>
                                                    {currentAIEntityData?.entity === AI_Entity.testCase ? <AutoAwesome sx={{ color: theme.palette.ai.main }} /> : <AutoAwesomeOutlined sx={{ color: theme.palette.ai.main }} />}
                                                </IconButton>
                                            </LightTooltip>
                                            <LightTooltip title="Link ">
                                                <IconButton onClick={() => handleOpenLinkTag(currentUserStoryData)}>
                                                    <LinkCustomIcon sx={{ color: theme.palette.text.ternary }} />
                                                </IconButton>
                                            </LightTooltip>
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails>

                                        <AccordionDetailsContainer>
                                            <SectionContainer enableEdit={enableEdit} currentSection={EditSection.linkedTestCase}>

                                                <Box className="">
                                                    <Box className={`w-full  flex flex-col gap-1 `}>

                                                        {/* table */}
                                                        {!userStoryData?.testCases || userStoryData?.testCases?.length === 0 ?
                                                            <ValueComponent text={"None"} />
                                                            :
                                                            (
                                                                <Box className="flex flex-col  w-full h-52">
                                                                    <LinkedItemsTable list={userStoryData?.testCases} />
                                                                </Box>
                                                            )

                                                        }
                                                    </Box>
                                                </Box>
                                            </SectionContainer>
                                        </AccordionDetailsContainer>
                                    </AccordionDetails>
                                </AccordionComponent>

                                {/* Attachments  */}
                                <AccordionComponent
                                    expanded={expandedAccordion === AccordionName.attachments}
                                    onChange={handleChangeAccordion(AccordionName.attachments)}
                                >
                                    <AccordionSummary expandIcon={<ExpandMore />}   >
                                        <Box className="flex items-center  w-full">
                                            <HeadingComponent heading="Attachments" className={""} />

                                            {expandedAccordion !== AccordionName.attachments &&
                                                <Box className="flex-1  flex items-center gap-1 ml-2  overflow-hidden  ">
                                                    <Typography fontSize={font.size.fs_5} className="" > {`${(userStoryData?.attachments?.length > 0) ? userStoryData?.attachments?.length : `None`}`} </Typography>
                                                </Box>}

                                            <IconButton size="small" onClick={() => fileInputRef.current.click()} sx={{ ml: "auto" }}>
                                                <Attachment sx={{ transform: "rotate(90deg)" }} />
                                            </IconButton>

                                            <input
                                                ref={fileInputRef}
                                                type="file"
                                                multiple
                                                accept="*"
                                                onChange={handleChangeFiles}
                                                style={{ display: "none" }}
                                            />
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails>

                                        <AccordionDetailsContainer>
                                            <SectionContainer enableEdit={enableEdit} currentSection={EditSection.attachments}>


                                                <Box>
                                                    {enableEdit && enableEdit === EditSection.attachments
                                                        ?
                                                        <Box className="w-full flex flex-col justify-between gap-1">
                                                            {/* uploaded attachments list */}
                                                            {selectedFiles?.length > 0 &&
                                                                <Box className="flex-1 flex items-center  gap-4 border overflow-x-auto">
                                                                    {selectedFiles?.map((file, index) => (
                                                                        <Box key={index} className="w-44 h-44 rounded-md shadow relative">
                                                                            <img src={URL.createObjectURL(file)} alt={file.name} className="object-fill w-full h-full overflow-hidden" />

                                                                            <IconButton onClick={() => setSelectedFiles(selectedFiles.filter((_, i) => i !== index))} sx={{ position: "absolute", top: 0, right: 0 }}>
                                                                                <Close />
                                                                            </IconButton>
                                                                        </Box>
                                                                    ))}
                                                                </Box>}
                                                        </Box>
                                                        :
                                                        <Box className="w-full flex flex-col justify-between gap-1">

                                                            {/* preview of the selected attachement */}
                                                            {selectedFiles?.length === 0 ?
                                                                <Box className="flex items-center gap-4">
                                                                    {/* <ValueComponent text={"None"} /> */}
                                                                </Box>
                                                                :
                                                                <Box className="flex items-center gap-4">

                                                                    {selectedFiles?.length > 0 &&
                                                                        <Box className="flex-1 flex items-center flex-wrap gap-4 border-2 border-dashed border-gray-400 p-2 rounded-lg">
                                                                            {selectedFiles?.map((file, index) => (
                                                                                <Box key={index} className="w-12 h-12 rounded-md shadow relative">
                                                                                    <img src={URL.createObjectURL(file)} alt={file?.name} className="object-fill w-full h-full overflow-hidden" />

                                                                                    <IconButton onClick={() => setSelectedFiles(selectedFiles.filter((_, i) => i !== index))} sx={{ position: "absolute", top: -5, right: -5 }}>
                                                                                        <Close sx={{ fontSize: font.size.fs_6 }} />
                                                                                    </IconButton>
                                                                                </Box>
                                                                            ))}
                                                                        </Box>}

                                                                    <IconButton onClick={() => handleContinueStory(userStoryData)}>
                                                                        <Upload />
                                                                    </IconButton>


                                                                </Box>}

                                                            {/* uploaded attachments list */}
                                                            {userStoryData?.attachments?.length === 0 ?
                                                                <Box className="flex items-center gap-4">
                                                                    <ValueComponent text={"None"} />
                                                                </Box>
                                                                :
                                                                <Box className="flex-1 flex flex-wrap gap-4 px-1 py-1 overflow-y-auto">
                                                                    {userStoryData?.attachments?.map((file, index) => (
                                                                        <Box key={index} className="min-w-20 w-auto max-w-24 h-20  overflow-hidden relative" borderRadius={theme.shape.borderRadius} >
                                                                            <MediaAttachmentComponent data={file} handleRemove={handleRemoveAttachements} showDelete={true} showDownload={true} isOnClick={true} />
                                                                            {/* <img src={URL.createObjectURL(file)} alt={file?.name} className="object-fill w-full h-full overflow-hidden" /> */}

                                                                            {false && <IconButton color="error" onClick={() => handleRemoveAttachements(file)} sx={{ position: "absolute", top: 0, right: 0 }}>
                                                                                <DeleteOutlined />
                                                                            </IconButton>}
                                                                        </Box>
                                                                    ))}
                                                                </Box>}

                                                        </Box>

                                                    }
                                                </Box>
                                            </SectionContainer>
                                        </AccordionDetailsContainer>
                                    </AccordionDetails>
                                </AccordionComponent>

                            </Box>

                        </Box>
                    </Box>
                </Box>



                {/*  popup for current edit section */}
                <div style={{ pointerEvents: 'none' }}>
                    <Popover
                        id="edit-section"
                        open={Boolean(editAnchorEl)}
                        anchorEl={editAnchorEl}
                        hideBackdrop
                        disablePortal
                        disableEnforceFocus
                        disableAutoFocus
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        transformOrigin={{ vertical: "bottom", horizontal: "right" }}

                        slotProps={{
                            paper: {
                                sx: {
                                    backgroundColor: 'transparent',
                                    boxShadow: 'none'
                                }
                            }
                        }}
                    >
                        <div style={{ pointerEvents: 'auto' }}>
                            <Box className="flex-1 flex items-center gap-1 py-1">

                                <ToggleButton
                                    size="small"
                                    value="check"
                                    selected={false}
                                    disabled={!enableEdit || !handleCheckValues()}
                                    onClick={() => handleContinueStory(userStoryData)}

                                >
                                    <Save />
                                </ToggleButton>


                                <ToggleButton
                                    size="small"
                                    value="check"
                                    onClick={handleCloseSectionEdit}

                                >
                                    <Close />
                                </ToggleButton>

                            </Box>
                        </div>
                    </Popover>
                </div>

                {/* popup for current AI entity */}
                <Popover
                    id="AI-Entity"
                    open={Boolean(aiAnchorEl)}
                    anchorEl={aiAnchorEl}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                    onClose={handleCloseAIPopup}
                >
                    <MenuItem
                        selected={currentAIEntityData?.entity === AI_Entity.useCase}
                        onClick={() => handleToggleAIWindow(Entity_Label.useCase, AI_Entity.useCase)}
                        sx={{
                            color: currentAIEntityData?.entity === AI_Entity.useCase ? theme.palette.primary.main : "",
                            backgroundColor: currentAIEntityData?.entity?.toLowerCase() === AI_Entity.useCase?.toLowerCase() ? theme.palette.secondary.main : ""
                        }}
                    >
                        {/* <ListItemIcon>
                            <Description fontSize="small" />
                        </ListItemIcon> */}
                        {/* <ListItemText>{Entity_Label.useCase}</ListItemText> */}
                        {Entity_Label.useCase}
                    </MenuItem>

                    <MenuItem
                        selected={currentAIEntityData?.entity === AI_Entity.testCase}
                        onClick={() => handleToggleAIWindow(Entity_Label.testCase, AI_Entity.testCase)}
                        sx={{
                            color: currentAIEntityData?.entity === AI_Entity.testCase ? theme.palette.primary.main : "",
                            backgroundColor: currentAIEntityData?.entity?.toLowerCase() === AI_Entity.testCase?.toLowerCase() ? theme.palette.secondary.main : ""
                        }}
                    >
                        {/* <ListItemIcon>
                            <Description fontSize="small" />
                        </ListItemIcon> */}
                        {/* <ListItemText>{Entity_Label.testCase}</ListItemText> */}
                        {Entity_Label.testCase}
                    </MenuItem>

                </Popover>

                {/* menu items */}
                <Popover
                    id="Menu-items"
                    open={Boolean(menuAnchorEl)}
                    anchorEl={menuAnchorEl}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                    onClose={handleToggleMenuPopup}
                >
                    <Box>
                        <MenuItem onClick={() => handleToggleSection(EditSection.dueDate)}>Due Date</MenuItem>
                        {false && <MenuItem onClick={() => handleToggleSection(EditSection.userPersona)}>User Persona</MenuItem>}
                    </Box>
                </Popover>

                {/* more actions */}
                <Popover
                    id="more-actions"
                    open={Boolean(moreAnchorEl)}
                    anchorEl={moreAnchorEl}
                    // anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    // transformOrigin={{ vertical: "top", horizontal: "right" }}
                    onClose={handleToggleMorePopup}
                >
                    <Box>
                        <MenuItem onClick={() => { }}>Move</MenuItem>
                        <MenuItem onClick={() => { }}>Copy</MenuItem>
                        <MenuItem onClick={() => { }}>Delete</MenuItem>
                    </Box>
                </Popover>



                {/* label list */}
                <Popover
                    id="label-list"
                    open={Boolean(labelAnchorEl)}
                    anchorEl={labelAnchorEl}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                    onClose={handleToggleLabelPopup}
                >
                    <Box className="flex flex-col px-0 py-2">
                        <Box className="flex flex-col px-4 py-2 max-h-[40vh] overflow-y-auto">
                            {
                                labelList?.map((label, index) => (
                                    <MenuItem key={index} style={{ display: "flex", alignItems: "center", gap: 4 }} >
                                        <Checkbox checked={selectedLabels?.includes(label)} onChange={() => handleChangeLabel(label)} />
                                        <Label sx={{ color: label?.colorCode }} />
                                        {label?.name}
                                    </MenuItem>
                                ))
                            }
                        </Box>

                        <Box className="flex  px-4 py-2 border-t">
                            <Button size="small" fullWidth onClick={handleLabelUpdate}>Apply </Button>
                        </Box>


                    </Box>

                </Popover>



                {/* {editAnchorEl && <Box
                    style={{
                        position: "absolute",
                        // top: 0,
                        // left: 0,
                        // top: editAnchorEl.getBoundingClientRect().top ,
                        // left: editAnchorEl.getBoundingClientRect().right 
                        top: editAnchorEl.getBoundingClientRect().top + window.scrollY,
                        left: editAnchorEl.getBoundingClientRect().right + window.scrollX
                    }}
                >
                    element
                </Box>} */}
            </Box>
        )
    }

}



