import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import { HttpStatusCode } from 'axios';
import { login_API, requestOTP_API, verifyOTP_API } from '../../../api/controller/authController';
import { useApplicationContext } from '../../../context/ApplicationContext';
import useErrorHandler from '../../../api/errorHandler/useErrorHandler';
import { useLocation, useNavigate, } from 'react-router-dom';
import { ModulePath, Severity, VerificationType } from '../../../constant/ApplicationVariables';
import validator from "validator";
import { useState } from 'react';
import { useEffect } from 'react';
import { CircularProgress, IconButton, InputAdornment, useTheme } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import OtpInput from 'react-otp-input';
import { font } from '../../../styles/font';
import MainContainer, { AuthCardComponent } from '../container';

const Card = styled(MuiCard)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    width: '100%',
    py: theme.spacing(4),
    gap: theme.spacing(4),
    margin: 'auto',
    borderRadius: theme.shape.borderRadius,
    // background: theme.palette.background.paper,
    background: "transparent",
    boxShadow: "none",
    [theme.breakpoints.up('sm')]: {
        // maxWidth: '450px',
    },
    // boxShadow:
    //     'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
    // ...theme.applyStyles('dark', {
    //     boxShadow:
    //         'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
    // }),
}));


export default function VerifyAccountContainer() {
    const { globalSnackbar, setGLobalSnackBar, handleSnackbar, profileDetails, setProfileDetails, accountVerificationType, setAccountVerificationType, handleStoreUserData } = useApplicationContext();
    const errorHandler = useErrorHandler();
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();

    const [loginData, setLoginData] = useState(null);
    const [otp, setOtp] = useState('');
    const [error, setError] = useState(null);
    const [prevPageStateData, setPrevPageStateData] = useState(null);

    const [loading, setLoading] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent default form submission behavior
        validateInputs(); // Call your validation function
    };

    const handleChangeValue = (event) => {
        const { name, value } = event.target;

        setLoginData((prevData) => ({
            ...prevData,
            [name]: value
        }))
    }

    const validateInputs = () => {
        let isValid = true;
        const errorObject = {};

        if (!otp) {
            errorObject.otp = " Please enter a valid otp.";
            isValid = false;
        }

        setError(errorObject);

        return isValid && handleContinue();
    };

    const handleContinue = async () => {
        try {
            setLoading(true);
            let headers;

            let body = {
                otp: otp,
                email: prevPageStateData?.email,
                type: prevPageStateData?.type
            }

            if (prevPageStateData?.type === VerificationType.Account) {
                delete body.email;
                headers = {
                    Authorization: `Bearer ${localStorage.getItem("access-token")}`
                }
            }



            const response = await verifyOTP_API(body, headers);

            if (response) {
                errorHandler(response);
            }

            if (response.status === HttpStatusCode.Ok) {
                handleStoreUserData(response.data);

                if (prevPageStateData?.type === VerificationType.Account) {
                    setTimeout(() => {
                        navigate(ModulePath.projects);
                    }, 1100);

                }

                if (prevPageStateData?.type === VerificationType.ForgotPassword) {
                    setTimeout(() => {
                        navigate(ModulePath.resetPassword, { state: { email: prevPageStateData?.email, type: VerificationType.ForgotPassword, fromPath: ModulePath.verifyAccount } });
                    }, 1100);
                }

                handleSnackbar("Verified successfully", Severity.success)
            }
        } catch (error) {
            console.log(error, error?.message, "error");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const handleRegenerateOTP = async () => {
        try {
            setLoading(true);
            let headers;

            let body = {
                // otp: "",
                email: prevPageStateData?.email,
                type: prevPageStateData?.type
            }

            if (prevPageStateData?.type === VerificationType.Account) {
                delete body.email
            }

            if (prevPageStateData?.type === VerificationType.Account) {
                delete body.email;
                headers = {
                    Authorization: `Bearer ${localStorage.getItem("access-token")}`
                }
            }


            const response = await requestOTP_API(body, headers);

            if (response) {
                errorHandler(response);
            }

            if (response.status === HttpStatusCode.Ok) {
                handleSnackbar(response.data?.message, Severity.success);
            }
        } catch (error) {
            console.log(error, error?.message, "login errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    console.log(navigate, location, "navigate,location");

    // to prevent this page from being accessed directly, only accessible from login, register, forgot-password page
    useEffect(() => {
        if (location.state) {
            let { email, type, fromPath } = location.state;
            if (fromPath !== ModulePath.login && fromPath !== ModulePath.register && fromPath !== ModulePath.forgotPassword) {
                navigate(ModulePath.login);
            } else {
                setPrevPageStateData({ email, type, fromPath });
            }
        } else {
            navigate(ModulePath.login);
        }
    }, [location, navigate]);


    return (
        <Box className=" flex flex-col gap-0 relative h-full w-full box-border overflow-hidden" >
            <MainContainer >
                <AuthCardComponent className="flex-1 gap-10 ">




                    {/* header container */}
                    <Box className="flex flex-col   ">

                        <Typography
                            sx={{ width: '100%', }}
                            color="textPrimary"
                            fontSize={font.size.fs_1_5}
                            fontWeight="bold"
                        >
                            Verify Account
                        </Typography>

                        <Box className="flex items-center gap-2">
                            <Typography color={theme.palette.text.ternary} fontSize={font.size.fs_5} className="flex items-center justify-center gap-1 ">
                                Back to login{' '}

                                <Typography color={theme.palette.text.primary} fontSize={"inherit"} fontWeight="bold" className="underline cursor-pointer " onClick={() => navigate(ModulePath.login)}>
                                    Click here
                                </Typography>

                            </Typography>
                        </Box>
                    </Box>

                    {/* form container */}
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        noValidate
                        className="flex flex-col w-full gap-10     "

                    >

                        {/* input container */}
                        <Box
                            className="flex flex-col w-full gap-6 py-0  "

                        >

                            <FormControl sx={{ width: '100%' }}>
                                {/* <FormLabel htmlFor="email">Email</FormLabel> */}
                                {/* <TextField
                                label="Email"
                                error={error?.email && error?.email}
                                helperText={error?.email && error?.email}
                                id="email"
                                type="email"
                                name="email"
                                placeholder="your@email.com"
                                autoComplete="email"
                                autoFocus
                                required
                                fullWidth
                                variant="outlined"
                                color={error?.email ? 'error' : 'primary'}
                                value={loginData?.email}
                                onChange={handleChangeValue}
                            /> */}

                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={6}
                                    renderSeparator={<span>-</span>}
                                    renderInput={(props) =>
                                        <input
                                            {...props}
                                            style={{
                                                width: "40px",
                                                height: "40px",
                                                borderBottom: `1px solid ${theme.palette.text.secondary}`,
                                                borderRadius: `${theme.shape.borderRadius} ${theme.shape.borderRadius} 0 0`,
                                                fontSize: font.size.fs_4,
                                                textAlign: 'center',
                                                background: "#DFDFE1",
                                                "&:focus": {
                                                    outline: "none",
                                                    border: "none",
                                                    background: "#DFDFE1",
                                                    borderBottom: `1px solid ${theme.palette.text.primary}`,
                                                },

                                            }}

                                        />
                                    }
                                    containerStyle={{ justifyContent: "center", flexWrap: "wrap", gap: "0.5rem" }}
                                // inputType='number'
                                />
                            </FormControl>
                        </Box>
                        <Box className="flex flex-col gap-10">
                            <Button
                                size="medium"
                                type="submit"
                                fullWidth
                                color="secondary"
                                variant="contained"
                                disabled={loading}
                                onClick={validateInputs}
                                sx={{ color: '#fff' }}
                            >
                                {loading ? <CircularProgress size={20} /> : "Continue"}
                            </Button>


                            <Box className="flex items-center justify-center gap-2">
                                <Typography color={theme.palette.text.ternary} fontSize={font.size.fs_5} className="flex items-center justify-center gap-1 ">
                                    Resend OTP?{' '}

                                    <Typography color={theme.palette.text.primary} fontSize={"inherit"} fontWeight="bold" className="underline cursor-pointer " onClick={handleRegenerateOTP}>
                                        Click here
                                    </Typography>
                                </Typography>
                            </Box>
                        </Box>

                    </Box>


                </AuthCardComponent>
            </MainContainer>
        </Box>
    );
}