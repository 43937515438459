import { AddOutlined, Check, Close, DeleteOutline, Edit, EditNote, EditOutlined, FolderOutlined, FolderSpecialOutlined, FolderSpecialTwoTone, FolderTwoTone, KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { Box, IconButton, InputAdornment, ListItemIcon, ListItemText, MenuItem, Popover, TextField, Typography, useTheme } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { Tree, NodeApi } from 'react-arborist'
import { font } from '../../styles/font';
import { useApplicationContext } from '../../context/ApplicationContext';
import AutoSizer from 'react-virtualized-auto-sizer';
import { LightTooltip } from '../styled/CustomComponent';
import { Entity, FolderType, ModulePath, Severity } from '../../constant/ApplicationVariables';
import { useLocation } from 'react-router-dom';
import { HttpStatusCode } from 'axios';
import { updateModule_API } from '../../api/controller/ModuleController';
import useErrorHandler from '../../api/errorHandler/useErrorHandler';

export default function Treeview({ dataList }) {
    const { currentFolderData } = useApplicationContext();

    const [list, setList] = useState([]);

    console.log(dataList, "dataList");
    const treeRef = useRef(null);

    const openNode = (id) => {
        if (treeRef.current) {
            treeRef.current.open(id);
        }
    };

    const scrollToNode = (id) => {
        if (treeRef.current) {
            // treeRef.current.openParents(id)
            // treeRef.current.scrollTo(id);

            treeRef.current.openParents(id); // Open all parent nodes
            treeRef.current.scrollTo(id);
        }
    };

    useEffect(() => {
        if (currentFolderData) {
            scrollToNode(currentFolderData?.id);
        }
    }, [currentFolderData]);

    useEffect(() => {
        setList(dataList);
    }, [dataList]);

    return (
        <Box className="flex flex-1 px-0 w-full " onContextMenu={(e) => e.preventDefault()}>
            <AutoSizer>
                {({ height, width }) => {
                    console.log(height, width, "heightwidth")
                    return (
                        <Tree
                            ref={treeRef}
                            // initialData={list}
                            data={list}
                            openByDefault={false}
                            width={width}
                            height={height}
                            indent={24}
                            rowHeight={38}


                        // overscanCount={1}
                        // paddingTop={30}
                        // paddingBottom={10}
                        // padding={25 /* sets both */}
                        >
                            {Node}
                        </Tree>

                    )
                }}
            </AutoSizer>
        </Box>
    )
}


function Node({ node, style, dragHandle, }) {
    const {
        currentFolderData, setCurrentFolderData,
        folderStructureList, setFolderStructureList,
        isUpdateCurrentFolderData, setIsUpdateCurrentFolderData,
        activeProject,
        isUpdateFolderStructure, setIsUpdateFolderStructure,
        currentFolderAnchorEl, setCurrentFolderAnchorEl,
        openEditUseCaseDialog, setOpenEditUseCaseDialog,
        openEditStoryDialog, setOpenEditStoryDialog,
        currentUserStoryData,
        currentUseCaseData,
        currentEditFolderData, setCurrentEditFolderData,
        handleSnackbar
    } = useApplicationContext();
    const theme = useTheme();
    const pathName = useLocation()?.pathname;
    const errorHandler = useErrorHandler();

    const [isEditing, setIsEditing] = useState(false);  // State to track if the node is being edited
    const [editedNode, setEditedNode] = useState(node?.data);  // State to store the edited node
    const inputRef = useRef();

    // const [currentFolderAnchorEl, setCurrentFolderAnchorEl] = useState(null);

    const handleToggleFolderMenu = (event) => {

        if (currentFolderAnchorEl) {
            setCurrentFolderAnchorEl(null);
        } else {
            setCurrentFolderAnchorEl(event.currentTarget);
        }
    }


    const handleFolderClick = (folder) => {
        // folder.toggle();
        setCurrentFolderData(folder?.data);

        console.log(folder, "node1data");


        // when the folder changes close the usecase and story dialog
        if (true) {
            setOpenEditStoryDialog(false);
            setOpenEditUseCaseDialog(false);
        }

        // if (currentFolderData?.id !== folder?.id) {
        //     setOpenEditStoryDialog(false);
        //     setOpenEditUseCaseDialog(false);
        // }
    }

    const handleIconClick = (folder) => {
        folder.toggle();
        setCurrentFolderData(folder?.data);


        if (true) {
            setOpenEditStoryDialog(false);
            setOpenEditUseCaseDialog(false);
        }

        console.log(folder, "node2data");
    }

    const handleRightClick = (event, folder) => {
        event.preventDefault();
        console.log(folder, "folder rightclick");
        setCurrentFolderData(folder?.data);
        setCurrentFolderAnchorEl(event.currentTarget);

        // handleToggleFolderMenu(event);

        // when the folder changes close the usecase and story dialog
        setOpenEditStoryDialog(false);
        setOpenEditUseCaseDialog(false);

    }


    const handleCreate = (e) => {
        setIsEditing(true);
        setEditedNode(node?.data);
    }

    const handleEdit = (nodeData) => {
        setIsEditing(true);
        setEditedNode(nodeData?.data);
    }


    const handleCreateNode = (parentNode) => {
        const newNode = {
            "id": Date.now().toString(),
            name: editedNode?.name,
            "path": parentNode?.data?.path,
            "section": "UserStory",
            "type": "UserStory",
            "children": [],
            "userStoryCount": 0,
            "useCaseCount": 0

        };

        const updateNode = (nodes) => {
            return nodes.map((node) => {
                if (node.id === parentNode.id) {
                    return { ...node, children: [...node.children, newNode] };
                } else if (node.children) {
                    return { ...node, children: updateNode(node.children) };
                }
                return node;
            });
        };

        setFolderStructureList(updateNode(folderStructureList));


        setIsEditing(true);
        setEditedNode(updateNode);
    };

    const handleSaveEdit = () => {
        // Update the node with the new name
        // const updatedNode = { ...node, data: { ...node.data, name: editedName } };
        // setList(prevList => {
        //     // Find and update the node in the list
        //     const updateNode = (nodes) =>
        //         nodes.map((n) => {
        //             if (n.id === node.id) {
        //                 return updatedNode;
        //             }
        //             if (n.children) {
        //                 return { ...n, children: updateNode(n.children) };
        //             }
        //             return n;
        //         });

        //     return updateNode(prevList);
        // });

        // setEditedNode(null)
        // setIsEditing(false);  // Exit editing mode

        handleUpdateModule(editedNode)
    };

    const handleUpdateModule = async (moduleData) => {
        try {
            // setLoading(true);

            let body = {
                "projectName": activeProject?.name,
                "currentName": currentFolderData?.name,
                "newName": moduleData?.name,
                "section": pathName === ModulePath.requirements ? Entity.userStory : pathName === ModulePath.useCase ? Entity.useCase : Entity.testCase,
                "path": currentFolderData?.path,
                "delete": false
            }

            // if (currentFolderData?.name === moduleData?.name) {
            //     delete body.newName
            // }

            // if(currentFolderData?.path === moduleData?.path) {
            //     delete body.path
            // }

            // if(currentFolderData?.section === moduleData?.section) {
            //     delete body.section
            // }

            const response = await updateModule_API(body);
            console.log(response, "response handleUpdateModule")

            if (response) {
                errorHandler(response);
            }

            if (response.status === HttpStatusCode.Ok) {
                setEditedNode(null)
                setIsEditing(false);

                setIsUpdateFolderStructure(true);
                // setOpenEditFolderDialog(false);
                // setIsUpdateCurrentFolderData(true);

                // setNewFolderLocation(body.newName ? `${body?.newName}` : `${body?.path}`);





                // setList(prevList => {
                // Find and update the node in the list
                const updateNode = (nodes) =>
                    nodes.map((n) => {
                        if (n.id === node.id) {
                            return n;
                        }
                        if (n.children) {
                            return { ...n, children: updateNode(n.children) };
                        }
                        return n;
                    });

                let foundData = updateNode(folderStructureList);

                if (foundData) {
                    // setCurrentFolderData(foundData)
                }
                // });
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                // setLoading(false);
            }, 1000);
        }
    }

    const handleCancelEdit = () => {
        setIsEditing(false);  // Exit editing mode without saving
        setEditedNode(null);  // Reset the input value
        // setCurrentEditFolderData(currentFolderData);
    };


    const handleEditStart = (node) => {

    }

    // When active Project changes, then update the current folder to first folder
    useEffect(() => {
        if (activeProject) {

            // if (!currentFolderData && node?.level === 0) {
            //     setCurrentFolderData(node?.data)
            // }

            // if (currentFolderData && ((node?.level === 0 && node?.data?.name?.toLowerCase()) !== activeProject?.name?.toLowerCase())) {
            //     setCurrentFolderData(node?.data)
            // }

            // if (currentFolderData && node?.id === currentFolderData?.id && !node.isFocused) {
            //     // setCurrentFolderData(node?.data);
            //     node?.focus()
            //     node.toggle()
            // }

        }
    }, [activeProject, currentFolderData, setCurrentFolderData]);


    // When page changes, then update the current folder to first folder
    // useEffect(() => {
    //     if (node?.level === 0 && !isUpdateFolderStructure) {
    //         setCurrentFolderData(node?.data)
    //     }
    // }, [pathName, isUpdateFolderStructure, setCurrentFolderData,]);



    // When page changes, then update the current folder to first folder
    useEffect(() => {
        if (!isUpdateFolderStructure) {
            // if (currentFolderData && node?.id === currentFolderData?.id) {
            //     setCurrentFolderData(node?.data)
            //     setTimeout(() => {
            //         setCurrentFolderData(node?.data)
            //     }, 1000)
            //     console.log("folderupdated")
            // }


            // if (!currentFolderData && node?.level === 0) {
            //     setCurrentFolderData(node?.data)
            // }


            if (currentFolderData && node?.id === currentFolderData?.id && !node.isFocused) {
                // setCurrentFolderData(node?.data);
                // node?.focus()
                // node.open()
            }
        }
    }, [isUpdateFolderStructure, currentFolderData, setCurrentFolderData]);


    // To update the current folder  data
    // useEffect(() => {
    //     if (isUpdateCurrentFolderData && currentFolderData && node?.id === currentFolderData?.id) {
    //         setCurrentFolderData(node?.data);
    //         // setIsUpdateCurrentFolderData(false);
    //     }
    // }, [isUpdateCurrentFolderData, currentFolderData?.id, setCurrentFolderData, setIsUpdateCurrentFolderData])



    // To select the current folder data based on current story / usecase module
    useEffect(() => {
        if (openEditStoryDialog && currentUserStoryData && node?.data?.path === currentUserStoryData?.folderPath) {
            setCurrentFolderData(node?.data)
        }

        if (openEditUseCaseDialog && currentUseCaseData && node?.data?.path === currentUseCaseData?.folderPath) {
            setCurrentFolderData(node?.data)
        }


    }, [openEditStoryDialog, openEditUseCaseDialog]);

    // useEffect(() => {
    //    if(currentFolderData && node.id === currentFolderData.id && !node.isFocused){
    //     //    node?.focus()
    //     // node.open()
    //    } 
    // }, [node])

    useEffect(() => {
        if (currentEditFolderData && node?.id === currentEditFolderData?.id) {
            setEditedNode(currentEditFolderData);
            setIsEditing(true);
        } else {
            setIsEditing(false);
            setEditedNode(null);
        }


    }, [currentEditFolderData]);


    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [inputRef]);


    useEffect(() => {
        const handleKeyDown = (event) => {
            if (editedNode?.id !== node?.id) {
                event.preventDefault();
                event.stopPropagation();
            }
        }
        document.addEventListener("keydown", handleKeyDown)
        return () => {
            document.removeEventListener("keydown", handleKeyDown)
        }
    }, []);


    console.log(node, "node1");

    console.log(currentFolderData, "currentFolderData")

    console.log(currentFolderAnchorEl, "folderAnchorEl");
    console.log(currentEditFolderData, "currentEditFolderData")

    return (
        <Box className="flex w-full  relative">



            <Box
                className={` flex-1 flex items-center gap-1 py-1 px-2 mx-4 cursor-pointer overflow-hidden`}
                borderRadius={theme.shape.borderRadius}
                // style={style}
                // ref={dragHandle}
                // onClick={() => { handleFolderClick(node) }}
                sx={{
                    // color: (currentFolderData?.id === node?.id) && theme.palette.secondary.contrastText,
                    // background: (currentFolderData?.id === node?.id) && "rgba(0, 0, 0, 0.05)" 
                    background: (currentFolderData?.id === node?.id) && theme.palette.highlight.main,
                    // color: (currentFolderData?.id === node?.id) && theme.palette.secondary.contrastText,
                    color: (currentFolderData?.id === node?.id) ? theme.palette.text.primary : theme.palette.text.secondary,
                    pl: (node.level * 4),
                    "&:hover": {
                        background: (currentFolderData?.id !== node?.id) && theme.palette.highlight.light,
                        // color : theme.palette.highlight.contrastText
                    }

                }}

            >
                <Box className="" onClick={() => handleIconClick(node)}>
                    {!node.isLeaf &&
                        (node.isOpen ?
                            <KeyboardArrowDown /> : <KeyboardArrowRight />
                        )
                    }

                    {/* <IconButton onClick={(e) => { e.stopPropagation(); setIsEditing(true); }}>
                    <Edit />
                </IconButton> */}
                </Box>

                <Box className=" flex-1 flex items-center gap-1  overflow-hidden" onClick={() => handleFolderClick(node)} sx={{ pl: 0 }} onContextMenu={(e) => handleRightClick(e, node)}>
                    {node.data.type === FolderType.project && <FolderSpecialOutlined sx={{}} />}
                    {node.data.type === FolderType.module && <FolderOutlined sx={{}} />}

                    {/* <Box className="flex-1 flex items-center gap-1  box-border overflow-hidden border-2 border-gray-400 "> */}


                    <LightTooltip
                        title={<Box className="flex flex-col gap-1">
                            <Typography fontSize={font.size.fs_5} className="flex-1 ">Title: {node.data.name}</Typography>
                            <Typography fontSize={font.size.fs_5} className="">Description: {node?.data?.description}</Typography>
                            {pathName === ModulePath.requirements &&
                                <Typography fontSize={font.size.fs_5} className="">User Story: {node?.data?.userStoryCount}</Typography>}
                            {pathName === ModulePath.useCase &&
                                <Typography fontSize={font.size.fs_5} className="">Use Case: {node?.data?.useCaseCount}</Typography>}
                            {pathName === ModulePath.testCase &&
                                <Typography fontSize={font.size.fs_5} className="">Use Case: {node?.data?.testCaseCount}</Typography>}
                        </Box>}
                        placement="right"
                    >
                        <Box className="flex-1 flex items-center gap-1 ">

                            {isEditing ? (
                                <Box
                                    sx={{ pointerEvents: 'auto' }}
                                    className="flex-1 flex items-center   z-50"
                                    bgcolor={theme.palette.background.default}
                                >
                                    <TextField
                                        type="text"
                                        fullWidth
                                        value={editedNode?.name || ""}
                                        onChange={(e) => setEditedNode({ ...editedNode, name: e.target.value })}
                                        onBlur={(e) => {
                                            if (e.relatedTarget && e.relatedTarget.classList.contains('MuiInput-input')) {
                                                return;
                                            }
                                            handleCancelEdit();
                                        }}  // Cancel on blur
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                handleSaveEdit(); // Save on Enter key
                                            }
                                        }}
                                        sx={{ bgcolor: theme.palette.background.default, }}
                                        focused
                                        slotProps={{
                                            input: {
                                                endAdornment: <InputAdornment position="end">
                                                    <Box>
                                                        <IconButton
                                                            aria-label="save"
                                                            onClick={handleSaveEdit}
                                                            edge="end"
                                                        >
                                                            <Check />
                                                        </IconButton>
                                                        <IconButton
                                                            aria-label="cancel"
                                                            onClick={handleCancelEdit}
                                                            edge="end"
                                                        >
                                                            <Close />
                                                        </IconButton>
                                                    </Box>
                                                </InputAdornment>,
                                            },
                                        }}

                                    />
                                </Box>
                            )
                                :
                                <Box className="flex-1 flex items-center  gap-1  box-border  overflow-hidden ">
                                    <Typography fontSize={font.size.fs_5} className=" flex-1 overflow-hidden text-ellipsis whitespace-nowrap box-border " sx={{ color: "inherit" }}>{node.data.name}</Typography>
                                    <Typography fontSize={font.size.fs_5} className="w-fit" sx={{ color: "inherit" }}>{`[${pathName === ModulePath.requirements ? node?.data?.userStoryCount : pathName === ModulePath.useCase ? node?.data?.useCaseCount : node?.data?.testCaseCount}]`}</Typography>
                                </Box>
                            }


                        </Box>
                    </LightTooltip>
                    {/* </Box> */}
                </Box>

            </Box>
        </Box>
    );
}