import { AccountCircleOutlined, Add, ArrowRightAlt, Clear, Close, Delete, Description, Edit, FilterAltOutlined, FolderOutlined, FolderSpecial, FolderSpecialTwoTone, MoreHoriz, Search } from '@mui/icons-material';
import { Avatar, Box, Breadcrumbs, Button, Chi, FormControlLabel, Radiop, CircularProgress, Divider, Fab, FormControl, Icon, IconButton, InputAdornment, InputLabel, MenuItem, Popover, Select, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useApplicationContext } from '../../context/ApplicationContext';
import { useEffect, useState } from 'react';
import { grey, purple } from '@mui/material/colors';
import { font } from '../../styles/font';
import ProjectCard from './ProjectCard';
import CreateProjectDrawer from '../drawer/CreateProject';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LightTooltip } from '../styled/CustomComponent';
import EditProjectDrawer from '../drawer/EditProject';
import { addProject_API, deleteProject_API, getProjectCategoryList_API, getProjectList_API, getProjectSubcategoryList_API, updateProject_API } from '../../api/controller/ProjectController';
import { ModulePath, Severity } from '../../constant/ApplicationVariables';
import { HttpStatusCode } from 'axios';
import useErrorHandler from '../../api/errorHandler/useErrorHandler';
import DeleteProjectDialog from '../dialog/DeleteProjectDialog';
import { getFolderStructureList_API } from '../../api/controller/RequirementController';

export default function ProjectsContainer() {
    const { openStoryPreview, setOpenStoryPreview, openStoryConfirmationPreview, setOpenStoryConfirmationPreview, folderStructureList, setFolderStructureList,
        projectList, setProjectList, activeProject, setActiveProject, openCreateProjectDrawer, setOpenCreateProjectDrawer, openEditProjectDrawer, setOpenEditProjectDrawer, openDeleteProjectDialog, setOpenDeleteProjectDialog,  handleSnackbar, activeProjectSubcategory, setActiveProjectSubcategory, activeProjectCategory, setActiveProjectCategory, headerSearchText, setHeaderSearchText, isUpdateFolderStructure, setIsUpdateFolderStructure, isUpdateProjectList, setIsUpdateProjectList } = useApplicationContext();
    const theme = useTheme();
    const navigate = useNavigate();
    const errorHandler = useErrorHandler();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.up("sm") && theme.breakpoints.down("md"));
    const isLaptop = useMediaQuery(theme.breakpoints.up("md") && theme.breakpoints.down("lg"));
    const isDesktop = useMediaQuery(theme.breakpoints.up("lg") && theme.breakpoints.down("xl"));
    const isLargeDisplay = useMediaQuery(theme.breakpoints.up("xl"));


    const [isLoading, setIsLoading] = useState(false);


    const handleOpenProject = (projectData) => {
        localStorage.setItem("activeProject", JSON.stringify(projectData));
        setActiveProject(projectData);
        navigate(ModulePath.requirements);

        // setTimeout(() => {
        //     navigate(ModulePath.requirements);
        // }, 700);
    }

    const handleOpenProjectEdit = (projectData) => {
        setActiveProject(projectData);
        setOpenEditProjectDrawer(true);
    }

    const handleCloseProjectEdit = () => {
        setOpenEditProjectDrawer(false);
        setActiveProject(null);
    }

    const handleOpenCreateDialog = () => {
        setOpenCreateProjectDrawer(true);
    }

    const handleCloseCreateDialog = () => {
        setOpenCreateProjectDrawer(false);
    }


    const handleDeleteProject = (projectData) => {
        setActiveProject(projectData);
        setOpenDeleteProjectDialog(true);
    }

    const handleCloseDeleteProject = () => {
        setOpenDeleteProjectDialog(false);
        setActiveProject(null);
    }

    const handleFilterProject = (item, index) => {
        if (activeProjectCategory && activeProjectSubcategory) {
            return item?.categoryName?.toLowerCase() === activeProjectCategory?.name?.toLowerCase() && item?.subCategoryName?.toLowerCase() === activeProjectSubcategory?.name?.toLowerCase();
        } else if (activeProjectCategory && !activeProjectSubcategory) {
            return item?.categoryName?.toLowerCase() === activeProjectCategory?.name?.toLowerCase();
        } else if (activeProjectSubcategory && !activeProjectCategory) {
            return item?.subCategoryName?.toLowerCase() === activeProjectSubcategory?.name?.toLowerCase();
        } else {
            return true
        }
    }

    const handleProjectSearchFilter = (item, index) => {
        if (headerSearchText) {
            return item.name?.toLowerCase().includes(headerSearchText?.toLowerCase())
        } else {
            return item
        }
    }



    const handleGetProjectList = async () => {
        try {
            setIsLoading(true);

            const response = await getProjectList_API();
            console.log(response, "response handleGetProjectList")

            if (response) {
                errorHandler(response, setIsLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                setProjectList(response.data);
                setIsUpdateProjectList(false);
            }

        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
        }
    }

    const handleCreateProject = async (projectData) => {
        if (!projectData?.name || !projectData?.description || !projectData?.categoryName || !projectData?.subCategoryName) {
            return handleSnackbar("Please fill all the fields", Severity.error)
        }

        try {
            // setIsLoading(true);

            let body = { ...projectData };

            const response = await addProject_API(body);

            if (response.status === HttpStatusCode.Ok) {
                handleGetProjectList();
                handleCloseCreateDialog();
                // handleSnackbar(response.message, Severity.success)
            }

            if (response) {
                errorHandler(response);
            }

            setTimeout(() => {
                // setIsLoading(false);
            }, 1000);

            console.log(response, "projectreponse data");
        } catch (error) {
            console.log(error, "projectreponse errorr");
        }
    }

    const handleUpdateProject = async (projectData) => {
        if (!projectData?.name || !projectData?.description || !projectData?.categoryName || !projectData?.subCategoryName) {
            return handleSnackbar("Please fill all the fields", Severity.error)
        }

        try {
            setIsLoading(true);

            // let body = { ...projectData };

            let body = {
                // "newName": projectData?.name,
                "currentName": activeProject?.name,
                "lastModifiedAt" : activeProject?.lastModifiedAt ? activeProject?.lastModifiedAt : null,
                // "categoryName": projectData?.categoryName,
                // "subCategoryName": projectData?.subCategoryName,
                // "description": projectData?.description,
                // "status": "Archive"
                // "status" : projectData?.status
            }

            if (projectData?.name !== activeProject?.name) {
                body.newName = projectData?.name
            }
            if (projectData?.description !== activeProject?.description) {
                body.description = projectData?.description
            }
            
            if (projectData?.categoryName !== activeProject?.categoryName) {
                body.categoryName = projectData?.categoryName
            }
            if (projectData?.subCategoryName !== activeProject?.subCategoryName) {
                body.subCategoryName = projectData?.subCategoryName
            }

            if (projectData?.status !== activeProject?.status) {
                body.status = projectData?.status
            }

            if (projectData?.useCasePrefix !== activeProject?.useCasePrefix) {
                body.useCasePrefix = projectData?.useCasePrefix
            }

            if (projectData?.userStoryPrefix !== activeProject?.userStoryPrefix) {
                body.userStoryPrefix = projectData?.userStoryPrefix
            }

            if (projectData?.testCasePrefix !== activeProject?.testCasePrefix) {
                body.testCasePrefix = projectData?.testCasePrefix
            }

            const response = await updateProject_API(body);

            if (response.status === HttpStatusCode.Ok) {
                handleGetProjectList();

                let list = [...projectList];
                let foundIndex = list?.findIndex((item) => item?.name === body.currentName);
                if (foundIndex !== -1) {
                    list[foundIndex] = response.data;
                }
                setProjectList(list);

                
                handleCloseProjectEdit();
            }

            if (response) {
                errorHandler(response, setIsLoading);
            }

            setTimeout(() => {
                setIsLoading(false);
            }, 1000);

            console.log(response, "projectreponse data");
        } catch (error) {
            console.log(error, "projectreponse errorr");
        }
    }

    const handleDelete = async () => {
        try {
         

            let query = `name=${activeProject?.name}`

            const response = await deleteProject_API(query);
            console.log(response, "response handleDelete")

            if (response) {
                errorHandler(response);
            }

            if (response.status === HttpStatusCode.Ok) {
                handleGetProjectList();
                handleCloseDeleteProject();
                handleSnackbar(response.message, Severity.success)
            }


        } catch (error) {
            console.log(error, error?.message, "projectreponse errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
              
            }, 1000);
        }
    }

    useEffect(() => {
        if (projectList?.length === 0 || isUpdateProjectList) {
            handleGetProjectList();
        }
    }, [projectList?.length, isUpdateProjectList]);

    console.log(isUpdateProjectList, "isUpdateProjectList")

    console.log(isMobile, isTablet, isLaptop, isDesktop, isLargeDisplay, "isMobile isTablet isLaptop isDesktop isLargeDisplay");

    return (
        <Box className=" flex flex-col gap-6 py-4 relative h-full w-full overflow-y-auto " >

            {!openStoryPreview && !openStoryConfirmationPreview &&
                <Box className="absolute bottom-0 right-0 z-10">
                    <LightTooltip title={`Create Project`}>
                        <Fab
                            color="secondary"
                            onClick={handleOpenCreateDialog}
                            style={{ boxShadow: "none" }}>
                            <Add />
                        </Fab>
                    </LightTooltip>
                </Box>}

            {/* header */}
            <Box className="w-full h-fit flex justify-between sticky top-0 z-10 backdrop-blur-sm ">
                <Header projectsList={projectList?.filter(handleFilterProject)?.filter(handleProjectSearchFilter)} isLoading={isLoading} setIsLoading={setIsLoading} />
            </Box>


            {/* items list */}
            <Box className=" grow flex flex-col " >
                {isLoading ? (
                    <Box className="w-full h-full flex flex-col items-center justify-center gap-2">
                        <CircularProgress />
                        <Typography fontSize={font.size.fs_5}>Loading...</Typography>
                    </Box>
                )
                    : projectList?.length === 0 ?
                        <Box className="grow flex flex-col gap-2 justify-center items-center ">
                            <Description sx={{ fontSize: font.size.fs_1 }} />
                            <Typography fontSize={font.size.fs_5}>No projects</Typography>
                        </Box>
                        :
                        (
                            projectList
                                ?.filter(handleFilterProject)
                                ?.filter(handleProjectSearchFilter)?.length === 0 ?
                                (
                                    <Box className="grow flex flex-col gap-0 justify-center items-center ">
                                        <FolderSpecialTwoTone color="secondary" sx={{ fontSize: font.size.fs_1 }} />
                                        <Typography fontSize={font.size.fs_4}>No project </Typography>
                                    </Box>
                                ) :

                                (
                                    <Box className="w-full h-fit flex  flex-wrap gap-6">
                                        {
                                            projectList
                                                ?.filter(handleFilterProject)
                                                ?.filter(handleProjectSearchFilter)
                                                ?.map((project, index) => {
                                                    return (
                                                        <Box
                                                            key={index}
                                                            className={` overflow-hidden  border hover:shadow-2xl backdrop-blur-md transition-all ease-in-out duration-300 h-44 min-w-[80px] 
                                                            ${isMobile ? "w-full" : isTablet ? "w-[45%]" : isLaptop ? "w-[29.5%]" : isDesktop ? "w-[22%]" : "w-[18.6%]"} `}
                                                            bgcolor={theme.palette.card.main}
                                                            borderRadius={theme.shape.borderRadius}
                                                            sx={{ border: `1px solid transparent`, "&:hover": { border: `1px solid ${theme.palette.primary.main}` } }}
                                                        // onClick={() => handleOpenProject(project)}
                                                        >
                                                            <ProjectCard
                                                                item={project}
                                                                handleClick={() => handleOpenProject(project)}
                                                                handleEditClick={() => handleOpenProjectEdit(project)}
                                                                handleClickDelete={() => handleDeleteProject(project)}
                                                            />
                                                        </Box>
                                                    )
                                                })
                                        }

                                    </Box>
                                )
                        )
                }
            </Box>

            {openCreateProjectDrawer && <CreateProjectDrawer open={openCreateProjectDrawer} handleClose={handleCloseCreateDialog} handleContinue={handleCreateProject} />}
            {openEditProjectDrawer && <EditProjectDrawer open={openEditProjectDrawer} handleClose={handleCloseProjectEdit} handleContinue={handleUpdateProject} />}
            {openDeleteProjectDialog && <DeleteProjectDialog open={openDeleteProjectDialog} data={activeProject} handleConfirm={() => handleDelete()} handleClose={handleCloseDeleteProject} />}

        </Box>
    )
}



function Header({ projectsList, isLoading, setIsLoading }) {

    const {
        handleSnackbar,
        categoryList, setCategoryList,
        subCategoryList, setSubCategoryList,
        activeProjectCategory, setActiveProjectCategory,
        activeProjectSubcategory, setActiveProjectSubcategory,
        setHeaderSearchText
    } = useApplicationContext();
    const theme = useTheme();
    const errorHandler = useErrorHandler();


    const [loading, setLoading] = useState(false);

    let timer;


    const handleChangeProjectCategory = (event) => {
        setIsLoading(true)
        setActiveProjectCategory(event.target.value);

        setTimeout(() => {
            setIsLoading(false);
        }, 800);
    }

    const handleChangeProjectSubcategory = (event) => {
        setIsLoading(true)
        setActiveProjectSubcategory(event.target.value);

        setTimeout(() => {
            setIsLoading(false);
        }, 800);
    }

    const handleClearFilter = () => {
        setActiveProjectCategory(null);
        setActiveProjectSubcategory(null);
    }

    const handleChangeSearch = (e) => {
        clearTimeout(timer);
        setLoading(true);
        setIsLoading(true)

        timer = setTimeout(() => {
            setHeaderSearchText(e.target.value);
            setLoading(false);
            setIsLoading(false)
        }, 1000);
    }

    const handleGetCategoryList = async () => {
        try {
       

            const response = await getProjectCategoryList_API();

            if (response.status === HttpStatusCode.Ok) {
                setCategoryList(response.data);
                // setActiveProjectCategory(response.data[0]);


                // handleSnackbar(response.message, Severity.success)
            }

            if (response) {
                errorHandler(response);
            }


            console.log(response, "categoryreponse data")
        } catch (error) {
            console.log(error, "categoryreponse errorr")
        }
    }

    const handleGetSubCategoryList = async () => {
        try {
         

            let query = `parentName=${activeProjectCategory?.name}`;

            const response = await getProjectSubcategoryList_API(query);

            if (response.status === HttpStatusCode.Ok) {
                setSubCategoryList(response.data);
                // setActiveProjectSubcategory(response.data[0]);
                setActiveProjectSubcategory(null);

                // handleSnackbar(response.message, Severity.success)
            }

            if (response) {
                // errorHandler(response);
            }



            console.log(response, "subcategoryreponse data")
        } catch (error) {
            console.log(error, "subcategoryreponse errorr")
        }
    }

    useEffect(() => {
        if (categoryList?.length === 0) {
            handleGetCategoryList();
        }
        // handleGetCategoryList();
    }, [categoryList?.length]);


    useEffect(() => {
        if (activeProjectCategory) {
            handleGetSubCategoryList();
        }
    }, [activeProjectCategory]);



    console.log(activeProjectCategory, "activeProjectCategory");

    return (
        <Box className="w-full h-full flex flex-col px-0 gap-4 ">

            {/* search / filter container */}
            <Box className="w-full h-fit flex flex-col  gap-6">


                {false && <Box className="h-full flex items-center justify-center flex-1 rounded-xl min-w-44 max-w-[40%]" >
                    <TextField
                        fullWidth
                        placeholder="Search"
                        color="white"
                        size="medium"

                        sx={{
                            py: "6px",
                            bgcolor: theme.palette.common.white,
                            borderRadius: theme.shape.borderRadius,
                            px: "4px",
                            // ":root" : {
                            //     bgcolor : theme.palette.common.white
                            // }
                            // background : theme.palette.common.white,
                            '& .MuiInputBase-input': {
                                boxSizing: 'border-box',
                                border: "none",
                                // background: theme.palette.common.white,
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none'
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                border: 'none'
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                border: 'none'
                            }
                        }}
                        slotProps={{
                            input: {
                                startAdornment: <InputAdornment position="center">
                                    <Search />
                                </InputAdornment>,
                                endAdornment: <InputAdornment position="center">
                                    {loading && <CircularProgress size={22} sx={{}} />}
                                </InputAdornment>,
                            }
                        }}
                        // value={headerSearchText}
                        onChange={handleChangeSearch}
                    />
                </Box>}

                <Box className="flex items-center gap-4 ">
                    {/* <Avatar sx={{ width: "25px", height: "25px", bgcolor: theme.palette.secondary.main, fontSize: "14px" }}>
                        {projectsList?.length?.toString()}
                    </Avatar> */}

                    <Box
                        className="flex items-center gap-2 px-2 py-0 h-full"
                        border={activeProjectCategory?.name ? `1px solid ${theme.palette.primary.main}` : `1px solid transparent`}
                        borderRadius={theme.shape.borderRadius}
                        bgcolor={activeProjectCategory?.name ? theme.palette.common.white : theme.palette.common.white}>
                        <Typography fontSize={font.size.fs_5}>Category</Typography>
                        <Select size="small"
                            className="w-28 h-6"
                            // variant="standard"
                            value={activeProjectCategory?.name || "All"}
                            displayEmpty
                            sx={{
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none'
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    border: 'none'
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    border: 'none'
                                }
                            }}
                            renderValue={() => activeProjectCategory?.name}
                            onChange={handleChangeProjectCategory}
                        >
                            {
                                categoryList?.map((item, index) => {
                                    return (
                                        <MenuItem
                                            selected={activeProjectCategory && activeProjectCategory?.name?.toLowerCase() === item?.name?.toLowerCase()}
                                            value={item}
                                            key={index}>
                                            {item?.name}
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>
                        {activeProjectCategory && <IconButton onClick={() => { setActiveProjectCategory(null); setActiveProjectSubcategory(null); }}>
                            <Clear />
                        </IconButton>}
                    </Box>

                    <Box
                        className="flex items-center gap-2 px-2 py-1 h-full"
                        border={activeProjectSubcategory?.name ? `1px solid ${theme.palette.primary.main}` : `1px solid transparent`}
                        borderRadius={theme.shape.borderRadius}
                        bgcolor={activeProjectSubcategory?.name ? theme.palette.common.white : theme.palette.common.white}
                    >
                        <Typography className={`${!activeProjectCategory?.name ? "opacity-60" : ""}`} fontSize={font.size.fs_5}>SubCategory</Typography>
                        <Select size="small"
                            className="w-28 h-6"
                            // variant="standard"
                            disabled={!activeProjectCategory?.name}
                            value={activeProjectSubcategory?.name || ""}
                            displayEmpty
                            sx={{
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none'
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    border: 'none'
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    border: 'none'
                                }
                            }}
                            renderValue={() => activeProjectSubcategory?.name}
                            onChange={handleChangeProjectSubcategory}
                        >
                            {
                                subCategoryList?.map((item, index) => {
                                    return (
                                        <MenuItem
                                            selected={activeProjectSubcategory && activeProjectSubcategory?.name?.toLowerCase() === item?.name?.toLowerCase()}
                                            value={item}
                                            key={index}>
                                            {item?.name}
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>

                        {activeProjectSubcategory && <IconButton onClick={() => setActiveProjectSubcategory(null)}>
                            <Clear />
                        </IconButton>}
                    </Box>

                </Box>

            </Box>

        </Box>
    )
}
