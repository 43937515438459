import { AccountCircleOutlined, Add, AddOutlined, ArrowBack, ArrowBackOutlined, ArrowLeft, ArrowLeftOutlined, ArrowRightAlt, AutoAwesome, AutoAwesomeOutlined, AutoModeOutlined, CancelOutlined, CloseOutlined, Delete, DeleteOutline, Description, DownloadOutlined, Edit, EditOutlined, FilterAltOffOutlined, FilterAltOutlined, FolderOutlined, FolderSpecialOutlined, Home, HomeOutlined, KeyboardDoubleArrowLeft, Label, MenuOpen, MoreHoriz, UploadOutlined, Visibility, VisibilityOff } from '@mui/icons-material';
import { Avatar, Box, Breadcrumbs, Button, Chip, CircularProgress, Dialog, Divider, Fab, FormControl, FormHelperText, FormLabel, Icon, IconButton, InputAdornment, Skeleton, Tab, Tabs, TextField, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material'
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import GridViewIcon from '@mui/icons-material/GridView';
import ListIcon from '@mui/icons-material/List';
import { LightTooltip } from "../styled/CustomComponent";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { HttpStatusCode } from 'axios';
import { FolderType, ModulePath, Severity, VerificationStatus, VerificationType } from "../../constant/ApplicationVariables";
import useErrorHandler from '../../api/errorHandler/useErrorHandler';
import { addModule_API, deleteModule_API, updateModule_API } from '../../api/controller/ModuleController';
import { getFolderStructureList_API } from '../../api/controller/RequirementController';
import { useApplicationContext } from '../../context/ApplicationContext';
import { font } from '../../styles/font';
import validator from "validator"
import { register_API, resetPassword_API } from '../../api/controller/authController';
import { updateUserDetail_API } from '../../api/controller/userController';
import { MuiTelInput } from 'mui-tel-input';
import MediaComponent from '../media/MediaComponent';
import { addLabel_API, getLabelList_API, updateDeleteLabel_API } from '../../api/controller/SettingsController';
import DeleteConfirmationDialog from '../dialog/DeleteConfirmationDialog';



export default function SettingsContainer() {
    const theme = useTheme();


    return (
        <Box className=" flex flex-col gap-0   h-full w-full box-border  overflow-hidden  " >

            {/* main container */}
            <Box className="flex-1 flex flex-col gap-4  px-0 py-0 h-full relative overflow-hidden  "    >
                <Box className=" flex items-center justify-between gap-4  px-0 py-0 h-fit  "    >
                    <Typography fontSize={font.size.fs_5} color="textPrimary">Labels</Typography>
                </Box>


                <Box className="flex-1 flex flex-col gap-4  px-0 py-0 h-full relative overflow-hidden  "    >

                    <LabelsComponent />
                </Box>
            </Box>

        </Box>
    )
}

function LabelsComponent({ }) {
    const {
        handleSnackbar,
        activeProject,

        labelList, setLabelList
    } = useApplicationContext();
    const theme = useTheme();
    const navigate = useNavigate();
    const errorHandler = useErrorHandler();


    const [loading, setLoading] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const [labelName, setLabelName] = useState("");
    const [labelColor, setLabelColor] = useState("#FF5733");
    const [currentLabelData, setCurrentLabelData] = useState(null);


    // const [labelList, setLabelList] = useState([
    //     {
    //         name: "Label 1",
    //         colorCode: "#FF0000",
    //         createdAt: "2021-10-10",
    //         lastModifiedAt: "2021-10-11",
    //     },
    //     {
    //         name: "Label 2",
    //         colorCode: "#00FF00",
    //         createdAt: "2021-10-10",
    //         lastModifiedAt: "2021-10-11",
    //     },
    //     {
    //         name: "Label 3",
    //         colorCode: "#0000FF",
    //         createdAt: "2021-10-10",
    //         lastModifiedAt: "2021-10-11",
    //     },
    //     {
    //         name: "Label 4",
    //         colorCode: "#FFFF00",
    //         createdAt: "2021-10-10",
    //         lastModifiedAt: "2021-10-11",
    //     },
    //     {
    //         name: "Label 5",
    //         colorCode: "#FF00FF",
    //         createdAt: "2021-10-10",
    //         lastModifiedAt: "2021-10-11",
    //     },
    //     {
    //         name: "Label 6",
    //         colorCode: "#00FFFF",
    //         createdAt: "2021-10-10",
    //         lastModifiedAt: "2021-10-11",
    //     },
    //     {
    //         name: "Label 7",
    //         colorCode: "#FF5733",
    //         createdAt: "2021-10-10",
    //         lastModifiedAt: "2021-10-11",
    //     },
    //     {
    //         name: "Label 8",
    //         colorCode: "#33FF57",
    //         createdAt: "2021-10-10",
    //         lastModifiedAt: "2021-10-11",
    //     },
    //     {
    //         name: "Label 9",
    //         colorCode: "#3357FF",
    //         createdAt: "2021-10-10",
    //         lastModifiedAt: "2021-10-11",
    //     },
    //     {
    //         name: "Label 10",
    //         colorCode: "#FF33FF",
    //         createdAt: "2021-10-10",
    //         lastModifiedAt: "2021-10-11",
    //     },
    //     {
    //         name: "Label 11",
    //         colorCode: "#33FFFF",
    //         createdAt: "2021-10-10",
    //         lastModifiedAt: "2021-10-11",
    //     }

    // ]);



    const handleOpenDialog = (isEdit, item) => {
        setIsEditMode(isEdit);
        setOpenDialog(true);


        if (item && isEdit) {
            setCurrentLabelData(item);
            setLabelName(item?.name);
        }

    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setIsEditMode(false);
        setLabelName("");
        setCurrentLabelData(null);
    }






    const handleOpenDeleteDialog = (data) => {
        setCurrentLabelData(data);
        setOpenDeleteDialog(true);
    }

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
        setCurrentLabelData(null);
    }

    const handleContinueDeleteDialog = () => {
        setOpenDeleteDialog(false);

        handleContinueUpdate(true);
    }





    const handleSubmitDialog = () => {
        if (isEditMode) {
            handleContinueUpdate();

        } else {
            handleContinue();
        }
    }





    const handleGetList = async () => {

        try {

            setLoading(true)


            let body = {
                name: activeProject?.name,
            }

            let query = new URLSearchParams(body).toString();

            const response = await getLabelList_API(query);

            console.log(response, " response in updateprofile")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response.status === HttpStatusCode.Ok) {
                setLabelList(response.data);

            }

        } catch (error) {
            console.log(error, error?.message, "login errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);

            }, 1000);
        }
    }

    const handleContinue = async () => {

        try {

            setLoading(true);


            let body = {
                name: labelName,
                // colorCode : "#FF5733",
                projectName: activeProject?.name,
            }

            const response = await addLabel_API(body);

            console.log(response, " response in updateprofile")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response.status === HttpStatusCode.Ok) {

                handleCloseDialog();
                handleGetList();


                handleSnackbar(response?.data?.message, Severity.success);
            }

        } catch (error) {
            console.log(error, error?.message, "login errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);

            }, 1000);
        }
    }

    const handleContinueUpdate = async (isDelete) => {

        try {

            setLoading(true);


            let body = {
                currenName: currentLabelData?.name,
                newName: labelName,
                // colorCode : "#FF5733",
                projectName: activeProject?.name,

                delete: isDelete,
                lastModifiedAt: currentLabelData?.lastModifiedAt ? currentLabelData?.lastModifiedAt : null
            }

            if (currentLabelData?.name === labelName) {
                delete body.newName;
            }

            const response = await updateDeleteLabel_API(body);

            console.log(response, " response in updateprofile")

            if (response) {
                errorHandler(response, setLoading);
            }

            if (response.status === HttpStatusCode.Ok) {

                handleCloseDialog();
                handleGetList();


                handleSnackbar(response?.data?.message, Severity.success);
            }

        } catch (error) {
            console.log(error, error?.message, "login errorr");
            handleSnackbar(String(error), Severity.error);
        } finally {
            setTimeout(() => {
                setLoading(false);

            }, 1000);
        }
    }


    // get label list
    // useEffect(() => {
    //     if (activeProject?.name) {
    //         handleGetList();
    //     }
    // }, [activeProject?.name]);

    return (
        <Box className="w-full h-full flex flex-col gap-1  " >
            <Box className="flex gap-4 px-0 py-0 w-full h-full relative">


                <Box className="absolute bottom-0 right-0 z-10">
                    <LightTooltip title={`Add Label`}>
                        <Fab
                            color="secondary"
                            // disabled={openEditStoryDialog}
                            onClick={() => handleOpenDialog(false)}
                            sx={{ boxShadow: "none" }}>
                            <Add />
                        </Fab>
                    </LightTooltip>
                </Box>




                {/* main container */}
                <Box className="flex gap-4 px-0 py-0 w-full h-full relative overflow-y-auto">


                    {/* list container */}
                    <Box className="flex flex-col gap-4 px-0 py-0 pb-16 w-full h-fit  ">
                        {labelList?.map((label, index) => (
                            <Box key={index} className="flex items-center gap-4 px-4 py-2 w-full h-fit" borderRadius={theme.shape.borderRadius} bgcolor={"background.default"}>

                                <Label sx={{ color: label?.colorCode }} />
                                <Typography fontSize={font.size.fs_5} color={"textSecondary"} className="flex-1">{label?.name}</Typography>

                                <IconButton
                                    onClick={() => {
                                        // handleEdit(label);
                                        handleOpenDialog(true, label);
                                    }}
                                >
                                    <EditOutlined />
                                </IconButton>

                                <IconButton
                                    onClick={() => {
                                        handleOpenDeleteDialog(label);
                                    }}
                                >
                                    <DeleteOutline />
                                </IconButton>

                            </Box>
                        ))}

                    </Box>



                </Box>




            </Box>


            {/* create/ edit dialog */}
            <Dialog
                open={openDialog}
                onClose={() => handleCloseDialog()}
            >
                <Box className="flex flex-col gap-4 p-4 min-w-full max-w-96">
                    {/* header */}
                    <Box className="flex items-center justify-between gap-4 ">
                        <Typography fontSize={font.size.fs_5} color={"textSecondary"}>{isEditMode ? "Edit" : "Add"} Label</Typography>
                        <IconButton onClick={() => handleCloseDialog()}>
                            <CloseOutlined />
                        </IconButton>
                    </Box>

                    {/* body */}
                    <Box className="flex-1 flex items-center justify-between gap-4 ">
                        <TextField placeholder="Name" autoFocus value={labelName} onChange={(e) => setLabelName(e.target.value)} />
                        {/* color */}
                    </Box>
                    {/* footer */}
                    <Box className="flex-1 flex items-center justify-between gap-4 ">
                        <Button variant="outlined">Cancel</Button>
                        <Button variant="contained" color="secondary" onClick={handleSubmitDialog}>{isEditMode ? "Update" : "Continue"}</Button>
                    </Box>
                </Box>
            </Dialog>

            {
                openDeleteDialog && <DeleteConfirmationDialog open={openDeleteDialog} data={`${currentLabelData?.name}`} handleCloseDialog={handleCloseDeleteDialog} handleContinue={handleContinueDeleteDialog} />
            }

        </Box>
    )
}

