import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from "@mui/material";
import { CustomAccordionComponent, CustomAccordionComponentDetails, CustomAccordionComponentSummary, LightTooltip } from "../../styled/CustomComponent";
import { forwardRef } from "react";
import { TableVirtuoso } from "react-virtuoso";
import { ValueComponent } from "./CustomTextFormatting";
import { grey } from "@mui/material/colors";

export function AccordionDetailsContainer({ children, ...props }) {
    return (
        <Box className="flex flex-col gap-2 max-h-48 overflow-y-auto">
            {children}
        </Box>
    )
}

export function SectionContainer({ children, dataAvailable, enableEdit, currentSection, ...props }) {
    const theme = useTheme();
    return (
        <Box className={`w-full flex flex-col gap-1 px-1 border border-transparent cursor-pointer ${props.onClick && "hover:border hover:border-gray-200"}  rounded-lg ${props.className} `} {...props} bgcolor={enableEdit === currentSection ? theme.palette.secondary.light : "transparent"} sx={{ background: dataAvailable ? null : theme.palette.highlight.main, ...props.sx }}>
            {children}
        </Box>
    );
}

export function AccordionComponent(props) {
    return <CustomAccordionComponent {...props} sx={{ ...props.sx, maxWidth: "400px" }} />
}

export function AccordionComponentSummary(props) {
    return <CustomAccordionComponentSummary {...props} />
}

export function AccordionComponentDetails(props) {
    return <CustomAccordionComponentDetails {...props} />
}




export function LinkedItemsTable({ list, ...props }) {
    const theme = useTheme();

    const TableComponents = {
        Scroller: forwardRef((props, ref) => <TableContainer component={Paper} style={{ overflowX: "auto", overflowY: "scroll" }} {...props} ref={ref} />),
        Table: (props) => <Table {...props} style={{ borderCollapse: 'separate' }} />,
        TableHead: TableHead,
        TableRow: (props) => <TableRow {...props} />,
        TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
        TableCell: (props) => <TableCell {...props} sx={{ ...props?.sx, px: "1rem !important" }} />,
    }
    
    return (
        <TableVirtuoso
            style={{ width: "100%", height: "205px", overflowX: 'auto', }}
            data={list}
            components={TableComponents}
            fixedHeaderContent={() => (
                <TableHead>

                    <TableCell align="center" padding={"none"} className="whitespace-nowrap" style={{ margin: "0 1rem !important", fontWeight: "bold", minWidth: "42px", maxWidth: "42px", background: theme.palette.header.main, borderRight: `1px solid ${grey[300]}` }}>
                        <ValueComponent text={"S.No."} className="whitespace-nowrap py-1 px-2" fontWeight="bold" />
                    </TableCell>

                    <TableCell align="left" padding={"none"} className="whitespace-nowrap" style={{ margin: "0 1rem !important", fontWeight: "bold", minWidth: "100px", maxWidth: "100px", background: theme.palette.header.main, borderRight: `1px solid ${grey[300]}` }}>

                        <ValueComponent text={"ID"} className="whitespace-nowrap py-1 px-2" fontWeight="bold" />
                    </TableCell>

                    <TableCell align="left" padding={"none"} className="whitespace-nowrap" style={{ margin: "0 1rem !important", fontWeight: "bold", width: "100%", maxWidth: "100%", background: theme.palette.header.main, }}>

                        <ValueComponent text={"Title"} className="whitespace-nowrap py-1 px-2" fontWeight="bold" />
                    </TableCell>
                </TableHead>
            )}


            itemContent={(index, itemData) => (

                <TableRow sx={{ "&:hover": { background: grey[100], }, display: "table-row" }}>

                    <TableCell padding="none" align="center" sx={{ minWidth: "42px", maxWidth: "42px", borderRight: `1px solid ${grey[300]}` }} >

                        <ValueComponent text={index + 1} className="py-1" />
                    </TableCell>

                    <TableCell padding="none" align="left" sx={{ minWidth: "100px", maxWidth: "100px", borderRight: `1px solid ${grey[300]}` }} >

                        <ValueComponent text={`${itemData?.customId}`} className="line-clamp-1 px-2 py-1" />
                    </TableCell>

                    <TableCell padding="none" align="left" sx={{ width: "100%", maxWidth: "100%", }} >
                        <LightTooltip title={<ValueComponent text={`${itemData?.title}`} />} placement="left">

                            <ValueComponent text={`${itemData?.title}`} className="line-clamp-1 px-2 py-1" />
                        </LightTooltip>
                    </TableCell>

                </TableRow>

            )}
        />
    )
}
