import { AddTwoTone, Attachment, AutoAwesome, AutoAwesomeOutlined } from "@mui/icons-material"
import { Box, Checkbox, CircularProgress, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material"
import { amber, blue, green, grey, red } from "@mui/material/colors"
import { forwardRef, useMemo, useState } from "react"
import { TableVirtuoso } from "react-virtuoso"
import ViewUserStoryDrawer from "../drawer/ViewUserStory"
import { useApplicationContext } from "../../context/ApplicationContext"
import { font } from "../../styles/font"
import { LightTooltip } from "../styled/CustomComponent"
import { Entity_Label, FolderType, ModulePath } from "../../constant/ApplicationVariables"
import dayjs from "dayjs"
import StatusBadge from "../common/StatusBadge"
import AddUserFieldsDrawer from "../drawer/AddUserFieldsDrawer"
import { LuLink, LuUnlink } from "react-icons/lu";
import { useLocation } from "react-router-dom"
import { LinkCustomIcon, UnLinkCustomIcon } from "../styled/CustomIcons"
import { formatDateTime } from "../../utils/DateTime"

const TableComponents = {
    Scroller: forwardRef((props, ref) => <TableContainer component={Paper} style={{ overflowX: "auto", overflowY: "scroll" }} {...props} ref={ref} />),
    Table: (props) => <Table {...props} style={{ borderCollapse: 'separate' }} />,
    TableHead: TableHead,
    TableRow: (props) => <TableRow {...props} />,
    TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
    TableCell: (props) => <TableCell {...props} sx={{ ...props?.sx, px: "1rem !important" }} />,
}

export default function DashboardList({ dataList, }) {
    const {
        currentUserStoryData, setCurrentUserStoryData,

        currentFolderData,
        isUserStoryLoading, setIsUserStoryLoading,
        openLinkTagDrawer, setOpenLinkTagDrawer,
        openEditUseCaseDialog, setOpenEditUseCaseDialog,
        useCaseList, setUseCaseList,
        currentUseCaseData, setCurrentUseCaseData,
        filteredUseCaseList, setFilteredUseCaseList,
        isActiveProjectContainerLoading, setIsActiveProjectContainerLoading,
        selectedUseCaseList, setSelectedUseCaseList,
        toggleChatWindow, setToggleChatWindow,
        isItemChatWindow, setIsItemChatWindow,
    } = useApplicationContext();
    const theme = useTheme();
    const pathName = useLocation()?.pathname;


    const handleOpen = (item) => {
        setCurrentUseCaseData(item);
        setOpenEditUseCaseDialog(true);
        console.log(item, "item handleOpen")
    }

    const handleChangeCheckboxAll = (e) => {
        if (e.target.checked) {
            setSelectedUseCaseList(dataList);
        } else {
            setSelectedUseCaseList([]);
        }
    }

    const handleChangeCheckbox = (e, item) => {
        if (e.target.checked) {
            setSelectedUseCaseList([...selectedUseCaseList, item]);
        } else {
            setSelectedUseCaseList(selectedUseCaseList?.filter((story) => story?.customId !== item?.customId));
        }
    }

    const handleOpenLinkTag = (data) => {
        setCurrentUseCaseData(data)
        setOpenLinkTagDrawer(true)
    }

    const handleToggleAIWindow = (e, data) => {
        e && e.stopPropagation()

        if (isItemChatWindow && currentUseCaseData?.customId === data?.customId) {
            setIsItemChatWindow(false)
            setCurrentUseCaseData(null);
        } else {
            setCurrentUseCaseData(data);
            setIsItemChatWindow(true);
            setToggleChatWindow(true);
        }
    }

    const columns = [


        {
            data: "ID",
            padding: "normal",
            align: "center",
            background: theme.palette.header.main,
            width: "150px",
            style: {
                // position: "sticky", left: "110px", background: theme.palette.background.paper
            }
        },
        {
            data: "Title",
            padding: "normal",
            align: "left",
            background: theme.palette.header.main,
            width: "500px",
            style: {}
        },
        {
            data: "Count",
            padding: "normal",
            align: "left",
            background: theme.palette.header.main,
            width: "480px",
            style: {}
        },





    ];

    const testCaseColumns = [
        // {
        //     data: "AI",
        //     padding: "checkbox",
        //     align: "center",
        //     background: theme.palette.header.main,
        //     width: "40px"
        // },
        {
            data: <Checkbox size="small" disabled={isActiveProjectContainerLoading} checked={!isActiveProjectContainerLoading && useCaseList?.length > 0 && selectedUseCaseList?.length === useCaseList?.length} indeterminate={selectedUseCaseList?.length > 0 && selectedUseCaseList?.length < useCaseList?.length} onChange={handleChangeCheckboxAll} />,
            padding: "checkbox",
            align: "center",
            background: theme.palette.header.main,
            width: "40px",
            style: {
                position: "sticky", left: 0, background: theme.palette.background.paper
            }
        },
        {
            data: "Link",
            padding: "normal",
            align: "center",
            background: theme.palette.header.main,
            width: "70px",
            style: {
                position: "sticky", left: "40px", background: theme.palette.background.paper
            }
        },

        {
            data: "ID",
            padding: "normal",
            align: "center",
            background: theme.palette.header.main,
            width: "150px",
            style: {
                position: "sticky", left: "110px", background: theme.palette.background.paper
            }
        },
        {
            data: "Title",
            padding: "normal",
            align: "left",
            background: theme.palette.header.main,
            width: "200px",
            style: {
                position: "sticky", left: "260px", background: theme.palette.background.paper
            }
        },
        {
            data: "Steps",
            padding: "normal",
            align: "left",
            background: theme.palette.header.main,
            width: "480px",
            style: {}
        },
        {
            data: "Define Type",
            padding: "normal",
            align: "center",
            background: theme.palette.header.main,
            width: "100px",
            style: {}
        },

        {
            data: "Created By",
            key: "createdByEmail",
            padding: "normal",
            align: "left",
            background: theme.palette.header.main,
            width: "150px",
            order: "asc",
            isFilterable: true,
            style: {}
        },

        {
            data: "Creation Date",
            padding: "normal",
            align: "left",
            background: theme.palette.header.main,
            width: "170px",
            style: {}
        },

        {
            data: "Expected Result",
            padding: "normal",
            align: "left",
            background: theme.palette.header.main,
            width: "170px",
            style: {}
        },

        {
            data: "Actual Result",
            padding: "normal",
            align: "left",
            background: theme.palette.header.main,
            width: "170px",
            style: {}
        },

    ];


    let list = [
        {
            id: 1,
            title: "Completed",
            count: 10
        },
        {
            id: 2,
            title: "Pending",
            count: 5
        },
        {
            id: 3,
            title: "Failed",
            count: 7
        },
        {
            id: 4,
            title: "Interrupted",
            count: 11
        },

    ]

    return (
        <Box className="grow w-full h-full flex flex-col overflow-hidden" borderRadius={theme.shape.borderRadius + 10}>
            <TableVirtuoso
                style={{ height: "100%", width: "100%", overflowX: 'auto', borderRadius: theme.shape.borderRadius }}
                data={list}
                components={TableComponents}
                fixedHeaderContent={() => (
                    <TableHead>
                        {
                            columns?.map((item, index) => (
                                <TableCell key={index} align={item?.align} padding={item?.padding} className="whitespace-nowrap" sx={item?.style} style={{ margin: "0 1rem !important", fontWeight: "bold", minWidth: item?.width, maxWidth: item?.width, background: item?.background }}>
                                    <Typography fontSize={font.size.fs_5} fontWeight="bold" className="whitespace-nowrap"> {item?.data}</Typography>
                                </TableCell>
                            ))

                        }
                    </TableHead>
                )}


                itemContent={(index, itemData) => (

                    <TableRow onClick={() => handleOpen(itemData)} sx={{}}  >

                        {/* <TableCell padding="checkbox" align="center" sx={{ minWidth: "40px", maxWidth: "40px", position: "sticky", left: 0, }} onClick={(e) => handleToggleAIWindow(e, itemData)}>
                                    <IconButton sx={{ background: theme.palette.common.white }}>
                                        {isItemChatWindow && itemData?.customId === currentUseCaseData?.customId ? <AutoAwesome color="primary" /> : <AutoAwesomeOutlined color="primary" />}
                                    </IconButton>
                                </TableCell> */}

                        <>

                            {/* <LightTooltip title={itemData?.customId}> */}
                            <TableCell padding="normal" align="center" sx={{ minWidth: "150px", maxWidth: "150px", cursor: "pointer", }}>
                                <TextFieldComponent color={theme.palette.text.primary} className=" font-bold underline cursor-pointer"> {itemData?.id}</TextFieldComponent>
                            </TableCell>
                            {/* </LightTooltip> */}

                            {/* <LightTooltip title={itemData?.title}> */}
                            <TableCell padding="normal" align="left" sx={{ minWidth: "500px", maxWidth: "500px", cursor: "pointer", }}>
                                <TextFieldComponent > {itemData?.title}</TextFieldComponent>
                            </TableCell>
                            {/* </LightTooltip> */}


                            {/* <LightTooltip title={`Use Case`}> */}
                            <TableCell padding="normal" align="left" sx={{ minWidth: "480px", maxWidth: "480px", cursor: "pointer", }}>
                                <TextFieldComponent > {itemData?.count}</TextFieldComponent>
                            </TableCell>
                            {/* </LightTooltip> */}

                        </>


                    </TableRow>

                )}
            />

            {/* <AddUserFieldsDrawer
                open={true}
                handleClose={handleCloseUserFieldsDrawer}
                handleContinue={() => { }}
            /> */}


            {/* {openUserStoryDrawer && currentUserStoryData && 
            <ViewUserStoryDrawer open={openUserStoryDrawer} handleClose={handleCloseData} />} */}
        </Box>
    )
}


function TextFieldComponent({ children, ...props }) {
    const theme = useTheme();
    return <Typography fontSize={font.size.fs_5} color={theme.palette.text.secondary} className={`line-clamp-1 ${props.className}`} {...props}> {children}</Typography>
}